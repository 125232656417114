import React from "react";
import { Field, ErrorMessage } from "formik";
import ErrorMsg from "./ErrorMsg";

const Select = ({ name, label, options, required, ...rest }) => {
  return (
    <div className="row mb-2">
      <div className="col-sm-5">
        <label className="form-label" htmlFor={name}>
          {label}
          {required ? <span className="text-danger">{required}</span> : ""}
        </label>
      </div>
      <div className="col-sm-7">
        <Field
          as="select"
          className="form-control"
          name={name}
          id={name}
          {...rest}
        >
          <option value="">Please Select a {label}</option>
          {options?.map((item, index) => (
            <option key={index} value={item?.key ? item?.key : item?.id}>
              {item?.value ? item?.value : item?.code}
            </option>
          ))}
        </Field>
        {required ? <ErrorMessage name={name} component={ErrorMsg} /> : ""}
      </div>
    </div>
  );
};

export default Select;
