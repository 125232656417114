import axios from "axios";
import { API_BASE_URL, PROMOTION_URL } from "../helper/env";
import {
  getLocalAuthData,
  getLocalAuthToken,
  getObjectToFormData,
  getAuthToken,
} from "../helper/utils";

export const add = (url, key, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_BASE_URL + "/" + url,
        { [key]: data },
        {
          headers: {},
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};
export const addV2 = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "/" + url, data, {
        headers: {},
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};
export const promotionAdd = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .post(PROMOTION_URL + url, data, {
        headers: {},
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const exportFile = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + "/" + url, {
        params,
        headers: {},
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const update = (url, id, item, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL + "/" + url + "/" + id,
        { [item]: data },
        {
          headers: {},
        }
      )
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};
export const updatePromotion = (url, id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .put(PROMOTION_URL + url + "/" + id, data, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const updateDwh = (url, id, item, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL + "/" + url + "/" + id,
        { [item]: data },
        {
          headers: {},
        }
      )
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const getList = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/` + url, {
        params,
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getPromotionList = (url) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .get(PROMOTION_URL + url, {
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getData = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/` + url, {
        params,
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const putData = (url, data) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .put(API_BASE_URL + `/` + url, data, {
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

//will whitelist by status
export const putDataV2 = (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_BASE_URL + `/` + url, data, {
        headers: {
          Authorization: getAuthToken(),
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          resolve(res.data);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getLocations = (url, params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/` + url, {
        params,
        headers: {},
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getThanas = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/thanas/search`, {
        params,
        headers: {},
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getAreas = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/areas/search`, {
        params,
        headers: {},
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getDistributors = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/distributors`, {
        headers: {},
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getCompanies = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/options/companies`, {
        headers: {},
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getRoutes = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/options/routes`, {
        headers: {},
      })
      .then((res) => {
        if (res.data) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getOutlets = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/options/partners`, {
        headers: {},
      })
      .then((res) => {
        if (res.data) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getChannels = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/options/channels`, {
        headers: {},
      })
      .then((res) => {
        if (res.data) {
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getSKUList = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/products/variants/search?sku=`, {
        headers: {},
      })
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getSKUListV2 = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/options/sku/search?sku=`, {
        headers: {},
      })
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getDetails = (url, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/` + url + `/` + id, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getWarehouseDetails = (url, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/` + url + `/` + id + `/` + `edit`, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getPromotionDetails = (url, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(PROMOTION_URL + url + `/` + id, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const deleteData = (url, params) => {
  const authData = getLocalAuthData();
  // console.log(params)
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/${url}`, {
        params,
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const destroy = (url, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/${url}/${id}`, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const packCustomerOrder = (customer_order_id, item, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL + `/customer_orders/` + customer_order_id + `/pack`,
        { [item]: data },
        {
          headers: {},
        }
      )
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const cancelCustomerOrder = (customer_order_id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL +
          `/customer_orders/` +
          customer_order_id +
          `/cancel_order`,
        data,
        {
          headers: {},
        }
      )
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const unpackCustomerOrder = (customer_order_id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL + `/customer_orders/` + customer_order_id + `/unpack`,
        data,
        {
          headers: {},
        }
      )
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const updateOrderStatus = (packedOrders, item, customer_order_id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL + `/customer_orders/` + customer_order_id + `/pack`,
        { packed_items: packedOrders },
        {
          headers: {},
        }
      )
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getPaginatedList = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/` + url, {
        params,
        headers: {
          Authorization: authData.message?.token,
        },
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const updateData = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .put(API_BASE_URL + "/" + url, data, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postData = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + `/` + url, params, {
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
export const getLocationVariantsList = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/locations/${id}/variants`, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const assignRiderCustomerOrder = (customer_order_id, params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL +
          `/customer_orders/` +
          customer_order_id +
          `/assign_rider`,
        params,
        {
          headers: {},
        }
      )
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const serialiseAndPost = (url, obj, data) => {
  const authData = getLocalAuthData();
  const formData = getObjectToFormData({ [obj]: data });
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "/" + url, formData, {
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const serializeAndUpdate = (url, obj, id, data) => {
  const authData = getLocalAuthData();
  const formData = getObjectToFormData({ [obj]: data });
  console.log(formData);
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/${url}/${id}`, formData, {
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Something went wrong");
      });
  });
};

export const getData2 = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/` + url, {
        params,
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const postData2 = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + `/` + url, params, {
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const putData2 = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .put(API_BASE_URL + `/` + url, params, {
        headers: {
          Authorization: authData.message.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const destroy2 = (url, params) => {
  const authData = getLocalAuthToken();
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/${url}`, {
        headers: {
          Authorization: authData.message.token,
        },
        data: params,
      })
      .then((res) => {
        if (res.data.success) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
