import { Button, Modal } from "react-bootstrap";
import { FiTrash } from "react-icons/all";
import React, { useState } from "react";

function ConfirmationModal(props)  {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {
        props.customBtn ?
          <Button className={props.btnClassName} variant={props.variant} size={`sm`} onClick={handleShow}>{props.btnText}</Button> :
            props.showBtn === 'false' ?
            <FiTrash onClick={handleShow} className='btn-remove text-danger'/>
            :
            <Button className="text-danger" size={`sm`} variant={`default`} onClick={handleShow}><FiTrash/></Button>
      }
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <div dangerouslySetInnerHTML={{__html: props.body}} />
        {/* {props.body} */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          No
        </Button>
        <Button variant="secondary" onClick={() => {
            props.handleAction();
            handleClose();
        }}>Yes</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default ConfirmationModal;
