import React,{useState,useEffect} from "react";
import { Form, Row, Col } from "react-bootstrap";
import { BiRefresh } from "react-icons/all";
import { getDetails } from './../../services/baseServices';

const BoxItem = ({handleOnLocationChange, item, packedItems, index, updateLocations}) => {

    const [locations, setLocations] = useState([])
    useEffect(()=>{
        setLocations(item?.locations)
    }, [item])
    
    const refresh = () => {
        handleOnLocationChange(index,item.id,'')
        getDetails("locations/variants", item?.variant_id)
        .then((res) => {
          if(res?.success){
            setLocations(res?.data)
            updateLocations(index, res?.data)
          }
        })
        .catch((errMsg) => {
        });
    }

    return (
        <>
            <Form.Group as={Row} controlId="location">
                <Col sm='2'>
                    <div style={{cursor: 'pointer'}} onClick={()=>{refresh()}}>
                        <BiRefresh size={30}/>
                    </div>
                </Col>
                {
                    // locations?.length > 0 &&
                    <Col sm='10'>
                        <Form.Control
                            required
                            name="location_id"
                            onChange={(e) => {
                            handleOnLocationChange(
                                index,
                                item.id,
                                e.target.value
                            );
                            }}
                            as="select"
                            type="text"
                            value={packedItems[index]?.location_id}
                        >
                            <option value="">--Select Location--</option>
                            {locations?.length > 0
                            ? locations?.map((location) => {
                                return (
                                    <option
                                    value={location.id}
                                    key={location.id}
                                    >
                                    {location.code}
                                    </option>
                                );
                                })
                            : ""}
                        </Form.Control>
                        {packedItems[index]?.errors?.loc ? (
                            <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                            >
                            {packedItems[index]?.errors?.loc}
                            </Form.Control.Feedback>
                        ) : (
                            <Form.Control.Feedback type="invalid">
                            Location is required
                            </Form.Control.Feedback>
                        )}
                    </Col>
                }
            </Form.Group>
        </>
    );
};

export default BoxItem;
