import React, { Component } from "react";
import App from "../../App";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { getDetails, postData, putData } from "../../services/baseServices";
import { FaLongArrowAltLeft, FaPrint } from "react-icons/all";
import { getDate, humanize } from "../../helper/utils";
import DhBoxingItem from "./DhBoxingItem";
import BoxModal from "./BoxModal";
import ExportCSV from "../common/ExportCSV";
import ConfirmationModal from "../common/Modal";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import PrintInvoice from "./PrintInvoice";

const headers = [
  // { label: "ID", key: "id" },
  { label: "Product", key: "product_title" },
  { label: "Unit Price", key: "price" },
  { label: "Total Price", key: "total_price" },
  { label: "Ordered Quantity", key: "quantity" },
  { label: "Packed Quantity", key: "sent_quantity" },
  { label: "Received", key: "received_quantity" },
  { label: "Passed", key: "qc_passed" },
  { label: "Quality Failed", key: "quality_failed" },
  { label: "Quantity Failed", key: "quantity_failed" },
  { label: "QC Status", key: "qc_status" },
];

class ChDetails extends Component {
  constructor() {
    super();
    this.state = {
      returnOrder: {},
      selectedRow: 0,
      isLoading: true,
      packedStatus: false,
      orderPlaced: false,
      readyToShip: false,
      inTransit: false,
      receivedToDh: false,
      dispatchStatus: false,
      openPackOptions: false,
      showCheckbox: false,
      selectedLineItemsId: [],
      boxItems: [],
      showBoxModal: false,
      exportForLineItems: [],
    };
  }

  changeStatus = (order) => {
    switch (order.order_status) {
      case "order_placed":
        this.setState({
          orderPlaced: true,
          readyToShip: false,
          inTransit: false,
          receivedToDh: false,
        });
        return;
      case "ready_to_ship":
        this.setState({
          orderPlaced: false,
          readyToShip: true,
          inTransit: false,
          receivedToDh: false,
        });
        return;
      case "in_transit":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: true,
          receivedToDh: false,
        });
        return;
      case "received_to_cwh":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: false,
          receivedToDh: true,
        });
        return;
      default:
        return;
    }
  };

  componentDidMount() {
    this.getPurchaseOrder();
  }

  getPurchaseOrder = () => {
    this.setState({ order_id: this.props.match.params.id });
    getDetails("return_transfer_orders", this.props.match.params.id)
      .then((res) => {
        this.setState({ returnOrder: res?.data });
        let LineItems = res?.data?.line_items?.map((item) => {
          let obj = { ...item };
          if (obj.qc_status) obj.qc_status = "Done";
          else obj.qc_status = "Not Done";
          return obj;
        });
        this.setState({ exportForLineItems: LineItems });
        this.changeStatus(res.data);
        this.setState({ isLoading: false });
      })
      .catch((errMsg) => { });
  };

  debouncedOnChangeStatus = _.debounce((url) => {
    this.updateStatus(url);
  }, DEBOUNCE_TIME);

  handleWithDebounceChangeStatus = async (url) => {
    this.debouncedOnChangeStatus(url);
  };

  updateStatus = (url) => {
    putData(`return_transfer_orders/${this.props.match.params.id}/${url}`)
      .then((res) => {
        if (res) {
          const returnOrderObj = { ...this.state.returnOrder }
          returnOrderObj['order_status'] = 'in_transit';
          this.setState({ returnOrder: returnOrderObj })
          this.changeStatus({ order_status: 'in_transit' });
          toast.success("Successfully done ");
        } else {
          toast.error('order dispatch is failed');
        }
      })
      .catch((errMsg) => { });
  };

  handleShowAndCloseModal = () => {
    this.setState({
      selectedLineItemsId: [],
      showBoxModal: !this.state.showBoxModal,
    });
  };

  handleBoxing = () => {
    this.setState({
      showCheckbox: !this.state.showCheckbox,
    });
  };

  selectUnselectItem = (id) => {
    let selectedItem = [...this.state.selectedLineItemsId];
    if (selectedItem.includes(id)) {
      let index = selectedItem.indexOf(id);
      selectedItem.splice(index, 1);
      this.setState({ selectedLineItemsId: selectedItem });
    } else {
      selectedItem.push(id);
      this.setState({ selectedLineItemsId: selectedItem });
    }
  };
  cancelSelection = () => {
    let NoOfselectedLineItemsId = this.state.selectedLineItemsId.length;
    if (NoOfselectedLineItemsId > 0) {
      this.setState({
        selectedLineItemsId: [],
      });
    }
  };

  debouncedOnCreateBox = _.debounce(() => {
    this.createBox();
  }, DEBOUNCE_TIME);

  handleWithDebounceCreateBox = async () => {
    this.debouncedOnCreateBox();
  };

  createBox = () => {
    let params = {
      rt_order_id: this.state.returnOrder.id,
      line_item_ids: [...this.state.selectedLineItemsId],
    };
    postData("return_transfer_orders/boxes", params)
      .then((res) => {
        if (res.status_code === 200 || res.status_code === 201) {
          this.setState({ selectedLineItemsId: [] }, () => {
            toast.success(res.message);
            this.getPurchaseOrder();
          });
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  moveItemToBox = () => {
    this.setState({ showBoxModal: !this.state.showBoxModal });
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="text-left">
              {this.state.orderPlaced &&
                this.state.returnOrder?.line_items?.length > 0 && (
                  <Button
                    size="sm"
                    type={"button"}
                    className="mr-1"
                    onClick={this.handleBoxing}
                    variant="info"
                  >
                    Boxing
                  </Button>
                )}
              {this.state.readyToShip && (
                <Button
                  size="sm"
                  type={"button"}
                  className="mr-1"
                  onClick={() => {
                    this.handleWithDebounceChangeStatus("in_transit");
                  }}
                  variant="info"
                >
                  Dispatch
                </Button>
              )}
            </div>
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/dhReturnOrder/list`}
                className="btn btn-sm btn-link"
              >
                <FaLongArrowAltLeft /> Back to List
              </Link>
            </div>
          </div>

          {this.state.isLoading ? (
            <div className={`card-block`}>
              <div className="block-body">Loading...</div>
            </div>
          ) : (
            <div className="card-block">
              <div className="block-header">
                <h5 className="card-title">Inter Godown Transfer Order Details</h5>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <a href="#">
                        <Button
                          variant={"default"}
                          onClick={this.handlePrint}
                          size={"sm"}
                        >
                          <FaPrint /> Print Invoice
                        </Button>
                      </a>
                    );
                  }}
                  content={() => this.componentRef}
                />
                <div className="only-print">
                  <PrintInvoice
                    ref={(el) => (this.componentRef = el)}
                    purchaseOrder={this.state.returnOrder}
                  />
                </div>
              </div>
              <div className="block-body">
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>IGT ID</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder && this.state?.returnOrder?.id}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Creation Date</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          getDate(this.state.returnOrder.order_date).format(
                            "Do MMMM YYYY"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Total Ordered Quantity</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.quantity}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>QC Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder?.qc_status
                          ? "Done"
                          : "Not Done"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.order_status
                          ? humanize(this.state.returnOrder.order_status)
                          : "Unknown"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Total Price</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.total_price}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div
            className={`card-block ${this.state.returnOrder?.line_items?.length > 0 ? "" : "d-none"
              }`}
          >
            <div className="block-header">
              <h4 className="block-title">Inter Godown Transfer Order Items</h4>
              <div className="ml-auto">
                <ExportCSV
                  data={this.state.exportForLineItems}
                  headers={headers}
                  title="IGT Order Items"
                />
              </div>
            </div>

            {this.state.isLoading ? (
              <div className={`card-block`}>
                <div className="block-body">Loading...</div>
              </div>
            ) : this.state.returnOrder?.line_items?.length > 0 ? (
              <div className="card">
                <div className="card-body">
                  <>
                    <table className="order-table table table-striped table-hover">
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: "20px" }}>#</th>
                          <th>Product</th>
                          <th>Unit Price</th>
                          <th>Total Price</th>
                          <th className="text-center">Ordered Quantity</th>
                          <th className="text-center">Packed Quantity</th>
                          <th className="text-center">Received</th>
                          <th className="text-center">Passed</th>
                          <th className="text-center">Quality Failed</th>
                          <th className="text-center">Quantity Failed</th>
                          <th className="text-center">QC Status</th>
                          {this.state.openPackOptions && (
                            <th>
                              Req. Fields <span className="text-danger">*</span>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.returnOrder?.line_items?.length > 0 &&
                          this.state.returnOrder?.line_items?.map(
                            (orderItem, index) => {
                              console.log("order item", orderItem);
                              return (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    this.setState({ selectedRow: index })
                                  }
                                >
                                  <td>
                                    {this.state.showCheckbox && (
                                      <Form.Group className="order-item pt-3">
                                        <Form.Check
                                          type="checkbox"
                                          onChange={() =>
                                            this.selectUnselectItem(
                                              orderItem.id
                                            )
                                          }
                                          checked={this.state.selectedLineItemsId.includes(
                                            orderItem.id
                                          )}
                                        />
                                      </Form.Group>
                                    )}
                                  </td>

                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="order-item">
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/product/details/${orderItem.product_id}`}
                                      >
                                        {orderItem.product_title}
                                      </Link>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.price} BDT
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.total_price} BDT
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item text-center">
                                      {orderItem.quantity}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="order-item text-center">
                                      {orderItem.sent_quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item text-center">
                                      {orderItem.received_quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item text-center">
                                      {orderItem.qc_passed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item text-center">
                                      {orderItem.quality_failed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item text-center">
                                      {orderItem.quantity_failed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item text-center">
                                      {orderItem.qc_status
                                        ? "Done"
                                        : "Not Done"}
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                    {this.state.orderPlaced && (
                      <div className="d-flex justify-content-end">
                        <OverlayTrigger
                          key="view"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-view`}>Create Box</Tooltip>
                          }
                        >
                          <ConfirmationModal
                            customBtn={true}
                            variant="primary"
                            size="sm"
                            btnText="Create Box"
                            btnClassName={`mr-2 ${this.state.selectedLineItemsId.length > 0
                              ? ""
                              : "disabled"
                              }`}
                            title="Create Box"
                            body="Are you sure you want to create box?"
                            handleAction={this.handleWithDebounceCreateBox}
                          />
                        </OverlayTrigger>
                        <button
                          className="btn btn-primary btn-sm mr-2"
                          type="button"
                          disabled={
                            !(
                              this.state.selectedLineItemsId.length > 0 &&
                              this.state.returnOrder?.boxes?.length > 0
                            )
                          }
                          onClick={this.moveItemToBox}
                        >
                          Move
                        </button>

                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={this.cancelSelection}
                          disabled={
                            this.state.selectedLineItemsId.length > 0
                              ? false
                              : true
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </>
                </div>
              </div>
            ) : (
              <div className="text-center py-4">
                <p>There are no line items for boxing</p>
              </div>
            )}
          </div>
        </div>
        {this.state.selectedLineItemsId?.length > 0 && (
          <BoxModal
            show={this.state.showBoxModal}
            handleClose={this.handleShowAndCloseModal}
            existingBoxes={this.state.returnOrder?.boxes}
            type={"Ch_Details"}
            dh_po_id={this.state.returnOrder?.id}
            lineItemId={this.state.selectedLineItemsId}
            getPurchaseOrder={this.getPurchaseOrder}
          />
        )}
        {this.state.returnOrder?.boxes?.length > 0 &&
          this.state.returnOrder?.boxes?.map((item) => (
            <DhBoxingItem
              key={item.id}
              boxItem={item}
              purchaseOrder={this.state.returnOrder}
              getPurchaseOrder={this.getPurchaseOrder}
              headers={headers}
            />
          ))}
      </App>
    );
  }
}

export default withRouter(ChDetails);
