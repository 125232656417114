import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { postData } from "../../services/baseServices";
import { toast } from "react-toastify";


const SendPromotionModal = ({ show, handleClose, promotionId }) => {

  const schema = yup.object({
    nftMsg: yup.string().required('Notification message is required'),
    nftBnMsg: yup.string().required('Notification in Bangla message is required'),
  }).required();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = data => {
    const body = {
      message: data.nftMsg,
      bn_message: data.nftBnMsg,
      promotion_id: promotionId
    }

    console.log(promotionId);
    console.log(body);

    postData("notifications", body)
      .then(res => {
        toast.success(res.message);
        handleClose()
      })
      .catch(errMsg => {
        toast.error(errMsg);
      });
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Promotion Notification</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className='mb-3'>
            <label htmlFor="nftMsg" className='font-weight-bold'>Notification <span className="text-danger">*</span></label>
            <textarea id="" cols="30" rows="3" {...register("nftMsg")} class='form-control' id='nftMsg'></textarea>
            <p className='text-danger'>{errors.nftMsg?.message}</p>

          </div>
          <div>
            <label htmlFor="nftMsg" className='font-weight-bold'>Notification in Bangla<span className="text-danger">*</span></label>
            <textarea id="" cols="30" rows="3" {...register("nftBnMsg")} class='form-control' id='nftMsg'></textarea>
            <p className='text-danger'>{errors.nftBnMsg?.message}</p>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type='submit'>
            Send
          </Button>
        </Modal.Footer>
      </form>

    </Modal>
  )
}

export default SendPromotionModal

