import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getPaginatedList, putData } from "../../services/baseServices";
import { getFormattedDate } from "../../helper/utils"
import { toast } from "react-toastify";
import ConfirmationModal from "../common/Modal"
import { TABLES } from './Reconciliation'
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env"

class ReturnOrders extends Component {
	constructor() {
		super();
		this.state = {
			returnOrders: [],
			activePage: 1,
			per_page: 15,
			page: 1,
			query_params: {},
			isLoading: true,
		};
	}

	getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

	getCOList = (params) => {
        this.setState({ isLoading: true })
		getPaginatedList(this.props.url, params)
			.then((res) => {
				this.setState(
					{
						returnOrders: res.data,
						isLoading: false,
						per_page: res.headers["x-per-page"],
						total_data: parseInt(res.headers["x-total"]),
					},
					() => {}
				);
			})
			.catch((errMsg) => {
                this.setState({ isLoading: false })
				console.log("Error Message: ", errMsg);
			});
	};

	componentDidMount() {
		console.log(this.props.filteredRiders)
		this.setState({ query_params: this.props.queryParams }, () => {
			this.getCOList(this.getQueryParams());
		})
	}

	handlePageChange = (pageNumber) => {
		this.setState({ page: pageNumber, activePage: pageNumber }, () => {
			this.getCOList(this.getQueryParams());
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
		    console.log(this.props.queryParams)
			this.setState({ query_params: this.props.queryParams }, () => {
            	this.getCOList(this.getQueryParams());
			})
        }
	}

    debouncedOnReceiveRO = _.debounce((orderId)=>{
        this.receiveReturnedOrder(orderId)
    }, DEBOUNCE_TIME);

    handleWithDebounceReceiveRO = async (orderId) => {
        this.debouncedOnReceiveRO(orderId);
    }

    receiveReturnedOrder = (orderId) => {
        this.setState(({isWaiting: true}), ()=> {
            let url = this.props.whom === "sr" ? `routes/receive_order/${orderId}` : `riders/receive_order/${orderId}`;
            putData(url,{})
            .then(res => {
                if(res.status_code === 200) {
                    toast.success("Return collected successfully");
                    this.props.onAfterAnyAction([TABLES.ORDER_SUMMARY, TABLES.ORDER_LIST])
                    this.getCOList(this.getQueryParams());
                } else {
                    toast.error(res.message);
                }
            })
            .catch(errMsg => {
                this.setState({isWaiting: false});
                toast.error(errMsg);
            });
        })
      }

	render() {
		return (
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">Customer Orders</h2>
                </div>
                {
                    this.state.isLoading ?
                        <div>Loading...</div>
                    :
                        <>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{width: '120px'}}>Order No</th>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Shipping Type</th>
                                        <th>Price (৳)</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                            <tbody>
                                {
                                this.state.returnOrders?.length>0 ?
                                this.state.returnOrders?.map((item,key) => (
                                    <tr key={key}>
                                        <td>{<Link to={`${process.env.PUBLIC_URL}/customerOrder/details/${item.order_id}`}
                                                    >{item.order_id}</Link>}</td>
                                        <td>{getFormattedDate(item.date, 'LT, DD-MM-YYYY')}</td>
                                        <td>{item.order_type}</td>
                                        <td>{item.shipping_type}</td>
                                        <td>{item.price}</td>
                                        <td>{item.status}</td>
                                        <td>
                                            <OverlayTrigger
                                                key="view"
                                                placement="top"
                                                overlay={
                                                <Tooltip id={`tooltip-view`}>
                                                    Delete
                                                </Tooltip>
                                                }
                                            >
                                                <ConfirmationModal 
                                                    customBtn = {true}
                                                    btnText = "Receive"
                                                    variant = "success"
                                                    title =" Receive customer order" 
                                                    body = {`Are you sure to receive the customer order(${item.order_id})?`} 
                                                    handleAction = {() =>this.handleWithDebounceReceiveRO(item.order_id)}/>
                                            </OverlayTrigger>
                                        </td> 
                                    </tr>
                                ))
                                :
                                    <tr><td colSpan={8}>No request found</td></tr>
                                }
                            </tbody>
                            </table>
                            {
                                this.state.returnOrders?.length > 0 &&
                                <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={parseInt(this.state.per_page)}
                                        totalItemsCount={parseInt(this.state.total_data)}
                                        pageRangeDisplayed={10}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            }
                        </>
                    }
            </div>
		);
	}
}

export default withRouter(ReturnOrders);
