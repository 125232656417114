import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEdit, FiPlus, FiEye } from "react-icons/all";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import ConfirmationModal from "../common/Modal";
import App from "../../App";
import { destroy, getList } from "../../services/baseServices";
import "../../assets/scss/order-list.scss";

const CompanyList = () => {
  const [companyList, setCompanyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCompanyList = () => {
    getList("companies")
      .then((res) => {
        if (res.success) {
          setCompanyList(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to fetch company list");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchCompanyList();
  }, []);

  return (
    <App layout="">
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Company List</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/company/add`}
              className="btn btn-sm btn-secondary"
            >
              <FiPlus /> Add Company
            </Link>
          </div>
        </div>
        <div className="card-block">
          <div className="block-body">
            {isLoading ? (
              <div className="text-center">Loading...</div>
            ) : (
              <table className="order-table table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th>Logo</th>
                    <th>Name</th>
                    <th>Is Active</th>
                    <th>SD Rate</th>
                    <th>Vat Rate</th>
                    <th>Source of Tax</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {companyList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="order-item">
                          <Link
                            to={`${process.env.PUBLIC_URL}/company/details/${item.id}`}
                          >
                            <div className="user-avatar">
                              <img
                                src={item.logo_url}
                                alt="Company Logo"
                                style={{ width: "40px", height: "40px" }}
                              />
                            </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="order-item">{item.name}</div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item.is_active ? "Active" : "Inactive"}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">{item.sd_rate}</div>
                      </td>
                      <td>
                        <div className="order-item">{item.vat_rate}</div>
                      </td>
                      <td>
                        <div className="order-item">{item.source_tax}</div>
                      </td>
                      <td style={{ width: "150px" }}>
                        <div className="btn-group">
                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>View</Tooltip>
                            }
                          >
                            <Link
                              to={`${process.env.PUBLIC_URL}/company/details/${item.id}`}
                              className="btn btn-sm btn-default"
                            >
                              <FiEye />
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="edit"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-edit`}>Edit</Tooltip>
                            }
                          >
                            <Link
                              to={`${process.env.PUBLIC_URL}/company/edit/${item.id}`}
                              className="btn btn-sm btn-default"
                            >
                              <FiEdit />
                            </Link>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </App>
  );
};

export default CompanyList;
