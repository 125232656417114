import React, { Component } from "react";
import App from "../../App";
import { Button, Form } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { getData, getDetails, getList, putData } from "../../services/baseServices";
import { FaLongArrowAltLeft, FaPrint } from "react-icons/all";
import { getDate, humanize } from "../../helper/utils";
import ExportCSV from "../common/ExportCSV";
import ChBoxingItem from "./ChBoxingItem";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import PrintInvoice from "./PrintInvoice";
import PoDetailsModal from "../purchaseOrder/PoDetailsModal";

const headers = [
  { label: "Product", key: "product_title" },
  { label: "Unit Price", key: "price" },
  { label: "Total Price", key: "total_price" },
  { label: "Ordered Quantity", key: "quantity" },
  { label: "Sent Quantity", key: "sent_quantity" },
  { label: "Received", key: "received_quantity" },
  { label: "Passed", key: "qc_passed" },
  { label: "Quality Failed", key: "quality_failed" },
  { label: "Quantity Failed", key: "quantity_failed" },
  { label: "QC Status", key: "qc_status" },
];
class DhDetails extends Component {
  constructor() {
    super();
    this.state = {
      locations: [],
      returnOrder: {},
      boxes: [],
      selectedRow: 0,
      isLoading: true,
      packedStatus: false,
      orderPlaced: false,
      readyToShip: false,
      inTransit: false,
      receivedToCWh: false,
      dispatchStatus: false,
      selectedLocation: null,
      openPackOptions: false,
      packedItems: {},
      dataForExport: [],
      exportForLineItems: [],
      showModal: false,
      modalData: []
    };
  }

  changeStatus = (order) => {
    switch (order.order_status) {
      case "order_placed":
        this.setState({
          orderPlaced: true,
          readyToShip: false,
          inTransit: false,
          receivedToCWh: false,
        });
        return;
      case "ready_to_ship":
        this.setState({
          orderPlaced: false,
          readyToShip: true,
          inTransit: false,
          receivedToCWh: false,
        });
        return;
      case "in_transit":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: true,
          receivedToCWh: false,
        });
        return;
      case "received_to_cwh":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: false,
          receivedToCWh: true,
        });
        return;
      default:
        return;
    }
  };

  componentDidMount() {
    this.getPurchaseOrder();

    getList("locations")
      .then((res) => {
        this.setState({ locations: res });
      })
      .catch((errMsg) => { });
  }

  getPurchaseOrder = () => {
    this.setState({ order_id: this.props.match.params.id });
    getDetails("return_transfer_orders", this.props.match.params.id)
      .then((res) => {
        this.setState({ returnOrder: res?.data });
        let LineItems = res?.data?.line_items?.map((item) => {
          let obj = { ...item };
          if (obj.qc_status) obj.qc_status = "Done";
          else obj.qc_status = "Not Done";
          return obj;
        });
        this.setState({ exportForLineItems: LineItems });
        this.setState({ boxes: res?.data?.boxes });
        this.changeStatus(res.data);
        this.setState({ isLoading: false });
      })
      .catch((errMsg) => {
        console.log("error".errMsg);
      });
  };

  debouncedOnChangeStatus = _.debounce((url) => {
    this.updateStatus(url);
  }, DEBOUNCE_TIME);

  handleWithDebounceChangeStatus = async (url) => {
    this.debouncedOnChangeStatus(url);
  };

  updateStatus = (url) => {
    putData(`return_transfer_orders/${this.props.match.params.id}/${url}`)
      .then((res) => {
        if (res) {
          const returnOrderObj = { ...this.state.returnOrder };
          returnOrderObj["order_status"] = "received_to_cwh";
          this.setState({ returnOrder: returnOrderObj });
          this.changeStatus({ order_status: "received_to_cwh" });
          toast.success("Successfully done ");
        } else {
          toast.error("order received is failed");
        }
      })
      .catch((errMsg) => { });
  };

  updateBoxList = (box_id, lineItem) => {
    let boxList = [...this.state.boxes];

    if (box_id && lineItem.id) {
      let selectedBoxIndex = boxList.findIndex((item) => item.id === box_id);
      let selectedBox = boxList[selectedBoxIndex];
      let boxLineItems = [...selectedBox.line_items];
      let boxLineItemIndex = boxLineItems.findIndex(
        (item) => item.id === lineItem.id
      );
      boxLineItems[boxLineItemIndex] = lineItem;
      selectedBox["line_items"] = boxLineItems;
      boxList[selectedBoxIndex] = selectedBox;
    }
    this.setState({ boxes: boxList });
  };

  showModalHandler = (id) => {
    getData(`line_items/${id}`)
      .then((res) => {
        this.state.modalData = res?.data
        this.setState({
          ...this.state,
          showModal: !this.state.showModal
        })
      })
      .catch((error) => {
        console.log(error)
      })

  }
  hideModalHandler = () => {
    this.setState({
      ...this.state,
      showModal: false
    })
  }
  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="text-left">
              {this.state.inTransit && (
                <Button
                  size="sm"
                  type={"button"}
                  className="mr-1"
                  onClick={() => {
                    this.handleWithDebounceChangeStatus("received_to_wh");
                  }}
                  variant="info"
                >
                  Receive
                </Button>
              )}
            </div>
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/dhReturnOrder/list`}
                className="btn btn-sm btn-link"
              >
                <FaLongArrowAltLeft /> Back to List
              </Link>
            </div>
          </div>

          {this.state.isLoading ? (
            <div className={`card-block`}>
              <div className="block-body">Loading...</div>
            </div>
          ) : (
            <div className="card-block">
              <div className="block-header">
                <h5 className="card-title">Inter Godown Transfer Order Details</h5>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <a href="#">
                        <Button
                          variant={"default"}
                          onClick={this.handlePrint}
                          size={"sm"}
                        >
                          <FaPrint /> Print Invoice
                        </Button>
                      </a>
                    );
                  }}
                  content={() => this.componentRef}
                />
                <div className="only-print">
                  <PrintInvoice
                    ref={(el) => (this.componentRef = el)}
                    purchaseOrder={this.state.returnOrder}
                  />
                </div>
              </div>
              <div className="block-body">
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>IGT ID</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder && this.state?.returnOrder?.id}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Creation Date</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          getDate(this.state.returnOrder.order_date).format(
                            "Do MMMM YYYY"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Total Ordered Quantity</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.quantity}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>QC Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.qc_status
                          ? "Done"
                          : "Not Done"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.order_status
                          ? humanize(this.state.returnOrder.order_status)
                          : "Unknown"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Total Price</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.total_price}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Created By</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.returnOrder &&
                          this.state.returnOrder.created_by?.name}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* start list */}

          {this.state.returnOrder?.line_items?.length > 0 && (
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Inter Godown Transfer Order Items</h4>
                <div className="ml-auto">
                  <ExportCSV
                    data={this.state.exportForLineItems}
                    headers={headers}
                    title="IGT Order Items"
                  />
                </div>
              </div>
              {this.state.isLoading ? (
                <div className={`card-block`}>
                  <div className="block-body">Loading...</div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <>
                      <Form
                        noValidate
                        validated={this.state.isFormValidated}
                        onSubmit={this.handlePackOrder}
                      >
                        <table className="order-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "20px" }}>#</th>
                              <th>Product</th>
                              <th>Unit Price</th>
                              <th>Total Price</th>
                              <th className="text-center">Ordered Quantity</th>
                              <th className="text-center">Sent Quantity</th>
                              <th className="text-center">Received</th>
                              <th className="text-center">Passed</th>
                              <th className="text-center">Quality Failed</th>
                              <th className="text-center">Quantity Failed</th>
                              <th className="text-center">QC Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.returnOrder?.line_items?.map(
                              (orderItem, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      this.setState({ selectedRow: index })
                                    }
                                  >
                                    <td>{index + 1}</td>
                                    <td>
                                      <div className="order-item">
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/product/details/${orderItem.product_id}`}
                                        >
                                          {orderItem.product_title}
                                        </Link>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.price} BDT
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.total_price} BDT
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item text-center">
                                        {orderItem.quantity}
                                      </div>
                                    </td> <td>
                                      <div className="order-item text-center">
                                        {orderItem.sent_quantity}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="order-item text-center">
                                        {orderItem.received_quantity}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item text-center">
                                        {orderItem.qc_passed}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item text-center">
                                        {orderItem.quality_failed}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item text-center">
                                        {orderItem.quantity_failed}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item text-center">
                                        {orderItem.qc_status
                                          ? "Done"
                                          : "Not Done"}
                                      </div>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        type={"button"}
                                        className="mr-1"
                                        onClick={() => this.showModalHandler(orderItem?.id)}
                                        variant="info">
                                        Log
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </Form>
                    </>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* end list */}

          {this.state.boxes?.length > 0 &&
            this.state.boxes?.map((item) => (
              <ChBoxingItem
                key={item.id}
                showModalHandler={this.showModalHandler}
                boxItem={item}
                po_id={this.state.returnOrder.id}
                receivedToCWh={this.state.receivedToCWh}
                locations={this.state.locations}
                getPurchaseOrder={this.getPurchaseOrder}
                updateBoxList={this.updateBoxList}
                headers={headers}
              />
            ))}
        </div>

        <PoDetailsModal
          show={this.state.showModal}
          onHide={this.hideModalHandler}
          modalTitle="IGT Details Log"
          data={this.state.modalData}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
      </App>
    );
  }
}

export default withRouter(DhDetails);
