import React, { useEffect, useState } from "react";

import BangladeshLogo from "../../assets/img/bangladesh_logo_mushak.png";
import { getData } from "../../services";
import moment from "moment";

const PrintMushak = React.forwardRef((props, ref) => {
  const { stoId } = props;
  const [stoMushakData, setStoMushakData] = useState({});
  useEffect(() => {
    getData(`sto_mushaks/print/${stoId}`).then((res) => {
      if (res?.status === 200) {
        setStoMushakData(res?.data);
      }
    });
    return () => {
      setStoMushakData({});
    };
  }, [stoId, ref]);
  return (
    <div className="invoice-container" ref={ref}>
      <div className="d-flex justify-content-between py-5">
        <img
          src={BangladeshLogo}
          alt="Logo Bangladesh"
          className="my-auto"
          style={{
            width: "150px",
            height: "150px",
          }}
        />

        <div className="invoice-header">
          <p>Government of the People's Republic of Bangladesh</p>
          <p className="font-weight-bold mb-3">National Board of Revenue</p>
          <p className="font-weight-bold">Tax Challan</p>
          <p className="mb-3">
            [See clauses (c) and (f) of Sub-Rule(1) of Rule 40]
          </p>
          <p>
            Name of the registered person: {stoMushakData?.registered_person}
          </p>
          <p>
            BIN of the registered person: {stoMushakData?.bin_registered_person}
          </p>
          <p>
            Address of the registered person:{" "}
            {stoMushakData?.address_registered_person}
          </p>
          <p>
            Address of invoice issue: {stoMushakData?.address_invoice_issue}
          </p>
        </div>

        <p
          className="font-weight-bold my-auto"
          style={{
            fontSize: "11px",
          }}
        >
          Mushak -{" "}
          {stoMushakData?.mushak_code ? stoMushakData?.mushak_code : "6.3"}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <div className="customer-info">
          <p className="mb-1">
            Customer Name:{" "}
            <span className="ml-3 customer-name">
              {stoMushakData?.customer_name}
            </span>
          </p>
          <p className="mb-1">
            Customer BIN:{" "}
            <span className="ml-3 customer-bin">
              {stoMushakData?.customer_bin}
            </span>
          </p>
          <p className="mb-1">
            Customer Address:{" "}
            <span className="ml-3 customer-address">
              {stoMushakData?.customer_address}
            </span>
          </p>
          <p className="mb-1">
            Shipping Address:{" "}
            <span className="ml-3 customer-shipping-address">
              {stoMushakData?.shipping_address}
            </span>
          </p>
          <p className="mb-1 text-bold">
            Vehicle Type & Registration No:
            <span className="ml-3">{stoMushakData?.vehicle_details}</span>
          </p>
        </div>
        <div className="challan-info">
          <div className="d-flex justify-content-between">
            <p className="pr-5 mb-1">Challan No:</p>
            <p>{stoMushakData?.challan_no || ""}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="pr-5 mb-1">Date</p>
            <p>
              {stoMushakData?.date
                ? moment(stoMushakData?.date).format("DD-MMM-YY")
                : ""}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="pr-5 mb-1">Time</p>
            <p>
              {stoMushakData?.time
                ? moment(stoMushakData?.time)
                    .utcOffset("+0600")
                    .format("h:mm A")
                : ""}
            </p>
          </div>
        </div>
      </div>

      <div className="line-tems">
        <table className="table table-bordered custom-table">
          <thead>
            <tr>
              <th>Sl</th>
              <th>
                Description of product or service (with brand name if
                applicable)
              </th>
              <th>Quantity</th>
              <th>Unit Price* (Taka) </th>
              <th>Total Price/ Total Charge (Taka) </th>
              <th>SD Rate</th>
              <th>SD Amount (Taka)</th>
              <th>Value Added Tax Rate/Specific Tax</th>
              <th>Amount of Value Added Tax Rate/Specified Tax</th>
              <th>Price/Charge Including All kinds of Duties and Taxes</th>
            </tr>
          </thead>
          <tbody>
            {stoMushakData?.items && stoMushakData?.items.length > 0
              ? stoMushakData?.items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.product_title}</td>
                    <td>{item.quantity}</td>
                    <td>
                      {parseFloat(Number(item.base_unit_price)).toFixed(2)}
                    </td>
                    <td>{parseFloat(Number(item.total_price)).toFixed(2)}</td>
                    <td>{parseFloat(Number(item.sd_rate)).toFixed(2)}%</td>
                    <td>{parseFloat(Number(item.sd_amount)).toFixed(2)}</td>
                    <td>{parseFloat(Number(item.vat_rate)).toFixed(2)}%</td>
                    <td>{parseFloat(Number(item.vat_amount)).toFixed(2)}</td>
                    <td>
                      {parseFloat(Number(item.price_including_all)).toFixed(2)}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4" className="font-weight-bold text-right">
                Total
              </td>
              <td>
                {stoMushakData?.items_total?.total_price
                  ? parseFloat(
                      Number(stoMushakData?.items_total?.total_price)
                    ).toFixed(2)
                  : "0.00"}
              </td>
              <td colSpan="3"></td>
              <td>
                {stoMushakData?.items_total?.vat_amount
                  ? parseFloat(
                      Number(stoMushakData?.items_total?.vat_amount)
                    ).toFixed(2)
                  : "0.00"}
              </td>
              <td>
                {stoMushakData?.items_total?.price_including_all
                  ? parseFloat(
                      Number(stoMushakData?.items_total?.price_including_all)
                    ).toFixed(2)
                  : "0.00"}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="d-flex justify-content-between footer-info">
        <p>
          *Price excluding all types of Taxes:{" "}
          <span className="font-weight-bold">
            {stoMushakData?.items_total?.total_price
              ? parseFloat(
                  Number(stoMushakData?.items_total?.total_price)
                ).toFixed(2)
              : "0.00"}
          </span>
        </p>
        <div>
          <div className="d-flex justify-content-between">
            <p className="pr-5">
              Name of the person in charge of the organization:
            </p>
            {stoMushakData?.footer_person_in_charge}
          </div>
          <div className="d-flex justify-content-between">
            <p className="pr-5">Designation:</p>
            {stoMushakData?.footer_pic_designation}
          </div>
          <div className="d-flex justify-content-between">
            <p className="pr-5">Signature:</p>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <p className="pr-5 mt-2">Seal:</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintMushak;
