import React, { useEffect, useState } from 'react';
import App from "../../App";
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from "react-router-dom";
import { DiUbuntu, FaLongArrowAltLeft } from "react-icons/all"
import BrandForm from './Form'
import { serializeAndUpdate, getDetails } from "../../services/baseServices";

const Edit = () => {
    const [brand, setBrand] = useState({})
    const [existingBannerImages, setExistingBannerImages] = useState([])
    const [newBannerImages, setNewBannerImages] = useState([])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [metaInfo, setMetaInfo] = useState({})
    const history = useHistory()
    const { id } = useParams()

    const onSubmit = data => {
        const meta_datum_attributes = {
            meta_title: metaInfo.metaTitle,
            bn_meta_title: metaInfo.metaTitleBn,
            meta_description: metaInfo.metaDescription,
            bn_meta_description: metaInfo.metaDescriptionBn,
            meta_keyword: metaInfo.metaKeywords,
            bn_meta_keyword: metaInfo.metaKeyKeywordsBn
        }

        let updatedData = { ...data, meta_datum_attributes, banners_file: newBannerImages }
        delete updatedData.logo
        delete updatedData.banner
        delete updatedData.branding_image

        setIsSubmitting(true)
        serializeAndUpdate('brands', 'brand', id, updatedData)
            .then(res => {
                if (res.success) {
                    toast.success("Brand updated successfully")
                    history.push('/brands/list')
                } else {
                    toast.error(res?.message)
                }
                setIsSubmitting(false)
            }).catch(err => {
                toast.error(err)
                setIsSubmitting(false)
            })
    }

    useEffect(() => {
        getDetails('brands', id)
            .then(res => {
                if (res.success) {
                    const brandDetails = {
                        id: res.data.id,
                        name: res.data.name,
                        slug: res.data.slug,
                        bn_name: res.data.bn_name,
                        logo: res.data.logo_file,
                        banner: res.data.banner_file,
                        brand_info_visible: res.data.brand_info_visible,
                        branding_image: res.data.branding_image_file,
                        is_own_brand: res.data.is_own_brand,
                        business_type: res.data.business_type,
                        branding_layout: res.data.branding_layout,
                        branding_promotion_with: res.data.branding_promotion_with,
                        branding_video_url: res.data.branding_video_url,
                        branding_title: res.data.branding_title,
                        branding_title_bn: res.data.branding_title_bn,
                        branding_subtitle: res.data.branding_subtitle,
                        branding_subtitle_bn: res.data.branding_subtitle_bn,
                        short_description: res.data.short_description,
                        short_description_bn: res.data.short_description_bn,
                        more_info_button_text: res.data.more_info_button_text,
                        more_info_button_text_bn: res.data.more_info_button_text_bn,
                        more_info_url: res.data.more_info_url,
                        campaigns_attributes: res.data.campaigns_attributes.map((item) => {
                            return ({
                                id: item?.id,
                                page_url: item?.page_url,
                                title: item?.title,
                                title_bn: item?.title_bn
                            })
                        }),
                        filtering_options_attributes: res.data.filtering_options_attributes,
                        public_visibility: res.data.public_visibility,
                        homepage_visibility: res.data.homepage_visibility,
                        redirect_url: res.data.redirect_url,
                    }
                    const metaData = {
                        metaTitle: res?.data?.meta_info?.meta_title,
                        metaTitleBn: res?.data?.meta_info?.bn_meta_title,
                        metaDescription: res?.data?.meta_info?.meta_description,
                        metaDescriptionBn: res?.data?.meta_info?.bn_meta_description,
                        metaKeywords: res?.data?.meta_info?.meta_keyword,
                        metaKeyKeywordsBn: res?.data?.meta_info?.bn_meta_keyword,
                    }
                    setMetaInfo(metaData)
                    setBrand(brandDetails)
                    setExistingBannerImages(res?.data?.banners)
                }
            })
    }, [id])


    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Edit Brand</h2>
                <div className="ml-auto">
                    <Link to={`${process.env.PUBLIC_URL}/brands/list`}
                        className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to Brand List</Link>
                </div>
            </div>
            <div>
                <BrandForm
                    details={brand}
                    existingBannerImages={existingBannerImages}
                    setExistingBannerImages={setExistingBannerImages}
                    setNewBannerImages={setNewBannerImages}
                    metaInfo={metaInfo}
                    setMetaInfo={setMetaInfo}
                    handleSubmit={onSubmit}
                    createForm={false}
                    isSubmitting={isSubmitting}
                />
            </div>
        </App>
    )
}

export default Edit
