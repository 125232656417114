import React, {Component} from 'react';
import App from "../../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEdit, FiPlus } from "react-icons/all";
import {withRouter, Link} from "react-router-dom";
import "../../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {toast} from 'react-toastify';
import ConfirmationModal from "../../common/Modal";
import { destroy } from '../../../services/baseServices';
import {getSliderList} from "../../../services/sliderServices";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      formData: {image: ''},
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
    };
  }

  getData = () => {
    getSliderList()
      .then(res => {
        this.setState({items: res})
      })
      .catch(errMsg => toast.error(errMsg))
  }

  componentDidMount() {
    this.getData();
  }

  handleDeleteSlider = (id) => {
    destroy("slides", id)
      .then(res => {
        if(res.status_code === 200){
          toast.success('Successfully deleted');
          this.getData();
        } else {
            toast.error(res?.message)
        }
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  render() {
    // noinspection RequiredAttributes
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Common Slider List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/common-slider/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add
                Slider</Link>
            </div>
          </div>
          <div className="card-block">
            <div className="block-body">
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Link Url</th>
                  <th>Img Type</th>
                  <th>Body</th>
                  <th>Position</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {this.state.items.length > 0 && this.state.items.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.image !== null ?
                        <img src={item.image} alt="" style={{width: "40px", height: "40px"}}/> : ''}</td>
                      <td>
                        <div className="order-item">{item.name}</div>
                      </td>
                      <td className='text-break'>{item.link_url}</td>
                      <td>{item.img_type}</td>
                      <td className='text-break'>{item.body}</td>
                      <td>{item.position}</td>
                      <td style={{width: '150px'}}>
                        <div className="btn-group">
                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>
                                Edit
                              </Tooltip>
                            }
                          >
                          <Link to={`${process.env.PUBLIC_URL}/common-slider/edit/${item.id}`}
                            className="btn btn-sm btn-default"><FiEdit/></Link>
                          </OverlayTrigger>
                            <OverlayTrigger
                              key="view"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-view`}>
                                  Delete
                                </Tooltip>
                              }
                            >
                            <ConfirmationModal title="Delete" body="Are you sure you want to delete?"  handleAction={() =>this.handleDeleteSlider(item.id)}/>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
