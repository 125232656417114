import { serialize } from 'object-to-formdata';
import moment from 'moment'
import * as yup from 'yup'

export const getObjectToFormData = (data) => {
  const formData = serialize(data);
  return formData;
}

export const numEnToBn = (str) => {
  str = str.toString()
  let bnNum = {
    0: '০',
    1: '১',
    2: '২',
    3: '৩',
    4: '৪',
    5: '৫',
    6: '৬',
    7: '৭',
    8: '৮',
    9: '৯'
  }
  for (let x in bnNum) {
    str = str.replace(new RegExp(x, 'g'), bnNum[x])
  }
  return str
}

export const objectToFormData = (obj) => {
  let formData = new FormData();
  Object.keys(obj).forEach(key => formData.append(key, obj[key]));
  return formData;
};

export const percentCompleted = (progressEvent) => {
  return Math.round((progressEvent.loaded * 100) / progressEvent.total)
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

export const getAuthToken = () => {
  const authData = JSON.parse(localStorage.getItem('authData'));
  return authData.message?.token
}
export const getLocalAuthData = () => {
  return JSON.parse(localStorage.getItem('userToken'));
}
export const getLocalAuthToken = () => {
  return JSON.parse(localStorage.getItem('authData'));
}

export const urlPramsToObj = (history) => {
  const search = history.location.search.substring(1);
  return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}

export const twoDecimalPlace = (num) => {
  return(Math.round(num * 100) / 100).toFixed(2);
}

export const objToUrlPrams = (obj) => {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const getWarehouseType = () => {
  return getLocalAuthToken() ? getLocalAuthToken().message?.warehouse_type : '';
}

export const getWarehouseName = () => {
  return getLocalAuthToken() ? getLocalAuthToken().message?.warehouse_name : '';
}

export const getUserName = () => {
  return getLocalAuthToken() ? getLocalAuthToken().message?.user_name : '';
}

export const getBusinessType = () => {
  return getLocalAuthToken() ? getLocalAuthToken().message?.business_type : '';
}

export const convertDigitToWord = (num) => {
  const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  return str;
}

export const getDate = (date) => {
  return moment(date).utcOffset('+0600');
}

export const getFormattedDate = (date, format) => {
  return date ? moment(date).utcOffset('+0600').format(format): '';
}

export const refreshPage = (url, callback) => {
  setTimeout(() => {
    callback()
    window.location.replace(url)
  },1000)
}

export const getDimension = async(file, acceptedFormats) =>{
  let reader = new FileReader();
  if(!file || !acceptedFormats.includes(file.type)){
    return new Promise((resolve, reject)=>{resolve({width:0, height:0})})}
  else {
    return new Promise((resolve, reject)=>{ 
      reader.onerror = () =>{
        reader.abort()
        reject(new DOMException('Problem parsing input file'))
      }
      reader.onload = () =>{
        let image = new Image()
        image.src = reader.result;
        image.onload = function(){
          resolve({width: this.width, height:this.height})
        }
      }
      if(file && acceptedFormats.includes(file.type)){
        reader.readAsDataURL(file)
      }else{
        resolve({width:0, height:0})
      }
  
    })
  }
}

export const getSchema = ()=>{
    const FILE_SIZE = 160*1024;
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg','image/png']
    const schema = yup.object().shape({
    name: yup.string().required('Title is required field'),
    logo: yup.mixed()
    .test("Required", "Logo is a required field", (value) => {
      return !value || value.length > 0;
    })
    .test('FileSize', 'Uploaded file is to big, image size not more than 150kb.', (value) =>{
      return  value === null || (value && value[0]?.size <=FILE_SIZE) 
    }).test('type', 'Invalid image format, supported image format are .jpg, .jpeg and .png',(value) =>{
      return value === null || (value && SUPPORTED_FORMATS.includes(value[0]?.type))
    })
    .test('LogoDimension', 'Logo must be 100 x 100 (width x height)', async (value) =>{
      let sizes = await getDimension(value[0])
      let result = (sizes.width <= 900 && sizes.height <= 900)
      return result
    
    }),
    banner: yup.mixed()
    .test("Required", "Banner is a required field", (value) => {
      return !value || value.length > 0;
    })
    .test('FileSize', 'Uploaded file is to big, image size not more than 150kb.', (value) =>{
      return  value === null || (value && value[0]?.size <=FILE_SIZE) 
    }).test('type', 'Invalid image format, supported image format:(.jpg, .jpeg and .png)',(value) =>{
      return value === null || (value && SUPPORTED_FORMATS.includes(value[0]?.type))
    })
    .test('LogoDimension', 'Banner must be 300 x 100 (width x height)', async (value) =>{
      let sizes = await getDimension(value[0])
      let result = (sizes.width <= 900 && sizes.height <= 900)
      return result
    
    })
});
return schema
}

export const humanize = (str) => {
  let humanizedStr = ''
  if(str) {
    humanizedStr = str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
  }
  return humanizedStr;
}

export const convertToSlug = (str) => {
  if(str) {
    //replace all special characters | symbols with a space
    str = str.replace(/[`~!@#$%^&*()_\+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
            .toLowerCase();
      
    // trim spaces at start and end of string
    str = str.replace(/^\s+|\s+$/gm,'');
      
    // replace space with dash/hyphen
    str = str.replace(/\s+/g, '-');
    
    return str;
  } else {
    return ''
  }
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
export function isNullOrEmpty(value) {
  return value === null || value === undefined || value === "";
}

export const trimToTwoDecimals = (value) => {
  return parseFloat(Number(value)).toFixed(2);
};

export const convertToCSV = (data) => {
  const rows = [];

  if (Array.isArray(data) && data.length > 0) {
    const headers = Object.keys(data[0]);
    rows.push(headers.join(","));

    data.forEach((item) => {
      const values = headers.map((header) => item[header]);
      rows.push(values.join(","));
    });
  }

  return rows.join("\n");
};