import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import { getDetails } from "../../services/baseServices";
import { getWarehouseType, getFormattedDate } from "../../helper/utils";
import ExportCSVAsync from "../common/ExportCSVAsync";

const headers = [
  { label: "Variant Id", key: "variant_id" },
  { label: "Consumer discount", key: "consumer_discount" },
];

const loadData = (item) => {
  return {
    variant_id: item.variant_id,
    consumer_discount: item.consumer_discount
  };
};

class Details extends Component {
  state = {
    promotion: {},
    promotion_variants: {},
  };

  componentDidMount() {
    getDetails("flash_sales", this.props.match.params.id)
      .then((res) => {
        this.setState({
          promotion: res.data,
          //   promotion_variants: this.groupBy(res.promotion_variants, "state"),
        });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
  }

  render() {
    const { promotion } = this.state;

    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="page-title" />
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/flashSale/list`}
                className={`btn btn-sm btn-link`}>
                <MdArrowBack /> Back to List
              </Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Flash Sale Details</h4>
              <div className="ml-auto">
                <ExportCSVAsync
                  fileName={
                    "Flash-sale" +
                    getFormattedDate(new Date(), "DD-MM-YYYY") +
                    ".csv"
                  }
                  Url={`flash_sales/${this.props.match.params.id}/export_variants`}
                  headers={headers}
                  loadData={loadData}
                  title="Flash sale Details"
                />
              </div>
            </div>
            <div className="block-body">
              <>
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>Title</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{promotion.title}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Bangla Title</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{promotion.title_bn}</td>
                    </tr>
                    {/* <tr>
                      <td style={{ width: "250px" }}>Warehouse</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {promotion.warehouse_name}
                      </td>
                    </tr> */}
                    <tr>
                      <td style={{ width: "250px" }}>Applicable From</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {getFormattedDate(promotion.from_date, "DD/MM/YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Applicable To</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {getFormattedDate(promotion.to_date, "DD/MM/YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {promotion.is_active ? "Active" : "Inactive"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Start Time</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{promotion.start_time}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>End Time</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{promotion.end_time}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Created By</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{promotion.created_by?.name}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);
