import { useState } from "react";
import { Button } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";

function OrderDetailsDynamicForm({
  register,
  control,
  watch,
  limit,
  name: componentName,
  quantity,
  locations,
  QrCode,
}) {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: componentName,
  });
  const [error, setError] = useState("");

  const handleQtyChange = (e, index) => {
    setError("");
    const updatedData = watch(componentName);

    if (
      countTotal2() - updatedData[index].qtn + parseInt(e) <= limit &&
      parseInt(e) <= limit &&
      parseInt(e) > 0 &&
      parseInt(e) <=
        locations?.find((i) => i.id == updatedData[index].locqtn)?.quantity
    ) {
      setError("");
      replace(updatedData);
    } else {
      setError("Total Quantity can not be more than Quantity");
    }
  };

  const handleLocationChange = (e, index) => {
    setError("");
    const updatedData = watch(componentName);

    if (locations?.find((i) => i.id == e)?.quantity >= updatedData[index].qtn) {
      replace(updatedData);
    } else {
      setError("Locations Quantity should be greater than Quantity");
    }
  };
  const handleQRCodeChange = (e, index) => {
    setError("");
    if (e !== QrCode) {
      setError("Please enter valid QR code.");
    } else {
      setError("");
    }
  };

  const countTotal2 = () => {
    const updatedData = watch(componentName);
    const totalCount = updatedData?.reduce(
      (totalCount, item) => parseInt(totalCount) + parseInt(item.qtn),
      0
    );
    return totalCount;
  };

  const checkAvailability = () => {
    return countTotal() < limit ? true : false;
  };
  const countTotal = () => {
    const totalCount = fields?.reduce(
      (totalCount, item) => parseInt(totalCount) + parseInt(item.qtn),
      0
    );
    return totalCount;
  };

  return (
    <tr>
      <th className="text-center">
        Quantity
        <br />
        {quantity}
        <br />
        {limit > 1 && (
          <Button
            onClick={() => {
              append({ qtn: 1, qrcode: "", locqtn: "" });
            }}
            variant={"primary"}
            size={"sm"}
            disabled={!checkAvailability()}
            className={`${!checkAvailability() ? "cursor-not-allowed " : ""}
                        text-gray-900
                        `}
          >
            Add
          </Button>
        )}
      </th>
      <td className="py-4 px-6 ">
        {fields.map((item, index) => {
          {
            console.log("items----", item);
          }
          return (
            <li className="d-flex" key={item.id}>
              <select
                className="border text-sm rounded-lg p-2"
                value={item.locqtn}
                {...register(`${componentName}.${index}.locqtn`, {
                  onChange: (e) => handleLocationChange(e.target.value, index),
                })}
              >
                <option value="">--Select Location--</option>
                {locations.map((loc) => (
                  <option
                    value={loc.id}
                    disabled={
                      loc.id != item.locqtn &&
                      fields?.some((i) => i.locqtn == loc.id)
                    }
                  >
                    {" "}
                    {loc.code}({loc.quantity})
                  </option>
                ))}
              </select>
              <input
                type="number"
                style={{ width: "70px" }}
                className="mx-2 border text-sm rounded-lg"
                {...register(`${componentName}.${index}.qtn`, {
                  onChange: (e) => handleQtyChange(e.target.value, index),
                })}
              />
              <input
                className=" border text-sm rounded-lg"
                defaultValue={item.qrcode}
                placeholder="QR Code ..."
                {...register(`${componentName}.${index}.qrcode`, {
                  onChange: (e) => handleQRCodeChange(e.target.value, index),
                })}
              />

              {fields.length > 1 && (
                <Button
                  size="sm"
                  className="m-2"
                  type={"button"}
                  onClick={() => fields.length > 1 && remove(index)}
                  variant="danger"
                >
                  {" "}
                  Remove
                </Button>
              )}
            </li>
          );
        })}
        <span style={{ color: "red" }}>{error}</span>
      </td>
    </tr>
  );
}

export default OrderDetailsDynamicForm;
