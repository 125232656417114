import MetaForm from "./MetaForm";
import App from "../../App";
import {AiOutlineArrowLeft} from 'react-icons/all'
import {useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import { getDetails } from "../../services/baseServices";

const Edit = () => {
    const { id } = useParams()
    const [metaDetails, setMetaDetails] = useState({})
  
    useEffect(() =>{
      getDetails('static_pages', id)
      .then((res) => {
        if (res?.success) {
            setMetaDetails(res.data);
        }
      });    
    }, [id])
  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Edit Meta Info</h2>
        <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/meta-info/list`}
              className="btn btn-sm btn-link mr-2"
            >
              <AiOutlineArrowLeft /> Back to List
            </Link>
          </div>
      </div>
      <MetaForm metaDetails={metaDetails}/>
    </App>
  );
};

export default Edit;
