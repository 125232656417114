import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Button, Modal } from "react-bootstrap";
import FormControl from "../../components/controls/FormControl";
import { putData } from "../../services/baseServices";
import { toast } from "react-toastify";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"

const GerbageModal = ({ garbage_details, show, handleClose }) => {
  const [submitting, setSubmitting] = useState(false)
  useEffect(()=> {
    if (show) setSubmitting(false)
  }, [show])  
  const validationSchema = yup.object().shape({
    gerbage_qunatity: yup
      .number()
      .required("Garbage quantity is required field")
      .min(1, "Garbage quantity must be greater than or equal to 1")
      .max(
        garbage_details.remaining_blocked_quantity,
        `Garbage qunatity must be less than or equal to ${garbage_details.remaining_blocked_quantity}`
      )
      .test(
        "is-decimal",
        "decimal value is not allowed",
        (value) => !(value + "").match(/^\d*\.{1}\d*$/)
      ),
  });

  const initialValues = {
    gerbage_qunatity: "",
  };

  const debouncedOnGarbage = _.debounce((values)=>{
    handleGarbageSubmit(values)
  }, DEBOUNCE_TIME);

  const handleWithDebounceGarbageSubmit = (values) => {
    setSubmitting(true)
    debouncedOnGarbage(values);
  }

  const handleGarbageSubmit = (values) => {
    setSubmitting(true)
    const params = {
      quantity: values.gerbage_qunatity,
    };
    putData(`blocked_items/garbage/${garbage_details.id}`, params)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success(res.message);
          handleClose(true);
        } else {
          toast.error(res.message);
          setSubmitting(false)
        }
      })
      .catch((err) => {
        toast.error(err);
        setSubmitting(false)
      });
  };
  return (
    <div>
      <Modal show={show} onHide={()=>handleClose(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="ml-3">Garbage Product Details</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleWithDebounceGarbageSubmit}
        >
          {(formik) => (
            <Form>
              <Modal.Body className="p-5">
                <FormControl 
                  control="input"
                  type="number"
                  name="gerbage_qunatity"
                  label="Garbage quantity"
                  required="*"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=> handleClose(false)}>
                  Close
                </Button>
                <Button variant="primary" type="submit" disabled={submitting}>
                  Garbage
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default GerbageModal;
