import React, {Component} from 'react';
import '../assets/scss/header.scss';
import {Link, withRouter} from "react-router-dom";
import logo from "../assets/img/logo.svg";
import blankAvatar from "../assets/img/blank-avatar.png";
import {FiLogOut, FiMenu, FiUser} from "react-icons/all";
import {connect} from "react-redux";
import {handleToggleNavbar, logout} from "../redux";
import {getWarehouseName, getWarehouseType, getUserName} from "../helper/utils";

const warehouseName = getWarehouseName();
const warehouseType = getWarehouseType();

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowingAccountDropdown: false,
            wh_name: warehouseName,
            wh_type: warehouseType === 'central' ? 'Central' : 'Distribution'
        }
    }

    handleToggleAccountDropdown = () => {
        this.setState({isShowingAccountDropdown: !this.state.isShowingAccountDropdown})
    };

    logout = () => {
        localStorage.clear();
        window.location.replace('/login')
    };

    render() {
        return (
            <div className={this.props.navbar.isNavbarShowing ? 'header' : 'header collapsed-sidebar'}>
                <div className="header-brand">
                    <Link to="/" className="brand-logo"><img src={logo} alt="" style={{height: "30px"}}/></Link>
                </div>

                <div className="header-content">
                    <div className="layout-actions">
                        <button
                            onClick={() => this.props.handleNavbarToggle()}
                            className="sidebar-toggle"><FiMenu/></button>

                        <Link to="/" className="brand-logo"><img src={logo} alt="" style={{height: "30px"}}/></Link>
                    </div>
                    <div>
                        <strong>
                            <span className="wh-name">{this.state.wh_name}</span>
                            {/* <span className="wh-type">{ this.state.wh_type }</span> */}
                        </strong>
                    </div>
                    <div>
                        <div className="mini-account">
                            <button type="button"
                                    onClick={() => this.handleToggleAccountDropdown()}
                                    className="account-user">
                                <div className="user-avatar">
                                    <img src={blankAvatar} alt=""/>
                                </div>
                                <div className="user-name">{getUserName()}</div>
                            </button>

                            {this.state.isShowingAccountDropdown &&
                            <div className="account-dropdown">
                                {
                                    <Link to="/profile/settings" className="dropdown-link"><span className="icon"><FiUser/></span> <span
                                        className="text">Profile Settings</span></Link>}
                                <span
                                    onClick={() => this.logout(() => {
                                        this.props.history.push('/login');
                                    })}
                                    className="dropdown-link"><span className="icon"><FiLogOut/></span> <span
                                    className="text">Logout</span></span>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        navbar: state.navbar
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // logout: cb => dispatch(logout(cb)),
        handleNavbarToggle: () => dispatch(handleToggleNavbar())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
