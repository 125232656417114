import React, { useEffect, useState } from "react";
import agamiLogo from "../../assets/img/agamiLogo.png";
import agamiAddress from "../../assets/img/agamiAddress.png";
import moment from "moment";
import { getData } from "../../services";
import { trimToTwoDecimals } from "../../helper/utils";
import { toast } from "react-toastify";
const StoInvoice = React.forwardRef((props, ref) => {
  const { stoId } = props;
  const [data, setData] = useState({});

  useEffect(() => {
    getData(`sto_invoices/print/${stoId}`).then((res) => {
      if (res?.status === 200) {
        setData(res?.data ?? {});
      }
    }).catch((err) => { 
      toast.error("Failed to fetch data");
    });
    return () => {
      setData({});
    };
  }, [stoId, ref]);
  return (
    <div
      ref={ref}
      style={{
        padding: "50px 50px 0px 50px",
      }}
    >
      <div className="d-flex justify-content-between my-0">
        <img
          src={agamiLogo}
          alt="agamiLogo"
          className="my-auto"
          style={{
            width: "319px",
            height: "111px",
          }}
        />
        <img
          src={agamiAddress}
          alt="agamiLogo"
          className="my-auto ml-auto"
          style={{
            width: "221px",
            height: "140px",
            marginRight: "-35px",
          }}
        />
      </div>
      <h5 className="text-left my-2 sto_invoice-invoice-header">INVOICE</h5>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column sto_invoice-customer-info">
          <h6>Customer:</h6>
          <p>{data?.customer_name}</p>
          <p className="customer-address">{data?.customer_address}</p>
        </div>
        <div
          style={{
            marginRight: "11.3%",
          }}
          className="d-flex flex-column sto_invoice-delivery-address-info"
        >
          <h6>Delivery Address:</h6>
          <p>{data?.delivery_name}</p>
          <p className="customer-address">{data?.delivery_address}</p>
        </div>
      </div>
      <div className="d-flex justify-content-between my-3">
        <div className="d-flex flex-column sto_invoice-invoice-info">
          <div className="d-flex justify-content-between my-2">
            <h6>Invoice No:</h6>
            <p className="invocie-no">{data?.invoice_no}</p>
          </div>
          <div className="d-flex justify-content-between my-2">
            <h6 className="mr-4">Invoice Date:</h6>
            <p>
              {data?.invoice_date
                ? moment(data?.invoice_date).format("DD-MMM-YY")
                : "--"}
            </p>
          </div>
          <div className="d-flex justify-content-between my-2">
            <h6 className="mr-4">Delivery Date:</h6>
            <p>
              {data?.delivery_date
                ? moment(data?.delivery_date).format("DD-MMM-YY")
                : "--"}
            </p>
          </div>
        </div>
        <div
          className="d-flex flex-column sto_invoice-invoice-info"
          style={{
            width: "41.5%",
          }}
        >
          <div className="d-flex justify-content-between my-2">
            <h6 className="mr-5">Customer Ref:</h6>
            <p className="customer-ref">{data?.customer_ref}</p>
          </div>
          <div className="d-flex justify-content-between my-2">
            <h6
              className="mr-4"
              style={{
                whiteSpace: "nowrap",
                paddingRight: "5px",
              }}
            >
              Dispatch Location:
            </h6>
            <p className="mr-auto dispatch-loc">{data?.dispatch_loc}</p>
          </div>
          <div className="d-flex justify-content-between my-2">
            <h6 className="mr-4">Terms of Delivery:</h6>
            <p
              className="mr-auto"
              style={{
                paddingLeft: "7px",
              }}
            >
              {data?.delivery_terms}
            </p>
          </div>
          <div className="d-flex justify-content-between my-2">
            <h6 className="mr-5">Payment Terms:</h6>
            <p className="mr-auto">{data?.payment_term}</p>
          </div>
        </div>
      </div>
      <div className="line-tems">
        <table className="table table-bordered custom-table-sto_invoice">
          <thead>
            <tr>
              <th>Product No.</th>
              <th>Product Description</th>
              <th>Quantity</th>
              <th>UOM</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Vat</th>
              <th>Total (BDT)</th>
            </tr>
          </thead>
          <tbody>
            {data?.items && data?.items.length > 0
              ? data?.items.map((productData, index) => (
                  <tr key={index}>
                    <td>{productData?.product_id}</td>
                    <td>{productData?.product_title}</td>
                    <td>{productData?.quantity}</td>
                    <td>{productData?.unit_of_supply}</td>
                    <td>{trimToTwoDecimals(productData?.base_unit_price)}</td>
                    <td>{trimToTwoDecimals(productData?.total_price)}</td>
                    <td>{trimToTwoDecimals(productData?.vat_amount)}</td>
                    <td>
                      {trimToTwoDecimals(productData?.price_including_all)}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="7" className="font-weight-bold text-center">
                Total
              </td>
              <td>
                {data?.items_total?.price_including_all
                  ? trimToTwoDecimals(data?.items_total?.price_including_all)
                  : "0.00"}
              </td>
            </tr>
            <tr>
              <td colSpan="7" className="font-weight-bold text-center">
                Tax Deducted at Source
              </td>
              <td>
                {data?.items_total?.tax_deducted
                  ? trimToTwoDecimals(data?.items_total?.tax_deducted)
                  : "0.00"}
              </td>
            </tr>
            <tr>
              <td colSpan="7" className="font-weight-bold text-center">
                Discount
              </td>
              <td>-</td>
            </tr>
            <tr>
              <td colSpan="7" className="font-weight-bold text-center">
                Gross Payable Amount
              </td>
              <td>
                {data?.items_total?.gross_total
                  ? trimToTwoDecimals(data?.items_total?.gross_total)
                  : "0.00"}
              </td>
            </tr>
            <tr>
              <td colSpan="8" className="text-left">
                Amount Chargeable (In Words): <br></br>
                {data?.items_total?.gross_total_in_words}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div
        className="line-tems"
        style={{
          marginTop: "90px",
        }}
      >
        <table className="table table-bordered custom-table">
          <thead>
            <tr>
              <th
                style={{
                  width: "50%",
                  paddingBottom: "135px",
                  fontWeight: "bold",
                }}
              >
                Customer Seal & Signature
              </th>
              <th
                style={{
                  width: "50%",
                  paddingBottom: "135px",
                  fontWeight: "bold",
                }}
              >
                For Agami Distributions
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="page-footer">
        <h6 className="text-center">Page 1 of 1</h6>
      </div>
    </div>
  );
});

export default StoInvoice;
