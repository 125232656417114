import {Button, Col, Form, Row} from "react-bootstrap";
import {CommissionSchema} from "../../ValidationSchema/ConfigurationSchema"
import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {getConfigByKey, createOrUpdate} from "../../../redux/configuration/actions";
import {isEmpty, isObject, size} from 'lodash'

const FormCommission = () => {
    const dispatch = useDispatch();
    const initialFormValues = {
        partner: {
            organic_holding_fee: {
                value: 15,
                percent: false
            },
            induced_holding_fee: {
                value: 5,
                percent: true
            }
        }
    }
    const [formData, setFormData] = useState(initialFormValues);
    useEffect(() => {
        dispatch(getConfigByKey('configurations', (isSuccess, res) => {
            if (isSuccess && !isEmpty(res?.value_json)) {
                const value = JSON.parse(res.value_json);
                if (isObject(value) && size(value) > 0) {
                    setFormData({
                        ...formData,
                        ...value
                    })
                }
            } else {
                toast.error(res.message);
            }
        }))
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={CommissionSchema}
            onSubmit={(values) => {
                dispatch(createOrUpdate({
                    key: 'configurations',
                    user_type: 'warehouse',
                    value_json: values
                }, (isTrue, message) => {
                    if (isTrue) {
                        toast.success(message);
                    } else {
                        toast.error(message);
                    }
                }))
            }}
        >
            {(formik) => {
                const {
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isValid,
                    dirty,
                    touched
                } = formik;
                return (
                    <div className="card-block">
                        <div className="block-body">
                            <Form onSubmit={handleSubmit}>
                                <div>
                                    <h6>Partner Commission</h6>
                                    <hr/>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Organic Holding Fee <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7} className="d-flex">
                                            <div className="group-input-l-element">
                                                <Form.Control
                                                    type="text"
                                                    className={errors?.partner?.organic_holding_fee?.value ? 'is-invalid' : ''}
                                                    name="partner.organic_holding_fee.value"
                                                    value={values.partner.organic_holding_fee.value}
                                                    autoComplete="off"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.partner?.organic_holding_fee?.value}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="group-input-r-element default-page-bg">
                                                <Form.Control as="select"
                                                              className={errors?.partner?.organic_holding_fee?.percent ? 'is-invalid' : ''}
                                                              name="partner.organic_holding_fee.percent"
                                                              onBlur={handleBlur}
                                                              onChange={handleChange}
                                                              value={values.partner.organic_holding_fee.percent}
                                                >
                                                    <option value="false">Fixed Amount</option>
                                                    <option value="true">Percent Amount</option>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.partner?.organic_holding_fee?.percent}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Induced Holding Fee <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7} className="d-flex">
                                            <div className="group-input-l-element">
                                                <Form.Control
                                                    type="text"
                                                    className={errors?.partner?.induced_holding_fee?.value ? 'is-invalid' : ''}
                                                    name="partner.induced_holding_fee.value"
                                                    value={values.partner.induced_holding_fee.value}
                                                    autoComplete="off"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.partner?.induced_holding_fee?.value}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="group-input-r-element default-page-bg">
                                                <Form.Control as="select"
                                                              className={errors?.partner?.induced_holding_fee?.percent ? 'is-invalid' : ''}
                                                              name="partner.induced_holding_fee.percent"
                                                              onBlur={handleBlur}
                                                              onChange={handleChange}
                                                              value={values.partner.induced_holding_fee.percent}
                                                >
                                                    <option value="false">Fixed Amount</option>
                                                    <option value="true">Percent Amount</option>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.partner?.induced_holding_fee?.percent}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <Form.Group as={Row}>
                                    <Col md={10}>
                                        <div className="mt-5 d-flex justify-content-end">
                                            <Button
                                                size="btn-sm"
                                                type={"submit"}
                                                variant="primary"
                                                disabled={!(dirty && isValid)}
                                            >
                                                Save Change
                                            </Button>
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default FormCommission;
