import React, { Component } from "react";
import App from "../../App";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { updatePOStatus } from "../../services/purchaseOrderService";
import { getDetails, getList } from "../../services/baseServices";
import { FaLongArrowAltLeft, FaPrint, FiPlus } from "react-icons/all";
import { getDate, humanize } from "../../helper/utils";
import ExportCSV from "../common/ExportCSV";
import DhBoxingItem from "./DhBoxingItem";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import AddDepositForm from "./AddDepositForm";
import StoInvoice from "./StoInvoice";

const headers = [
  { label: "Product", key: "product_title" },
  { label: "Unit Price", key: "price" },
  { label: "Total Price", key: "total_price" },
  { label: "Ordered", key: "quantity" },
  { label: "Sent", key: "sent_quantity" },
  { label: "Received", key: "received_quantity" },
  { label: "Passed", key: "qc_passed" },
  { label: "Quality Failed", key: "quality_failed" },
  { label: "Quantity Failed", key: "quantity_failed" },
  { label: "QC Status", key: "qc_status" },
];
class DhDetails extends Component {
  constructor() {
    super();
    this.state = {
      locations: [],
      purchaseOrder: {},
      boxes: [],
      selectedRow: 0,
      isLoading: true,
      packedStatus: false,
      orderPlaced: false,
      readyToShip: false,
      inTransit: false,
      receivedToDh: false,
      dispatchStatus: false,
      selectedLocation: null,
      openPackOptions: false,
      packedItems: {},
      dataForExport: [],
      isModalOpen: false,
    };
  }

  handlePrint = () => {
    const originalTitle = document.title;
    const stoNumber = this.props.match.params.id;
    document.title = `Sto-Invoice-${stoNumber}.pdf`;
    setTimeout(() => {
      document.title = originalTitle;
    }, 1000);
  };

  toggleModalState = () => {
    this.setState((prevState) => {
      return { isModalOpen: !prevState.isModalOpen };
    });
  };

  changeStatus = (order) => {
    switch (order.order_status) {
      case "Order placed":
        this.setState({
          orderPlaced: true,
          readyToShip: false,
          inTransit: false,
          receivedToDh: false,
        });
        return;
      case "Ready to ship":
        this.setState({
          orderPlaced: false,
          readyToShip: true,
          inTransit: false,
          receivedToDh: false,
        });
        return;
      case "In transit":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: true,
          receivedToDh: false,
        });
        return;
      case "Received":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: false,
          receivedToDh: true,
        });
        return;
      default:
        return;
    }
  };
  componentDidMount() {
    this.getPurchaseOrder();

    getList("locations")
      .then((res) => {
        this.setState({ locations: res });
      })
      .catch((errMsg) => {});
  }
  getPurchaseOrder = () => {
    this.setState({ order_id: this.props.match.params.id });
    getDetails("dh_purchase_orders", this.props.match.params.id)
      .then((res) => {
        let order = { ...res };
        this.setState({ purchaseOrder: order.wh_purchase_order });
        let LineItems = res.wh_purchase_order.line_items?.map((item) => {
          let obj = { ...item };
          if (obj.qc_status) obj.qc_status = "Done";
          else obj.qc_status = "Not Done";
          return obj;
        });
        this.setState({ dataForExport: LineItems });
        this.setState({ boxes: order.wh_purchase_order?.boxes });
        this.changeStatus(order.wh_purchase_order);
        this.setState({ isLoading: false });
      })
      .catch((errMsg) => {});
  };

  debouncedOnChangeStatus = _.debounce((url) => {
    this.updateStatus(url);
  }, DEBOUNCE_TIME);

  handleWithDebounceChangeStatus = async (url) => {
    this.debouncedOnChangeStatus(url);
  };

  updateStatus = (url) => {
    updatePOStatus(this.props.match.params.id, url)
      .then((res) => {
        if (!res.status_code) {
          let order = { ...res };
          this.setState({ purchaseOrder: order.wh_purchase_order });
          this.changeStatus(order.wh_purchase_order);
          toast.success("Successfully done " + humanize(url));
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {});
  };

  updateBoxList = (box_id, lineItem) => {
    let boxList = [...this.state.boxes];

    if (box_id && lineItem.id) {
      let selectedBoxIndex = boxList.findIndex((item) => item.id === box_id);
      let selectedBox = boxList[selectedBoxIndex];
      let boxLineItems = [...selectedBox.line_items];
      let boxLineItemIndex = boxLineItems.findIndex(
        (item) => item.id === lineItem.id
      );
      boxLineItems[boxLineItemIndex] = lineItem;
      selectedBox["line_items"] = boxLineItems;
      boxList[selectedBoxIndex] = selectedBox;
    }
    this.setState({ boxes: boxList });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., send data to the server)
    console.log("Submit Here");
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="text-left">
              {this.state.inTransit && (
                <Button
                  size="sm"
                  type={"button"}
                  className="mr-1"
                  onClick={() => {
                    this.handleWithDebounceChangeStatus("received_to_dh");
                  }}
                  variant="info"
                >
                  Receive
                </Button>
              )}
            </div>
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/dhPurchaseOrder/list`}
                className="btn btn-sm btn-link"
              >
                <FaLongArrowAltLeft /> Back to List
              </Link>
            </div>
          </div>
          {this.state.isLoading ? (
            <div className={`card-block`}>
              <div className="block-body">Loading...</div>
            </div>
          ) : (
            <div className="card-block">
              <div className="block-header">
                <h5 className="card-title">Stock Transfer Order Details</h5>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <a href="#">
                        <Button
                          variant={"default"}
                          onClick={this.handlePrint}
                          size={"sm"}
                        >
                          <FaPrint /> Print Invoice
                        </Button>
                      </a>
                    );
                  }}
                  content={() => this.componentRef}
                />

                <div className="only-print">
                  <StoInvoice
                    ref={(el) => (this.componentRef = el)}
                    stoId={this.props.match.params.id}
                  />
                </div>
              </div>
              <div className="block-body d-flex">
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>STO ID</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state?.purchaseOrder?.id}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Creation Date</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          getDate(this.state.purchaseOrder.order_date).format(
                            "Do MMMM YYYY"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>TIN</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{this.state.purchaseOrder?.tin ?? "--"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>BIN</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{this.state.purchaseOrder?.bin ?? "--"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>NID Number</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{this.state.purchaseOrder?.nid ?? "--"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Quantity</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder.quantity}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>QC Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                        this.state.purchaseOrder.qc_status
                          ? "Done"
                          : "Not Done"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                        this.state.purchaseOrder.order_status
                          ? this.state.purchaseOrder.order_status
                          : "Unknown"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Total Price</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder.total_price}
                      </td>
                    </tr>
                    {this.state.purchaseOrder?.business_type === "multicat" ? (
                      <>
                        <tr>
                          <td style={{ width: "250px" }}>Wallet Payment</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>
                            {this.state.purchaseOrder &&
                              this.state.purchaseOrder?.payments?.from_wallet}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Bank Deposit</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>
                            {this.state.purchaseOrder &&
                              this.state.purchaseOrder?.payments?.total_deposit}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Total Due</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>
                            {this.state.purchaseOrder &&
                              this.state.purchaseOrder?.payments?.due}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Payment Status</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td
                            style={{
                              color:
                                this.state.purchaseOrder &&
                                this.state.purchaseOrder?.payments?.status ===
                                  "paid"
                                  ? "green"
                                  : this.state.purchaseOrder?.payments
                                      ?.status === "partial"
                                  ? "orange"
                                  : "red",
                            }}
                          >
                            {this.state.purchaseOrder &&
                              this.state.purchaseOrder?.payments?.status?.toUpperCase()}
                          </td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <>
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Bank Deposit Details</h4>
                <div className="ml-auto">
                  <button
                    type="button"
                    className="btn  btn-sm btn-secondary ml-2"
                    onClick={() => {
                      this.setState({ isModalOpen: true });
                    }}
                  >
                    <FiPlus /> Add Deposit
                  </button>
                </div>
              </div>
              {this.state.isLoading ? (
                <div className={`card-block`}>
                  <div className="block-body">Loading...</div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <table className="order-table table table-striped table-hover">
                      <thead>
                        <tr>
                          <th style={{ width: "20px" }}>#</th>
                          <th>Deposit Amount</th>
                          <th>Deposit No</th>
                          <th>Deposit Slip</th>
                          <th>Uploaded On</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.purchaseOrder?.deposits.map(
                          (item, index) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{item?.amount}</td>
                              <td>{item?.deposit_no}</td>
                              <td>
                                <a
                                  href={item?.slip}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={item?.slip}
                                    alt="slip"
                                    style={{ width: "70px", height: "70px" }}
                                  />
                                </a>
                              </td>
                              <td>{item?.uploaded_on}</td>
                              <td>
                                {item?.is_deposited ? (
                                  <Alert bg="success">
                                    <strong>Received</strong>
                                  </Alert>
                                ) : (
                                  <Alert variant="warning">
                                    <strong>Submitted</strong>
                                  </Alert>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </>

          {this.state.isModalOpen ? (
            <AddDepositForm
              isModalOpen={this.state.isModalOpen}
              toggleModalState={this.toggleModalState}
              stoId={this.state?.purchaseOrder?.id}
              getPurchaseOrder={this.getPurchaseOrder}
            />
          ) : null}

          {this.state.purchaseOrder?.line_items?.length > 0 && (
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Stock Transfer Order Items</h4>
                <div className="ml-auto">
                  <ExportCSV
                    data={this.state.dataForExport}
                    headers={headers}
                    title="Stock Transfer Order Items"
                  />
                </div>
              </div>
              {this.state.isLoading ? (
                <div className={`card-block`}>
                  <div className="block-body">Loading...</div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <>
                      <Form
                        noValidate
                        validated={this.state.isFormValidated}
                        onSubmit={this.handlePackOrder}
                      >
                        <table className="order-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "20px" }}>#</th>
                              <th>Product</th>
                              <th>Unit Price</th>
                              <th>Total Price</th>
                              <th>Ordered</th>
                              <th>Sent</th>
                              <th>Received</th>
                              <th>Passed</th>
                              <th>Quality Failed</th>
                              <th>Quantity Failed</th>
                              <th>QC Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.purchaseOrder?.line_items?.map(
                              (orderItem, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      this.setState({ selectedRow: index })
                                    }
                                  >
                                    <td>{index + 1}</td>
                                    <td>
                                      <div className="order-item">
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/product/details/${orderItem.product_id}`}
                                        >
                                          {orderItem.product_title}
                                        </Link>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.price} BDT
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.total_price} BDT
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.quantity}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.sent_quantity}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.received_quantity}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.qc_passed}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.quality_failed}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.quantity_failed}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="order-item">
                                        {orderItem.qc_status
                                          ? "Done"
                                          : "Not Done"}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </Form>
                    </>
                  </div>
                </div>
              )}
            </div>
          )}

          {this.state.boxes?.length > 0 &&
            this.state.boxes?.map((item) => (
              <DhBoxingItem
                key={item.id}
                boxItem={item}
                po_id={this.state.purchaseOrder.id}
                receivedToDh={this.state.receivedToDh}
                locations={this.state.locations}
                getPurchaseOrder={this.getPurchaseOrder}
                updateBoxList={this.updateBoxList}
                headers={headers}
              />
            ))}
        </div>
      </App>
    );
  }
}

export default withRouter(DhDetails);
