import React, { Component } from 'react';
import App from "../../App";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getPurchaseOrderItems, assignLocationToPO } from "../../services/purchaseOrderService"
import { getData, getDetails, getList, updateData } from "../../services/baseServices";
import { FaLongArrowAltLeft, FaPrint } from "react-icons/all";
import ReactToPrint from "react-to-print";
import QRComponent from "../purchaseOrder/ProductQR";
import { getFormattedDate, getWarehouseType } from "../../helper/utils";
import ExportCSV from "../common/ExportCSV"
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"
import ConfirmationModal from '../common/Modal'
import PoDetailsModal from './PoDetailsModal';
const warehouseType = getWarehouseType();

const headers = [
  { label: "Product", key: "product_title" },
  { label: "Code by supplier", key: "code_by_supplier" },
  { label: "Unit Price", key: "price" },
  { label: "Total Price", key: "total_price" },
  { label: "Ordered", key: "due_quantity" },
  { label: "Received", key: "received_quantity" },
  { label: "Passed", key: "qc_passed" },
  { label: "Quality Failed", key: "quality_failed" },
  { label: "Quantity Failed", key: "quantity_failed" },
  { label: "QC Status", key: "qc_status" },
];

const defaultState = {
  purchaseOrderItems: [],
  purchaseOrder: null,
  selectedRow: 0,
  received_quantity: 0,
  passed_quantity: 0,
  variant_id: null,
  isShowingQRGenerationModal: false,
  isLoading: true,
  selectedStartItemID: 0,
  selectedEndItemID: 0,
  startItemID: 0,
  endItemID: 0,
  QRInitial: '',
  colspan: 4,
  setQR: false,
  qrSku: null,
  orderLocations: {},
  selectedLocation: null,
  dataForExport: [],
  showModal: false,
  modalData: []

}


class Details extends Component {
  constructor() {
    super();
    this.state = defaultState;
  };

  hideModalHandler = () => {
    this.setState({
      ...this.state,
      showModal: false
    })
  }
  // 2311

  showModalHandler = (id) => {
    getData(`line_items/${id}`)
      .then((res) => {
        console.log(res)
        this.state.modalData = res.data
        this.setState({
          ...this.state,
          showModal: !this.state.showModal
        })
      })
      .catch((error) => {
        console.log(error);
      })


  }
  getData = () => {
    this.setState(defaultState)
    getPurchaseOrderItems('/qcs/line_items_by_order?order_type=WhPurchaseOrder&order_id=', this.props.match.params.id)
      .then(res => {
        this.setState({ purchaseOrderItems: res })
        this.setState({ isLoading: false })
        let dataForExport = res.map(item => {
          let obj = { ...item }
          if (obj.qc_status)
            obj.qc_status = "Done"
          else
            obj.qc_status = "Not Done"
          return obj
        })
        this.setState({ dataForExport })
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })

    getDetails("wh_purchase_orders", this.props.match.params.id)
      .then(res => {
        let order = { ...res };
        this.setState({ purchaseOrder: order.wh_purchase_order })
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })
  }

  componentDidMount() {
    this.getData()

    getList("locations")
      .then(res => {
        this.setState({ locations: res })
      })
      .catch(errMsg => {
      })
  }

  setSelectedLocation = location_id => {
    this.setState({ selectedLocation: location_id });
    return this.state.selectedLocation;
  }

  setLineItemLocation = (index, e, order_item_id) => {
    this.setSelectedLocation(e.target.value);
    const orderLocations = { ...this.state.orderLocations };
    orderLocations[order_item_id] = e.target.value;
    this.setState({ orderLocations: orderLocations }, () => { });
  }

  debouncedOnAssignLocation = _.debounce((order_item_id) => {
    this.assignLocation(order_item_id)
  }, DEBOUNCE_TIME);

  handleWithDebounceAssignLocation = async (order_item_id) => {
    this.debouncedOnAssignLocation(order_item_id);
  }

  assignLocation = (order_item_id) => {
    if (!this.state.orderLocations[order_item_id]) {
      toast.error("Please select a location");
      return
    }
    const params = {
      line_item_id: order_item_id,
      location_id: this.state.orderLocations[order_item_id]
    }
    assignLocationToPO(params)
      .then(res => {
        if (res.id) {
          this.getData()
          // toast.success(res?.message);
          toast.success("Assigned location successfully.");
        }
        else {
          toast.error(res?.message);
        }
      })
      .catch(errMsg => {
        toast.error(errMsg);
      })
  }

  hideQRModal = () => {
    this.setState({ isShowingQRGenerationModal: false })
  }

  setQRSku = (sku) => {
    this.setState({ qrSku: sku })
  }

  openQRPrintModal = (quantity_recieved) => {
    this.setState({
      isShowingQRGenerationModal: true
    })
    this.setState({ endItem: quantity_recieved })
  }

  startItemChangeHandler = (event) => {
    this.setState({ selectedStartItemID: event.target.value })
  }

  endItemChangeHandler = (event) => {
    this.setState({ selectedEndItemID: event.target.value })
  }

  returnQRCode = (component) => {
    let tbody = [];
    let tr = [];
    for (let i = 1; i <= 100; i++) {
      tr.push(
        <td style={{ textAlign: "center", paddingBottom: "25px" }}>
          {component}
        </td>
      );
      if (i % 10 === 0 || i === 90) {
        tbody.push(<tr>{tr}</tr>);
        tr = [];
        console.log(tbody)
      }
    }
    return tbody;
  };

  debouncedOnReceive = _.debounce(() => {
    this.receivePO()
  }, DEBOUNCE_TIME);

  handleWithDebounceReceive = async () => {
    this.debouncedOnReceive();
  }

  receivePO = () => {
    updateData(`wh_purchase_orders/${this.props.match.params.id}/receive`)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success(res.message);
          this.getData()
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => { });
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            {
              this.state.purchaseOrder?.order_status === 'Order placed' &&
              <ConfirmationModal
                customBtn={true}
                variant='primary'
                size='sm'
                btnText='Receive'
                title="Receive"
                body="Are you sure to receive the PO?"
                handleAction={this.handleWithDebounceReceive}
              />
            }

            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/purchaseOrder/list`}
                className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to List</Link>
            </div>
          </div>

          {/*{this.state.setQR &&*/}
          {/*<div className="only-print" ref={el => (this.referenceContent = el)}>
            <br/>
            <br/>
            <br/>
            <br/>
            &nbsp;&nbsp;&nbsp;<QRCode value={`testing`}/>
            <br/>
          </div>*/}
          {/*}*/}

          <Modal show={this.state.isShowingQRGenerationModal} onHide={this.hideQRModal}>
            <Modal.Header closeButton>
              <Modal.Title>Select Item Range</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.submitChangeStatusForm}>
              <Modal.Body>
                <Form.Row>
                  <Form.Label column sm="3">
                    Start Item ID:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      name={"name"}
                      defaultValue={this.state.startItemID}
                      onChange={this.startItemChangeHandler}
                      max={this.state.selectedEndItemID}
                      min={this.state.startItemID}
                      required
                      type="number" />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Form.Label column sm="3">
                    End Item ID:
                  </Form.Label>
                  <Col sm="3" md={3} lg={3}>
                    <Form.Control
                      name={"name"}
                      defaultValue={this.state.endItemID}
                      onChange={this.endItemChangeHandler}
                      max={this.state.endItemID}
                      min={this.state.selectedStartItemID}
                      required
                      type="number" />
                  </Col>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="default" onClick={this.hideQRModal}>
                  Close
                </Button>
                <Button disabled={this.state.isSubmitting}
                  variant="primary" type={`submit`}>
                  {this.state.isSubmitting ? 'Submitting...' : 'Print QR'}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {
            this.state.isLoading ?
              <div className={`card-block`}>
                <div className="block-body">
                  Loading...
                </div>
              </div>
              :
              <div className="card-block">
                <div className="block-header text-right">
                  <div className="block-title">
                    <h4 className="block-title">Purchase Order Details</h4>
                  </div>

                  <div>
                    <ReactToPrint
                      trigger={() => {
                        return (<a href="#">
                          <Button variant={"default"} onClick={this.handlePrint} size={"sm"}><FaPrint /> Print Invoice
                          </Button>
                        </a>)
                      }}
                      content={() => this.componentRef}
                    />
                  </div>

                  <div className="only-print" ref={el => (this.componentRef = el)}>
                    <br />
                    <br />
                    <h4 className='text-right mr-5'>PURCHASE ORDER</h4>
                    <p
                      className='text-right mr-5'>{getFormattedDate(this.state.purchaseOrder?.order_date, 'Do MMMM YYYY')}</p>
                    <div className='col-12 row'>
                      <div className='col-6'>
                        <table className="invoice-table">
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                <table className="table table-bordered">
                                  <tbody>

                                    <React.Fragment key={1}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Company Name:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.supplier_name}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={2}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Address:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.company_address}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>BIN:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.bin}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>ATTN:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.attn}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Supplier Email:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.supplier_email}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Supplier Group:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.supplier_group}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Ship To:</strong></span>
                                        </td>
                                        <td className={`text-center`}>
                                          <span>{this.state.purchaseOrder?.ship_to?.holding}</span>
                                          <br />
                                          <span>{this.state.purchaseOrder?.ship_to?.address}</span>
                                          <br />
                                          <span>{this.state.purchaseOrder?.ship_to?.attn}</span>
                                        </td>
                                      </tr>
                                    </React.Fragment>

                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-6'>
                        <table className="invoice-table">
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                <table className="table table-bordered">
                                  <tbody>

                                    <React.Fragment key={1}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>PO Number:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.po}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={2}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>PO Raised From:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.po_raised_from}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Payment Terms:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.payment_terms}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Contact Person:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.contact_person?.name}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Email:</strong></span>
                                        </td>
                                        <td className={`text-center`}>{this.state.purchaseOrder?.contact_person?.email}</td>
                                      </tr>
                                    </React.Fragment>

                                    <React.Fragment key={3}>
                                      <tr key={1}>
                                        <td>
                                          <span className="d-block"><strong>Bill To:</strong></span>
                                        </td>
                                        <td className={`text-center`}>
                                          <span>{this.state.purchaseOrder?.bill_to?.holding}</span>
                                          <br />
                                          <span>{this.state.purchaseOrder?.bill_to?.address_line1}</span>
                                          <br />
                                          <span>{this.state.purchaseOrder?.bill_to?.address_line2}</span>
                                          <br />
                                          <span>{this.state.purchaseOrder?.bill_to?.address_line3}</span>
                                          <br />
                                          <span>{this.state.purchaseOrder?.bill_to?.attn}</span>
                                          <br />
                                          <span>{this.state.purchaseOrder?.bill_to?.vat_id}</span>
                                        </td>
                                      </tr>
                                    </React.Fragment>

                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className='col-12 row'>
                      <div className='col-12'>
                        <table className="invoice-table">
                          <tbody>
                            <tr>
                              <td colSpan={2}>
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Line</th>
                                      <th className={'text-center'}>Description</th>
                                      <th className={'text-center'}>Code By Supplier</th>
                                      <th className={'text-center'}>Quantity</th>
                                      <th className={'text-center'}>Brand</th>
                                      {/*<th className={'text-center'}>Need by Date</th>*/}
                                      <th className="text-right">Amount (৳)</th>
                                    </tr>
                                  </thead>

                                  <tbody>

                                    {this.state.purchaseOrder?.line_items && this.state.purchaseOrder?.line_items.length > 0 ?
                                      this.state.purchaseOrder?.line_items?.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <tr key={index + 1}>
                                            <td className={`text-center`}>{index + 1}</td>
                                            <td className={`text-center`}>
                                              <span className="d-block"><strong>{item?.product_title}</strong></span>
                                              <span className="d-block text-muted">
                                                {item.product_attribute_values.map((value, index) => {
                                                  return (
                                                    <>
                                                      {value.attribute_value}{item.product_attribute_values.length > index + 1 ? '-' : ''}
                                                    </>

                                                  )
                                                }
                                                )}
                                              </span>
                                            </td>
                                            <td className={`text-center`}> {item.code_by_supplier} </td>
                                            <td className={`text-center`}>{item.quantity}</td>
                                            <td className={`text-center`}>{item.brand?.name}</td>
                                            {/*<td className={`text-center`}>January 7, 2021</td>*/}
                                            <td className="text-right">{item?.price}</td>
                                          </tr>
                                        </React.Fragment>
                                      ))
                                      :
                                      ''
                                    }

                                    {/*<tr className="text-bold">
                                  <td colSpan={this.state.colspan} className="text-right">Subtotal</td>
                                  <td className="text-right">65656</td>
                                </tr>*/}

                                    <tr className="text-bold">
                                      <td colSpan={this.state.colspan} className="text-right">VAT @15%</td>
                                      <td
                                        className="text-right">{this.state.purchaseOrder?.total_price_with_vat - this.state.purchaseOrder?.total_price}</td>
                                    </tr>

                                    <tr className="text-bold">
                                      <td colSpan={this.state.colspan} className="text-right">Grand Total</td>
                                      <td className="text-right">{this.state.purchaseOrder?.total_price_with_vat}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className='col-12 row'>
                      <div className='col-12'>
                        <h5>Total amount (in words)
                          : {this.state.purchaseOrder?.price_in_words} {/*{convertDigitToWord(2020).charAt(0).toUpperCase() + convertDigitToWord(2020).slice(1)} taka*/}
                        </h5>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className={"row px-2"}>
                      <div className={"col-3 py-5"}>
                        <h6>Issued By:</h6>
                      </div>
                      <div className={"col-3"}>
                        <hr />
                        <h6>{this.state.purchaseOrder?.issued_by?.name}</h6>
                        <span>{this.state.purchaseOrder?.issued_by?.designation}</span>
                        <br />
                        <span>{this.state.purchaseOrder?.issued_by?.holding}</span>
                        <br />
                        <span>{this.state.purchaseOrder?.issued_by?.email}</span>
                      </div>
                      <div className={"col-6"}>
                        <div className={"comment-container"}>
                          <p className={"text-center"}>Comment</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="block-body">
                  {/* <h5 class="card-title">Purchase Order Details</h5> */}
                  <table className="info-table">
                    <tbody>
                      <tr>
                        <td style={{ width: "250px" }}>PO ID</td>
                        <td style={{ width: "20px", textAlign: "center" }}>:</td>
                        <td>
                          {this.state.purchaseOrder &&
                            this.state?.purchaseOrder?.id}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '250px' }}>Supplier</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td>{this.state.purchaseOrder &&
                          this.state.purchaseOrder.supplier_name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '250px' }}>Creation Date</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td>{this.state.purchaseOrder &&
                          getFormattedDate(this.state.purchaseOrder.order_date, 'Do MMMM YYYY')}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '250px' }}>Quantity</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td>{this.state.purchaseOrder &&
                          this.state.purchaseOrder.quantity}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '250px' }}>QC Status</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td>{this.state.purchaseOrder &&
                          this.state.purchaseOrder.qc_status ? "Done" : "Not Done"}</td>
                      </tr>

                      <tr>
                        <td style={{ width: "250px" }}>Status</td>
                        <td style={{ width: "20px", textAlign: "center" }}>:</td>
                        <td>
                          {this.state.purchaseOrder &&
                            this.state.purchaseOrder.order_status
                            ? this.state.purchaseOrder.order_status
                            : "Unknown"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '250px' }}>Total Price</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td>{this.state.purchaseOrder &&
                          this.state.purchaseOrder.total_price}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '250px' }}>Pay Status</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td>{this.state.purchaseOrder &&
                          this.state.purchaseOrder.pay_status}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '250px' }}>Paid</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td>{this.state.purchaseOrder &&
                          this.state.purchaseOrder.paid}</td>
                      </tr>
                      {
                        warehouseType === 'central' && <tr>
                          <td style={{ width: '250px' }}>Created By</td>
                          <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                          <td>{this.state.purchaseOrder &&
                            this.state.purchaseOrder?.created_by?.name}</td>
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
              </div>
          }
          <div class="card-block">
            <div className="block-header">
              <h4 className="block-title">Purchase Order Items</h4>
              <div className="ml-auto">
                <ExportCSV data={this.state.dataForExport} headers={headers} title="Purchase Order Items" />
              </div>
            </div>
            {/* <div class="col-sm-8"> */}
            {
              this.state.isLoading ?
                <div className={`card-block`}>
                  <div className="block-body">
                    Loading...
                  </div>
                </div>
                :
                <div className="card">
                  <div className="card-body">
                    {/* <h5 class="card-title">Purchase Order Product List</h5> */}
                    <>
                      <table className="order-table table table-striped table-hover table-responsive">
                        <thead>
                          <tr>
                            <th style={{ width: '20px' }}>#</th>
                            {/* <th>Details Log</th> */}
                            <th>Product</th>
                            <th>Code By Supplier</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                            <th>Ordered</th>
                            <th>Received</th>
                            <th>Passed</th>
                            <th>Quality Failed</th>
                            <th>Quantity Failed</th>
                            <th>QC Status</th>

                            {
                              this.state.purchaseOrder?.order_status !== 'Order placed' &&
                              <>
                                <th>Action</th>
                                {/* <th>Location </th> */}
                                {/* {this.state.purchaseOrderItems.some(item=> item.qc_status) && <th>Assign</th>} */}
                                {this.state.purchaseOrderItems.some(item => !!item.qc_status) && (
                                  <th>Location</th>
                                )}
                                {this.state.purchaseOrderItems.some(item => !item.location && item.qc_status) && <th>Assign</th>}
                              </>
                            }

                          </tr>
                        </thead>
                        <tbody>
                          {this.state.purchaseOrderItems.length > 0 &&
                            this.state.purchaseOrderItems.map((orderItem, index) => {
                              return (
                                <tr key={index} onClick={() => this.setState({ selectedRow: index })}>
                                  <td>{index + 1}</td>

                                  {/* <td>
                                    <Button
                                      size="sm"
                                      type={"button"}
                                      className="mr-1"
                                      onClick={() => this.showModalHandler(orderItem.id)}
                                      variant="info">
                                      Log
                                    </Button>
                                  </td> */}

                                  <td>
                                    <div className="order-item">
                                      <Link to={`${process.env.PUBLIC_URL}/product/details/${orderItem.product_id}`}>
                                        {orderItem.product_title}
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="order-item">
                                      {orderItem.code_by_supplier}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="order-item">
                                      {orderItem.price} BDT
                                    </div>
                                  </td>
                                  <td>
                                    <div className="order-item">
                                      {orderItem.total_price} BDT
                                    </div>
                                  </td>
                                  <td>
                                    <div className="order-item">
                                      {orderItem.due_quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.received_quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.qc_passed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.quality_failed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.quantity_failed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.qc_status ? "Done" : "Not Done"}
                                    </div>
                                  </td>
                                  {
                                    this.state.purchaseOrder?.order_status !== 'Order placed' &&
                                    <>
                                      <td style={{ width: '100px' }} className={'nowrap'}>
                                        <div className="btn-group">
                                          {orderItem.qc_status ?

                                            <QRComponent ProductTitle={orderItem.product_title} Sku={orderItem.sku} />
                                            :
                                            <Link
                                              to={`${process.env.PUBLIC_URL}/purchaseOrder/details/product-qc/${this.state?.purchaseOrder?.id}/${orderItem.id}`}
                                              data={orderItem}
                                              className="btn btn-block btn-sm btn-outline-secondary"
                                            >
                                              Start QC
                                            </Link>
                                          }
                                        </div>
                                        <Button
                                          size="sm"
                                          type={"button"}
                                          className="ml-2"
                                          onClick={() => this.showModalHandler(orderItem.id)}
                                          variant="info">
                                          Log
                                        </Button>
                                      </td>
                                      {orderItem.qc_status &&
                                        <td style={{ width: '150px' }}>
                                          <select id="location" name="location_id" className='form-control form-control-sm'
                                            disabled={!orderItem.qc_status || orderItem.location?.id}
                                            onChange={e => {
                                              this.setLineItemLocation(index, e, orderItem.id);
                                            }
                                            }
                                            value={orderItem.location ? orderItem.location.id : this.state.orderLocations[orderItem.id]}
                                          >
                                            <option value="">--Locations--</option>
                                            {this.state.locations?.length > 0 ?
                                              this.state.locations?.map((item) => {
                                                return (
                                                  <option value={item.id}>{item.code}</option>
                                                )
                                              })
                                              : ""
                                            }
                                          </select>
                                        </td>
                                      }
                                      {
                                        (!orderItem.location?.id && orderItem.qc_status) &&
                                        <td>
                                          <Button
                                            size="sm"
                                            type={"button"}
                                            className="mr-1"
                                            onClick={() => {
                                              this.handleWithDebounceAssignLocation(orderItem.id);
                                            }}
                                            variant="info">
                                            Assign
                                          </Button>
                                        </td>
                                      }
                                    </>
                                  }

                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </>
                  </div>
                </div>
            }
            {/* </div> */}
          </div>
        </div>
        <PoDetailsModal
          show={this.state.showModal}
          modalTitle="PO Details Log"
          onHide={this.hideModalHandler}
          data={this.state.modalData}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
      </App>
    );
  }
}

export default withRouter(Details);