import React, { useState, useEffect } from "react";
import styles from "./ImageUpload.module.scss";
import { AiFillCloseCircle } from "react-icons/ai";

const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file"));
    };
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      resolve({ width: 0, height: 0 });
    }
  });
};

const ImageUpload = ({setBannerImages}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() =>{
    setBannerImages(allFiles)
  },[allFiles])

  const handleImageChange = async(e) => {
    const SUPPORTED_FORMAT = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/webp",
    ];
    const IMG_SIZE = 1024*1024
    
    const imageDimentions = []
    e.persist()
    const files = e.target.files;
    let imageFiles = Array.from(files).every((file) =>
      SUPPORTED_FORMAT.includes(file.type)
    );
    
    let imageFileSize = Array.from(files).every((file) =>
        file.size <= IMG_SIZE
    );
    if(!imageFiles || !imageFileSize){
      setErrorMsg(
        "Invalid image format, supported image format:(.jpg, .webp, .jpeg and .png)) with 1440X400 pixels not more than 1MB"
      );
      return
    }
    for(var i=0; i<files.length;i++){
      let sizes = await getDimension(files[i])
      let result = (sizes.width == 1440 && sizes.height == 400)
      imageDimentions.push(result)
    }

    if (imageDimentions?.every(i=> i===true)) {
        const arr = [...allFiles];
        const filesArray = Array.from(files).map((file) => {
          arr.push(file);
          e.target.value = null;
          return URL.createObjectURL(file);
        });
        setAllFiles(arr);
        setSelectedFiles((prevImages) => prevImages.concat(filesArray));
        Array.from(files).map((file) => URL.revokeObjectURL(file));
        setErrorMsg("");
      }  
      else {
        setErrorMsg(
          "Invalid image format, supported image format:(.jpg, .webp, .jpeg and .png)) with 1440X400 pixels not more than 1MB"
        );
      }
  };

  const handleRemoveImg = (index) => {
    const blobFiles = [...selectedFiles];
    const imageFiles = [...allFiles];
    blobFiles.splice(index, 1);
    imageFiles.splice(index, 1);
    setSelectedFiles(blobFiles);
    setAllFiles(imageFiles);
  };
  return (
    <div className={styles.imageUploadWrapper}>
      <input
        multiple
        type="file"
        id="file"
        name="banner_file"
        onChange={(e) => handleImageChange(e)}
      />

      <div className={styles.retrunImageUploadMain}>
        <div className={styles.imageUploadPreview}>
          {selectedFiles?.map((photo, index) => (
            <div className={styles.returnImageWrapper} key={photo}>
              <img src={photo} alt={`photo-${index}`} />
              <span
                className={styles.cancelUploadedImage}
                onClick={() => handleRemoveImg(index)}
              >
                <AiFillCloseCircle color="#fff" size={30} />
              </span>
            </div>
          ))}
        </div>

        <div className={styles.returnImageUpload}>
          <div className={styles.imageUploadLabelHolder}>
            <label htmlFor="file" className={styles.imageUploadLabel}>
              Add Banner
            </label>
          </div>
        </div>
      </div>
      <div className={styles.imgInfo}>
        {errorMsg ? (
          <small className={styles.imgError}>{errorMsg}</small>
        ) : (
          <small>
            File size required: {1440} * {400}
          </small>
        )}
      </div>
    </div>
  );
};
export default ImageUpload;
