import React, { useEffect, useState } from "react";
import { Link, withRouter, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import App from "../../App";
import Pagination from "react-js-pagination";
import { getCustomVariantPrice } from "../../services/productServices";
import { AiOutlineArrowLeft } from "react-icons/all";

const CustomProductsList = () => {
  const { sku: skuProps } = useParams();
  const [state, setState] = useState({
    products: [],
    resource: "",
    phone: "",
    sku: skuProps ? skuProps : "",
    isDisabled: true,
  });
  const [pagination, setPagination] = useState({
    "x-page": 1,
    "x-per-page": 15,
    "x-total": 0,
    "x-total-pages": 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setState({ ...state, sku: skuProps });
    setIsLoading(true);

    getCustomVariantPrice({ sku: skuProps })
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          products: res?.data?.data,
        }));
        setPagination((prevPagination) => ({
          ...prevPagination,
          "x-page": res.headers["x-page"],
          "x-per-page": res.headers["x-per-page"],
          "x-total": res.headers["x-total"],
          "x-total-pages": res.headers["x-total-pages"],
        }));

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [skuProps]);

  const handleChange = (event) => {
    const updatedState = { ...state, [event.target.name]: event.target.value };
    const { sku, phone, resource } = updatedState;
    const isDisabled = !(
      (phone === "" && resource === "") ||
      (phone !== "" && resource !== "") ||
      (phone === "" && resource !== "")
    );
    setState({ ...updatedState, isDisabled });
  };

  const handlePageChange = (pageNumber) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      "x-page": pageNumber,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    getCustomVariantPrice({
      sku: state.sku,
      phone: state.phone,
      resource: state.resource,
    })
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          products: res?.data?.data,
        }));
        setPagination((prevPagination) => ({
          ...prevPagination,
          "x-page": res.headers["x-page"],
          "x-per-page": res.headers["x-per-page"],
          "x-total": res.headers["x-total"],
          "x-total-pages": res.headers["x-total-pages"],
        }));

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <App layout="">
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Custom Product Price List</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/product/list`}
              className="btn btn-sm btn-link mr-2"
            >
              <AiOutlineArrowLeft /> Back to Product List
            </Link>
          </div>
        </div>
      </div>

      <form className="form-inline mb-3" onSubmit={handleSubmit}>
        <div className="form-group mr-3">
          <label htmlFor="sku" className="sr-only">
            Search by SKU
          </label>
          <input
            type="search"
            name="sku"
            id="sku"
            defaultValue={skuProps ? skuProps : ""}
            onChange={handleChange}
            className="form-control form-control-sm"
            placeholder="Search by SKU"
          />
        </div>

        <div className="form-group mr-3">
          <label htmlFor="resource" className="sr-only">
            Resource Type
          </label>
          <select
            id="resource"
            name="resource"
            className="form-control form-control-sm"
            onChange={handleChange}
          >
            <option value="">--Select Resource Type--</option>
            <option value="warehouse">Warehouse</option>
            <option value="partner">Partner</option>
          </select>
        </div>
        <div className="form-group mr-3">
          <label htmlFor="phone" className="sr-only">
            Search by Phone Number
          </label>
          <input
            type="search"
            name="phone"
            id="phone"
            onChange={handleChange}
            className="form-control form-control-sm"
            placeholder="Search by Phone"
          />
        </div>

        <Button
          type={`submit`}
          disabled={state.isDisabled}
          size="sm"
          variant="primary"
        >
          Search
        </Button>
      </form>
      {isLoading ? (
        <div className={`card-block`}>
          <div className="block-body">Loading...</div>
        </div>
      ) : (
        <div className="card-block">
          <div className="block-body">
            <table className="order-table table table-striped">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Type</th>
                  <th>Effective Price</th>
                  <th>Base Price</th>
                </tr>
              </thead>
              <tbody>
                {state.products && state.products.length > 0 ? (
                  state.products.map((product, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id mb-0">{product.sku}</h4>
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id mb-0">{product.name}</h4>
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id mb-0">{product.phone}</h4>
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id mb-0">{product.type}</h4>
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id mb-0">
                              {product.custom_price}
                            </h4>
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            <h4 className="order-id mb-0">
                              {product.actual_price}
                            </h4>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td className="text-center" colspan="6">
                    No Match Found! Please Search with Acurate Data.
                  </td>
                )}
              </tbody>
            </table>
          </div>
          {pagination["x-total"] && pagination["x-total"] > 0 ? (
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={parseInt(pagination["x-page"] || +1)}
                itemsCountPerPage={parseInt(pagination["x-per-page"])}
                totalItemsCount={parseInt(pagination["x-total"])}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
      )}
    </App>
  );
};

export default withRouter(CustomProductsList);
