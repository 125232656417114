import React, { Component } from "react";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPurchaseOrderItem,
  getQuestionnaires,
  qcPurchaseOrderItem,
} from "../../services/purchaseOrderService";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";

class ProductQC extends Component {
  constructor() {
    super();
    this.state = {
      purchaseOrderItem: null,
      order_id: null,
      questionnaire: null,
      received_quantity: 0,
      qc_remaining_quantity: 0,
      quality_count: 0,
      failed_reasons: [],
      failed_ids: [],
      failed_quantity: 0,
      passed_quantity: 0,
      quantityCheck: false,
      variant_id: null,
    };
  }

  componentDidMount() {
    this.setState({ order_id: this.props.match.params.order_id });
    getPurchaseOrderItem(
      this.props.match.params.order_id,
      this.props.match.params.line_item_id,
      "WhPurchaseOrder"
    )
      .then((res) => {
        this.setState({ purchaseOrderItem: res });
        getQuestionnaires(res.category_id)
          .then((res) => {
            if (Array.isArray(res)) {
              this.setState({ questionnaire: res });
            } else {
              toast.info("No questionnaires to check");
            }
          })
          .catch((errMsg) => {
            console.log("Err Msg: ", errMsg);
          });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  quantityChangeHandler = (event) => {
    this.setState({
      received_quantity: event.target.value,
      qc_remaining_quantity: event.target.value,
    });
  };

  submitQuantityChangeHandler = (event) => {
    event.preventDefault();
    this.setState({ quantityCheck: true });
    toast.success("Quantity received " + this.state.received_quantity);
  };

  // selectedYes = (event) => {
  //   let temp = this.state.failed_ids;
  //   if (temp.includes(event.target.value)) {
  //     temp.pop(event.target.value);
  //   } else {
  //     temp.push(event.target.value);
  //   }
  //   this.setState({failed_ids: temp});
  //   console.log("failed questions ids:", temp);
  // }

  selectedRadio = (event) => {
    let temp = this.state.failed_ids;
    if (event.target.value === "false" && temp.includes(event.target.name)) {
      temp.pop(event.target.name);
    } else if (
      event.target.value === "true" &&
      !temp.includes(event.target.name)
    ) {
      temp.push(event.target.name);
    }
    this.setState({ failed_ids: temp });
    // console.log("failed questions ids:", temp);
  };

  qualityCountChangeHandler = (event) => {
    this.setState({ quality_count: event.target.value });
  };

  debouncedOnQualityCheckSubmit = _.debounce((e) => {
    this.qualityCheckSubmitHandler(e);
  }, DEBOUNCE_TIME);

  handleWithDebounceQualityCheck = (e) => {
    e.preventDefault();
    this.debouncedOnQualityCheckSubmit(e);
  };

  qualityCheckSubmitHandler = (event) => {
    event.preventDefault();
    if (this.state.failed_ids.length > 0) {
      this.setState({
        failed_quantity:
          parseInt(this.state.failed_quantity) +
          parseInt(this.state.quality_count),
      });
      this.setState({
        failed_reasons: [
          ...this.state.failed_reasons,
          {
            quantity: this.state.quality_count,
            question_ids: this.state.failed_ids,
          },
        ],
      });

      toast.warning("Quanlity check failed: " + this.state.quality_count);
    } else if (this.state.qc_remaining_quantity > 0) {
      this.setState({
        passed_quantity:
          parseInt(this.state.passed_quantity) +
          parseInt(this.state.quality_count),
      });
      toast.info("Quanlity check passed: " + this.state.quality_count);
    }
    if (this.state.qc_remaining_quantity === 0) {
      let line_item = this.state.purchaseOrderItem;

      let formData = {
        order: {
          order_id: this.props.match.params.order_id,
          order_type: "WhPurchaseOrder",
          variant_id: line_item.variant_id,
          received_quantity: this.state.received_quantity,
          passed_quantity:
            parseInt(this.state.received_quantity) -
            parseInt(this.state.failed_quantity),
          failed_quantity: this.state.failed_quantity,
          failed_reasons: this.state.failed_reasons,
        },
      };

      qcPurchaseOrderItem(formData)
        .then((res) => {
          this.props.history.push(
            `/purchaseOrder/details/${this.state.order_id}`
          );
          toast.success("QC done !");
          
        })
        .catch((errMsg) => {
          console.log("Err Msg: ", errMsg);
        });
    }

    let negation =
      parseInt(this.state.qc_remaining_quantity) -
      parseInt(this.state.quality_count);
    this.setState({ qc_remaining_quantity: negation });
    this.setState({ failed_ids: [] });
    document.getElementById("questionnaire-form").reset();
  };

  render() {
    // const {formData} = this.state;
    let checkForm;


    if (
      this.state.purchaseOrderItem &&
      this.state.purchaseOrderItem.qc_status
    ) {
      checkForm = (
        <div className="d-flex justify-content-center">
          <p>Qauntity & Quality Check Done!</p>
        </div>
      );
    } else if (this.state.quantityCheck === false) {
      checkForm = (
        <>
          <h5 class="card-title">Quantity Check</h5>
          <Form onSubmit={this.submitQuantityChangeHandler}>
            <Form.Group as={Row} controlId="displayOrShopName">
              <Form.Label column sm="4">
                Quantity Received <span className="text-danger">*</span>
              </Form.Label>
              <Col sm="3" md={3} lg={3}>
                <Form.Control
                  name={"name"}
                  defaultValue={0}
                  onChange={this.quantityChangeHandler}
                  max={
                    this.state.purchaseOrderItem &&
                    this.state.purchaseOrderItem.due_quantity
                  }
                  min={0}
                  required
                  type="number"
                />
              </Col>
            </Form.Group>

            <div className="mt-3">
              <Button
                size="lg"
                type={"submit"}
                disabled={this.state.isAdding}
                variant="primary"
              >
                {" "}
                Next{" "}
              </Button>
            </div>
          </Form>
        </>
      );
    } else {
      checkForm = (
        <>
          <h5 class="card-title">Quality Check</h5>
          <br />
          <Form
            id="questionnaire-form"
            onSubmit={this.handleWithDebounceQualityCheck}
          >
            {this.state.qc_remaining_quantity > 0 ? (
              <>
                {this.state?.questionnaire &&
                  this.state?.questionnaire?.map((item, index) => {
                    return (
                      <>
                        {/* <Form.Group as={Row} controlId="displayOrShopName">
                    <Col sm="3" md={3} lg={3}>
                      <Form.Group inline controlId={index}>
                        <Form.Check value={item.id} className="mb-2 mr-sm-2" onChange={this.selectedYes} type="checkbox" label={item.question} />
                      </Form.Group>
                    </Col>
                  </Form.Group> */}
                        <Form.Group as={Row}>
                          <Form.Label column sm="6">
                            {item.question}
                          </Form.Label>
                          <Col sm="9" md={8} lg={3}>
                            <RadioGroup
                              row
                              name={item.id}
                              value={this.state.failed_ids.includes(
                                `${item.id}`
                              )}
                              onChange={this.selectedRadio}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="No"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Yes"
                              />
                            </RadioGroup>
                          </Col>
                        </Form.Group>
                      </>
                    );
                  })}
                <br></br>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="4">
                    Quantity of Products<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="3" md={3} lg={3}>
                    <Form.Control
                      name={"name"}
                      defaultValue={0}
                      onChange={this.qualityCountChangeHandler}
                      min={0}
                      max={this.state.qc_remaining_quantity}
                      required
                      type="number"
                    />
                  </Col>
                </Form.Group>
              </>
            ) : (
              <>Quantity and Quality check complete ! Press done to confirm.</>
            )}
            <div className="mt-3">
              <Button
                size="lg"
                type={"submit"}
                onClick={() => this.setState({ isQualityChecked: true })}
                disabled={this.state.isAdding}
                variant={
                  this.state.qc_remaining_quantity > 0 ? "primary" : "success"
                }
              >
                {this.state.qc_remaining_quantity > 0 ? "Next" : "Done"}
              </Button>
            </div>
          </Form>
        </>
      );
    }

    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Purchase Order Product QC</h2>
          </div>

          <div className="card-block">
            <div className="block-body">
              <table className="order-table table table-striped table-hover">
                <thead>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th>Product</th>
                    <th>Ordered</th>
                    <th>Received</th>
                    <th>Passed</th>
                    <th>Failed</th>
                    <th>QC Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.purchaseOrderItem && (
                    <tr key={1}>
                      <td>{this.state.purchaseOrderItem.id}</td>
                      <td>
                        <div className="order-item">
                          <Link
                            to={`${process.env.PUBLIC_URL}/product/details/1`}
                          >
                            {this.state.purchaseOrderItem.product_title}
                          </Link>
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          {this.state.purchaseOrderItem.due_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          {this.state.received_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          {this.state.passed_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          {this.state.failed_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          {this.state.purchaseOrderItem.qc_status
                            ? "Done"
                            : "Not Done"}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div class="row justify-content-md-center">
            <div class="col-sm-8">
              <div className="card">
                <div className="card-body">{checkForm}</div>
              </div>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(ProductQC);
