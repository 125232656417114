import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-widgets/dist/css/react-widgets.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./auth/components/PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import Login from "./views/Login";
import axios from "axios";
// DWH
import DWHList from "./views/dwh/List";
import Adddwh from "./views/dwh/Add";
import DWHDetail from "./views/dwh/Details";
import DWHEdit from "./views/dwh/Edit";

// Warehouse Admin
import SaList from "./views/staff/List";
import SaAdd from "./views/staff/Add";
import SaDetail from "./views/staff/Details";
import SaEdit from "./views/staff/Edit";

// Products
import PList from "./views/product/List";
// import PAdd from "./views/product/Add";
import AddProduct from "./views/product/AddProduct";
import AddMulticatProduct from "./views/product/AddMulticatProduct";
// import PDetail from "./views/product/Details";
import PDetail from "./views/product/ProductDetails";
// import PEdit from "./views/product/Edit";
import NewPEdit from "./views/product/EditProduct";
import CustomProductsList from "./views/product/CustomProductsList.js";

// Suppliers
import SList from "./views/supplier/List";
import SAdd from "./views/supplier/Add";
import SDetail from "./views/supplier/Details";
import SEdit from "./views/supplier/Edit";

// Checklist
import CList from "./views/checklist/List";
import CAdd from "./views/checklist/Add";
import CDetail from "./views/checklist/Details";
import CEdit from "./views/checklist/Edit";

// Logistic
import LList from "./views/logistic/List";
import LAdd from "./views/logistic/Add";
import LDetail from "./views/logistic/Details";
import LEdit from "./views/logistic/Edit";

// Partners
import PaList from "./views/partner/List";
import PaAdd from "./views/partner/Add";
import PaDetail from "./views/partner/Details";
import PaEdit from "./views/partner/Edit";

// SRS
import SrList from "./views/sr/List";
import SrAdd from "./views/sr/Add";
import SrDetail from "./views/sr/Details";
import SrEdit from "./views/sr/Edit";

// Riders
import RList from "./views/rider/List";
import RAdd from "./views/rider/Add";
import RDetail from "./views/rider/Details";
import REdit from "./views/rider/Edit";

// Location
import LOList from "./views/location/List";
import LOAdd from "./views/location/Add";
import LODetail from "./views/location/Details";
import LOEdit from "./views/location/Edit";

// Categories
import CaList from "./views/category/TreeCategory";
import CaAdd from "./views/category/Add";
import CaDetail from "./views/category/Details";
import CaEdit from "./views/category/Edit";

//Brand
import BrandList from "./views/newBrand/List";
import BrandAdd from "./views/newBrand/Add";
import BrandDetail from "./views/newBrand/Details";
import BrandEdit from "./views/newBrand/Edit";

// Roles
import RoList from "./views/role/List";
import RoAdd from "./views/role/Add";
import RoDetail from "./views/role/Details";
import RoEdit from "./views/role/Edit";

// Purchase Orders
import PoList from "./views/purchaseOrder/List";
import PoAdd from "./views/purchaseOrder/NewAdd";
import PoDetail from "./views/purchaseOrder/Details";
import PoEdit from "./views/purchaseOrder/Edit";
import PoPQC from "./views/purchaseOrder/ProductQC";

// DH Purchase Orders
import DhPoList from "./views/dhPurchaseOrder/List";
import DhPoAdd from "./views/dhPurchaseOrder/NewAdd";
import DhPoDetail from "./views/dhPurchaseOrder/STODetails.jsx";
import DhPoPQC from "./views/dhPurchaseOrder/ProductQC";

// DH Return Orders
import DhRoList from "./views/dhReturnOrders/List";
import DhRoAdd from "./views/dhReturnOrders/NewAdd";
import DhRoDetail from "./views/dhReturnOrders/Details";
import DhRoPQC from "./views/dhReturnOrders/ProductQC";

// Customer Orders
import COList from "./views/customerOrder/customerOrderList";
import CODetails from "./views/customerOrder/customerOrderDetails";

// Attribute
import AtList from "./views/attribute/List";
import AtAdd from "./views/attribute/Add";
import AtDetail from "./views/attribute/Details";
import AtEdit from "./views/attribute/Edit";

// Attribute Set
import AtSetList from "./views/attributeSet/List";
import AtSetAdd from "./views/attributeSet/Add";
import AtSetDetail from "./views/attributeSet/Details";
import AtSetEdit from "./views/attributeSet/Edit";

// ROUTE
import RouList from "./views/route/List";
import RouAdd from "./views/route/Add";
import RouDetail from "./views/route/Details";
import RouEdit from "./views/route/Edit";
import TemporaryImages from "./views/bulkUploads/temporaryImages";
import UploadImage from "./views/bulkUploads/uploadImage";

// Inbound QC

//Reconciliation
import Reconciliation from "./views/reconciliation/Reconciliation";
import RouteCollect from "./views/reconciliation/CollectFromRoute";
import RiderCollect from "./views/reconciliation/CollectFromRider";
import RPID from "./views/reconciliation/ReturnPackedItemDetails";

//Promotions
import PromotionList from "./views/promotions/List";
import PromotionAdd from "./views/promotions/Add";
import PromotionDetail from "./views/promotions/Details";
import PromotionEdit from "./views/promotions/Edit";

//Flash sale
import FlashSaleList from "./views/flashSale/List";
import FlashSaleAdd from "./views/flashSale/Add";
import FlashSaleDetail from "./views/flashSale/Details";
import FlashSaleEdit from "./views/flashSale/Edit";

//PromoCoupon
import PromoCouponList from "./views/promoCoupon/List";
import PromoCouponAdd from "./views/promoCoupon/Add";
import PromoCouponDetail from "./views/promoCoupon/Details";
import PromoCouponEdit from "./views/promoCoupon/Edit";

// RA
import RAList from "./views/ra/List";
import RACreate from "./views/ra/Create";
import RADetail from "./views/ra/Details";
import RAEdit from "./views/ra/Edit";

//QC Failed Items
import POQCFIList from "./views/qcFailedItems/POList.jsx";
import STOQCFIList from "./views/qcFailedItems/STOList.jsx";
import IGTQCFIList from "./views/qcFailedItems/IGTList.jsx";
import RCOQCFIList from "./views/qcFailedItems/RCOList.jsx";

import { Provider } from "react-redux";
import store from "./redux/store";
import Dashboard from "./views/Dashboard";
import ProductAssign from "./views/supplier/NewProductAssign";
import EditProductAssign from "./views/supplier/EditProductAssign";
import { getLocalAuthToken, getWarehouseType } from "./helper/utils";

// Common Slider
import {
  CommonSliderList,
  CommonSiderAdd,
  CommonSliderEdit,
} from "./views/slider/common-slider";

//Promotional Slider
import {
  PromotionalSliderList,
  PromotionalSliderAdd,
  PromotionalSliderDetails,
} from "./views/slider/promotional-slider";

// Inventory Route
import InventoryList from "./views/inventory/List";

//Block Item List
import BlockItemList from "./views/blockItem/List";
import { toast } from "react-toastify";

// Return Order
import ROrderList from "./views/returnOrder/List";
import ROPDetails from "./views/returnOrder/Partner";
import ROCDetails from "./views/returnOrder/Customer";

import BackwardChallanList from "./views/challan/BackwardChallanList";
import BackwardChallanDetail from "./views/challan/BackwardChallanDetails";
import ForwardChallanList from "./views/challan/ForwardChallanList";
import ForwardChallanDetail from "./views/challan/ForwardChallanDetails";
import ForwardChallanForm from "./views/challan/ForwardChallanForm";

// SR Margin
// import SRMarginList from "./views/srMargin/List"
// import SRMarginDetails from "./views/srMargin/Details"
// import PartnerCommission from './views/srMargin/PartnerCommissionDetails';

// FC to CWH
// import CashDeposit from './views/cashDeposit/DepositForm'
// import DepositList from "./views/cashDeposit/List";
// import DepositDetails from "./views/cashDeposit/Details";

// import CTList from "./views/commissionTransactions/List"
// import CTDetails from "./views/commissionTransactions/Details"

// Reviews
import ReviewList from "./views/reviews/List";
import ReviewDetails from "./views/reviews/Details";

// Product Requests
import RequestList from "./views/productRequests/List";
import RequestDetails from "./views/productRequests/Details";

import ContactUsList from "./views/contactUs/List";
import ContactUsDetails from "./views/contactUs/Details";

import ADRList from "./views/deactivationReasons/List";
import ADRCreate from "./views/deactivationReasons/Create";
import ADREdit from "./views/deactivationReasons/Edit";
import ADRDetails from "./views/deactivationReasons/Details";

import HelpTopicList from "./views/helpTopic/List";
import HelpTopicCreate from "./views/helpTopic/Create";
import HelpTopicEdit from "./views/helpTopic/Edit";
import HelpTopicDetails from "./views/helpTopic/Details";

import ArticleList from "./views/articles/List";
import ArticleCreate from "./views/articles/Create";
import ArticleEdit from "./views/articles/Edit";
import ArticleDetails from "./views/articles/Details";

import FeedbackList from "./views/feedbacks/List";

import DistrictList from "./views/config/districts/List";
import DistrictCreate from "./views/config/districts/Create";
import DistrictEdit from "./views/config/districts/Edit";
import DistrictDetails from "./views/config/districts/Details";

import ThanaList from "./views/config/thanas/List";
import ThanaCreate from "./views/config/thanas/Create";
import ThanaEdit from "./views/config/thanas/Edit";
import ThanaDetails from "./views/config/thanas/Details";

import AreaList from "./views/config/areas/List";
import AreaCreate from "./views/config/areas/Create";
import AreaEdit from "./views/config/areas/Edit";
import AreaDetails from "./views/config/areas/Details";

// import ArticleEdit from './views/articles/Edit'
// import ArticleDetails from './views/articles/Details'
import BPUList from "./views/bundlePackUnpack/List";
import BundlePack from "./views/bundlePackUnpack/CreatePack";
import BundleUnpack from "./views/bundlePackUnpack/Unpack";

import MetaList from "./views/metaInfo/List";
import MetaCreate from "./views/metaInfo/Add";
import MetaEdit from "./views/metaInfo/Edit";
import MetaDetails from "./views/metaInfo/Details";

import CouponList from "./views/coupons/List";
import CouponCreate from "./views/coupons/Add";
import CouponEdit from "./views/coupons/Edit";
import CouponDetails from "./views/coupons/Details";

import PartnerMargin from "./views/reports/partnerMargin";
import CustomersOrderPackForm from "./views/customerOrder/customerOrderPackForm";
import AuditLogs from "./views/AuditLogs/AuditLogs";
import ProfileSettings from "./views/profile/Settings";
import EditConfiguration from "./views/configuration/Edit";

import PromotionListV2 from "./views/promotionV2/List";
import PromotionCreateV2 from "./views/promotionV2/Add";
import PromotionEditV2 from "./views/promotionV2/Edit";
import SalesKpi from "./views/kpi/SalesKpi";
import DeliveryKpi from "./views/kpi/DeliveryKpi";

import BufferStockList from "./views/bufferStock/List";
import BufferStockAdd from "./views/bufferStock/Add";
import BufferStockEdit from "./views/bufferStock/EditBuffer";
import OutLet_CSV_export from "./views/OutLetSorting/OutLet_CSV_export";
import MulticatPartnerImportCSV from "./views/importExport/MulticatPartner";
import SRCreationCSV from "./views/importExport/SRCreation";
import DMCreation from "./views/importExport/DMCreation";
import Volume from "./views/McReport/PreOrder/Volume.js";
import Memo from "./views/McReport/PreOrder/Memo.js";
import BlukProductPriceUpload from "./views/product/BulkProductPriceUpload";
import UploadDhAssignment from "./views/product/UploadDhAssignment.js";
import ViewDhAssignment from "./views/product/ViewDhAssignment.js";
import CompanyList from "./views/companies/List.jsx";
import CompanyAdd from "./views/companies/Add.jsx";
import CompanyEdit from "./views/companies/Edit.jsx";
import CompanyDetails from "./views/companies/Details.jsx";
import BufferStockSR from "./views/bufferStockSR/BufferStockSR.js";

const wareHouseType = getWarehouseType();
const authData = getLocalAuthToken() ? getLocalAuthToken()?.message : "";

axios.defaults.headers.common["Authorization"] = authData?.token || "";
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.replace("/login");
    } else if (error?.response?.status === 500) {
      toast.error(
        "Something went wrong, please try again. If problem persist, please contact system admin."
      );
    } else {
      return error.response;
    }
  }
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <PrivateRoute exact path="/">
          <Dashboard />
        </PrivateRoute>
        <Route exact path="/login">
          <Login />
        </Route>
        <PrivateRoute exact path="/profile/settings">
          <ProfileSettings />
        </PrivateRoute>
        <PrivateRoute exact path="/dwh/list">
          <DWHList />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/dwh/add">
                    <Adddwh />
                </PrivateRoute>*/}
        <PrivateRoute exact path="/dwh/details/:id">
          <DWHDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/dwh/edit/:id">
          <DWHEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/outlet-sorting/csv-export-import">
          <OutLet_CSV_export />
        </PrivateRoute>
        <PrivateRoute exact path="/staff/list">
          <SaList />
        </PrivateRoute>
        <PrivateRoute exact path="/staff/add">
          <SaAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/staff/details/:id">
          <SaDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/staff/edit/:id">
          <SaEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/product/list">
          <PList />
        </PrivateRoute>
        <PrivateRoute exact path="/product/custom-product-price-list/:sku?">
          <CustomProductsList />
        </PrivateRoute>
        <PrivateRoute exact path="/dh-assignment-upload">
          <UploadDhAssignment />
        </PrivateRoute>
        <PrivateRoute exact path="/product/dh-assignment-view">
          <ViewDhAssignment />
        </PrivateRoute>
        <PrivateRoute exact path="/product/add-product">
          <AddProduct />
        </PrivateRoute>
        <PrivateRoute exact path="/product/add-multicat-product">
          <AddMulticatProduct />
        </PrivateRoute>
        <PrivateRoute exact path="/product/details/:id">
          <PDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/product/edit/:id">
          <NewPEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/bundle/list">
          <BPUList />
        </PrivateRoute>
        <PrivateRoute exact path="/bundle/create-pack">
          <BundlePack />
        </PrivateRoute>
        <PrivateRoute exact path="/bundle/unpack/:id">
          <BundleUnpack />
        </PrivateRoute>
        <PrivateRoute exact path="/bulk-product-price-upload">
          <BlukProductPriceUpload />
        </PrivateRoute>
        <PrivateRoute exact path="/supplier/list">
          <SList />
        </PrivateRoute>
        <PrivateRoute exact path="/supplier/add">
          <SAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/supplier/details/:id">
          <SDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/supplier/assign-product/:id">
          <ProductAssign />
        </PrivateRoute>
        <PrivateRoute exact path="/supplier/edit-assign-product/:id">
          <EditProductAssign />
        </PrivateRoute>
        <PrivateRoute exact path="/supplier/edit/:id">
          <SEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/promotion/multicat/list">
          <PromotionListV2 />
        </PrivateRoute>
        <PrivateRoute exact path="/promotion/multicat/create">
          <PromotionCreateV2 />
        </PrivateRoute>
        <PrivateRoute exact path="/promotion/multicat/edit/:id">
          <PromotionEditV2 />
        </PrivateRoute>
        <PrivateRoute exact path="/checklist/list">
          <CList />
        </PrivateRoute>
        <PrivateRoute exact path="/checklist/add">
          <CAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/checklist/details/:id">
          <CDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/checklist/edit/:id">
          <CEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic/list">
          <LList />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic/add">
          <LAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic/details/:id">
          <LDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic/edit/:id">
          <LEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/partner/list">
          <PaList />
        </PrivateRoute>
        <PrivateRoute exact path="/partner/details/:id">
          <PaDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/sr/list">
          <SrList />
        </PrivateRoute>
        <PrivateRoute exact path="/sr/add">
          <SrAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/sr/details/:id">
          <SrDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/sr/edit/:id">
          <SrEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/rider/list">
          <RList />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/rider/add">
                    <RAdd />
                </PrivateRoute> */}
        <PrivateRoute exact path="/rider/details/:id">
          <RDetail />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/rider/edit/:id">
                    <REdit />
                </PrivateRoute> */}
        <PrivateRoute exact path="/location/list">
          <LOList />
        </PrivateRoute>
        <PrivateRoute exact path="/location/add">
          <LOAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/location/details/:id">
          <LODetail />
        </PrivateRoute>
        <PrivateRoute exact path="/location/edit/:id">
          <LOEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/buffer-stock/reconcile">
          <BufferStockList />
        </PrivateRoute>

        <PrivateRoute exact path="/buffer-stock/add">
          <BufferStockAdd />
        </PrivateRoute>

        <PrivateRoute exact path="/buffer-stock/sr">
          <BufferStockSR />
        </PrivateRoute>
        <PrivateRoute exact path="/buffer-stock/edit">
          <BufferStockEdit />
        </PrivateRoute>

        <PrivateRoute exact path="/category/list">
          <CaList />
        </PrivateRoute>
        <PrivateRoute exact path="/category/add">
          <CaAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/category/details/:id">
          <CaDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/category/edit/:id">
          <CaEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/role/list">
          <RoList />
        </PrivateRoute>
        <PrivateRoute exact path="/role/add">
          <RoAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/role/details/:id">
          <RoDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/role/edit/:id">
          <RoEdit />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/attribute/list">
          <AtList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/attribute/add">
          <AtAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/attribute/details/:id">
          <AtDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/attribute/edit/:id">
          <AtEdit />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/attributeSet/list">
          <AtSetList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/attributeSet/add">
          <AtSetAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/attributeSet/details/:id">
          <AtSetDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/attributeSet/edit/:id">
          <AtSetEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/purchaseOrder/list">
          <PoList />
        </PrivateRoute>
        <PrivateRoute exact path="/purchaseOrder/add">
          <PoAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/purchaseOrder/details/:id">
          <PoDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/kpi/sales">
          <SalesKpi />
        </PrivateRoute>

        <PrivateRoute exact path="/kpi/delivery">
          <DeliveryKpi />
        </PrivateRoute>
        <PrivateRoute exact path="/purchaseOrder/edit/:id">
          <PoEdit />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/purchaseOrder/details/product-qc/:order_id/:line_item_id"
        >
          <PoPQC />
        </PrivateRoute>
        <PrivateRoute exact path="/dhPurchaseOrder/list">
          <DhPoList />
        </PrivateRoute>
        <PrivateRoute exact path="/dhPurchaseOrder/add">
          <DhPoAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/dhPurchaseOrder/details/:id">
          <DhPoDetail />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/dhPurchaseOrder/details/product-qc/:order_id/:line_item_id"
        >
          <DhPoPQC />
        </PrivateRoute>
        {/* DH return orders */}
        {/* <PrivateRoute exact path="/dhReturnOrder/list">
          <DhRoList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/dhReturnOrder/add">
          <DhRoAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/dhReturnOrder/details/:id">
          <DhRoDetail />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/dhReturnOrder/details/product-qc/:order_id/:line_item_id"
        >
          <DhRoPQC />
        </PrivateRoute>
        <PrivateRoute exact path="/customerOrder/list">
          <COList />
        </PrivateRoute>
        <PrivateRoute exact path="/returnOrder/details/:id">
          <ROPDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/returnOrder/list">
          <ROrderList />
        </PrivateRoute>
        <PrivateRoute exact path="/customerOrder/details/:id">
          <CODetails />
        </PrivateRoute>
        <PrivateRoute exact path="/customerOrder/pack/:id">
          <CustomersOrderPackForm />
        </PrivateRoute>
        <PrivateRoute exact path="/returnOrder/partners/details/:id">
          <ROPDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/returnOrder/customers/details/:id">
          <ROCDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/returnOrder/list">
          <ROrderList />
        </PrivateRoute>
        <PrivateRoute exact path="/ra/add">
          <RACreate />
        </PrivateRoute>
        <PrivateRoute exact path="/ra/edit/:id">
          <RAEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/ra/list">
          <RAList />
        </PrivateRoute>
        <PrivateRoute exact path="/ra/details/:id">
          <RADetail />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/route/add">
                    <RouAdd />
                </PrivateRoute> */}
        <PrivateRoute exact path="/company/list">
          <CompanyList />
        </PrivateRoute>
        <PrivateRoute exact path="/company/add">
          <CompanyAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/company/details/:id">
          <CompanyDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/company/edit/:id">
          <CompanyEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/brands/list">
          <BrandList />
        </PrivateRoute>
        <PrivateRoute exact path="/brands/details/:id">
          <BrandDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/brands/add">
          <BrandAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/brands/edit/:id">
          <BrandEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/blockItems/list">
          <BlockItemList />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/route/edit/:id">
                    <RouEdit />
                </PrivateRoute> */}
        <PrivateRoute exact path="/route/list">
          <RouList />
        </PrivateRoute>
        <PrivateRoute exact path="/route/details/:id">
          <RouDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/route/collect/:id">
          <RouteCollect />
        </PrivateRoute>
        <PrivateRoute exact path="/rider/collect/:id">
          <RiderCollect />
        </PrivateRoute>
        <PrivateRoute exact path="/temporaryImages">
          <TemporaryImages />
        </PrivateRoute>
        <PrivateRoute exact path="/uploadImage">
          <UploadImage />
        </PrivateRoute>
        <PrivateRoute exact path="/common-slider/list">
          <CommonSliderList />
        </PrivateRoute>
        <PrivateRoute exact path="/common-slider/add">
          <CommonSiderAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/common-slider/edit/:id">
          <CommonSliderEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/promotional-slider/list">
          <PromotionalSliderList />
        </PrivateRoute>
        <PrivateRoute exact path="/promotional-slider/add">
          <PromotionalSliderAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/promotional-slider/details/:id">
          <PromotionalSliderDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/inventory/list">
          <InventoryList />
        </PrivateRoute>
        <PrivateRoute exact path="/reconciliation">
          <Reconciliation />
        </PrivateRoute>
        <PrivateRoute exact path="/promotions/list">
          <PromotionList />
        </PrivateRoute>
        <PrivateRoute exact path="/promotions/add">
          <PromotionAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/promotions/details/:id">
          <PromotionDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/promotions/edit/:id">
          <PromotionEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/flashSale/list">
          <FlashSaleList />
        </PrivateRoute>
        <PrivateRoute exact path="/flashSale/add">
          <FlashSaleAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/flashSale/details/:id">
          <FlashSaleDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/flashSale/edit/:id">
          <FlashSaleEdit />
        </PrivateRoute>
        {/* promo coupon */}
        <PrivateRoute exact path="/promo-coupon/list">
          <PromoCouponList />
        </PrivateRoute>
        <PrivateRoute exact path="/promo-coupon/add">
          <PromoCouponAdd />
        </PrivateRoute>
        <PrivateRoute exact path="/promo-coupon/details/:id">
          <PromoCouponDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/promo-coupon/edit/:id">
          <PromoCouponEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/po-failed-items/list">
          <POQCFIList />
        </PrivateRoute>
        <PrivateRoute exact path="/igt-failed-items/list">
          <IGTQCFIList />
        </PrivateRoute>
        <PrivateRoute exact path="/rco-failed-items/list">
          <RCOQCFIList />
        </PrivateRoute>
        <PrivateRoute exact path="/rco-failed-items/list">
          <RCOQCFIList />
        </PrivateRoute>
        <PrivateRoute exact path="/returnPackedItem/details/:id">
          <RPID />
        </PrivateRoute>
        <PrivateRoute exact path="/blockItems/list">
          <BlockItemList />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/reviews/list">
          <ReviewList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/reviews/details/:id">
          <ReviewDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/product-request/list">
          <RequestList />
        </PrivateRoute>
        <PrivateRoute exact path="/product-request/details/:id">
          <RequestDetails />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/contact-us/list">
          <ContactUsList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/contact-us/details/:id">
          <ContactUsDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/acc-delete-reason/add">
          <ADRCreate />
        </PrivateRoute>
        <PrivateRoute exact path="/acc-delete-reason/edit/:id">
          <ADREdit />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/acc-delete-reason/list">
          <ADRList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/acc-delete-reason/details/:id">
          <ADRDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/help-topic/add">
          <HelpTopicCreate />
        </PrivateRoute>
        <PrivateRoute exact path="/help-topic/edit/:id">
          <HelpTopicEdit />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/help-topic/list">
          <HelpTopicList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/help-topic/details/:id">
          <HelpTopicDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/articles/add">
          <ArticleCreate />
        </PrivateRoute>
        <PrivateRoute exact path="/articles/edit/:id">
          <ArticleEdit />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/articles/list">
          <ArticleList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/articles/details/:id">
          <ArticleDetails />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/feedbacks/list">
          <FeedbackList />
        </PrivateRoute> */}
        {/* meta info */}
        {/* <PrivateRoute exact path="/meta-info/list">
          <MetaList />
        </PrivateRoute> */}
        <PrivateRoute exact path="/meta-info/add">
          <MetaCreate />
        </PrivateRoute>
        <PrivateRoute exact path="/meta-info/edit/:id">
          <MetaEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/meta-info/details/:id">
          <MetaDetails />
        </PrivateRoute>
        {/* coupon info */}
        <PrivateRoute exact path="/coupon/list">
          <CouponList />
        </PrivateRoute>
        <PrivateRoute exact path="/coupon/add">
          <CouponCreate />
        </PrivateRoute>
        <PrivateRoute exact path="/coupon/edit/:id">
          <CouponEdit />
        </PrivateRoute>
        <PrivateRoute exact path="/coupon/details/:id">
          <CouponDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/audit-logs/list">
          <AuditLogs />
        </PrivateRoute>
        <PrivateRoute exact path="/configurations/:key">
          <EditConfiguration />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/config/districts/add">
                    <DistrictCreate />
                </PrivateRoute>

                <PrivateRoute exact path="/config/districts/edit/:id">
                    <DistrictEdit />
                </PrivateRoute>

                <PrivateRoute exact path="/config/districts/list">
                    <DistrictList />
                </PrivateRoute>

                <PrivateRoute exact path="/config/districts/details/:id">
                    <DistrictDetails />
                </PrivateRoute>

                <PrivateRoute exact path="/config/thanas/add">
                    <ThanaCreate />
                </PrivateRoute>

                <PrivateRoute exact path="/config/thanas/edit/:id">
                    <ThanaEdit />
                </PrivateRoute>

                <PrivateRoute exact path="/config/thanas/list">
                    <ThanaList />
                </PrivateRoute>

                <PrivateRoute exact path="/config/thanas/details/:id">
                    <ThanaDetails />
                </PrivateRoute>

                <PrivateRoute exact path="/config/areas/add">
                    <AreaCreate />
                </PrivateRoute>
                
                <PrivateRoute exact path="/config/areas/edit/:id">
                    <AreaEdit />
                </PrivateRoute>

                <PrivateRoute exact path="/config/areas/list">
                    <AreaList />
                </PrivateRoute>

                <PrivateRoute exact path="/config/areas/details/:id">
                    <AreaDetails />
                </PrivateRoute> */}
        {/* <PrivateRoute exact path="/config/districts/details/:id">
                    <ArticleDetails/>
                </PrivateRoute> */}
        {wareHouseType !== "central" && (
          <>
            <PrivateRoute exact path="/sto-failed-items/list">
              <STOQCFIList />
            </PrivateRoute>
            {/* route */}
            <PrivateRoute exact path="/route/add">
              <RouAdd />
            </PrivateRoute>

            <PrivateRoute exact path="/route/edit/:id">
              <RouEdit />
            </PrivateRoute>

            {/* rider */}
            <PrivateRoute exact path="/rider/add">
              <RAdd />
            </PrivateRoute>

            <PrivateRoute exact path="/rider/edit/:id">
              <REdit />
            </PrivateRoute>

            {/* partner */}
            <PrivateRoute exact path="/partner/add">
              <PaAdd />
            </PrivateRoute>

            <PrivateRoute exact path="/partner/edit/:id">
              <PaEdit />
            </PrivateRoute>

            <PrivateRoute exact path="/multicat-partner/import-file">
              <MulticatPartnerImportCSV />
            </PrivateRoute>

            <PrivateRoute exact path="/multicat/sr-creation">
              <SRCreationCSV />
            </PrivateRoute>

            <PrivateRoute exact path="/multicat/dm-creation">
              <DMCreation />
            </PrivateRoute>

            {/* <PrivateRoute exact path="/partner-margin">
                            <PartnerMargin/>
                        </PrivateRoute> */}

            <PrivateRoute exact path="/challan/list">
              <ForwardChallanList />
            </PrivateRoute>

            <PrivateRoute exact path="/challan/details/:id">
              <ForwardChallanDetail />
            </PrivateRoute>

            <PrivateRoute exact path="/challan/create">
              <ForwardChallanForm />
            </PrivateRoute>

            <PrivateRoute exact path="/return-challan/list">
              <BackwardChallanList />
            </PrivateRoute>

            <PrivateRoute exact path="/return-challan/details/:id">
              <BackwardChallanDetail />
            </PrivateRoute>

            <PrivateRoute exact path="/report/volume">
              <Volume />
            </PrivateRoute>
            <PrivateRoute exact path="/report/memo">
              <Memo />
            </PrivateRoute>
          </>
        )}
        <Route>
          <p>404 | Page not found</p>
          <p>
            <Link to="/">Back to Home</Link>
          </p>
        </Route>
      </Switch>
    </Router>

    <ToastContainer position="bottom-right" autoClose={3000} />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
