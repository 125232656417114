import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData} from "../helper/utils";

export const addAttribute = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + '/product_attributes', {product_attribute: data}, {
      headers: {}
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject('Something went wrong')
      })
  })
}

export const updateAttribute = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/product_attributes/${id}`, {product_attribute:data}, {
      headers: {}
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  });
}

export const getAttributeList = params => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/product_attributes`, {
      params,
      headers: {}
    }).then(res => {
      console.log(res)
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res)
      }
    }).catch(err => {
      reject(err.message)
    })

  })
}

export const getAttributeDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {

    axios.get(API_BASE_URL + `/product_attributes/${id}`, {
      headers: {}
    }).then(res => {
      resolve(res.data);
      // if (res.data.status === 1 || res.data.status === 1) {
      //   resolve(res.data);
      // }
    }).catch(err => {
      reject(err.message)
    })

  })
}

export const deleteAttribute = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/product_attributes/${id}`, {
      headers: {}
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}