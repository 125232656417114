import { Button, Modal, Form } from "react-bootstrap";
import { FiTrash } from "react-icons/all";
import React, {useState} from "react";

function FormModal(props)  {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        form.className += " was-validated";
      } else {
        props.handleAction()
        handleClose()
      }
  }
  return (
    <>
      {
        props.customBtn ?
            <Button className={props.btnClassName} variant={props.variant} size={`sm`} onClick={handleShow}>{props.btnText}</Button> 
            :
            <Button className="text-danger" size={`sm`} variant={`default`} onClick={handleShow}><FiTrash/></Button>
      }
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <hr></hr>
        <Form onSubmit={handleFormSubmit}>
            <Modal.Body>
                {props.body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                Close
                </Button>
                <Button
                  size="btn-sm"
                  type={"submit"}
                  variant="primary"
                  >Submit
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>
    </>
  );
}

export default FormModal;
