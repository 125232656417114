import React from 'react'
import moment from 'moment'
import { ceil } from 'lodash';

const range = (from, to, step) =>
  [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);

export default function ChallanCopy({orderItems=[], cancelledOrders =[], returnRequests=[], from, to}) {
    let indexx = 0
    let indexOrder = 0
    let indexCancel = 0
  return (
    <div className="block-body print-outer p-5 only-print">
        <div className='d-flex justify-content-end'>Print on:{moment(new Date()).format("DD-MM-YYYY hh:mm:ss")}</div>
        <div className='d-flex justify-content-between'>
            <div className='d-flex iustify-content-start flex-column'>
            <h3>From</h3>
            <div><strong></strong> {from?.name}</div>
            <div><strong>Phone:</strong> {from?.phone}</div>
            </div>
            <img src="/agami-logo.png" height={30}></img>
            <div className='d-flex justify-content-start flex-column'>
            <h3>To</h3>
            <div><strong></strong> {to?.name}</div>
            <div><strong>Phone:</strong> {to?.phone}</div>
            </div>
        </div>
        <div className='d-flex justify-content-between'>
            <div className='w-50 border border-dark text-center'>To be filled up by FC</div>
            <div className='w-50 border border-dark text-center'>To be filled up by DH main House</div>
        </div>

        <div className='d-flex justify-content-between'>
            <div className='w-50 border border-dark text-center'>Shipment to DH order ID</div>
            <div className='w-50 text-center'>
            <div className='d-flex justify-content-between'>
                <div className='border border-dark text-center' style={{width: 'calc(100% / 3)'}}>QC failed Order ID</div>
                <div className='border border-dark text-center' style={{width: 'calc(100% / 3)'}}>Return Order ID</div>
                <div className='border border-dark text-center' style={{width: 'calc(100% / 3)'}}>Return QC Failed Order ID</div>
            </div>
            </div>
        </div>
        <div className='d-flex justify-content-between'>
            <div className='w-50 d-flex justify-content-between flex-wrap'>
            <table style={{flex: 1}}>
                <tbody>
                {
                    range(1, orderItems?.length > 200 ? ceil(orderItems?.length /8) : 25, 1)?.map((item, index)=>{
                    return (
                        <tr style={{height: '40px'}} key={index}>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 8)'}}>{orderItems[indexx++]?.order?.id}</td>
                        </tr>
                    )
                    })
                }
                </tbody>
            </table>
            </div>
            <div style={{width: '50%'}}>
            <div className='d-flex justify-content-between flex-wrap'>
            <table  style={{flex: 1}}>
                <tbody>
                {
                    range(1, orderItems?.length > 200 ? ceil(orderItems?.length /8) : 25, 1)?.map((item, index)=>{
                    return (
                        <tr style={{height: '40px'}} key={index}>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 3)'}}>{cancelledOrders[indexOrder++]?.order_id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 3)'}}>{returnRequests[indexCancel++]?.id}</td>
                        <td className='text-center border border-dark' style={{width: 'calc(100% / 3)'}}></td>
                        </tr>
                    )
                    })
                }
                </tbody>
            </table>
            </div>
            </div>
        </div>
        <div className='d-flex justify-content-between'>
            <div className='w-50 border border-dark text-left' style={{height: '60px'}}>Total:</div>
            <div className='w-50 text-center'>
                <div className='d-flex justify-content-between' style={{height: '60px'}}>
                    <div className='text-left border border-dark' style={{width: 'calc(100% / 3)'}}>Total QC Failed</div>
                    <div className='text-left border border-dark' style={{width: 'calc(100% / 3)'}}>Total Returned</div>
                    <div className='text-left border border-dark' style={{width: 'calc(100% / 3)'}}>Total Returned QC Failed</div>
                </div>
            </div>
        </div>
        <div className='d-flex justify-content-between'>
        <div className='w-50 border border-dark text-left' style={{height: '60px'}}>Inventory Staff's Sign:</div>
            <div className='w-50 text-center'>
                <div className='d-flex justify-content-between' style={{height: '60px'}}>
                    <div className='w-50 text-left border border-dark'>DH Godown Keeper's Sign</div>
                    <div className='w-50 text-left border border-dark'>DH Cashier's Sign</div>
                </div>
            </div>
        </div>
    </div>
  )
}
