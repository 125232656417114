
import React, {useState, useEffect} from 'react';
import App from "../../App";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import HelpModuleForm from "./Form";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getData, putData } from "../../services/baseServices"

const Create = () => {
    const [helpTopic, setHelpTopic] = useState({
        id: 0,
        title: '',
        bn_title: '',
        public_visibility: true
    }) 
    const [metaInfo, setMetaInfo] = useState({})


    const history = useHistory()
    const {id} = useParams()
    useEffect(()=>{
        getData(`help_topics/${id}`)
        .then(res => {
            setHelpTopic(res?.data)
            const metaData ={
              metaTitle:res?.data.meta_info.meta_title,
              metaTitleBn:res?.data.meta_info.bn_meta_title,
              metaDescription:res?.data.meta_info.meta_description,
              metaDescriptionBn:res?.data.meta_info.bn_meta_description,
              metaKeywords:res?.data.meta_info.meta_keyword,
              metaKeyKeywordsBn:res?.data.meta_info.bn_meta_keyword,
            }
          setMetaInfo(metaData)
        })
        .catch(errMsg => {
        });
    },[id])

    const onFormSubmit = (data) => {
      
      const meta_datum_attributes = {
        meta_title: metaInfo.metaTitle,
        bn_meta_title: metaInfo.metaTitleBn, 
        meta_description: metaInfo.metaDescription, 
        bn_meta_description: metaInfo.metaDescriptionBn,
        meta_keyword: metaInfo.metaKeywords,
        bn_meta_keyword: metaInfo.metaKeyKeywordsBn
    }
    let updatedData = {...data, meta_datum_attributes}
        putData(`help_topics/${id}`,updatedData)
        .then(res => {
          if(res.success) {
            history.push(`/help-topic/list`);
            toast.success(res.message);
          }
          else {
            toast.error(res.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg);
        }); 
    }
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Edit Help Module</h2>
                <div className="ml-auto">
                    <Link to={`${process.env.PUBLIC_URL}/help-topic/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
                </div>
            </div>

            <div className="card-block">
                <div className="block-body">
                    <>
                    <HelpModuleForm
                        helpTopic = {helpTopic}
                        metaInfo={metaInfo}
                        setMetaInfo={setMetaInfo}
                        onFormSubmit = {onFormSubmit}
                        edit= {true}
                    />
                    </>
                </div>
            </div>

        </App>
    );
}
export default withRouter(Create);