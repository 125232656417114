import { Controller } from "react-hook-form";
import { Input } from "antd";

const InputControl = ({
  name,
  type = "text",
  control,
  errMsg,
  isDisabled = false,
  placeholder = "",
  className = "",
  defaultValue = "",
}) => {
  return (
    <div className="relative">
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Input
            allowClear
            {...field}
            type={type}
            id={name}
            className={`rounded my-1 py-2 ${className}`}
            status={errMsg && "error"}
            // size={size}
            disabled={isDisabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
          />
        )}
      />
      <p className="text-danger">
        {errMsg}
      </p>
    </div>
  );
};

export default InputControl;
