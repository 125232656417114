import React, { Component } from 'react';
import { FaLongArrowAltLeft } from 'react-icons/all';
import { Link } from 'react-router-dom';
import App from './../../App';
import { Rating } from '@material-ui/lab/Rating';
import { getDetails } from './../../services/baseServices';
import { withRouter } from 'react-router-dom';

class ReviewDetails extends Component {
    constructor() {
        super();
        this.state = {
            reviewDetails: null,
            isLoading: true
        }
      };

      componentWillMount() {
        console.log("match", this.props.match)
        this.getReviewDetails();
        
      }

      getReviewDetails = () =>{
        getDetails("reviews", this.props.match.params.id)
        .then((res) => {
          console.log("response", res);
          if(res.status === 200){
            this.setState({ reviewDetails: res.data}, ()=>{
              console.log("reviewDetails", this.state);
              this.setState({isLoading: false})
            });
          }else{
            this.setState({isLoading: false})
          }
        })
        .catch((errMsg) => {
          this.setState({isLoading: false})
          console.log("Error Message: ", errMsg);
        });
      }

    render() {
      
        return (
            <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Review Details</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/reviews/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
            </div>
          </div>

          {
            this.state.isLoading ?
              <div className={`card-block`}>
                <div className="block-body">
                  Loading...
                </div>
              </div>
              :
              <div className="card-block">
						<div className="block-header">
							<h4 className="block-title">Review Details</h4>
						</div>
						{this.state.isLoading ? (
							<div className={`card-block`}>
								<div className="block-body">Loading...</div>
							</div>
						) : (
							<div className="block-body">
								<>
									<table className="info-table col-4">
										<tbody>
											<tr>
												<td style={{ width: "250px" }}>Review Id</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reviewDetails.id}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>
													Title
												</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reviewDetails.title}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Description</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reviewDetails.description}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Rating</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reviewDetails.rating}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>User Id</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reviewDetails.user_id}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Review Type</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reviewDetails.reviewable_type}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Review For</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reviewDetails.reviewable_name}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Images</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>
													<div>
														{this.state.reviewDetails.images &&
														this.state.reviewDetails.images.map((image, index, array) =>
														<div className="user-avatar" key={index}>
															<img src={image} alt="" style={{width: "80px", height: "80px", marginRight: "10px"}}/>
														</div>
														)}
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</>
							</div>
						)}
					</div>
					
          }
          
        </div>
      </App>
        );
    }
}

export default withRouter(ReviewDetails);