import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, getObjectToFormData} from "../helper/utils";

export const getOrderList = params => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/customer_orders/list`, {
      params,
      headers: {}
    }).then(res => {
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res)
      }
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const filterCustomerList = params => {
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/customer_orders/list`, {
      params,
      headers: { }
    }).then(res => {
      if(res.data.error){
        reject(res.data.error)
      } else{
        resolve(res.data)
      }
    }).catch(err => {
      reject(err.message)
    })
  })
}
