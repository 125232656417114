import React, { useState } from "react";
import App from "../../../App";
import { Button } from "react-bootstrap";
import { getData2 } from "../../../services";
import {
  excel2dDataMapper,
  fileSuffixDateRange,
} from "../../../helper/reportHelper";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getFormattedDate } from "../../../helper/utils";
import moment from "moment";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const ExcelJS = require("exceljs");

const Volume = () => {
  const [dateRange, setDateRange] = useState({
    start_date: getFormattedDate(moment().subtract(30, "days"), "DD/MM/YYYY"),
    end_date: getFormattedDate(moment().subtract(0, "days"), "DD/MM/YYYY"),
  });
  const [report_type, setReportType] = useState("pre_order");
  const [loader, setLoader] = useState(false);

  const handleReportDownload = async (e) => {
    e.preventDefault();
    const params = { ...dateRange, report_type };

    setLoader(true);
    try {
      const res = await getData2("/multicat_reports/volumes", params);
      const mappedData = excel2dDataMapper(res.data);

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Data");

      mappedData.forEach((row) => {
        sheet.addRow(row);
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileSuffix = fileSuffixDateRange(
        dateRange.start_date,
        dateRange.end_date
      );
      const filename = `${report_type}-volume-${fileSuffix}.xlsx`;

      if (window.navigator.msSaveOrOpenBlob) {
        // For IE/Edge
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      // Handle any errors here
      toast.error("Error downloading report:");
      // Display an error message to the user or handle the error accordingly
    }
    setLoader(false);
  };

  const handleOnDateChange = (e, picker) => {
    setDateRange({
      start_date: picker.startDate.format(),
      end_date: picker.endDate.format(),
    });
  };

  return (
    <App layout={""}>
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title"> Volume Summary </h2>
        </div>
        <form className="form-inline mb-3">
          <div className="form-group mr-3">
            <label>
              Report Type
              <select
                id="report_type"
                name="report_type"
                label="Report Type"
                className="form-control form-control-sm m-2"
                style={{ textAlign: "left", minWidth: "150px" }}
                onChange={(event) => {
                  setReportType(event.target.value); // Update state with the selected value
                }}
                value={report_type} // Bind the value to the state variable
              >
                <option value="pre_order">Pre Order</option>
                <option value="delivery">Delivery</option>
              </select>
            </label>
            <DateRangePicker
              initialSettings={{
                showDropdowns: true,
                locale: { format: "DD/MM/YYYY" },
                startDate: moment(dateRange.start_date, "DD/MM/YYYY"),
                endDate: moment(dateRange.end_date, "DD/MM/YYYY"),
                maxSpan: { months: 2 },
                autoApply: true,
              }}
              onApply={(e, picker) => handleOnDateChange(e, picker)}
            >
              <input
                type="text"
                value={
                  dateRange.start_date && dateRange.end_date
                    ? `${getFormattedDate(
                        dateRange.start_date,
                        "DD/MM/YYYY"
                      )} - ${getFormattedDate(
                        dateRange.end_date,
                        "DD/MM/YYYY"
                      )}`
                    : ""
                }
                style={{ textAlign: "left", minWidth: "150px" }}
                placeholder={"Select date range"}
                className="form-control form-control-sm mr-2"
              />
            </DateRangePicker>
            <Button
              size="sm"
              type={`submit`}
              onClick={handleReportDownload}
              variant="primary"
            >
              Download
            </Button>
          </div>
        </form>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="card-block">
          {/* <div className="card-body"></div> */}
        </div>
      </div>
    </App>
  );
};
export default Volume;
