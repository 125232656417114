import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { toast } from "react-toastify";
import { getPaginatedList, putData, getList } from "../../services/baseServices";
import ConfirmationModal from "../common/Modal"
import QCModal from "../../components/QCModal";
import { TABLES } from './Reconciliation'
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env"

class ReturnRequests extends Component {
	constructor() {
		super();
		this.state = {
            questionnaires:[],
            question_details:{},
            locations:[],
            returnLocations: {},
			returnRequests: [],
			activePage: 1,
			per_page: 15,
			page: 1,
			query_params: {},
			isLoading: true
		};
	}
	handleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	handleReceivingModal = () => {
		this.setState({ showReceivingodal: !this.state.showReceivingodal });
	};

	handleQCModal = () => {
		this.setState({ showQCModal: !this.state.showQCModal });
	};
    getAttributes = (attrArray) => {
        let attrs = "";
        if(!!attrArray){
        attrArray.map((attr)=>(
        attrs+= attr.name+": "+attr.value+", "
        ))}
        return attrs.trimEnd();
    }
    
	getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

	getRetuenList = (params) => {
        this.setState({ isLoading: true })
		getPaginatedList(this.props.url, params)
			.then((res) => {
				this.setState(
					{
						returnRequests: res.data,
						isLoading: false,
						per_page: res.headers["x-per-page"],
						total_data: parseInt(res.headers["x-total"]),
					},
					() => {}
				);
			})
			.catch((errMsg) => {
                this.setState({ isLoading: false })
				console.log("Error Message: ", errMsg);
			});
	};

	componentDidMount() {
		this.setState({ query_params: this.props.queryParams }, () => {
			this.getRetuenList(this.getQueryParams());
		})
        getList("locations")
        .then((res) => {
            this.setState({ locations: res });
        })
        .catch((errMsg) => {});
	}

	handlePageChange = (pageNumber) => {
		this.setState({ page: pageNumber, activePage: pageNumber }, () => {
			this.getRetuenList(this.getQueryParams());
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
		    console.log(this.props.queryParams)
			this.setState({ query_params: this.props.queryParams }, () => {
            	this.getRetuenList(this.getQueryParams());
			})
        }
	}

    handleAfterAction = () => {
        this.setState({ showQCModal: !this.state.showQCModal }, ()=> {
            this.getRetuenList(this.getQueryParams());
        });
    }
   
    debouncedOnReceiveRR = _.debounce((returnId)=>{
        this.receiveReturnRequest(returnId)
    }, DEBOUNCE_TIME);

    handleWithDebounceReceiveRR = async (returnId) => {
        this.debouncedOnReceiveRR(returnId);
    }

    receiveReturnRequest = (returnId) => {
        let url = this.props.whom === "sr" ? "route_returns/receive_order" : `riders/receive_return_order/`+returnId;
        let params = this.props.whom === "sr" ? { "return_id": returnId } : {}
        this.setState(({isWaiting: true}), ()=>{
            putData(url, params)
            .then(res => {
                if(res.status_code === 200) {
                    toast.success("Return collected successfully")
                    this.props.onAfterAnyAction([TABLES.ORDER_SUMMARY, TABLES.ORDER_LIST])
                    this.getRetuenList(this.getQueryParams())
                } else {
                    toast.error(res.message)
                }
            })
            .catch(errMsg => {
                this.setState({isWaiting: false});
                toast.error(errMsg);
            });
        })
    }

    getQuestionnare = (category_id, return_order_id,return_type) =>{

       const question_info={return_order_id,return_type }
        
        getList("questionnaires/returns_questions",{category_id:category_id,return_order_id:return_order_id})
        .then((res) => {
            let questionnaires=res.map(item =>{
                return ({id:item.id, question:item.question, defaultStatus:true})
            })
            this.setState({ questionnaires, question_details:question_info});
        })
        .catch((errMsg) => {});
    }

	setLineItemLocation = (e, line_item_id) => {
        console.log('setlooo',e.target.value, line_item_id)
		const returnLocations = { ...this.state.returnLocations };
		returnLocations["location_id"] = e.target.value;
		returnLocations["line_item_id"] = line_item_id;
        console.log(returnLocations)
		this.setState({ returnLocations: returnLocations }, () => {});
	};

    debouncedOnAssignLocation = _.debounce((return_id)=>{
        this.assignLocation(return_id)
    }, DEBOUNCE_TIME);
    
    handleWithDebounceAssignLocation = async (return_id) => {
        this.debouncedOnAssignLocation(return_id);
    }

	assignLocation = (return_id) => {
		const params = {
            return_order_id:return_id,
            locations: [{...this.state.returnLocations}] 
        } 

        let addRouteOrRiderId = (obj, propName, propValue) =>{
            obj[propName] = propValue
        }
        if(this.props.whom === 'rider'){
            addRouteOrRiderId(params,'rider_id', this.props.rider_id )
        }else{
            addRouteOrRiderId(params,'route_id', this.props.route_id )
        }

		putData('warehouse_variants_locations/return_assign',params)
			.then((res) => {
                if(res.status_code == "200") {
					toast.success(res.message);
                    this.getRetuenList(this.getQueryParams())
				  }    
				  else {
					toast.error(res.message);
				  } 
				})
			
			
			.catch((errMsg) => {
				toast.error(errMsg);
			});
	};

	render() {
		return (
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">Returned Requests</h2>
                </div>
                {
                    this.state.isLoading ?
                        <div>Loading...</div>
                    :
                        <>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th style={{width: '75px'}}>Req. No</th>
                                    <th>Type</th>
                                    <th>Order No</th>
                                    <th>Item</th>
                                    <th>SKU</th>
                                    <th>Price (৳)</th>
                                    <th>Attributes</th>
                                    <th>Status</th>
                                    <th className='text-center' style={{width: '220px'}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.returnRequests?.length>0 ?
                                    this.state.returnRequests?.map((item,key) => (
                                    <tr key={key}>
                                        <th><Link to={`${process.env.PUBLIC_URL}/returnOrder/details/${item.return_id}`}>{item.return_id}</Link></th>
                                        <td>{item.return_type}</td>
                                        <td><Link to={`${process.env.PUBLIC_URL}/customerOrder/details/${item.customer_order_id}`}>{item.customer_order_id}</Link></td>
                                        <td>{item.product_details.title}</td>
                                        <td>{item.product_details?.sku}</td>
                                        <td>{item.product_details.price}</td>
                                        <td>
                                        {
                                            this.getAttributes(item?.product_details.product_attribute_values)
                                        }
                                        </td>
                                        <td>{item.return_status}</td>
                                        <td className="mx-auto text-center">
                                            {item.return_status === "relocation_pending" &&
                                            item.return_type === "packed" ? (
                                                <>
                                                    <Link
                                                        className="btn btn-sm btn-secondary"
                                                        to={`${process.env.PUBLIC_URL}/returnPackedItem/details/${item.return_id}`}
                                                    >
                                                        Unpack
                                                    </Link>
                                                </>
                                            ) : item.return_status === "in_transit" ? (
                                                <OverlayTrigger
                                                    key="view"
                                                    placement="top"
                                                    overlay={
                                                    <Tooltip id={`tooltip-view`}>
                                                        Receive
                                                    </Tooltip>
                                                    }
                                                >
                                                    <ConfirmationModal 
                                                        customBtn = {true}
                                                        btnText = "Receive"
                                                        variant = "success"
                                                        title = "Receive return request" 
                                                        body = {`Are you sure to receive the Return Request(${item.return_id})?`} 
                                                        handleAction = {() =>this.handleWithDebounceReceiveRR(item.return_id)}/>
                                                </OverlayTrigger>
                                                    ) : item.return_status === "qc_pending" ? (
                                                        <>
                                                            <button
                                                                className="btn btn-sm btn-primary text-center"
                                                                onClick={() =>{this.handleQCModal();this.getQuestionnare( item.product_details?.category_id,item.return_id, item.return_type)}}
                                                            >
                                                                Start QC
                                                            </button>
                                                        </>
                                                    ) : item.return_status === "relocation_pending" ? (
                                                <>
                                                    <div className="d-flex">
                                                        <select
                                                            id="location"
                                                            name="location_id"
                                                            className="form-control form-control-sm"
                                                            onChange={(e) => {this.setLineItemLocation(e, item.product_details.line_item_id)}}
                                                        >
                                                            <option value="">--Locations--</option>
                                                            {this.state.locations.length > 0
                                                                ? this.state.locations.map((item, i) => {
                                                                        return (
                                                                            <option value={item.id} key={i}>
                                                                                {item.code}
                                                                            </option>
                                                                        );
                                                                    })
                                                                : ""}
                                                        </select>
                                                        <button className="btn btn-sm btn-info text-white ml-2" 
                                                        onClick={() => this.handleWithDebounceAssignLocation(item.return_id)}>
                                                            Assign
                                                        </button>
                                                    </div>
                                                </>
                                            ) :item.return_status ==='completed'?(""):('')}
                                        </td>
                                    </tr>
                                    ))
                                    :


                                    <tr><td colSpan={8}>No request found</td></tr>
                                }
                                </tbody>
                            </table>
                            <QCModal
                                    show={this.state.showQCModal}
                                    handleClose={this.handleQCModal}
                                    questionnaires={this.state.questionnaires}
                                    question_details={this.state.question_details}
                                    whom = {this.props.whom}
                                    rider_id = {this.props.rider_id}
                                    route_id = {this.props.route_id}
                                    handleAfterAction = {this.handleAfterAction}

                                />
                            {
                                this.state.returnRequests?.length > 0 &&
                                <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.per_page}
                                        totalItemsCount={this.state.total_data}
                                        pageRangeDisplayed={10}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            }
                        </>
                }
                
                
            </div>
		);
	}
}

export default withRouter(ReturnRequests);
