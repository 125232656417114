import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import Pagination from "react-js-pagination";
import { getDetails, getPaginatedList } from "../../services/baseServices";
import OrderList from "../partner/OrderList";
import OrderSummary from "../partner/OrderSummary";
import "../../assets/scss/header.scss";
import ExportCSVAsync from "../common/ExportCSVAsync";
import moment from "moment";
import { Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getFormattedDate, getWarehouseType } from "../../helper/utils";

const wareHouseType = getWarehouseType();
const headers = [
  { label: "Order #", key: "order_id" },
  { label: "Order Date", key: "order_date" },
  { label: "Delivery Date", key: "delivery_date" },
  { label: "Order Type", key: "order_type" },
  { label: "Payment Type", key: "pay_type" },
  { label: "Shipping Type", key: "shipping_type" },
  { label: "Order Status", key: "status" },
  { label: "Price", key: "grand_total" },
  { label: "Partner's Margin", key: "partner_commission" },
];

const loadData = (item) => {
  return {
    order_id: item.order_id,
    order_date: item.order_date,
    delivery_date: item.delivery_date,
    order_type: item.order_type,
    pay_type: item.pay_type,
    shipping_type: item.shipping_type,
    status: item.status,
    grand_total: item.grand_total,
    partner_commission: item.partner_commission,
  };
};

var startDate = moment().subtract(29, "days");
var today = moment();

class Details extends Component {
  state = {
    partner: {},
    schedule: [],
    orderSummary: {},
    customerOrders: [],
    isPartnerLoading: true,
    isOrderSummaryLoading: true,
    isCustomerOrdersLoading: true,
    selectedTab: "received",
    activePage: 1,
    per_page: 5,
    page: 1,
    temp_params: {
      start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
      end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
    },
    query_params: {
      start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
      end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
    },
    start_date_time: startDate,
    end_date_time: today,
  };

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getPaginatedCustomerOrders = (params) => {
    this.setState({
      isCustomerOrdersLoading: true,
      isOrderSummaryLoading: true,
    });

    getPaginatedList(
      `partners/` + this.props.match.params.id + `/customer_orders`,
      params
    )
      .then((res) => {
        this.setState({ per_page: res.headers["x-per-page"] });
        this.setState({ total_data: res.headers["x-total"] });
        this.setState({ customerOrders: res.data });
        this.setState({ isCustomerOrdersLoading: false });
      })
      .catch((errMsg) => {
        this.setState({ isCustomerOrdersLoading: false });
      });
  };

  componentDidMount() {
    getDetails("partners", this.props.match.params.id)
      .then((res) => {
        this.setState({ partner: res.data, metaInfo: res.data?.meta_info });
        this.setState({
          schedule: res.data.schedule.toUpperCase().split("_"),
        });
        this.setState({ isPartnerLoading: false });
      })
      .catch((errMsg) => {});

    this.getPaginatedCustomerOrders(this.getQueryParams());
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedCustomerOrders(this.getQueryParams());
    });
  };

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    this.setState({ ...this.state, page: 1, query_params: tempParam }, () => {
      this.getPaginatedCustomerOrders(this.state.temp_params);
    });
  };

  handleOnApply = (e, picker) => {
    this.setState({
      start_date_time: picker.startDate.format(),
      end_date_time: picker.endDate.format(),
    });
    this.setState({
      temp_params: {
        ...this.state.temp_params,
        start_date_time: getFormattedDate(picker.startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(picker.endDate, "YYYY-MM-DD"),
      },
    });
  };

  render() {
    const { partner } = this.state;
    const { customerOrders } = this.state;
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="page-title" />
            <div className="ml-auto"></div>
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/partner/list`}
                className={`btn btn-sm btn-link`}
              >
                <MdArrowBack /> Back to List
              </Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Partner Details</h4>
            </div>
            {this.state.isPartnerLoading ? (
              <div className={`card-block`}>
                <div className="block-body">Loading...</div>
              </div>
            ) : (
              <div className="block-body" style={{ overflow: "auto" }}>
                <>
                  <table className="info-table col-4">
                    <tbody>
                      <tr>
                        <td style={{ width: "250px" }}>Business Type</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td className={`text-bold`}>
                          {partner?.business_type}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Partner Slug</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td className={`text-bold`}>{partner?.slug}</td>
                      </tr>
                      {wareHouseType === "central" && (
                        <tr>
                          <td style={{ width: "250px" }}>Created By</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td className={`text-bold`}>
                            {partner?.created_by?.name}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td style={{ width: "250px" }}>Distributor</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td className={`text-bold`}>
                          {partner?.distributor_name}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Partner Name</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td className={`text-bold`}>{partner?.name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>
                          Partner Name (Bangla)
                        </td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td className={`text-bold`}>{partner?.bn_name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Status</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td className={`text-bold`}>{partner?.status}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Prompt Sale Status</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td className={`text-bold`}>
                          {partner?.prompt_sale_status}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Phone</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.phone}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Commision Applicale</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {partner?.is_commission_applicable ? "YES" : "NO"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Credit Applicable</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.is_credit_applicable ? "YES" : "NO"}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Credit Amount</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.credit?.credit_value}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Used Credit Amount</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.credit?.used_amount}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>
                          Available Credit Amount
                        </td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.credit?.available_amount}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Schedule</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {this.state.schedule &&
                            this.state.schedule.map((item, index, array) =>
                              index < array.length - 1 ? (
                                <>
                                  <span className="text">{item}</span>,{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  & <span className="text">{item}</span>{" "}
                                </>
                              )
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Companies</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {partner &&
                          partner?.companies &&
                          partner?.companies.length > 0
                            ? partner?.companies
                                .map((item, index) => item.label)
                                .join(", ")
                            : "No companies available"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Channel</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {partner?.channels &&
                            partner?.channels?.map((channel, index, array) =>
                              index < array.length - 1 ? (
                                <>
                                  <span className="text">{channel.name}</span>,{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  & <span className="text">
                                    {channel.name}
                                  </span>{" "}
                                </>
                              )
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Address</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.addresses?.address_line}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Area</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.addresses?.area_name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Thana</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.addresses?.thana_name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>District</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{partner?.addresses?.district_name}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              </div>
            )}
          </div>
          <div className="pb-4 text-center">
            <div className="d-inline-block">
              <DateRangePicker
                onApply={(e, picker) => this.handleOnApply(e, picker)}
                initialSettings={{
                  startDate: this.state.start_date_time,
                  endDate: this.state.end_date_time,
                  maxSpan: { months: 3 },
                  showDropdowns: true,
                  autoApply: true,
                  locale: { format: "DD/MM/YYYY" },
                }}
              >
                <input
                  type="text"
                  value={
                    this.state.start_date_time && this.state.end_date_time
                      ? `${getFormattedDate(
                          this.state.start_date_time,
                          "DD/MM/YYYY"
                        )} - ${getFormattedDate(
                          this.state.end_date_time,
                          "DD/MM/YYYY"
                        )}`
                      : ""
                  }
                  style={{ textAlign: "left", minWidth: "150px" }}
                  placeholder={"Select date range"}
                  className="form-control form-control-sm"
                />
              </DateRangePicker>
            </div>
            <div className="d-inline-block ml-2">
              <Button
                size="sm"
                type={`submit`}
                onClick={this.handleOnSearch}
                variant="primary"
              >
                Filter
              </Button>
            </div>
          </div>

          {/* <OrderSummary orderSummary={this.state.orderSummary} /> */}

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Partner's Customer Orders</h4>

              <div className="ml-auto">
                <ExportCSVAsync
                  fileName={
                    "Partners Customer Orders-" +
                    getFormattedDate(new Date(), "DD-MM-YYYY") +
                    ".csv"
                  }
                  Url={`/partners/${partner?.id}/customer_orders_export`}
                  headers={headers}
                  params={this.state.temp_params}
                  loadData={loadData}
                  title="Partner's Customer Orders"
                />
              </div>
            </div>
            {this.state.isCustomerOrdersLoading ? (
              <div className={`card-block`}>
                <div className="block-body">Loading...</div>
              </div>
            ) : (
              <>
                <div className="block-body">
                  <OrderList customerOrders={this.state.customerOrders} />
                </div>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);
