import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getList } from "../../services/baseServices";
import SearchComponent from "../product/Bundle/SearchComponent";
import PackForm from "./Form";
import { uuidv4 } from "../../helper/utils";
import $ from 'jquery'

class Add extends Component {
  constructor() {
    super();

    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  state = {
    formData: {},
    routeInfo: {},
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false,
    searchText: "",
    searchResult: { item_count: 0, variants: [] },
    loadingState: "initial",
    selectedProducts: [],
    locations: [],
    total_amount: 0,
    payTermsInfo: {},
  };

  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    this.setState({ formData }, () => {});
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    getList("locations")
      .then((res) => {
        this.setState({ locations: res });
      })
      .catch((errMsg) => {});
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ loadingState: "initial" });
    }
  }

  sendQuery = () => {
    const targetedURL = `bundles/search?search_string=${this.state.searchText}`;
    this.setState({ loadingState: "loading" });
    getList(targetedURL)
      .then((res) => {
        this.setState({ loadingState: "loaded", searchResult: res.data });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: "initial",
          searchResult: { item_count: 0, variants: [] },
        });
      });
  };

  handleOnChange = (e) => {
    this.setState({ searchText: e.target.value });
    if (e.target.value.length >= 3) {
      this.sendQuery();
    } else {
      this.setState({
        loadingState: "initial",
        searchResult: { item_count: 0, variants: [] },
      });
    }
  };

  handleOnSelect = (variant) => {
    let selectedProducts = [...this.state.selectedProducts];
    const found = selectedProducts.find(
      (element) => element.bundle_variant_id === variant.id
    );

    if (found === undefined) {
      if (selectedProducts.length > 0) {
        toast.info(
          "Couldn't be added because already have a bundle. You can remove current one to add."
        );
      } else {
        let tempBundleVariants = [];
        variant.bundle_variants.forEach((bv) => {
          tempBundleVariants.push({
            variant_id: bv.variant_id,
            sku: bv.sku,
            quantity: bv.quantity,
            locations: bv.locations,
            available_quantity: bv.warehouse_available_quantity,
            allocated: 1,
            packed: [
              { id: uuidv4(), qr_code: "", location_id: "", quantity: 1 },
            ],
          });
        });
        const toBpushed = {
          bundle_variant_sku: variant.sku,
          product_title: variant.product_title,
          bundle_variant_id: variant.id,
          bundle_location_id: "",
          bundle_variants: tempBundleVariants,
        };

        selectedProducts.push(toBpushed);
        this.setState({
          loadingState: "initial",
          selectedProducts,
        });
      }
    } else {
      toast.info("Already selected.");
    }
  };

  handleVariantQuantityChange = (total_bundle, e, parentSku, childsku, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childsku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        const targetedRowIndex = theProduct.bundle_variants[
          cIndex
        ].packed.findIndex((x) => x.id === item_id);

        const canAdd = this.checkQuantityLimitOfSKU(
          total_bundle,
          parentSku,
          childsku,
          parseInt(e.target.value),
          item_id
        );
        // check can update Q?
        if (canAdd) {
          theProduct.bundle_variants[cIndex].packed[targetedRowIndex].quantity =
            parseInt(e.target.value);
          selectedProducts[pIndex] = theProduct;
          this.setState({ selectedProducts }, () =>
            this.countTotalNumOfAllocatedQuantityUnderSKU(
              parentSku,
              cIndex,
              this.state.selectedProducts[pIndex].bundle_variants[cIndex].packed
            )
          );
        } else toast.info("Could not upgrade due to quantity limit exceed");
      }
    }
  };

  isThisLocationAlreadyAssignedUnderSKU = (pIndex, cIndex, loc_id) => {
    let { selectedProducts } = this.state;
    return selectedProducts[pIndex].bundle_variants[cIndex].packed.some(
      (element) => element.location_id === loc_id
    );
  };

  setVariantsLocations = (e, parentSku, childSku, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childSku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        const targetedRowIndex = theProduct.bundle_variants[
          cIndex
        ].packed.findIndex((x) => x.id === item_id);

        // check already assigned location?

        if (e.target.value === "") {
          theProduct.bundle_variants[cIndex].packed[
            targetedRowIndex
          ].location_id = "";
          selectedProducts[pIndex] = theProduct;
          this.setState({ selectedProducts });
        } else {
          if (
            !this.isThisLocationAlreadyAssignedUnderSKU(
              pIndex,
              cIndex,
              parseInt(e.target.value)
            )
          ) {
            const locIndex = theProduct.bundle_variants[
              cIndex
            ].locations.findIndex((loc) => loc.id === parseInt(e.target.value));
            if (pIndex !== -1) {
              if (
                theProduct.bundle_variants[cIndex].packed[targetedRowIndex]
                  .quantity >
                theProduct.bundle_variants[cIndex].locations[locIndex].quantity
              ) {
                toast.info("Insuficient quantity");
              } else {
                theProduct.bundle_variants[cIndex].packed[
                  targetedRowIndex
                ].location_id = parseInt(e.target.value);
                selectedProducts[pIndex] = theProduct;
                this.setState({ selectedProducts });
              }
            }
          } else {
            theProduct.bundle_variants[cIndex].packed[
              targetedRowIndex
            ].location_id = "";
            selectedProducts[pIndex] = theProduct;
            this.setState({ selectedProducts }, () => {
              $("select#"+item_id).val("").trigger("change");
              toast.info("Already assigned location. Please choose other.");
            });
          }
        }
      }
    }
  };

  setBundleLocation = (e, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === item_id
    );

    if (pIndex !== -1) {
      selectedProducts[pIndex].bundle_location_id = parseInt(e.target.value);
      this.setState({ selectedProducts });
    }
  };

  handleInputQRCodeOnChange = (e, parentSku, childSku, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childSku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        const targetedRowIndex = theProduct.bundle_variants[
          cIndex
        ].packed.findIndex((x) => x.id === item_id);

        theProduct.bundle_variants[cIndex].packed[targetedRowIndex].qr_code =
          e.target.value;
        selectedProducts[pIndex] = theProduct;
        this.setState({ selectedProducts });
      }
    }
  };

  countTotalNumOfAllocatedQuantityUnderSKU = (sku, cIndex, list) => {
    // count
    // update
    let total = 0;
    list.forEach((item) => {
      total = total + parseInt(item.quantity);
    });

    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === sku
    );

    if (productIndex !== -1) {
      const theProduct = selectedProducts[productIndex];
      theProduct.bundle_variants[cIndex].allocated = total;
      selectedProducts[productIndex] = theProduct;

      this.setState({ selectedProducts });
    }
  };

  checkQuantityLimitOfSKU = (
    total_bundle,
    parentSKU,
    childsku,
    reqQuantity = 1,
    item_id = null
  ) => {
    // find
    // check
    // if exceed msg
    let selectedProducts = [...this.state.selectedProducts];
    const parentFound = selectedProducts.find(
      (element) => element.bundle_variant_id === parentSKU
    );
    if (parentFound !== undefined) {
      const childFound = parentFound.bundle_variants.find(
        (element) => element.sku === childsku
      );
      
      if (childFound !== undefined) {
        if (item_id) {
          let total = 0;
          childFound.packed.forEach((item) => {
            if (item.id !== item_id) {
              total = total + parseInt(item.quantity);
            }
          });

          if (childFound.quantity*total_bundle >= total + reqQuantity) {
            return true;
          } else {
            return false;
          }
        } else {
          if (childFound.quantity*total_bundle - childFound.allocated >= reqQuantity) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  };

  addOneRowUnderSKU = (parentSKU, childsku) => {
    // find
    // add
    // update allocated Q

    let selectedProducts = [...this.state.selectedProducts];
    const pIndex = selectedProducts.findIndex(
      (element) => element.bundle_variant_id === parentSKU
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childsku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        theProduct.bundle_variants[cIndex].packed.push({
          id: uuidv4(),
          qr_code: "",
          location_id: "",
          quantity: 1,
        });
        selectedProducts[pIndex] = theProduct;

        this.setState({ selectedProducts }, () =>
          this.countTotalNumOfAllocatedQuantityUnderSKU(
            parentSKU,
            cIndex,
            this.state.selectedProducts[pIndex].bundle_variants[cIndex].packed
          )
        );
      }
    }
  };

  addItemIntoPackUnderSKU = (total_bundle, parentSKU, childsku) => {
    const canAdd = this.checkQuantityLimitOfSKU(total_bundle,parentSKU, childsku);
    if (canAdd) this.addOneRowUnderSKU(parentSKU, childsku);
    else toast.info("Could not add due to quantity limit exceed");
  };

  handleSKURemove = (e, sku) => {
    e.preventDefault();

    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === sku
    );
    selectedProducts.splice(productIndex, 1);

    this.setState({ selectedProducts }, () => {
      toast.success("Removed successfully.");
    });
  };

  handleVariantRemove = (e, parentSku, childSku, item_id) => {
    e.preventDefault();

    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childSku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        if (theProduct.bundle_variants[cIndex].packed.length === 1) {
          // toast.error("Couldn't remove successfully.");
          toast.info("At least one bundle variant has to be attached.");
        } else {
          const targetedRowIndex = theProduct.bundle_variants[
            cIndex
          ].packed.findIndex((x) => x.id === item_id);

          theProduct.bundle_variants[cIndex].packed.splice(targetedRowIndex, 1);

          this.setState({ selectedProducts }, () => {
            toast.success("Removed successfully.");
            this.countTotalNumOfAllocatedQuantityUnderSKU(
              parentSku,
              cIndex,
              this.state.selectedProducts[pIndex].bundle_variants[cIndex].packed
            );
          });
        }
      }
    }
  };

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Pack Bundle</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/bundle/list`}
              className="btn btn-sm btn-link">
              <FaLongArrowAltLeft /> Back to Bundle Pack List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <SearchComponent
                wrapperRef={this.wrapperRef}
                loadingState={this.state.loadingState}
                handleOnChange={this.handleOnChange}
                handleOnSelect={this.handleOnSelect}
                searchResult={this.state.searchResult}
              />
              <PackForm
                selectedProducts={this.state.selectedProducts}
                locations={this.state.locations}
                handleSKURemove={this.handleSKURemove}
                handleVariantRemove={this.handleVariantRemove}
                handleVariantQuantityChange={this.handleVariantQuantityChange}
                handleInputQRCodeOnChange={this.handleInputQRCodeOnChange}
                setVariantsLocations={this.setVariantsLocations}
                addItemIntoPackUnderSKU={this.addItemIntoPackUnderSKU}
                setBundleLocation={this.setBundleLocation}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Add);
