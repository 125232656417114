import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";

class Edit extends Component {
  state = {
  };

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit SR</h2>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    SR Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter name
                      name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Name in Bangla <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"bn_name"}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter name in bangla
                      name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                  Delivery Man <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"route"}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter route
                      name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Phone no. <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"phone"}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter phone.</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"email"}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter email.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Address <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"address"}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Image <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <input type="file"
                           name={'image'}
                           accept="image/x-png,image/gif,image/jpeg"
                           id="fileUpload"/>
                  </Col>
                </Form.Group>
                <div className="mt-3">
                  <Link to={`/sr/list`}>
                    <Button size="lg" type={"submit"} disabled={this.state.isAdding} variant="primary">{this.state.isAdding ? 'Updating...' : 'Update'}</Button>
                  </Link>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);