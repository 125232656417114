import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getPaginatedList } from "../../services/baseServices";
import ExportCSVAsyncV2 from "../common/ExportCSVAsyncV2";
import { getFormattedDate } from "../../helper/utils";

const headers = [
	{ label: "Distributor Name", key: "name" },
	{ label: "Phone", key: "phone" },
	{ label: "Collected", key: "distributor_collected" },
	{ label: "Collectable", key: "distributor_collectable" },
	{ label: "Return Collected", key: "total_return_collected" },
	{ label: "Return Collectable", key: "total_return_collectable" },
];

const loadData = (item) => {
	return {
		name: item.name,
		phone: item.phone,
		total_return_collected: item.total_return_collected,
		total_return_collectable: item.total_return_collectable,
		distributor_collected: item.distributor_collected,
		distributor_collectable: item.distributor_collectable,
	};
};

class Distributors extends Component {
	constructor() {
		super();
		this.state = {
			distributors: [],
			activePage: 1,
			per_page: 15,
			page: 1,
			query_params: {},
			isLoading: true,
		};
	}

	getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

	getDistributors = (params) => {
		getPaginatedList("warehouses/distributors", params)
			.then((res) => {
				this.setState(
					{
						distributors: res?.data?.data,
						isLoading: false,
						per_page: res.headers["x-per-page"],
						total_data: parseInt(res.headers["x-total"]),
					},
					() => {}
				);
			})
			.catch((errMsg) => {
				console.log("Error Message: ", errMsg);
			});
	};

	componentDidMount() {
		console.log(this.props.filtereddistributors)
		this.setState({ query_params: this.props.queryParams }, () => {
			this.getDistributors(this.getQueryParams());
		})
	}

	handleOnSearch = (e) => {
		e.preventDefault();
		const tempParam = this.state.temp_params;
		this.setState({...this.state, page: 1, query_params: tempParam}, () => {
			this.getDistributors(this.state.temp_params);
		});
	}

	handleOnFieldChange = (e) => {
		const queryData = {...this.state.temp_params};
		queryData[e.target.name] = e.target.value;
		this.setState({temp_params: queryData}, () => {
		});
	}

	handleOnClear = ()=>{
		this.setState({
			temp_params: {...this.state.query_params,
				title: '', 
				distributor_id:''
			},
			query_params: {...this.state.query_params, 
				title: '', 
				distributor_id:''
			}
		}, () =>{
			this.getDistributors(this.getQueryParams());
		})
	}

	handlePageChange = (pageNumber) => {
		this.setState({ page: pageNumber, activePage: pageNumber }, () => {
			this.getDistributors(this.getQueryParams());
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() !== this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() !== this.props.queryParams.end_date_time.toString() ) {
		    console.log(this.props.queryParams)
			this.setState({ query_params: this.props.queryParams }, () => {
            	this.getDistributors(this.getQueryParams());
			})
        }
	}

	render() {
		return (
		<div className="main-content">
			<div className="page-header">
				<h2 className="page-title">Distributor List</h2>
				<div className="ml-auto" style={{display: "flex"}}>
					<ExportCSVAsyncV2
					fileName={"Distributors-"+getFormattedDate(new Date(),"DD-MM-YYYY")+".csv"}
					Url={`warehouses/distributors`}
					params={{skip_pagination: true}}
					headers={headers}
					loadData={loadData}
					title="Distributor List"/>

				</div>
			</div>
			<div align="center">
				<form className="form-inline mb-3">
					<div className="form-group mr-3">
						<label htmlFor="searchInput" className="sr-only">
							Distributor Name
						</label>
						<input
							type="search"
							name="title"
							id="title"
							className="form-control form-control-sm"
							placeholder="Search byDistributor Name"
							value={this.state.temp_params?.title}
							onChange={this.handleOnFieldChange}
						/>
					</div>
					<Button
						size="sm"
						type={`submit`}
						onClick={this.handleOnSearch}
						variant="primary"
					>
						Filter
					</Button>
					<Button
						size="sm"
						type={`button`}
						className="ml-2"
						onClick={this.handleOnClear}
						variant="danger"
					>
						Clear
					</Button>
				</form>
			</div>
			<table className="order-table table table-striped">
				<thead>
					<tr>
						<th style={{width: '20px'}}>#</th>
						<th>Distributor Name</th>
						<th>Phone</th>
						<th>Collected</th>
						<th>Collectable</th>
						<th>Return Collected</th>
						<th>Return Collectable</th>
					</tr>
				</thead>
				<tbody>
					{this.state.distributors?.length > 0 &&
					this.state.distributors?.map((distributor, index) => {
						return (
						<tr key={index}>
							<td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
							<td>
								<div className="order-item">
										{distributor.name}
								</div>
							</td>
							<td>
								<div className="order-item">
										{distributor.phone}
								</div>
							</td>
							<td>
								<div className="order-item">
									{distributor.distributor_collected}
								</div>
							</td>
							<td>
								<div className="order-item">
									{distributor.distributor_collectable}
								</div>
							</td>
							<td>
								<div className="order-item">
									{distributor?.total_return_collected}
								</div>
							</td>
							<td>{distributor.total_return_collectable}</td>
						</tr>
						);
					})}
				</tbody>
			</table>
			<div className="mt-3">
				<Pagination
					itemClass="page-item"
					linkClass="page-link"
					activePage={this.state.activePage}
					itemsCountPerPage={parseInt(this.state.per_page)}
					totalItemsCount={parseInt(this.state.total_data)}
					pageRangeDisplayed={10}
					onChange={this.handlePageChange.bind(this)}
				/>
			</div>
		</div>
		);
	}
}

export default withRouter(Distributors);
