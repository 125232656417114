import React from "react";
import "react-datepicker/dist/react-datepicker.css";

const TopSkuList = ({ skuList }) => {
  return (
    <table className="order-table table table-striped">
      <thead>
        <tr>
          <th style={{ width: "100px" }}>#</th>
          <th>Title</th>
          <th>SKU</th>
          <th>Company</th>
          <th>Units</th>
          <th>Category</th>
          <th>Sub - Category</th>
        </tr>
      </thead>

      <tbody>
        {skuList?.length > 0 ? (
          skuList?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>

                <td>
                  <div className="order-item">{item?.title}</div>
                </td>

                <td>
                  <div className="order-item">{item?.sku}</div>
                </td>

                <td>
                  <div className="order-item">{item?.company?.name}</div>
                </td>

                <td>
                  <div className="order-item">{item?.units}</div>
                </td>

                <td>
                  <div className="order-item">
                    {item?.categories.join(", ")}
                  </div>
                </td>

                <td>
                  <div className="order-item">
                    {item?.sub_categories.join(", ")}
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={7} className="text-center">
              <h5>List Not Available</h5>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TopSkuList;
