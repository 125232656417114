import React, { Component } from 'react';
import App from "../../App";
import { OverlayTrigger, Tooltip, Button, Col, Form, Row } from "react-bootstrap";
import  Pagination from 'react-js-pagination';
import { withRouter } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { getPaginatedList, updateData, getList } from "../../services/baseServices";
import Loader from 'react-loader';
import ConfirmationModal from '../common/Modal'
import FormModal from './FormModal'
import {toast} from "react-toastify";
import { FaBalanceScaleLeft, RiTestTubeFill } from "react-icons/all";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"

class List extends Component {
  constructor() {
    super();
    this.state = {
      qcFailedList: [],
      total_data: 0,
      activePage: 1,
      per_page : 50,
      page: 1,
      isLoading: false,
      temp_params: {},
      query_params: {},
      receivedQuantity: {},
      locations: []
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
    return params;
  }

  getPaginatedQCFailedList = (params) => {
    this.setState({...this.state, isLoading: true});
    let url = 'qcs/qc_failed_items?order_type=ReturnTransferOrder'

    getPaginatedList( url ,params)
      .then(res => {
            if(res?.data?.status === 200) {
                this.setState({
                qcFailedList: res?.data?.data, 
                isLoading: false,
                per_page: parseInt(res.headers['x-per-page']),
                total_data: parseInt(res.headers['x-total']),
            });
        }
      })
      .catch(errMsg => {
        this.setState({...this.state, isLoading: false});
        console.log('Error Message: ', errMsg)
      })
  }

  componentDidMount() {
    this.getPaginatedQCFailedList(this.getQueryParams());
    getList("locations")
        .then((res) => {
            this.setState({ locations: res });
        })
        .catch((errMsg) => {});
  }

  handlePageChange = (pageNumber) => {
    this.setState({page: pageNumber, activePage: pageNumber}, () => {
      this.getPaginatedQCFailedList(this.getQueryParams());
    });
  }

  handleOnFilterChange = (e) => {
    const queryData = {...this.state.temp_params};
    queryData[e.target.name] = e.target.value;
    this.setState({temp_params: queryData}, () => {
    });
  }

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
      this.setState({per_page: 50, page: 1, query_params: tempParam, isLoading:true}, () => {
        this.getPaginatedQCFailedList(this.state.temp_params);
      });
  }

  debouncedOnQCFailedItem = _.debounce((orderId, failed_qc_id, action, failed_type)=>{
    this.handleQCFailedItem(orderId, failed_qc_id, action, failed_type)
  }, DEBOUNCE_TIME);

  handleWithDebounceQCFailed = (orderId, failed_qc_id, action, failed_type) => {
    this.debouncedOnQCFailedItem(orderId, failed_qc_id, action, failed_type);
  }

  handleQCFailedItem =  (orderId, failed_qc_id, action, failed_type) => {
    let receivedQuantity = {...this.state.receivedQuantity}
    let params = {
        order_id: orderId,
        failed_qc_id: failed_qc_id, 
        action: action,
        failed_type: failed_type,
        quantity: receivedQuantity[failed_qc_id]?.received_qty,
        location_id: receivedQuantity[failed_qc_id]?.location_id
    }

    updateData("qcs/reconcile", params)
    .then(res => {
      if(res.success) {
        this.getPaginatedQCFailedList(this.getQueryParams());
        toast.success(res.message);
      } else {
        toast.error(res.message)
      }
      
    })
    .catch(errMsg => {
      toast.error(errMsg)
    })
  }

  handleOnFieldChange = (id, e) => {
    let { name, value } = e.target
    let receivedQuantity = {...this.state.receivedQuantity, [name]: value}
    receivedQuantity[id] = receivedQuantity
    this.setState({receivedQuantity})
  }

  receivePOForm = (id, remaining_quantity) => {
    return (
      <>
        <Form.Group as={Row} controlId="remaining_quantity" key={id}>
          <Form.Label column sm="5">
          Receivable Quantity
          </Form.Label>
          <Form.Label column sm="7">
            {remaining_quantity}
          </Form.Label>
        </Form.Group>
        <Form.Group as={Row} controlId="received_qty" key={id}>
          <Form.Label column sm="5">
          Received Quantity <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="7">
          <Form.Control
              name={"received_qty"}
              onChange={(e)=> {this.handleOnFieldChange(id,e)}}
              type="number"
              max={remaining_quantity}
              min={1}
              required
          />
          <Form.Control.Feedback type="invalid">
              Field is required
          </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="location" key={id}>
          <Form.Label column sm="5">
          Location <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="7">
          <Form.Control
            name={"location_id"}
            required
            onChange={(e)=> {this.handleOnFieldChange(id,e)}}
            as="select">
            <option value="">--Select Location--</option>
            {this.state.locations.length > 0
            ? this.state.locations.map(
                (item) => {
                  return (
                    <option value={item.id}>
                      {item.code}
                    </option>
                  );
                }
              )
            : ""}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
              Location is required
          </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </>
    )
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">IGT QC Failed Item List</h2>
            
          </div>
          <form className="form-inline mb-3">
            <div className="form-group mr-3">
              <label htmlFor="order_id" className="sr-only">Search by Order No</label>
              <input type="search" name="order_id" id="order_id" onChange={this.handleOnFilterChange}
                     className='form-control form-control-sm' placeholder="Search by IGT No"/>
            </div>
            <div className="form-group mr-3">
              <label htmlFor="sku" className="sr-only">Search by SKU</label>
              <input type="search" name="sku" id="sku" onChange={this.handleOnFilterChange}
                     className='form-control form-control-sm' placeholder="Search by SKU"/>
            </div>

            <div className="form-group mr-3">
              <label htmlFor="code_by_supplier" className="sr-only">Search by Supplier Code</label>
              <input type="text" name="code_by_supplier" id="code_by_supplier" onChange={this.handleOnFilterChange}
                     className='form-control form-control-sm' placeholder="Search by Supplier Code"/>
            </div>


            <Button type={`submit`} onClick={this.handleOnSearch} size="sm" variant="primary">Search</Button>
          </form>
          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body">
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className='text-nowrap'>IGT number</th>
                      <th>Product Title</th>
                      <th>SKU</th>
                      <th>failed quantity</th>
                      <th>received quantity</th>
                      <th>closed quantity</th>
                      <th>failed reason</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.qcFailedList?.length > 0 ? this.state.qcFailedList?.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                          <td>{item.order_id}</td>
                          <td>{item.title}</td>
                          <td>{item.sku}</td>
                          <td>{item.quantity_failed}</td>
                          <td>{item.received_quantity}</td>
                          <td>{item.closed_quantity}</td>
                          <td>{ item.failed_type === "quantity_failed" ? <FaBalanceScaleLeft className="text-danger"/> : <RiTestTubeFill  className="text-danger"/> }  {item.failed_type}</td>
                          <td style={{width: '150px'}}>
                            {/* {item.is_settled?'kk':'jj'} */}
                            <div className={`btn-group`}>
                              <OverlayTrigger
                                  key={index+1}
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Receive
                                    </Tooltip>
                                  }
                              >
                                {
                                  item.is_settled?(
                                    <button className='btn btn-primary btn-sm disabled text-white mr-2'>Receive</button>
                                  ):(
                                    <FormModal 
                                      title={`Receive IGT for SKU-${item.sku}`} 
                                      body={this.receivePOForm(item.failed_qc_id, item.remaining_quantity)}
                                      customBtn = {true}
                                      variant="primary"
                                      btnClassName={`mr-2`}
                                      btnText="Receive"
                                      handleAction={
                                        item.is_settled?()=>{}:
                                        (e) =>this.handleWithDebounceQCFailed(item.order_id, item.failed_qc_id, "received", item.failed_type)
                                    }/>
                                  )
                                }
                              </OverlayTrigger>
                              <OverlayTrigger
                                  key={index+2}
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Close
                                    </Tooltip>
                                  }
                              >
                                {
                                  item.is_settled?(
                                    <button className='btn btn-warning btn-sm disabled text-white'>Close</button>
                                  ):( <ConfirmationModal 
                                      title={`Close IGT for SKU-${item.sku}`}
                                      body={`Your remaining quantity are <strong>${item.remaining_quantity}</strong>.<br/> Are you sure to close the IGT? `}
                                      customBtn = {true}
                                      btnClassName={`btn btn-secondary`}
                                      btnText="Close"
                                      handleAction={ item.is_settled?()=>{}:
                                        () =>this.handleWithDebounceQCFailed(item.order_id, item.failed_qc_id, "closed", item.failed_type)
                                  }/>)
                                }
                               
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                    );
                  }) : ""}
                  </tbody>
                </table>
              </div>
              <div className="mt-3">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={this.state.total_data}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </Loader>
          
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
