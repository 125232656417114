import App from "../../App";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import * as yup from "yup";
import CouponEditForm from "./CouponEditForm";
import { useEffect, useState } from "react";
import { getDetails } from "../../services/baseServices";
import { useParams } from "react-router-dom";

const schema = yup.object().shape(
  {
    title: yup
      .string()
      .required("Title is required")
      .trim()
      .max(100, "Title must be at most 100 characters"),
    startDate: yup
    .string()
    .typeError("Start date is required")
    .required("Start date is required"),
    endDate: yup
    .string()
    .typeError("End date is required")
    .required("End date is required"),
    status: yup.string().required("Status is required"),
  },
  []
);

const Edit = () => {
  const { id } = useParams();
  const [couponDetails, setCouponDetails] = useState();
  useEffect(() => {
    getCouponDetails();
  }, [id]);

  const getCouponDetails = async () => {
    const response = await getDetails("promo_coupons", id);

    if (response?.success) {
      setCouponDetails(response?.data);
    } else {
      console.log("something went wrong");
    }
  };

  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Edit Coupon</h2>
        <div className="ml-auto">
          <Link
            to={`${process.env.PUBLIC_URL}/coupon/list`}
            className={`btn btn-sm btn-link`}
          >
            <MdArrowBack />
            Back to List
          </Link>
        </div>
      </div>

      <div className="card-block">
        <div className="block-body">
          <CouponEditForm validationSchema={schema} couponDetails={couponDetails} />
        </div>
      </div>
    </App>
  );
};

export default Edit;
