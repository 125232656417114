import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import { getDetails } from "../../services/baseServices";

class Details extends Component {
  constructor() {
    super();
    this.state = {
      warehouseInfo: {},
    };
  }

  componentDidMount() {
    getDetails("warehouses", this.props.match.params.id)
      .then((res) => {
        this.setState({ warehouseInfo: res });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  render() {
    const { warehouseInfo } = this.state;

    return (
      <App layout={`boxed`}>
        <div className="main-content">
          <div className="page-header">
            <div className="page-title" />
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/dwh/list`}
                className={`btn btn-sm btn-link`}
              >
                <MdArrowBack /> Back to List
              </Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Distribution House Details</h4>
            </div>
            <div className="block-body">
              <>
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>Warehouse Id</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{warehouseInfo?.id}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Warehouse Name</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{warehouseInfo?.name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>
                        Warehouse Name in Bangla
                      </td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{warehouseInfo?.bn_name}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Email</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{warehouseInfo?.email}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Phone</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{warehouseInfo?.phone}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Address</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{`${warehouseInfo?.address?.area_name},${warehouseInfo?.address?.thana_name},${warehouseInfo?.address?.district_name}`}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Commission Applicable</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {warehouseInfo?.is_commission_applicable ? "YES" : "NO"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Companies</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {warehouseInfo &&
                        warehouseInfo.companies &&
                        warehouseInfo.companies.length > 0
                          ? warehouseInfo.companies
                              .map((item, index) => item.name)
                              .join(", ")
                          : "No companies available"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);
