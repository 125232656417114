import { Form, Row, Col } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

const MetaInfo = ({
  metaInfo,
  handleOnInputChange,
  handleOnChangeKeywordValue,
  isRequired,
}) => {
  const arrayToSelectOptionArray = (arr) => {
    const newArray = [];
    if (arr) {
      arr.forEach(item => {
        newArray.push({
          label: item,
          value: item
        })
      })
    }
    return newArray
  }

  return (
    <>
      <Form.Group as={Row} controlId="name">
        <Form.Label column sm="3">
          Meta title
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            as="textarea"
            required={isRequired}
            rows={10}
            name={"metaTitle"}
            value={metaInfo?.metaTitle}
            onChange={handleOnInputChange}
            type="text"
            pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
            maxLength={360}
          />
          <Form.Control.Feedback type="invalid">
            Meta Title is required
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="name">
        <Form.Label column sm="3">
          Meta Title in Bangla
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            as="textarea"
            required={isRequired}
            rows={10}
            name={"metaTitleBn"}
            value={metaInfo?.metaTitleBn}
            onChange={handleOnInputChange}
            type="text"
            maxLength={360}
          />
          <Form.Control.Feedback type="invalid">
            Meta Title in Bangla is required
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="name">
        <Form.Label column sm="3">
          Meta Description
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            as="textarea"
            required={isRequired}
            rows={10}
            name={"metaDescription"}
            value={metaInfo?.metaDescription}
            onChange={handleOnInputChange}
            type="text"
            pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
            maxLength={360}
          />
        <Form.Control.Feedback type="invalid">
            Meta Description is required
        </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="name">
        <Form.Label column sm="3">
            Meta Description in Bangla
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            as="textarea"
            required={isRequired}
            rows={10}
            name={"metaDescriptionBn"}
            value={metaInfo?.metaDescriptionBn}
            onChange={handleOnInputChange}
            maxLength={360}
          />
        <Form.Control.Feedback type="invalid">
            Meta Description in Bangla is required
        </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formModel">
        <Form.Label column sm="3">
          Meta Keywords
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <CreatableSelect
            noOptionsMessage={() => "Please type your values..."}
            isMulti
            onChange={(value) => {
              handleOnChangeKeywordValue("en", value);
            }}
            value={
              metaInfo?.metaKeywords
                ? arrayToSelectOptionArray(
                    metaInfo?.metaKeywords
                  )
                : ""
            }
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formModel">
        <Form.Label column sm="3">
          Meta Keywords in Bangla
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <CreatableSelect
            noOptionsMessage={() => "Please type your values..."}
            isMulti
            onChange={(value) => {
              handleOnChangeKeywordValue("bn", value);
            }}
            value={
              metaInfo?.metaKeyKeywordsBn
                ? arrayToSelectOptionArray(
                    metaInfo?.metaKeyKeywordsBn
                  )
                : ""
            }
          />
        </Col>
      </Form.Group>
    </>
  );
};

export default MetaInfo;
