import React, { useState, useEffect } from 'react'
import App from "../../App";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FiEdit, FiPlus, FiEye } from "react-icons/all";
import { Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { toast } from 'react-toastify';
import ConfirmationModal from "../common/Modal";
import { getPaginatedList, destroy } from "../../services/baseServices";
import Pagination from 'react-js-pagination';

const List = () => {
  const [brandList, setBrandList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [totalData, setTotalData] = useState(10)
  const [activePage, setActivePage] = useState(1)
  const [queryParams, setQueryParams] = useState({ page: 1, per_page: 25 })

  const getPaginatedBrands = (params) => {
    getPaginatedList("brands/paginated", params)
      .then(res => {
        setBrandList(res.data.data)
        setTotalData(res.headers['x-total'])
      })
      .catch(errMsg => {
        console.log('Error Message: ', errMsg)
      })
  }

  const handlePageChange = (page) => {
    setQueryParams(prev => ({ ...prev, ['page']: page }));
    setActivePage(page)
    setRefresh(!refresh)
  }

  useEffect(() => {
    getPaginatedBrands(queryParams)
  }, [refresh])

  const handleDeleteBrand = id => {
    destroy('brands', id)
      .then(res => {
        if (res.status_code === 200) {
          setRefresh(!refresh)
          toast.success(res?.message)
        } else {
          toast.error(res?.message)
        }
      })
      .catch(err => {
        toast.error(err)
      })
  }

  const handleFilterOnChange = (e) => {
    let { name, value } = e.target
    setQueryParams(prev => ({ ...prev, [name]: value }));
  }

  const filterList = () => {
    setQueryParams(prev => ({ ...prev, ['page']: 1 }));
    setRefresh(!refresh)
  }

  return (
    <App layout="">
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Brand List</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/brands/add`} className="btn btn-sm btn-secondary"><FiPlus /> Add
              Brand</Link>
          </div>
        </div>
        <form className="form-inline mb-3">
          <div className="form-group mr-3">
            <label htmlFor="searchInput" className="sr-only">
              Search by Title
            </label>
            <input
              type="search"
              name="title"
              id="title"
              onChange={handleFilterOnChange}
              className="form-control form-control-sm"
              placeholder="Search by Title"
            />
          </div>
          <div className="form-group mr-3">
            <label htmlFor="status" className="sr-only">
              Own Brand
            </label>
            <select
              id="status"
              name="is_own_brand"
              className="form-control form-control-sm"
              onChange={handleFilterOnChange}
            >
              <option value="">--Select Brand Type--</option>
              <option value={true}>Own Brand</option>
              <option value={false}>Others</option>
            </select>
          </div>

          <Button
            size="sm"
            onClick={filterList}
            variant="primary"
          >
            Filter
          </Button>
        </form>
        <div className="card-block">
          <div className="block-body">
            <table className="order-table table table-striped">
              <thead>
                <tr>
                  <th style={{ width: '20px' }}>#</th>
                  <th>Logo</th>
                  <th>Title</th>
                  <th>Title in Bangla</th>
                  <th>Brand Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                {brandList.length > 0 ? brandList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="order-item">
                          <Link to={`${process.env.PUBLIC_URL}/brands/details/${item.id}`}>
                            <div className="user-avatar">
                              <img src={item.logo_file} alt="" style={{ width: "40px", height: "40px" }} />
                            </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item.bn_name}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item.is_own_brand ? "Own Brand" : "Others"}
                        </div>
                      </td>

                      <td style={{ width: '150px' }}>
                        <div className="btn-group">
                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>
                                View
                              </Tooltip>
                            }
                          >
                            <Link to={`${process.env.PUBLIC_URL}/brands/details/${item.id}`}
                              className="btn btn-sm btn-default"><FiEye /></Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>
                                Edit
                              </Tooltip>
                            }
                          >
                            <Link to={`${process.env.PUBLIC_URL}/brands/edit/${item.id}`}
                              className="btn btn-sm btn-default"><FiEdit /></Link>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>
                                Delete
                              </Tooltip>
                            }
                          >
                            <ConfirmationModal
                              title="Delete"
                              body="Are you sure to delete the brand?"
                              handleAction={() => handleDeleteBrand(item.id)}
                            />
                          </OverlayTrigger>
                        </div>

                      </td>
                    </tr>
                  );
                }) : ""}

              </tbody>
            </table>
          </div>
          {
            totalData > 0 &&
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={queryParams.per_page}
                totalItemsCount={totalData}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
              />
            </div>
          }
        </div>
      </div>
    </App>
  )
}

export default List
