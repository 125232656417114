import React from 'react'
import SearchComponent from './SearchComponent'
import Variants from './Variants'
import { getList } from "../../../services/baseServices";
import { toast } from "react-toastify";

class BundleForm extends React.Component {

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    state = {
        searchText: "",
        searchResult: { item_count: 0, variants: [] },
        loadingState: "initial",
        selectedProducts: this.props.bundleVariants,
        totalQ: 0,
        payTermsInfo: {}
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            selectedProducts,
            totalQ
        } = this.state
        if (prevState.totalQ !== totalQ) {
            this.props.handleBundleVariations(selectedProducts)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    calcuTotalQFor() {
        let totalQ = 0
        this.state.selectedProducts.forEach((product) => {
            totalQ = totalQ + product.quantity
        })
        this.setState({ totalQ: parseInt(totalQ) })
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ loadingState: "initial" })
        }
    }

    sendQuery = () => {
        const targetedURL = `products/skus/search?search_string=${this.state.searchText}`
        this.setState({ loadingState: "loading" })
        getList(targetedURL)
            .then(res => {
                // console.log(res)
                this.setState({ loadingState: "loaded", searchResult: res })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loadingState: "initial", searchResult: { item_count: 0, variants: [] } })
            }
            )

    }

    handleOnChange = (e) => {
        this.setState({ searchText: e.target.value })
        if (e.target.value.length >= 3) {
            this.sendQuery()
        } else {
            this.setState({ loadingState: "initial", searchResult: { item_count: 0, variants: [] } })
        }
    }

    handleOnSelect = (variant) => {
        let selectedProducts = [...this.state.selectedProducts]
        const found = selectedProducts.find(element => element.bundle_sku === variant.sku)

        if (found === undefined) {
            selectedProducts.push({ bundle_sku: variant.sku, quantity: 1 })
            this.setState({
                loadingState: "initial",
                selectedProducts
            }, () => {
                if (this.state.selectedProducts.length > 0) this.calcuTotalQFor()
                else this.setState({ totalQ: 0 })
            })
        } else {
            toast.info("Already selected.")
        }
    }

    handleVariantQuantityChange = (e, sku) => {
        let { selectedProducts } = this.state
        const productIndex = selectedProducts.findIndex(x => x.bundle_sku === sku);

        if (productIndex !== -1) {
            const theProduct = selectedProducts[productIndex];
            theProduct.quantity = e.target.value
            selectedProducts[productIndex] = theProduct
            this.setState({ selectedProducts }, () => {
                if (this.state.selectedProducts.length > 0) this.calcuTotalQFor()
                else this.setState({ totalQ: 0 })
            })
        }
    }

    handleVariantRemove = (e, sku) => {
        e.preventDefault()

        let { selectedProducts } = this.state
        const productIndex = selectedProducts.findIndex(x => x.bundle_sku === sku);
        selectedProducts.splice(productIndex, 1);

        this.setState({ selectedProducts }, () => {
            toast.success('Removed successfully.')
            if (this.state.selectedProducts.length > 0) this.calcuTotalQFor()
            else this.setState({ totalQ: 0 })
        })
    }

    handleWithDebouncePO = async (e, data) => {
        this.setState({ payTermsInfo: data })
        e.preventDefault()
        this.debouncedOnPOSubmit();
    }

    render() {
        return (
            <div className="block-body">
                <SearchComponent
                    wrapperRef={this.wrapperRef}
                    loadingState={this.state.loadingState}
                    handleOnChange={this.handleOnChange}
                    handleOnSelect={this.handleOnSelect}
                    searchResult={this.state.searchResult} 
                    isBundleEdit = {this.props.isEdit}/>
                <Variants selectedProducts={this.state.selectedProducts}
                    handleVariantQuantityChange={this.handleVariantQuantityChange}
                    handleVariantRemove={this.handleVariantRemove}
                    handlePurchaseOrder={this.handleWithDebouncePO} 
                    isBundleEdit={this.props.isEdit}/>
            </div>
        )
    }
}

export default BundleForm
