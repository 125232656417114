import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { getList } from "../../services/baseServices";
import { getBusinessType } from "../../helper/utils";
const businessType = getBusinessType();

function RouteForm(props) {
  const [distributors, setDistributors] = useState([]);

  const group = [
    { label: "Shopoth", value: "general" },
    { label: "Multicat", value: "multicat" },
  ];

  useEffect(() => {
    getList("distributors")
      .then((res) => {
        setDistributors(res?.data);
      })
      .catch((errMsg) => {});
  }, []);

  return (
    <Form
      noValidate
      // validated={this.state.isFormValidated}
      onSubmit={props.handleClick}
    >
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Distributor <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            required
            name="distributor_id"
            onChange={props.handleInputOnChange}
            // value={props.routeInfo.distributor_id}
            as="select"
            type="text"
          >
            <option value="">--Select--</option>
            {distributors.map((item, i) => {
              return (
                <option
                  key={i}
                  value={item.id}
                  selected={props.formData.distributor_id === item.id}
                >
                  {item.name}
                </option>
              );
            })}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select distributor.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Group
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name="group"
            onChange={props.handleInputOnChange}
            as="select"
            type="text"
          >
            <option value="">--Select--</option>
            {group.map((option, i) => (
              <option
                key={i}
                value={option.value}
                selected={props.routeInfo.group === option.value}
              >
                {option.label}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select group.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Title in English <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"title"}
            required
            defaultValue={props.routeInfo.title}
            onChange={props.handleInputOnChange}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            Please enter title in English .
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Title in Bangla <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"bn_title"}
            required
            defaultValue={props.routeInfo.bn_title}
            onChange={props.handleInputOnChange}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            Please enter title in Bangla.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Phone Number <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"phone"}
            pattern="(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$"
            required
            defaultValue={props.routeInfo.phone}
            onChange={props.handleInputOnChange}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            Please enter valid BD phone number.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          {businessType === "multicat" ? "DM Name" : "SR Name"}{" "}
          <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"sr_name"}
            required
            defaultValue={props.routeInfo.sr_name}
            onChange={props.handleInputOnChange}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            Please enter {businessType === "multicat" ? "DM name" : "SR name"} .
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          {businessType === "multicat" ? "DM Point" : "SR Point"}{" "}
          <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"sr_point"}
            required
            defaultValue={props.routeInfo.sr_point}
            onChange={props.handleInputOnChange}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            Please enter {businessType === "multicat" ? "DM Point" : "SR Point"}{" "}
            number.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <div className="mt-3">
        <Button
          size="lg"
          type={"submit"}
          disabled={props.isSubmitting}
          variant="primary"
        >
          {props.isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </div>
    </Form>
  );
}

export default RouteForm;
