import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";

class Edit extends Component {
  state = {
  };

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Staff</h2>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Name in Bangla <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"bn_name"}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter name in bangla.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Phone No. <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"phone_no"}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter your phone no.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Address <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"address"}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"email"}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter your email.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"password"}
                      required
                      type="password"/>
                    <Form.Control.Feedback type="invalid">Please enter password.</Form.Control.Feedback>
                    <i className="text-help text-muted text-danger">Please keep this field blank if you don't want to change the password.</i>
                  </Col>
                </Form.Group>
                {/*<Form.Group as={Row} controlId="displayOrShopName">*/}
                {/*  <Form.Label column sm="3">*/}
                {/*    Role <span className="text-danger">*</span>*/}
                {/*  </Form.Label>*/}
                {/*  <Col sm="9" md={8} lg={7}>*/}
                {/*    <Form.Control*/}
                {/*      name={"role"}*/}
                {/*      as="select">*/}
                {/*      <option value="">--Select Role--</option>*/}
                {/*      <option value="1">Normal</option>*/}
                {/*      <option value="2">Admin</option>*/}
                {/*      <option value="3">Store Owner</option>*/}
                {/*      <option value="4">Warehouse Manager</option>*/}
                {/*      <option value="5">Product Manager</option>*/}
                {/*      <option value="6">Supplier Manager</option>*/}
                {/*    </Form.Control>*/}
                {/*  </Col>*/}
                {/*</Form.Group>*/}
                <div className="mt-3">
                  <Link to={`/staff/list`}>
                    <Button size="lg" type={"submit"} disabled={this.state.isAdding} variant="primary">{this.state.isAdding ? 'Adding...' : 'Add'}</Button>
                  </Link>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);