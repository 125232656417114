import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { addV2, getData2, getList } from "../../services/baseServices";
import "../../assets/scss/new-po.scss";
import SearchComponent from "../../components/SearchComponent";
import Variants from "../../components/Variants";
import { FaLongArrowAltLeft } from "react-icons/all";
import { isNullOrEmpty, refreshPage } from "../../helper/utils";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";

class Add extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    searchText: "",
    productType: "multicat",
    searchResult: { item_count: 0, variants: [] },
    loadingState: "initial",
    selectedProducts: [],
    depositFormData: { deposit_slip: null, deposit_no: "", deposit_amount: "" },
    isSubmitting: false,
    totalVariantQuantity: 0,
    totalVariantPrice: 0,
    walletBalance: 0,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    getData2("wallets/balance")
      .then((res) => {
        if (
          res.success &&
          res.status === 200 &&
          res.data &&
          res?.data?.balance !== undefined
        ) {
          this.setState({ walletBalance: res?.data?.balance });
        } else {
          toast.error("Something went wrong in fetching wallet");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong in fetching wallet");
      });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ loadingState: "initial" });
    }
  }

  onFileChangeDepositUpload = (event) => {
    this.setState({
      depositFormData: {
        ...this.state.depositFormData,
        deposit_slip: event.target.files[0],
      },
    });
  };

  handleInputChangeDepositUpload = (event) => {
    const { name, value } = event.target;
    this.setState({
      depositFormData: {
        ...this.state.depositFormData,
        [name]: value,
      },
    });
  };

  sendQuery = () => {
    const targetedURL = `products/skus/search?search_string=${this.state.searchText}&product_type=${this.state.productType}&order_type=sto`;
    this.setState({ loadingState: "loading" });
    getList(targetedURL)
      .then((res) => {
        // console.log(res)
        this.setState({ loadingState: "loaded", searchResult: res });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: "initial",
          searchResult: { item_count: 0, variants: [] },
        });
      });
  };

  handleOnChange = (e) => {
    e.target.name === "product_type"
      ? this.setState({ productType: e.target.value })
      : this.setState({ searchText: e.target.value });
    if (e.target.value.length >= 3 && e.target.name === "search_text") {
      this.sendQuery();
    } else {
      this.setState({
        loadingState: "initial",
        searchResult: { item_count: 0, variants: [] },
      });
    }
  };

  handleOnSelect = (variant) => {
    let selectedProducts = [...this.state.selectedProducts];
    const found = selectedProducts.find((element) => element.id === variant.id);

    if (found === undefined) {
      variant.quantity = "";
      selectedProducts.unshift(variant);
      this.setState({
        loadingState: "initial",
        selectedProducts,
      });
    } else {
      toast.info("Already selected.");
    }
  };

  handleVariantQuantityChange = (e, product, variant_id) => {
    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex((x) => x.id === variant_id);

    if (productIndex !== -1) {
      let theProduct = selectedProducts[productIndex];
      theProduct.quantity = parseInt(e.target.value);
      let parseQuantity = theProduct.quantity || 0;
      if (product.stock_available_quantity < theProduct.quantity) {
        parseQuantity = product.stock_available_quantity;
        product.quantity = product.stock_available_quantity;
      }
      theProduct.totalPrice = parseQuantity * product.price_distribution;
      selectedProducts[productIndex] = theProduct;
      this.setState({
        selectedProducts,
      });
      this.setState({ selectedProducts }, () => {
        let totalQuantity = 0;
        let totalPrice = 0;
        for (let i = 0; i < selectedProducts.length; i++) {
          if (selectedProducts[i].quantity && selectedProducts[i].totalPrice) {
            totalQuantity = totalQuantity + selectedProducts[i].quantity;
            totalPrice = totalPrice + selectedProducts[i].totalPrice;
          }
        }
        this.setState({
          totalVariantQuantity: totalQuantity,
          totalVariantPrice: totalPrice,
        });
      });
    } else {
      this.setState({
        selectedProducts,
        totalVariantQuantity: 0,
        totalVariantPrice: 0,
      });
    }
  };

  handleVariantRemove = (e, variant_id) => {
    e.preventDefault();

    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex((x) => x.id === variant_id);

    if (productIndex !== -1) {
      selectedProducts.splice(productIndex, 1);

      this.setState({ selectedProducts }, () => {
        toast.success("Removed successfully.");

        let totalQuantity = 0;
        let totalPrice = 0;
        for (let i = 0; i < selectedProducts.length; i++) {
          if (selectedProducts[i].quantity && selectedProducts[i].totalPrice) {
            totalQuantity = totalQuantity + selectedProducts[i].quantity;
            totalPrice = totalPrice + selectedProducts[i].totalPrice;
          }
        }
        this.setState({
          totalVariantQuantity: totalQuantity,
          totalVariantPrice: totalPrice,
        });
      });
    } else {
      this.setState({
        selectedProducts,
        totalVariantQuantity: 0,
        totalVariantPrice: 0,
      });
    }
  };

  debouncedOnPOSubmit = _.debounce(() => {
    this.setState({ isSubmitting: true }, () => {
      this.proceedPurchaseOrder();
    });
  }, DEBOUNCE_TIME);

  handleWithDebouncePO = async (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    this.debouncedOnPOSubmit();
  };

  proceedPurchaseOrder = () => {
    let variantsToSend = [];
    this.state.selectedProducts.forEach((product) => {
      if (product.quantity && product.quantity > 0) {
        variantsToSend.push({
          variant_id: product.id,
          quantity: product.quantity,
        });
      }
    });
    if (variantsToSend.length > 0) {
      const postData = new FormData();
      variantsToSend.forEach((variant, index) => {
        postData.append(
          `dh_order_params[variants][][variant_id]`,
          variant.variant_id
        );
        postData.append(
          `dh_order_params[variants][][quantity]`,
          variant.quantity
        );
      });
      isNullOrEmpty(this.state.productType) || postData.append('business_type', this.state.productType);
      if (
        !isNullOrEmpty(this.state.depositFormData.deposit_no) &&
        !isNullOrEmpty(this.state.depositFormData.deposit_slip) &&
        !isNullOrEmpty(this.state.depositFormData.deposit_amount)
      ) {
        postData.append("deposit_no", this.state.depositFormData.deposit_no);
        postData.append(
          "deposit_slip",
          this.state.depositFormData.deposit_slip
        );
        postData.append(
          "deposit_amount",
          this.state.depositFormData.deposit_amount
        );
      }

      addV2("line_items/create_line_items_with_dh_po", postData)
        .then((res) => {
          if (res?.success) {
            toast.success(`${res.message}`, {
              autoClose: 5000, 
            });
            refreshPage(`/dhPurchaseOrder/list`, () => {
              this.setState({ isSubmitting: true });
            });
          } else if (res?.message) {
            toast.error(res?.message);
            this.setState({ isSubmitting: false });
          } else {
            toast.error("Please fill all required fields/quantities");
            this.setState({ isSubmitting: false });
          }
        })
        .catch((errMsg) => {
          toast.error(errMsg);
          this.setState({ isSubmitting: false });
        });
    } else {
      toast.error("Please create STO for atleast one item");
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    return (
      <App>
        <div className="page-header">
          <h2 className="page-title">Create Stock Transfer Order</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/dhPurchaseOrder/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <SearchComponent
              wrapperRef={this.wrapperRef}
              loadingState={this.state.loadingState}
              handleOnChange={this.handleOnChange}
              handleOnSelect={this.handleOnSelect}
              searchResult={this.state.searchResult}
              selectedProducts={this.state.selectedProducts}
            />
          </div>
        </div>

        <div>
          <Variants
            selectedProducts={this.state.selectedProducts}
            handleInputChangeDepositUpload={this.handleInputChangeDepositUpload}
            onFileChangeDepositUpload={this.onFileChangeDepositUpload}
            isSubmitting={this.state.isSubmitting}
            type="dhPurchaseOrder"
            cancelUrl="dhPurchaseOrder"
            handleVariantQuantityChange={this.handleVariantQuantityChange}
            proceedPurchaseOrder={this.proceedPurchaseOrder}
            handleVariantRemove={this.handleVariantRemove}
            handlePurchaseOrder={this.handleWithDebouncePO}
            totalVariantQuantity={this.state.totalVariantQuantity}
            totalVariantPrice={this.state.totalVariantPrice}
            showTotalRow={true}
            productType={this.state.productType}
            walletBalance={this.state.walletBalance}

          />
        </div>
      </App>
    );
  }
}

export default withRouter(Add);
