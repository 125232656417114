import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiMoney } from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getPaginatedList } from "../../services/baseServices";
import ExportCSVAsync from "../common/ExportCSVAsync";
import { getFormattedDate } from "../../helper/utils";

const headers = [
	{ label: "Rider Name", key: "name" },
	{ label: "Phone", key: "phone" },
	{ label: "Total Order", key: "total_order" },
	{ label: "Prepaid", key: "prepaid_order_count" },
	{ label: "Collected", key: "collected" },
];

const loadData = (item) => {
	return {
		name: item.name,
		phone: item.phone,
		total_order: item.total_order,
		prepaid_order_count: item.prepaid_order_count,
		collected: item.collected,
	};
};

class Riders extends Component {
	constructor() {
		super();
		this.state = {
			riders: [],
			activePage: 1,
			per_page: 15,
			page: 1,
			query_params: {},
			isLoading: true,
		};
	}

	getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

	getRiderList = (params) => {
		getPaginatedList("warehouses/riders", params)
			.then((res) => {
				this.setState(
					{
						riders: res.data,
						isLoading: false,
						per_page: res.headers["x-per-page"],
						total_data: parseInt(res.headers["x-total"]),
					},
					() => {}
				);
			})
			.catch((errMsg) => {
				console.log("Error Message: ", errMsg);
			});
	};

	componentDidMount() {
		console.log(this.props.filteredRiders)
		this.setState({ query_params: this.props.queryParams }, () => {
			this.getRiderList(this.getQueryParams());
		})
	}

	handlePageChange = (pageNumber) => {
		this.setState({ page: pageNumber, activePage: pageNumber }, () => {
			this.getRiderList(this.getQueryParams());
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
		    console.log(this.props.queryParams)
			this.setState({ query_params: this.props.queryParams }, () => {
            	this.getRiderList(this.getQueryParams());
			})
        }
	}

	render() {
		return (
		<div className="main-content">
			<div className="page-header">
				<h2 className="page-title">Rider List</h2>
				<div className="ml-auto" style={{display: "flex"}}>
					<ExportCSVAsync
					fileName={"Riders-"+getFormattedDate(new Date(),"DD-MM-YYYY")+".csv"}
					Url={`warehouses/riders_export`}
					headers={headers}
					loadData={loadData}
					title="Rider List"/>

				</div>
			</div>
			<table className="order-table table table-striped">
				<thead>
					<tr>
						<th style={{width: '20px'}}>#</th>
						<th>Rider Name</th>
						<th>Phone</th>
						<th>Total Order</th>
						<th>Prepaid</th>
						<th>Collected</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{this.state.riders?.length > 0 &&
					this.state.riders?.map((rider, index) => {
						return (
						<tr key={index}>
							<td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
							<td>
								<div className="order-item">
										{rider.name}
								</div>
							</td>
							<td>
								<div className="order-item">
									{rider.phone}
								</div>
							</td>
							<td>
								<div className="order-item">
									{rider.total_order}
								</div>
							</td>
							<td>
								<div className="order-item">
									{rider?.prepaid_order_count}
								</div>
							</td>
							<td>{rider.collected}</td>
							<td style={{ width: "150px" }}>
								<div className="btn-group">
									<OverlayTrigger
										key="view"
										placement="top"
										overlay={
											<Tooltip id={`tooltip-view`}>Collect</Tooltip>
										}
									>
										<Link
											to={`/rider/collect/${rider.id}`}
											className="btn btn-sm btn-default"
										>
											<BiMoney />
										</Link>
									</OverlayTrigger>
								</div>
							</td>
						</tr>
						);
					})}
				</tbody>
			</table>
			<div className="mt-3">
				<Pagination
					itemClass="page-item"
					linkClass="page-link"
					activePage={this.state.activePage}
					itemsCountPerPage={parseInt(this.state.per_page)}
					totalItemsCount={parseInt(this.state.total_data)}
					pageRangeDisplayed={10}
					onChange={this.handlePageChange.bind(this)}
				/>
			</div>
		</div>
		);
	}
}

export default withRouter(Riders);
