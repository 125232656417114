import React, { useEffect, useRef, useState } from "react";
import App from "../../App";
import { withRouter, useHistory } from "react-router-dom";
import SelectDeliveryPerson from "../../components/SelectDeliveryPerson";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getList, postData } from "../../services/baseServices";
import { toast } from "react-toastify";

const AssignBuffer = () => {
  const [products, setProducts] = useState([]);
  const [deliveryPersons, setDeliveryPersons] = useState([]);
  const [selectedDeliveryPerson, setSelectedDeliveryPerson] = useState({});
  const [bufferStocks, setBufferStocks] = useState(new Map());
  const history = useHistory();

  useEffect(() => {
    getBufferList();
  }, []);

  const getBufferList = () =>
    getList("buffer_stocks").then((res) => {
      if (res?.data?.product_list) {
        setProducts(res?.data?.product_list);
        if (res?.data?.product_list?.length) {
          const buffer = new Map();
          res?.data?.product_list?.forEach((item, index) => {
            buffer.set(item?.variant_id, {
              variant_id: item?.variant_id,
              allowed_buffer: 0,
            });
          });
          setBufferStocks(buffer);
        }
      }

      if (res?.data?.delivery_person_list) {
        setDeliveryPersons(res?.data?.delivery_person_list);
      }
    });

  const handleSelectChange = (value) => {
    setSelectedDeliveryPerson(value);
  };

  const handleQuantityUpdate = (
    productId,
    newBufferQty,
    available_quantity
  ) => {
    setBufferStocks((prevProductsMap) => {
      if (newBufferQty > available_quantity) {
        toast.warn("Assign buffer can not be greater than available quantity", {
          toastId: "buffer_stock_warn",
        });
        return prevProductsMap; // Return the previous state
      }
      const updatedProductsMap = new Map(prevProductsMap);
      const product = updatedProductsMap.get(productId);
      if (product) {
        product.allowed_buffer = Math.max(0, parseInt(newBufferQty));
      } else {
        return prevProductsMap; // Return the previous state
      }
      return updatedProductsMap;
    });
  };

  const handleSubmit = () => {
    if (!selectedDeliveryPerson) {
      toast.warning("Please select delivery person!");

      return;
    }

    if (bufferStocks?.size === 0) {
      toast.warning("Please input buffer stock first!");
      return;
    }

    const buffers = Array.from(bufferStocks.values());

    const payload = {
      delivery_person_id: selectedDeliveryPerson?.id,
      assigned_buffers: buffers,
    };

    postData("/buffer_stocks/assign_buffer", payload)
      .then((res) => {
        if (res.success) {
          toast.success(
            `Assigned sucessfully to ${selectedDeliveryPerson?.sr_name}`
          );

          setBufferStocks((prevState) => prevState.clear());
          getBufferList();
          const inputFields = document.querySelectorAll(".my-input");
          inputFields.forEach((input) => {
            input.value = "";
          });
          history.push("/buffer-stock/reconcile");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <App layout={""}>
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Add Buffer Stock</h2>
        </div>
        <div>
          <Form.Group as={Row}>
            <Col sm="12" md={6} lg={6}>
              <SelectDeliveryPerson
                deliveryPersons={deliveryPersons}
                handleChange={handleSelectChange}
                selectedDeliveryPerson={selectedDeliveryPerson}
              />
            </Col>
          </Form.Group>
        </div>
        <div className="card-block">
          <div className="block-body">
            <table className="order-table table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "20px" }}>#</th>
                  <th>Product Title</th>
                  <th>SKU</th>
                  <th>Available Quantity</th>
                  <th>Assign Quantity</th>
                </tr>
              </thead>
              <tbody>
                {products && products?.length
                  ? products?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="order-item">{item?.product_name}</div>
                        </td>
                        <td>
                          <div className="order-item">{item?.sku}</div>
                        </td>
                        <td>
                          <div className="order-item">
                            {item?.available_quantity}
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            <Form.Control
                              className="my-input"
                              type="number"
                              disabled={item?.available_quantity === 0}
                              defaultValue={0}
                              onChange={(e) =>
                                handleQuantityUpdate(
                                  item?.variant_id,
                                  e.target.value,
                                  item?.available_quantity
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <div>
            <Button onClick={handleSubmit} className="float-right my-4">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </App>
  );
};

export default withRouter(AssignBuffer);
