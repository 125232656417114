import * as Yup from "yup";

const ProfileChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('New password is required.')
        .min(6, 'New password minimum length is 6.')
        .matches(
            /^(?=.*[!@#\$%\^&\*])/,
            "Must contain one special character. Example: *, & , # etc"
        )
        .max(40, 'New password maximum length is 40.'),
    password_confirmation: Yup.string()
        .required('Confirm password is required.')
        .oneOf([Yup.ref('password'), null], 'Value not matched with new password.'),
    current_password: Yup.string().trim().required("Current password is required"),
});

export default ProfileChangePasswordSchema;