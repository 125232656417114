import React, { Component } from "react";
import App from "../../App";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FiEdit,
  FiEye,
  FiPlus,
  FaFileExport,
  FiUpload,
  VscPreview,
} from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import { getProduct, bulkImport } from "../../services/productServices";
import "../../assets/scss/order-list.scss";
import { toast } from "react-toastify";
import "../../assets/scss/header.scss";
import { destroy, getList, exportFile } from "../../services/baseServices";
import ConfirmationModal from "../common/Modal";
import { getWarehouseType } from "../../helper/utils";
import { ECOM_URL } from "../../helper/env";

const warehouseType = getWarehouseType();

class List extends Component {
  constructor() {
    super();
    this.state = {
      productLists: [],
      isLoading: true,
      selectedFile: null,
      activePage: 1,
      per_page: 15,
      page: 1,
      temp_params: {},
      query_params: {},
      catergoryList: [],
      sub_cat_List: [],
      sub_cat_ref: React.createRef(),
      isExporting: false,
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getPaginatedProducts = (params) => {
    getProduct(params)
      .then((res) => {
        this.setState(
          {
            productLists: res.data,
            isLoading: false,
            per_page: res.headers["x-per-page"],
            total_data: res.headers["x-total"],
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
  };

  updatedTempParam = () => {
    const params = this.state.temp_params;
    delete params.sub_category_id;
    return params;
  };

  getSubCategories = (e) => {
    if (!!e.target.value) {
      let selected_cat = this.state.catergoryList.find(
        (item) => item.id == e.target.value
      );
      if (!!selected_cat && selected_cat.sub_categories.length > 0) {
        this.setState(
          {
            sub_cat_List: selected_cat.sub_categories,
          },
          () => {}
        );
        this.state.sub_cat_ref.current.value = "";
        this.setState({ temp_params: this.updatedTempParam() }, () => {});
      } else {
        this.setState({ sub_cat_List: [] }, () => {});
      }
    } else {
      this.setState(
        { sub_cat_List: [], temp_params: this.updatedTempParam() },
        () => {}
      );
    }
  };

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {});
  };

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    this.setState(
      { per_page: 50, page: 1, query_params: tempParam, isLoading: true },
      () => {
        this.getPaginatedProducts(this.state.temp_params);
      }
    );
  };

  componentDidMount() {
    this.getPaginatedProducts(this.getQueryParams());
    getList("categories")
      .then((res) => {
        this.setState({ catergoryList: res });
      })
      .catch((errMsg) => {});
  }

  handleDeleteProduct = (id) => {
    destroy("products", id)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success("Product has been deleted successfully");
          this.getPaginatedProducts(this.getQueryParams());
        } else {
          toast.error(res?.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  showBulkImporterModal = () => {
    this.setState({ isShowingBulkImportModal: true });
  };

  hideBulkImporterModal = () => {
    this.setState({ isShowingBulkImportModal: false });
  };

  handleImportBulkProduct = (e) => {
    e.preventDefault();
    this.setState({ isUploading: true }, () => {
      const data = this.state.selectedFile;
      bulkImport(data)
        .then((res) => {
          this.setState(
            {
              formData: null,
              isLoading: true,
              isShowingBulkImportModal: false,
              isUploading: false,
            },
            () => {
              this.getPaginatedProducts(this.getQueryParams());
            }
          );
          toast.success("Products has been imported successfully");
        })
        .catch((errMsg) => {
          this.setState({ isUploading: false });
          toast.error(errMsg);
        });
    });
  };

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedProducts(this.getQueryParams());
    });
  };

  handleExportFile = async () => {
    this.setState({ isExporting: true }, () => {
      exportFile("products/export", {})
        .then((res) => {
          window.location.href = res.file_path;
          console.log(res.file_path);
          this.setState(
            {
              isExporting: false,
            },
            () => {}
          );
          toast.success("Products has been exported successfully");
        })
        .catch((errMsg) => {
          this.setState({ isExporting: false });
          toast.error(errMsg);
        });
    });
  };

  render() {
    return (
      <App layout="">
        <Modal
          show={this.state.isShowingBulkImportModal}
          onHide={this.hideBulkImporterModal}
        >
          <Form onSubmit={this.handleImportBulkProduct}>
            <Modal.Header closeButton>
              <Modal.Title>Bulk Importing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} controlId="formFile">
                <Form.Label column sm="3">
                  File <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={9} lg={9}>
                  <Form.Control
                    type={`file`}
                    required
                    onChange={this.onFileChange}
                    name={`file`}
                  />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.hideBulkImporterModal}>
                Close
              </Button>
              <Button
                variant="primary"
                disabled={this.state.isUploading}
                type={`submit`}
              >
                {this.state.isUploading ? "Importing..." : "Import Now"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Product List</h2>
            <div className="ml-auto">
              {warehouseType === "central" && (
                <Button
                  size={"sm"}
                  variant={"default"}
                  disabled={this.state.isExporting}
                  className="mr-2"
                  onClick={() => this.handleExportFile()}
                >
                  <FaFileExport className="mr-1" />
                  {this.state.isExporting ? "Exporting..." : "Export Now"}
                </Button>
              )}
              {warehouseType === "central" && (
                <Button
                  size={`sm`}
                  variant={"default"}
                  onClick={this.showBulkImporterModal}
                  className={`mr-2`}
                >
                  <FiUpload className={`mr-2`} />
                  Bulk Import
                </Button>
              )}
              {/* {warehouseType === 'central' &&
                <Link to={`${process.env.PUBLIC_URL}/product/add-product`} className="btn btn-sm btn-secondary"><FiPlus /> Add Product </Link>
              } */}
            </div>
          </div>

          <form className="form-inline mb-3">
            <div className="form-group mr-3">
              <label htmlFor="sku" className="sr-only">
                Search by SKU
              </label>
              <input
                type="search"
                name="sku"
                id="sku"
                onChange={this.handleOnFieldChange}
                className="form-control form-control-sm"
                placeholder="Search by SKU"
              />
            </div>
            <div className="form-group mr-3">
              <label htmlFor="title" className="sr-only">
                Search by Title
              </label>
              <input
                type="search"
                name="title"
                id="title"
                onChange={this.handleOnFieldChange}
                className="form-control form-control-sm"
                placeholder="Search by Title"
              />
            </div>

            <div className="form-group mr-3">
              <label htmlFor="brand" className="sr-only">
                Search by Brand
              </label>
              <input
                type="text"
                name="brand"
                id="brand"
                onChange={this.handleOnFieldChange}
                className="form-control form-control-sm"
                placeholder="Search by Brand"
              />
            </div>

            <div className="form-group mr-3">
              <label htmlFor="category" className="sr-only">
                Category
              </label>
              <select
                id="category"
                name="category_id"
                className="form-control form-control-sm"
                onChange={(e) => {
                  this.getSubCategories(e);
                  this.handleOnFieldChange(e);
                }}
              >
                <option value="">--Select Category--</option>
                {this.state.catergoryList.length > 0
                  ? this.state.catergoryList.map((item) => {
                      return <option value={item.id}>{item.title}</option>;
                    })
                  : ""}
              </select>
            </div>

            <div
              className={`form-group mr-3 ${
                this.state.sub_cat_List.length <= 0 ? "d-none" : ""
              }`}
            >
              <label htmlFor="sub-category" className="sr-only">
                Sub Category
              </label>
              <select
                id="sub-category"
                name="sub_category_id"
                className="form-control form-control-sm"
                ref={this.state.sub_cat_ref}
                onChange={(e) => this.handleOnFieldChange(e)}
              >
                <option value="">--Select Sub Category--</option>
                {this.state.sub_cat_List.length > 0
                  ? this.state.sub_cat_List.map((item) => {
                      return <option value={item.id}>{item.title}</option>;
                    })
                  : ""}
              </select>
            </div>

            {/* <div className="form-group mr-3">
              <label htmlFor="business_type" className="sr-only">Business Type</label>
              <select id="business_type" name="business_type" className='form-control form-control-sm'
                onChange={ this.handleOnFieldChange}>
                <option value="">--Select Business Type--</option>
                <option value='b2c'>B2C</option>
                <option value='b2b'>B2B</option>
                <option value='both'>Both</option>
              </select>
            </div> */}

            <Button
              type={`submit`}
              onClick={this.handleOnSearch}
              size="sm"
              variant="primary"
            >
              Search
            </Button>
          </form>

          {this.state.isLoading ? (
            <div className={`card-block`}>
              <div className="block-body">Loading...</div>
            </div>
          ) : (
            <div className="card-block">
              <div className="block-body">
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      <th>Product Id</th>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Company</th>
                      <th>Supplier Tagged</th>
                      <th>Brand</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.productLists.length > 0 ? (
                      this.state.productLists.map((product, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {(this.state.page - 1) * this.state.per_page +
                                index +
                                1}
                            </td>
                            <td>
                              <Link
                                to={`${process.env.PUBLIC_URL}/product/details/${product.id}`}
                              >
                                {product.id}
                              </Link>
                            </td>
                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/product/details/${product.id}`}
                                  >
                                    <div className="user-avatar">
                                      <img
                                        src={product.hero_image}
                                        alt=""
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                    </div>
                                  </Link>
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/product/details/${product.id}`}
                                  >
                                    {product.title}
                                  </Link>
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {product?.company?.name}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {product.supplier_tag === undefined
                                    ? "N/A"
                                    : [product.supplier_tag ? "True" : "False"]}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {product.brand}
                                </h4>
                              </div>
                            </td>
                            <td style={{ width: "150px" }} className={"nowrap"}>
                              <a
                                href={`${ECOM_URL}/${product.slug}`}
                                target="_blank"
                                className="btn btn-sm btn-default"
                              >
                                <VscPreview />
                              </a>
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>View</Tooltip>
                                }
                              >
                                <Link
                                  to={`${process.env.PUBLIC_URL}/product/details/${product.id}`}
                                  className="btn btn-sm btn-default"
                                >
                                  <FiEye />
                                </Link>
                              </OverlayTrigger>
                              {warehouseType === "central" && (
                                <div className="btn-group">
                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Edit
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/product/edit/${product.id}`}
                                      className="btn btn-sm btn-default"
                                    >
                                      <FiEdit />
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Delete
                                      </Tooltip>
                                    }
                                  >
                                    <ConfirmationModal
                                      title="Delete"
                                      body="Are you sure you want to delete?"
                                      handleAction={() =>
                                        this.handleDeleteProduct(product.id)
                                      }
                                    />
                                  </OverlayTrigger>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <td className="text-center" colspan="6">
                        No Products Found
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.total_data > 0 ? (
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </App>
    );
  }
}

export default withRouter(List);
