import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import FormChangePassword from './partial/FormChangePassword'
import Table from 'react-bootstrap/Table';
import {getWarehouseName, getUserName} from "../../helper/utils";

const Settings = () => {
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Profile Settings</h2>
                <div className="ml-auto">
                    <Link to="/"
                          className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Dashboard</Link>
                </div>
            </div>
            <div className="card-block">
                <div className="block-header">
                    <h4 className="block-title">Profile Information</h4>
                </div>
                <div className="block-body">
                    <Table size="sm">
                        <tbody>
                            <tr>
                                <td>Name: {getUserName()}</td>
                            </tr>
                            <tr>
                                <td>Warehouse: {getWarehouseName()} </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="card-block">
                <div className="block-header">
                    <h4 className="block-title">Change Password</h4>
                </div>
                <div className="block-body">
                    <FormChangePassword/>
                </div>
            </div>
        </App>
    );
}
export default withRouter(Settings);