export function excel2dDataMapper(data) {
  const { skus, dates, details } = data;
  const result = [['Date', ...skus]];
  dates.forEach((date) => {
    const rowData = [date];
    skus.forEach((sku) => {
      const value = details[date][sku] ? details[date][sku] : 0;
      rowData.push(value);
    });
    result.push(rowData);
  });

  return result;
}


export function fileSuffixDateRange(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Format the date parts
  const startFormatted = `${start.getFullYear()}-${String(
    start.getMonth() + 1
  ).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')}`;
  
  const endFormatted = `${end.getFullYear()}-${String(
    end.getMonth() + 1
  ).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')}`;

  return `${startFormatted}_${endFormatted}`;
}


