import React, { useState, useEffect } from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import Rating from '@material-ui/lab/Rating';
import "react-datepicker/dist/react-datepicker.css";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";

function LocationForm(props)  {

  useEffect(() => {
  }, [props.formData]);

  return (

    <Form
      noValidate
        // validated={this.state.isFormValidated}
      onSubmit={props.handleClick}>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">Code
          <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"code"}
            onChange={props.handleInputOnChange}
            defaultValue={props.formData.code}
            required
            type="text"/>
          <Form.Control.Feedback type="invalid">Please enter code.</Form.Control.Feedback>
        </Col>
      </Form.Group>
      <div className="mt-3">
        <Button size="lg"
          type={`submit`}
          disabled={props.isSubmitting}
          variant="primary">{props.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
      </div>
      </Form>
    );
// }
}

export default LocationForm;
