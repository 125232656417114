import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { getList } from "../../services/baseServices";

function RiderForm(props) {
  const [errors, setErrors] = useState({});
  const [distributors, setDistributors] = useState([]);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  useEffect(() => {
      getList("distributors")
      .then((res) => {
          setDistributors(res?.data);
      })
      .catch((errMsg) => {});
  }, [])

  return (
    <Form
      noValidate
      validated={props.isFormValidated}
      onSubmit={props.handleClick}
    >
       <Form.Group as={Row} controlId="ds">
          <Form.Label column sm="3">
              Distributor <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
              <Form.Control
              required
              name="distributor_id"
              onChange={props.handleInputOnChange}
              // value={props.routeInfo.distributor_id}
              as="select"
              type="text"
              >
              <option value="">--Select--</option>
              {distributors.map((item, i) => {
                  return (
                      <option
                          key={i}
                          value={item.id}
                          selected={props.formData.distributor_id === item.id}
                      >
                          {item.name}
                      </option>
                  );
              })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
              Please select distributor.
              </Form.Control.Feedback>
          </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Name<span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"name"}
            pattern="^[a-zA-Z]+(\.?)(\s[a-zA-Z]+(\.?))*$"
            onChange={props.handleInputOnChange}
            defaultValue={props.formData.name}
            placeholder='Ex: Mr. John Smith'
            required
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.name}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Phone <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            name={"phone"}
            pattern="(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$"
            required
            onChange={props.handleInputOnChange}
            defaultValue={props.formData.phone}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.phone}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="displayOrShopName">
        <Form.Label column sm="3">
          Email
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Form.Control
            id="abc"
            name={"email"}
            pattern="^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$"
            onChange={props.handleInputOnChange}
            defaultValue={props.formData.email}
            autoComplete={false}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.email}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

   
          <Form.Group as={Row} controlId="password_hash">
            <Form.Label column sm="3">
              Password <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"password_hash"}
                onChange={props.handleInputOnChange}
                type="password"
                autoComplete="new-password"
                autoCorrect="off"
                required={props.isAddForm}
              />
              {!props.isAddForm &&
                <i className="text-help text-muted text-danger">Please keep this field blank if you don't want to
                  change the password.</i>
              }
              <Form.Control.Feedback type="invalid">
                {errors?.password_hash}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="password_confirmation">
            <Form.Label column sm="3">
              Confirm Password <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                required = {props.isAddForm || (!props.isAddForm && props.formData.password_hash)}
                id="password_confirmation"
                name={"password_confirmation"}
                onChange={props.handleInputOnChange}
                autoComplete="password_confirmation"
                autoCorrect="off"
                type="password"
              />
              {!props.isAddForm &&
                    <i className="text-help text-muted text-danger">Please keep this field blank if you don't want to
                        change the password.</i>
              }
              <Form.Control.Feedback
                type="invalid"
                className={
                  props.formData.password_hash !=
                  props.formData.password_confirmation
                    ? "d-block"
                    : ""
                }
              >
                {errors?.password_confirmation}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
       
      <div className="mt-3">
        <Button
          size="lg"
          type={`submit`}
          disabled={props.isSubmitting}
          variant="primary"
        >
          {props.isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </div>
    </Form>
  );
  // }
}

export default RiderForm;
