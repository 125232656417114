import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { DatePicker, TimePicker, Button as AntDButton } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import moment from "moment";
import { getList, putData } from "../../services";
import { toast } from "react-toastify";

dayjs.extend(customParseFormat);

const initialFormData = {
  mushak_code: "6.3",
  clauses: "See clauses (c) and (f) of Sub-Rule(1) of Rule 40",
  registered_person: "",
  bin_registered_person: "",
  address_registered_person: "",
  address_invoice_issue: "",
  customer_name: "",
  customer_bin: "",
  customer_address: "",
  shipping_address: "",
  vehicle_details: "",
  challan_no: "",
  date: dayjs(new Date()).format("YYYY-MM-DD"),
  time: dayjs(new Date().getTime()).format("HH:mm:ss"),
  footer_person_in_charge: "",
  footer_pic_designation: "",
};

const EditMushakModal = ({ showModal, handleClose, stoId }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    getList(`sto_mushaks/${stoId}`).then((res) => {
      if (res.status === 200) {
        setFormData({
          ...initialFormData,
          ...res.data,
          date:
            res.data.date === null || res.data.date === ""
              ? dayjs(new Date()).format("YYYY-MM-DD")
              : dayjs(res.data.date).toDate(),
          time:
            res.data.time === null || res.data.time === ""
              ? dayjs(new Date().getTime()).format("HH:mm:ss")
              : moment(res.data.time).format("HH:mm:ss"),
        });
      }
    });
  }, [stoId, showModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      date: date,
    });
  };

  const handleTimeChange = (_, timeString) => {
    setFormData({
      ...formData,
      time: timeString,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    putData(`sto_mushaks/${stoId}`, formData)
      .then((res) => {
        if (res.status === 200) {
          setFormData({
            ...initialFormData,
            date: initialFormData.date,
            time: initialFormData.time,
          });
          toast.success("Mushak updated successfully");
        }
      })
      .catch((err) => {
        toast.error("Failed to update Mushak");
      });
    handleClose();
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Edit Mushak</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.registered_person"
          >
            <Form.Label>Registered Person</Form.Label>
            <Form.Control
              type="text"
              name="registered_person"
              value={formData.registered_person}
              onChange={handleChange}
              placeholder="Enter Registered Person"
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.bin_registered_person"
          >
            <Form.Label>BIN Registered Person</Form.Label>
            <Form.Control
              type="text"
              name="bin_registered_person"
              value={formData.bin_registered_person}
              onChange={handleChange}
              placeholder="Enter BIN Registered Person"
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.address_registered_person"
          >
            <Form.Label>Address of Registered Person</Form.Label>
            <Form.Control
              type="text"
              name="address_registered_person"
              value={formData.address_registered_person}
              onChange={handleChange}
              placeholder="Enter Address Registered Person"
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.address_invoice_issue"
          >
            <Form.Label>Address of Invoice Issue</Form.Label>
            <Form.Control
              type="text"
              name="address_invoice_issue"
              value={formData.address_invoice_issue}
              onChange={handleChange}
              placeholder="Enter Address Invoice Issue"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.vehicle_details">
            <Form.Label>Vehicle Details</Form.Label>
            <Form.Control
              type="text"
              name="vehicle_details"
              value={formData.vehicle_details}
              onChange={handleChange}
              placeholder="Enter Vehicle Details"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.customer_name">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              name="customer_name"
              value={formData.customer_name}
              onChange={handleChange}
              placeholder="Enter Customer Name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.customer_bin">
            <Form.Label>Customer BIN</Form.Label>
            <Form.Control
              type="text"
              name="customer_bin"
              value={formData.customer_bin}
              onChange={handleChange}
              placeholder="Enter Customer BIN"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.customer_address">
            <Form.Label>Customer Address</Form.Label>
            <Form.Control
              type="text"
              name="customer_address"
              value={formData.customer_address}
              onChange={handleChange}
              placeholder="Enter Customer Address"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.shipping_address">
            <Form.Label>Shipping Address</Form.Label>
            <Form.Control
              type="text"
              name="shipping_address"
              value={formData.shipping_address}
              onChange={handleChange}
              placeholder="Enter Shipping Address"
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.challan_no">
            <Form.Label>Challan No</Form.Label>
            <Form.Control
              type="text"
              name="challan_no"
              value={formData.challan_no}
              onChange={handleChange}
              placeholder="Enter Challan No"
            />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="exampleForm.date">
            <Form.Label>Challan Date</Form.Label>
            <DatePicker
              style={{
                marginLeft: "10px",
                width: "50%",
              }}
              defaultValue={
                formData.date === null
                  ? dayjs(new Date()).format("YYYY-MM-DD")
                  : moment(formData.date)
              }
              selected={dayjs(formData.date)}
              dateFormat="yyyy-MM-dd"
              placeholder="Enter date"
              onChange={handleDateChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.time">
            <Form.Label>Challan Time</Form.Label>
            <TimePicker
              style={{
                marginLeft: "10px",
                width: "50%",
              }}
              open={open}
              onOpenChange={setOpen}
              renderExtraFooter={() => (
                <AntDButton
                  size="small"
                  type="primary"
                  onClick={() => setOpen(false)}
                >
                  OK
                </AntDButton>
              )}
              defaultValue={dayjs(formData.time, "HH:mm:ss")}
              format="HH:mm:ss"
              onChange={handleTimeChange}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.footer_person_in_charge"
          >
            <Form.Label>Person in Charge of the Organization</Form.Label>
            <Form.Control
              type="text"
              name="footer_person_in_charge"
              value={formData.footer_person_in_charge}
              onChange={handleChange}
              placeholder="Enter Person In Charge Of The Organization"
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="exampleForm.footer_pic_designation"
          >
            <Form.Label>Designation</Form.Label>
            <Form.Control
              type="text"
              name="footer_pic_designation"
              value={formData.footer_pic_designation}
              onChange={handleChange}
              placeholder="Enter Designation"
            />
          </Form.Group>
          <Button size="sm" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditMushakModal;
