export const WEEKDAYS = [
    {
        day_name: 'Sun',
        selected: false,
        day_value: 'sunday'
    },
    {
        day_name: 'Mon',
        selected: false,
        day_value: 'monday'
    },
    {
        day_name: 'Tue',
        selected: false,
        day_value: 'tuesday'
    },
    {
        day_name: 'Wed',
        selected: false,
        day_value: 'wednesday'
    },
    {
        day_name: 'Thu',
        selected: false,
        day_value: 'thursday'
    },
    {
        day_name: 'Fri',
        selected: false,
        day_value: 'friday'
    },
    {
        day_name: 'Sat',
        selected: false,
        day_value: 'saturday'
    }
]