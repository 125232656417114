import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {addCategory, getCategory} from "../../services/categoryServices";
import BrowseButton from "../../components/BrowseButton";
import {FaLongArrowAltLeft} from "react-icons/all";
import {tempImageAdd} from "../../services/productServices";

class Add extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      formData: {image_file:''},
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
      parent_ids: []
    };
  }

  componentDidMount() {
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
      console.log(this.state.formData);
    });
  }

  getSubcat = (category, categories) => {
    let cat = categories;
    category.forEach(item => {
      cat.push(item);
      if (item?.sub_categories?.length > 0) {
        this.getSubcat(item?.sub_categories, categories);
      }
    })
    return cat;
  }

  handleAddCategory = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        const formData = {...this.state.formData}
        formData.image_file = this.state.selectedFile;
        tempImageAdd(formData)
          .then(res => {
            this.props.history.push(`/temporaryImages`);
            toast.success("Temporary image has been uploaded successfully.");
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });

      })
    }
  }

  onFileChange = event => {
    let imges = [];
    for (let i = 0; i < event.target.files.length; i++) {
      imges.push(event.target.files[i]);
    }
    this.setState({selectedFile: imges});
  };

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Image</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/temporaryImages`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Temporary Image List</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form
                noValidate
                validated={this.state.isFormValidated}
                onSubmit={this.handleAddCategory}>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Image
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>

                    <BrowseButton
                      id={'mainImage'}
                      multiple
                      name={'image'}
                      onChange={this.onFileChange}
                    />
                  </Col>
                </Form.Group>
                <div className="mt-3">
                  <Button size="lg"
                          type={`submit`}
                          disabled={this.state.isSubmitting}
                          variant="primary">{this.state.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);