import React, {Component} from 'react';
import App from "../../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";
import BrowseButton from "../../../components/BrowseButton";
import {addSlider} from "../../../services/sliderServices";
import {toast} from "react-toastify";
import {getList} from "../../../services/baseServices"
import {humanize} from '../../../helper/utils'

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        img_type: "",
        link_url: "",
        published: false,
        position: "",
        body: "",
        name: "",
      },
      image: null,
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      imageTypes: {},
    };
  }

  componentDidMount() {
    getList("slides/image_type")
    .then(res => {
        let imageTypes = {}
        if(res?.success){
          imageTypes = res.data
        }
        this.setState({ imageTypes });
    })
    .catch(errMsg => {
        console.log('Error Message: ', errMsg)
    })
  }

  onFileChange = event => {
    this.setState({image: event.target.files[0]});
  };

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData});
  }

  handleAddItem = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true});
    } else {
      if (this.state.image === null) {
        return;
      }

      this.setState({isSubmitting: true}, () => {
        const formData = new FormData();
        formData.append('image', this.state.image)
        formData.append('img_type', this.state.formData.img_type);
        formData.append('link_url', this.state.formData.link_url);
        formData.append('published', this.state.formData.published);
        formData.append('position', this.state.formData.position);
        formData.append('body', this.state.formData.body);
        formData.append('name', this.state.formData.name);
        addSlider(formData).then(() => {
          this.props.history.push(`/common-slider/list`);
          toast.success("Slider has been added successfully.");
        }).catch(err => {
          toast.error(err);
        })
      })
    }
  }

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Slider</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/common-slider/list`} className="btn btn-sm btn-link">
              <FaLongArrowAltLeft/> Back to Slider List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form noValidate validated={this.state.isFormValidated} onSubmit={this.handleAddItem}>
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="3">Name <span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name={"name"} onChange={this.handleInputOnChange} required type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="link_url">
                  <Form.Label column sm="3">Link Url </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name={"link_url"} onChange={this.handleInputOnChange} type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter url.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="position">
                  <Form.Label column sm="3">position <span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name={"position"} onChange={this.handleInputOnChange} required type="number"/>
                    <Form.Control.Feedback type="invalid">Please enter position.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="body">
                  <Form.Label column sm="3">body <span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control name={"body"} onChange={this.handleInputOnChange} required type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter body.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="image">
                  <Form.Label column sm="3">Image <span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseButton required name={'image'} id={'image'}
                                  onChange={this.onFileChange}/>
                    <Form.Control.Feedback type="invalid">Please select a image type.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="published">
                  <Form.Label column sm="3">Publish <span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control required name="published" onChange={this.handleInputOnChange} as="select" type="text">
                      <option value="">--Select--</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please select a publish type.</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="img_type">
                  <Form.Label column sm="3">Image type <span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control required name="img_type" onChange={this.handleInputOnChange} as="select" type="text">
                      <option value="">--Select--</option>
                      {/* <option value="0">Slider Image</option>
                      <option value="1">Promo Image</option>
                      <option value="2">Selection Image</option> */}
                      {
                        Object.keys(this.state.imageTypes).map((key,i) => (
                          <option key={i+1} value={this.state.imageTypes[key]}>{humanize(key)}</option>
                        ))
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please select a image type.</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <div className="mt-3">
                  <Button size="lg" type={`submit`} disabled={this.state.isSubmitting}
                          variant="primary">{this.state.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);
