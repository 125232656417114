import {Button, Col, Form, Row} from "react-bootstrap";
import ProfileChangePasswordSchema from "../../ValidationSchema/ProfileChangePasswordSchema"
import React from "react";
import {Formik} from 'formik';
import {changePassword} from "../../../redux";
import {toast} from "react-toastify";
import { useDispatch } from "react-redux";

const FormChangePassword = () => {
    const dispatch = useDispatch();
    return (
        <Formik
            initialValues={{
                password: '',
                current_password: '',
                password_confirmation:''
            }}
            validationSchema={ProfileChangePasswordSchema}
            onSubmit={(values ,{ resetForm }) => {
                dispatch(changePassword(values, (isTrue,message) => {
                    if (isTrue) {
                        toast.success(message + ". Will be applicable for next time login.");
                        resetForm()
                    } else {
                        toast.error(message);
                    }
                }))
            }}
        >
            {(formik) => {
                const {
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isValid,
                    dirty,
                    touched
                } = formik;
                return (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                New Password <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    type="password"
                                    className={errors.password ? 'is-invalid' : ''}
                                    name="password"
                                    value={values.password}
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Confirm Password <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    type="password"
                                    className={errors.password_confirmation && touched.password_confirmation ? 'is-invalid' : ''}
                                    name="password_confirmation"
                                    value={values.password_confirmation}
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password_confirmation}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="3">
                                Current Password <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    type="password"
                                    className={errors.current_password && touched.current_password ? 'is-invalid' : ''}
                                    name="current_password"
                                    value={values.current_password}
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.current_password}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <div className="mt-3">
                            <Button
                                size="btn-sm"
                                type={"submit"}
                                variant="primary"
                                disabled={!(dirty && isValid)}
                            >
                                Change Password
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormChangePassword;
