import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { getList, getData } from "../../services/baseServices";
import JoditEditorComponent from "../../components/JoditEditorComponent";
import MetaInfo from "../../components/MetaInfo";
import { convertToSlug } from './../../helper/utils';

const ArticleForm = ({ article, metaInfo, setMetaInfo, onFormSubmit, edit }) => {
  const [helpTopics, setHelpTopics] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const schema = yup.object().shape({
    title: yup.string().trim().required("Title is a required"),
    slug: yup.string().trim().required("Slug is a required"),
    bn_title: yup.string().trim().required("Bn Title is a required"),
    body: yup.string().trim().required("Body is a required"),
    bn_body: yup.string().trim().required("Bn Body is a required"),
    help_topic_id: yup.number().required("Please select a help topic"),
    public_visibility: yup.bool().required("Please provide the info"),
    footer_visibility: yup.bool().required("Please provide the info"),
    position: yup.number().required("Please provide the info"),
  });

  const [uniqueSlug, setUniqueSlug] = useState('');
  const {id }= useParams();

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getList("help_topics")
      .then((res) => {
        if (res.success) setHelpTopics(res.data);
        setIsLoaded(true);
      })
      .catch((errMsg) => {
        setIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    reset({
      title: article.title,
      slug: article.slug,
      bn_title: article.bn_title,
      body: article.body,
      bn_body: article.bn_body,
      help_topic_id: article.help_topic_id,
      public_visibility: article.public_visibility,
      footer_visibility: article.footer_visibility,
      position: article.position,
    });
  }, [article]);

  // meta info
  const handleOnMetaInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMetaInfo({ ...metaInfo, [name]: value });
  };
  const handleOnChangeKeywordValue = (en_bn, value) => {
    const mitainfoDetails = { ...metaInfo };
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach((val) => {
        valueIdArr.push(val.value);
      });
    }
    if (en_bn === "en") {
      mitainfoDetails.metaKeywords = valueIdArr;
    } else {
      mitainfoDetails.metaKeyKeywordsBn = valueIdArr;
    }
    setMetaInfo(mitainfoDetails);
  };

  const onSubmit = (data) => {
    onFormSubmit(data);
  };


  const checkUniqueSlug = (slug, type)=>{
    if(slug) {
        const queryObj = id 
            ? {slug, id, type}
            : {slug, type}
        getData('slugs/uniqueness', queryObj)
        .then(res => setUniqueSlug(res.success))
        .catch(err => setUniqueSlug(''))
    } else {
        setUniqueSlug('');
    }
  }

  const handleSlug = (e) =>{
    if((!edit && e.target.name === 'title') || e.target.name === "slug") {
        const slug = convertToSlug(e.target.value);
        setValue('slug', slug, { shouldValidate: true })
        // setFormData(prev => ({ ...prev, ['slug']: slug }));
        checkUniqueSlug(e.target.value, "Article")
    }
}
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Basic Info</h4>
          </div>
          <div className="block-body">
            {isLoaded && (
              <Form.Group as={Row} controlId="helpTopic">
                <Form.Label column sm="3">
                  Help Topic <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    className={errors.help_topic_id ? "is-invalid" : ""}
                    {...register("help_topic_id")}
                    as="select"
                    type="text"
                  >
                    <option value="">--Select Help Topic--</option>
                    {helpTopics.length > 0
                      ? helpTopics.map((item) => {
                          return (
                            <option value={parseInt(item.id)} key={item.id}>
                              {item.title}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {errors.help_topic_id?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3">
                    Title <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        {...register("title")}
                        type="text"
                        className={errors.title ? 'is-invalid' : ''}
                        onChange= {(e)=>handleSlug(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.title?.message}
                     </Form.Control.Feedback>
                 
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3">
                    Slug <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        {...register("slug")}
                        disabled={edit}
                        type="text"
                        className={errors.title ? 'is-invalid' : ''}
                        onChange= {(e)=>handleSlug(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.slug?.message}
                        {uniqueSlug === false? "Please provide a unique Slug" : ""}
                     </Form.Control.Feedback>
                 
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="name">
              <Form.Label column sm="3">
                BN Title <span className="text-danger">*</span>
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  {...register("bn_title")}
                  type="text"
                  className={errors.bn_title ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bn_title?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="body">
              <Form.Label column sm="3">
                Body
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <JoditEditorComponent
                  tabIndex={122}
                  value={article.body}
                  onChange={(value) => {
                    setValue("body", value, { shouldValidate: true });
                  }}
                />
                <div className="text-danger is-invalid">
                  {errors.body?.message}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="bn_body">
              <Form.Label column sm="3">
                BN Body
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <JoditEditorComponent
                  tabIndex={122}
                  value={article.bn_body}
                  onChange={(value) => {
                    setValue("bn_body", value, { shouldValidate: true });
                    // this.handleJoEditorOnChange('short_description', value);
                  }}
                />
                <div className="text-danger is-invalid">
                  {errors.bn_body?.message}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="visibily">
              <Form.Label column sm="3">
                Public Visibility
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  className={errors.public_visibility ? "is-invalid" : ""}
                  {...register("public_visibility")}
                  as="select"
                  type="text"
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Form.Control>

                <Form.Control.Feedback type="invalid">
                  {errors.public_visibility?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="visibily">
              <Form.Label column sm="3">
                Footer Visibility
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  className={errors.footer_visibility ? "is-invalid" : ""}
                  {...register("footer_visibility")}
                  as="select"
                  type="text"
                >
                  <option value={false}>False</option>
                  <option value={true}>True</option>
                </Form.Control>

                <Form.Control.Feedback type="invalid">
                  {errors.footer_visibility?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="visibily">
              <Form.Label column sm="3">
                Position
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Form.Control
                  className={errors.position ? "is-invalid" : ""}
                  {...register("position")}
                  type="number"
                ></Form.Control>

                <Form.Control.Feedback type="invalid">
                  {errors.position?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </div>
        </div>
        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Meta Info</h4>
          </div>
          <div className="block-body">
            <MetaInfo
              metaInfo={metaInfo}
              handleOnInputChange={handleOnMetaInputChange}
              handleOnChangeKeywordValue={handleOnChangeKeywordValue}
            />
          </div>
        </div>
        <div className="mt-3 text-center">
          <Link
            type="button"
            className="btn btn-sm btn-btn-sm mr-2"
            to={`${process.env.PUBLIC_URL}/articles/list`}
          >
            <Button type="button" variant="danger">
              Cancel
            </Button>
          </Link>
          <Button
                size="btn-sm"
                type={"submit"}
                variant="primary"
                disabled={uniqueSlug === false}
                >
                Submit
            </Button>
        </div>
      </Form>
    </>
  );
};

export default ArticleForm;
