import React, { useContext, useState, useEffect } from "react";
import { Accordion, Card, Button, Form } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { GoChevronUp, GoChevronDown } from "react-icons/go";
import { assignLocationToPO } from "../../services/purchaseOrderService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import QCModal from "./QCModal";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import ExportCSV from "../common/ExportCSV";

const ChBoxingItem = ({
  boxItem,
  po_id,
  receivedToCWh,
  locations,
  updateBoxList,
  headers,
  getPurchaseOrder,
  showModalHandler
}) => {
  const [orderLocations, setOrderLocations] = useState({});
  const [boxLineItems, setBoxLineItems] = useState([]);
  const [searchBySku, setSearchBySku] = useState("");
  const [showQCModal, setShowQCModal] = useState(false);
  const [selectLineItemId, setSelectedLineItemId] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [exportForBoxLineItems, setExportForBoxLineItems] = useState([]);

  const setSelectedLocation = (location_id) => {
    setSelectedLocationId(location_id);
    return selectedLocationId;
  };

  const handleSearchBySku = (e) => {
    setSearchBySku(e.target.value);
  };
  useEffect(() => {
    const searchResult = boxItem.line_items?.filter((item) =>
      item.sku.toLowerCase().includes(searchBySku)
    );
    setBoxLineItems(searchResult);
    findItemBySku();
  }, [searchBySku]);

  console.log("receivedToCWh", receivedToCWh);

  useEffect(() => {
    let boxLineItemList = boxItem.line_items?.map((item) => {
      let obj = { ...item };
      if (obj.qc_status) obj.qc_status = "Done";
      else obj.qc_status = "Not Done";
      return obj;
    });
    setExportForBoxLineItems(boxLineItemList);
    setBoxLineItems(boxItem?.line_items);
  }, [boxItem, boxItem.line_items.length]);

  const findItemBySku = () => {
    const boxLineItemList = [...boxLineItems];
    const selectedItem = boxLineItemList?.find(
      (item) => item.sku.toLowerCase() === searchBySku
    );
    return selectedItem && !selectedItem.qc_status
      ? handleStartQC(selectedItem?.id)
      : "";
  };

  const setLineItemLocation = (index, e, order_item_id) => {
    setSelectedLocation(e.target.value);
    const orderLocation = { ...orderLocations };
    orderLocation[order_item_id] = e.target.value;
    setOrderLocations(orderLocation);
  };

  const debouncedOnAssignLocation = _.debounce((order_item_id) => {
    assignLocation(order_item_id);
  }, DEBOUNCE_TIME);

  const handleWithDebounceAssignLocation = async (order_item_id) => {
    debouncedOnAssignLocation(order_item_id);
  };

  const assignLocation = (order_item_id) => {
    const params = {
      line_item_id: order_item_id,
      location_id: orderLocations[order_item_id],
      order_type: 'rto'
    };
    assignLocationToPO(params)
      .then((res) => {
        if (res.id) {
          let index = boxLineItems.findIndex((item) => item.id === res.id);
          const boxlineItemList = [...boxLineItems];
          boxlineItemList[index] = res;
          setBoxLineItems(boxlineItemList);
          toast.success("Location assigned successfully");
        } else {
          toast.error("Location assigned failed");
        }

        updateBoxList(boxItem.id, res);
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  const showHideQCModal = () => {
    setShowQCModal(!showQCModal);
  };

  const handleStartQC = (item_id) => {
    setSelectedLineItemId(item_id);
    showHideQCModal();
  };

  const handleGetQC = (qc) => {
    let id = qc.id;
    let index = boxLineItems.findIndex((item) => item.id === id);
    const boxlineItemList = [...boxLineItems];
    boxlineItemList[index] = qc;
    setBoxLineItems(boxlineItemList);
  };

  return (
    <Accordion defaultActiveKey="0" className="my-4">
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Box ID #{boxItem.id}
          </Accordion.Toggle>
          <ContextAwareToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {receivedToCWh && (
              <Form>
                <Form.Group className="mb-3 w-50 mx-auto">
                  <Form.Control
                    type="text"
                    value={searchBySku}
                    onChange={handleSearchBySku}
                    placeholder="Search by SKU"
                  />
                </Form.Group>
              </Form>
            )}
            <div className="my-3 d-flex justify-content-end">
              <div className="mr-2">
                <ExportCSV
                  data={exportForBoxLineItems}
                  headers={headers}
                  title="Stock Transfer Order Items"
                />
              </div>
            </div>

            <table className="order-table table table-striped table-hover">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Unit Price</th>
                  <th>Total Price</th>
                  <th className="text-center">Ordered Quantity</th>
                  <th className="text-center">Sent Quantity</th>
                  <th className="text-center">Received</th>
                  <th className="text-center">Passed</th>
                  <th className="text-center">Quality Failed</th>
                  <th className="text-center">Quantity Failed</th>
                  <th className="text-center">QC Status</th>
                  <th>Action</th>
                  {/* {receivedToCWh &&
                    boxLineItems?.some((item) => item.qc_status === false) && (
                      <th>Action</th>
                    )} */}
                  {boxLineItems?.some((item) => !!item.qc_status) && (
                    <th className="text-center">Location</th>
                  )}
                  {boxLineItems?.some(
                    (item) => !item.location && item.qc_status
                  ) && <th>Assign</th>}

                </tr>
              </thead>
              <tbody>
                {boxLineItems?.length > 0 &&
                  boxLineItems?.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <div className="order-item">
                          <Link
                            to={`${process.env.PUBLIC_URL}/product/details/${item.product_id}`}
                          >
                            {item.product_title}
                          </Link>
                        </div>
                      </td>

                      <td>
                        <div className="order-item">{item.price} BDT</div>
                      </td>

                      <td>
                        <div className="order-item">{item.total_price} BDT</div>
                      </td>

                      <td>
                        <div className="order-item text-center">
                          {item.quantity}
                        </div>
                      </td>
                      <td>
                        <div className="order-item text-center">
                          {item.sent_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item text-center">
                          {item.received_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item text-center">
                          {item.qc_passed}
                        </div>
                      </td>

                      <td>
                        <div className="order-item text-center">
                          {item.quality_failed}
                        </div>
                      </td>

                      <td>
                        <div className="order-item text-center">
                          {item.quantity_failed}
                        </div>
                      </td>

                      <td>
                        <div className="order-item text-center">
                          {item.qc_status ? "Done" : "Not Done"}
                        </div>
                      </td>
                      {receivedToCWh && !item.qc_status && (
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm btn-outline-secondary"
                              onClick={() => handleStartQC(item.id)}
                            >
                              Start QC
                            </button>
                          </div>
                        </td>
                      )}
                      <td>
                        <Button
                          size="sm"
                          type={"button"}
                          className="mr-1"
                          onClick={() => showModalHandler(item?.id)}
                          variant="info">
                          Log
                        </Button>
                      </td>
                      {item.qc_status && !(item.received_quantity === item.quality_failed) && (
                        <td style={{ width: "200px" }}>
                          <select
                            id="location"
                            name="location_id"
                            className="form-control form-control-sm"
                            onChange={(e) =>
                              setLineItemLocation(index, e, item.id)
                            }
                            disabled={!item.qc_status || item.location?.id}
                            value={
                              item.location
                                ? item.location.id
                                : orderLocations[item.id]
                            }
                          >
                            <option value="">--Locations--</option>
                            {locations.length > 0
                              ? locations.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.code}
                                  </option>
                                );
                              })
                              : ""}
                          </select>

                        </td>
                      )}

                      {item.qc_status && !item.location?.id && !(item.received_quantity === item.quality_failed) && (
                        <td>
                          <Button
                            size="sm"
                            type={"button"}
                            className="mr-1"
                            onClick={() =>
                              handleWithDebounceAssignLocation(item.id)
                            }
                            variant="info"
                          >
                            Assign
                          </Button>
                        </td>
                      )}

                    </tr>
                  ))}
              </tbody>
            </table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {selectLineItemId && (
        <QCModal
          show={showQCModal}
          handleClose={showHideQCModal}
          id={selectLineItemId}
          po_id={po_id}
          handleGetQC={handleGetQC}
          box_id={boxItem.id}
          updateBoxList={updateBoxList}
          getPurchaseOrder={getPurchaseOrder}
        />
      )}
    </Accordion>
  );
};
export default ChBoxingItem;

function ContextAwareToggle({ eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <div onClick={decoratedOnClick}>
      {isCurrentEventKey ? <GoChevronUp /> : <GoChevronDown />}
    </div>
  );
}
