import App from "../../App";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import * as yup from "yup";
import CouponForm from "./CouponForm";

const schema = yup.object().shape(
  {
    title: yup
      .string()
      .required("Title is required")
      .trim()
      .max(100, "Title must be at most 100 characters"),
    startDate: yup
      .string()
      .typeError("Start date is required")
      .required("Start date is required"),
    endDate: yup
      .string()
      .typeError("End date is required")
      .required("End date is required"),
    status: yup.string().required("Status is required"),

    minimumCartValue: yup
      .number()
      .typeError("Minimum cart value is required")
      .required("Minimum cart value is required")
      .min(0, "Minimum cart value must be greater than or equal to 0"),

    noOfCoupon: yup
      .number()
      .typeError("Total no of coupon is required")
      .required("Total no of coupon is required")
      .min(0, "Total no of coupon must be greater than or equal to 0"),

    orderType: yup.string().required("Order type is required"),

    productRule: yup.string().typeError(""),

    brandNames: yup.array().when(["productRule"], {
      is: (productRule) => productRule === "Brand",
      then: yup
        .array()
        .min(1, "At least one brand is required")
        .required("Brands is required"),
    }),

    skus: yup.array().when(["productRule"], {
      is: (productRule) => productRule === "Variant",
      then: yup
        .array()
        .min(1, "At least one SKU is required")
        .required("SKUs is required"),
    }),

    categories: yup.array().when(["productRule"], {
      is: (productRule) => productRule === "Category",
      then: yup
        .array()
        .min(1, "At least one category is required")
        .required("Categories is required"),
    }),

    userRule: yup.string().typeError(""),
    customers: yup.array().when(["userRule"], {
      is: (userRule) => userRule === "User",
      then: yup
        .array()
        .min(1, "At least one customer is required")
        .required("Customers is required"),
    }),
    partners: yup.array().when(["userRule"], {
      is: (userRule) => userRule === "Partner",
      then: yup
        .array()
        .min(1, "At least one outlet is required")
        .required("Outlets is required"),
    }),
    fcs: yup.string().when(["userRule"], {
      is: (userRule) => userRule === "Warehouse",
      then: yup.string().required("Warehouse is required"),
    }),

    discountType: yup.string().required("Discount type rule is required"),
    discountAmount: yup
      .number()
      .typeError("Discount amount is required")
      .required("Discount amount is required")
      .min(0, "Discount amount must be greater than or equal to 0"),

    maximumDiscountAmount: yup
      .number()
      .typeError("Maximum discount amount is required")
      .when(["discountType"], {
        is: (discountType) => {
          return discountType == 1;
        },
        then: yup
          .number()
          .typeError("Maximum discount amount is required")
          .required("Maximum discount amount is required")
          .min(0, "Maximum discount amount must be greater than or equal to 0"),
      }),
  },
  [["productRule", "userRule", "discountType"]]
);

const Add = () => {
  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Add Coupon</h2>
        <div className="ml-auto">
          <Link
            to={`${process.env.PUBLIC_URL}/coupon/list`}
            className={`btn btn-sm btn-link`}
          >
            <MdArrowBack />
            Back to List
          </Link>
        </div>
      </div>

      <div className="card-block">
        <div className="block-body">
          <CouponForm formMode="CREATE" validationSchema={schema} />
        </div>
      </div>
    </App>
  );
};

export default Add;
