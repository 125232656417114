import React, { Component } from "react";
import App from "../../App";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import { updatePOStatus } from "../../services/purchaseOrderService";
import { getData, getDetails, postData } from "../../services/baseServices";
import { FaLongArrowAltLeft, FaPrint } from "react-icons/all";
import { getDate, humanize } from "../../helper/utils";
import ChBoxingItem from "./ChBoxingItem";
import BoxModal from "./BoxModal";
import ExportCSV from "../common/ExportCSV";
import ConfirmationModal from "../common/Modal";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import PoDetailsModal from "../purchaseOrder/PoDetailsModal";
import AddMushakModal from "./AddMushakModal";
import EditMushakModal from "./EditMushakModal";
import PrintMushak from "./PrintMushak";
import ConfirmModal from "./ConfirmModal";
import StoInvoice from "./StoInvoice";

const headers = [
  { label: "ID", key: "id" },
  { label: "Product", key: "product_title" },
  { label: "Unit Price", key: "price" },
  { label: "Total Price", key: "total_price" },
  { label: "Ordered", key: "quantity" },
  { label: "Sent", key: "sent_quantity" },
  { label: "Received", key: "received_quantity" },
  { label: "Passed", key: "qc_passed" },
  { label: "Quality Failed", key: "quality_failed" },
  { label: "Quantity Failed", key: "quantity_failed" },
  { label: "QC Status", key: "qc_status" },
];

class ChDetails extends Component {
  constructor() {
    super();
    this.state = {
      purchaseOrder: {},
      selectedRow: 0,
      isLoading: true,
      packedStatus: false,
      orderPlaced: false,
      readyToShip: false,
      inTransit: false,
      receivedToDh: false,
      dispatchStatus: false,
      openPackOptions: false,
      showCheckbox: false,
      selectedLineItemsId: [],
      boxItems: [],
      showBoxModal: false,
      exportForLineItems: [],
      showModal: false,
      modalData: [],
      addMushakModal: false,
      editMusakModal: false,
    };
  }

  printMushakRef = React.createRef();
  stoInvoiceRef = React.createRef();

  handlePrint = () => {
    const originalTitle = document.title;
    const stoNumber = this.props.match.params.id;
    document.title = `Mushak-${stoNumber}.pdf`;
    setTimeout(() => {
      document.title = originalTitle;
    }, 1000);
  };

  handleOpenMushokModal = () => {
    this.setState({
      addMushakModal: true,
    });
  };

  handleCloseMushokModal = () => {
    this.setState({
      addMushakModal: false,
    });
  };

  handleMushakEditModal = () => {
    this.setState({
      editMusakModal: true,
    });
  };
  handleCloseMushakEditModal = () => {
    this.setState({
      editMusakModal: false,
    });
  };

  changeStatus = (order) => {
    switch (order.order_status) {
      case "Order placed":
        this.setState({
          orderPlaced: true,
          readyToShip: false,
          inTransit: false,
          receivedToDh: false,
        });
        return;
      case "Ready to ship":
        this.setState({
          orderPlaced: false,
          readyToShip: true,
          inTransit: false,
          receivedToDh: false,
        });
        return;
      case "In transit":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: true,
          receivedToDh: false,
        });
        return;
      case "Received":
        this.setState({
          orderPlaced: false,
          readyToShip: false,
          inTransit: false,
          receivedToDh: true,
        });
        return;
      default:
        return;
    }
  };

  componentDidMount() {
    this.getPurchaseOrder();
  }

  getPurchaseOrder = () => {
    this.setState({ order_id: this.props.match.params.id });
    getDetails("dh_purchase_orders", this.props.match.params.id)
      .then((res) => {
        let order = { ...res };
        this.setState({ purchaseOrder: order.wh_purchase_order });
        let LineItems = res.wh_purchase_order.line_items?.map((item) => {
          let obj = { ...item };
          if (obj.qc_status) obj.qc_status = "Done";
          else obj.qc_status = "Not Done";
          return obj;
        });
        this.setState({ exportForLineItems: LineItems });
        this.changeStatus(order.wh_purchase_order);
        this.setState({ isLoading: false });
      })
      .catch((errMsg) => {});
  };

  debouncedOnChangeStatus = _.debounce((url) => {
    this.updateStatus(url);
  }, DEBOUNCE_TIME);

  handleWithDebounceChangeStatus = async (url) => {
    this.debouncedOnChangeStatus(url);
  };

  updateStatus = (url) => {
    updatePOStatus(this.props.match.params.id, url)
      .then((res) => {
        if (!res.status_code) {
          let order = { ...res };
          this.setState({ purchaseOrder: order.wh_purchase_order });
          this.changeStatus(order.wh_purchase_order);
          toast.success("Successfully done " + humanize(url));
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {});
  };

  handleShowAndCloseModal = () => {
    this.setState({
      selectedLineItemsId: [],
      showBoxModal: !this.state.showBoxModal,
    });
  };

  handleBoxing = () => {
    this.setState({
      showCheckbox: !this.state.showCheckbox,
    });
  };

  selectUnselectItem = (id) => {
    let selectedItem = [...this.state.selectedLineItemsId];
    if (selectedItem.includes(id)) {
      let index = selectedItem.indexOf(id);
      selectedItem.splice(index, 1);
      this.setState({ selectedLineItemsId: selectedItem });
    } else {
      selectedItem.push(id);
      this.setState({ selectedLineItemsId: selectedItem });
    }
  };
  cancelSelection = () => {
    let NoOfselectedLineItemsId = this.state.selectedLineItemsId.length;
    if (NoOfselectedLineItemsId > 0) {
      this.setState({
        selectedLineItemsId: [],
      });
    }
  };

  debouncedOnCreateBox = _.debounce(() => {
    this.createBox();
  }, DEBOUNCE_TIME);

  handleWithDebounceCreateBox = async () => {
    this.debouncedOnCreateBox();
  };

  createBox = () => {
    let params = {
      dh_po_id: this.state.purchaseOrder.id,
      line_item_ids: [...this.state.selectedLineItemsId],
    };
    postData("dh_purchase_orders/boxes", params)
      .then((res) => {
        if (res.status_code === 200 || res.status_code === 201) {
          this.setState({ selectedLineItemsId: [] }, () => {
            toast.success(res.message);
            this.getPurchaseOrder();
          });
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  moveItemToBox = () => {
    this.setState({ showBoxModal: !this.state.showBoxModal });
  };

  showModalHandler = (id) => {
    getData(`line_items/${id}`)
      .then((res) => {
        this.state.modalData = res?.data;
        this.setState({
          ...this.state,
          showModal: !this.state.showModal,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  hideModalHandler = () => {
    this.setState({
      ...this.state,
      showModal: false,
    });
  };

  handleStoInvoicePrint = () => {
    const originalTitle = document.title;
    const stoNumber = this.props.match.params.id;
    document.title = `Sto-Invoice-${stoNumber}.pdf`;
    setTimeout(() => {
      document.title = originalTitle;
    }, 1000);
  };

  onGenerateMushakSuccess = () => {
    this.getPurchaseOrder();
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="text-left">
              {this.state.orderPlaced &&
                this.state.purchaseOrder.line_items?.length > 0 && (
                  <Button
                    size="sm"
                    type={"button"}
                    className="mr-2"
                    onClick={this.handleBoxing}
                    variant="info"
                  >
                    Boxing
                  </Button>
                )}

              {!this.state.purchaseOrder.mushak_created ? (
                <Button
                  size="sm"
                  type={"button"}
                  className="mr-2"
                  variant="secondary"
                  onClick={this.handleOpenMushokModal}
                >
                  Generate Mushak
                </Button>
              ) : (
                <Button
                  size="sm"
                  type={"button"}
                  className="mr-2"
                  variant="secondary"
                  onClick={this.handleMushakEditModal}
                >
                  Edit Mushak
                </Button>
              )}
              <EditMushakModal
                showModal={this.state.editMusakModal}
                handleClose={this.handleCloseMushakEditModal}
                stoId={this.props.match.params.id}
              />
              <AddMushakModal
                showModal={this.state.addMushakModal}
                stoId={this.props.match.params.id}
                handleClose={this.handleCloseMushokModal}
                onGenerateMushakSuccess={this.onGenerateMushakSuccess}
              />
              {this.state.readyToShip && (
                <Button
                  size="sm"
                  type={"button"}
                  className="mr-1"
                  onClick={() => {
                    this.handleWithDebounceChangeStatus("in_transit");
                  }}
                  variant="info"
                >
                  Dispatch
                </Button>
              )}
            </div>
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/dhPurchaseOrder/list`}
                className="btn btn-sm btn-link"
              >
                <FaLongArrowAltLeft /> Back to List
              </Link>
            </div>
          </div>

          {this.state.isLoading ? (
            <div className={`card-block`}>
              <div className="block-body">Loading...</div>
            </div>
          ) : (
            <div className="card-block">
              <div className="block-header">
                <h5 className="card-title">
                  CWH wise Stock Transfer Order Details
                </h5>
                <div className="d-flex justify-content-end">
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <a href="#">
                          <Button
                            className="mx-2"
                            variant={"default"}
                            onClick={this.handlePrint}
                            size={"sm"}
                          >
                            <FaPrint /> Print Mushak
                          </Button>
                        </a>
                      );
                    }}
                    content={() => this.printMushakRef.current}
                  />
                  <div className="only-print">
                    <PrintMushak
                      ref={this.printMushakRef}
                      stoId={this.props.match.params.id}
                    />
                  </div>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <a href="#">
                          <Button
                            variant={"default"}
                            onClick={this.handleStoInvoicePrint}
                            size={"sm"}
                          >
                            <FaPrint /> Print Invoice
                          </Button>
                        </a>
                      );
                    }}
                    content={() => this.stoInvoiceRef.current}
                  />

                  <div className="only-print">
                    <StoInvoice
                      ref={this.stoInvoiceRef}
                      stoId={this.props.match.params.id}
                    />
                  </div>
                </div>
              </div>
              <div className="block-body">
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>STO ID</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state?.purchaseOrder?.id}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Creation Date</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          getDate(this.state.purchaseOrder.order_date).format(
                            "Do MMMM YYYY"
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Quantity</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder.quantity}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>QC Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                        this.state.purchaseOrder.qc_status
                          ? "Done"
                          : "Not Done"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                        this.state.purchaseOrder.order_status
                          ? this.state.purchaseOrder.order_status
                          : "Unknown"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Created By</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder?.created_by?.name}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>TIN</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{this.state.purchaseOrder?.tin ?? "--"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>BIN</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{this.state.purchaseOrder?.bin ?? "--"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>NID Number</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>{this.state.purchaseOrder?.nid ?? "--"}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Total Price</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder.total_price}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Wallet Payment</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder?.payments?.from_wallet}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Bank Deposit</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder?.payments?.total_deposit}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Total Due</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td>
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder?.payments?.due}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Payment Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td
                        style={{
                          color:
                            this.state.purchaseOrder &&
                            this.state.purchaseOrder?.payments?.status ===
                              "paid"
                              ? "green"
                              : this.state.purchaseOrder?.payments?.status ===
                                "partial"
                              ? "orange"
                              : "red",
                        }}
                      >
                        {this.state.purchaseOrder &&
                          this.state.purchaseOrder?.payments?.status?.toUpperCase()}
                      </td>
                    </tr>
                    {this.state.purchaseOrder && (
                      <tr>
                        <td style={{ width: "250px" }}>
                          Distributor Address:{" "}
                        </td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{this.state.purchaseOrder.warehouse_address}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div
            className={`card-block ${
              this.state.purchaseOrder?.line_items?.length > 0 ? "" : "d-none"
            }`}
          >
            <div className="block-header">
              <h4 className="block-title">Stock Transfer Order Items</h4>
              <div className="ml-auto">
                <ExportCSV
                  data={this.state.exportForLineItems}
                  headers={headers}
                  title="Stock Transfer Order Items"
                />
              </div>
            </div>

            {this.state.isLoading ? (
              <div className={`card-block`}>
                <div className="block-body">Loading...</div>
              </div>
            ) : this.state.purchaseOrder?.line_items?.length > 0 ? (
              <div className="card">
                <div className="card-body">
                  <>
                    <table className="order-table table table-striped table-hover">
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: "20px" }}>#</th>
                          <th>Product</th>
                          <th>Unit Price</th>
                          <th>Total Price</th>
                          <th>Available</th>
                          <th>Ordered</th>
                          <th>Sent</th>
                          <th>Received</th>
                          <th>Passed</th>
                          <th>Quality Failed</th>
                          <th>Quantity Failed</th>
                          <th>QC Status</th>
                          {this.state.openPackOptions && (
                            <th>
                              Req. Fields <span className="text-danger">*</span>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.purchaseOrder?.line_items?.length > 0 &&
                          this.state.purchaseOrder?.line_items?.map(
                            (orderItem, index) => {
                              return (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    this.setState({ selectedRow: index })
                                  }
                                >
                                  <td>
                                    {this.state.showCheckbox && (
                                      <Form.Group className="order-item pt-3">
                                        <Form.Check
                                          type="checkbox"
                                          onChange={() =>
                                            this.selectUnselectItem(
                                              orderItem.id
                                            )
                                          }
                                          checked={this.state.selectedLineItemsId.includes(
                                            orderItem.id
                                          )}
                                        />
                                      </Form.Group>
                                    )}
                                  </td>

                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="order-item">
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/product/details/${orderItem.product_id}`}
                                      >
                                        {orderItem.product_title}
                                      </Link>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.price} BDT
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.total_price} BDT
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.available_quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.sent_quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.received_quantity}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.qc_passed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.quality_failed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.quantity_failed}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="order-item">
                                      {orderItem.qc_status
                                        ? "Done"
                                        : "Not Done"}
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                    {this.state.orderPlaced && (
                      <div className="d-flex justify-content-end">
                        <OverlayTrigger
                          key="view"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-view`}>Create Box</Tooltip>
                          }
                        >
                          <ConfirmationModal
                            customBtn={true}
                            variant="primary"
                            size="sm"
                            btnText="Create Box"
                            btnClassName={`mr-2 ${
                              this.state.selectedLineItemsId.length > 0
                                ? ""
                                : "disabled"
                            }`}
                            title="Create Box"
                            body="Are you sure you want to create box?"
                            handleAction={this.handleWithDebounceCreateBox}
                          />
                        </OverlayTrigger>
                        <button
                          className="btn btn-primary btn-sm mr-2"
                          type="button"
                          disabled={
                            !(
                              this.state.selectedLineItemsId.length > 0 &&
                              this.state.purchaseOrder?.boxes?.length > 0
                            )
                          }
                          onClick={this.moveItemToBox}
                        >
                          Move
                        </button>

                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={this.cancelSelection}
                          disabled={
                            this.state.selectedLineItemsId.length > 0
                              ? false
                              : true
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </>
                </div>
              </div>
            ) : (
              <div className="text-center py-4">
                <p>There are no line items for boxing</p>
              </div>
            )}
          </div>
          {this.state.isLoading ? (
            <div className={`card-block`}>
              <div className="block-body">Loading...</div>
            </div>
          ) : (
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Bank Deposit Details</h4>
              </div>

              <div className="card">
                <div className="card-body">
                  <table className="order-table table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "20px" }}>#</th>
                        <th>Deposit Amount</th>
                        <th>Deposit No</th>
                        <th>Deposit Slip</th>
                        <th>Uploaded On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.purchaseOrder?.deposits.map((item, index) => (
                        <tr key={item?.id}>
                          <td>{index + 1}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.deposit_no}</td>
                          <td>
                            <a
                              href={item?.slip}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={item?.slip}
                                alt="slip"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>
                          </td>
                          <td>{item?.uploaded_on}</td>
                          <td>
                            {item?.is_deposited ? (
                              <div className="order-item text-success">
                                Received
                              </div>
                            ) : (
                              <div className="order-item">
                                <ConfirmModal
                                  item={item}
                                  getPurchaseOrder={this.getPurchaseOrder}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.selectedLineItemsId?.length > 0 && (
          <BoxModal
            show={this.state.showBoxModal}
            handleClose={this.handleShowAndCloseModal}
            existingBoxes={this.state.purchaseOrder?.boxes}
            type={"Ch_Details"}
            dh_po_id={this.state.purchaseOrder?.id}
            lineItemId={this.state.selectedLineItemsId}
            getPurchaseOrder={this.getPurchaseOrder}
          />
        )}
        {this.state.purchaseOrder?.boxes?.length > 0 &&
          this.state.purchaseOrder?.boxes?.map((item) => (
            <ChBoxingItem
              key={item.id}
              showModalHandler={this.showModalHandler}
              boxItem={item}
              purchaseOrder={this.state.purchaseOrder}
              getPurchaseOrder={this.getPurchaseOrder}
              headers={headers}
            />
          ))}

        <PoDetailsModal
          show={this.state.showModal}
          modalTitle="STO Details Log"
          onHide={this.hideModalHandler}
          data={this.state.modalData}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
      </App>
    );
  }
}

export default withRouter(ChDetails);
