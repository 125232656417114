import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaLongArrowAltLeft } from "react-icons/all";
import { promotionAdd } from "../../services/baseServices";
import "react-datepicker/dist/react-datepicker.css";
import PromotionForm from "./Form";
// import { getList } from "../../services/baseServices";

class Add extends Component {
  constructor() {
    super();

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
  }

  state = {
    promotionInfo: {},
    formData: {
      name: "",
      promo_type: "",
      company_id: "",
      selectedDistributors: [],
      selectedChannels: [],
      selectedOutlets: [],
      selectedSkus: [],
      promotion_start_date: new Date(),
      promotion_end_date: new Date(),
      discount_type: "",
      discount_amount: null,
      selectedFreeSkus: "",
      promo_text: "",
      promo_weight: "",
      threshold: "",
      result: "",
    },
    isFormValidated: false,
    isLoadingData: true,
  };

  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    this.setState(
      () => ({ formData: formData }),
      () => {}
    );
  };
  handleRuleChange = (rules) => {
    const formData = { ...this.state.formData };
    if (rules) {
      formData["selectedRules"] = rules.map((el) => {
        return String(el.value);
      });
    }

    this.setState({ formData }, () => {});
  };
  handleFreeSkuChange = (option) => {
    const formData = { ...this.state.formData };
    if (option) {
      formData["selectedFreeSkus"] = option.value;
    }

    this.setState({ formData }, () => {});
  };

  handleAddItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        const formData = { ...this.state.formData };
        const body = {
          name: formData.name,
          promo_type: formData.promo_type,
          company_id: formData.company_id,
          promo_group: {
            skus:
              formData.selectedSkus?.length > 0 ? formData.selectedSkus : [],
            channels:
              formData.selectedChannels?.length > 0
                ? formData.selectedChannels
                : [],
            outlets:
              formData.selectedOutlets?.length > 0
                ? formData.selectedOutlets
                : [],
            distributors:
              formData.selectedDistributors?.length > 0
                ? formData.selectedDistributors
                : [],
          },
          start: formData.promotion_start_date,
          end: formData.promotion_end_date,
          promo_slab: formData.result,
          promo_text: formData.promo_text ? formData.promo_text : "",
        };
        promotionAdd("promotions/", body)
          .then((res) => {
            this.props.history.push(`/promotion/multicat/list`);
            toast.success("Promotion has been created successfully.");
          })
          .catch((errMsg) => {
            toast.error(errMsg);
            this.setState({ isSubmitting: false });
          });
      });
    }
  };

  setDate = (date, attributeName) => {
    // date = new Date(date).toLocaleDateString();
    date = new Date(date).toISOString().split("T")[0];
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [attributeName]: date,
      },
    }));
  };
  handleSkuList = (skuList) => {
    const formData = { ...this.state.formData };
    if (skuList) {
      if (
        formData.rule_type === "sku_weight" ||
        formData.rule_type === "pack"
      ) {
        let data = {
          sku: skuList.map((el) => {
            return el.selectedOptions.value;
          }),
          weight: formData.promo_weight ? formData.promo_weight : "",
          threshold: formData.threshold ? formData.threshold : "",
        };
        formData["selectedRules"] = data;
      } else {
        formData["selectedRules"] = skuList.map((el) => {
          const unitType =
            formData.rule_type === "sku_weight" ? "weight" : "quantity";
          if (formData.rule_type === "sku_weight") {
            return {
              sku: el.selectedOptions.value,
            };
          } else {
            return {
              sku: el.selectedOptions.value,
              [unitType]: parseInt(el.textValue),
            };
          }
        });
      }
    }
    this.setState(
      () => ({ formData }),
      () => {}
    );
  };
  handleSelectedChange = (options, name) => {
    const formData = { ...this.state.formData };
    if (options) {
      formData[name] = options.map((el) => {
        return String(el.value);
      });
    }
    this.setState(
      () => ({ formData }),
      () => {}
    );
  };
  handleSlabList = (slabList) => {
    const formData = { ...this.state.formData };
    if (slabList) {
      if (formData.promo_type === "sku_weight") {
        let data = slabList.map((el) => {
          return {
            rule_weight: el.ruleWeight,
            min_weight: el.minWeight,
            max_weight: el.maxWeight,
            discount_type: el.discountType,
            discount_rate: el.discountRate,
          };
        });
        formData["result"] = data;
      }
    }
    this.setState(
      () => ({ formData }),
      () => {}
    );
  };
  handleGenericList = () => {
    const formData = { ...this.state.formData };
    let data = {
      rule_weight: "0",
      min_weight: "0",
      max_weight: "0",
      discount_type: formData.discount_type,
      discount_rate: formData.discount_amount,
    };
    formData["result"] = [data];
    this.setState(
      () => ({ formData }),
      () => {}
    );
  };

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Promotion</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/promotion/multicat/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Promotion List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <PromotionForm
                isEdit={false}
                handleClick={this.handleAddItem}
                handleInputOnChange={this.handleInputOnChange}
                handleSkuList={this.handleSkuList}
                handleSlabList={this.handleSlabList}
                promotionInfo={this.state.promotionInfo}
                formData={this.state.formData}
                isSubmitting={this.state.isSubmitting}
                setDate={this.setDate}
                setSelectedRule={this.handleRuleChange}
                handleSelectedChange={this.handleSelectedChange}
                handleGenericList={this.handleGenericList}
              />
            </>
          </div>
        </div>
        <ToastContainer />
      </App>
    );
  }
}

export default withRouter(Add);
