import React, { Component } from "react";
import App from "../../App";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FiPlus, FaFileExport } from "react-icons/all";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../helper/env";
import { DatePicker } from "antd";
import moment from "moment";

class OutLet_CSV_export extends Component {
  constructor() {
    super();
    this.state = {
      exportData: null,
      selectedFile: null,
      uploading: false,
      selectedFileName: "",
      selectedDate: moment().add(1, 'day').format("YYYY-MM-DD"),
    };
  }

  dateOnChange = (date) => {
    if (date) {
      const dt = new Date(date);
      this.setState({ selectedDate: dt });
    } else {
      this.setState({ selectedDate: null });
    }
  };

  exportCSV = () => {
    if (this.state.selectedDate) {
      axios
        .get(
          `${API_BASE_URL}/partner_sorts/export?selected_date=` +
            this.state.selectedDate
        )
        .then((response) => {
          toast.success("Successfully Fetched");
          this.setState({ exportData: response.data });

          // Trigger the CSV download
          this.downloadCSV(response.data);
        })
        .catch((error) => {
          toast.error("Error exporting CSV");
          this.setState({
            selectedFile: null,
            selectedFileName: "",
          });
        });
    } else {
      toast.warn("Please select date");
      return;
    }
  };

  downloadCSV = (data) => {
    const csvContent = "data:text/csv;charset=utf-8," + this.convertToCSV(data);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `sorted_partners_${this.state.selectedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  convertToCSV = (data) => {
    const rows = [];

    if (Array.isArray(data) && data.length > 0) {
      const headers = Object.keys(data[0]);
      rows.push(headers.join(","));

      data.forEach((item) => {
        const values = headers.map((header) => item[header]);
        rows.push(values.join(","));
      });
    }

    return rows.join("\n");
  };

  handleFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({
      selectedFile: file,
      selectedFileName: file ? file.name : "",
    });
  };

  handleUpload = () => {
    if (this.state.selectedFile && !this.state.uploading) {
      this.setState({ uploading: true });
      this.uploadCSV();
    } else {
      toast.error("Please select a CSV file to upload.");
    }
  };

  uploadCSV = () => {
    const formData = new FormData();
    formData.append("sorted_partners", this.state.selectedFile);

    axios
      .post(`${API_BASE_URL}/partner_sorts/import`, formData)
      .then((response) => {
        console.log("Upload successful", response.data);
        toast.success("Upload successful");
        this.setState({
          selectedFile: null,
          selectedFileName: "",
          uploading: false,
        });
      })
      .catch((error) => {
        console.error("Error uploading CSV", error);
        toast.error("Error uploading CSV");
        this.setState({
          selectedFile: null,
          selectedFileName: "",
          uploading: false,
        });
      });
  };

  render() {
    return (
      <App>
        <h5 className="page-title">DM Route Planning CSV Import-Export</h5>
        <div className="mt-5">
          <div className="d-flex justify-content-around">
            <div>
              <div className="main-content">
                <h6 className="page-title">DM Route Planning CSV Export</h6>
                <div className="ml-auto d-flex justify-content-center">
                  <div>
                    <label htmlFor="searchInput" className="sr-only">
                      Selected Date
                    </label>
                    <DatePicker
                      selected={this.state.selectedDate}
                      dateFormat="yyyy-mm-dd"
                      // defaultValue={this.state.selectedDate}
                      placeholder={this.state.selectedDate}
                      onChange={this.dateOnChange}
                    />
                  </div>
                  <Button
                    disabled={!this.state.selectedDate}
                    onClick={this.exportCSV}
                    className="btn btn-sm btn-secondary ml-2"
                  >
                    <FaFileExport /> Export CSV
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="main-content">
                <h6 className="page-title">DM Route Planning CSV Import</h6>
                <div className="ml-auto d-flex justify-content-center">
                  <label
                    htmlFor="fileInput"
                    className="btn btn-sm btn-secondary ml-2"
                  >
                    <FiPlus /> Select CSV File
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={this.handleFileChange}
                    />
                  </label>
                </div>
                <br />
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                {this.state.selectedFileName && (
                  <div className="ml-2">
                    <b>Selected File: </b>
                    {this.state.selectedFileName}
                  </div>
                )}
              </div>
              <br />
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  onClick={this.handleUpload}
                  className="btn btn-sm btn-secondary ml-2"
                  disabled={this.state.uploading || !this.state.selectedFile}
                  style={{
                    opacity:
                      this.state.uploading || !this.state.selectedFile
                        ? 0.5
                        : 1,
                  }}
                >
                  {this.state.uploading ? "Uploading..." : "Upload"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(OutLet_CSV_export);
