import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import PromotionForm from "./Form";
import {
  getPromotionDetails,
  getList,
  updatePromotion,
} from "../../services/baseServices";

class Add extends Component {
  state = {
    formData: {
      name: "",
      promo_type: "",
      selectedDistributors: [],
      selectedChannels: [],
      selectedOutlets: [],
      selectedSkus: [],
      promotion_start_date: null,
      promotion_end_date: null,
      discount_type: "",
      discount_amount: null,
      selectedFreeSkus: "",
      promo_text: "",
      promo_weight: "",
      threshold: "",
      isActive: false,
      result: "",
    },
    promotionInfo: {
      name: "",
      promo_type: "",
      selectedDistributors: [],
      selectedChannels: [],
      selectedOutlets: [],
      selectedSkus: [],
      promotion_start_date: null,
      promotion_end_date: null,
      discount_type: "",
      discount_amount: null,
      selectedFreeSkus: "",
      promo_text: "",
      promo_weight: "",
      threshold: "",
      isActive: false,
      result: "",
    },
    areas: [],
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false,
  };

  componentDidMount() {
    getPromotionDetails("promotions", this.props.match.params.id)
      .then((res) => {
        this.setState({
          formData: {
            name: res.data?.name,
            promo_text: res.data?.promo_text,
            promo_type: res.data?.promo_type,
            is_active: res.data?.is_active,
            promotion_start_date: new Date(res.data?.start),
            promotion_end_date: new Date(res.data?.end),
            selected_skus: res.data?.promo_group.skus,
            selectedChannels: res.data?.promo_group.channels,
            selectedOutlets: res.data?.promo_group.outlets,
            selecteDistributors: res.data?.promo_group.distributors,
            isActive: res.data?.is_active,
            result: res.data?.promo_slab,
            discount_type:
              res.data?.promo_type === "generic"
                ? res.data?.promo_slab[0]["discount_type"]
                : "",
            discount_amount:
              res.data?.promo_type === "generic"
                ? res.data?.promo_slab[0]["discount_rate"]
                : "",
          },
        });
        this.setState({ promotionInfo: this.state.formData });
      })
      .catch((errMsg) => {
        toast.error("Server Error");
      });
  }

  setDate = (date, attributeName) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [attributeName]: date,
      },
    });
  };

  handleInputOnChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleIsActiveChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.checked,
      },
    });
  };

  handleUpdateItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        const formData = { ...this.state.formData };
        const body = {
          id: this.props.match.params.id,
          name: formData.name,
          promo_text: formData.promo_text,
          is_active: formData.isActive,
          end: formData.promotion_end_date,
        };
        updatePromotion("promotions/update", this.props.match.params.id, body)
          .then((res) => {
            this.props.history.push(`/promotion/multicat/list`);
            toast.success(res?.message);
          })
          .catch((errMsg) => {
            toast.error("Update Failed");
            this.setState({ isSubmitting: false });
          });
      });
    }
  };
  render() {
    const { formData } = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Update Promotion</h2>
        </div>
        <div className="card-block">
          <div className="block-body">
            <>
              <PromotionForm
                handleClick={this.handleUpdateItem}
                handleInputOnChange={this.handleInputOnChange}
                isSubmitting={this.state.isSubmitting}
                setDate={this.setDate}
                formData={this.state.formData}
                promotionInfo={this.state.promotionInfo}
                isEdit={true}
                handleIsActiveChange={this.handleIsActiveChange}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);
