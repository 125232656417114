import React, { Component } from "react";
import App from "../../App";
import { withRouter } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import {
  getWarehouseType,
  getFormattedDate,
  getBusinessType,
} from "../../helper/utils";
import {
  getList,
  getPaginatedList,
  getDetails,
} from "../../services/baseServices";
import Pagination from "react-js-pagination";
import Loader from "react-loader";
import ExportCSVAsync from "../common/ExportCSVAsync";
import BlockModal from "./BlockModal";

const warehouseType = getWarehouseType();
const businessType = getBusinessType();

const headersForDH = [
  { label: "Id", key: "id" },
  { label: "Product Title", key: "product_title" },
  { label: "SKU", key: "sku" },
  { label: "Available Qty", key: "available_quantity" },
  { label: "Booked Qty", key: "booked_quantity" },
  { label: "Blocked Qty", key: "blocked_quantity" },
  { label: "QC Failed", key: "qc" },
  { label: "Ready to Ship From FC", key: "ready_to_ship_from_fc" },
  { label: "In Transit", key: "in_transit" },
  { label: "In Partner", key: "in_partner" },
  { label: "Total Count", key: "total_count" },
  { label: "VALUE (DISTRIBUTOR'S MARGIN)", key: "distribution_margin" },
  { label: "Total MRP", key: "total_mrp" },
];
const dataForDH = (item) => {
  return {
    id: item.id,
    product_title: item.product_title,
    sku: item.sku,
    available_quantity: item.available_quantity,
    booked_quantity: item.booked_quantity,
    blocked_quantity: item.blocked_quantity,
    qc: item.qc_quantity,
    ready_to_ship_from_fc: item.ready_to_ship_from_fc_quantity,
    in_transit: item.in_transit_quantity,
    in_partner: item.in_partner_quantity,
    total_count: item.total_count,
    distribution_margin: item.distribution_margin,
    total_mrp: item.mrp,
  };
};
const headersForCW = [
  { label: "Id", key: "id" },
  { label: "Product Title", key: "product_title" },
  { label: "SKU", key: "sku" },
  { label: "Available Qty", key: "available_quantity" },
  // { label: "Booked Qty", key: "booked_quantity" },
  { label: "Blocked Qty", key: "blocked_quantity" },
  { label: "QC Failed", key: "qc" },
  { label: "Ready to Ship From FC", key: "ready_to_ship_from_fc" },
  { label: "In Transit", key: "in_transit" },
  { label: "In Partner", key: "in_partner" },
  { label: "Total Count", key: "total_count" },
  { label: "Total MRP", key: "effective_mrp" },
  { label: "Trade Price", key: "price_agami_trade" },
  { label: "VALUE (DISTRIBUTOR'S MARGIN)", key: "distribution_margin" },
];
const dataForCW = (item) => {
  return {
    id: item.id,
    product_title: item.product_title,
    sku: item.sku,
    available_quantity: item.available_quantity,
    // booked_quantity: item.booked_quantity,
    blocked_quantity: item.blocked_quantity,
    qc: item.qc_quantity,
    ready_to_ship_from_fc: item.ready_to_ship_from_fc_quantity,
    in_transit: item.in_transit_quantity,
    in_partner: item.in_partner_quantity,
    total_count: item.total_count,
    effective_mrp: item.mrp,
    price_agami_trade: item.trade_price,
    distribution_margin: item.distribution_margin,
  };
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventories: [],
      isLoading: true,
      isExporting: false,
      activePage: 1,
      per_page: 50,
      page: 1,
      temp_params: {
        category: "",
        warehouse_id: "",
        company: "",
        sub_category: "",
        sku: "",
      },
      query_params: {
        category: "",
        warehouse_id: "",
        company: "",
        sub_category: "",
        sku: "",
      },
      companies: [],
      categories: [],
      sub_categories: [],
      dh_list: [],
      showBlockModal: false,
      blokModalDetails: {},
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  updatedTempParam = () => {
    const params = { ...this.state.temp_params };
    delete params.sub_category;
    return params;
  };

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = { ...this.state.temp_params };
    tempParam["page"] = 1;
    this.setState(
      { query_params: tempParam, isLoading: true, activePage: 1, page: 1 },
      () => {
        this.getPaginatedInventories(this.state.temp_params);
      }
    );
  };

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {});
  };

  getPaginatedInventories = (params) => {
    this.setState({ isLoading: true });
    // let currentUrlParams = new URLSearchParams(window.location.search);
    // currentUrlParams.set('page', params.page);
    // currentUrlParams.set('total', params.per_page);
    // this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
    getPaginatedList("inventories/products", params)
      .then((res) => {
        this.setState(
          {
            inventories: res.data,
            isLoading: false,
            per_page: res.headers["x-per-page"],
            total_data: res.headers["x-total"],
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        this.setState({ isLoading: false });
        console.log("Error Message: ", errMsg);
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedInventories(this.getQueryParams());
    });
  };

  componentDidMount() {
    this.getPaginatedInventories(this.getQueryParams());
    getList("categories")
      .then((res) => {
        this.setState({ categories: res });
      })
      .catch((errMsg) => {});
    getList("inventories/warehouses")
      .then((res) => {
        this.setState({ dh_list: res.warehouses });
      })
      .catch((errMsg) => {});
  }

  getSubCategories = (e) => {
    if (!!e.target.value) {
      const queryData = { ...this.state.temp_params };
      queryData["sub_category"] = "";
      let selected_cat = this.state.categories.find(
        (item) => item.id == e.target.value
      );
      if (!!selected_cat && selected_cat.sub_categories.length > 0) {
        this.setState({
          sub_categories: selected_cat.sub_categories,
          temp_params: queryData,
        });
      } else {
        this.setState(
          { sub_categories: [], temp_params: this.updatedTempParam() },
          () => {}
        );
      }
    } else {
      this.setState(
        { sub_categories: [], temp_params: this.updatedTempParam() },
        () => {}
      );
    }
  };

  reset = () => {
    this.setState(
      {
        temp_params: { warehouse_id: "" },
        query_params: { warehouse_id: "" },
        page: 1,
        activePage: 1,
      },
      () => {
        this.getPaginatedInventories({
          per_page: this.state.per_page,
          page: 1,
        });
      }
    );
  };

  handleBlockModal = (refresh) => {
    this.setState({ showBlockModal: !this.state.showBlockModal });
    if (refresh) this.getPaginatedInventories(this.getQueryParams());
  };

  showAndHideBlockModal = (id) => {
    console.log(id);
    getDetails("blocked_items/item_info", id)
      .then((res) => {
        if (res.status_code === 404) {
          toast.error(res.message);
        } else {
          this.setState({ blokModalDetails: res });
          this.handleBlockModal();
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Inventory</h2>
            <div className="ml-auto">
              <ExportCSVAsync
                title="Inventory"
                fileName={
                  "Inventories-" +
                  getFormattedDate(new Date(), "DD-MM-YYYY") +
                  ".csv"
                }
                Url="inventories/export"
                headers={
                  warehouseType !== "distribution" &&
                  this.state.query_params.warehouse_id === ""
                    ? headersForCW
                    : headersForDH
                }
                params={this.state.query_params}
                loadData={
                  warehouseType !== "distribution" &&
                  this.state.query_params.warehouse_id === ""
                    ? dataForCW
                    : dataForDH
                }
              />
            </div>
          </div>
          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body">
                <form className="form-inline mb-3">
                  {warehouseType === "central" && (
                    <>
                      <div className="form-group mr-3">
                        <label htmlFor="warehouse_id" className="sr-only">
                          Warehouse
                        </label>
                        <select
                          id="warehouse_id"
                          name="warehouse_id"
                          value={this.state.temp_params.warehouse_id}
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            this.handleOnFieldChange(e);
                          }}
                        >
                          <option value="">--Select Warehouse--</option>
                          {this.state.dh_list.length > 0
                            ? this.state.dh_list.map((item, i) => {
                                return (
                                  <option key={i} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </>
                  )}
                  <div className="form-group mr-3">
                    <label htmlFor="sku" className="sr-only">
                      SKU
                    </label>
                    <input
                      type="text"
                      name="sku"
                      value={this.state.temp_params.sku}
                      id="sku"
                      onChange={this.handleOnFieldChange}
                      className="form-control form-control-sm"
                      placeholder="Search by SKU"
                    />
                  </div>
                  <div className="form-group mr-3">
                    <label htmlFor="product_title" className="sr-only">
                      Product Title
                    </label>
                    <input
                      type="text"
                      name="product_title"
                      value={this.state.temp_params.product_title}
                      id="product_title"
                      onChange={this.handleOnFieldChange}
                      className="form-control form-control-sm"
                      placeholder="Search by Title"
                    />
                  </div>
                  <div className="form-group mr-3">
                    <label htmlFor="company" className="sr-only">
                      Company
                    </label>
                    <input
                      type="text"
                      name="company"
                      value={this.state.temp_params.company}
                      id="company"
                      onChange={this.handleOnFieldChange}
                      className="form-control form-control-sm"
                      placeholder="Search by Company"
                    />
                  </div>
                  <div className="form-group mr-3">
                    <label htmlFor="category" className="sr-only">
                      Category
                    </label>
                    <select
                      name="category"
                      id="category"
                      value={this.state.temp_params.category}
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.getSubCategories(e);
                        this.handleOnFieldChange(e);
                      }}
                    >
                      <option value="">--Select Category--</option>
                      {this.state.categories.length > 0
                        ? this.state.categories.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.title}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                  <div
                    className={`form-group mr-3 ${
                      this.state.temp_params.category != "" ? "" : "d-none"
                    }`}
                  >
                    <label htmlFor="sub_category" className="sr-only">
                      Sub-Category
                    </label>
                    <select
                      id="sub_category"
                      name="sub_category"
                      value={this.state.temp_params.sub_category}
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.handleOnFieldChange(e);
                      }}
                    >
                      <option value="">--Select Sub-Category--</option>
                      {this.state.sub_categories.length > 0
                        ? this.state.sub_categories.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.title}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                  <Button
                    className="mr-2"
                    onClick={this.reset}
                    size="sm"
                    variant="secondary"
                  >
                    Reset
                  </Button>
                  <Button
                    type={`submit`}
                    onClick={this.handleOnSearch}
                    size="sm"
                    variant="primary"
                  >
                    Search
                  </Button>
                </form>

                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      {/* <th style={{ width: "20px" }}>#</th> */}
                      <th>Product Title</th>
                      <th>SKU</th>
                      {warehouseType !== "central" ||
                      this.state.query_params.warehouse_id !== "" ? (
                        <th colSpan={3} className="text-center">
                          FC (fulfilment center)
                          <th>Available Qty</th>
                          <th>Booked Qty</th>
                          <th>Blocked Qty</th>
                        </th>
                      ) : (
                        <th colSpan={2} className="text-center">
                          CW
                          <th>Available Qty</th>
                          <th>Blocked Qty</th>
                        </th>
                      )}
                      <th>QC Failed</th>
                      <th>Ready to Ship From FC</th>
                      <th>In Transit</th>
                      {businessType !== "multicat" ? (
                        <>
                          <th>In Partner</th>
                        </>
                      ) : (
                        ""
                      )}

                      <th>Total count</th>
                      {warehouseType !== "distribution" &&
                        this.state.query_params.warehouse_id === "" && (
                          <>
                            <th>Total MRP</th>
                            <th>Trade Price</th>
                          </>
                        )}
                      {businessType !== "multicat" ? (
                        <>
                          <th>VALUE (DISTRIBUTOR'S MARGIN)</th>
                        </>
                      ) : (
                        ""
                      )}

                      {(warehouseType === "distribution" ||
                        this.state.query_params.warehouse_id !== "") && (
                        <>
                          <th>Total MRP</th>
                        </>
                      )}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.inventories.length > 0 ? (
                      this.state.inventories.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>
                              {(this.state.page - 1) * this.state.per_page +
                                index +
                                1}
                            </td> */}
                            <td>{item.product_title}</td>
                            <td>{item.sku}</td>
                            <td>
                              {item.available_quantity
                                ? item.available_quantity
                                : 0}
                            </td>
                            {(warehouseType !== "central" ||
                              this.state.query_params.warehouse_id !== "") && (
                              <td>
                                {item.booked_quantity
                                  ? item.booked_quantity
                                  : 0}
                              </td>
                            )}

                            <td>
                              {item.blocked_quantity
                                ? item.blocked_quantity
                                : 0}
                            </td>
                            <td>{item.qc_quantity}</td>
                            <td>{item.ready_to_ship_from_fc_quantity}</td>
                            <td>{item.in_transit_quantity}</td>
                            {businessType !== "multicat" ? (
                              <>
                                <td>{item.in_partner_quantity}</td>
                              </>
                            ) : (
                              ""
                            )}

                            <td>{item.total_count}</td>
                            {warehouseType !== "distribution" &&
                              this.state.query_params.warehouse_id === "" && (
                                <>
                                  <td>{item.mrp}</td>
                                  <td>{item.trade_price}</td>
                                </>
                              )}
                            {businessType !== "multicat" ? (
                              <>
                                <td>{item.distribution_margin}</td>
                              </>
                            ) : (
                              ""
                            )}

                            {(warehouseType === "distribution" ||
                              this.state.query_params.warehouse_id !== "") && (
                              <>
                                <td>{item.mrp}</td>
                              </>
                            )}

                            <td>
                              {
                                <Button
                                  disabled={item.available_quantity <= 0}
                                  variant={
                                    item.available_quantity <= 0
                                      ? "default"
                                      : "outline-danger"
                                  }
                                  size="sm"
                                  onClick={(e) =>
                                    item.available_quantity <= 0
                                      ? e.preventDefault()
                                      : this.showAndHideBlockModal(item.id)
                                  }
                                >
                                  Block
                                </Button>
                              }
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={
                            warehouseType !== "distribution" &&
                            this.state.query_params.warehouse_id !== ""
                              ? 12
                              : 10
                          }
                        >
                          No Products Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.total_data > 0 ? (
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </Loader>
        </div>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.BOTTOM_RIGHT}
        />
        <BlockModal
          show={this.state.showBlockModal}
          handleClose={this.handleBlockModal}
          blokModalDetails={this.state.blokModalDetails}
        />
      </App>
    );
  }
}

export default withRouter(List);
