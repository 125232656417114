import { Link} from "react-router-dom";
import MetaForm from "./MetaForm";
import App from "../../App";
import {AiOutlineArrowLeft} from 'react-icons/all'

const Add = () => {
  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Add Meta Info</h2>
        <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/meta-info/list`}
              className="btn btn-sm btn-link mr-2"
            >
              <AiOutlineArrowLeft /> Back to List
            </Link>
          </div>
      </div>
      <MetaForm />
    </App>
  );
};

export default Add;
