import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { DatePicker, Radio, Input } from "antd";
import {   humanize } from "../../helper/utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { putData } from "../../services/baseServices";

const CouponEditForm = ({ validationSchema, couponDetails }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (couponDetails) {
      reset({
        title: couponDetails?.title,
        status: couponDetails?.status === "active" ? 1 : 0,
        startDate: moment(couponDetails?.start_date),
        endDate: moment(couponDetails?.end_date),
      });
    }
  }, [couponDetails]);

  const onSubmit = async (data) => {
    const promo_coupon = {
      title: data.title,
      status: +data.status,
      start_date: data.startDate,
      end_date: data.endDate,
    };

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log(promo_coupon);
            putData(`promo_coupons/${couponDetails?.id}`, { promo_coupon })
              .then((res) => {
                if (res?.success) {
                  toast.success(res?.message);
                  history.push("/coupon/list");
                } else {
                  toast.error(res?.message);
                }
              })
              .catch(() => {
                toast.error("Error");
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Form.Group as={Row} controlId={"CouponTitle"}>
          <Form.Label column sm="3">
            Title <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Ex: Buy 1 get 1 free"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            <p className="text-danger ">
              <small> {errors.title?.message}</small>
            </p>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="startDate">
          <Form.Label column sm="3">
            Start Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Controller
              control={control}
              name="startDate"
              render={({ field, value }) => (
                <DatePicker
                  inputReadOnly
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Select start date"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  format="DD/MM/yyyy"
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              )}
            />
            <p className="text-danger ">
              <small>{errors.startDate?.message}</small>
            </p>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="endDate">
          <Form.Label column sm="3">
            End Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <DatePicker
                  inputReadOnly
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Select end date"
                  value={field.value}
                  format="DD/MM/yyyy"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              )}
            />
            <p className="text-danger ">
              <small>{errors.endDate?.message}</small>
            </p>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="status">
          <Form.Label column sm="3">
            Status <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              )}
            />
            <p className="text-danger ">
              <small>{errors.status?.message}</small>
            </p>
          </Col>
        </Form.Group>

        <table className="info-table">
          <tbody>
            <tr>
              <td style={{ width: "250px" }}>Mini Cart Value</td>
              <td style={{ width: "20px", textAlign: "center" }}>:</td>
              <td className={`text-bold`}>
                {couponDetails?.minimum_cart_value}
              </td>
            </tr>
            <tr>
              <td style={{ width: "250px" }}>Order Type</td>
              <td style={{ width: "20px", textAlign: "center" }}>:</td>
              <td className={`text-bold`}>{humanize(couponDetails?.order_type)}</td>
            </tr>
            <tr>
              <td style={{ width: "250px" }}>Discount Type</td>
              <td style={{ width: "20px", textAlign: "center" }}>:</td>
              <td className={`text-bold`}>{humanize(couponDetails?.discount_type)}</td>
            </tr>
            <tr>
              <td style={{ width: "250px" }}>Discount{couponDetails?.discount_type === "percentage" && "(%)"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>:</td>
              <td className={`text-bold`}>{couponDetails?.discount}</td>
            </tr>
            {couponDetails?.discount_type !== "fixed" && (
              <tr>
                <td style={{ width: "250px" }}>Max Discount Amount</td>
                <td style={{ width: "20px", textAlign: "center" }}>:</td>
                <td className={`text-bold`}>
                  {couponDetails?.max_discount_amount}
                </td>
              </tr>
            )}
            {couponDetails?.promo_coupon_rules?.map((item, i) => {
              return (
                <tr key={i}>
                  <td style={{ width: "250px" }}>{item?.ruleable_type}</td>
                  <td style={{ width: "20px", textAlign: "center" }}>:</td>
                  <td className={`text-bold`}>
                    {item?.ruleable_values?.join(", ")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="mt-3 text-center">
          <Link
            to={`${process.env.PUBLIC_URL}/coupon/list`}
            className="mr-2 btn btn-md btn-danger"
          >
            Cancel
          </Link>
          <Button
            size="md"
            type="submit"
            disabled={isSubmitting}
            variant="primary"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CouponEditForm;
