
import React, { useState, useEffect } from 'react';
import App from "../../../App";
import { Link, useHistory, withRouter } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import {postData, getData} from '../../../services/baseServices'
import { toast } from 'react-toastify';
import AreaForm from './Form';

const AreasCreate = () => {
    const [area, setArea] = useState({
        name: '',
        bn_name: '',
        home_delivery: true,
    })
    const [thana, setThana] = useState([]);
    const history = useHistory()
    const onFormSubmit = (data) => {
        const params = {
            area: {
                name: data.name,
                bn_name: data.bn_name,
                thana_id: data.thana_id,
                home_delivery: data.home_delivery
            }
        }
          postData("areas", params)
          .then(res => {
            if(res.success) {
              history.push(`/config/areas/list`);
              toast.success(res.message);
            }
            else {
              toast.error(res.message);
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
          }); 
    }
    useEffect(() => {
          getData('thanas')
          .then(res => {
            setThana(res.data)
          })
          .catch(errMsg => {
          })
    }, [])
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Create Area</h2>
                <div className="ml-auto">
                    <Link to={`${process.env.PUBLIC_URL}/config/areas/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
                </div>
            </div>

            <div className="card-block">
                <div className="block-body">
                    <>
                    <AreaForm
                        area={area}
                        thana = {thana}
                        onFormSubmit = {onFormSubmit}
                    />
                    </>
                </div>
            </div>
        </App>
    );
}
export default withRouter(AreasCreate);