import React, { Component } from "react";
import { getDate, humanize } from "../../helper/utils";
import { getWarehouseType } from "../../helper/utils";

const warehouseType = getWarehouseType();
class PrintInvoice extends Component {
  render() {
    const { purchaseOrder } = this.props;

    return (
      <div className="invoice-container">
        <table className="invoice-table">
          <tbody>
            <tr className="v-top">
              <td>
                <h3 className="invoice-title">
                  Order # 0000{purchaseOrder?.id}
                </h3>
                <span className="d-block">
                  <strong>Creation Date</strong> :
                  {this.props.purchaseOrder &&
                    getDate(purchaseOrder.order_date).format("Do MMMM YYYY")}
                </span>
                <span className="d-block">
                  <strong>Total Ordered Quantity</strong> :
                  {purchaseOrder.quantity}
                </span>
                <span className="d-block">
                  <strong>QC Status</strong> :
                  {purchaseOrder.qc_status ? "Done" : "Not Done"}
                </span>
                <span className="d-block">
                  <strong>Status</strong> :
                  {purchaseOrder.order_status
                    ? humanize(purchaseOrder.order_status)
                    : "Unknown"}
                </span>
                <span className="d-block">
                  <strong>Total Price</strong> :{purchaseOrder.total_price}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="line-tems">
          {purchaseOrder.line_items.length > 0 && (
            <>
              <h5>Inter Godown Transfer Order Items</h5>
              <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th className="align-top-left">Product</th>
                        <th className="align-top-left">Unit Price</th>
                        <th className="align-top-left">Total Price</th>
                        <th className="align-top-left">Ordered Quantity</th>
                        <th className="align-top-left">
                            {warehouseType === "central" ? "Sent Quantity" : "Packed Quantity"}
                        </th>
                        <th className="align-top-left">Received</th>
                        <th className="align-top-left">Passed</th>
                        <th className="align-top-left">Quality Failed</th>
                        <th className="align-top-left">Quantity Failed</th>
                    </tr>
                </thead>

                <tbody>
                  {purchaseOrder?.line_items.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.product_title}</td>
                      <td>{item.price}</td>
                      <td>{item.total_price}</td>
                      <td>{item.quantity}</td>
                      <td>{item.sent_quantity}</td>
                      <td>{item.received_quantity}</td>
                      <td>{item.qc_passed}</td>
                      <td>{item.quality_failed}</td>
                      <td>{item.quantity_failed}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div className="box-list">
          {purchaseOrder?.boxes?.length > 0 &&
            purchaseOrder?.boxes?.map((box) => (
              <>
                <h5>Box ID#{box.id} </h5>
                <table key={box.id} className="table table-bordered">
                  <th>#</th>
                  <th>Product</th>
                  <th>Unit Price</th>
                  <th>Total Price</th>
                  <th>Ordered Quantity</th>
                  <th>
                    {warehouseType === "central"
                      ? "Sent Quantity"
                      : "Packed Quantity"}
                  </th>
                  <th>Received</th>
                  <th>Passed</th>
                  <th>Quality Failed</th>
                  <th>Quantity Failed</th>
                  <tbody>
                    {box?.line_items.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.product_title}</td>
                        <td>{item.price}</td>
                        <td>{item.total_price}</td>
                        <td>{item.quantity}</td>
                        <td>{item.sent_quantity}</td>
                        <td>{item.received_quantity}</td>
                        <td>{item.qc_passed}</td>
                        <td>{item.quality_failed}</td>
                        <td>{item.quantity_failed}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ))}
        </div>
      </div>
    );
  }
}

export default PrintInvoice;
