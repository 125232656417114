import React, { Component } from "react";
import App from "../../App";
import { FaLongArrowAltLeft} from "react-icons/all";
import "../../assets/scss/invoice.scss";
import { withRouter, Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { getDetails, getList, putData } from "../../services/baseServices";
import "react-datepicker/dist/react-datepicker.css";
import {  getFormattedDate, twoDecimalPlace } from "../../helper/utils";
import { toast } from "react-toastify";

class ReturnPackedItemDetails extends Component {
	state = {	
		returnDetails: {},
		returnLocations: {},
		isLoading: true,
		isSubmitting: false,
		locations: [],
		backUrl: ''
	};

	componentDidMount() {
		getDetails('return_orders/unpack', this.props.match.params.id)
		.then(res =>{
			let backUrl = ''
			if(res.receiver_type==='Route') {
				backUrl = `/route/collect/${res.route_information.id}`
			} else {
				backUrl = `/rider/collect/${res.rider_information.id}`
			}
			this.setState({
				returnDetails:res,
				isLoading: false,
				backUrl
			})
		}).catch((errMsg) => {
			console.log("Err Msg: ", errMsg);
		});

		getList("locations")
		.then((res) => {
			this.setState({ locations: res });
		})
		.catch((errMsg) => {});
	}

	getAttributes = (attrArray) => {
		let attrs = "";
		if (!!attrArray) {
			attrArray.map((attr) => (attrs += attr.name + ": " + attr.value + ", "));
		}
		return attrs.trimEnd();
	};

	setLineItemLocation = (index, e, line_item_id) => {
		const returnLocations = {...this.state.returnLocations};
		returnLocations[index] = {
			location_id: e.target.value,
			line_item_id: line_item_id
		}
		this.setState({returnLocations})

	};

	assignLocation = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.preventDefault();
		  e.stopPropagation();
		  this.setState({isFormValidated: true})
		} else {
		  this.setState({isSubmitting: true}, () => {
			let locations = []
				Object.keys(this.state.returnLocations).map((key, i) => (
					locations.push(this.state.returnLocations[key])
				))
			const params = {
				return_order_id:this.state.returnDetails.id,
				locations: locations
			} 
	
			let addRouteOrRiderId = (obj, propName, propValue) =>{
				obj[propName] = propValue
			}
			if(this.props.whom === 'rider'){
				addRouteOrRiderId(params,'rider_id', this.state.returnDetails.rider_information.id )
			}else{
				addRouteOrRiderId(params,'route_id', this.state.returnDetails.route_information.id )
			}
	
			putData('warehouse_variants_locations/return_assign',params)
				.then((res) => {
					if(res.status_code === 200 || res.status_code === 201) {
						this.props.history.goBack()
						toast.success(res.message);
					  }    
					  else {
						toast.error(res.message);
					  } 
					this.setState({isSubmitting: false})
					})		
				.catch((errMsg) => {
					toast.error(errMsg);
					this.setState({isSubmitting: false})
				});
		  })
		}
	};
	render() {
		const returnDetails = this.state.returnDetails
		return (
			<App layout="">
				<div className="main-content">
					<div className="page-header">
						<h2 className="page-title">Return Packed Item Details</h2>
						<div className="ml-auto">
							<Link
								to={`/returnOrder/list`}
								className="btn btn-sm btn-link"
							>
								<FaLongArrowAltLeft /> Back to Requset List
							</Link>
						</div>
					</div>
					{this.state.isLoading ? (
						<div className={`card-block`}>
							<div className="block-body">Loading...</div>
						</div>
					) : (
						<div className="card-block">
							<div className="block-body print-outer">
								<Form
									noValidate
									validated={this.state.isFormValidated}
									onSubmit={this.assignLocation}>
									<table className="invoice-table">
										<tbody>
											<tr className="v-top">
												<td>
													<h3 className="invoice-title">
														Request # {returnDetails?.return_order_id}
													</h3>
													<span>
														Order #
														{
															<Link
																to={`/customerOrder/details/${returnDetails?.customer_order_id}`}
															>
																{returnDetails?.customer_order_id}
															</Link>
														}
													</span>
													<span className="d-block">
														Status : {returnDetails?.return_status}
													</span>
													<span className="d-block mb-2">
														Request Date :{" "}
														{getFormattedDate(returnDetails?.date,"DD-MM-YYYY")}
													</span>
												</td>
												<td className="text-right">
													<span className="d-block">
														<h6>Customer Information</h6>
													</span>
													<span className="d-block">
														{returnDetails?.customer_information?.name}
													</span>
													<span className="d-block">
														{returnDetails?.customer_information?.email}
													</span>
													<span className="d-block">
														{returnDetails?.customer_information?.phone}
													</span>
												</td>
											</tr>

											<tr>
												<td colSpan={2}></td>
											</tr>

											<tr>
												<td colSpan={2}>
													<table>
														<tbody>
															<tr>
																<td style={{ paddingBottom: "20px" }}>
																	<h4 className="invoice-title-alt">
																		Partner Info
																	</h4>
																	<span className="d-block">
																	Id:	{returnDetails?.partner_information?.id}
																	</span>
																	<span className="d-block">
																		Name: {returnDetails?.partner_information?.name}
																	</span>
																	<span className="d-block">
																		Phone: {returnDetails?.partner_information?.phone}
																	</span>
																	<span className="d-block">
																		Email: {returnDetails?.partner_information?.email}
																	</span>
																	<span className="d-block">
																		Area: {returnDetails?.partner_information?.area}
																	</span>
																</td>
																
																<td style={{ paddingBottom: "20px" }}>
																	<h4 className="invoice-title-alt">Warehouse</h4>
																	<span className="d-block">
																		Name: {returnDetails?.warehouse_information?.name}
																	</span>
																	<span className="d-block">
																		Phone: {returnDetails?.warehouse_information?.phone}
																	</span>
																</td>
																{returnDetails.receiver_type === 'Rider'?(
																	<td>
																		<h4 className="invoice-title-alt">Rider</h4>
																		<span className="d-block">
																		Name: {returnDetails.rider_information?.name}
																		</span>
																		<span className="d-block">
																		Phone: {returnDetails.rider_information?.phone}
																		</span>
																	</td>
																): (
																	<td>
																		<h4 className="invoice-title-alt">Route</h4>
																		<span className="d-block">
																		Name: {returnDetails.route_information?.name}
																		</span>
																		<span className="d-block">
																		Phone: {returnDetails.route_information ?.phone}
																		</span>
																	</td>
																)}
															</tr>
														</tbody>
													</table>		
													<>
														<h3>Reasons</h3>
														<table className="info-table">
															<tbody>
																<tr>
																	<td style={{ width: "250px" }}>Type</td>
																	<td
																		style={{ width: "20px", textAlign: "center" }}
																	>
																		:
																	</td>
																	<td>{returnDetails?.reason}</td>
																</tr>
																<tr>
																	<td style={{ width: "250px" }}>Description</td>
																	<td
																		style={{ width: "20px", textAlign: "center" }}
																	>
																		:
																	</td>
																	<td>{returnDetails?.description}</td>
																</tr>
															</tbody>
														</table>

														<hr></hr>
													</>
													<table className="table table-bordered">
														<thead>
															<tr>
																<th className="text-center">Location</th>
																<th>Item</th>
																<th className={"text-right"}>Unit Price</th>
																<th className={"text-center"}>Quantity</th>
																<th className={"text-right"}>Discount</th>
																<th className="text-right">Amount (৳)</th>
															</tr>
														</thead>

														<tbody>
															{returnDetails?.line_items?.length > 0
																? returnDetails?.line_items?.map((item, index) => (
																		<React.Fragment key={index}>
																			<tr key={index + 1}>
																				<td>
																				<div className="d-flex">
																					<select
																						id="location"
																						name="location_id"
																						required
																						className="form-control form-control-sm"
																						onChange={(e) => {this.setLineItemLocation(index,e, item.shopoth_line_item_id)}}
																					>
																						<option value="">--Locations--</option>
																						{this.state.locations.length > 0
																							? this.state.locations.map((item, i) => {
																									return (
																										<option value={item.id} key={i}>
																											{item.code}
																										</option>
																									);
																								})
																							: ""}
																					</select>
																				</div>
																				</td>
																				<td>
																					<span className="d-block">
																						<strong>{item?.item.product_title}</strong>
																					</span>
																					
																				</td>
																				<td className={"text-right"}>
																					{twoDecimalPlace(item.item?.unit_price)}
																				</td>
																				<td className={`text-center`}>
																					{twoDecimalPlace(item.quantity)}
																				</td>
																				<td className={"text-right"}>
																					{twoDecimalPlace(item?.item?.product_discount)}
																				</td>
																				<td className="text-right">
																					{twoDecimalPlace(item?.amount)}
																				</td>
																				
																			</tr>
																		</React.Fragment>
																)  
																
																)
																: ""}

															<tr className="text-bold">
																<td colSpan={5} className="text-right">
																	Subtotal
																</td>
																<td className="text-right">
																	{twoDecimalPlace(returnDetails?.sub_total)}
																</td>
															</tr>

							
				
															<tr className="text-bold">
																<td colSpan={5} className="text-right">
																	Shipping Charge
																</td>
																<td className="text-right">
																	{twoDecimalPlace(
																		returnDetails?.shipping_charge
																	)}
																</td>
															</tr>

															<tr className="text-bold">
																<td colSpan={5} className="text-right">
																	Grand Total
																</td>
																<td className="text-right">
																	{twoDecimalPlace(returnDetails?.grand_total)}
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									<div className='d-flex justify-content-end'>
										<Link className="btn btn-lg btn-warning mr-2" to={this.state.backUrl}>
											Cancel
										</Link>
										<Button type='submit' variant='success' disabled={this.state.isSubmitting}>Submit</Button>
										
									</div>	
								</Form>
							</div>
						</div>
					)}
				</div>
			</App>
		);
	}
}

export default withRouter(ReturnPackedItemDetails);