import React, { Component } from 'react';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'react-js-pagination';
import ConfirmationModal from '../common/Modal';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { Button } from 'react-bootstrap';
import App from './../../App';
import { BiLayerPlus } from 'react-icons/all';
import { FiEdit } from 'react-icons/all';
import { getPaginatedList } from '../../services/baseServices';
import { destroy, getList, putData } from './../../services/baseServices';
import { toast } from 'react-toastify';
import { FiEye } from 'react-icons/all';
import { FaRegArrowAltCircleDown, FaCheck } from "react-icons/fa";

class ReviewList extends Component {
    constructor() {
        super();
        this.state = {
            reviews: [],
            activePage: 1,
            per_page :25,
            page:1,
            isLoading: false,
            temp_params: {},
            query_params: {},
        };
    }

    componentDidMount() {
        this.getPaginatedReviews(this.getQueryParams());
    }

    getQueryParams = () => {
        let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
        return params;
    }

    handlePageChange = (pageNumber) => {
      this.setState({page: pageNumber, activePage: pageNumber}, () => {
          this.getPaginatedReviews(this.getQueryParams());
      });
  }

    getPaginatedReviews = (params) => {
        this.setState({...this.state, isLoading: true});
        getPaginatedList("reviews",params)
          .then(res => {
              if(res.data?.success === true){
                this.setState({
                    reviews: res.data.data, 
                    isLoading: false,
                    per_page: parseInt(res.headers['x-per-page']),
                    total_data: parseInt(res.headers['x-total']),
                  }, () => {
                  });
              }else{
                this.setState({...this.state, isLoading: false});
              }
          })
          .catch(errMsg => {
            this.setState({...this.state, isLoading: false});
            console.log('Error Message: ', errMsg)
          })
    }
    
    handleApprove = (id) =>{
      this.setState({isLoading: true});
      putData(`reviews/approve/${id}`, {})
      .then(res=>{
        if(res.status === 200){
          this.setState({isLoading: false}, ()=>{
            toast.success(res.message);
          });
        }else{
          this.setState({isLoading: false}, ()=>{
            toast.error(res.message)
          });
        }
      }).catch(err=>{
        this.setState({isLoading: false}, ()=>{
          toast.error(err.message)
        });
      })
    } 

    handleOnFieldChange = (e) => {
      const queryData = {...this.state.temp_params};
      queryData[e.target.name] = e.target.value;
      this.setState({temp_params: queryData}, () => {
      });
    }

    handleOnSearch = (e) => {
      e.preventDefault();
      const tempParam = this.state.temp_params;
        this.setState({page: 1, query_params: tempParam, isLoading:true}, () => {
          this.getPaginatedReviews(this.state.temp_params);
        });
    }

    render() {
        return (
            <App layout="">
          <div className="main-content">
            <div className="page-header">
              <h2 className="page-title">Review List</h2>
            </div>

              <div className="card-block">
                <div className="block-body">

                  <form className="form-inline mb-3">
                    <div className="form-group mr-3">
                            <select
                              id="status"
                              name="reviewable_type"
                              className="form-control form-control-sm"
                              onChange={this.handleOnFieldChange}
                            >
                              <option value="">--Select Review Type--</option>
                              <option value="Variant">Vairant</option>
                              <option value="Partner">Partner</option>
                            </select>
                    </div>
                    <Button type={`submit`} onClick={this.handleOnSearch} size="sm" variant="primary">Search</Button>
                  </form>
                  <Loader loaded={!this.state.isLoading}>
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Rating</th>
                        <th>User Id</th>
                        <th>Review Type</th>
                        <th>Review for</th>
                        <th>Images</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.reviews.length > 0 ? this.state.reviews.map((item, index) => {
                        return (
                            <tr key={item.id}>
                              <td>
                                <div className="order-item">
                                    {item.title}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.description}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.rating}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.user_id}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.reviewable_type}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.reviewable_name}
                                </div>
                              </td>
                            <td>
                                <div className="order-item d-flex">
                                    {
                                        item?.images?.map((image,index)=>(
                                          <div className="user-avatar" key={index}>
                                              <img src={image} alt="" style={{width: "40px", height: "40px", marginRight: "5px"}}/>
                                          </div>
                                        ))
                                    }
                                    
                                </div>
                            </td>
                              <td style={{width: '150px'}}>
                                <div className="btn-group">
                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      {
                                        item.is_approved ? "Approved" : "Approve"
                                      }
                                    </Tooltip>
                                    }
                                  >
                                    {
                                      item.is_approved ? <Link onClick={(e)=>e.preventDefault()} className="btn btn-sm btn-disabled"><FaCheck/></Link>
                                      : 
                                      <ConfirmationModal 
                                        customBtn={true}
                                        variant='primary'
                                        btnText='Approve'
                                        title="Approve" 
                                        body="Are you sure to approve?" 
                                        handleAction={()=>this.handleApprove(item.id)}      
                                      />
                                    }
                                  
                                </OverlayTrigger>
                                  <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          View Details
                                        </Tooltip>
                                      }
                                  >
                                  <Link to={`/reviews/details/${item.id}`} className="btn btn-sm btn-default"><FiEye/></Link>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                        );
                      }) : ""}
                      </tbody>
                    </table>
                  </Loader>
                  </div>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
          
            </div>
          <ToastsContainer store={ToastsStore}/>
        </App>
        );
    }
}

export default ReviewList;