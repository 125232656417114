import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getData, getDetails } from "../../services/baseServices";
import { updatePartner } from "../../services/partnerServices";
import PartnerForm from "./Form";
import { FaLongArrowAltLeft } from "react-icons/all";
import { convertToSlug } from "../../helper/utils";

class Edit extends Component {
  state = {
    partnerInfo: {},
    formData: {
      companies: [],
      channels: [],
    },
    dataToUpdate:{},
    partner: { details: {}, address_attributes: {} },
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false,
    readyToSubmit: true,
    uniqueSlug: "",
    batRoute: null,
    isEditable: true,
  };

  componentDidUpdate(prevProps, prevState) {
    const { formData } = this.state;
    if (prevState.formData?.slug !== formData?.slug) {
      if (formData?.slug) {
        getData("slugs/uniqueness", {
          slug: formData.slug,
          id: this.props.match.params.id,
          type: "Category",
        })
          .then((res) => this.setState({ uniqueSlug: res.success }))
          .catch((err) => this.setState({ uniqueSlug: "" }));
      } else {
        this.setState({ uniqueSlug: "" });
      }
    }
  }

  setDistributorId = (ds_id) => {
    const formData = { ...this.state.formData };
    formData["distributor_id"] = ds_id;
    this.setState({ formData });
  };

  componentDidMount() {
    getDetails("partners", this.props.match.params.id)
      .then((res) => {
        if (res?.success) {
          this.setState({ partnerInfo: res.data });
          const formData = { ...this.state.formData };
          formData["route_id"] = res.data.route?.id;
          formData["companies"] = res.data.companies;
          formData["channels"] = res.data.channels;
          formData["area_id"] = res.data.addresses?.area_id;
          formData["thana_id"] = res.data.addresses?.thana_id;
          formData["distributor_id"] = res.data.route?.distributor_id;
          formData["address_line"] = res.data.addresses?.address_line;
          formData["phone"] = res.data.phone;
          formData["status"] = res.data.status;
          formData["prompt_sale_status"] = res.data.prompt_sale_status;
          formData["bkash_number"] = res.data.bkash_number;
          formData["area"] = res.data.area;
          formData["schedule"] = res?.data?.schedule.split("_") || [];
          formData["is_commission_applicable"] =
            res.data.is_commission_applicable;
          formData["is_credit_applicable"] = res.data.is_credit_applicable
            ? true
            : false;
          formData["credit_value"] = res.data?.credit?.credit_value;
          formData["image"] = res.data.image;
          formData["slug"] = res.data.slug;
          formData["business_type"] = res.data.business_type || 'multicat';
          formData["sr_id"] = res.data.retailer_assistant.id;

          this.setState({ batRoute: res?.data?.bat_route });
          this.setState({ formData }, () => {});
        }
      })
      .catch((errMsg) => {});
  }

  handleInputOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.name === "slug") {
      const slug = convertToSlug(e.target.value);
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          slug: slug,
        },
        dataToUpdate: {
          ...prevState.dataToUpdate,
          slug: slug,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
        },
        dataToUpdate: {
          ...prevState.dataToUpdate,
          [name]: value,
        },
      }));
    }
  };

  handleUpdatePartner = (e) => {
    e.preventDefault();
    const formInfo = { ...this.state.formData };

    this.setState({ formData: formInfo });

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        let partner = { ...this.state.dataToUpdate };
        const phone =
          partner["phone"]?.length === 14
            ? partner["phone"]?.substring(3)
            : partner["phone"];
        partner = { ...partner, phone };

        const address_attributes = {
         
        };
        if (partner.address_line && partner.area_id) {
          address_attributes["address_line"] = partner.address_line;
          address_attributes["area_id"] = partner.area_id;
        }

        delete partner.address_line;
        delete partner.area_id;
        delete partner.thana_id;
        if (typeof partner.image_file === "undefined") delete partner.image;

          updatePartner("partners", this.props.match.params.id,{partner, address_attributes} )
            .then((res) => {
              if (res.success) {
                this.props.history.push(`/partner/list`);
                toast.success("updated");
              } else {
                toast.error(res.message);
              }
            })
            .catch((errMsg) => {
              toast.error(errMsg);
              this.setState({ isSubmitting: false });
            })
            .finally(() => {
              this.setState({ isSubmitting: false });
            });
        
      });
    }
  };
  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Partner</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/partner/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Partner List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <PartnerForm
                setDistributorId={this.setDistributorId}
                handleClick={this.handleUpdatePartner}
                handleInputOnChange={this.handleInputOnChange}
                partnerInfo={this.state.partnerInfo}
                formData={this.state.formData}
                dataToUpdate={this.state.dataToUpdate}
                isSubmitting={this.state.isSubmitting}
                readyToSubmit={this.state.readyToSubmit}
                uniqueSlug={this.state.uniqueSlug}
                batRoute={this.state.batRoute}
                isEditable={this.state.isEditable}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);
