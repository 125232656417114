import React, { useContext, useState, useEffect } from "react";
import { Accordion, Card, Button, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { GoChevronUp, GoChevronDown } from "react-icons/go";
import { Link, useHistory } from "react-router-dom";
import { deleteData, putData } from "../../services/baseServices";
import { toast } from "react-toastify";
import BoxModal from "./BoxModal";
import ExportCSV from "../common/ExportCSV"
import ConfirmationModal from '../common/Modal'
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"
import { getWarehouseType } from "../../helper/utils";

const ChBoxingItem = ({
  boxItem,
  purchaseOrder,
  getPurchaseOrder,
  headers,
  data,
  showModalHandler
}) => {
  const [openPackOptions, setOpenPackOptions] = useState(false);
  const [packedItems, setPackedItems] = useState({});
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingBoxes, setExistingBoxes] = useState([]);
  const [showBoxModal, setShowBoxModal] = useState(false);
  const [movingLineItemId, setMovingLineItemId] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [exportForBoxLineItems, setExportForBoxLineItems] = useState([])

  const warehouseType = getWarehouseType();
  useEffect(() => {
    let packedItems = {};
    boxItem.line_items?.map(
      (item, i) =>
      (packedItems[i] = {
        errors: {},
        orderedQty: item.quantity,
        originalSKU: item.sku,
        locations: item.locations,
      })
    );
    setPackedItems(packedItems);

  }, [boxItem.line_items.length]);

  useEffect(() => {
    let boxLineItems = boxItem.line_items?.map(item => {
      let obj = { ...item }
      if (obj.qc_status)
        obj.qc_status = "Done"
      else
        obj.qc_status = "Not Done"
      return obj
    })
    setExportForBoxLineItems(boxLineItems)
  }, [boxItem, boxItem.line_items.length])

  useEffect(() => {
    let existingBoxList = purchaseOrder?.boxes?.filter(
      (item) => item.id !== boxItem.id
    );
    setExistingBoxes(existingBoxList);
  }, [boxItem]);


  const history = useHistory();
  const handlePackClick = () => {
    setOpenPackOptions(!openPackOptions);
  };

  const removeLineItemFromBox = (id) => {
    const params = {
      dh_po_id: purchaseOrder.id,
      line_item_id: id,
      box_id: boxItem.id,
    };
    deleteData("dh_purchase_orders/boxes/item_remove", params)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success(res.message);
          getPurchaseOrder();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleDeleteBox = () => {
    const params = {
      dh_po_id: purchaseOrder.id,
      box_id: boxItem.id,
    };
    deleteData("dh_purchase_orders/boxes/remove", params)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success(res.message);
          getPurchaseOrder();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });

  };

  const cancelPacking = () => {
    setOpenPackOptions(false);
  };

  const handleShowAndCloseModal = () => {
    // setMovingLineItemId(null)
    setShowBoxModal(!showBoxModal);
  };


  const handleMoveLineItem = (id) => {
    setMovingLineItemId(id);
    handleShowAndCloseModal();
  };

  const checkSKUandSet = (index, itemId, sku) => {
    let packedItem = { ...packedItems };
    let object = packedItem[index];
    object.line_item_id = itemId;
    if (isFormValidated) {
      if (sku !== object.originalSKU) {
        object.errors["sku"] = "QR code doesn't match";
      } else {
        object.errors["sku"] = "";
      }
    }
    object.sku = sku;
    packedItem[index] = object;
    setPackedItems(packedItem);
  };

  const handleOnLocationChange = (index, itemId, locationId) => {
    setSelectedLocationId(locationId);
    let packedItem = { ...packedItems };
    let object = packedItem[index];
    object.line_item_id = itemId;
    let location = object.locations?.find((i) => i.id == locationId);
    let avaialableQty = locationId ? location?.quantity : 0;

    if (object.quantity && locationId && object.quantity > avaialableQty) {
      object.errors["loc"] = "Location has not enough items";
    } else {
      object.errors["loc"] = "";
    }
    object.location_id = locationId;
    packedItem[index] = object;
    setPackedItems(packedItem);
  };

  const checkQTYandSet = (index, itemId, quantity) => {
    let packedItem = { ...packedItems };
    let object = packedItem[index];
    let location = object.locations?.find((i) => i.id == selectedLocationId);
    let avaialableQty = selectedLocationId ? location?.quantity : 0;
    object.line_item_id = itemId;
    if (selectedLocationId && quantity && quantity > avaialableQty) {
      object.errors["loc"] = "Location has not enough items";
    } else {
      object.errors["loc"] = "";
    }
    if (quantity > object.orderedQty) {
      object.errors["qty"] = "Can't pack larger than requested";
    } else {
      object.errors["qty"] = "";
    }

    object.quantity = quantity;
    packedItem[index] = object;
    setPackedItems(packedItem);
  };

  const isValid = () => {
    let packedItem = [];
    Object.keys(packedItems).map((key, i) => packedItem.push(packedItems[key]));
    packedItem = packedItem.map((item) => {
      let object = { ...item };
      if (item.quantity > item.orderedQty) {
        object.errors["qty"] = "Can't pack larger than requested";
      }
      if (item.sku !== item.originalSKU) {
        object.errors["sku"] = "QR code doesn't match";
      }
      return object;
    });
    setPackedItems(packedItem);
    let hasError = packedItem.some((item) => {
      return (item.errors.loc || item.errors.qty || item.errors.sku);
    });
    return !hasError;
  };

  const debouncedOnPackSubmit = _.debounce((e, form) => {
    handleSubmitPack(e, form)
  }, DEBOUNCE_TIME);

  const handleWithDebouncePackSubmit = (e) => {
    e.preventDefault()
    debouncedOnPackSubmit(e, e.currentTarget);
  }

  const handleSubmitPack = (e, form) => {
    e.preventDefault();
    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      setIsFormValidated(!isFormValidated);
    } else {
      setIsSubmitting(true);
      if (isValid()) {
        let line_items = [];
        Object.keys(packedItems).map((key, i) =>
          line_items.push(packedItems[key])
        );

        let line_item_list = line_items?.map(item => ({
          line_item_id: item.line_item_id,
          location_id: +item.location_id,
          sku: item.sku,
          quantity: + item.quantity

        }))

        putData("dh_purchase_orders/boxes/pack", {
          dh_po_id: purchaseOrder?.id,
          box_id: boxItem?.id,
          line_items: [...line_item_list]
        })
          .then(res => {
            if (res.status_code === 200) {
              toast.success(res.message)
              getPurchaseOrder()
            } else {
              toast.error(res.message)
            }
            setIsSubmitting(false)
          })
          .catch(err => {
            toast.error(err)
            setIsSubmitting(false)
          })
      } else {
        toast.error("Please fill all required fields correctly");
        setIsSubmitting(false);
        setIsFormValidated(true);
      }
    }
  };
  return (
    <Accordion defaultActiveKey="0" className="mb-3">
      <Form noValidate validated={isFormValidated} onSubmit={handleWithDebouncePackSubmit}>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Box ID: {boxItem.id}
            </Accordion.Toggle>
            <ContextAwareToggle eventKey="0" />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="my-3 d-flex justify-content-end">
                <Button
                  size="sm"
                  variant="primary mr-2"
                  onClick={handlePackClick}
                  type="button"
                  className={boxItem.status === "unpacked" ? '' : 'd-none'}
                >
                  Pack
                </Button>
                <div className='mr-2'>
                  <ExportCSV data={exportForBoxLineItems} headers={headers} title="Stock Transfer Order Items" />
                </div>

                <OverlayTrigger
                  key="view"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-view`}>
                      Delete
                    </Tooltip>
                  }
                >
                  <ConfirmationModal
                    customBtn={true}
                    btnClassName={boxItem.status === "unpacked" ? '' : 'd-none'}
                    variant='danger'
                    btnText='Delete'
                    title="Delete"
                    body="Are you sure you want to delete?"
                    handleAction={handleDeleteBox}
                  />
                </OverlayTrigger>
              </div>

              <table className="order-table table table-striped table-hover">
                <thead>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th>Product</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                    <th>Ordered</th>
                    <th>Sent</th>
                    <th>Received</th>
                    <th>Passed</th>
                    <th>Quality Failed</th>
                    <th>Quantity Failed</th>
                    <th>QC Status</th>

                    <th className={`text-center ${boxItem.status === "unpacked" ? '' : 'd-none'}`}>
                      {openPackOptions ? (
                        <p> Req. Fields<span className="text-danger">*</span></p>
                      ) : (
                        <p> Action</p>
                      )}
                    </th>
                    {
                      warehouseType === "central" && <th>Actions</th>
                    }

                  </tr>
                </thead>
                <tbody>
                  {boxItem?.line_items?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="order-item">
                          <Link
                            to={`${process.env.PUBLIC_URL}/product/details/${item.product_id}`}
                          >
                            {item.product_title}
                          </Link>
                        </div>
                      </td>

                      <td>
                        <div className="order-item">{item.price} BDT</div>
                      </td>

                      <td>
                        <div className="order-item">{item.total_price} BDT</div>
                      </td>

                      <td>
                        <div className="order-item">{item.quantity}</div>
                      </td>

                      <td>
                        <div className="order-item">{item.sent_quantity}</div>
                      </td>

                      <td>
                        <div className="order-item">
                          {item.received_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item">{item.qc_passed}</div>
                      </td>

                      <td>
                        <div className="order-item">{item.quality_failed}</div>
                      </td>

                      <td>
                        <div className="order-item">{item.quantity_failed}</div>
                      </td>

                      <td>
                        <div className="order-item">
                          {item.qc_status ? "Done" : "Not Done"}
                        </div>
                      </td>


                      <td style={{ width: "200px" }} className={boxItem.status === "unpacked" ? '' : 'd-none'}>
                        {openPackOptions ? (
                          <>
                            <Form.Group as={Row} controlId="sku">
                              <Col>
                                <Form.Control
                                  name={"sku"}
                                  required
                                  placeholder="QR Code"
                                  onChange={(e) =>
                                    checkSKUandSet(
                                      index,
                                      item.id,
                                      e.target.value
                                    )
                                  }
                                  type="text"
                                />
                                {packedItems[index]?.errors?.sku ? (
                                  <Form.Control.Feedback type="invalid" className="d-block">
                                    {packedItems[index]?.errors?.sku}
                                  </Form.Control.Feedback>
                                ) : (
                                  <Form.Control.Feedback type="invalid">
                                    QR code is required
                                  </Form.Control.Feedback>
                                )}
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="location">
                              <Col>
                                <Form.Control
                                  required
                                  name="location_id"
                                  onChange={(e) => {
                                    handleOnLocationChange(
                                      index,
                                      item.id,
                                      e.target.value
                                    );
                                  }}
                                  as="select"
                                  type="text"
                                >
                                  <option value="">--Select Location--</option>
                                  {item.locations?.length > 0
                                    ? item.locations?.map((location) => {
                                      return (
                                        <option
                                          value={location.id}
                                          key={location.id}
                                        >
                                          {location.code}
                                        </option>
                                      );
                                    })
                                    : ""}
                                </Form.Control>
                                {packedItems[index]?.errors?.loc ? (
                                  <Form.Control.Feedback type="invalid" className="d-block">
                                    {packedItems[index]?.errors?.loc}
                                  </Form.Control.Feedback>
                                ) : (
                                  <Form.Control.Feedback type="invalid">
                                    Location is required
                                  </Form.Control.Feedback>
                                )}
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="quantity">
                              <Col>
                                <Form.Control
                                  name={"quantity"}
                                  required
                                  placeholder="Quantity"
                                  max={item.quantity}
                                  min={1}
                                  onChange={(e) =>
                                    checkQTYandSet(
                                      index,
                                      item.id,
                                      e.target.value
                                    )
                                  }
                                  type="number"
                                />
                                {packedItems[index]?.errors?.qty ? (
                                  <Form.Control.Feedback type="invalid" className="d-block">
                                    {packedItems[index]?.errors?.qty}
                                  </Form.Control.Feedback>
                                ) : (
                                  <Form.Control.Feedback type="invalid">
                                    Quantity is required
                                  </Form.Control.Feedback>
                                )}
                              </Col>
                            </Form.Group>
                          </>
                        ) : (
                          <div className="order-item d-flex justify-content-center">
                            <Button
                              size="sm"
                              variant="success"
                              className="mr-2"
                              type="button"
                              disabled={existingBoxes.length > 0 ? false : true}
                              onClick={() => handleMoveLineItem(item.id)}
                            >
                              Move
                            </Button>
                            <OverlayTrigger
                              key="view"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-view`}>
                                  Remove
                                </Tooltip>
                              }
                            >
                              <ConfirmationModal
                                customBtn={true}
                                variant='danger'
                                btnText='Remove'
                                title="Remove"
                                body="Are you sure you want to remove?"
                                handleAction={() => removeLineItemFromBox(item.id)}
                              />
                            </OverlayTrigger>
                          </div>
                        )}
                      </td>
                      {
                        warehouseType === "central" && <td>
                          <Button
                            size="sm"
                            type={"button"}
                            className="mr-1"
                            onClick={() => showModalHandler(item.id)}
                            variant="info">
                            Log
                          </Button>
                        </td>
                      }

                    </tr>
                  ))}
                </tbody>
              </table>

            </Card.Body>
          </Accordion.Collapse>
          {openPackOptions && boxItem.status === "unpacked" && (
            <Card.Footer>
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-danger mr-2"
                  type="button"
                  onClick={cancelPacking}
                >
                  Cancel
                </Button>
                <Button
                  type={`submit`}
                  disabled={isSubmitting}
                  variant="outline-success"
                >
                  {isSubmitting ? "Submitting..." : "Submit Pack"}
                </Button>
              </div>
            </Card.Footer>
          )}
        </Card>
      </Form>
      {
        movingLineItemId && <BoxModal
          show={showBoxModal}
          handleClose={handleShowAndCloseModal}
          existingBoxes={existingBoxes}
          currentBox={boxItem}
          dh_po_id={purchaseOrder.id}
          lineItemId={movingLineItemId}
          type={"Ch_BoxingItem"}
          getPurchaseOrder={getPurchaseOrder}
        />
      }

    </Accordion>
  );
};
export default ChBoxingItem;



function ContextAwareToggle({ eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <div onClick={decoratedOnClick}>
      {isCurrentEventKey ? <GoChevronUp /> : <GoChevronDown />}
    </div>
  );
}
