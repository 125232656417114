import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import DatePicker from "react-datepicker";
import TimeInput from "material-ui-time-picker";
import { Link } from "react-router-dom";
import { getList, getData } from "../../services/baseServices";
import moment from "moment";
import "../../assets/scss/promo.scss";
import BrowseButton from "../../components/BrowseButton";

class FlashSaleForm extends Component {
  state = {
    fromDate: "",
    toDate: "",
    // warehouses: [],
    inputValue: "",
  };

  // componentDidMount() {
  //   getList("warehouses")
  //     .then((res) => {
  //       this.setState({ warehouses: res });
  //     })
  //     .catch((errMsg) => {});
  // }

  handleInputChange = (e) => {
    console.log(e);
    const inputValue = e.replace(/\W/g, "");
    this.setState({ inputValue: inputValue });
    return inputValue;
  };

  handleOnChange = (key, value) => {
    console.log(key, value);
    let xx = value.map((item) => {
      return item.value;
    });
    console.log(xx, xx);
    this.props.onPromoFieldChange(key, xx);
  };

  render() {
    const errors = this.props.errors;
    return (
      <Form
        noValidate
        //validated={this.props.isFormValidated}
        onSubmit={this.props?.handleClick}>
        <Form.Group as={Row} controlId={"promotion-title"}>
          <Form.Label column sm="3">
            Title <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              // pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
              name="title"
              onChange={this.props.handleInputOnChange}
              required
              type="text"
              maxLength={25}
            />
            <Form.Control.Feedback type="invalid">
              Title is required
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId={"promotion-title"}>
          <Form.Label column sm="3">
            Bangla Title <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              // pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
              name="title_bn"
              onChange={this.props.handleInputOnChange}
              required
              type="text"
              maxLength={25}
            />
            <Form.Control.Feedback type="invalid">
              Bangla Title is required
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} controlId="warehouse">
          <Form.Label column sm="3">
            Warehouse <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              required
              name="warehouse_id"
              onChange={(e) => {
                this.props.handleInputOnChange(e);
              }}
              as="select"
              type="text">
              <option value="">--Select--</option>
              {this.state.warehouses.length > 0
                ? this.state.warehouses.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                : ""}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a warehouse.
            </Form.Control.Feedback>
          </Col>
        </Form.Group> */}

        <Form.Group as={Row} controlId="from-date">
          <Form.Label column sm="3">
            From Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <DatePicker
              required
              selected={this.state.fromDate}
              minDate={new Date()}
              maxDate={this.state.toDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                //this.props?.formData["from_date", date]
                this.setState({ fromDate: date });
                this.props.handleDateOnChange("from_date", date);
              }}
            />
            <Form.Control.Feedback className="d-block" type="invalid">
              {errors.from_date}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="to-date">
          <Form.Label column sm="3">
            To Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <DatePicker
              required
              selected={this.state.toDate}
              minDate={this.state.fromDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                //this.props?.formData["to_date", date]
                this.setState({ toDate: date });
                this.props.handleDateOnChange("to_date", date);
              }}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.to_date}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="start_time">
            <Form.Label column sm="3">Start Time<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <TimeInput
                required
                mode='24h'
                onChange={time => {
                  this.props.handleTimeOnChange("start_time", moment(time).format("HH:mm"));
                  //this.props?.formData["start_time", time]
                }
                }
              />
              {/* <Form.Control.Feedback type="invalid">{errors.start_time}</Form.Control.Feedback> */}
            </Col>
          </Form.Group>  
          <Form.Group as={Row} controlId="end_time">
            <Form.Label column sm="3">End Time<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <TimeInput
                required
                defaultValue={null}
                mode='24h'
                onChange={time => {
                  this.props.handleTimeOnChange("end_time", moment(time).format("HH:mm"));
                  //this.props?.formData["end_time", time]
                }
                }
              />
              {/* <Form.Control.Feedback type="invalid">{errors.end_time}</Form.Control.Feedback> */}
            </Col>
          </Form.Group> 

        <Form.Group as={Row} controlId="active">
          <Form.Label column sm="3">
            Status
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <RadioGroup
              row
              name="is_active"
              value={this.props.formData.is_active}
              onChange={this.props.handleInputOnChange}>
              <FormControlLabel className="promo-status"
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel className="promo-status"
                value={false}
                control={<Radio />}
                label="In Active"
              />
            </RadioGroup>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formImages">
          <Form.Label column sm="3">
            File <span className="text-danger">*</span>
            <p className="text-danger">Please upload only .csv file</p>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <BrowseButton
              multiple={false}
              onChange={(e) => this.props.onFileChange(e)}
              id={"file"}
              name={"file"}
            />
            {/* <Form.Control 
              type={`file`}
              // accept=".csv, text/csv, text/*,application/vnd.ms-excel"
              
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
              required
              onChange={(e)=>this.props.onFileChange(e)}
              name={`file`}/> */}
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.file}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <div className="mt-3 text-center">
          <Link
            to={`${process.env.PUBLIC_URL}/flashSale/list`}
            className="mr-2 btn btn-md btn-danger">
            Cancel
          </Link>
          <Button
            size="md"
            type={`submit`}
            disabled={this.props.isSubmitting}
            variant="primary">
            {this.props.isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </Form>
    );
  }
}
export default FlashSaleForm;
