import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import Rating from '@material-ui/lab/Rating';
import "react-datepicker/dist/react-datepicker.css";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import DatePicker from "react-datepicker";

function SupplierForm(props) {
    const [productQualityRating, setProductQualityRating] = useState(props.formData?.product_quality_rating);
    const [deliverTimeRating, setDeliverTimeRating] = useState(props.formData?.deliver_time_rating);
    const [serviceQualityRating, setServiceQualityRating] = useState(props.formData?.service_quality_rating);
    const [professionalismRating, setProfessionalismRating] = useState(props.formData?.professionalism_rating);
    const [prePayment, setPrePayment] = useState(props.formData?.pre_payment || '');
    const [postPayment, setPostPayment] = useState(props.formData?.post_payment || '');
    const [creditPayment, setCreditPayment] = useState(props.formData?.credit_payment);
    const [contractStartDate, setContractStartDate] = useState(props.formData?.contract_start_date ? props.formData?.contract_start_date : new Date());
    const [contractEndDate, setContractEndDate] = useState(props.formData?.contract_end_date);
    const [addressLine, setAddressLine] = useState(props.formData?.address_line);

    useEffect(() => {
        setAddressLine(props.formData.address_line);
        setProductQualityRating(props.formData.product_quality_rating);
        setDeliverTimeRating(props.formData.deliver_time_rating);
        setServiceQualityRating(props.formData.service_quality_rating);
        setProfessionalismRating(props.formData.professionalism_rating);
        setPrePayment(props.formData.pre_payment);
        setPostPayment(props.formData.post_payment);
        setCreditPayment(props.formData.credit_payment);
        setContractStartDate(props.formData?.contract_start_date);
        setContractEndDate(props.formData?.contract_end_date);
    }, [props.formData, props.supplierInfo]);


    return (
        <Form
            noValidate
            onSubmit={props.handleClick}>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">Supplier Name<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"supplier_name"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.supplier_name}
                        required
                        type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter supplier name.</Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">Email<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"email"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.email}
                        required
                        type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter email.</Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Phone
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"phone"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.phone}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Address<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        required
                        name="address_line"
                        onChange={props.handleInputOnChange}
                        defaultValue={addressLine}
                        as="textarea" type="text">
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Mou Document Number
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"mou_document_number"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.mou_document_number}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Supplier Representative
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"supplier_representative"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.supplier_representative}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Representative Designation
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"representative_designation"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.representative_designation}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Representative Contact
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"representative_contact"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.representative_contact}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    TIN
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"tin"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.tin}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    BIN
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"bin"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.bin}
                        type="text"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Contact Start Date
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <DatePicker
                        name={"contract_start_date"}
                        style={{width: "100%"}}
                        selected={contractStartDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => props.setDate(date, 'contract_start_date')}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Contact End Date
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <DatePicker
                        style={{width: "100%"}}
                        name={"contract_end_date"}
                        selected={contractEndDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => props.setDate(date, 'contract_end_date')}
                    />
                </Col>
            </Form.Group>

            {/* <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3"> Bank Name</Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"bank_name"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.bank_name}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Account Number
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"account_number"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.account_number}
                        type="text"/>
                </Col>
            </Form.Group> */}
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Swift Code
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"swift_code"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.swift_code}
                        type="text"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Central Warehouse Address
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"central_warehouse_address"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.central_warehouse_address}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Local Warehouse Address
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"local_warehouse_address"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.local_warehouse_address}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Product Quality Ratingy
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Rating
                        name="product_quality_rating"
                        value={Number(productQualityRating)}
                        precision={0.5}
                        onChange={(e) => {
                            {
                                props.handleInputOnChange(e)
                            }
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Delivery Time Rating
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Rating
                        name="deliver_time_rating"
                        value={Number(deliverTimeRating)}
                        precision={0.5}
                        onChange={(e) => {
                            {
                                props.handleInputOnChange(e)
                            }
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Service Quality Rating
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Rating
                        name="service_quality_rating"
                        value={Number(serviceQualityRating)}
                        precision={0.5}
                        onChange={(e) => {
                            {
                                props.handleInputOnChange(e)
                            }
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Professionalism Rating
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Rating
                        name="professionalism_rating"
                        value={Number(professionalismRating)}
                        precision={0.5}
                        onChange={(e) => {
                            {
                                props.handleInputOnChange(e)
                            }
                        }}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDangerousGoods">
                <Form.Label column sm="3">Pre Payment</Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <RadioGroup row name="pre_payment" value={String(prePayment)} onChange={props.handleInputOnChange}>
                        <FormControlLabel value={'true'} control={<Radio/>} label="Yes"/>
                        <FormControlLabel value={'false'} control={<Radio/>} label="No"/>
                    </RadioGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDangerousGoods">
                <Form.Label column sm="3"> Post Payment</Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <RadioGroup row name="post_payment" value={String(postPayment)}
                                onChange={props.handleInputOnChange}>
                        <FormControlLabel value={'true'} control={<Radio/>} label="Yes"/>
                        <FormControlLabel value={'false'} control={<Radio/>} label="No"/>
                    </RadioGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDangerousGoods">
                <Form.Label column sm="3">Credit Payment</Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <RadioGroup row name="credit_payment" value={String(creditPayment)}
                                onChange={props.handleInputOnChange}>
                        <FormControlLabel value={'true'} control={<Radio/>} label="Yes"/>
                        <FormControlLabel value={'false'} control={<Radio/>} label="No"/>
                    </RadioGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Credit Days
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"credit_days"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.credit_days}
                        type="number"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Credit Limit
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"credit_limit"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.credit_limit}
                        type="number"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Agami KAM Name
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"agami_kam_name"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.agami_kam_name}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Agami KAM Email
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"agami_kam_email"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.agami_kam_email}
                        type="text"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Delivery Responsibility
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"delivery_responsibility"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.delivery_responsibility}
                        type="text"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Product Lead Time
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"product_lead_time"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.product_lead_time}
                        type="number"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                    Return Days
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        name={"return_days"}
                        onChange={props.handleInputOnChange}
                        defaultValue={props.supplierInfo.return_days}
                        type="number"/>
                </Col>
            </Form.Group>

            <div className="mt-3">
                <Button size="lg"
                        type={`submit`}
                        disabled={props.isSubmitting}
                        variant="primary">{props.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
            </div>

        </Form>
    );
// }
}

export default SupplierForm;
