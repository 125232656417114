import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {MdArrowBack} from "react-icons/all";
import {Alert} from "react-bootstrap";

class Details extends Component {
  state = {}

  render() {
    const {entrepreneurDetails} = this.state;

    return (
      <App layout={`boxed`}>
        <div className="main-content">
          <div className="page-header">
            <div className="page-title"/>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/dwh/list`} className={`btn btn-sm btn-link`}><MdArrowBack/> Back
                to List</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Warehouse Details</h4>
            </div>
            <div className="block-body">
              <>
                <table className="info-table">
                  <tbody>
                  <tr>
                    <td style={{width: '250px'}}>Warehouse Name</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td className={`text-bold`}>Distribution Warehouse 1</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Warehouse Name in Bangla</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>ডিস্ট্রিবিউশন ওয়্যারহাউস ১</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Email</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>dist@gmail.com</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Phone</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>+880177445555</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Address</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>Dhaka, Bangladesh</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Creation Date</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>25th November, 2020</td>
                  </tr>
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);