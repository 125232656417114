import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import styleReactSelect from "../../assets/styleReactSelect";
import {FaLongArrowAltLeft} from "react-icons/all";
import {addAttribute} from "../../services/attributeServices";
class Add extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        product_attribute_values_attributes: [],
      },
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      attributeInfo: null,
      valueEmpty:false
    };
  }

  componentDidMount() {

  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
      console.log(this.state.formData);
    });
  }

  handleBnInputOnChange = (e, index) => {
    let attributes = [...this.state.formData.product_attribute_values_attributes];
    attributes[index].bn_value = e.target.value;
    this.setState({
      formData: {...this.state.formData, product_attribute_values_attributes: attributes}
    }, () => {
      console.log(this.state.formData)
    });
  }

  handleAttrOnChange = (newValue, type) => {
    let attributes = [];
    if (newValue && type === 1) {
      newValue.forEach((val) => {
        attributes.push({value: val.value});
      });
    }
    this.setState({
      formData: {...this.state.formData, product_attribute_values_attributes: attributes},
      valueEmpty:false
    }, () => {
      console.log(this.state.formData)
    });
  }

  handleAddItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {

      const formData = {...this.state.formData}
      if(formData.product_attribute_values_attributes.length <= 0){
        this.setState({valueEmpty:true})
        return
      }
      this.setState({isSubmitting: true}, () => {
        addAttribute(formData)
          .then(res => {
            if(res?.id) {
              this.props.history.push(`/attribute/list`);
              toast.success("Attribute has been added successfully.");
            } else {
              toast.error(res.message);
              this.setState({isSubmitting: false})
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Attribute</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/attribute/list`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Attribute List</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form
                noValidate
                validated={this.state.isFormValidated}
                onSubmit={this.handleAddItem}>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Title in English <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      onChange={this.handleInputOnChange}
                      required
                      pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter attribute name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Title in Bangla <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"bn_name"}
                      onChange={this.handleInputOnChange}
                      required
                      // pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter attribute in Bangla.</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Values <span className="text-danger">*</span>
                  </Form.Label>

                  <Col sm="9" md={8} lg={7}>
                    <CreatableSelect
                      defaultValue={this.state.attributes}
                      required
                      isMulti
                      styles={styleReactSelect}
                      placeholder="Please type your values..."
                      onChange={(newValue) => {
                        this.handleAttrOnChange(newValue, 1);
                      }}
                      noOptionsMessage={() => 'Please type your values...'}
                    />
                    {this.state.valueEmpty && <span type="invalid" style={{color:"red"}}><i>Please enter attribute values.</i></span>}
                  </Col>

                </Form.Group>

                <div id="dynamicInput">
                  {this.state.formData.product_attribute_values_attributes.map((item, index) => {
                    return (
                      <Form.Group as={Row} controlId="displayOrShopName">
                        <Form.Label column sm="3">
                          {item.value} in Bangla<span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                            name={"bn_name"}
                            onChange={(e) => {
                              this.handleBnInputOnChange(e, index)
                            }}
                            defaultValue={item?.bn_value}
                            required
                            type="text"/>
                          <Form.Control.Feedback type="invalid">Please enter attribute in
                            Bangla.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    );
                  })}
                </div>

                <div className="mt-3">
                  <Button size="lg"
                          type={`submit`}
                          disabled={this.state.isSubmitting}
                          variant="primary">{this.state.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);