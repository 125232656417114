import React, { Component } from "react";
import App from "../../App";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from "react-toasts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getPaginatedList } from "../../services/baseServices";
import { getFormattedDate } from "../../helper/utils";
import Loader from "react-loader";

import ExportCSVAsync from "../common/ExportCSVAsync";
import moment from "moment";
import { FiPlus } from "react-icons/fi";

const headers = [
  { label: "PO NO.", key: "id" },
  { label: "Quantity", key: "quantity" },
  { label: "Supplier", key: "supplier_name" },
  { label: "Price", key: "total_price" },
  { label: "Date", key: "order_date" },
  { label: "QC Status", key: "qc_status" },
  { label: "Paid", key: "paid" },
  { label: "Pay Status", key: "pay_status" },
];

var startDate = moment().subtract(29, "days");
var today = moment();

const loadData = (item) => {
  return {
    id: item.id,
    quantity: item.quantity,
    supplier_name: item.supplier_name,
    total_price: item.total_price,
    order_date: getFormattedDate(item.order_date, "LT Do MMMM YYYY"),
    qc_status: item.qc_status ? "Done" : "Not Done",
    paid: item.paid,
    pay_status: item.pay_status,
  };
};

class List extends Component {
  constructor() {
    super();
    this.state = {
      orderList: [],
      isLoading: true,
      activePage: 1,
      per_page: 15,
      page: 1,
      temp_params: {
        start_date_time: startDate.format("YYYY-MM-DD"),
        end_date_time: today.format("YYYY-MM-DD"),
      },
      query_params: {
        start_date_time: startDate.format("YYYY-MM-DD"),
        end_date_time: today.format("YYYY-MM-DD"),
      },
      isExporting: false,
      start_date_time: startDate,
      end_date_time: today,
      reqForPay: false,
      orderInfo: undefined,
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getPaginatedPOs = (params) => {
    this.setState({ ...this.state, isLoading: true });
    getPaginatedList("wh_purchase_orders", params)
      .then((res) => {
        this.setState(
          {
            orderList: res.data,
            isLoading: false,
            per_page: res.headers["x-per-page"],
            total_data: res.headers["x-total"],
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        this.setState({ ...this.state, isLoading: false });
        console.log("Error Message: ", errMsg);
      });
  };

  componentDidMount() {
    this.getPaginatedPOs(this.getQueryParams());
  }

  refreshList = () => this.getPaginatedPOs(this.getQueryParams());

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    console.log(tempParam);
    this.setState({ ...this.state, page: 1, query_params: tempParam }, () => {
      this.getPaginatedPOs(this.state.temp_params);
    });
  };

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {});
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedPOs(this.getQueryParams());
    });
  };

  handleOnApply = (e, picker) => {
    this.setState({
      start_date_time: getFormattedDate(picker.startDate, ""),
      end_date_time: getFormattedDate(picker.endDate, ""),
    });
    this.setState({
      temp_params: {
        ...this.state.temp_params,
        start_date_time: getFormattedDate(picker.startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(picker.endDate, "YYYY-MM-DD"),
      },
    });
  };

  payHandler = (orderInfo) => this.setState({ reqForPay: true, orderInfo });

  payAbortion = () => this.setState({ reqForPay: false, orderInfo: undefined });

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Purchase Order List</h2>
            <div className="ml-auto d-flex">
              <ExportCSVAsync
                fileName={
                  "PO-" + getFormattedDate(new Date(), "DD-MM-YYYY") + ".csv"
                }
                Url="wh_purchase_orders/export"
                headers={headers}
                params={this.state.query_params}
                loadData={loadData}
                title="Purchase Order List"
              />
              <Link
                to={`${process.env.PUBLIC_URL}/purchaseOrder/add`}
                className="btn btn-sm btn-secondary ml-2"
              >
                <FiPlus /> Add Purchase Order
              </Link>
            </div>
          </div>
          <form className="form-inline mb-3">
            <div className="form-group mr-3">
              <label htmlFor="status" className="sr-only">
                Date Range
              </label>
              <DateRangePicker
                initialSettings={{
                  startDate: this.state.start_date_time,
                  endDate: this.state.end_date_time,
                  maxSpan: { months: 3 },
                  showDropdowns: true,
                  locale: { format: "DD/MM/YYYY" },
                  autoApply: true,
                }}
                onApply={(e, picker) => this.handleOnApply(e, picker)}
              >
                <input
                  type="text"
                  value={
                    this.state.start_date_time && this.state.end_date_time
                      ? `${getFormattedDate(
                          this.state.start_date_time,
                          "DD/MM/YYYY"
                        )} - ${getFormattedDate(
                          this.state.end_date_time,
                          "DD/MM/YYYY"
                        )}`
                      : ""
                  }
                  style={{ textAlign: "left", minWidth: "150px" }}
                  placeholder={"Select date range"}
                  className="form-control form-control-sm"
                />
              </DateRangePicker>
            </div>
            <Button
              size="sm"
              type={`submit`}
              onClick={this.handleOnSearch}
              variant="primary"
            >
              Filter
            </Button>
          </form>
          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body">
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      {/*<th>Product</th>*/}
                      <th>PO No.</th>
                      <th>Quantity</th>
                      <th>Supplier</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>QC Status</th>
                      <th>Paid</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orderList.length > 0 ? (
                      this.state.orderList.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {(this.state.page - 1) * this.state.per_page +
                                index +
                                1}
                            </td>

                            <td>
                              <div className="order-item">
                                # 000000{order.id}
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {order.quantity}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {order.supplier_name}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {order.total_price}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                {getFormattedDate(
                                  order.order_date,
                                  "LT Do MMMM YYYY"
                                )}
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                {order.qc_status ? "Done" : "Not Done"}
                              </div>
                            </td>
                            <td>
                              <div className="order-item">
                                {order.paid || 0}
                              </div>
                            </td>
                            <td>
                              <div className="order-item">
                                {order.pay_status}
                              </div>
                            </td>
                            <td style={{ width: "150px" }} className={"nowrap"}>
                              <div>
                                {/*<button className='btn-sm btn-default'>Do QC</button>*/}
                                <Link
                                  to={`${process.env.PUBLIC_URL}/purchaseOrder/details/${order.id}`}
                                  className="btn btn-sm btn-outline-secondary"
                                >
                                  Details
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          {" "}
                          <h5>No Order Found</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-3">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={this.state.total_data}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </Loader>
        </div>
        <ToastsContainer store={ToastsStore} />
      </App>
    );
  }
}

export default withRouter(List);
