import React, { Component } from 'react';
import App from "../../App";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getAssignedProducts } from "../../services/supplierServices";
import { editAssignProduct } from "../../services/productServices";
import { deleteData } from '../../services/baseServices'
import ConfirmationModal from "../common/Modal";

class EditProductAssign extends Component {

	constructor() {
		super();
		this.state = {
			formData: {
				suppliers_variants: [],
			},
			value: [],
			assignedProductLists: [],
			filteredList: []
		}
	}

	getAssignedItems = () => {
		getAssignedProducts(this.props.match.params.id)
		.then(res => {
			this.setState({
				assignedProductLists: res,
				filteredList: res,
				isLoading: false,
			}, () => {});
		})
		.catch(errMsg => {
			console.log('Error Message: ', errMsg)
		})
	}
	
	componentDidMount() {
		this.getAssignedItems()
	}

	handleSearch = (e) => {
		let filteredList = this.state.assignedProductLists.filter(item => item.product.toLowerCase().includes(e.target.value.toLowerCase()))
		this.setState({filteredList})
	}

	handleInputOnChange = (e, variant_id) => {
		const formData = {...this.state.formData};
		formData.suppliers_variants[variant_id] = {[e.target.name]: e.target.value, variant_id: variant_id};
		this.setState({formData}, () => {
			console.log(this.state);
		})
	}

	handleEditAssignProduct = async (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			this.setState({isFormValidated: true})
		} else {
			this.setState({isSubmitting: true}, () => {
			const formData = {...this.state.formData}
			formData.suppliers_variants = formData.suppliers_variants.filter(function (sv) {
				return sv != null;
			});
			console.log("formData===edit==", formData)
			editAssignProduct(formData, this.props.match.params.id)
				.then(res => {
					console.log(res);
					this.props.history.push(`/supplier/list`);
					// toast.success("Product has been assigned successfully.");
				})
				.catch(errMsg => {
					toast.error(errMsg);
					this.setState({isSubmitting: false})
				});
			})
		}
	}

	removeAssignment =  (variantId) => {
		let url = `suppliers/${this.props.match.params.id}/suppliers_variants`
		deleteData(url, {variant_id: variantId})
		.then(res => {
			if(res.status_code === 200) {
				this.getAssignedItems()
				toast.success(res.message);
			} else {
				toast.error(res.message)
			}
		
		})
		.catch(errMsg => {
			toast.error(errMsg)
		})
	  }

	render() {

		return (
			<App layout="boxed">
				<div className="page-header">
					<h2 className="page-title">Supplier Product Edit Assign</h2>
					<input style={{width: '500px'}} type="text" placeholder="Search By Product Title" onChange={this.handleSearch}/>
				</div>
				<Form
					noValidate
					validated={this.state.isFormValidated}
					onSubmit={this.handleEditAssignProduct}>
					<div className="card-block">
						{this.state.filteredList.length > 0 ?
							this.state.filteredList.map((assigned, index) => {
								return (
									<>
										<div className="block-header">
											<h4 className="block-title">{assigned.product}</h4>
										</div>
										<div className="block-body">
											{assigned?.variants.length > 0 ?
												assigned.variants.map((variant) => {
												return (
													<div key={index} className="variation-card" style={{borderBottom: 'none'}}>
														<div className="variation-header v-middle">
															<div className="d-flex v-middle">
																<h4 className="variation-title mb-0 nowrap">
																{variant.name}
																</h4>
																<div className="form-inline ml-4">
																	<label htmlFor="" className="mr-2">Supplier Price (BDT)</label>
																	<Form.Control name="supplier_price" size={"sm"}
																		defaultValue={variant.sp_price}
																		onChange={(e) =>
																		this.handleInputOnChange(e, variant.id)
																		}
																		step="0.01"
																		type={"number"}/>
																</div>
															</div>
															<div className="ml-auto">
																<OverlayTrigger
																	key={index+2}
																	placement="top"
																	overlay={
																		<Tooltip id={`tooltip-view`}>
																		Delete
																		</Tooltip>
																	}
																>
																	<ConfirmationModal 
																		title={`Close assignment for - ${variant.name}`}
																		body="Are you sure to withdraw the assignment?" 
																		customBtn = {true}
																		variant = {"danger"}
																		btnText="Remove"
																		handleAction={()=>this.removeAssignment(variant.id)}/>
																</OverlayTrigger>
															</div>
														</div>
													</div>
												);
												})
												: ''}

										</div>
									</>
								)
								}
							)
							: ''}
					</div>

					<div className="card-block">
						<div className="block-body d-flex">
							<div className="ml-auto">

								<Link className="btn btn-lg btn-default mr-2" to={`/supplier/list`}>
									Cancel
								</Link>
								<Button type={'submit'} size={'lg'} veriant={'primary'}>Update</Button>
							</div>
						</div>
					</div>
				</Form>
			</App>
		);
		}
	}

export default withRouter(EditProductAssign);
