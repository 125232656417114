import React, { useEffect, useState } from "react";
import { getDimension } from "../../helper/utils";


export default function FileUploader({
  name,
  readOnly,
  customError = "",
  isRequired = false,
  onFileSelect,
  onFailure,
  dimension = { width: 300, height: 300 },
  maxSize,
  acceptedFormats,
  isMultiple = false,
}) {
  const [error, setError] = useState(customError);
  const handleFileInput = async (e) => {
    e.persist();
    const files = e.target.files;
    let err = "";
    for (var i = 0; i < files.length; i++) {
      let sizes = await getDimension(files[i], acceptedFormats);
      if (!acceptedFormats.includes(files[i].type))
        err =
          "Invalid image format, supported image format:(.jpg, .jpeg and .png)";
      else if (files[i].size > maxSize)
        err = `File size cannot exceed ${maxSize / 1024}KB`;
      else if (
        sizes.width !== dimension.width ||
        sizes.height !== dimension.height
      )
        err = `Dimention between ${dimension.width}X${dimension.height}`;
      else err = "";
    }
    if (!err) onFileSelect(name, files);
    else onFailure(name, err);
  };

  useEffect(() => {
    setError(customError);
  }, [customError]);

  return (
    <div className="file-uploader" key={name}>
      <input
        disabled={readOnly}
        type="file"
        required={isRequired}
        name={name}
        multiple={isMultiple}
        onChange={handleFileInput}
        accept={acceptedFormats.join(",")}
      />
      <div className="invalid-feedback d-block">{error}</div>
    </div>
  );
}
