import React from 'react'
import {Field, ErrorMessage} from 'formik'
import ErrorMsg from './ErrorMsg'

const Textarea = ({name, label, required, ...rest}) => {
    return (
        <div className='row mb-2'>
            <div className='col-sm-5'>
                <label className='form-label' htmlFor={name}>
                    {label}{required?(<span className='text-danger'>{required}</span>):''}
                </label>
            </div>
            <div className='col-sm-7'>
                <Field as='textarea' className='form-control' name={name} id={name} {...rest}/>
                {
                    required?(
                        <ErrorMessage name={name} component={ErrorMsg}/>
                    ):''
                }
            </div>
        </div>
      
    )
}

export default Textarea
