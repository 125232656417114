import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import { FaFileExport } from "react-icons/all";
import { getList } from "../../services/baseServices";
import { toast } from "react-toastify";

class ExportCSV extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			isExporting: false,
		};
		this.csvLinkEl = React.createRef();
	}

	generateFomattedData = (data) => {
		if (data) {
			return data?.map((item, i) => {
				return this.props.loadData(item);
			});
		} else {
			return [];
		}
	};
	getData = () => {
		this.setState({ isExporting: true });
		return getList(this.props.Url, this.props.params)
			.then((res) => res)
			.catch((errMsg) => {
				toast.error("Failed to export");
				return [];
			});
	};

	downloadReport = async () => {
		const data = await this.getData();
		const fData = this.generateFomattedData(data);
		if(fData.length > 0) {
			this.setState({ data: fData }, () => {
				setTimeout(() => {
					this.csvLinkEl.current.link.click();
					this.setState({ isExporting: false });
					toast.success(`${this.props.title} has been exported successfully`);
				});
			});
		} else {
			this.setState({ isExporting: false });
			toast.error("Nothing to export");
		}
		
	};

	render() {
		const { data } = this.state;

		return (
			<div>
				<Button
					disabled={this.state.isExporting}
					onClick={() => this.downloadReport()}
					className="btn btn-sm btn-secondary"
				>
					<FaFileExport /> {this.state.isExporting ? "Exporting..." : "Export"}{" "}
				</Button>
				<CSVLink
					className="d-none"
					filename={this.props.fileName}
					data={data}
					headers={this.props.headers}
					ref={this.csvLinkEl}
				/>
			</div>
		);
	}
}

export default ExportCSV;
