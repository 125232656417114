import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../helper/utils";
import { FiEye } from "react-icons/all";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const OrderList = ({ customerOrders }) => {
	console.log(customerOrders);
	return (
		<table className="order-table table table-striped">
			<thead>
				<tr>
					<th style={{ width: "100px" }}>Order #</th>
					<th>Order Date</th>
					<th>Delivery Date</th>
					<th>Order Type</th>
					<th>Payment Type</th>
					<th>Shipping Type</th>
					<th>Order Status</th>
					<th>Price</th>
					<th>Partner's Margin</th>
					<th>Action</th>
				</tr>
			</thead>

			<tbody>
				{customerOrders?.length > 0 ? (
					customerOrders?.map((item, index) => {
						return (
							<tr key={index}>
								<td>{item.order_id}</td>
								<td>{getFormattedDate(item.order_date,"LT, Do, MMMM, YYYY")}</td>
								<td>
									<div className="order-item">
										{item.delivery_date ? (
											<span>
												{getFormattedDate(item.delivery_date,"LT, Do, MMMM, YYYY")}
											</span>
										) : (
											""
										)}
									</div>
								</td>

								<td>
									<div className="order-item">{item.order_type}</div>
								</td>

								<td>
									<div className="order-item">{item.pay_type}</div>
								</td>

								<td>
									<div className="order-item">{item.shipping_type}</div>
								</td>

								<td>
									<div className="order-item">{item.status}</div>
								</td>

								<td>
									<div className="order-item">{item.grand_total}</div>
								</td>

								<td>
									<div className="order-item">{item.partner_commission}</div>
								</td>
								<td style={{ width: "150px" }}>
									<div className="btn-group">
										<OverlayTrigger
											key="view"
											placement="top"
											overlay={<Tooltip id={`tooltip-view`}>View</Tooltip>}
										>
											<Link
												to={`${process.env.PUBLIC_URL}/customerOrder/details/${item.order_id}`}
												className="btn btn-sm btn-default"
											>
												<FiEye />
											</Link>
										</OverlayTrigger>
									</div>
								</td>
							</tr>
						);
					})
				) : (
					<tr>
						<td colSpan={9} className="text-center">
							<h5>No Order Found</h5>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default OrderList;
