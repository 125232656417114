import React from "react";

export default function OrderSummary({orderSummary}) {
    return (
        <>
            <div className="card-block">
                <div className="block-body">
                    <table className=" table table-bordered table-striped text-center">
                        <thead>
                            <tr className="text-white ">
                                <th className="bg-primary text-center">Total Order</th>
                                <th colSpan="2" className="bg-primary text-center ">
                                    Partner{" "}
                                </th>
                                <th colSpan="2" className="bg-success text-center ">
                                    Unpack Return
                                </th>
                                <th colSpan="2" className="bg-danger text-center">
                                    Pack Return
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>#</td>
                                <td>Total</td>
                                <td>Collected by SR</td>
                                <td>No. of SKUs</td>
                                <td>Collected by SR</td>
                                <td>No. of Orders</td>
                                <td>Collected by SR</td>
                            </tr>
                            <tr>
                                <td>{orderSummary?.total_order}</td>
                                <td>{orderSummary?.total}</td>
                                <td>{orderSummary?.collected_by_sr}</td>
                                <td>{orderSummary?.unpack_return?.no_of_skus}</td>
                                <td>{orderSummary?.unpack_return?.collected_by_sr}</td>
                                <td>{orderSummary?.pack_return?.no_of_orders}</td>
                                <td>{orderSummary?.pack_return?.collected_by_sr}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}