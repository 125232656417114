import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, getObjectToFormData, objectToFormData} from "../helper/utils";





export const getCategory = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/categories`, {
      headers: {}
    }).then(res => {
      console.log(res)
      resolve(res);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getCategoryDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/categories/` + id, {
      headers: {}
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const deleteCategory = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/categories/${id}`, {
      headers: {}
    })
      .then(res => {
        resolve(res.data.message)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}