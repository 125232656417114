import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import React, {Component} from 'react';
import App from "../../App";
import {withRouter} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Customers from "./Customers"
import Partners from "./Partners"
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { getFormattedDate } from "../../helper/utils";

export const KEYS = {
    CUSTOMER: 1,
    PARTNER: 0
}

export const TABLES = {
    ORDER_SUMMARY: 'ORDER_SUMMARY',
    ORDER_LIST: 'ORDER_LIST',
    RETURN_REQUEST: 'RETURN_REQUEST',
    CUSTOME_ORDER: 'CUSTOME_ORDER'
}

class List extends Component {
    constructor() {
        super();
        var startDate = localStorage.getItem("reconciliation_st_d") ? moment(localStorage.getItem("reconciliation_st_d")): moment().subtract(29, 'days');
        var today = localStorage.getItem("reconciliation_ed_d") ? moment(localStorage.getItem("reconciliation_ed_d")): moment();

        var seletedTab = localStorage.getItem("return_req_orders_tab") ? parseInt(localStorage.getItem("return_req_orders_tab")) : KEYS.CUSTOMER;
        this.state = {
            warehouse_info: null,
            start_date_time: startDate,
            end_date_time: today,
            query_params: { start_date_time:  getFormattedDate(startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(today, "YYYY-MM-DD") },
            selectedTab: seletedTab
        };
    }

    componentWillUnmount() {
        this.setState({})
    }

    getQueryParams = () => {
        let params = Object.assign(this.state.query_params);
        return params;
    }

    handleOnApply = (e, picker)=>{
        this.setState({start_date_time: getFormattedDate(picker.startDate,''), end_date_time: getFormattedDate(picker.endDate,'')})
        this.setState({query_params: {...this.state.query_params, start_date_time: getFormattedDate(picker.startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(picker.endDate, "YYYY-MM-DD")}})
    }

    onTabChange = (key) => {
        localStorage.setItem("return_req_orders_tab", key)
        this.setState({selectedTab: key})
    }

    render() {
        return (
            <App layout="">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Return Request List</h2>
                    </div>
                    <div className="card-block">
                        <div className="block-body">
                            {/* <DateRangeFilter start_date_time={this.state.start_date_time} end_date_time={this.state.end_date_time} handleOnApply={(e, picker) => this.handleOnApply(e, picker)} filterList={this.handleOnFilter}/> */}
                            <div className="pb-4">
                                <div className="d-inline-block">
                                    <DateRangePicker
                                        initialSettings={{
                                            startDate: this.state.start_date_time,
                                            endDate: this.state.end_date_time,
                                            maxSpan: {"months": 3},
                                            showDropdowns: true,
                                            locale: {format: "DD/MM/YYYY"},
                                            autoApply: true,
                                        }}
                                        onApply={(e, picker) => this.handleOnApply(e, picker)}
                                    >

                                        <input type="text"
                                            value={this.state.start_date_time && this.state.end_date_time ? `${getFormattedDate(this.state.start_date_time, "DD/MM/YYYY")} - ${getFormattedDate(this.state.end_date_time, "DD/MM/YYYY")}` : ''}
                                            style={{textAlign: 'left', minWidth: '150px'}}
                                            placeholder={"Select date range"}
                                            className="form-control form-control-sm"/>
                                    </DateRangePicker>
                                </div>
                            </div>
                            <Tabs selectedIndex={this.state.selectedTab} onSelect={this.onTabChange}>
                                <TabList>
                                    <Tab eventKey={KEYS.PARTNER}>Return from Partners</Tab>
                                    <Tab eventKey={KEYS.CUSTOMER}>Return from Customers</Tab>
                                </TabList>

                                <TabPanel>
                                    <Partners queryParams={this.state.query_params} />
                                </TabPanel>
                                <TabPanel>
                                    <Customers queryParams={this.state.query_params} />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>

                <ToastsContainer store={ToastsStore}/>
            </App>
        );
    }
}

export default withRouter(List);
