import React, {Component} from 'react';
import App from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEdit, FiPlus } from "react-icons/all";
import Pagination from 'react-js-pagination';
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {toast} from 'react-toastify';
import {getAttributeList, deleteAttribute} from "../../services/attributeServices";
import {getList, getPaginatedList} from '../../services/baseServices'

class List extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      formData: {image: ''},
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
      activePage: 1,
      per_page: 10,
      page: 1,
      counter: 1
    };
  }

  componentDidMount() {
    getPaginatedList('attribute_sets',{per_page: this.state.per_page, page: this.state.page})
      .then(res => {
        if(res.status === 200) {
          this.setState({items: res.data.data,
            per_page: res.headers['x-per-page'],
            total_data: res.headers['x-total'],
          })
        }
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })
  }
  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber, isLoading: true}, () => {
      getPaginatedList('attribute_sets',{per_page: this.state.per_page, page: this.state.page})
        .then(res => {
          this.setState({
            items: res.data.data,
            isLoading: false,
            per_page: res.headers['x-per-page'],
            total_data: res.headers['x-total'],
          }, () => {
            console.log(this.state.productAttrsOption);
          });
        })
        .catch(errMsg => {
          console.log('Error Message: ', errMsg)
        })
    });
  }
  handleDeleteAttribute = (id) => {
    deleteAttribute(id)
      .then(res => {
        if(res.status_code === 200) {
          toast.success('Successfully deleted');
          getAttributeList({per_page: this.state.per_page, page: this.state.page})
            .then(res => {
              this.setState({
                items: res.data.daya,
                isLoading: false,
                counter: this.state.activePage - 1 > 0 ? ((this.state.activePage - 1) * this.state.per_page) + 1 : 1,  
              })
            })
            .catch(errMsg => {
              console.log('Err Msg: ', errMsg)
            })
        }
        else {
          toast.error(res.message);
        }
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  render() {
    console.log('items', this.state.items)
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Attribute Set List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/attributeSet/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add
                AttributeSet</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">
              <table className="order-table table table-striped">
                  <thead>
                    <tr>
                        <th style={{width:'20px'}}>#</th>
                        <th>Name</th>
                        <th>Values</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                <tbody>

                {this.state.items?.length > 0 ? this.state?.items?.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                      <td>
                        <div className="order-item">
                          {item.title}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item?.product_attributes && item?.product_attributes.length > 0 ?
                            item?.product_attributes?.map((value,i)=>{
                              return (
                                <span key={i}>
                                  {value.name + ','}
                                </span>
                              );
                            })
                            : ""}
                        </div>
                      </td>
                      <td style={{width: '150px'}}>
                        <div className="btn-group">
                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>
                                Edit
                              </Tooltip>
                            }
                          >
                          <Link to={`${process.env.PUBLIC_URL}/attributeSet/edit/${item.id}`}
                            className="btn btn-sm btn-default"><FiEdit/></Link>
                          </OverlayTrigger>
                            {/* <OverlayTrigger
                              key="view"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-view`}>
                                  Delete
                                </Tooltip>
                              }
                            >
                            <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() =>this.handleDeleteAttribute(item.id)}/>
                          </OverlayTrigger> */}
                        </div>
                      </td>
                    </tr>
                  );
                }) : ""}

                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
