import React, { Component } from "react";
import App from "../../App";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEye, FiEdit, FiPlus } from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { toast } from "react-toastify";
import {
  destroy,
  getList,
  getPaginatedList,
} from "../../services/baseServices";
import ConfirmationModal from "../common/Modal";
import Loader from "react-loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getFormattedDate, getWarehouseType } from "../../helper/utils";
import ExportCSVAsync from "../common/ExportCSVAsync";
import moment from "moment";

const wareHouseType = getWarehouseType();

const headers = [
  { label: "Outlet Name", key: "name" },
  { label: "Partner Code", key: "partner_code" },
  { label: "Phone", key: "phone" },
  { label: "Returns", key: "returns" },
  { label: "Total Orders", key: "total_orders" },
  { label: "Total Amount", key: "total_amount" },
  { label: "Collected", key: "collected" },
  { label: "Due", key: "due_payment" },
  { label: "Region Name", key: "region_name" },
  { label: "Distributor", key: "distributor_name" },
];

const loadData = (item) => {
  return {
    name: item.name,
    partner_code: item.partner_code,
    phone: item.phone,
    returns: item.returns,
    total_orders: item.total_orders,
    total_amount: item.total_amount,
    collected: item.collected,
    due_payment: item.due_payment,
    region_name: item.region_name,
    distributor_name: item.distributor_name,
    credit_limit: item.credit_limit,
  };
};

var startDate = moment().subtract(29, "days");
var today = moment();

class List extends Component {
  constructor() {
    super();
    this.state = {
      partners: [],
      routes: [],
      activePage: 1,
      per_page: 25,
      page: 1,
      counter: 1,
      temp_params: {
        status: "delivered_to_partner",
        start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
      },
      query_params: {
        status: "delivered_to_partner",
        start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
      },
      isExporting: false,
      start_date_time: startDate,
      end_date_time: today,
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getPaginatedPartners = (params) => {
    this.setState({ ...this.state, isLoading: true });
    getPaginatedList("partners", params)
      .then((res) => {
        this.setState(
          {
            partners: res.data,
            isLoading: false,
            per_page: res?.headers["x-per-page"] || 25,
            total_data: res.headers["x-total"],
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        this.setState({ ...this.state, isLoading: false });
        console.log("Error Message: ", errMsg);
      });
  };

  componentDidMount() {
    this.getPaginatedPartners(this.getQueryParams());
    getList("routes")
      .then((res) => {
        this.setState({ routes: res });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    console.log(tempParam);
    this.setState({ ...this.state, page: 1, query_params: tempParam }, () => {
      this.getPaginatedPartners(this.state.temp_params);
    });
  };

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {});
  };

  handleDeletePartner = (id) => {
    destroy("partners", id)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success("Successfully deleted");
          this.getPaginatedPartners(this.getQueryParams());
        } else {
          toast.error(res?.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedPartners(this.getQueryParams());
    });
  };

  handleOnApply = (e, picker) => {
    this.setState({
      start_date_time: getFormattedDate(picker.startDate, ""),
      end_date_time: getFormattedDate(picker.endDate, ""),
    });
    this.setState({
      temp_params: {
        ...this.state.temp_params,
        start_date_time: getFormattedDate(picker.startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(picker.endDate, "YYYY-MM-DD"),
      },
    });
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Partner List</h2>
            <div className="ml-auto" style={{ display: "flex" }}>
              <ExportCSVAsync
                title="Partner List"
                fileName={
                  "Partners-" +
                  getFormattedDate(new Date(), "DD-MM-YYYY") +
                  ".csv"
                }
                Url="partners/export"
                headers={headers}
                params={this.state.query_params}
                loadData={loadData}
              />
              {wareHouseType !== "central" && (
                <Link
                  to={`${process.env.PUBLIC_URL}/partner/add`}
                  className="btn btn-sm btn-secondary ml-2"
                >
                  <FiPlus /> Add Partner
                </Link>
              )}
            </div>
          </div>
          <form className="form-inline mb-3">
            <div className="form-group mr-3">
              <label htmlFor="status" className="sr-only">
                Date Range
              </label>
              <DateRangePicker
                onApply={(e, picker) => this.handleOnApply(e, picker)}
                initialSettings={{
                  startDate: this.state.start_date_time,
                  endDate: this.state.end_date_time,
                  maxSpan: { months: 3 },
                  showDropdowns: true,
                  locale: { format: "DD/MM/YYYY" },
                  autoApply: true,
                }}
              >
                <input
                  type="text"
                  value={
                    this.state.start_date_time && this.state.end_date_time
                      ? `${getFormattedDate(
                          this.state.start_date_time,
                          "DD/MM/YYYY"
                        )} - ${getFormattedDate(
                          this.state.end_date_time,
                          "DD/MM/YYYY"
                        )}`
                      : ""
                  }
                  style={{ textAlign: "left", minWidth: "150px" }}
                  placeholder={"Select date range"}
                  className="form-control form-control-sm"
                />
              </DateRangePicker>
            </div>
            <div className="form-group mr-3">
              <label htmlFor="status" className="sr-only">
                Shipping Date
              </label>
              <select
                id="status"
                name="status"
                className="form-control form-control-sm"
                onChange={this.handleOnFieldChange}
              >
                <option value="delivered_to_partner">At Partner outlet</option>
                <option value="completed">Order Completed</option>
              </select>
            </div>
            <div className="form-group mr-3">
              <label htmlFor="partner_code" className="sr-only">
                Partner Code
              </label>
              <input
                type="text"
                name="partner_code"
                id="partner_code"
                onChange={this.handleOnFieldChange}
                className="form-control form-control-sm"
                placeholder="Search by Partner Code"
              />
            </div>

            <div className="form-group mr-3">
              <label htmlFor="phone" className="sr-only">
                Phone Number
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                onChange={this.handleOnFieldChange}
                className="form-control form-control-sm"
                placeholder="Search by Phone Number"
              />
            </div>

            <div className="form-group mr-3">
              <label htmlFor="category" className="sr-only">
              Delivery Man
              </label>
              <select
                id="category"
                name="route_id"
                className="form-control form-control-sm"
                onChange={this.handleOnFieldChange}
              >
                <option key={0} value="">
                  --Select Route--
                </option>
                {this.state.routes?.length > 0
                  ? this.state.routes?.map((item, i) => {
                      return (
                        <option key={i + 1} value={item.id}>
                          {item.title}
                        </option>
                      );
                    })
                  : ""}
              </select>
            </div>

            <Button
              type={`submit`}
              onClick={this.handleOnSearch}
              size="sm"
              variant="primary"
            >
              Search
            </Button>
          </form>

          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body" style={{ overflowX: "scroll" }}>
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      <th>Outlet Name</th>
                      <th>Distributor</th>
                      <th>Partner Code</th>
                      <th>Phone</th>
                      <th>Returns*</th>
                      <th>Total Orders</th>
                      <th>Total Amount</th>
                      <th>Collected</th>
                      <th>Due</th>
                      <th>Credit Applicable</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.partners?.length > 0 ? (
                      this.state.partners?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {(this.state?.page - 1) * this.state?.per_page +
                                index +
                                1}
                            </td>
                            <td>
                              <div className="order-item">{item?.name}</div>
                            </td>
                            <td>
                              <div className="order-item">
                                {item?.distributor_name}
                              </div>
                            </td>
                            <td>
                              <div className="order-item">
                                {item?.partner_code}
                              </div>
                            </td>
                            <td>
                              <div className="order-item">{item?.phone}</div>
                            </td>
                            <td>{item?.returns}</td>
                            <td>{item?.total_orders}</td>
                            <td>{item?.total_amount}</td>
                            <td>{item?.collected}</td>
                            <td>
                              <div className="order-item">
                                {item?.due_payment}
                              </div>
                            </td>
                            <td>
                              <div className="order-item">
                                {item?.is_credit_applicable ? "YES" : "NO"}
                              </div>
                            </td>

                            <td style={{ width: "150px" }}>
                              <div className="btn-group">
                                <OverlayTrigger
                                  key={index}
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>View</Tooltip>
                                  }
                                >
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/partner/details/${item.id}`}
                                    className="btn btn-sm btn-default"
                                  >
                                    <FiEye />
                                  </Link>
                                </OverlayTrigger>

                                {wareHouseType !== "central" && (
                                  <>
                                    <OverlayTrigger
                                      key={index + 1}
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          Edit
                                        </Tooltip>
                                      }
                                    >
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/partner/edit/${item.id}`}
                                        className="btn btn-sm btn-default"
                                      >
                                        <FiEdit />
                                      </Link>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      key={index + 2}
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <ConfirmationModal
                                        title="Delete"
                                        body="Are you sure you want to delete?"
                                        handleAction={() =>
                                          this.handleDeletePartner(item.id)
                                        }
                                      />
                                    </OverlayTrigger>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>No partners found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-3">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.activePage}
                  itemsCountPerPage={parseInt(this.state.per_page)}
                  totalItemsCount={parseInt(this.state.total_data)}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </Loader>
        </div>
        <ToastsContainer store={ToastsStore} />
      </App>
    );
  }
}

export default withRouter(List);
