import React, { Component } from "react";
import App from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEye, FiEdit, FiPlus } from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { toast } from 'react-toastify';
import ConfirmationModal from "../common/Modal";
import { getPaginatedList, destroy2 } from "../../services/baseServices";
import Loader from "react-loader";
import { getFormattedDate, humanize } from "../../helper/utils";

class List extends Component {
  constructor() {
    super();
    this.state = {
      promoCoupons: [],
      activePage: 1,
      per_page: 25,
      page: 1,
      isLoading: false,
      query_params: {},
      show:false,
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getPaginatedCoupons = (params) => {
    this.setState({ ...this.state, isLoading: true });
    getPaginatedList("coupons", params)
      .then((res) => {
        this.setState(
          {
            promoCoupons: res.data.data,
            isLoading: false,
            per_page: parseInt(res.headers["x-per-page"]),
            total_data: parseInt(res.headers["x-total"]),
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        this.setState({ ...this.state, isLoading: false });
        console.log("Error Message: ", errMsg);
      });
  };

  componentDidMount() {
    this.getPaginatedCoupons(this.getQueryParams());
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedCoupons(this.getQueryParams());
    });
  };



  handleDeleteAction = (id) => {
    destroy2(`coupons/${id}`)
    .then(res => {
        if(res.success){
            toast.success(res.message);
            this.getPaginatedCoupons(this.getQueryParams());
        } else {
            toast.error(res?.message)
        }
    })
    .catch(errMsg => {
        toast.error(errMsg)
    })
  }


  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Promo Coupon List</h2>
            <div className="ml-auto">
              <Link
                to={`/promo-coupon/add`}
                className="btn btn-sm btn-secondary">
                <FiPlus /> Add Promo Coupon
              </Link>
            </div>
          </div>
          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body">
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Coupon</th>
                      <th>Type</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.promoCoupons?.length > 0
                      ? this.state.promoCoupons?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {(this.state.page - 1) * this.state.per_page +
                                  index +
                                  1}
                              </td>
                              <td>{item.coupon_code}</td>
                              <td>{humanize(item.coupon_type)}</td>
                              <td>{getFormattedDate(item.start_at, 'DD/MM/YYYY HH:mm')}</td>
                              <td>{getFormattedDate(item.end_at, 'DD/MM/YYYY HH:mm')}</td>
                              <td>{item.is_active ? "Active" : "Inactive"}</td>
                              <td style={{ width: "150px" }}>
                                <div className="btn-group">
                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        View
                                      </Tooltip>
                                    }>
                                    <Link
                                      to={`/promo-coupon/details/${item.id}`}
                                      className="btn btn-sm btn-default">
                                      <FiEye />
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    key="edit"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-view`}>
                                        Edit
                                      </Tooltip>
                                    }>
                                    <Link
                                      to={`/promo-coupon/edit/${item.id}`}
                                      className="btn btn-sm btn-default">
                                      <FiEdit />
                                    </Link>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                      <Tooltip id={`tooltip-view`}>Delete</Tooltip>
                                      }
                                  >
                                  <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() =>this.handleDeleteAction(item.id)}/>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : <tr className="text-center"><td colSpan="8"><b>No result found</b></td></tr>}
                  </tbody>
                </table>
              </div>
              <div className="mt-3">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={this.state.total_data}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </Loader>
        </div>
        
        <ToastsContainer store={ToastsStore} />
      </App>
    );
  }
}

export default withRouter(List);
