import React, { useEffect, useState } from 'react';
import App from "../../App";
import { Link, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getDetails } from '../../services/baseServices'

const FILTER_OPTIONS = [{ key: 'category', value: 'Category' }, { key: 'product_attribute', value: 'Product Attribute' }, { key: 'price_range', value: 'Price Range' }, { key: 'product_type', value: 'Product Type' }, { key: 'keyword', value: 'Keyword' }]

const Details = () => {
  const [brand, setBrand] = useState({})
  const { id } = useParams()
  const [metaInfo, setMetaInfo] = useState({})

  useEffect(() => {
    getDetails('brands', id)
      .then(res => {
        setBrand(res.data)
        setMetaInfo(res.data?.meta_info)
      })
  }, [id])

  return (
    <App layout={`boxed`}>
      <div className="main-content">
        <div className="page-header">
          <div className="page-title">Brand Details</div>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/brands/list`}
              className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to Brand List</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Brand Basic Info</h4>
          </div>
          <div className="block-body">
            <>
              <table className="info-table">
                <tbody>
                  <tr>
                    <td style={{ width: '250px' }}>Created By</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td className={`text-bold`}>{brand?.created_by?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Slug</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td className={`text-bold`}>{brand?.slug}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Brand Name</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td className={`text-bold`}>{brand?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Brand Name in Bangla</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td>{brand?.bn_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Type</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td>{brand?.is_own_brand ? "Own Brand" : "Others"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Public Visibility</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td>{brand?.public_visibility ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Home Page Visibility</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td>{brand?.homepage_visibility ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}> Redirect URL</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td>{brand?.redirect_url}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Banners</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td>
                      {
                        brand?.banners?.map((item, i) => {
                          return (
                            <>
                              <img key={i} src={item?.url} width={"100%"} height={"200"} alt="" style={{ marginBottom: '2px' }} />
                              <br />
                            </>
                          )
                        })
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '250px' }}>Logo</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td>
                      <img src={`${brand?.logo_file}`} width={"100"} height={"100"} alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          </div>
        </div>

        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Branding Info</h4>
          </div>

          <div className="block-body">
            <table className="info-table">
              <tbody>
                <tr>
                  <td style={{ width: '250px' }}>Show Promotion</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.brand_info_visible ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Layout</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.branding_layout}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Promotion With</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.branding_promotion_with}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Video Url</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.branding_video_url}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Image</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{ }
                    <img src={`${brand.branding_image_file}`} width={"300px"} height={"auto"} alt="" />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Title</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>
                    <td className={`text-bold`}>{brand?.branding_title}</td>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Title in Bangla</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.branding_title_bn}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Sub title</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.branding_subtitle}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Branding Sub title in Bangla</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.branding_subtitle_bn}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Short Description</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td dangerouslySetInnerHTML={{ __html: brand?.short_description }}></td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Short Description in Bangla</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td dangerouslySetInnerHTML={{ __html: brand?.short_description_bn }}></td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Button Text</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.more_info_button_text}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Button Text in Bangla</td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.more_info_button_text_bn}</td>
                </tr>
                <tr>
                  <td style={{ width: '250px' }}>Button Text URL </td>
                  <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                  <td className={`text-bold`}>{brand?.more_info_url}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {
          brand?.campaigns_attributes?.length > 0 && <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Branding Campaign Slider</h4>
            </div>

            <div className="block-body">
              <table className="info-table">
                <tbody>
                  <tr>
                    <td style={{ width: '250px' }}>Branding Campaign Slider Attributes</td>
                    <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                    <td >
                      <table className="table table-responsive">
                        <thead >
                          <th style={{ width: '200px' }}>Title</th>
                          <th style={{ width: '200px' }} scope="col-4">Title in Bangla</th>
                          <th style={{ width: '200px' }} scope="col-4">Page Url</th>
                        </thead>
                        <tbody>
                          {
                            brand?.campaigns_attributes.map(item => (
                              <tr>
                                <th>{item?.title}</th>
                                <th>{item?.title_bn}</th>
                                <th>{item?.page_url}</th>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        }



        {
          brand?.filtering_options_attributes?.length > 0 && <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Branding Filter Attributes</h4>
            </div>
            <div className="block-body">
              <table className="info-table">
                <tbody>
                  {
                    brand.filtering_options_attributes.map(item => (
                      item.filtering_type === 'keyword' || item.filtering_type === 'price_range' ? <tr>
                        <td style={{ width: '250px' }}>{FILTER_OPTIONS.find(attr => attr.key === item.filtering_type).value}</td>
                        <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                        <td className={`text-bold`}>{item.filtering_type === 'price_range' ? "Enabled" : item.filtering_keys.join(', ')}</td>
                      </tr> :
                        <tr>
                          <td style={{ width: '250px' }}>{FILTER_OPTIONS.find(attr => attr.key === item.filtering_type).value}</td>
                          <td style={{ width: '20px', textAlign: 'center' }}>:</td>
                          <td className={`text-bold`}>{item.filtering_keys.map(item => item.key_name).join(', ')}</td>
                        </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>

      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title">Meta Info</h4>
        </div>
        <div className="block-body">
          <table className="info-table">
            <tbody>
              <tr>
                <td style={{ width: "200px" }}>Meta Title</td>
                <td style={{ width: "20px", textAlign: "center" }}>
                  :
                </td>
                <td>{metaInfo?.meta_title}</td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>Meta Title in Bangla</td>
                <td style={{ width: "20px", textAlign: "center" }}>
                  :
                </td>
                <td>{metaInfo?.bn_meta_title}</td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>Meta Description</td>
                <td style={{ width: "20px", textAlign: "center" }}>
                  :
                </td>
                <td>{metaInfo?.meta_description}</td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>Meta Description in Bangla</td>
                <td style={{ width: "20px", textAlign: "center" }}>
                  :
                </td>
                <td>{metaInfo?.bn_meta_description}</td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>Meta Keywords</td>
                <td style={{ width: "20px", textAlign: "center" }}>
                  :
                </td>
                <td>{
                  metaInfo?.meta_keyword?.map((item, index) => (
                    <span key={index}>{item}, </span>
                  ))
                }</td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>Meta Keywords in Bangla</td>
                <td style={{ width: "20px", textAlign: "center" }}>
                  :
                </td>
                <td>{
                  metaInfo?.bn_meta_keyword?.map((item, index) => (
                    <span key={index}>{item}, </span>
                  ))
                }</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </App>
  )
}

export default Details
