import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, getObjectToFormData} from "../helper/utils";

export const getPaginatedList = (params, url) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + url, {
      params,
      headers: {}
    }).then(res => {
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res)
      }
    }).catch(err => {
      reject(err.message)
    })
  })
}
