import React, { Component } from 'react';
import { FaLongArrowAltLeft } from 'react-icons/all';
import { Link } from 'react-router-dom';
import App from './../../App';
import { getData } from './../../services/baseServices';
import { withRouter } from 'react-router-dom';

class RequestDetails extends Component {
    constructor() {
        super();
        this.state = {
            requestDetails: {},
            isLoading: true
        }
      };

      componentWillMount() {
        this.getRequestDetails();
        
      }

      getRequestDetails = () =>{
        getData("requested_variants/details", {
			variant_id: this.props.match.params.id
		})
        .then((res) => {
          if(res.success){
            this.setState({ requestDetails: res.data}, ()=>{
              this.setState({isLoading: false})
            });
          }else{
            this.setState({isLoading: false})
          }
        })
        .catch((errMsg) => {
          this.setState({isLoading: false})
          console.log("Error Message: ", errMsg);
        });
      }

    render() {
		const {requestDetails} = this.state
        return (
			<App layout="">
				<div className="main-content">
					<div className="page-header">
						<h2 className="page-title">Request Details</h2>
						<div className="ml-auto">
						<Link to={`${process.env.PUBLIC_URL}/product-request/list`}
								className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
						</div>
					</div>

					{
						this.state.isLoading ?
						<div className={`card-block`}>
							<div className="block-body">
							Loading...
							</div>
						</div>
						:
						<div className="card-block">
							<div className="block-header">
								<h4 className="block-title">Request Details</h4>
							</div>
							{this.state.isLoading ? (
								<div className={`card-block`}>
									<div className="block-body">Loading...</div>
								</div>
							) : (
								<>
									<div className="block-body">
										<table className="info-table">
											<tbody>
												<tr>
													<td style={{ width: "250px" }}>Variant Id</td>
													<td style={{ width: "20px", textAlign: "center" }}>
														:
													</td>
													<td>{requestDetails.variant_id}</td>
												</tr>
												<tr>
													<td style={{ width: "250px" }}>
														Product Title
													</td>
													<td style={{ width: "20px", textAlign: "center" }}>
														:
													</td>
													<td>{requestDetails.product_title}</td>
												</tr>
												<tr>
													<td style={{ width: "250px" }}>Sku</td>
													<td style={{ width: "20px", textAlign: "center" }}>
														:
													</td>
													<td>{requestDetails.sku}</td>
												</tr>
												<tr>
													<td style={{ width: "250px" }}>Total Requested</td>
													<td style={{ width: "20px", textAlign: "center" }}>
														:
													</td>
													<td>{requestDetails.total_request_count}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="block-header">
										<h4 className="block-title">User Details</h4>
									</div>
									<div className="block-body">
										<table className="order-table table table-striped">
											<thead>
												<tr>
													<td>#</td>
													<td>Warehouse</td>
													<td>Customer</td>
													<td>Mobile</td>
													<td>Email</td>
												</tr>
											</thead>
											<tbody>
												{
													requestDetails?.user_details?.map((item,i) =>{
														return(
															<tr key={i}>
																<td>{i+1}</td>
																<td className="order-item">{item.warehouse}</td>
																<td className="order-item">{item.customer_name}</td>
																<td className="order-item">{item.mobile_number}</td>
																<td className="order-item">{item.email}</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</div>
								</>
							)}
						</div>	
					}
			</div>
		</App>
        );
    }
}

export default withRouter(RequestDetails);