import React, { Component } from "react";
import App from "../../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import { getDetails, putData } from "../../../services/baseServices";
import { getLayoutString } from "./List";
import { Divider } from "antd";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "react-loader";
class Details extends Component {
  constructor() {
    super();
    this.state = {
      promotionalSliderInfo: {},
      loading: false,
    };
  }

  getPromoDetails = () => {
    this.setState({ loading: true });
    getDetails("promo_banners", this.props.match.params.id)
      .then((res) => {
        if (res?.success) {
          this.setState({
            promotionalSliderInfo: res?.data,
            loading: false,
          });
        } else {
          console.log(res?.message);
        }
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  };

  componentDidMount() {
    this.getPromoDetails();
  }

  updatePromoSlider = (publish) => {
    putData(`promo_banners/${this.props.match.params.id}}`, {
      is_visible: publish,
    })
      .then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          this.getPromoDetails();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleActiveBtn = () => {
    this.updatePromoSlider(true);
  };

  handleInactiveBtn = () => {
    this.updatePromoSlider(false);
  };

  render() {
    const { promotionalSliderInfo } = this.state;
    return (
      <App layout={`boxed`}>
        <div className="main-content">
          <div className="page-header">
            <div className="page-title" />
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/promotional-slider/list`}
                className={`btn btn-sm btn-link`}
              >
                <MdArrowBack /> Back to List
              </Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Promotional Slider Details</h4>
            </div>
            <div className="block-body">
              <Loader loaded={!this.state.loading}>
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}> Id</td>
                      <td style={{ width: "50px", textAlign: "center" }}>:</td>
                      <td>{promotionalSliderInfo?.id}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Promotion Title</td>
                      <td style={{ width: "50px", textAlign: "center" }}>:</td>
                      <td>{promotionalSliderInfo?.title}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Layout</td>
                      <td style={{ width: "50px", textAlign: "center" }}>:</td>
                      <td>{getLayoutString(promotionalSliderInfo?.layout)}</td>
                    </tr>{" "}
                    <tr>
                      <td style={{ width: "250px" }}>Publish</td>
                      <td style={{ width: "50px", textAlign: "center" }}>:</td>
                      <td
                        className=" d-flex justify-content-between"
                        style={{ width: "400px" }}
                      >
                        <div>
                          {promotionalSliderInfo?.is_visible ? "Yes" : "No"}
                        </div>
                        <div className="ml-5 promoBannerPublishBtn">
                          {promotionalSliderInfo?.is_visible ? (
                            <Button
                              className="btn-secondary"
                              onClick={this.handleInactiveBtn}
                            >
                              Inactive
                            </Button>
                          ) : (
                            <Button onClick={this.handleActiveBtn}>
                              Active
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Divider>
                  <b>For Web</b>
                </Divider>
                <table className="info-table">
                  <tbody>
                    {promotionalSliderInfo?.web_images?.length > 0 &&
                      promotionalSliderInfo?.web_images?.map(
                        (web_banner, i) => (
                          <tbody key={i}>
                            <tr>
                              <td style={{ width: "250px" }}>Banner Title</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td className="text-break">
                                {web_banner?.image_title}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "250px" }}>Description</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td className="text-break">
                                {web_banner?.description}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "250px" }}>Image</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td>
                                <img
                                  src={web_banner?.image_url}
                                  alt={web_banner?.image_title}
                                  width={300}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "250px" }}>Redirect Url</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td className="text-break">
                                {web_banner?.redirect_url}
                              </td>
                            </tr>
                            <tr>
                              <td className="mb-5"></td>
                            </tr>
                            {promotionalSliderInfo?.web_images?.length > 1 &&
                              i + 1 <
                                promotionalSliderInfo?.web_images?.length && (
                                <tr>
                                  <td></td>
                                  <td className="mb-5 " colSpan={2}>
                                    <Divider />
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        )
                      )}
                  </tbody>
                </table>

                <Divider>
                  <b>For App</b>
                </Divider>
                <table className="info-table">
                  <tbody>
                    {promotionalSliderInfo?.app_images?.length > 0 &&
                      promotionalSliderInfo?.app_images?.map(
                        (app_banner, i) => (
                          <tbody key={i}>
                            <tr>
                              <td style={{ width: "250px" }}>Banner Title</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td className="text-break">
                                {app_banner?.image_title}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "250px" }}>Description</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td className="text-break">
                                {app_banner?.description}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "250px" }}>Image</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td>
                                <img
                                  src={app_banner?.image_url}
                                  alt={app_banner?.image_title}
                                  width={300}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td style={{ width: "250px" }}>Redirect Url</td>
                              <td
                                style={{ width: "50px", textAlign: "center" }}
                              >
                                :
                              </td>
                              <td className="text-break">
                                {app_banner?.redirect_url}
                              </td>
                            </tr>
                            {promotionalSliderInfo?.app_images?.length > 1 &&
                              i + 1 <
                                promotionalSliderInfo?.app_images?.length && (
                                <tr>
                                  <td></td>
                                  <td className="mb-5 " colSpan={2}>
                                    <Divider />
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        )
                      )}
                  </tbody>
                </table>
              </Loader>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);
