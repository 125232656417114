import React, { Component } from 'react';
import App from "../../App";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FiEye, FiEdit, FiPlus } from "react-icons/all";
import {IoMdNotificationsOutline} from "react-icons/io"
import  Pagination from 'react-js-pagination';
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { getPaginatedList } from "../../services/baseServices";
import Loader from 'react-loader';
import SendPromotionModal from './SendPromotionModal'

class List extends Component {
  constructor() {
    super();
    this.state = {
      promotions: [],
      activePage: 1,
      per_page : 25,
      page: 1,
      isLoading: false,
      query_params: {},
      show:false,
      promotionId: 0
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
    return params;
  }

  getPaginatedPromotions = (params) => {
    this.setState({...this.state, isLoading: true});
    getPaginatedList("promotions",params)
      .then(res => {
        this.setState({
          promotions: res.data, 
          isLoading: false,
          per_page: parseInt(res.headers['x-per-page']),
          total_data: parseInt(res.headers['x-total']),
        }, () => {
        });
      })
      .catch(errMsg => {
        this.setState({...this.state, isLoading: false});
        console.log('Error Message: ', errMsg)
      })
  }

  componentDidMount() {
    this.getPaginatedPromotions(this.getQueryParams());
  }

  handlePageChange = (pageNumber) => {
    this.setState({page: pageNumber, activePage: pageNumber}, () => {
      this.getPaginatedPromotions(this.getQueryParams());
    });
  }

  handleShowHide = () => {
    this.setState({show:!this.state.show})
  }

  handleSendNotification = id =>{
    this.setState({promotionId:id})
    this.handleShowHide()
  }
  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Promotion List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/promotions/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Promotion</Link>
            </div>
          </div>
          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body">
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Promo Category</th>
                      {/* <th>Warehouse</th> */}
                      <th>From</th>
                      <th>To</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.promotions?.length > 0 ? this.state.promotions?.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.promotion_category}</td>
                          {/* <td>{item.warehouse_name}</td> */}
                          <td>{item.from_date}</td>
                          <td>{item.to_date}</td>
                          <td>{item.is_active?"Active":"Inactive"}</td>
                          <td style={{width: '150px'}}>
                            <div className="btn-group">
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    View
                                  </Tooltip>
                                }
                              >
                                <Link to={`${process.env.PUBLIC_URL}/promotions/details/${item.id}`}
                                      className="btn btn-sm btn-default"><FiEye/></Link>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="edit"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Edit
                                  </Tooltip>
                                }
                              >
                                <Link to={`${process.env.PUBLIC_URL}/promotions/edit/${item.id}`}
                                  className="btn btn-sm btn-default"><FiEdit/></Link>
                              </OverlayTrigger>
                              <Button className='btn btn-sm btn-default' onClick={() => this.handleSendNotification(item.id)}>
                                <IoMdNotificationsOutline color='#334155'/>
                              </Button>
                            </div>
                          </td>
                        </tr>
                    );
                  }) : ""}
                  </tbody>
                </table>
              </div>
              <div className="mt-3">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={this.state.total_data}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </Loader>
          
        </div>
        {
          this.state.promotionId && (
              <SendPromotionModal
              show={this.state.show}
              handleClose={this.handleShowHide}
              promotionId={this.state.promotionId}
            />
          )
        }
    
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
