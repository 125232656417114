import React, { Component } from "react";
import App from "../../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEdit, FiPlus, FiEye } from "react-icons/all";
import { withRouter, Link } from "react-router-dom";
import "../../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { toast } from "react-toastify";
import ConfirmationModal from "../../common/Modal";
import { destroy } from "../../../services/baseServices";
import { getPaginatedList } from "../../../services/baseServices";
import Pagination from "react-js-pagination";
import Loader from "react-loader";

export const getLayoutString = (layout) => {
  switch (layout) {
    case 1:
      return "Full";
    case 2:
      return "Half";
    case 3:
      return "One Third";
    default:
      return "";
  }
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      formData: { image: "" },
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
      activePage: 1,
      per_page: 25,
      page: 1,
      temp_params: {},
      query_params: {},
      loading: false,
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getData = (params) => {
    this.setState({ loading: true });
    getPaginatedList("promo_banners", params)
      .then((res) => {
        if (res?.data?.success) {
          this.setState({
            items: res?.data?.data,
            per_page: res.headers["x-per-page"],
            total_data: res.headers["x-total"],
            loading: false,
          });
        } else {
          console.log(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {});
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getData(this.getQueryParams());
    });
  };

  componentDidMount() {
    this.getData(this.getQueryParams());
  }

  handleDeleteSlider = (id) => {
    destroy("promo_banners", id)
      .then((res) => {
        if (res.success) {
          toast.success("Successfully deleted");
          this.getData(this.getQueryParams());
        } else {
          toast.error(res?.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Promotional Slider List</h2>
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/promotional-slider/add`}
                className="btn btn-sm btn-secondary"
              >
                <FiPlus /> Add Promotion Slider
              </Link>
            </div>
          </div>
          <div className="card-block">
            <div className="block-body">
              <Loader loaded={!this.state.loading}>
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>Id</th>
                      <th className="text-center">Title</th>
                      <th>Layout</th>
                      <th>Publish</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.length > 0 &&
                      this.state.items.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.id}</td>

                            <td>
                              <div className="order-item text-center">
                                {item?.title}
                              </div>
                            </td>
                            <td>{getLayoutString(item?.layout)}</td>
                            <td>{item?.is_visible ? "Yes" : "No"}</td>

                            <td style={{ width: "150px" }}>
                              <div className="btn-group">
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Details
                                    </Tooltip>
                                  }
                                >
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/promotional-slider/details/${item.id}`}
                                    className="btn btn-sm btn-default"
                                  >
                                    <FiEye />
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <ConfirmationModal
                                    title="Delete"
                                    body="Are you sure you want to delete?"
                                    handleAction={() =>
                                      this.handleDeleteSlider(item.id)
                                    }
                                  />
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </Loader>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </App>
    );
  }
}

export default withRouter(List);
