import { putDataV2 } from "../../services";
export const changePassword = (data, callback = () => null) => {
  return () => {
    putDataV2(`staffs/reset`, data)
      .then((res) => {
        if (res?.success) {
          callback(true, res?.message);
        } else {
          callback(false, res?.message);
        }
      })
      .catch((err) => {
        callback(false, "An server error occurred!");
      });
  };
};
