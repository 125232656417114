import React, { Component } from "react";
import App from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEdit, FiEye } from "react-icons/all";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { toast } from "react-toastify";
import { getList, destroy } from "../../services/baseServices";

class List extends Component {
  constructor() {
    super();
    this.state = {
      warehouses: [],
      formData: {},
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
    };
  }

  componentDidMount() {
    getList("warehouses")
      .then((res) => {
        this.setState({ warehouses: res });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  handleDeleteWarehouse = (id) => {
    destroy("warehouses", id)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success("Successfully deleted");
          getList("warehouses")
            .then((res) => {
              this.setState({ warehouses: res });
            })
            .catch((errMsg) => {
              console.log("Err Msg: ", errMsg);
            });
        } else {
          toast.error("err message");
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };
  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Distribution House List</h2>
            {/* <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/dwh/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Distribution House</Link>
            </div> */}
          </div>

          <div className="card-block">
            <div className="block-body">
              {/*<form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="searchInput" className="sr-only">Search Key</label>
                  <input type="search" name="search_string" id="orderNumber"
                         className='form-control form-control-sm' placeholder="Search Key"/>
                </div>
                <Button size="sm" variant="secondary">Search</Button>
              </form>*/}
              <table className="order-table table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th>Name</th>
                    <th>Name in Bangla</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.warehouses.length > 0
                    ? this.state.warehouses.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="order-item">{item.name}</div>
                            </td>
                            <td>
                              <div className="order-item">{item?.bn_name}</div>
                            </td>
                            <td>
                              <div className="order-item">{item?.email}</div>
                            </td>
                            <td>
                              <div className="order-item">{item?.phone}</div>
                            </td>
                            <td style={{ width: "150px" }}>
                              <div className="btn-group">
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Details
                                    </Tooltip>
                                  }
                                >
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/dwh/details/${item.id}`}
                                    className="btn btn-sm btn-default"
                                  >
                                    <FiEye />
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>Edit</Tooltip>
                                  }
                                >
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/dwh/edit/${item.id}`}
                                    className="btn btn-sm btn-default"
                                  >
                                    <FiEdit />
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(List);
