import React, {Component} from 'react';
import App from "../../App";
import {FaLongArrowAltLeft} from "react-icons/all";
import "../../assets/scss/invoice.scss";
import {withRouter, Link} from "react-router-dom";
import {Button, Form, Modal} from "react-bootstrap";
import {getDetails, getList, putData} from "../../services/baseServices";
import {toast} from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate, twoDecimalPlace, humanize} from "../../helper/utils";

class RRDetails extends Component {
    state = {
        requestDetails: {},
        returnDetails: {},
        customer: {},
        rider: {},
        isLoading: true,
        status: null,
        changingStoreId: null,
        changeStatusMsg: '',
        closeOrderModal: false,
        isSubmitting: false,
        ridersList: [],
        closeRiderModal: false,
        reshecduleModal: false,
        home_pick_up: false,
        return_option: "",
        params: {},
        startDate: null,
        partner_info: {},
        id: null,
        pick_up_type: null,
        receiver_info: {},
        refunded: null,
        return_orders: [],
        rider_info: {},
        route_info: {},
        customer_order_id: null,
        pick_address: {},
        warehouse_phone: null,
        distributor_name: null,
        sub_total: null,
        grand_total: null,
        vat_shipping_charge: null,
        pick_up_charge: null,
        reschedule_date: null
    }

    componentDidMount() {
        getDetails("aggregate_returns", this.props.match.params.id)
            .then(res => {
                console.log(res)
                this.setState({
                    // requestDetails: res.return_details,
                    // returnDetails: res.return_orders,
                    customer: res.customer,
                    partner_info: res.partner_info,
                    id: res.id,
                    pick_up_type: res.pick_up_type,
                    receiver_info: res.receiver_info,
                    refunded: res.refunded,
                    return_orders: res.return_orders,
                    rider_info: res.rider_info,
                    route_info: res.route_info,
                    customer_order_id: res.customer_order_id,
                    pick_address: res.pick_address,
                    distributor_name: res.distributor_name,
                    warehouse_phone: res.warehouse_phone,
                    sub_total: res.sub_total,
                    grand_total: res.grand_total,
                    vat_shipping_charge: res.vat_shipping_charge,
                    pick_up_charge: res.pick_up_charge,
                    // rider: res.rider,
                    // home_pick_up: (res.return_details.return_option_value == "0"),
                    // return_option: res.return_details.return_option,
                    isLoading: false,
                    reschedule_date: res.reschedule_date
                });
            })
            .catch(errMsg => {
                console.log('Err Msg: ', errMsg)
            });
        getList('riders')
            .then(res => {
                this.setState({
                    ridersList: res,
                    isLoading: false,
                }, () => {
                });
            })
            .catch(errMsg => {
            })
    }

    handleOnFieldChange = (e) => {
        const queryData = {...this.state.params};
        queryData[e.target.name] = e.target.value;
        this.setState({params: queryData}, () => {
        });
    }
    handelRiderModal = () => {
        this.setState({closeRiderModal: !this.state.closeRiderModal})
    }

    assignRiderSubmit = (e) => {
        e.preventDefault();
        putData(`aggregate_returns/${this.state.id}/rider_assign`, this.state.params)
            .then(res => {
                if (res.status_code == "200" || res.status_code == "201") {
                    toast.success("Rider assigned successful");
                    window.location.reload();
                } else {
                    toast.error("Please fill all required fields(* marked)");
                }
            })
            .catch(errMsg => {
                toast.error("Please fill all required fields(* marked)");
            });
    }
    getAttributes = (attrArray) => {
        let attrs = "";
        if (!!attrArray) {
            attrArray.map((attr) => (
                attrs += attr.name + ": " + attr.value + ", "
            ))
        }
        return attrs.trimEnd();
    }

    render() {
        const {
            requestDetails, partner_info,
            id,
            customer_order_id,
            pick_up_type,
            pick_address,
            receiver_info,
            refunded,
            return_orders,
            rider_info,
            distributor_name,
            warehouse_phone,
            route_info,
            sub_total,
            grand_total,
            vat_shipping_charge,
            pick_up_charge,
            reschedule_date
        } = this.state;
        const {returnDetails} = this.state;
        return (
            <App layout="">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Aggregated Return Order Details</h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/returnOrder/list`}
                                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Aggregated Return Orders
                                List</Link>
                        </div>
                    </div>
                    <Modal show={this.state.closeRiderModal} onHide={this.handelRiderModal}>
                        <Modal.Header>
                            <Modal.Title>Assign Rider </Modal.Title>
                        </Modal.Header>
                        <Form onSubmit={this.assignRiderSubmit}>
                            <Modal.Body>
                                <Form.Group controlId="rider_id">
                                    <Form.Label>Rider<span className="text-danger">*</span></Form.Label>
                                    <Form.Control as="select"
                                                  name="rider_id"
                                                  onChange={this.handleOnFieldChange}>
                                        <option value="">--Select Rider--</option>
                                        {
                                            this.state.ridersList.map((item, i) => (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="default" onClick={this.handelRiderModal}>
                                    Close
                                </Button>
                                <Button variant="primary" type={`submit`}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {this.state.isLoading ?
                        <div className={`card-block`}>
                            <div className="block-body">
                                Loading...
                            </div>
                        </div>
                        :
                        <div className="card-block">
                            {/* <div className="block-header text-right">
                                <div className="block-title">
                                    {
                                        pick_up_type === 'from_home' && refunded === false && getWarehouseType() !== "central" &&
                                        <Button
                                            className="mr-2"
                                            size="sm"
                                            type={"button"}
                                            onClick={() => {
                                                this.setState({closeRiderModal: true})
                                            }}
                                            variant="primary">
                                            Assign Rider
                                        </Button>
                                    }
                                </div>

                            </div> */}
                            <div className="block-body print-outer">

                                <table className="invoice-table">
                                    <tbody>

                                    <tr className="v-top">
                                        <td>
                                            <h3 className="invoice-title">Aggregated Return Order # 0000{id}</h3>
                                            <p>Order # {<Link
                                                to={`/customerOrder/details/${customer_order_id}`}>{customer_order_id}</Link>}</p>
                                            <span className="d-block">Pickup Type : {humanize(pick_up_type)}</span>
                                            <span
                                                className="d-block">Reschedule Date : {reschedule_date ? getFormattedDate(reschedule_date, 'DD-MM-YYYY') : '_'}</span>

                                        </td>
                                        <td className="text-right">
                                            <span className="d-block"><h6>Customer Information</h6></span>
                                            <span className="d-block">{this.state.customer?.customer_name}</span>
                                            <span className="d-block">{this.state.customer?.customer_email}</span>
                                            <span className="d-block">{this.state.customer?.customer_phone}</span>
                                            <br/>
                                            <span className="d-block"><h6>Receiver Information</h6></span>
                                            <span className="d-block">{this.state.receiver_info?.name}</span>
                                            <span className="d-block">{this.state.receiver_info?.email}</span>
                                            <span className="d-block">{this.state.receiver_info?.phone}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    {/* <td style={{paddingBottom: '20px'}}>
                            <h4 className="invoice-title-alt">Type</h4>
                            <span className="d-block">{requestDetails?.return_option}</span>
                          </td> */}
                                                    {pick_up_type === 'from_home' &&
                                                    <td style={{paddingBottom: '20px'}}>
                                                        <h4 className="invoice-title-alt">Pickup Address</h4>
                                                        <span
                                                            className="d-block">{pick_address.area_name}</span>
                                                        <span
                                                            className="d-block">{pick_address.thana_name}</span>
                                                        <span
                                                            className="d-block">{pick_address.district_name}</span>
                                                        <span
                                                            className="d-block">{pick_address.address_line}</span>
                                                    </td>}
                                                    {pick_up_type != 'from_home' && <td style={{paddingBottom: '20px'}}>
                                                        <h4 className="invoice-title-alt">Partner Info</h4>
                                                        <span className="d-block"><Link
                                                            to={`${process.env.PUBLIC_URL}/partner/details/${partner_info.partner_id}`}>{partner_info.partner_name}</Link></span>
                                                        <span
                                                            className="d-block">{partner_info.partner_phone}</span>
                                                        <span
                                                            className="d-block">{partner_info.partner_email}</span>
                                                        <span
                                                            className="d-block">{partner_info.partner_area}</span>
                                                    </td>}
                                                    <td style={{paddingBottom: '20px'}}>
                                                        <h4 className="invoice-title-alt">Distributor</h4>
                                                        <span
                                                            className="d-block">{distributor_name}</span>
                                                        {/* <span
                                                            className="d-block">{warehouse_phone}</span> */}
                                                    </td>
                                                    {pick_up_type === 'from_home' &&
                                                    <td>
                                                        <h4 className="invoice-title-alt">Rider</h4>
                                                        <span className="d-block">{rider_info.rider_name || '_'}</span>
                                                        <span className="d-block">{rider_info.rider_phone || '_'}</span>
                                                    </td>
                                                    }
                                                    {pick_up_type != 'from_home' &&
                                                    <td>
                                                        <h4 className="invoice-title-alt">Delivery Man</h4>
                                                        <span
                                                            className="d-block">{route_info.route_name}</span>
                                                        <span
                                                            className="d-block">{route_info.route_phone}</span>
                                                    </td>
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            {/*                                            <>

                                                <h3>Reasons</h3>
                                                <table className="info-table">
                                                    <tbody>
                                                    <tr>
                                                        <td style={{width: '250px'}}>Type</td>
                                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                                        <td>{requestDetails?.return_reason}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: '250px'}}>Description</td>
                                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                                        <td>{requestDetails?.description}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <hr></hr>
                                            </>*/}
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th className={'text-center'}>Return Order #</th>
                                                    <th className={'text-center'}>Item</th>
                                                    <th className={"text-center"}>Date</th>
                                                    <th className={'text-center'}>Reason</th>
                                                    <th className={'text-center'}>Status</th>
                                                    <th className="text-right">Amount (৳)</th>
                                                </tr>
                                                </thead>

                                                <tbody>

                                                {return_orders.length > 0 ?
                                                    return_orders?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <tr key={index + 1}>
                                                                <td className={'text-center'}>000{item.return_order_id}</td>
                                                                <td>
                                                                    <span
                                                                        className="d-block"><strong>{item.item?.product_title}</strong></span>
                                                                    <span className="d-block text-muted">
                                                                        {this.getAttributes(item.item?.product_attribute_values)}
                                                                    </span>
                                                                </td>
                                                                <td className={'text-center'}>{getFormattedDate(item.requested_on, 'DD-MM-YYYY')}</td>
                                                                <td className={'text-center'}>{item.reason}</td>
                                                                <td className={`text-center`}>{humanize(item.return_status)}</td>
                                                                <td className="text-right">{twoDecimalPlace(item.amount)}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))
                                                    :
                                                    ''
                                                }

                                                <tr className="text-bold">
                                                    <td colSpan={5} className="text-right">Subtotal</td>
                                                    <td className="text-right">{twoDecimalPlace(sub_total)}</td>
                                                </tr>

                                                {/* <tr className="text-bold">
                          <td colSpan={4} className="text-right">Discount</td>
                          <td className="text-right">{twoDecimalPlace(requestDetails?.total_discount_amount)}</td>
                        </tr> */}
                                                <tr className="text-bold">
                                                    <td colSpan={5} className="text-right">Shipping Charge</td>
                                                    <td className="text-right">{twoDecimalPlace(pick_up_charge)}</td>
                                                </tr>

                                                <tr className="text-bold">
                                                    <td colSpan={5} className="text-right">VAT@ 15% on Shipping Charge</td>
                                                    <td className="text-right">{twoDecimalPlace(vat_shipping_charge)}</td>
                                                </tr>

                                                <tr className="text-bold">
                                                    <td colSpan={5} className="text-right">Grand Total</td>
                                                    <td className="text-right">{twoDecimalPlace(grand_total)}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </App>
        );
    }
}

export default withRouter(RRDetails);
