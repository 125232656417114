import React, { Component } from "react"
import App from "../../App";
import { FaLongArrowAltLeft, IoMdClose } from "react-icons/all";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from 'react-select';
import "../../assets/scss/add-product.scss";
import styleReactSelect from "../../assets/styleReactSelect";
import { toast } from "react-toastify";
import { getProductType } from "../../services/productTypeService";
import { addProduct } from "../../services/productServices";
import BrowseButton from "../../components/BrowseButton";
import JoditEditorComponent from "../../components/JoditEditorComponent";
import ChooseCategory from "../../components/ChooseCategory";
import { getData, getList } from "../../services/baseServices";
import VariantForm from "./VariantForm"
import BundleForm from "./Bundle/Index"
import { difference } from "lodash"
import { convertToSlug } from "../../helper/utils";
import MetaInfo from '../../components/MetaInfo'

class AddProduct extends Component {
    constructor() {
        super();
        this.state = {
            uniqueSlug: '',
            product: {
                slug: '',
                public_visibility: false,
                product_features_attributes: [],
                product_type_ids: [],
                sku_type: 'simple_product',
                variants_attributes: [{
                    primary: true,
                    price_consumer: '',
                    price_retailer: '',
                    price_distribution: '',
                    discount_type: 'percentage'
                }],
                product_attribute_images_attributes: [],
                bundle_variants: [],
                is_emi_available: false,
                tenures: []
            },
            variant_attributeValues: [],
            copy_variant_attribute_values: [],
            productTypes: [],
            brands: [],
            category_ids: [],
            attributeSet: [],
            product_type: [],
            selectedAttributeSet: {},
            selectedAttribute: {},
            attributeValues: [],
            metaInfo: {},
            faq: {},
            notLastChild: false,
            selectedBrand: {},
            updateVariantFor: null
        }
    }

    handleOnMetaInputChange = (e) => {
        const metainfo = { ...this.state.metaInfo }
        const name = e.target.name;
        const value = e.target.value;
        const updatedMetaInfo = { ...metainfo, [name]: value }
        this.setState({ metaInfo: updatedMetaInfo })
    }
    handleOnChangeMetaKeywordValue = (en_bn, value) => {
        const mitainfoDetails = { ...this.state.metaInfo };
        const valueIdArr = [];
        if (value?.length > 0) {
            value.forEach(val => {
                valueIdArr.push(val.value);
            })
        }
        if (en_bn === 'en') {
            mitainfoDetails.metaKeywords = valueIdArr;
        } else {
            mitainfoDetails.metaKeyKeywordsBn = valueIdArr;
        }
        this.setState({ metaInfo: mitainfoDetails })
    }

    componentDidMount() {
        getProductType()
            .then(async res => {
                await this.setState({
                    productTypes: res,
                }, () => {
                });
            })
            .catch(errMsg => {
                console.log('Error Message: ', errMsg)
            })

        getList("brands")
            .then(async res => {
                let brands = []
                await res.data.forEach(item => {
                    let brand = { ...item, label: item.name, value: item.id }
                    brands.push(brand)
                })

                await this.setState({ brands });
            })
            .catch(errMsg => {
                console.log('Error Message: ', errMsg)
            })

        getList('attribute_sets')
            .then(res => {
                if (res.status === 200) {
                    let attrSets = []
                    res.data.map(attr => {
                        let attrSet = { ...attr, value: attr.id, label: attr.title }
                        // attrSet['id'] = attr.id;
                        // attrSet['label'] = attr.title;
                        attrSets.push(attrSet)
                    })
                    this.setState({ attributeSet: attrSets })
                }
            })
            .catch(errMsg => {
                console.log('Err Msg: ', errMsg)
            })
    }

    handleBundleVariations = (data) => {
        console.log('I see', data)
        let product = { ...this.state.product }
        // console.log(product.bundle_variants)
        product = { ...product, bundle_variants: data }
        this.setState({ product }, () => {
            // console.log(this.state.product.bundle_variants);
        })
    }

    handleOnInputChange = (e) => {
        const name = e.target.name
        const val = e.target.value
        let product = { ...this.state.product }
        if (name === 'sku_type') {
            if (val === 'simple_product' || val === 'bundle_product') {
                product = {
                    ...product, variants_attributes: [{
                        primary: true,
                        price_consumer: '',
                        price_retailer: '',
                        price_distribution: '',
                        discount_type: 'percentage'
                    }],
                    product_attribute_images_attributes: []
                }
                this.setState({ selectedAttributeSet: {}, attributeValues: [], })
            } else {
                product = { ...product, variants_attributes: [] }
            }
        }
        product = { ...product, [name]: val }
        this.setState({ product }, () => {
            if (name === 'title') {
                const slug = convertToSlug(this.state.product.title)
                this.setState({ product: { ...product, slug } })
            } else if (name === "slug") {
                const slug = convertToSlug(this.state.product.slug)
                this.setState({ product: { ...product, slug } })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            product
        } = this.state
        // console.log(prevState)
        if (prevState.product?.slug !== product?.slug) {
            if (product?.slug) {
                getData('slugs/uniqueness', { slug: product.slug, type: "product" })
                    .then(res => this.setState({ uniqueSlug: res.success }))
                    .catch(err => this.setState({ uniqueSlug: '' }))
            } else {
                this.setState({ uniqueSlug: '' })
            }
        }
    }

    handleProductTypeInputChange = (event) => {
        const target = event.target;
        let value = target.value;
        let product = { ...this.state.product }
        let productTypeArr = product.product_type_ids;
        if (target.checked) {
            productTypeArr.push(value);
        } else {
            productTypeArr = productTypeArr.filter(function (e) {
                return e != value
            });
        }
        product.product_type_ids = productTypeArr;
        this.setState({ product })
    }

    handleEMIOptionChange = (e) => {
        const name = e.target.name
        const val = String(e.target.value) == "true"
        let product = { ...this.state.product }
        product = { ...product, [name]: val, tenures: product.tenures }
        this.setState({ product })
    }

    handleEMITenureInputChange = (event) => {
        const target = event.target;
        let value = target.value;
        let product = { ...this.state.product }
        let tenures = product.tenures;
        if (target.checked) {
            tenures.push(value);
        } else {
            tenures = tenures.filter(function (e) {
                return e != value
            });
        }
        product = { ...product, tenures: tenures };
        this.setState({ product })
    }

    handleAttributeSetChange = (e) => {
        const attributeSet = [...this.state.attributeSet]
        const selectedAttributeSet = attributeSet.find(item => {
            return item.id === e.id
        })
        this.setState({ selectedAttributeSet })
        let product = { ...this.state.product }

        if (product.attribute_set_id !== e.id) {
            product.selectedAttributeSet = {}
        }
        product = { ...product, attribute_set_id: e.id };
        this.setState({ product })
    }

    handleAttributeChange = (e) => {
        const product_attribute = this.state.selectedAttributeSet?.product_attributes?.find(item => item.id == e.target.value)
        const product = { ...this.state.product, image_attribute_id: e.target.value, product_attribute_images_attributes: [] }
        const attributeValues = product_attribute?.product_attribute_values
        this.setState({ product, attributeValues })
    }

    handleJoEditorOnChange = (name, data) => {
        const product = { ...this.state.product, [name]: data };
        this.setState({ product })
    }

    onConfirmCategory = async (selectedCategories) => {
        const lastCat = selectedCategories[selectedCategories.length - 1];
        const selectedCatIds = selectedCategories?.map((item) => { return (item.id) })
        const product = { ...this.state.product, category_ids: selectedCatIds, leaf_category_id: lastCat.id }
        this.setState({ product })
        if (lastCat?.sub_categories?.length > 0) {
            this.setState({ notLastChild: true })
        } else {
            this.setState({ notLastChild: false })
        }
    }

    handleOnChangeKeywordValue = (en_bn, value) => {
        const product = { ...this.state.product };
        const valueIdArr = [];
        if (value?.length > 0) {
            value.forEach(val => {
                valueIdArr.push(val.value);
            })
        }
        if (en_bn === 'en') {
            product.meta_keyword = valueIdArr;
        } else {
            product.bn_meta_keyword = valueIdArr;
        }
        this.setState({ product }, () => {
        });
    }

    handleReactSelectOnChange = (name, option) => {
        const product = { ...this.state.product };
        product[name] = option.value
        this.setState({ product, selectedBrand: option }, () => { });
    }

    handleImages = (name, files, multiple) => {
        const product = { ...this.state.product };
        let fff = []
        for (let i = 0; i < files.length; i++) {
            fff.push(files[i])
        }
        product[name] = multiple ? fff : fff[0]
        this.setState({ product })
    }

    addOneKeyFeature = () => {
        let { product } = this.state
        product.product_features_attributes.push({ title: '', bn_title: '', description: '', bn_description: '', })
        this.setState({ product })
    }

    handleKeyFeature = (e, index, prop) => {
        let { product } = this.state
        product.product_features_attributes[index][prop] = e.target.value;
        this.setState({ product })
    }

    removeKeyFeature = (index) => {
        let { product } = this.state
        product.product_features_attributes.splice(index, 1);
        this.setState({ product })
    }

    handleFaqInputOnChange = (e) => {
        const faq = { ...this.state.faq, [e.target.name]: e.target.value };
        this.setState({ faq }, () => {
        });
    }

    handleAddFaq = () => {
        const faq = this.state.product?.frequently_asked_questions_attributes || [];
        if (this.state.faq.question && this.state.faq.answer && this.state.faq.bn_question && this.state.faq.bn_answer) {
            faq.push(this.state.faq);
            const product = { ...this.state.product, frequently_asked_questions_attributes: faq };
            this.setState({ product }, () => {
                this.setState({ faq: {} }, () => {
                    document.getElementById('formAddFaq').reset();
                })
            })
        } else {
            toast.error('Please fill all(*) FAQ data')
        }
    }

    handleUpdateFaq = (index) => {
        if (this.state.faq.question && this.state.faq.answer && this.state.faq.bn_question && this.state.faq.bn_answer) {
            const product = { ...this.state.product };
            product.frequently_asked_questions_attributes[index] = this.state.faq;
            this.setState({ product, faq: {}, editFaqIndex: null }, () => {
                document.getElementById('formAddFaq').reset();
            })
        } else {
            toast.error('Please fill all(*) FAQ data')
        }
    }

    handleEditFaq = (index) => {
        const faq = this.state.product.frequently_asked_questions_attributes[index];
        this.setState({ faq: faq, editFaqIndex: index });
    }

    handleDeleteFaq = (index) => {
        const product = { ...this.state.product };
        product.frequently_asked_questions_attributes.splice(index, 1);
        this.setState({ product });
    }

    getEffectiveMRP = (discount_type, discountAmount, price) => {
        return Math.round((discount_type === 'percentage' ? price - discountAmount / 100 * price : price - discountAmount))
    }

    handleSKUInputOnChange = (e, index) => {
        const variants_attributes = [...this.state.product.variants_attributes];
        variants_attributes[index][e.target.name] = e.target.value;
        switch (e.target.name) {
            case 'price_consumer':
                if (variants_attributes[index].consumer_discount) {
                    variants_attributes[index].effective_mrp =
                        this.getEffectiveMRP(variants_attributes[index]?.discount_type, variants_attributes[index].consumer_discount, e.target.value)
                } else {
                    variants_attributes[index].effective_mrp = e.target.value
                }
                break;
            case 'consumer_discount':
                if (variants_attributes[index].price_consumer) {
                    variants_attributes[index].effective_mrp =
                        this.getEffectiveMRP(variants_attributes[index]?.discount_type, e.target.value, variants_attributes[index].price_consumer)
                } else {
                    variants_attributes[index].effective_mrp = 0
                }
                break;
            case 'discount_type':
                if (variants_attributes[index].price_consumer && variants_attributes[index].consumer_discount) {
                    variants_attributes[index].effective_mrp =
                        this.getEffectiveMRP(variants_attributes[index]?.discount_type, variants_attributes[index].consumer_discount, variants_attributes[index].price_consumer)
                } else {
                    variants_attributes[index].effective_mrp = 0
                }
                break;
            case 'b2b_discount_type':
                debugger
                if (variants_attributes[index].b2b_price && variants_attributes[index].b2b_discount) {
                    variants_attributes[index].b2b_effective_mrp =
                        this.getEffectiveMRP(variants_attributes[index]?.b2b_discount_type, variants_attributes[index].b2b_discount, variants_attributes[index].b2b_price)
                } else {
                    variants_attributes[index].b2b_effective_mrp = 0
                }
                break;
            case 'b2b_price':
                if (variants_attributes[index].b2b_discount) {
                    variants_attributes[index].b2b_effective_mrp =
                        this.getEffectiveMRP(variants_attributes[index]?.b2b_discount_type, variants_attributes[index].b2b_discount, e.target.value)
                } else {
                    variants_attributes[index].b2b_effective_mrp = e.target.value
                }
                break;
            case 'b2b_discount':
                if (variants_attributes[index].b2b_price) {
                    variants_attributes[index].b2b_effective_mrp =
                        this.getEffectiveMRP(variants_attributes[index]?.b2b_discount_type, e.target.value, variants_attributes[index].b2b_price)
                } else {
                    variants_attributes[index].b2b_effective_mrp = 0
                }
                break;
            default:
                break;
        }

        const product = { ...this.state.product, variants_attributes };
        this.setState({ product })
    }

    handleAttributeImageChange = (attrValueId, files) => {
        const product = { ...this.state.product };
        let fff = []
        for (let i = 0; i < files.length; i++) {
            fff.push(files[i])
        }
        let existingAttrImageIndex = product.product_attribute_images_attributes.findIndex(item => item.product_attribute_value_id == attrValueId)
        if (existingAttrImageIndex < 0) {
            product.product_attribute_images_attributes.push({
                product_attribute_value_id: attrValueId,
                is_default: false,
                images_file: fff
            })
        } else {
            product.product_attribute_images_attributes[existingAttrImageIndex] =
            {
                product_attribute_value_id: attrValueId,
                is_default: false,
                images_file: fff
            }
        }
        this.setState({ product });
    }
    handleCloseVariationModal = () => {
        this.setState({
            isShowVariationModal: false,
            updateVariantFor: null,
            product_attribute_value_ids: []
        })
    }
    handleShowVariationModal = () => {
        this.setState({
            isShowVariationModal: true,
            editSKUIndex: null,
            variant_attributeValues: []
        })
    }

    handleEditVariantModal = (index) => {
        this.setState({
            updateVariantFor: index, isShowVariationModal: true,
            variant_attributeValues: []
        })
    }

    handleComboOnChange = (e, index, attrValue) => {
        let selectedAttrValue = attrValue?.product_attribute_values?.find(i => i.id == e.target.value)
        let variant_attributeValues = [...this.state.variant_attributeValues];
        // let variants_attributes = product.variants_attributes
        variant_attributeValues[index] = selectedAttrValue;//attrValue?.product_attribute_values[index]
        // product = {...product, variants_attributes};

        this.setState({ variant_attributeValues });
    }

    handleAddVariation = () => {
        if (this.state.variant_attributeValues?.filter((item) => item?.id > 0).length < this.state.selectedAttributeSet?.product_attributes.length) {
            toast.error('Please choose a combination')
            return
        }

        let variants_attributes = [...this.state.product.variants_attributes];
        let variantAttrIds = variants_attributes.map(i => i.product_attribute_value_ids)
        let attrValueIds = this.state.variant_attributeValues.map(i => i.id)
        if (variants_attributes?.length > 0 && variantAttrIds.some(i => (difference(i, attrValueIds).length == 0))) {
            toast.error('The combination is already exists')
            return
        }

        let copy_variant_attribute_values = [...this.state.copy_variant_attribute_values]
        copy_variant_attribute_values.push(this.state.variant_attributeValues)
        variants_attributes.push({ discount_type: 'percentage', product_attribute_value_ids: this.state.variant_attributeValues.map(item => { return (item.id) }) })
        const product = { ...this.state.product, variants_attributes };
        this.setState({ product, copy_variant_attribute_values, variant_attributeValues: [] });
        this.handleCloseVariationModal()
    }

    handleUpdateVariation = () => {
        if (this.state.variant_attributeValues?.filter((item) => item?.id > 0).length < this.state.selectedAttributeSet?.product_attributes.length) {
            toast.error('Please choose a combination')
            return
        }
        let variants_attributes = [...this.state.product.variants_attributes];
        let variantAttrIds = variants_attributes.map(i => i.product_attribute_value_ids)
        let attrValueIds = this.state.variant_attributeValues.map(i => i.id)
        if (variants_attributes?.length > 0 && variantAttrIds.some(i => (difference(i, attrValueIds).length == 0))) {
            toast.error('The combination is already exists')
            return
        }
        const product = { ...this.state.product };
        let copy_variant_attribute_values = [...this.state.copy_variant_attribute_values]
        copy_variant_attribute_values[this.state.updateVariantFor] = this.state.variant_attributeValues
        product.variants_attributes[this.state.updateVariantFor].product_attribute_value_ids = this.state.variant_attributeValues.map(item => { return (item.id) });
        this.setState({ product, updateVariantFor: null, copy_variant_attribute_values, })
        this.handleCloseVariationModal()
    }

    handleDeleteSku = (index) => {
        const product = { ...this.state.product };
        let variants_attributes = product?.variants_attributes;
        variants_attributes = variants_attributes?.filter((i, j) => j !== index);
        product.variants_attributes = variants_attributes
        let copy_variant_attribute_values = [...this.state.copy_variant_attribute_values]
        copy_variant_attribute_values = copy_variant_attribute_values?.filter((i, j) => j !== index);
        this.setState({ product, copy_variant_attribute_values });
    }

    getMetaInfo = () => {
        const metaInfo = { ...this.state.meta }
    }
    handleAddProduct = async (e) => {
        e.preventDefault();
        const productData = { ...this.state.product }
        const metaData = { ...this.state.metaInfo }
        const meta_datum_attributes = {
            meta_title: metaData.metaTitle,
            bn_meta_title: metaData.metaTitleBn,
            meta_description: metaData.metaDescription,
            bn_meta_description: metaData.metaDescriptionBn,
            meta_keyword: metaData.metaKeywords,
            bn_meta_keyword: metaData.metaKeyKeywordsBn
        }
        const updatedProduct = { ...productData, meta_datum_attributes }
        this.setState({ product: updatedProduct })

        const form = e.currentTarget;
        if (form.checkValidity() === false || this.state.notLastChild || !this.state.product.brand_id) {
            e.preventDefault();
            e.stopPropagation();
            form.className += " was-validated";
            this.setState({ isFormValidated: true })
        } else {
            if (this.state.product?.sku_type === 'bundle_product' && this.state.product?.bundle_variants.length === 0) {
                toast.error('Please select atleast one SKU for Bundle Product');
            } else {
                this.setState({ isSubmitting: true }, () => {
                    addProduct(this.state.product)
                        .then(res => {
                            if (res?.status_code === 201) {
                                toast.success("Product has been added successfully.");
                                this.props.history.push(`/product/list`);
                            } else {
                                toast.error(res.message);
                                this.setState({ isSubmitting: false })
                            }
                        })
                        .catch(errMsg => {
                            toast.error(errMsg);
                            this.setState({ isSubmitting: false })
                        });
                })
            }
        }
    }

    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Add Product</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/product/list`}
                            className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to Product List</Link>
                    </div>
                </div>

                <div className="main-content">
                    <Form
                        noValidate
                        validated={this.state.isFormValidated}
                        onSubmit={this.handleAddProduct}>

                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">What You're Selling</h4>
                            </div>

                            <div className="block-body">
                                <Form.Group as={Row} controlId="business_type">
                                    <Form.Label column sm="3">
                                        Business Type <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="business_type"
                                            id={`custom-radio-b2c`}
                                            required
                                            onChange={this.handleOnInputChange}
                                            value={'b2c'}
                                            label={`B2C`}
                                        />
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="business_type"
                                            required
                                            onChange={this.handleOnInputChange}
                                            value={'b2b'}
                                            id={`custom-radio-b2b`}
                                            label={`B2B`}
                                        />
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="business_type"
                                            required
                                            onChange={this.handleOnInputChange}
                                            value={'both'}
                                            id={`custom-radio-both`}
                                            label={`Both(B2B and B2C)`}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please select a Business type.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formName">
                                    <Form.Label column sm="3">
                                        Title <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text"
                                            required
                                            onChange={(e) => {
                                                this.handleOnInputChange(e)
                                            }}
                                            name="title" placeholder="" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid product title.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formName">
                                    <Form.Label column sm="3">
                                        Slug <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text"
                                            required
                                            onChange={(e) => {
                                                this.handleOnInputChange(e)
                                            }}
                                            name="slug" value={this.state.product?.slug} className={this.state.uniqueSlug === '' ? "" : [this.state.uniqueSlug ? "slug-is-valid" : "slug-is-invalid"]} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a slug.
                                        </Form.Control.Feedback>
                                        {this.state.uniqueSlug === false && <div class="slug-err-msg">Please provide an unique slug.</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formName">
                                    <Form.Label column sm="3">
                                        Title in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control type="text"
                                            onChange={(e) => {
                                                this.handleOnInputChange(e)
                                            }}
                                            name="bn_title" placeholder="" />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid product title in Bangla.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formCategory">
                                    <Form.Label column sm="3">
                                        Category <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <ChooseCategory
                                            onConfirm={this.onConfirmCategory}
                                        />
                                        {this.state.notLastChild &&
                                            <span className='text-danger'> Please select a sub-category. </span>
                                        }
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formName">
                                    <Form.Label column sm="3">
                                        Weight To set Priority
                                    </Form.Label>
                                    <Col sm="4" md={4} lg={4}>
                                        <Form.Control
                                            name="weight" size={"sm"}
                                            min="0"
                                            step="1"
                                            defaultValue={0}
                                            onChange={this.handleOnInputChange}
                                            type={"number"}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please set weight to the product
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formWarrantyPeriod">
                                    <Form.Label column sm="3">
                                        Publish
                                    </Form.Label>

                                    <Col sm="4" md={4} lg={4}>
                                        <Form.Control
                                            name="public_visibility"
                                            onChange={this.handleOnInputChange}
                                            as="select" type="text">
                                            <option value={false}>No</option>
                                            <option value={true}>Yes</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formWarrantyPeriod">
                                    <Form.Label column sm="3">
                                        EMI Enabled
                                    </Form.Label>

                                    <Col sm="4" md={4} lg={4}>
                                        <Form.Control
                                            name="is_emi_available"
                                            onChange={this.handleEMIOptionChange}
                                            as="select" type="text">
                                            <option value={false}>No</option>
                                            <option value={true}>Yes</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                {
                                    this.state.product.is_emi_available &&
                                    <Form.Group as={Row} controlId="formDangerousGoods">
                                        <Form.Label column sm="3">
                                            EMI tenures <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Check
                                                custom
                                                inline
                                                required={this.state.product?.tenures?.length <= 0}
                                                type="checkbox"
                                                name="tenures"
                                                onChange={this.handleEMITenureInputChange}
                                                value={3}
                                                id="3"
                                                label="3 Months"
                                            />
                                            <Form.Check
                                                custom
                                                inline
                                                required={this.state.product?.tenures?.length <= 0}
                                                type="checkbox"
                                                name="tenures"
                                                onChange={this.handleEMITenureInputChange}
                                                value={6}
                                                id="6"
                                                label="6 Months"
                                            />
                                            <Form.Check
                                                custom
                                                inline
                                                required={this.state.product?.tenures?.length <= 0}
                                                type="checkbox"
                                                name="tenures"
                                                onChange={this.handleEMITenureInputChange}
                                                value={9}
                                                id="9"
                                                label="9 Months"
                                            />
                                            <Form.Check
                                                custom
                                                inline
                                                required={this.state.product?.tenures?.length <= 0}
                                                type="checkbox"
                                                name="tenures"
                                                onChange={this.handleEMITenureInputChange}
                                                value={12}

                                                id="12"
                                                label="12 Months"
                                            />

                                            <Form.Check
                                                custom
                                                inline
                                                required={this.state.product?.tenures?.length <= 0}
                                                type="checkbox"
                                                name="tenures"
                                                onChange={this.handleEMITenureInputChange}
                                                value={18}
                                                id="18"
                                                label="18 Months"
                                            />

                                            <Form.Check
                                                custom
                                                inline
                                                required={this.state.product?.tenures?.length <= 0}
                                                type="checkbox"
                                                name="tenures"
                                                onChange={this.handleEMITenureInputChange}
                                                value={24}
                                                id="24"
                                                label="24 Months"
                                            />

                                            <Form.Check
                                                custom
                                                inline
                                                required={this.state.product?.tenures?.length <= 0}
                                                type="checkbox"
                                                name="tenures"
                                                onChange={this.handleEMITenureInputChange}
                                                value={36}

                                                id="36"
                                                label="36 Months"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please select one.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                }
                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">Basic Information</h4>
                            </div>

                            <div className="block-body">
                                {/* <Form.Group as={Row} controlId="formModel">
                                <Form.Label column sm="3">
                                Meta Keywords
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                <CreatableSelect
                                    noOptionsMessage={() => 'Please type your values...'}
                                    isMulti
                                    styles={styleReactSelect}
                                    onChange={(value) => {
                                    this.handleOnChangeKeywordValue('en', value)
                                    }}
                                />
                                </Col>
                            </Form.Group> */}

                                {/* <Form.Group as={Row} controlId="formModel">
                                <Form.Label column sm="3">
                                Meta Keywords in Bangla
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                <CreatableSelect
                                    noOptionsMessage={() => 'Please type your values...'}
                                    isMulti
                                    styles={styleReactSelect}
                                    onChange={(value) => {
                                    this.handleOnChangeKeywordValue('bn', value)
                                    }}
                                />
                                </Col>
                            </Form.Group> */}
                                <Form.Group as={Row} controlId="formWarrantyPeriod">
                                    <Form.Label column sm="3">
                                        Refundable
                                    </Form.Label>

                                    <Col sm="4" md={4} lg={4}>
                                        <Form.Control
                                            name="is_refundable"
                                            onChange={this.handleOnInputChange}
                                            as="select" type="text">
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </Form.Control>
                                    </Col>

                                </Form.Group>
                                <Form.Group as={Row} controlId="formHighlight">
                                    <Form.Label column sm="3">
                                        Short Description
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={122}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('short_description', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHighlight">
                                    <Form.Label column sm="3">
                                        Short Description in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={2}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('bn_short_description', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="productDescription">
                                    <Form.Label column sm="3">
                                        Specification
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={3}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('description', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="productDescription">
                                    <Form.Label column sm="3">
                                        Specification in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={4}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('bn_description', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formWarrantyType">
                                    <Form.Label column sm="3">
                                        Warranty Type <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="warranty_type"
                                            required
                                            onChange={this.handleOnInputChange}
                                            value={'no_warranty'}
                                            id={`custom-radio-4`}
                                            label={`No Warranty`}
                                        />
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="warranty_type"
                                            id={`custom-radio-1`}
                                            required
                                            onChange={this.handleOnInputChange}
                                            value={'international_manufacturer_warranty'}
                                            label={`International Manufacturer Warranty`}
                                        />
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="warranty_type"
                                            required
                                            onChange={this.handleOnInputChange}
                                            value={'non_local_warranty'}
                                            id={`custom-radio-2`}
                                            label={`Non-local warranty`}
                                        />
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            name="warranty_type"
                                            required
                                            onChange={this.handleOnInputChange}
                                            value={'local_seller_warranty'}
                                            id={`custom-radio-3`}
                                            label={`Local seller warranty`}
                                        />
                                        <Form.Check
                                            custom
                                            inline
                                            type="radio"
                                            required
                                            name="warranty_type"
                                            onChange={this.handleOnInputChange}
                                            value={'international_seller_warranty'}
                                            id={`custom-radio-5`}
                                            label={`International Seller Warranty`}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please select a warranty type.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>


                                {this.state.product.warranty_type !== 'no_warranty' &&
                                    <Form.Group as={Row} controlId="formWarrantyPeriod">
                                        <Form.Label column sm="3">
                                            Warranty Period
                                        </Form.Label>

                                        <Col sm="3" md={3} lg={3}>
                                            <Form.Control name="warranty_period"
                                                onChange={this.handleOnInputChange}
                                                type="text" />

                                            <Form.Control.Feedback type="invalid">
                                                Please provide valid information.
                                            </Form.Control.Feedback>
                                        </Col>

                                        <Col sm="4" md={4} lg={4}>
                                            <Form.Control
                                                name="warranty_period_type"
                                                onChange={this.handleOnInputChange}
                                                as="select" type="text">
                                                <option value="">--Select--</option>
                                                <option value="1">Months</option>
                                                <option value="2">Years</option>
                                            </Form.Control>

                                            <Form.Control.Feedback type="invalid">
                                                Please select a warranty period.
                                            </Form.Control.Feedback>
                                        </Col>

                                    </Form.Group>
                                }
                                <Form.Group as={Row} controlId="formWarrantyPolicy">
                                    <Form.Label column sm="3">
                                        Warranty Policy
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={4}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('warranty_policy', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formWarrantyPolicy">
                                    <Form.Label column sm="3">
                                        Warranty Policy in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={4}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('bn_warranty_policy', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formRR">
                                    <Form.Label column sm="3">
                                        Refund & Return Policy
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={4}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('return_policy', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formWarrantyPolicy">
                                    <Form.Label column sm="3">
                                        Refund & Return Policy in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <JoditEditorComponent
                                            tabIndex={4}
                                            onChange={(value) => {
                                                this.handleJoEditorOnChange('bn_return_policy', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                        Dangerous Goods
                                    </Form.Label>

                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control name="dangerous_goods"
                                            onChange={this.handleOnInputChange}
                                            type={"text"} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formInTheBox">
                                    <Form.Label column sm="3">
                                        What's in the box
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control name="inside_box"
                                            onChange={this.handleOnInputChange}
                                            type="text" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formInTheBox">
                                    <Form.Label column sm="3">
                                        What's in the box in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control name="bn_inside_box"
                                            onChange={this.handleOnInputChange}
                                            type="text" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Product Type <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                required
                                                name="product_type"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Value is required.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formDangerousGoods">
                                    <Form.Label column sm="3">
                                        Offer Type
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>

                                        {this.state.productTypes.length > 0 ?
                                            this.state.productTypes.map((item, index) => {
                                                return (
                                                    <Form.Check
                                                        custom
                                                        inline
                                                        type="checkbox"
                                                        name="product_type_ids"
                                                        onChange={this.handleProductTypeInputChange}
                                                        value={item.id}
                                                        id={`custom-${index}`}
                                                        label={item.title}
                                                    />
                                                );
                                            })

                                            : ''}

                                        <Form.Control.Feedback type="invalid">
                                            Please select one.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Company in English <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                required
                                                name="company"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Value is required.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Company in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="bn_company"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Value is required.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Brand <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Select
                                            value={this.state.selectedBrand}
                                            styles={styleReactSelect}
                                            placeholder="Select Brand..."
                                            onChange={(opt) => this.handleReactSelectOnChange("brand_id", opt)}
                                            options={this.state.brands}
                                            name="brand_id"
                                            required={true}
                                        >
                                        </Select>
                                        <Form.Control.Feedback type="invalid" className={`brand ${(this.state.isFormValidated && !this.state.product.brand_id) ? "d-block" : ''}`}>
                                            Brand is required.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Certification in English
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="certification"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Certification in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="bn_certification"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        License Required
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="license_required"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Material in English
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="material"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Material in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="bn_material"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Consumption Guideline in English
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="consumption_guidelines"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Consumption Guideline in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="bn_consumption_guidelines"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Temperature Requirement in English
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="temperature_requirement"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Temperature Requirement in Bangla
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="bn_temperature_requirement"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Keywords
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="keywords"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Tagline
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                name="tagline"
                                                onChange={this.handleOnInputChange}
                                                type="text" />
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPackageWeight">
                                    <Form.Label column sm="3">
                                        Order Limit
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <div className="input-group">
                                            <Form.Control
                                                type="number"
                                                min={1}
                                                pattern={'^\+?(0|[1-9]\d*)$'}
                                                name="max_quantity_per_order"
                                                onChange={this.handleOnInputChange}
                                            />
                                        </div>
                                        {/* <Form.Control.Feedback 
                                    type="invalid" className={` ${(
                                        this.state.isFormValidated &&
                                        !this.state.product.max_quantity_per_order) ?
                                        "d-block":''}`}
                                >
                                    Order Limit is required. minimum order limit is 1 and maximum limit is 100
                                </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>

                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">Gallery</h4>
                            </div>
                            <div className="block-body">
                                <Form.Group as={Row} controlId="formVideoUrl">
                                    <Form.Label column sm="3">
                                        Video URL
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <Form.Control name="video_url" onChange={this.handleOnInputChange} type="text" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formImages">
                                    <Form.Label column sm="3">
                                        Hero Image <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <BrowseButton
                                            multiple={false}
                                            // onChange={e => {
                                            //     this.setState({hero_image_file: e.target.files[0]})
                                            // }}
                                            onChange={(e) => this.handleImages('hero_image_file', e.target.files, false)}
                                            id={'heroImage'} name={'hero_image_file'}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formImages">
                                    <Form.Label column sm="3">
                                        Product Images
                                    </Form.Label>
                                    <Col sm="9" md={8} lg={7}>
                                        <BrowseButton
                                            multiple
                                            onChange={(e) => this.handleImages('images_file', e.target.files, true)}
                                            id={'mainImage'} name={'images_file'}
                                        />
                                    </Col>
                                </Form.Group>

                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">KEY FEATURES</h4>
                            </div>
                            <div className="block-body">
                                <Button onClick={this.addOneKeyFeature} variant={"success"}
                                    disabled={this.state.product?.product_features_attributes?.length >= 5}>Add
                                    One</Button>
                                {
                                    this.state.product?.product_features_attributes?.length > 0 &&
                                    <table className="table mt-5 table-responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">Title</th>
                                                <th scope="col">Title in Bangla</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Description in Bangla</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.product?.product_features_attributes?.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <input type="text" className="form-control"
                                                                placeholder="Title" value={item.title}
                                                                onChange={(e) => this.handleKeyFeature(e, i, 'title')}
                                                                required maxLength={25} />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control"
                                                                placeholder="Title in Bangla" value={item.bn_title}
                                                                onChange={(e) => this.handleKeyFeature(e, i, 'bn_title')}
                                                                required maxLength={25} />
                                                        </td>
                                                        <td>
                                                            <textarea className="form-control"
                                                                placeholder="Description"
                                                                onChange={(e) => this.handleKeyFeature(e, i, 'description')}
                                                                // required 
                                                                maxLength={50}>
                                                                {item.description}
                                                            </textarea>
                                                        </td>
                                                        <td>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Description in Bangla"
                                                                onChange={(e) => this.handleKeyFeature(e, i, 'bn_description')}
                                                                // required 
                                                                maxLength={50}>
                                                                {item.bn_description}
                                                            </textarea>
                                                        </td>
                                                        <td><span className="pointer-event"
                                                            onClick={() => this.removeKeyFeature(i)}><IoMdClose /></span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">SKU Type</h4>
                            </div>
                            <div className="block-body">
                                <Form.Check
                                    custom
                                    inline
                                    required
                                    type="radio"
                                    defaultChecked={this.state.product?.sku_type === 'simple_product'}
                                    name="sku_type"
                                    id={`sku-type-1`}
                                    onChange={this.handleOnInputChange}
                                    value={'simple_product'}
                                    label={`Simple Product`}
                                />

                                <Form.Check
                                    custom
                                    inline
                                    type="radio"
                                    required
                                    defaultChecked={this.state.product?.sku_type === 'variable_product'}
                                    name="sku_type"
                                    id={`sku-type-2`}
                                    onChange={this.handleOnInputChange}
                                    value={'variable_product'}
                                    label={`Variable Product`}
                                />

                                <Form.Check
                                    custom
                                    inline
                                    type="radio"
                                    required
                                    defaultChecked={this.state.product?.sku_type === 'bundle_product'}
                                    name="sku_type"
                                    id={`sku-type-3`}
                                    onChange={this.handleOnInputChange}
                                    value={'bundle_product'}
                                    label={`Bundle Product`}
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please please select a type.
                                </Form.Control.Feedback>
                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">{this.state.product.sku_type === 'simple_product' ? 'SKU Information' : [this.state.product.sku_type === 'bundle_product' ? 'Bundle Information' : 'Variants']}</h4>
                            </div>
                            {
                                this.state.product.sku_type === 'variable_product' &&
                                <div className="block-body">
                                    <Form.Group as={Row} controlId="formInTheBox">
                                        <Form.Label column sm="2">
                                            Attribute Set
                                        </Form.Label>
                                        <Col sm="10" md={9} lg={7}>
                                            <Select
                                                value={this.state.selectedAttributeSet}
                                                styles={styleReactSelect}
                                                placeholder="Select Attribute set..."
                                                onChange={e => this.handleAttributeSetChange(e)}
                                                options={this.state.attributeSet}
                                                name='attr-set'
                                                required={true}
                                            >
                                            </Select>
                                        </Col>
                                    </Form.Group>
                                    {
                                        this.state.selectedAttributeSet?.product_attributes?.length > 0 && (
                                            <Form.Group as={Row} controlId="formInTheBox">
                                                <Form.Label column sm="2">
                                                    Image Attribute
                                                </Form.Label>
                                                <Col sm="10" md={9} lg={7}>
                                                    <Form.Control
                                                        name="image_attribute_id"
                                                        onChange={this.handleAttributeChange}
                                                        as="select" type="text">
                                                        <option value="">--Select--</option>
                                                        {
                                                            this.state.selectedAttributeSet?.product_attributes.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        )
                                    }

                                    {this.state.attributeValues?.length > 0 &&
                                        this.state.attributeValues?.map((option, i) => (
                                            <Form.Group as={Row} controlId="formInTheBox" key={i}>
                                                <Form.Label column sm="2"></Form.Label>
                                                <Col sm="10" md={9} lg={7}>
                                                    <table className="info-table">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: '50px' }} className="nowrap">{option.value} </td>
                                                                <td style={{ width: '30px' }} className="text-center">:</td>
                                                                <td>
                                                                    <BrowseButton
                                                                        name={'combo_img'}
                                                                        id={`combo-img-${option.id}`}
                                                                        size={'sm'}
                                                                        multiple
                                                                        //onChange={(e)=> this.handleImages('images_file',e.target.files, true)}
                                                                        onChange={e => {
                                                                            this.handleAttributeImageChange(option.id, e.target.files)
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Form.Group>
                                        ))
                                    }
                                    {
                                        this.state.selectedAttributeSet?.product_attributes?.length > 0 &&
                                        <>
                                            <Button onClick={this.handleShowVariationModal} variant={"secondary"}
                                                size={"sm"}>+ Add Variation</Button>

                                            <Modal size={"sm"} show={this.state.isShowVariationModal}
                                                onHide={this.handleCloseVariationModal}>
                                                <Modal.Header closeButton>
                                                    {this.state.updateVariantFor !== null ?
                                                        <Modal.Title>Update Combination</Modal.Title>
                                                        :
                                                        <Modal.Title>Create Combination</Modal.Title>
                                                    }
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {this.state.selectedAttributeSet?.product_attributes?.map((attr, index) => (
                                                        <Form.Group key={index}>
                                                            <Form.Label>{attr.name}<span
                                                                className="text-danger">*</span></Form.Label>
                                                            <Form.Control
                                                                onChange={(e) => this.handleComboOnChange(e, index, attr)}
                                                                as="select">
                                                                {attr?.product_attribute_values?.length > 0 ?
                                                                    <>
                                                                        <option key={index}
                                                                            value="">--Select--
                                                                        </option>
                                                                        {attr.product_attribute_values.map((option, index) => {
                                                                            return (
                                                                                <option key={index}
                                                                                    value={option.id}>{option.value}</option>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </>
                                                                    :
                                                                    <option value="">No Option</option>
                                                                }
                                                            </Form.Control>
                                                        </Form.Group>
                                                    ))}
                                                </Modal.Body>

                                                <Modal.Footer>
                                                    <Button variant="default" size={"sm"}
                                                        onClick={this.handleCloseVariationModal}>
                                                        Close
                                                    </Button>
                                                    {this.state.updateVariantFor !== null ?
                                                        <Button variant="primary" size={"sm"}
                                                            onClick={() => this.handleUpdateVariation()}>
                                                            Update Variation
                                                        </Button>
                                                        :
                                                        <Button variant="primary" size={"sm"}
                                                            onClick={this.handleAddVariation}>
                                                            Add Variation
                                                        </Button>
                                                    }
                                                </Modal.Footer>
                                            </Modal>

                                        </>
                                    }
                                </div>
                            }

                            {
                                this.state.product.sku_type === 'bundle_product' && <BundleForm bundleVariants={this.state.product.bundle_variants} handleBundleVariations={this.handleBundleVariations} />
                            }

                            <div className="block-body">
                                {
                                    this.state.product?.sku_type !== 'bundle_product' && this.state.product && this.state.product?.variants_attributes?.map((variant, i) => {
                                        return (
                                            <VariantForm
                                                handleSKUInputOnChange={this.handleSKUInputOnChange}
                                                variant={variant}
                                                position={i}
                                                header={this.state.product?.sku_type === 'variable_product'}
                                                attributeValues={this.state.copy_variant_attribute_values[i]}
                                                handleEditSKUInfo={this.handleEditVariantModal}
                                                handleDeleteSku={this.handleDeleteSku}
                                            />
                                        )
                                    })
                                }

                                {
                                    this.state.product?.sku_type === 'bundle_product' && this.state.product?.bundle_variants.length > 0 && this.state.product && this.state.product?.variants_attributes?.map((variant, i) => {
                                        return (
                                            <VariantForm
                                                handleSKUInputOnChange={this.handleSKUInputOnChange}
                                                variant={variant}
                                                position={i}
                                                header={false}
                                                attributeValues={this.state.copy_variant_attribute_values[i]}
                                                handleEditSKUInfo={this.handleEditVariantModal}
                                                handleDeleteSku={this.handleDeleteSku}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">Meta Info</h4>
                            </div>
                            <div className="block-body">
                                <MetaInfo
                                    metaInfo={this.state.metaInfo}
                                    handleOnInputChange={this.handleOnMetaInputChange}
                                    handleOnChangeKeywordValue={this.handleOnChangeMetaKeywordValue}
                                />
                            </div>
                        </div>
                        <div className="card-block">
                            <div className="block-header">
                                <h4 className="block-title">FAQ</h4>
                            </div>

                            <div className="block-body">
                                {this.state.product?.frequently_asked_questions_attributes?.length > 0 &&
                                    <div className="q-a-list mb-4">
                                        {this.state.product.frequently_asked_questions_attributes.map((faq, i) => (
                                            <div key={i} className="item">
                                                <div className="question">
                                                    <span className="symbol">Q</span>
                                                    <span className="text">{faq.question}</span>
                                                </div>

                                                <div className="answer">
                                                    <span className="symbol">A</span>
                                                    <span className='text'>{faq.answer}</span>
                                                </div>

                                                <div className="question">
                                                    <span className="symbol">BQ</span>
                                                    <span className="text">{faq.bn_question}</span>
                                                </div>

                                                <div className="answer">
                                                    <span className="symbol">BA</span>
                                                    <span className='text'>{faq.bn_answer}</span>
                                                </div>

                                                <div className="q-a-actions">
                                                    <span
                                                        onClick={() => this.handleEditFaq(i)}
                                                        className="text-info">Edit</span>
                                                    <span onClick={() => this.handleDeleteFaq(i)}
                                                        color="text-danger">Delete</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                                <Form id="formAddFaq">
                                    <Form.Group controlId="faqQ">
                                        <Form.Label srOnly>Question</Form.Label>
                                        <Form.Control
                                            name="question"
                                            defaultValue={this.state.faq?.question}
                                            onChange={this.handleFaqInputOnChange}
                                            type="text" placeholder="Question *" />
                                    </Form.Group>

                                    <Form.Group controlId="faqA">
                                        <Form.Label srOnly>Answer</Form.Label>
                                        <Form.Control
                                            name="answer"
                                            defaultValue={this.state.faq?.answer}
                                            onChange={this.handleFaqInputOnChange} placeholder="Answer *" />
                                    </Form.Group>

                                    <Form.Group controlId="faqBnQ">
                                        <Form.Label srOnly>Question</Form.Label>
                                        <Form.Control
                                            name="bn_question"
                                            defaultValue={this.state.faq?.bn_question}
                                            onChange={this.handleFaqInputOnChange}
                                            type="text" placeholder="Bangla Question *" />
                                    </Form.Group>

                                    <Form.Group controlId="faqBnA">
                                        <Form.Label srOnly>Answer</Form.Label>
                                        <Form.Control
                                            name="bn_answer"
                                            defaultValue={this.state.faq?.bn_answer}
                                            onChange={this.handleFaqInputOnChange} placeholder="Bangla Answer *" />
                                    </Form.Group>

                                    {this.state.editFaqIndex || this.state.editFaqIndex === 0 ?
                                        <Button onClick={() => this.handleUpdateFaq(this.state.editFaqIndex)}
                                            variant={"secondary"}>Update FAQ</Button>
                                        :
                                        <Button onClick={this.handleAddFaq} variant={"secondary"}>Add FAQ</Button>
                                    }
                                </Form>
                            </div>
                        </div>

                        <div className="card-block">
                            <div className="block-body text-right">
                                <Link to={`/product/list`} className={`btn btn-lg btn-default mr-2`}>Cancel</Link>
                                <Button size="lg"
                                    type={`submit`}
                                    disabled={this.state.isSubmitting || this.state.uniqueSlug === false}
                                    variant="primary">{this.state.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </App>
        )
    }

}

export default withRouter(AddProduct);