import React, { useState, useEffect } from "react";
import "../../assets/scss/tree-category.scss";
import App from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEdit, FiPlus } from "react-icons/all";
import { Link } from "react-router-dom";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { toast } from 'react-toastify';
import ConfirmationModal from "../common/Modal";
import Pagination from 'react-js-pagination';
import { destroy, getPaginatedList } from "../../services/baseServices";
import "antd/dist/antd.css";
import { Table } from "antd";
import Loader from "react-loader"

const Menu = (props) => {
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [pagination, setPagination] = useState({
        "x-page": 1,
        "x-per-page": 15,
        "x-total": 0,
        "x-total-pages": 0
    });

    const actionColumn = id => {
        let actionElement =
            <div className="btn-group">
                <OverlayTrigger
                    key="view"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-view`}>
                            Edit
                        </Tooltip>
                    }
                >
                    <Link to={`${process.env.PUBLIC_URL}/category/edit/${id}`}
                        className="btn btn-sm btn-default"><FiEdit /></Link>
                </OverlayTrigger>
                <OverlayTrigger
                    key="view"
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-view`}>
                            Delete
                        </Tooltip>
                    }
                >
                    <ConfirmationModal title="Delete" body="Are you sure to delete?" handleAction={() => handleDeleteCategory(id)} />
                </OverlayTrigger>
            </div>
        return actionElement;
    }


    const columns = [
        {
            title: "Name",
            dataIndex: "title",
            key: "title"
        },
        {
            title: "Bn Name",
            dataIndex: "bn_title",
            key: "bn_title"
        },
        {
            title: "Position",
            dataIndex: "position",
            key: "position"
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action"
        }
    ];


    const getTreeValue = (categories, level) => {
        let deep = level;
        let value =
            categories.map((item, i) => {
                let key = (i + 1) + (deep > 0 ? '-' + deep : '')
                key = key.split("").reverse().join("")
                let coulmn = actionColumn(item.id)
                return ({
                    id: item.id,
                    title: item.title,
                    bn_title: item.bn_title,
                    position: item.position,
                    action: coulmn,
                    key: key,
                    created_by: item?.created_by?.name,
                    hasChild: item.sub_categories?.length > 0,
                    children: item.sub_categories?.length > 0 ? getTreeValue(item.sub_categories, key) : []
                })
            })
        return value;
    }

    const handlePageChange = (pageNumber) => {
        pagination["x-page"] = pageNumber;
        setPagination(pagination);
        setRefresh(!refresh)
    }

    const handleDeleteCategory = (id) => {
        destroy("categories", id)
            .then(res => {

                //   toast.success("Category has been successfully deleted")
                //   setRefresh(!refresh)
                if (res.status_code === 200) {
                    toast.success(res.message)
                    setRefresh(!refresh)
                } else {
                    toast.error(res.message)
                }
            })
            .catch(errMsg => {
                toast.error(errMsg)
            })
    }

    useEffect(() => {
        setLoading(true)
        getPaginatedList("categories/list", { per_page: pagination["x-per-page"], page: pagination["x-page"] })
            .then(res => {
                pagination["x-page"] = res.headers["x-page"];
                pagination["x-per-page"] = res.headers["x-per-page"];
                pagination["x-total-pages"] = res.headers["x-total-pages"];
                pagination["x-total"] = res.headers["x-total"];
                setPagination(pagination);
                let treeCategories = getTreeValue(res.data, 0)
                setCategories(treeCategories)
                setLoading(false)
            })
            .catch(errMsg => {
                console.log('Error Message: ', errMsg)
                setLoading(false)
            })
    }, [refresh])

    return (
        <App layout="">
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">Category List</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/category/add`} className="btn btn-sm btn-secondary"><FiPlus /> Add
                            Category</Link>
                    </div>
                </div>

                <Loader loaded={!loading}>
                    <div className="card-block">
                        <div className="block-body">
                            <Table columns={columns} dataSource={categories} defaultExpandAllRows={false} pagination={false} />
                        </div>
                        {
                            <div className="mt-3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={parseInt(pagination['x-page'])}
                                    itemsCountPerPage={parseInt(pagination['x-per-page'])}
                                    totalItemsCount={parseInt(pagination['x-total'])}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                />
                            </div>
                        }
                    </div>
                </Loader>
            </div>
            <ToastsContainer store={ToastsStore} />
        </App>
    );
};

export default Menu;