import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";

const stateType = {
  class: "classComponent",
  function: "functionComponent",
};

class ModalForm extends React.Component {
 


  render() {
    const { title, children, isModalOpen , handleClose, handleSubmit} = this.props;
    const isSubmitted = false;
    return (
      <>
        <Modal
          show={isModalOpen}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              {children}
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitted}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ModalForm;
