import React, { Component } from "react";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getDetails, update } from "../../services/baseServices";
import RiderForm from "./Form";
import { MdArrowBack } from "react-icons/all";

class Edit extends Component {
  state = {
    formData: {},
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false,
    errors: {
      name: "Name is required",
      email: "Email is required",
      phone: "Phone is required",
    },
  };

  componentDidMount() {
    getDetails("riders", this.props.match.params.id)
      .then((res) => {
        this.setState({ formData: { ...res } });
      })
      .catch((errMsg) => {});
  }

  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };

    if (
      e.target.name === "name" &&
      e.target.value.match(e.target.pattern) === null
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          name: "No special characters and numbers are allowed",
        },
      });
    } else if (
      e.target.name === "email" &&
      e.target.value.match(e.target.pattern) === null
    ) {
      this.setState({
        errors: { ...this.state.errors, email: "Please provide valid email" },
      });
    } else if (
      e.target.name === "phone" &&
      e.target.value.match(e.target.pattern) === null
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          phone: "Please provide valid BD phone number",
        },
      });
    }else if (
      e.target.name === "password_confirmation" &&
      formData["password_hash"] !== e.target.value
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          password_confirmation: "Password doesn't match",
        },
      });
    }
    formData[e.target.name] = e.target.value;
    this.setState({ formData }, () => {});
  };

  handleUpdateItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        let formData = { ...this.state.formData };
        const phone = formData["phone"].length === 14 ? formData["phone"].substring(3) : formData["phone"];
        formData = {...formData, phone};

        if ((formData.password_hash || formData.password_confirmation) && (formData.password_hash !== formData.password_confirmation)) {
          toast.error("Password doesn't match with confirmed password");
          this.setState({ isSubmitting: false });
          return;
        }

        delete formData.password_confirmation;
        update("riders", this.props.match.params.id, "rider", formData)
          .then((res) => {
            if(res.phone){
              this.props.history.push(`/rider/list`);
              toast.success("Rider has been updated successfully.");
            }else{
              toast.error(res.message);
            }
            this.setState({ isSubmitting: false });
          })
          .catch((errMsg) => {
            toast.error(errMsg);
            this.setState({ isSubmitting: false });
          });
      });
    }
  };

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Rider</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/rider/list`}
              className={`btn btn-sm btn-link`}
            >
              <MdArrowBack />
              Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <RiderForm
                handleClick={this.handleUpdateItem}
                handleInputOnChange={this.handleInputOnChange}
                formData={this.state.formData}
                isSubmitting={this.state.isSubmitting}
                isFormValidated={this.state.isFormValidated}
                errors={this.state.errors}
                isAddForm={false}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Edit);
