import React, {Component} from 'react';
import App from "../../App";
import {
  AiOutlineArrowLeft,
  FaStore,
  FiFilter,
  FiPlus,
  TiArrowUnsorted
} from "react-icons/all";
import {Link, withRouter, Redirect} from "react-router-dom";
import { getFormattedDate } from "../../helper/utils";

class Details extends Component {
  state = {
    productDetails: null,
    isDetailLoading: false,
    redirect: false,
    status: "",
    btnText: "",
    activeBlockTab: 'Description'
  }

  handleBlockTab = (label) => {
    this.setState({activeBlockTab: label});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/products/list'/>
    }
    const details = this.state.productDetails;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Supplier Details</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/supplier/list`}
                  className="btn btn-sm btn-link mr-2"><AiOutlineArrowLeft/> Back to List</Link>
            <Link to={`${process.env.PUBLIC_URL}/supplier/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add
              Supplier</Link>
          </div>
        </div>

        <div className="main-content">
          <div className="card-block">
            <div className="block-body">
              <div className="product-list">
                <div className="product-item">
                  <div className="product lg">
                    {/*<div className="product-thumb">*/}
                    {/*  <img src="/static/media/blank-avatar.d5dc8a64.png" alt="" style={{width:"40px" , height:"40px"}}/>*/}
                    {/*</div>*/}

                    <div className="product-desc">
                      <h4 className="product-title">Supplier 1</h4>
                      <p className="date mb-0"><em>Created at : </em> {getFormattedDate(details?.createdAt, 'LL')}</p>
                      <p className="date mb-0">
                        Email: supplier@gmail.com
                        <br/>
                        Phone: +8801774455223
                        <br/>
                        Address: Dhaka, Bangladesh
                      </p>
                    </div>

                    {/*<div className="actions">*/}
                    {/*  <Link to={`/products/edit/${details?._id}`}*/}
                    {/*        className="btn-action btn-block btn-outline-primary">Edit</Link>*/}
                    {/*  <button className="btn-action btn-block btn-outline-warning"*/}
                    {/*          onClick={() => this.handleProductStatus(this.state.productDetails._id, this.state.status)}>{this.state.btnText}</button>*/}
                    {/*  <button className="btn-action btn-block btn-outline-danger"*/}
                    {/*          onClick={() => this.handleHardDeleteProduct(this.state.productDetails._id)}>Delete*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>

              {details?.sku_type !== 1 &&
              <>
                <hr/>
                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th>Reliability</th>
                  </tr>
                  </thead>

                  <tbody>
                    <tr key={1}>
                      <td>Reliable</td>
                    </tr>
                  </tbody>
                </table>
              </>
              }
            </div>
          </div>

          {details?.reviews?.length > 0 &&
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Review</h4>
            </div>

          </div>}

          {/*<div className="card-block">*/}
          {/*  <ul className="desc-header-nav">*/}
          {/*    <li className={this.state.activeBlockTab === 'Description' ? 'active' : ''} value="">*/}
          {/*      <span onClick={() => {this.handleBlockTab('Description')}}>Description</span>*/}
          {/*    </li>*/}
          {/*    <li className={this.state.activeBlockTab === 'DescriptionB' ? 'active' : ''} value="">*/}
          {/*      <span onClick={() => {this.handleBlockTab('DescriptionB')}}>Description in Bangla</span>*/}
          {/*    </li>*/}
          {/*  </ul>*/}

          {/*  <div className="block-body">*/}
          {/*    {this.state.activeBlockTab === 'Description' &&*/}
          {/*    <div className={`entry-content`}*/}
          {/*         dangerouslySetInnerHTML={{__html: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)"}}/>}*/}
          {/*    {this.state.activeBlockTab === 'DescriptionB' &&*/}
          {/*    <div className={`entry-content`}*/}
          {/*         dangerouslySetInnerHTML={{__html: "এটি একটি দীর্ঘ প্রতিষ্ঠিত সত্য যে কোনও পাঠকের বিন্যাসের দিকে তাকালে কোনও পাঠকের পাঠযোগ্য সামগ্রী দ্বারা বিভ্রান্ত হবে। লরেম ইপসাম ব্যবহারের বিষয়টি হ'ল এটিতে চিঠিপত্রের কম-বেশি স্বাভাবিক বিতরণ থাকে, যেমন 'এখানে সামগ্রী, এখানে সামগ্রী' ব্যবহার করার বিপরীতে, এটি পড়ার মতো ইংরাজির মতো দেখায়। অনেক ডেস্কটপ প্রকাশনা প্যাকেজ এবং ওয়েব পৃষ্ঠার সম্পাদক এখন লোরেম ইপসামকে তাদের ডিফল্ট মডেল পাঠ্য হিসাবে ব্যবহার করেন এবং 'লরেম ইপসাম' অনুসন্ধানের ফলে তাদের শৈশবকালীন অনেকগুলি ওয়েবসাইট উন্মোচিত হবে। বিভিন্ন সংস্করণ কয়েক বছর ধরে বিকশিত হয়েছে, কখনও দুর্ঘটনার দ্বারা, কখনও কখনও উদ্দেশ্য (ইনজেকশনের রসিকতা এবং এর মতো)" }}/>}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </App>
    );
  }
}

export default withRouter(Details);