import React, { useEffect, useState } from "react";
import App from "../../App";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEye } from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getData, getList, getPaginatedList } from "../../services/baseServices";
import { getFormattedDate, getLocalAuthToken, getWarehouseType, humanize } from "../../helper/utils";
import Loader from 'react-loader';
import moment from 'moment'
import LogModal from "../product/LogModal";
import { DatePicker } from "antd";


const warehouseType = getWarehouseType();

const AuditLogs = () => {

    const [data, setData] = useState([])
    const [modalData, setModalData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [queryParams, setQueryParams] = useState(
        { page: 1, per_page: 50, auditable_type: null, auditable_id: null, action: null, created_at: null })

    useEffect(() => {
        getDataPagi()
    }, [])

    const handleDeatilsLog = (auditableId) => {
        getData(`audit_logs/${auditableId}`)
            .then((res) => {
                setModalData([res?.data])
                setShowModal(true)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const hideModalHandler = () => {
        setShowModal(false)
    }

    const handleFilterOnSumbit = (e) => {
        e.preventDefault()
        let q = { ...queryParams }
        q.page = 1
        setQueryParams(q)
        getDataPagi()
    }
    const handlePageChange = (pageNumber) => {
        let q = { ...queryParams }
        q.page = pageNumber
        setQueryParams(q)
        getDataPagi()
    };

    const getDataPagi = () => {
        setLoading(false)
        getPaginatedList('audit_logs', queryParams)
            .then((res) => {
                console.log(res?.data?.data)
                setData(res?.data?.data)
                setLoading(true)

            })
            .catch((error) => {
                setLoading(true)
            });

    }
    const dateOnChange = (date) => {
        const dt = new Date(date)
        let q = { ...queryParams }
        q.created_at = dt
        setQueryParams(q)
    };

    const onFieldChange = (e) => {
        let q = { ...queryParams }
        q[e.target.name] = e.target.value
        setQueryParams(q)
    }

    const options = [
        { label: "WhPurchaseOrder" },
        { label: "DhPurchaseOrder" },
        { label: "ReturnTransferOrder" },
        { label: "Variant" },
        { label: "Brand" },
        { label: "Product" },
        { label: "Promotion" },
        { label: "Coupon" },
        { label: "Supplier" },
        { label: "Location" },
        // { label: "Route" },
        // { label: "Challan" },
        // { label: "Distributor" },
        // { label: "DistributorMargin" },
        // { label: "District" },
        // { label: "FailedQc" },
        // { label: "HelpTopic" },
        // { label: "Location" },
        // { label: "MetaDatum" },
        // { label: "ProductFeature" },
        // { label: "ProductType" },
        // { label: "PromoCoupon" },
        // { label: "ProductAttribute" },
        // { label: "PartnerMargin" },
        // { label: "ReturnChallan" },
        // { label: "PurchaseOrderStatus" },
        // { label: "PromotionVariant" },
        // { label: "ProductsProductType" },
        // { label: "PromoBanner" },
        // { label: "RouteDevice" },
        // { label: "ReturnCustomerOrder" },
        // { label: "Campaign" },
        // { label: "Article" },
        // { label: "AttributeSet" },
        // { label: "AttributeSetProductAttribute" },
        // { label: "BankAccount" },
        // { label: "BankTransaction" },
        // { label: "BlockedItem" },
        // { label: "Box" },
        // { label: "BoxLineItem" },
        // { label: "CustomerOrder" },
        // { label: "Coupon" },
        // { label: "Rider" },
        // { label: "Partner" },
        // { label: "RetailerAssistant" },
        // { label: "SalesRepresentative" },
        // { label: "WarehouseVariant" },
        // { label: "WarehouseBundle" },
        // { label: "ReturnStatusChange" },
        // { label: "UserModifyReason" },
        // { label: "UserModificationRequest" },
        // { label: "SocialLink" },

    ]

    return (
        <App layout="">
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">Audit Logs </h2>
                </div>
                <form className="form-inline mb-3">
                    <div className="form-group mr-3">
                        <label htmlFor="searchInput" className="sr-only">
                            Search by Date
                        </label>
                        <DatePicker
                            selected={new Date()}
                            dateFormat="yyyy-mm-dd"
                            placeholder="Search by Date"
                            onChange={dateOnChange}
                        />
                    </div>

                    <div className="form-group mr-3">
                        <label htmlFor="searchInput" className="sr-only">
                            Search by Auditable Id
                        </label>
                        <input
                            type="search"
                            name="auditable_id"
                            id="order_no"
                            onChange={onFieldChange}
                            defaultValue={queryParams.auditable_id}
                            className="form-control form-control-sm"
                            placeholder="Search by Auditable Id"
                        />
                    </div>
                    <div className="form-group mr-3">
                        <label htmlFor="status" className="sr-only">
                            Auditable Type
                        </label>
                        <select
                            id="auditable type"
                            name="auditable_type"
                            className="form-control form-control-sm"
                            onChange={onFieldChange}
                            value={queryParams.auditable_type}
                        >
                            <option value="">--Auditable Type--</option>

                            {
                                options.map((item) => <option value={item.label}>{item.label} </option>)
                            }

                        </select>
                    </div>
                    <div className="form-group mr-3">
                        <label htmlFor="schedule" className="sr-only">
                            Action Type
                        </label>
                        <select
                            id="action"
                            name="action"
                            className="form-control form-control-sm"
                            onChange={onFieldChange}
                            value={queryParams.action}
                        >
                            <option value="">--Select Action Type--</option>
                            <option value="create">Create</option>
                            <option value="update">Update</option>
                            <option value="destroy">Destroy</option>
                        </select>
                    </div>
                    <Button
                        size="sm"
                        type={`submit`}
                        onClick={handleFilterOnSumbit}
                        variant="primary"
                    >
                        Filter
                    </Button>
                </form>
                <Loader loaded={loading}>
                    <div className="card-block">
                        <div className="block-body">
                            <table className="order-table table table-striped">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>staff Name</th>
                                        <th>STAFF for</th>
                                        <th>Auditable Type</th>
                                        <th>Auditable Id</th>
                                        <th>ACTION TYPE</th>
                                        <th>ACTION </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        data.length > 0 ? data?.map((item, i) => {
                                            return <tr key={item?.id} >
                                                <td>
                                                    <div className="order-item">
                                                        {new Date(item?.created_at).toLocaleString()}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="order-item">
                                                        {humanize(item?.created_by?.first_name)} {humanize(item?.created_by?.last_name)}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="order-item">
                                                        {humanize(item?.created_by?.unit)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="order-item">
                                                        {humanize(item?.auditable_type)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="order-item">
                                                        {item?.auditable_id}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="order-item">
                                                        {humanize(item?.action)}
                                                    </div>
                                                </td>
                                                <td style={{ width: "150px" }} className={"nowrap"}>
                                                    <div className="btn-group">
                                                        <OverlayTrigger
                                                            key="view"
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-view`}>View</Tooltip>
                                                            }
                                                        >
                                                            <FiEye className="cursor-pointer" onClick={() => handleDeatilsLog(item?.id)} />
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                            :
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <h5>No Data Found</h5>
                                                </td>
                                            </tr>
                                    }



                                </tbody>
                            </table>
                        </div>
                        <LogModal
                            show={showModal}
                            logType="Details Log"
                            onHide={hideModalHandler}
                            data={modalData}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        />
                        <div className="mt-3">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                itemsCountPerPage={20}
                                activePage={queryParams.page}
                                totalItemsCount={data?.length}
                                pageRangeDisplayed={10}
                                onChange={(e) => handlePageChange(e)}
                            />
                        </div>
                    </div>
                </Loader>
            </div>
        </App>
    )
}

export default AuditLogs