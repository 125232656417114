import axios from 'axios';
import {API_BASE_URL} from "../helper/env";

export const getSliderList = () => {
  return new Promise((resolve, reject) =>
    axios.get(API_BASE_URL + `/slides`, {headers: {}})
      .then(res => resolve(res.data))
      .catch(err => reject(err.message)));
}

export const addSlider = (body) => {
  return new Promise((resolve, reject) =>
    axios.post(API_BASE_URL + '/slides', body, {headers: {}})
      .then(res => resolve(res.data))
      .catch(err => reject(err.message)))
}

export const updateSlider = (id, body) => {
  return new Promise((resolve, reject) =>
    axios.put(`${API_BASE_URL}/slides/${id}`, body, {headers: {}})
      .then(res => resolve(res.data))
      .catch(err => reject(err.message)));
}

export const getSliderDetails = (id) => {
  return new Promise((resolve, reject) =>
    axios.get(API_BASE_URL + `/slides/${id}`, {headers: {}})
      .then(res => resolve(res.data))
      .catch(err => reject(err.message)));
}

export const deleteSlider = (id) => {
  return new Promise((resolve, reject) =>
    axios.delete(`${API_BASE_URL}/slides/${id}`, {headers: {}})
      .then(res => resolve(res.data.message))
      .catch(err => reject(err.message)))
}
