import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import MetaInfo from "../../components/MetaInfo";
import { toast } from "react-toastify";
import { postData, putData, getData } from "../../services/baseServices";
import { getObjectToFormData } from './../../helper/utils';

const MetaForm = ({ metaDetails }) => {
  const history = useHistory();
  const [pages, setPages] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});
  const [validated, setValidated] = useState(false);
  console.log("data", metaInfo);

  useEffect(() => {
    getData("static_pages/page_types").then((res) => {
      if (res?.success) {
        setPages(res.data);
      }
    });
  }, []);

  useEffect(() => {
    const metaDtata = {};
    if (metaDetails) {
      metaDtata["pageType"] = metaDetails?.page_type_key;
      metaDtata["metaTitle"] = metaDetails?.meta_info?.meta_title;
      metaDtata["metaTitleBn"] = metaDetails?.meta_info?.bn_meta_title;
      metaDtata["metaDescription"] = metaDetails?.meta_info?.meta_description;
      metaDtata["metaDescriptionBn"] = metaDetails?.meta_info?.bn_meta_description;
      metaDtata["metaKeywords"] = metaDetails?.meta_info?.meta_keyword;
      metaDtata["metaKeyKeywordsBn"] = metaDetails?.meta_info?.bn_meta_keyword;
    }
    setMetaInfo(metaDtata);
  }, [metaDetails]);

  const handleOnInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMetaInfo({ ...metaInfo, [name]: value });
  };
  const handleOnChangeKeywordValue = (en_bn, value) => {
    const mitainfoDetails = { ...metaInfo };
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach((val) => {
        valueIdArr.push(val.value);
      });
    }
    if (en_bn === "en") {
      mitainfoDetails.metaKeywords = valueIdArr;
    } else {
      mitainfoDetails.metaKeyKeywordsBn = valueIdArr;
    }
    setMetaInfo(mitainfoDetails);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(false);
      toast.error('Please fill all fields. Don\'t start with spaces')
      return
    } else setValidated(true);

    if(metaInfo.metaDescription?.trim() === '' || metaInfo.metaDescriptionBn?.trim()=== '') {
      toast.error('Please fill all fields. Don\'t start with spaces')
      return
    }
    const data = {
      page_type: + metaInfo.pageType,
      meta_datum_attributes: {
        meta_title: metaInfo.metaTitle,
        bn_meta_title: metaInfo.metaTitleBn,
        meta_description: metaInfo.metaDescription,
        bn_meta_description: metaInfo.metaDescriptionBn,
        meta_keyword: metaInfo.metaKeywords,
        bn_meta_keyword: metaInfo.metaKeyKeywordsBn,
      },
    };
    if (metaDetails) {
      putData(`static_pages/${metaDetails?.id}`, data).then((res) => {
        if (res.success) {
          toast.success(res.message);
          history.push("/meta-info/list");
        } else {
          toast.error(res.message);
        }
      });
    } else {
      postData("static_pages", data).then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          history.push("/meta-info/list");
        } else {
          toast.error(res?.message);
        }
      });
    }
  };
  return (
    <Form noValidate validated={validated} onSubmit={onSubmit}
    onKeyPress={(e)=>{
      if(e.key === 'Enter' && e?.target?.type !== 'textarea') {
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    }}
    >
      <div className="card-block">
        <div className="block-body">
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3">
              Page
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                as="select"
                required={true}
                name={"pageType"}
                onChange={(e) => {
                  !metaDetails && handleOnInputChange(e);
                }}
                disabled={metaDetails}
              >
                <option value="">Please select page</option>
                {pages?.map((page, index) => (
                  <option
                    value={page?.value}
                    key={index}
                    selected={page?.value === metaDetails?.page_type_key}
                  >
                    {page?.title}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Page is required
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <MetaInfo
            metaInfo={metaInfo}
            handleOnInputChange={handleOnInputChange}
            handleOnChangeKeywordValue={handleOnChangeKeywordValue}
            isRequired={true}
          />
          <div className=" row mt-3">
            <div className="col-md-10 text-right">
              <Button type={`submit`} variant="primary">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default MetaForm;
