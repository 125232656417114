import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getPaginatedList } from "../../services/baseServices";
import { getFormattedDate } from '../../helper/utils';
import { TABLES } from './Reconciliation'
import ExportCSVAsync from "../common/ExportCSVAsync";
import moment from "moment";


const headers = [
	{ label: "Order Id", key: "id" },
	{ label: "Delivery Date", key: "delivery_date" },
	{ label: "Order Date", key: "order_date" },
	{ label: "Payment Type", key: "payment_type" },
	{ label: "Total Amount", key: "total_amount" },
	{ label: "Collected By SR", key: "collected_by_route"},
	{ label: "Collected By DH", key: "collected_by_dh"}
  ];
  
  const loadData = (item) => {
	return {
		id: item.id,
		delivery_date: getFormattedDate(item.delivery_date, 'h:mm A, MMMM Do YYYY'),
	  	order_date: getFormattedDate(item.order_date, 'h:mm A, MMMM Do YYYY'),
		payment_type: item.payment_type,
		total_amount: item.total_amount,
		collected_by_route: item.collected_by_route,
		collected_by_dh: item.collected_by_dh
	};
  };

class OrderList extends Component {
	constructor() {
		super();
		this.state = {
			orderList: [],
			activePage: 1,
			per_page: 15,
			page: 1,
			query_params: {},
			isLoading: true
		};
	}

	getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

	getOrderList = (params) => {
		this.setState({ isLoading: true })
		getPaginatedList(this.props.url, params)
			.then((res) => {
				this.setState(
					{
						orderList: res.data,
						isLoading: false,
						per_page: res.headers["x-per-page"],
						total_data: parseInt(res.headers["x-total"]),
					},
					() => {}
				);
			})
			.catch((errMsg) => {
				this.setState({ isLoading: false })
				console.log("Error Message: ", errMsg);
			});
	};

	componentDidMount() {
		this.setState({ query_params: this.props.queryParams }, () => {
			this.getOrderList(this.getQueryParams());
		})
	}

	handlePageChange = (pageNumber) => {
		this.setState({ page: pageNumber, activePage: pageNumber }, () => {
			this.getOrderList(this.getQueryParams());
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
		    console.log(this.props.queryParams)
			this.setState({ query_params: this.props.queryParams }, () => {
            	this.getOrderList(this.getQueryParams());
			})
        }

        if(this.props.tablesToRefresh?.find(i => i === TABLES.ORDER_LIST) && !this.props.tableRefreshed?.find(i => i === TABLES.ORDER_LIST)) {
            this.props.stopReloading(TABLES.ORDER_LIST)
			this.getOrderList(this.getQueryParams());
        }
	}

	render() {
		return (
			<div className="main-content">
				<div className="page-header">
					<h2 className="page-title">Orders Breakdown</h2>
					{
						this.props.whom==="route" &&
						<div className="ml-auto">
							<ExportCSVAsync
								title="Orders Break down"
								fileName={
								`OrdersBreakDown-${this.props.route}-` +
								moment(new Date()).format("DD-MM-YYYY") +
								".csv"
								}
								Url={this.props.exportUrl}
								headers={headers}
								params={this.getQueryParams()}
								loadData={loadData}
							/>
						</div>
					}
				</div>
				{
					this.state.isLoading ?
						<div>Loading...</div>
					:
						<>
							<table className="table table-bordered">
								<thead>
									<tr className="bg-light">
										<th>#</th>
										<th>Delivery Date</th>
										<th>Order Date</th>
										<th>Payment Type</th>
										<th>Total Amount</th>
										<th>Collected by {this.props.whom==="route"? "SR" : this.props.whom}</th>
										<th>Collected by FC</th>
									</tr>
								</thead>
								<tbody>
								{
									this.state.orderList.map((orderDetails, i) => {
										return (
											<tr key={i}>
												<td>{orderDetails.id}</td>
												<td>{getFormattedDate(orderDetails.delivery_date, 'h:mm A, MMMM Do YYYY')}</td>
												<td>{getFormattedDate(orderDetails.order_date, 'h:mm A, MMMM Do YYYY')}</td>
												<td>{orderDetails.payment_type}</td>
												<td>{orderDetails.total_amount}</td>
												<td>{orderDetails[`collected_by_${this.props.whom}`]}</td>
												<td>{orderDetails.collected_by_dh}</td>
											</tr>
										)
									})
								}
								{ this.state.orderList?.length <= 0 && <tr><td colSpan={7}>No request found</td></tr> }
								</tbody>
							</table>
							{
								this.state.orderList?.length > 0 && 
								<div className="mt-3">
									<Pagination
										itemClass="page-item"
										linkClass="page-link"
										activePage={this.state.activePage}
										itemsCountPerPage={parseInt(this.state.per_page)}
										totalItemsCount={parseInt(this.state.total_data)}
										pageRangeDisplayed={10}
										onChange={this.handlePageChange.bind(this)}
									/>
								</div>
							}
						</>
					}
			</div>
		);
	}
}

export default withRouter(OrderList);
