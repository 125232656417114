import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getData } from "../../services/baseServices";
import { addPartner } from "../../services/partnerServices";
import { convertToSlug } from "../../helper/utils";
import PartnerForm from "../partner/Form";
import { FaLongArrowAltLeft } from "react-icons/all";

class Add extends Component {
  constructor() {
    super();

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
  }

  state = {
    partnerInfo: {},
    formData: { is_commission_applicable: true, slug: "", schedule: [] },
    partner: { details: {}, address_attributes: {} },
    readyToSubmit: true,
    uniqueSlug: "",
    isEditable: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { formData } = this.state;
    if (prevState.formData?.slug !== formData?.slug) {
      if (formData?.slug) {
        getData("slugs/uniqueness", { slug: formData.slug, type: "Category" })
          .then((res) => this.setState({ uniqueSlug: res.success }))
          .catch((err) => this.setState({ uniqueSlug: "" }));
      } else {
        this.setState({ uniqueSlug: "" });
      }
    }
  }

  setDistributorId = (ds_id) => {
    const formData = { ...this.state.formData };
    formData["distributor_id"] = ds_id;
    this.setState({ formData });
  };

  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };
    if (e.target.name === "name") {
      const slug = convertToSlug(e.target.value);
      formData["slug"] = slug;
    } else if (e.target.name === "slug") {
      const slug = convertToSlug(e.target.value);
      formData["slug"] = slug;
    }
    formData[e.target.name] = e.target.value;
    this.setState({ formData }, () => {});
  };

  handleAddItem = (e) => {
    e.preventDefault();
    const formInfo = { ...this.state.formData };
    this.setState({ formData: formInfo });

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        let partner = { ...this.state.formData };
        const phone =
          partner["phone"]?.length === 14
            ? partner["phone"].substring(3)
            : partner["phone"];
        partner = { ...partner, phone };

        const address_attributes = {
          address_line: partner.address_line,
          area_id: parseInt(partner.area_id),
        };
        delete partner.address_line;
        delete partner.area_id;
        delete partner.thana_id;
        const formData = { partner, address_attributes };

        if (
          formData.partner.password === formData.partner.password_confirmation
        ) {
          addPartner("partners", formData)
            .then((res) => {
              if (res?.success) {
                this.props.history.push(`/partner/list`);
                toast.success(res?.message);
              } else {
                toast.error(res?.message);
                this.setState({ isSubmitting: false });
              }
            })
            .catch((errMsg) => {
              toast.error(errMsg);
              this.setState({ isSubmitting: false });
            });
        } else {
          toast.error("Password doesn't match with confirmed password");
          this.setState({ isSubmitting: false });
        }
      });
    }
  };
  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Partner</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/partner/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Partner List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <PartnerForm
              setDistributorId={this.setDistributorId}
              handleClick={this.handleAddItem}
              handleInputOnChange={this.handleInputOnChange}
              partnerInfo={this.state.partnerInfo}
              formData={this.state.formData}
              isSubmitting={this.state.isSubmitting}
              readyToSubmit={this.state.readyToSubmit}
              uniqueSlug={this.state.uniqueSlug}
              isEditable={this.state.isEditable}
            />
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Add);
