import React from 'react'

const ErrorMsg = ({children}) => {
    return (
        <div className='text-danger'>
            {children}
        </div>
    )
}

export default ErrorMsg
