import React, { useState } from "react";
import { putData } from "../../services";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { get } from "lodash";

const ConfirmModal = ({ item, getPurchaseOrder }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleReceive = (depositId) => {
    putData("dh_purchase_orders/receive/" + depositId)
      .then((res) => {
        if (res?.status_code === 200) {
          getPurchaseOrder();
          toast.success("Successfully received");
        } else {
          toast.error(res?.message || "Something went wrong");
        }
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  };

  return (
    <div>
      <Button
        size="sm"
        type={`submit`}
        disabled={item?.is_deposited}
        onClick={handleShow}
        variant="primary"
      >
        Receive
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Don you want to receive?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{item?.amount} Amount will be received.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              handleReceive(item?.id);
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
