import React, { Component } from "react";
import App from "../../App";
import { withRouter } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { Button } from "react-bootstrap";
import {
  getPaginatedList,
  getDetails,
  getList,
} from "../../services/baseServices";
import Pagination from "react-js-pagination";
import Loader from "react-loader";
import ExportCSVAsync from "../common/ExportCSVAsync";
import moment from "moment";
import UnBlockModal from "./UnBlockModal";
import GerbageModal from "./GerbageModal";
import { getFormattedDate } from "../../helper/utils";
import DateRangePicker from "react-bootstrap-daterangepicker";

var startDate = moment().subtract(29, "days");
var today = moment();

const headers = [
  { label: "Product code", key: "product_code" },
  { label: "Product Title", key: "product_title" },
  { label: "SKU", key: "sku" },
  { label: "Category", key: "category" },
  { label: "Sub category", key: "sub_category" },
  { label: "MRP", key: "mrp" },
  { label: "Blocked Quantity", key: "quantity" },
  { label: "Currently Blocked", key: "currently_blocked" },
  { label: "Gerbaged Quantity", key: "garbage_quantity" },
  { label: "Unblocked Quantity", key: "unblocked_quantity" },
  { label: "Blocked Reason", key: "blocked_reason" },
];

const loadData = (item) => {
  return {
    product_code: item.product_code,
    product_title: item.product_title,
    sku: item.sku,
    category: item.category,
    sub_category: item.sub_category,
    mrp: item.mrp,
    quantity: item.quantity,
    currently_blocked:
      item.quantity - (item.garbage_quantity + item.unblocked_quantity),
    garbage_quantity: item.garbage_quantity,
    unblocked_quantity: item.unblocked_quantity,
    blocked_reason: item.blocked_reason,
  };
};

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blockItems: [],
      isLoading: true,
      isExporting: false,
      activePage: 1,
      per_page: 50,
      page: 1,

      formData: {
        sku: "",
        failed_reason: "",
      },

      showBlockModal: false,
      blokModalDetails: {},
      showUnBlockModal: false,
      showGarbageModal: false,
      unblocked_details: {},
      garbage_details: {},
      blocked_reasons: [],
      start_date_time: startDate,
      end_date_time: today,
      query_params: {},
    };
  }

  getData() {
    const obj = {};
    if (this.state.per_page > 0) obj.per_page = this.state.per_page;
    if (this.state.page > 0) obj.page = this.state.page;
    if (this.state.formData.sku.length > 0) obj.sku = this.state.formData.sku;
    if (this.state.formData.failed_reason.length > 0)
      obj.failed_reason = this.state.formData.failed_reason;
    if (this.state.start_date_time)
      obj.start_date_time = getFormattedDate(
        this.state.start_date_time,
        "YYYY-MM-DD"
      );
    if (this.state.end_date_time)
      obj.end_date_time = getFormattedDate(
        this.state.end_date_time,
        "YYYY-MM-DD"
      );

    this.setState({ isLoading: true, query_params: obj });
    getPaginatedList("blocked_items", obj)
      .then((res) => {
        this.setState(
          {
            blockItems: res.data,
            isLoading: false,
            per_page: res.headers["x-per-page"],
            total_data: res.headers["x-total"],
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        this.setState({ isLoading: false });
        console.log("Error Message: ", errMsg);
      });
  }

  componentDidMount() {
    this.getData();
    getList("blocked_items/blocked_reasons").then((res) => {
      this.setState({ blocked_reasons: res });
    });
  }

  handlePageChange = (pageNumber) => {
    this.setState(
      { page: pageNumber, activePage: pageNumber, isLoading: true },
      () => {
        this.getData();
      }
    );
  };

  handleFilterOnChange = (e) => {
    const formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    this.setState({ formData, page: 1 });
  };

  handleUnBlockModal = (id) => {
    getDetails("blocked_items", id).then((res) => {
      this.setState({ unblocked_details: res });
      this.setState({ showUnBlockModal: !this.state.showUnBlockModal });
    });
  };

  handleGerbageModal = (id) => {
    getDetails("blocked_items", id).then((res) => {
      this.setState({ unblocked_details: res });
      this.setState({ showGarbageModal: !this.state.showGarbageModal });
    });
  };

  handleCloseUnBlockModal = (refresh) => {
    this.setState({ showUnBlockModal: !this.state.showUnBlockModal });
    if (refresh) this.getData();
  };

  handleCloseGerbageModal = (refresh) => {
    this.setState({ showGarbageModal: !this.state.showGarbageModal });
    if (refresh) this.getData();
  };

  filterList = (e) => {
    e.preventDefault();
    this.setState({ activePage: 1 });
    this.getData();
  };

  handleOnApply = (e, picker) => {
    this.setState({
      start_date_time: picker.startDate.format(),
      end_date_time: picker.endDate.format(),
    });
  };

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Blocked Item List</h2>
            <div className="ml-auto">
              <ExportCSVAsync
                title="Blocked Items"
                fileName={
                  "BlockedItems-" +
                  moment(new Date()).format("DD-MM-YYYY") +
                  ".csv"
                }
                Url="blocked_items/export"
                headers={headers}
                params={this.state.query_params}
                loadData={loadData}
              />
            </div>
          </div>
          <form className="form-inline mb-3">
            <div className="form-group mr-3">
              <label htmlFor="sku" className="sr-only">
                SKU
              </label>
              <input
                type="text"
                name="sku"
                value={this.state.formData.sku}
                id="sku"
                onChange={this.handleFilterOnChange}
                className="form-control form-control-sm"
                placeholder="Search by SKU"
              />
            </div>

            <div className="form-group mr-3">
              <label htmlFor="failed_reason" className="sr-only">
                Blocked Reason
              </label>
              <select
                name="failed_reason"
                id="failed_reason"
                value={this.state.formData.failed_reason}
                className="form-control form-control-sm"
                onChange={this.handleFilterOnChange}
              >
                <option value="">--Select Failed Reason--</option>
                {this.state.blocked_reasons?.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mr-3">
              <label htmlFor="status" className="sr-only">
                Date Range
              </label>
              <DateRangePicker
                initialSettings={{
                  maxSpan: { months: 3 },
                  showDropdowns: true,
                  locale: { format: "DD/MM/YYYY" },
                  startDate: this.state.start_date_time,
                  endDate: this.state.end_date_time,
                }}
                onApply={(e, picker) => this.handleOnApply(e, picker)}
              >
                <input
                  type="text"
                  value={`${getFormattedDate(
                    this.state.start_date_time,
                    "DD/MM/YYYY"
                  )} - ${getFormattedDate(
                    this.state.end_date_time,
                    "DD/MM/YYYY"
                  )}`}
                  style={{ textAlign: "left", minWidth: "150px" }}
                  placeholder={"Select date range"}
                  className="form-control form-control-sm"
                />
              </DateRangePicker>
            </div>

            <Button
              size="sm"
              type={`submit`}
              onClick={this.filterList}
              variant="primary"
            >
              Filter
            </Button>
          </form>
          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body">
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      <th>Product Title</th>
                      <th>SKU</th>
                      <th>Blocked Quantity</th>
                      <th>Currently Blocked </th>
                      <th>Garbaged Quantity</th>
                      <th>Unblocked Quantity</th>
                      <th>Blocked Reason</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.blockItems.length > 0 ? (
                      this.state.blockItems.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {(this.state.page - 1) * this.state.per_page +
                                index +
                                1}
                            </td>
                            <td>{item.product_title}</td>
                            <td>{item.sku}</td>
                            <td>{item.blocked_quantity}</td>
                            <td>
                              {item.blocked_quantity -
                                (item.garbage_quantity +
                                  item.unblocked_quantity)}
                            </td>
                            <td>{item.garbage_quantity}</td>
                            <td>{item.unblocked_quantity}</td>
                            <td style={{ width: "150px" }}>
                              {item.blocked_reason}
                            </td>
                            <td style={{ width: "200px" }}>
                              <Button
                                className="mr-2"
                                variant="danger"
                                size="sm"
                                onClick={() => this.handleGerbageModal(item.id)}
                              >
                                Garbage
                              </Button>

                              <Button
                                variant="success"
                                size="sm"
                                onClick={() => this.handleUnBlockModal(item.id)}
                              >
                                Unblock
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={9}>No Products Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {this.state.total_data > 0 ? (
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </Loader>
        </div>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.BOTTOM_RIGHT}
        />
        <UnBlockModal
          unblocked_details={this.state.unblocked_details}
          show={this.state.showUnBlockModal}
          handleClose={this.handleCloseUnBlockModal}
        />
        <GerbageModal
          garbage_details={this.state.unblocked_details}
          show={this.state.showGarbageModal}
          handleClose={this.handleCloseGerbageModal}
        />
      </App>
    );
  }
}

export default withRouter(List);
