import React, { Component } from 'react';
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import PromotionForm from "./EditForm2";
import { MdArrowBack } from "react-icons/all";
import moment from 'moment';
import { updateData, getDetails } from '../../services/baseServices'


class Edit extends Component {
  state = {
    promoDetails: {},
    loading: true,
    formData: {
      categoryType: '',
      category_ids: [],
      public_visibility: false,
      is_active: true,
      from_date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      to_date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      customersPhone: "",
      skus: "",
      skuType: "",
    },
    conf_fields: [],
    fields: [],
    errors: {
      from_date: '',
      to_date: '',
      customersPhone: '',
      skus: '',
      skuType: '',
      categoryType: ''
    }
  };

  componentDidMount() {
    getDetails("coupons", this.props.match.params.id)
      .then(res => {
        let xxx = {
          coupon: res.data.coupon_code,
          discount_amount: res.data.discount_amount,
          from_date: moment(res.data.start_at).format("YYYY-MM-DD HH:mm"),
          to_date: moment(res.data.end_at).format("YYYY-MM-DD HH:mm"),
          // from_date: moment(res.data.start_at).format("YYYY-MM-DD"),
          // to_date: moment(res.data.end_at).format("YYYY-MM-DD"),
          is_active: res.data.is_active,
          max_limit: res?.data?.max_limit,
          discount_type: res?.data?.discount_type,
          coupon_type: res?.data?.coupon_type,
          max_user_limit: res?.data?.max_user_limit,
          used_count: res?.data?.used_count,
          skus: res?.data?.skus,
          skuType: res?.data?.sku_inclusion_type === 'not_applicable' ? '' : res?.data?.sku_inclusion_type,
          phone_numbers: res?.data?.phone_numbers,
          categoryType: res?.data?.coupon_categories?.category_inclusion_type ? res?.data?.coupon_categories?.category_inclusion_type : '',
          category_ids: res?.data?.coupon_categories?.categories ? res?.data?.coupon_categories?.categories?.map(i => parseInt(i?.id)) : [],
          public_visibility: res?.data?.is_visible,
        }
        this.setState({
          promoDetails: xxx,
          loading: false,
          formData: xxx
        })
      })
      .catch(errMsg => {
        this.setState({ loading: false })
      });
  }

  handleFieldsConfiguration = (fields) => {
    this.setState({ conf_fields: fields })
  }

  handleDateTimeOnChange = (key, date) => {
    const formData = { ...this.state.formData };
    formData[key] = moment(date).format("YYYY-MM-DD HH:mm");
    this.setState({ formData }, () => {
      this.checkDateValidation(key);
    });
  };

  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };
    let err = { ...this.state.errors };
    if (e.target.type === "radio") {
      if (e.target.name === 'is_active') {
        formData[e.target.name] = JSON.parse(e.target.value)
      }
      else {
        formData[e.target.name] = e.target.value
        let err = { ...this.state.errors };
        err[e.target.name] = "";

        this.setState({ formData }, () => {
          this.setState({ errors: err });
        });
        this.setState({ formData })
      }
    }
    else if (e.target.type === "checkbox") {
      formData[e.target.name] = !this.state.formData.public_visibility;
    }
    else {
      formData[e.target.name] = e.target.value;
    }
    this.setState({ formData }, () => { });
  }

  onPromoFieldChange = (id, name, value) => {
    let fieldsArray = this.state.fields.filter(item => item.name !== name)
    let fieldObject =
    {
      id: id,
      name: name,
      value: value
    };
    fieldsArray.push(fieldObject);
    this.setState({ fields: fieldsArray }, () => {
      if (this.state.isFormValidated) {
        this.isValidFields()
      }
    })
  }

  checkDateValidation = (key) => {
    const formData = { ...this.state.formData };
    let err = { ...this.state.errors }
    if (!formData[key]) {
      console.log(key)
      err[key] = key + ' is required';
    } else {
      console.log(key)
      err[key] = '';
    }

    this.setState({ errors: err });
  }

  handleTimeOnChange = (key, time) => {
    const formData = { ...this.state.formData };
    formData[key] = time;
    this.setState({ formData });
  }

  handleDateOnChange = (key, date) => {
    const formData = { ...this.state.formData };
    formData[key] = moment(date).format("YYYY-MM-DD");
    this.setState({ formData }, () => {
      this.checkDateValidation(key);
    });
  }

  handleDayChange = (weekDays) => {
    const formData = { ...this.state.formData };
    let days =
      weekDays.filter(item => item.selected).map(item => {
        return item.day_value
      })
    formData.days = days;
    this.setState({ formData }, () => {
      console.log(formData)
    });
  }

  isValidFields = () => {
    let validFields = []
    let err = { ...this.state.errors }
    this.state.conf_fields.map(con_field => {
      let field = this.state.fields.find(field => field.name == con_field.name)
      if (!field || !field.value || field.value.length < 0) {
        err[con_field.name] = con_field.title + ' is required';
        validFields.push(false)
      }
      else {
        err[con_field.name] = '';
        validFields.push(true)
      }
    })
    this.setState({ errors: err });
    let isInvalid = validFields.includes(false)

    return !isInvalid
  }

  isValid = () => {
    let valid = true;
    let err = { ...this.state.errors }

    if (!this.state.formData.from_date) {
      valid = false
      err["from_date"] = 'From date is required';
    }
    if (!this.state.formData.to_date) {
      valid = false
      err["to_date"] = 'To date is required';
    }
    if(this.state.formData.coupon_type !=='first_registration') {
      if (this.state.formData.category_ids.length > 0 && this.state.formData.categoryType == "") {
        valid = false;
        err["categoryType"] = "Non Applicable is selected, Please deselect category";
      }
      if (this.state.formData.categoryType && this.state.formData.category_ids.length <= 0) {
        valid = false;
        err["categoryType"] = "Please Select Category ";
      }
      if (this.state.formData.skuType == '' && this.state.formData.skus != "") {
        valid = false;
        err["skuType"] = "Not Applicable is selected, Please Remove Skus";
      }
      if (this.state.formData.skuType != '' && this.state.formData.skus == '') {
        valid = false;
        err["skuType"] = "Please Enter skus ";
      }

      if (this.state.formData.customersPhone) {
        const regex = /^\d+(?:,\d+)*$/g;
        const found = this.state.formData.customersPhone.match(regex);
        if (found == null) {
          valid = false;
          err["customersPhone"] = "customers Phone Number is not in valid format with not spaces (Exp: 01883****49,01883****71";
        }
      }

      if (this.state.formData.skus) {
        const regex = /^\S+(?:,\S+)*$/g;
        const found = this.state.formData.skus.match(regex);
        if (found == null) {
          valid = false;
          err["skus"] = "SKUs is not in valid format with not spaces (Exp: sku1,sku2)";
        }
      }
    }
    this.setState({ errors: err });

    return valid //&& this.isValidFields();
  }

  handleCustomerOnChange = (key, value) => {
    const formData = { ...this.state.formData };
    formData[key] = value;
    let err = { ...this.state.errors };
    err[key] = "";
    if (value == 0 && value == "") {
      err[key] = "";
    }
    this.setState({ formData }, () => {
      this.setState({ errors: err });
    });
    this.setState({ formData })
  }

  handleUpdateFlashSale = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({ isFormValidated: true })
      if (!this.isValid()) {
        toast.error("Please fill all(*) required fields")
        this.setState({ isSubmitting: false })
      }
    } else {
      this.setState({ isSubmitting: true }, () => {
        this.setState({ isFormValidated: true })
        if (!this.isValid()) {
          toast.error("Please fill all(*) required fields")
          this.setState({ isSubmitting: false })
          return
        }

        let formData = {
          "coupon_type": this.state.formData?.coupon_type,
          "discount_amount": this.state.formData?.discount_amount,
          "start_at": new Date(`${this.state.formData?.from_date}`),
          "end_at": new Date(`${this.state.formData?.to_date}`),
          // "start_at": new Date(`${this.state.formData?.from_date} ${this.state.formData?.start_time}`),
          // "end_at": new Date(`${this.state.formData?.to_date} ${this.state.formData?.end_time}`),
          "is_active": this.state.formData?.is_active,
          "discount_type": this.state.formData?.discount_type,
          "max_limit": this.state.formData?.max_limit,
          "coupon_category_attributes": {
            "category_inclusion_type": this.state.formData?.categoryType == "" ? null : this.state.formData?.categoryType,
            "category_ids": this.state.formData?.category_ids
          },
          };
        if(this.state.formData?.coupon_type !== 'first_registration') {
          formData = Object.assign(formData,{

          "max_user_limit": this.state.formData?.max_user_limit,
          "used_count": this.state.formData?.used_count,
          'skus': this.state.formData?.skus,
          'phone_numbers': this.state.formData?.customersPhone,
          "is_visible": this.state.formData?.public_visibility,
          
          "sku_inclusion_type": this.state.formData?.skuType == '' ? 'not_applicable' : this.state.formData?.skuType,
        
          }) 
        }
        updateData(`coupons/${this.props.match.params.id}`, formData)
          .then(res => {
            if (res.success) {
              this.props.history.push(`/promo-coupon/list`);
              toast.success(res.message);
            }
            else {
              toast.error(res.message);
              this.setState({ isSubmitting: false })
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({ isSubmitting: false })
          });
      })
    }
  }

  render() {

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Update Promo Coupon</h2>
          <div className="ml-auto">
            <Link to={`/promo-coupon/list`} className={`btn btn-sm btn-link`}><MdArrowBack />
              Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              {!this.state.loading &&
                <PromotionForm
                  handleCustomerOnChange={this.handleCustomerOnChange}
                  handleDateTimeOnChange={this.handleDateTimeOnChange}
                  handleClick={this.handleUpdateFlashSale}
                  handleInputOnChange={this.handleInputOnChange}
                  formData={this.state.formData}
                  isSubmitting={this.state.isSubmitting}
                  errors={this.state.errors}
                  promotion={this.state.promoDetails}
                />
              }
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);