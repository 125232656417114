import React, { Component } from "react";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import RiderForm from "../rider/Form";
import { add } from "../../services/baseServices";
import { MdArrowBack } from "react-icons/all";

class Add extends Component {
  constructor(props) {
    super(props);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
  }
  state = {
    formData: {},
    isFormValidated: false,
    errors: {
      name: "Name is required",
      email: "Email is required",
      phone: "Phone is required",
      password_hash: "Password is required",
      password_confirmation: "Password confirmation is required",
    },
  };

  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };
    if (
      e.target.name === "name" &&
      e.target.value.match(e.target.pattern) === null
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          name: "No special characters and numbers are allowed",
        },
      });
    } else if (
      e.target.name === "email" &&
      e.target.value.match(e.target.pattern) === null
    ) {
      this.setState({
        errors: { ...this.state.errors, email: "Please provide valid email" },
      });
    } else if (
      e.target.name === "phone" &&
      e.target.value.match(e.target.pattern) === null
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          phone: "Please provide valid BD phone number",
        },
      });
    } else if (
      e.target.name === "password_hash" &&
      e.target.value.match(e.target.pattern) === null
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          password_hash:
            "At least one number and one uppercase and lowercase letter, and more than 8 characters",
        },
      });
    } else if (
      e.target.name === "password_confirmation" &&
      formData["password_hash"] !== e.target.value
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          password_confirmation: "Password doesn't match",
        },
      });
    }
    formData[e.target.name] = e.target.value;
    this.setState({ formData }, () => {});
  };

  handleAddItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        let formData = { ...this.state.formData };
        const phone = formData["phone"].length === 14 ? formData["phone"].substring(3) : formData["phone"];
        formData = {...formData, phone};

        if (formData.password_hash === formData.password_confirmation) {
          delete formData.password_confirmation;
          add("riders", "rider", formData)
            .then((res) => {
              if(res?.status_code === 201){
                this.props.history.push(`/rider/list`);
                toast.success("Rider has been created successfully.");
              }else{
                toast.error(res.message);
              }
              this.setState({ isSubmitting: false });
            })
            .catch((errMsg) => {
              toast.error(errMsg);
              this.setState({ isSubmitting: false });
            });
        } else {
          toast.error("Password doesn't match with confirmed password");
          this.setState({ isSubmitting: false });
        }
      });
    }
  };

  render() {
    const { formData } = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Rider</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/rider/list`}
              className={`btn btn-sm btn-link`}
            >
              <MdArrowBack />
              Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <RiderForm
                handleClick={this.handleAddItem}
                handleInputOnChange={this.handleInputOnChange}
                formData={this.state.formData}
                isSubmitting={this.state.isSubmitting}
                isFormValidated={this.state.isFormValidated}
                isAddForm={true}
                errors={this.state.errors}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Add);
