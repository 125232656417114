import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../../assets/scss/promo.scss";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import DateTimePicker from "react-datetime-picker";
import { FiCalendar } from "react-icons/all";
import moment from "moment";
import { Tree } from 'antd';
import { getPaginatedList } from "../../services/baseServices";



const CreateForm2 = ({ handleClick, handleInputOnChange, handleDateTimeOnChange,
    formData, isSubmitting, errors, handleCustomerOnChange, isvalid
}) => {
    const [minDateFrom, setFormDateTime] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [minDateTo, setToDateTime] = useState(moment(new Date()).format("YYYY-MM-DD"))
    useEffect(() => {
        setToDateTime(moment(formData?.from_date).format("YYYY-MM-DD"))
    }, [formData])

    const handleDateTimeChange = (key, dateTime) => {
        handleDateTimeOnChange(key, dateTime);
    };
    const handleCustomerChange = (key, value) => {
        handleCustomerOnChange(key, value)
    }

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getPaginatedList("categories/tree_details")
            .then(res => {
                setCategories(res.data.data)
            })
            .catch(errMsg => {
                console.log('Error Message: ', errMsg)
            })
    }, [])

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const onExpand = (expandedKeysValue) => {
        // console.log('onExpand', expandedKeysValue);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue) => {
        // console.log('onCheck', checkedKeysValue);
        setCheckedKeys(checkedKeysValue);
        formData.category_ids = checkedKeysValue;

    };

    const onSelect = (selectedKeysValue, info) => {
        // console.log('onSelect', info);
        setSelectedKeys(selectedKeysValue);
    };

    return (
        <>
            <Form
                noValidate
                onSubmit={handleClick}>
                <Form.Group as={Row} controlId="orderType">
                    <Form.Label column sm="3">
                        Coupon Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                        <Form.Control
                            required
                            name="coupon_type"
                            onChange={e => {
                                handleInputOnChange(e);
                            }}
                            as="select" type="text">

                            <option value='first_registration'>First Order Coupon</option>
                            <option value='multi_user'>For Multiple Users</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Coupon Type is required
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId={"coupon"}>
                    <Form.Label column sm="3">
                        Coupon <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                        <Form.Control
                            name="code"
                            onChange={handleInputOnChange}
                            required
                            type="text"
                            min={6}
                            maxLength={25}
                        />
                        <Form.Control.Feedback type="invalid">
                            Coupon is required
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="from-date">
                    <Form.Label column sm="3">
                        From Date<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                        <DateTimePicker
                            value={new Date(formData?.from_date)}
                            minDate={new Date(minDateFrom)}
                            // name="from_date"
                            required={true}
                            disableClock={true}
                            clearIcon={null}
                            calendarIcon={<FiCalendar />}
                            yearPlaceholder="yyyy"
                            dayPlaceholder="dd"
                            monthPlaceholder="mm"
                            minutePlaceholder="mm"
                            hourPlaceholder="hh"
                            amPmAriaLabel="AM/PM"
                            format="yyyy-MM-dd h:mm a"
                            onChange={(date) => handleDateTimeChange('from_date', date)}
                        />

                        <Form.Control.Feedback className="d-block" type="invalid">
                            {errors?.from_date}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="to-date">
                    <Form.Label column sm="3">
                        To Date<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>

                        <DateTimePicker
                            value={new Date(formData?.to_date)}
                            minDate={new Date(minDateTo)}
                            required={true}
                            // name="to_date"
                            clearIcon={null}
                            calendarIcon={<FiCalendar />}
                            disableClock={true}
                            yearPlaceholder="yyyy"
                            dayPlaceholder="dd"
                            monthPlaceholder="mm"
                            minutePlaceholder="mm"
                            hourPlaceholder="hh"
                            amPmAriaLabel="AM/PM"
                            format="yyyy-MM-dd h:mm a"
                            onChange={(date) => handleDateTimeChange('to_date', date)}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.to_date}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                < Form.Group as={Row} controlId="discount_type" >
                    <Form.Label column sm="3">
                        Discount Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                        <Form.Control
                            required
                            name="discount_type"
                            onChange={handleInputOnChange}
                            as="select" type="text">
                            <option value="fixed">Fixed Value</option>
                            <option value="percentage">Percentage</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select a Type.</Form.Control.Feedback>
                    </Col>
                </Form.Group >

                <Form.Group as={Row} controlId={"discount_amount"}>
                    <Form.Label column sm="3">
                        Discount <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                        <Form.Control
                            name="discount_amount"
                            onChange={handleInputOnChange}
                            required
                            min={0}
                            step={1}
                            type="number"
                        />
                        <Form.Control.Feedback type="invalid">
                            Discount is required
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                {
                    formData?.discount_type === 'percentage' &&
                    <Form.Group as={Row} controlId={"max_limit"}>
                        <Form.Label column sm="3">
                            Max Discount Amount <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                            <Form.Control
                                name="max_limit"
                                onChange={handleInputOnChange}
                                required
                                min={0}
                                step={1}
                                type="number"
                            />
                            <Form.Control.Feedback type="invalid">
                                Max Limit is required
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                }
                {
                    formData?.coupon_type !== 'first_registration' &&
                    <>
                        <Form.Group as={Row} controlId={"max_user_limit"}>
                            <Form.Label column sm="3">
                                Max Use Limit(Total Count) <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name="max_user_limit"
                                    onChange={handleInputOnChange}
                                    required
                                    min={1}
                                    step={1}
                                    type="number"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Max Use Limit(Total Count) is required
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId={"used_count"}>
                            <Form.Label column sm="3">
                                Max Use Limit (Per User) <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name="used_count"
                                    defaultValue={1}
                                    onChange={handleInputOnChange}
                                    required
                                    min={1}
                                    step={1}
                                    type="number"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Max Use Limit (Per User) is required
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <>
                            <Form.Group as={Row} controlId="active">
                                <Form.Label column sm="3">
                                    Catogories <span className="text-danger">*</span>
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                    <RadioGroup
                                        row
                                        name="categoryType"
                                        value={formData?.categoryType}
                                        onChange={handleInputOnChange}>
                                        <FormControlLabel className="promo-status"
                                            value={''}
                                            control={<Radio />}
                                            label="Non Applicable"
                                        />
                                        <FormControlLabel className="promo-status"
                                            value={'included'}
                                            control={<Radio />}
                                            label="Included"
                                        />
                                        <FormControlLabel className="promo-status"
                                            value={'excluded'}
                                            control={<Radio />}
                                            label="Excluded"
                                        />
                                    </RadioGroup>
                                    <Tree
                                        checkable
                                        onExpand={onExpand}
                                        expandedKeys={expandedKeys}
                                        autoExpandParent={autoExpandParent}
                                        onCheck={onCheck}
                                        checkedKeys={checkedKeys}
                                        onSelect={onSelect}
                                        selectedKeys={selectedKeys}
                                        treeData={categories}
                                    />

                                    <p className="text-danger" >
                                        {
                                            errors?.categoryType
                                        }
                                    </p>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="public_visibility">
                                <Form.Label column sm="3" >
                                    Publicly visible
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                    <Form.Check
                                        custom
                                        inline
                                        type="checkbox"
                                        name="public_visibility"
                                        onChange={handleInputOnChange}
                                        checked={formData?.public_visibility}
                                        label="Publicly visible"
                                    />
                                </Col>
                            </Form.Group>
                        </>


                        <Form.Group as={Row} controlId={"skus"}>
                            <Form.Label column sm="3">
                                SKUs (with comma seperation)
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <RadioGroup
                                    row
                                    name="skuType"
                                    value={formData?.skuType}
                                    onChange={handleInputOnChange}>
                                    <FormControlLabel className="promo-status"
                                        value={''}
                                        control={<Radio />}
                                        label="Not Applicable"
                                    />
                                    <FormControlLabel className="promo-status"
                                        value={'included'}
                                        control={<Radio />}
                                        label="Included"
                                    />
                                    <FormControlLabel className="promo-status"
                                        value={'excluded'}
                                        control={<Radio />}
                                        label="Excluded"
                                    />
                                </RadioGroup>
                                <p className="text-danger" >
                                    {
                                        errors?.skuType
                                    }
                                </p>
                                <Form.Control
                                    name="skus"
                                    onChange={(e) => handleCustomerChange('skus', e.target.value)}
                                    as="textarea"
                                    rows={3}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.skus}
                                </Form.Control.Feedback>
                                <p className="text-danger" >
                                    {
                                        errors?.skus
                                    }
                                </p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"customersPhone"}>
                            <Form.Label column sm="3">
                                Phone Numbers (with comma seperation)
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name="customersPhone"
                                    onChange={(e) => handleCustomerChange('customersPhone', e.target.value)}
                                    as="textarea"
                                    rows={3}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.customers}
                                </Form.Control.Feedback>
                                <p className="text-danger" >
                                    {
                                        errors?.customersPhone
                                    }
                                </p>
                            </Col>
                        </Form.Group>
                    </>
                }

                <Form.Group as={Row} controlId="active">
                    <Form.Label column sm="3">
                        Status
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                        <RadioGroup
                            row
                            name="is_active"
                            value={formData?.is_active}
                            onChange={handleInputOnChange}>
                            <FormControlLabel className="promo-status"
                                value={true}
                                control={<Radio />}
                                label="Active"
                            />
                            <FormControlLabel className="promo-status"
                                value={false}
                                control={<Radio />}
                                label="In Active"
                            />
                        </RadioGroup>
                    </Col>
                </Form.Group>

                <div className="mt-3 text-center">
                    <Link
                        to={`/promo-coupon/list`}
                        className="mr-2 btn btn-md btn-danger">
                        Cancel
                    </Link>
                    <Button
                        size="md"
                        type={`submit`}
                        disabled={isSubmitting}
                        variant="primary">
                        {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                </div>
            </Form >
        </>
    )
}

export default CreateForm2