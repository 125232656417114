import React, { Component } from 'react';
import App from "../../App";
import Loader from 'react-loader';
import { Link, withRouter } from "react-router-dom";
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import { getPaginatedList,  putData2, getList } from "../../services/baseServices";
import {OverlayTrigger, Tooltip, Button} from "react-bootstrap";
import { FiEye, FiPlus } from "react-icons/all";
import { getFormattedDate, humanize } from '../../helper/utils';
import ConfirmationModal from "../common/Modal";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"

class ForwardChallanList extends Component {
    constructor() {
        super();
        this.state = {
            challanList: [],
            activePage: 1,
            per_page: 15,
            page: 1,
            temp_params: {},
            query_params: {},
            loading: false,
            distributors: []
        };
    }
    
    getQueryParams = () => {
        let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
        return params;
      }

    getPaginatedChallans = (params) => {
        this.setState({loading: true})
        getPaginatedList("challans", params)
        .then(res => {
            this.setState({ 
                challanList: res.data?.data,
                per_page: res.headers['x-per-page'],
                total_data: res.headers['x-total'],
                loading: false
             })
          })
        .catch(errMsg => {
            this.setState({loading: false})
        })
      }

      handleOnFieldChange = (e) => {
        const queryData = {...this.state.temp_params};
        queryData[e.target.name] = e.target.value;
        this.setState({temp_params: queryData}, () => {
        });
      }

      handleOnSearch = (e) => {
        e.preventDefault();
        const tempParam = this.state.temp_params;
          this.setState({per_page: 50, page: 1, query_params: tempParam, isLoading:true}, () => {
            this.getPaginatedChallans(this.state.temp_params);
          });
      }

      handlePageChange = (pageNumber) => {
        this.setState({page: pageNumber, activePage: pageNumber}, () => {
          this.getPaginatedChallans(this.getQueryParams());
        });
      }

    componentDidMount() {
        this.getPaginatedChallans(this.getQueryParams())
        getList("distributors")
        .then((res) => {
          this.setState({distributors: res?.data});
        })
        .catch((errMsg) => {});
    }

    dispatchChallan = (challanId)=>{
      putData2(`challans/${challanId}/dispatch`)
        .then(res => {
          if(res.success) {
            this.getPaginatedChallans(this.state.temp_params)
            toast.success("Challan has been dispatched successfully.");
          }
          else {
            toast.error(res.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg);
        }); 
    }

    debouncedOnDispatch = _.debounce((challanId)=>{
      this.dispatchChallan(challanId)
    }, DEBOUNCE_TIME);
  
    debouncedOnDispatchChallan = (challanId) => {
      this.debouncedOnDispatch(challanId);
    }

    render() {
        return(
            <App layout={''}>
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Challan List</h2>
                        <div className="ml-auto" style={{display: "flex"}}>
                          <Link to={`${process.env.PUBLIC_URL}/challan/create`} className="btn btn-sm btn-secondary ml-2"><FiPlus/> Create Challan</Link>
                        </div>
                    </div>
                    <form className="form-inline mb-3">
                      <div className="form-group mr-3">
                        <label htmlFor="distributor_id" className="sr-only">
                        Distributor
                        </label>
                        <select
                        id="distributor_id"
                        name="distributor_id"
                        className="form-control form-control-sm"
                        onChange={this.handleOnFieldChange}
                        >
                        <option value="">--Select Distributor--</option>
                        {this.state.distributors.map((item, i) => {
                          return (
                          <option
                            key={i}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                          );
                        })}
                        </select>
                      </div>
                      <div className="form-group mr-3">
                        <label htmlFor="status" className="sr-only">
                          Status
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="form-control form-control-sm"
                          onChange={this.handleOnFieldChange}
                        >
                          <option value="">--Select Challan Status--</option>
                          <option value="initiated">Initiated</option>
                          <option value="in_transit_to_dh">In Transit to DH</option>
                          <option value="partially_received">Partially Received</option>
                          <option value='completed'>Completed</option>
                        </select>
                      </div>
                      <Button
                        size="sm"
                        type={`submit`}
                        onClick={this.handleOnSearch}
                        variant="primary">
                        Filter
                      </Button>
                    </form>
                    <div className="card-block">
                        <div className="block-body">
                            <Loader loaded={!this.state.loading}>
                                <table className="order-table table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Challan No</th>
                                        <th>Distributor</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.challanList?.map((challan, index) => {
                                        return ( 
                                            <tr key={index}>
                                                <td>{challan?.id}</td>
                                                <td>{challan?.distributor?.name}</td>
                                                <td>{getFormattedDate(challan?.created_at, 'DD-MM-YYYY')}</td>
                                                <td>{humanize(challan?.status)}</td>
                                                <td >
                                                  <div className="btn-group">
                                                    <OverlayTrigger
                                                      key={index}
                                                      placement="top"
                                                      overlay={
                                                        <Tooltip id={`tooltip-view`}>
                                                          View
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <Link to={`${process.env.PUBLIC_URL}/challan/details/${challan.id}`}
                                                            className="btn btn-sm btn-default mr-2"><FiEye/></Link>
                                                    </OverlayTrigger>
                                                    {
                                                        challan.status === 'initiated'
                                                        ?
                                                        <OverlayTrigger
                                                        key="view"
                                                        placement="top"
                                                        overlay={
                                                        <Tooltip id={`tooltip-view`}>Send to DH</Tooltip>
                                                        }
                                                    >
                                                        <ConfirmationModal 
                                                            customBtn = {true}
                                                            btnText = "Send to DH"
                                                            variant = "primary"
                                                            title="Send to DH" 
                                                            body="Are you sure to dispatch the challan?" 
                                                            handleAction={() =>this.debouncedOnDispatchChallan(challan.id)}/>
                                                      </OverlayTrigger>
                                                      :
                                                      <button className='btn btn-sm btn-primary' disabled>
                                                        Send to DH
                                                      </button>
                                                    }
                                                    
                                                  </div>
                                                </td>
                                            </tr>
                                        )
                                        })
                                    }
                                    </tbody>
                                </table>
                                <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.per_page}
                                        totalItemsCount={this.state.total_data}
                                        pageRangeDisplayed={10}
                                        onChange={this.handlePageChange.bind(this)}
                                        />
                                </div>
                            </Loader>
                        </div>
                    </div>

                </div>
            </App>
        )
    }
}

export default withRouter(ForwardChallanList);