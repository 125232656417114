import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {getDetails, update} from "../../services/baseServices";
import RiderForm from "./Form";
import LocationForm from "../location/Form";
import {AiOutlineArrowLeft} from "react-icons/all";

class Edit extends Component {
  state = {
    formData: {},
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false
  };

  componentDidMount() {
    getDetails("locations", this.props.match.params.id)
      .then(res => {
        this.setState({ formData: {...res} });
      })
      .catch(errMsg => {
      });
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
    });
  }

  handleUpdateItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        const formData = {...this.state.formData};
        const location = {
          code: formData.code
        }
        update("locations", this.props.match.params.id, "location", location)
          .then(res => {
            if(res?.id) {
              this.props.history.push(`/location/list`);
              toast.success("Location code has been updated successfully.");
              } else {
                toast.error(res?.message);
                this.setState({isSubmitting: false})
              }
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Location</h2>
          <Link to={`${process.env.PUBLIC_URL}/location/list`}
            className="btn btn-sm btn-link mr-2"><AiOutlineArrowLeft/> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <LocationForm
                handleClick={this.handleUpdateItem}
                handleInputOnChange={this.handleInputOnChange}
                formData={this.state.formData}
                isSubmitting={this.state.isSubmitting}
                isAddForm={false}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Edit);
