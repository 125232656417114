import React, { useState } from "react";
import App from "../../../App";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import { PromotionalSliderForm } from "./PromotionalSliderForm";
import * as yup from "yup";

const MAX_FILE_SIZE = 1 * 1024 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
];

const getErrorMsg = (layout, bannerType) => {
  let errMsg = "";

  if (bannerType === "web") {
    if (layout === 1) {
      errMsg = "Full width web banner dimension must be 1440 x 250 pixel.";
    } else if (layout === 2) {
      errMsg = "Half width web banner dimension must be 720 x 250 pixel.";
    } else {
      errMsg = "One third web width banner dimension must be 500 x 200 pixel.";
    }
  } else {
    errMsg = "App banner dimension must be 767 x 250 pixel.";
  }

  return errMsg;
};
const checkSizeWithLayout = (width, height, layout, bannerType) => {
  let status = false;
  if (bannerType === "web") {
    if (layout === 1) {
      status = width === 1440 && height === 250;
    } else if (layout === 2) {
      status = width === 720 && height === 250;
    } else {
      status = width === 500 && height === 200;
    }
  } else {
    status = width === 767 && height === 250;
  }

  return status;
};
const checkDimension = async (file, layout, bannerType) => {
  console.log(file, layout);
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file"));
    };
    reader.onload = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = function () {
        const status = checkSizeWithLayout(
          this.width,
          this.height,
          layout,
          bannerType
        );
        resolve(status);
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      resolve(false);
    }
  });
};

const Add = () => {
  const [selectedLayout, setSelectedLayout] = useState(1);

  const getBannerSchema = (bannerType) => {
    const bannerSchema = yup.object().shape({
      image_type: yup.string().optional(),
      image_title: yup
        .string()
        .required("Banner title is required.")
        .max(50, "Banner title should contain at most 50 characters.")
        .trim(),
      description: yup
        .string()
        .required("Banner description is required.")
        .max(200, "Banner description should contain at most 200 characters.")
        .trim(),
      redirect_url: yup
        .string()
        .required("Banner redirect url is required.")
        .trim(),
      image_file: yup
        .mixed()
        .test("required", "Banner image is a required", (value) => {
          return value.file && value.file.name;
        })
        .test(
          "fileSize",
          "Uploaded file is to big, image size not more than 1MB.",
          (value, context) => {
            return value.file && value.file.size <= MAX_FILE_SIZE;
          }
        )
        .test(
          "type",
          "Invalid image format. Only (jpg, jpeg, png and webp) format are supported.",
          function (value) {
            return value.file && SUPPORTED_FORMATS.includes(value.file.type);
          }
        )
        .test(
          "dimension",
          getErrorMsg(selectedLayout, bannerType),
          async (value, ctx) => {
            console.log("image_type", ctx);
            let status = await checkDimension(
              value.file,
              selectedLayout,
              ctx?.parent?.image_type
            );
            return status;
          }
        ),
    });

    return bannerSchema;
  };

  const promoSchema = yup
    .object({
      promo_title: yup
        .string()
        .required("Promotion title is required.")
        .max(50, "Promotion title should contain at most 50 characters.")
        .trim(),
      layout: yup
        .number()
        .typeError("Layout is required")
        .required("Layout is required"),
      is_visible: yup
        .boolean()
        .typeError("Publish is required")
        .required("Publish is required"),
      bannerWeb: yup.array().of(getBannerSchema("web")),
      bannerApp: yup.array().of(getBannerSchema("app")),
    })
    .required();

  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Add Promotional Slider</h2>
        <div className="ml-auto">
          <Link
            to={`${process.env.PUBLIC_URL}/promotional-slider/list`}
            className="btn btn-sm btn-link"
          >
            <FaLongArrowAltLeft /> Back to Slider List
          </Link>
        </div>
      </div>

      <div className="card-block">
        <div className="block-body">
          <PromotionalSliderForm
            schema={promoSchema}
            setSelectedLayout={setSelectedLayout}
          />
        </div>
      </div>
    </App>
  );
};

export default Add;
