import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import FileUploader from "../../components/FileUploader"
import { toast } from "react-toastify";
import JoditEditorComponent from "../../components/JoditEditorComponent";
import BrowseButton from "../../components/BrowseButton";
import ConfirmationModal from "../common/Modal";
import { IoMdClose } from "react-icons/io";
import { Select } from 'antd';
import { getData, deleteData } from "../../services/baseServices";
import { convertToSlug } from "../../helper/utils";
import ImageUpload from './ImageUpload'
import MetaInfo from '../../components/MetaInfo'

const PATTERN = "[a-zA-Z0-9]+([a-zA-Z0-9 ]+)?"
const MAX = "50"
const FILTER_OPTIONS = [{ key: 'product_attribute', value: 'Product Attribute' }, { key: 'price_range', value: 'Price Range' }, { key: 'product_type', value: 'Product Type' }, { key: 'keyword', value: 'Keyword' }]

const BrandForm = ({ details, metaInfo, setMetaInfo, existingBannerImages, setExistingBannerImages, setNewBannerImages, handleSubmit, createForm, isSubmitting }) => {
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({
        name: '',
        slug: '',
        bn_name: '',
        logo_file: '',
        banner_file: '',
        video_url: '',
        branding_title: '',
        bn_branding_title: '',
        branding_sub_title: '',
        bn_branding_sub_title: '',
        branding_image_file: '',
        redirect_url: '',
        branding_video_url: ''

    })
    const [allCategories, setAllCategories] = useState([]);
    const [allProductTypes, setAllProductTypes] = useState([]);
    const [allProductAttributes, setAllProductAttributes] = useState([]);
    const [selectedFilteringAttributes, setSelectedFilteringAttributes] = useState([]);
    const [uniqueSlug, setUniqueSlug] = useState('');
    const { id } = useParams();



    // useEffect(()=>{
    //     checkUniqueSlug(formData?.slug, this.props.match.params?.id, "Brand");
    // }, [formData?.slug])


    const checkUniqueSlug = (slug, type) => {
        if (slug) {
            const queryObj = id
                ? { slug, id, type }
                : { slug, type }
            getData('slugs/uniqueness', queryObj)
                .then(res => setUniqueSlug(res.success))
                .catch(err => setUniqueSlug(''))
        } else {
            setUniqueSlug('');
        }
    }

    const handleOnFieldChange = (e) => {
        let { name, value, pattern, checked } = e.target;
        if (name === 'name' && value.match(pattern) === null) {
            setErrors(prev => ({ ...prev, [name]: 'No special chars or only spaces are allowed' }));
        }
        else if (name === 'bn_name' && value.match(pattern) === null) {
            setErrors(prev => ({ ...prev, [name]: 'No special chars or only spaces are allowed' }));
        }
        else if (name === 'redirect_url' && value.match(pattern) === null) {
            setErrors(prev => ({ ...prev, [name]: 'Please provide valid URL' }));
        }
        else if (name === 'branding_video_url' && value.match(pattern) === null) {
            setErrors(prev => ({ ...prev, [name]: 'Please provide valid youtube URL' }));
        }
        else if (name === 'is_own_brand' || name === 'public_visibility' || name === 'brand_info_visible' || name === 'homepage_visibility') {
            value = checked
        } else if (name === 'name' && value.length > MAX) {
            setErrors(prev => ({ ...prev, [name]: `Max ${MAX} chars can be input` }));
        } else if (name === 'bn_name' && value.length > MAX) {
            setErrors(prev => ({ ...prev, [name]: `Max ${MAX} chars can be input` }));
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
        if (name === 'name' && createForm) {
            const slug = convertToSlug(value)
            setFormData(prev => ({ ...prev, ['slug']: slug }));
            setFormData(prev => ({ ...prev, [name]: value }));
        } else if (name === "slug") {
            const slug = convertToSlug(value)
            setFormData(prev => ({ ...prev, ['slug']: slug }));
        }
        else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
        if ((createForm && name === "name") || name === "slug") {
            checkUniqueSlug(value, "Brand")
        }
    }

    const handleJoEditorOnChange = (name, value, isRequired) => {
        if (value.length < 1 && isRequired) {
            setErrors(prev => ({ ...prev, name: 'This field cannot be empty' }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    }

    const handleOnFileChange = (name, files) => {
        setFormData(prev => ({ ...prev, [name]: files[0] }));
        setErrors(prev => ({ ...prev, [name]: '' }))
    }


    const addOneKeyFeature = () => {
        let tempData = formData;
        let campaigns_attributes = tempData?.campaigns_attributes ? tempData.campaigns_attributes : [];
        campaigns_attributes = [...campaigns_attributes, { title: '', title_bn: '', page_url: '' }]
        tempData = { ...tempData, campaigns_attributes }
        setFormData(tempData);
    }

    const handleKeyFeature = (e, index, prop) => {
        let tempData = formData;
        let campaigns_attributes = [...tempData.campaigns_attributes]
        campaigns_attributes[index][prop] = e.target.value;
        tempData = { ...tempData, campaigns_attributes }
        setFormData(tempData);
    }
    const removeKeyFeature = (index) => {
        let tempData = formData;
        let campaigns_attributes = [...tempData.campaigns_attributes]
        let camp = campaigns_attributes[index]
        camp._destroy = true
        campaigns_attributes[index] = camp;
        tempData = { ...tempData, campaigns_attributes }
        setFormData(tempData);
    }

    const onFileUploadFailure = (name, err) => {
        setFormData(prev => ({ ...prev, [name]: undefined }));
        setErrors(prev => ({ ...prev, [name]: err }))
    }

    const isValid = () => {
        let valid = true
        if (!formData.name) {
            setErrors(prev => ({ ...prev, name: 'Name is required' }))
            valid = false
        }
        else if (formData.name.length > MAX) {
            setErrors(prev => ({ ...prev, name: `Max ${MAX} chars can be input` }));
            valid = false
        }

        else if (formData.bn_name.length > MAX) {
            setErrors(prev => ({ ...prev, bn_name: `Max ${MAX} chars can be input` }));
            valid = false
        }

        if (formData?.brand_info_visible && formData?.branding_layout === 'box' && !formData.short_description) {
            setErrors(prev => ({ ...prev, short_description: 'Short description is required' }))
            valid = false
        }
        if (formData?.brand_info_visible && formData?.branding_layout === 'box' && !formData.short_description_bn) {
            setErrors(prev => ({ ...prev, short_description_bn: 'Short description in Bangla is required' }))
            valid = false
        }
        if (errors.name || errors.bn_name || errors.short_description || errors.short_description_bn) {
            valid = false
        }
        return valid;
    }
    // meta info
    const handleOnMetaInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setMetaInfo({ ...metaInfo, [name]: value });


    }
    const handleOnChangeKeywordValue = (en_bn, value) => {
        const mitainfoDetails = { ...metaInfo };
        const valueIdArr = [];
        if (value?.length > 0) {
            value.forEach(val => {
                valueIdArr.push(val.value);
            })
        }
        if (en_bn === 'en') {
            mitainfoDetails.metaKeywords = valueIdArr;
        } else {
            mitainfoDetails.metaKeyKeywordsBn = valueIdArr;
        }
        setMetaInfo(mitainfoDetails)
    }

    const formOnSubmit = (e) => {
        setIsFormValidated(true);
        setErrors({})

        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            form.className += " was-validated";
            isValid()
        } else {
            if (isValid()) {
                let data = formData
                delete data.logo
                delete data.banner

                // meta info
                console.log(data)
                handleSubmit(data)
            } else {
                toast.error("Validation failed")
            }
        }
    }

    const handleAttibuteSelect = (selectedItems) => {
        let tempData = formData;

        let filtering_options_attributes = []
        selectedItems?.map((item) => {
            let seletedAttr = tempData.filtering_options_attributes?.find(i => i.filtering_type === item)
            if (seletedAttr) {
                filtering_options_attributes.push(seletedAttr)
            } else {
                filtering_options_attributes.push({
                    filtering_type: item,
                    filtering_keys: []
                })
            }
        })

        let deletedAttrList = tempData.filtering_options_attributes?.filter(i => !selectedItems.includes(i.filtering_type))
        if (deletedAttrList?.length > 0) {
            let deletedAttr = deletedAttrList[0]
            deletedAttr['_destroy'] = true
            filtering_options_attributes.push(deletedAttr)
        }

        tempData = { ...tempData, filtering_options_attributes };
        setFormData(tempData);
        setSelectedFilteringAttributes(selectedItems)
    }

    const handleFilteringAttributeChange = (selectedItems, name) => {
        let tempData = formData;
        let filtering_options_attributes = tempData.filtering_options_attributes;
        const filter_item = filtering_options_attributes.find(item => item.filtering_type === name);
        filter_item.filtering_keys = selectedItems;
        filtering_options_attributes = [...filtering_options_attributes.filter(item => item.filtering_type !== name), filter_item];
        tempData = { ...tempData, filtering_options_attributes }
        setFormData(tempData);
    }

    const getAllProductAttribute = () => {
        getData('product_attributes', {}).then(res => {
            setAllProductAttributes(res);
        }).catch(err => {
            toast.error(err.message);
        })
    }

    const getAllCategories = () => {
        getData('categories', {}).then(res => {
            setAllCategories(res);
        }).catch(err => {
            toast.error(err.message);
        })
    }

    const getAllProductTypes = () => {
        getData('product_types', {}).then(res => {
            setAllProductTypes(res);
        }).catch(err => {
            toast.error(err.message);
        })
    }



    const deleteBrandImage = (imageId, index) => {
        deleteData(`brands/${imageId}/delete_banner`, { brand_id: details?.id })
            .then(res => {
                if (res.success) {
                    toast.success(res.message);
                    const bannerImages = [...existingBannerImages]
                    bannerImages.splice(index, 1)
                    setExistingBannerImages(bannerImages)
                } else {
                    toast.error(res.message);
                }
            })
            .catch(errMsg => {
                toast.error(errMsg);
            });
    }



    useEffect(() => {
        if (!createForm && details) {
            const tempDetails = details;
            if (tempDetails?.filtering_options_attributes?.length > 0) {
                tempDetails.filtering_options_attributes.map((attr, index) => {
                    if (attr.filtering_type === 'keyword' || attr.filtering_type === 'price_range') {

                    } else {
                        tempDetails.filtering_options_attributes[index].filtering_keys = attr.filtering_keys.map(item => item.id)
                    }
                })
            } else {
                tempDetails.filtering_options_attributes = []
            }
            setFormData(tempDetails);
        } else {
            setFormData(details);
        }

        const attributes = details?.filtering_options_attributes?.length > 0 ? details.filtering_options_attributes.map(item => item.filtering_type) : []
        setSelectedFilteringAttributes(attributes);
        getAllProductAttribute();
        getAllCategories();
        getAllProductTypes();
    }, [details])

    return (
        <div className="main-content">
            <Form
                noValidate
                validated={isFormValidated}
                onSubmit={formOnSubmit}
            >
                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Brand Basic Info</h4>
                    </div>
                    <div className="block-body">
                        <Form.Group as={Row} controlId="is_own_brand">
                            <Form.Label column sm="3" >
                                Brand Type
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom
                                    inline
                                    type="checkbox"
                                    name="is_own_brand"
                                    onChange={handleOnFieldChange}
                                    checked={formData?.is_own_brand}
                                    label="Own Brand"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="public_visibility">
                            <Form.Label column sm="3" >
                                Publicly visible
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom
                                    inline
                                    type="checkbox"
                                    name="public_visibility"
                                    onChange={handleOnFieldChange}
                                    checked={formData?.public_visibility}
                                    label="Publicly visible"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="homepage_visibility">
                            <Form.Label column sm="3" >
                                Home page visible
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom
                                    inline
                                    type="checkbox"
                                    name="homepage_visibility"
                                    onChange={handleOnFieldChange}
                                    checked={formData?.homepage_visibility}
                                    label="Home page visible"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm="3">
                                Brand Name in English <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"name"}
                                    value={formData?.name ? formData?.name : ''}
                                    max={MAX}
                                    required
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.name}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm="3">
                                Slug <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"slug"}
                                    value={formData?.slug ? formData?.slug : ''}
                                    required
                                    // disabled={!createForm}
                                    // readOnly={!createForm}
                                    // onChange={()=> createForm && handleOnFieldChange()}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.slug}
                                </div>
                                {uniqueSlug === false && <div class="slug-err-msg">Please provide an unique slug.</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="bn_name">
                            <Form.Label column sm="3">
                                Brand Name in Bangla <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"bn_name"}
                                    max={MAX}
                                    value={formData?.bn_name ? formData.bn_name : ''}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                    required
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.bn_name}
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="bn_name">
                            <Form.Label column sm="3">
                                Redirect URL
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    placeholder="http(s)://example.com"
                                    name={"redirect_url"}
                                    max={MAX}
                                    value={formData?.redirect_url ? formData.redirect_url : ''}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                    pattern="^(http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.redirect_url}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="logo_file">
                            <Form.Label column sm="3">
                                Logo
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <FileUploader
                                    name="logo_file"
                                    customError={errors.logo_file}
                                    readOnly={false}
                                    onFileSelect={handleOnFileChange}
                                    onFailure={onFileUploadFailure}
                                    dimension={{ width: 300, height: 300 }}
                                    maxSize={160 * 1024}
                                    acceptedFormats={['image/jpg', 'image/jpeg', 'image/png', 'image/webp']}
                                    isMultiple={false} />
                                {
                                    !createForm &&
                                    <img src={`${formData?.logo}`} alt="" width={"300px"} height={"auto"} />
                                }

                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="banner_file">
                            <Form.Label column sm="3">
                                Banners
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <ImageUpload setBannerImages={setNewBannerImages} />
                                {
                                    !createForm && (
                                        <div className="existing-images" style={{ flexWrap: 'wrap' }}>
                                            {existingBannerImages &&
                                                existingBannerImages?.length > 0
                                                ? existingBannerImages.map((img, index) => {
                                                    return (
                                                        <div className="existing-img">
                                                            <ConfirmationModal
                                                                title="Delete"
                                                                body="Are you sure you want to delete?"
                                                                showBtn={`false`}
                                                                handleAction={() => deleteBrandImage(img.id, index)}
                                                            />
                                                            <div>
                                                                <img src={img.url} alt="" />
                                                            </div>
                                                        </div>
                                                    );

                                                })
                                                : ""}
                                        </div>
                                    )}
                            </Col>
                        </Form.Group>
                    </div>
                </div>
                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Branding Info</h4>
                    </div>
                    <div className="block-body">
                        <Form.Group as={Row} controlId="branding_layout">
                            <Col column sm="3">
                                Show Promotion
                            </Col>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom
                                    inline
                                    type="checkbox"
                                    name="brand_info_visible"
                                    onChange={handleOnFieldChange}
                                    checked={formData?.brand_info_visible}
                                    label="Promote"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please please select a type.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="business_type">
                            <Form.Label column sm="3">
                            Business Type <span className="text-danger">*</span>
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                            <Form.Check
                                custom
                                inline
                                type="radio"
                                name="business_type"
                                id={`custom-radio-1`}
                                required
                                onChange={handleOnFieldChange}
                                checked={formData?.business_type && formData.business_type === "b2c"}
                                value={"b2c"}
                                label={`B2C`}
                            />
                            <Form.Check
                                custom
                                inline
                                type="radio"
                                name="business_type"
                                required
                                onChange={handleOnFieldChange}
                                checked={formData?.business_type && formData.business_type === "b2b"}
                                value={"b2b"}
                                id={`custom-radio-4`}
                                label={`B2B`}
                            />
                            <Form.Check
                                custom
                                inline
                                type="radio"
                                name="business_type"
                                required
                                onChange={handleOnFieldChange}
                                checked={formData?.business_type && formData.business_type === "both"}
                                value={"both"}
                                id={`custom-radio-2`}
                                label={`Both(B2B and B2C)`}
                            />

                            <Form.Check
                                custom
                                inline
                                type="radio"
                                name="business_type"
                                required
                                onChange={handleOnFieldChange}
                                checked={formData?.business_type && formData.business_type === "multicat"}
                                value={"multicat"}
                                id={`custom-radio-3`}
                                label={`Multicat`}
                            />

                            <Form.Control.Feedback type="invalid">
                                Please select a Business type.
                            </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="branding_layout">
                            <Col column sm="3">
                                Branding Layout {formData?.brand_info_visible && <span className="text-danger">*</span>}
                            </Col>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom
                                    inline
                                    required={formData?.brand_info_visible}
                                    type="radio"
                                    checked={formData?.branding_layout && formData?.branding_layout === 'full'}
                                    name="branding_layout"
                                    id={`branding_layout-1`}
                                    onChange={handleOnFieldChange}
                                    value={'full'}
                                    label={`Full`}
                                />

                                <Form.Check
                                    custom
                                    inline
                                    type="radio"
                                    required={formData?.brand_info_visible}
                                    checked={formData?.branding_layout && formData?.branding_layout === 'box'}
                                    name="branding_layout"
                                    id={`branding_layout-2`}
                                    onChange={handleOnFieldChange}
                                    value={'box'}
                                    label={`Box`}
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please please select a type.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="branding_promotion_with">
                            <Col column sm="3">
                                Branding Promotion With {formData?.brand_info_visible && <span className="text-danger">*</span>}
                            </Col>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Check
                                    custom
                                    inline
                                    required={formData?.brand_info_visible}
                                    type="radio"
                                    checked={formData?.branding_promotion_with && formData?.branding_promotion_with === 'image'}
                                    name="branding_promotion_with"
                                    id={`branding_promotion_with-1`}
                                    onChange={handleOnFieldChange}
                                    value={'image'}
                                    label={`Image`}
                                />

                                <Form.Check
                                    custom
                                    inline
                                    type="radio"
                                    required={formData?.brand_info_visible}
                                    checked={formData?.branding_promotion_with && formData?.branding_promotion_with === 'video'}
                                    name="branding_promotion_with"
                                    id={`branding_promotion_with-2`}
                                    onChange={handleOnFieldChange}
                                    value={'video'}
                                    label={`Video`}
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please please select a type.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="branding_video_url">
                            <Form.Label column sm="3">
                                Branding Video Url {formData?.branding_promotion_with === "video" && <span className="text-danger">*</span>}
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"branding_video_url"}
                                    pattern="^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$"
                                    value={formData?.branding_video_url ? formData.branding_video_url : ''}
                                    required={formData?.brand_info_visible && formData?.branding_promotion_with === "video" ? true : false}
                                    readOnly={formData?.branding_promotion_with ? false : true}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.branding_video_url}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="branding_image_file">
                            <Form.Label column sm="3">
                                Branding Image {formData?.branding_promotion_with === "image" && formData?.brand_info_visible && <span className="text-danger">*</span>}
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <FileUploader
                                    name="branding_image_file"
                                    readOnly={formData?.branding_promotion_with ? false : true}
                                    customError={errors.branding_image_file}
                                    isRequired={createForm && formData?.brand_info_visible && formData?.branding_promotion_with === "image" ? true : false}
                                    onFileSelect={handleOnFileChange}
                                    onFailure={onFileUploadFailure}
                                    dimension={{ width: formData?.branding_layout === 'box' ? 800 : 1440, height: formData?.branding_layout === 'box' ? 500 : 400 }}
                                    maxSize={1024 * 1024}
                                    acceptedFormats={['image/jpg', 'image/jpeg', 'image/png', 'image/webp']}
                                    isMultiple={false} />
                                {
                                    !createForm &&
                                    <img src={`${formData?.branding_image}`} alt="" width={formData?.branding_layout === 'box' ? 300 : '100%'} height={"auto"} />
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="branding_title">
                            <Form.Label column sm="3">
                                Branding Title {formData?.brand_info_visible && formData?.branding_layout === 'box' && <span className="text-danger">*</span>}
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"branding_title"}
                                    defaultValue={details?.branding_title || ''}
                                    max={MAX}
                                    required={formData?.brand_info_visible && formData?.branding_layout === 'box'}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.branding_title}
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="branding_title_bn">
                            <Form.Label column sm="3">
                                Branding Title in Bangla {formData?.brand_info_visible && formData?.branding_layout === 'box' && <span className="text-danger">*</span>}
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"branding_title_bn"}
                                    value={formData?.branding_title_bn ? formData.branding_title_bn : ''}
                                    max={MAX}
                                    required={formData?.brand_info_visible && formData?.branding_layout === 'box'}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.branding_title_bn}
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="branding_subtitle">
                            <Form.Label column sm="3">
                                Branding Sub title
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"branding_subtitle"}
                                    defaultValue={formData?.branding_subtitle ? formData.branding_subtitle : ''}
                                    max={MAX}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.branding_subtitle}
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="branding_subtitle_bn">
                            <Form.Label column sm="3">
                                Branding Sub title in Bangla
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"branding_subtitle_bn"}
                                    value={formData?.branding_subtitle_bn ? formData.branding_subtitle_bn : ''}
                                    max={MAX}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.branding_subtitle_bn}
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="short_description">
                            <Form.Label column sm="3">
                                Short Description {formData?.brand_info_visible && formData?.branding_layout === 'box' && <span className="text-danger">*</span>}
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <JoditEditorComponent
                                    value={formData?.short_description ? formData.short_description : ''}
                                    tabIndex={122}
                                    onChange={(value) => {
                                        handleJoEditorOnChange('short_description', value, formData?.brand_info_visible && formData?.branding_layout === 'box' ? true : false);
                                    }}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.short_description}
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="short_description_bn">
                            <Form.Label column sm="3">
                                Short Description in Bangla {formData?.brand_info_visible && formData?.branding_layout === 'box' && <span className="text-danger">*</span>}
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <JoditEditorComponent
                                    value={formData?.short_description_bn ? formData.short_description_bn : ''}
                                    tabIndex={122}
                                    onChange={(value) => {
                                        handleJoEditorOnChange('short_description_bn', value, formData?.brand_info_visible && formData?.branding_layout === 'box' ? true : false);
                                    }}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.short_description_bn}
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="more_info_button_text">
                            <Form.Label column sm="3">
                                Button Text
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"more_info_button_text"}
                                    value={formData?.more_info_button_text ? formData.more_info_button_text : ''}
                                    max={MAX}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="more_info_button_text_bn">
                            <Form.Label column sm="3">
                                Button Text in Bangla
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"more_info_button_text_bn"}
                                    value={formData?.more_info_button_text_bn ? formData.more_info_button_text_bn : ''}
                                    max={MAX}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.more_info_button_text_bn}
                                </div>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="more_info_url">
                            <Form.Label column sm="3">
                                Button Text URL
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Form.Control
                                    name={"more_info_url"}
                                    value={formData?.more_info_url ? formData.more_info_url : ''}
                                    onChange={handleOnFieldChange}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.more_info_url}
                                </div>
                            </Col>
                        </Form.Group>
                    </div>
                </div>

                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Meta Info</h4>
                    </div>
                    <div className="block-body">
                        <MetaInfo
                            metaInfo={metaInfo}
                            handleOnInputChange={handleOnMetaInputChange}
                            handleOnChangeKeywordValue={handleOnChangeKeywordValue}
                        />
                    </div>
                </div>

                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Branding Campaign Slider</h4>
                    </div>
                    <div className="block-body">
                        <Button onClick={addOneKeyFeature} variant={"success"}
                            disabled={formData?.campaigns_attributes?.filter(i => !i._destroy)?.length >= 5}>Add
                            One</Button>
                        {
                            formData?.campaigns_attributes?.filter(i => !i._destroy)?.length > 0 &&
                            <table className="table mt-5 table-responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">Title in Bangla</th>
                                        <th scope="col">Page Url</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData?.campaigns_attributes?.filter(i => !i._destroy)?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <input type="text" className="form-control"
                                                        placeholder="Title" value={item.title}
                                                        onChange={(e) => handleKeyFeature(e, i, 'title')}
                                                        required maxLength={25} />
                                                </td>
                                                <td>
                                                    <input type="text" className="form-control"
                                                        placeholder="Title in Bangla" value={item.title_bn}
                                                        onChange={(e) => handleKeyFeature(e, i, 'title_bn')}
                                                        required maxLength={25} />
                                                </td>
                                                <td>
                                                    <input type="text" className="form-control"
                                                        placeholder="Page Url" value={item.page_url}
                                                        onChange={(e) => handleKeyFeature(e, i, 'page_url')}
                                                        required />
                                                </td>
                                                <td><span className="pointer-event"
                                                    onClick={() => removeKeyFeature(i)}><IoMdClose /></span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>

                <div className="card-block">
                    <div className="block-header">
                        <h4 className="block-title">Branding Filter Attributes</h4>
                    </div>

                    <div className="block-body">
                        <Form.Group as={Row} controlId="branding_button_url">
                            <Form.Label column sm="3">
                                Filter Options
                            </Form.Label>
                            <Col sm="9" md={8} lg={7}>
                                <Select
                                    mode="multiple"
                                    size="large"
                                    placeholder="Select attribute filter options"
                                    value={selectedFilteringAttributes}
                                    onChange={handleAttibuteSelect}
                                    style={{ width: '100%' }}
                                >
                                    {FILTER_OPTIONS.map(item => (
                                        <Select.Option key={item.key} value={item.key}>
                                            {item.value}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <div className="invalid-feedback d-block">
                                    {errors.branding_button_url}
                                </div>
                            </Col>
                        </Form.Group>

                        {
                            selectedFilteringAttributes?.includes('category') && <Form.Group as={Row} controlId="branding_button_url">
                                <Form.Label column sm="3">
                                    Categories
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                    <Select
                                        mode="multiple"
                                        size="large"
                                        placeholder="Select categories"
                                        value={formData?.filtering_options_attributes.length > 0 ? formData.filtering_options_attributes?.find(item => item.filtering_type === 'category' && !item._destroy).filtering_keys : []}
                                        onChange={(selected) => handleFilteringAttributeChange(selected, 'category')}
                                        style={{ width: '100%' }}
                                    >
                                        {allCategories.map(item => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <div className="invalid-feedback d-block">
                                        {errors.branding_button_url}
                                    </div>
                                </Col>
                            </Form.Group>
                        }
                        {
                            selectedFilteringAttributes?.includes('product_attribute') && <Form.Group as={Row} controlId="branding_button_url">
                                <Form.Label column sm="3">
                                    Product Attribute
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                    <Select
                                        mode="multiple"
                                        size="large"
                                        placeholder="Select product attribute"
                                        value={formData?.filtering_options_attributes.length > 0 ? formData.filtering_options_attributes?.find(item => item.filtering_type === 'product_attribute' && !item._destroy).filtering_keys : []}
                                        onChange={(selected) => handleFilteringAttributeChange(selected, 'product_attribute')}
                                        style={{ width: '100%' }}
                                    >
                                        {allProductAttributes.map(item => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <div className="invalid-feedback d-block">
                                        {errors.branding_button_url}
                                    </div>
                                </Col>
                            </Form.Group>
                        }
                        {
                            selectedFilteringAttributes?.includes('price_range') && <Form.Group as={Row} controlId="branding_button_url">
                                <Form.Label column sm="3">
                                    Price Range
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                    <Form.Check
                                        custom
                                        inline
                                        type="checkbox"
                                        checked={true}
                                        name="price_range"
                                        value
                                        label="Enable price range"
                                    />
                                    <div className="invalid-feedback d-block">
                                        {errors.branding_button_url}
                                    </div>
                                </Col>
                            </Form.Group>
                        }
                        {
                            selectedFilteringAttributes?.includes('product_type') && <Form.Group as={Row} controlId="branding_button_url">
                                <Form.Label column sm="3">
                                    Product Type
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                    <Select
                                        mode="multiple"
                                        size="large"
                                        placeholder="Select product type"
                                        value={formData?.filtering_options_attributes.length > 0 ? formData.filtering_options_attributes?.find(item => item.filtering_type === 'product_type' && !item._destroy).filtering_keys : []}
                                        onChange={(selected) => handleFilteringAttributeChange(selected, 'product_type')}
                                        style={{ width: '100%' }}
                                    >
                                        {allProductTypes.map(item => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <div className="invalid-feedback d-block">
                                        {errors.branding_button_url}
                                    </div>
                                </Col>
                            </Form.Group>
                        }
                        {
                            selectedFilteringAttributes?.includes('keyword') && <Form.Group as={Row} controlId="branding_button_url">
                                <Form.Label column sm="3">
                                    Keyword
                                </Form.Label>
                                <Col sm="9" md={8} lg={7}>
                                    <Select
                                        mode="tags"
                                        size="large"
                                        placeholder="Enter Keyword"
                                        value={formData?.filtering_options_attributes.length > 0 ? formData.filtering_options_attributes?.find(item => item.filtering_type === 'keyword' && !item._destroy).filtering_keys : []}
                                        onChange={(selected) => handleFilteringAttributeChange(selected, 'keyword')}
                                        style={{ width: '100%' }}
                                    >
                                        {formData?.filtering_options_attributes.find(item => item.filtering_type === 'keyword').filtering_keys.map(item => (
                                            <Select.Option key={item} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <div className="invalid-feedback d-block">
                                        {errors.branding_button_url}
                                    </div>
                                </Col>
                            </Form.Group>
                        }
                    </div>
                </div>


                <div className="card-block">
                    <div className="block-body d-flex justify-content-end">
                        <div className="mt-3 text-center">
                            <Link
                                type="button"
                                className="btn btn-sm btn-btn-sm mr-2"
                                to={`${process.env.PUBLIC_URL}/brands/list`}
                            >
                                <Button type="button" variant="danger">
                                    Cancel
                                </Button>
                            </Link>
                            <Button
                                size="btn-sm"
                                type={"submit"}
                                disabled={isSubmitting || uniqueSlug === false}
                                variant="primary"
                            >
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>

        </div>

    );
};

export default BrandForm;
