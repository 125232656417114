import React, { useEffect, useState } from "react";
import App from "../../App";
import { Link, useParams } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getDetails } from "../../services/baseServices";
import { toast } from "react-toastify";
const CompanyDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState({});

  const { id } = useParams();

  const getCompanyDetails = (id) => {
    getDetails("companies", id)
      .then((res) => {
        if (res.success) {
          setCompanyDetails(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to fetch company details");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyDetails(id);
  }, [id]);

  return (
    <App layout={`boxed`}>
      <div className="main-content">
        <div className="page-header">
          <div className="page-title">Company Details</div>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/company/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Company List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <table className="info-table">
                <tbody>
                  {isLoading && (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ width: "250px" }}>Company ID</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td className={`text-bold`}>{companyDetails?.id}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "250px" }}>Company Name</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td className={`text-bold`}>{companyDetails?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "250px" }}>Company Logo</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>
                      <img
                        src={`${companyDetails?.logo_url}`}
                        width={"100"}
                        height={"100"}
                        alt="Company Logo"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "250px" }}>IsActive</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td className={`text-bold`}>
                      {companyDetails?.is_active ? "Active" : "InActive"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "250px" }}>SD Rate(%)</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td className={`text-bold`}>{companyDetails?.sd_rate}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "250px" }}>Source Tax(%)</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td className={`text-bold`}>
                      {companyDetails?.source_tax}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "250px" }}>Vat Rate(%)</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td className={`text-bold`}>{companyDetails?.vat_rate}</td>
                  </tr>
                </tbody>
              </table>
            </>
          </div>
        </div>
      </div>
    </App>
  );
};

export default CompanyDetails;
