import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, getObjectToFormData} from "../helper/utils";

export const getPartnerList = params => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/partners`, {
      params,
      headers: {}
    }).then(res => {
      console.log(res)
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res)
      }
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const addPartner = (url, data) => {
  const authData = getLocalAuthData();
  const formData = getObjectToFormData(data)
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + '/' + url, formData, {
      headers: {}
    }).then(res => {
      console.log(res.data);
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res.data)
      }
    })
      .catch(err => {
        reject('Something went wrong')
      })
  })
}

export const updatePartner = (url, id, data) => {
  const authData = getLocalAuthData();
  const formData = getObjectToFormData(data)
  return new Promise((resolve, reject) => {
    axios.put(API_BASE_URL + '/' + url + '/' + id, formData, {
      headers: {}
    }).then(res => {
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res.data)
      }
    })
      .catch(err => {
        reject('Something went wrong')
      })
  });
}
