import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import App from "../../App";
import { getViewDhAssignment } from "../../services/productServices";
import { Button } from "react-bootstrap";
import { getList } from "../../services/baseServices";
import { Select } from "antd";
import ExportCSV from "../common/ExportCSV";
import { FaFileExport } from "react-icons/all";

const headers = [
  { label: "name", key: "name" },
  { label: "phone", key: "phone" },
  { label: "sku", key: "sku" },
  { label: "status", key: "status" },
];

const ViewDhAssignment = () => {
  const [warehouseId, setWarehouseId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [veiwDhAssignment, setVeiwDhAssignment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const exportCsvRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companiesResponse, warehousesResponse] = await Promise.all([
          getList("options/companies"),
          getList("options/warehouses"),
        ]);

        if (companiesResponse?.success) {
          setCompanies(companiesResponse.data);
        }
        if (warehousesResponse?.success) {
          setWarehouses(warehousesResponse.data);
        }
      } catch (errMsg) {
        console.log(errMsg);
      }
    };
    fetchData();
  }, []);

const handleSubmit = (event) => {
  event.preventDefault();
  setIsLoading(true);
  getViewDhAssignment({ warehouse_id: warehouseId, company_id: companyId })
    .then((res) => {
      setVeiwDhAssignment(res?.data);
      setIsLoading(false);
      if (exportCsvRef.current) {
        exportCsvRef.current.downloadReport();
      }
    })
    .catch((err) => {
      setIsLoading(false);
      setVeiwDhAssignment([]);
    });
};
  const handleCompanyChange = (value) => {
    setCompanyId(value);
  };

  const handleWarehouseChange = (value) => {
    setWarehouseId(value);
  };

  const warehousesOptions = warehouses.map((warehouse) => ({
    value: warehouse.id,
    label: warehouse.name,
  }));

  return (
    <App layout="">
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Export DH Products</h2>
          <div
            className="ml-auto"
            style={{
              display: "none",
            }}
          >
            <ExportCSV
              ref={exportCsvRef}
              data={veiwDhAssignment ?? []}
              headers={headers}
              title="DH Assignment"
            />
          </div>
        </div>
      </div>
      <form className="form-inline mb-3" onSubmit={handleSubmit}>
        <div className="form-group mr-3">
          <Select
            style={{ width: 240 }}
            placeholder="Select Warehouse"
            onChange={handleWarehouseChange}
            options={warehousesOptions}
            allowClear
          />
        </div>
        <div className="form-group mr-3">
          <Select
            style={{ width: 240 }}
            placeholder="Select Company"
            onChange={handleCompanyChange}
            options={companies}
            allowClear
          />
        </div>
        <Button
          type="submit"
          size="sm"
          variant="secondary"
          disabled={
            (companyId && !warehouseId) ||
            isLoading ||
            (!companyId && !warehouseId)
          }
        >
          <FaFileExport /> {isLoading ? "Exporting..." : "Export"}
        </Button>
      </form>
      {/* {isLoading ? (
        <div className="card-block">
          <div className="block-body">Loading...</div>
        </div>
      ) : (
        <div className="card-block">
          <div className="block-body">
            <table className="order-table table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Sku</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {veiwDhAssignment.length > 0 ? (
                  veiwDhAssignment.map((product, index) => (
                    <tr key={index}>
                      <td>{product.name}</td>
                      <td>{product.phone}</td>
                      <td>{product.sku}</td>
                      <td>{product.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="4">
                      No Match Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )} */}
    </App>
  );
};

export default withRouter(ViewDhAssignment);
