import React, { Component } from "react";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import RouteForm from "./Form";
import { getDetails, update } from "../../services/baseServices";
import { FaLongArrowAltLeft } from "react-icons/all";

class Edit extends Component {
  state = {
    formData: {},
    routeInfo: {},
  };

  componentDidMount() {
    getDetails("routes", this.props.match.params.id)
      .then((res) => {
        this.setState({ routeInfo: res });
        const formData = {
          ...this.state.formData,
          distributor_id: res?.distributor_id,
        };
        this.setState({ formData }, () => {});
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }
  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };
    formData[e.target.name] = e.target.value;
    this.setState({ formData }, () => {
      console.log(this.state.formData);
    });
  };
  handleUpdateItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        let formData = { ...this.state.formData };
        const phone =
          formData["phone"]?.length === 14
            ? formData["phone"]?.substring(3)
            : formData["phone"];
        formData = { ...formData, phone };

        update("routes", this.props.match.params.id, "route", formData)
          .then((res) => {
            if (res.id) {
              this.props.history.push(`/route/list`);
              toast.success("updated");
            } else {
              toast.error(res.message);
              this.setState({ isSubmitting: false });
            }
          })
          .catch((errMsg) => {
            toast.error(errMsg);
            this.setState({ isSubmitting: false });
          });
      });
    }
  };
  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Update Delivery Man</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/route/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Delivery Man List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <RouteForm
                handleClick={this.handleUpdateItem}
                handleInputOnChange={this.handleInputOnChange}
                routeInfo={this.state.routeInfo}
                isSubmitting={this.state.isSubmitting}
                formData={this.state.formData}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);
