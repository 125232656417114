import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { convertToSlug } from './../../helper/utils';
import MetaInfo from "../../components/MetaInfo";
import { getData } from "../../services/baseServices";

const HelpModuleForm = ({ helpTopic, metaInfo, setMetaInfo, onFormSubmit, edit }) => {
  const schema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required("Title is a required")
      .max("50", "Max 50 characters allowed"),
    slug: yup.string().trim().required("Slug is a required"),
    bn_title: yup
      .string()
      .trim()
      .required("Bn Title is a required")
      .max("50", "Max 50 characters allowed"),
    public_visibility: yup.bool().required("Please provide the info"),
  });

  const [uniqueSlug, setUniqueSlug] = useState('');
  const {id }= useParams();

  // meta info
  const handleOnMetaInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMetaInfo({ ...metaInfo, [name]: value });
  };
  const handleOnChangeKeywordValue = (en_bn, value) => {
    const mitainfoDetails = { ...metaInfo };
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach((val) => {
        valueIdArr.push(val.value);
      });
    }
    if (en_bn === "en") {
      mitainfoDetails.metaKeywords = valueIdArr;
    } else {
      mitainfoDetails.metaKeyKeywordsBn = valueIdArr;
    }
    setMetaInfo(mitainfoDetails);
  };

  const { reset, register, handleSubmit, control, formState:{ errors }, watch, setValue } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
          public_visibility: true,
        }
    });

    useEffect(()=>{
      reset({
          title: helpTopic.title,
          slug: helpTopic.slug,
          bn_title: helpTopic.bn_title,
          public_visibility: helpTopic.public_visibility
      })
    },[helpTopic])

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  const checkUniqueSlug = (slug, type)=>{
    if(slug) {
        const queryObj = id 
            ? {slug, id, type}
            : {slug, type}
        getData('slugs/uniqueness', queryObj)
        .then(res => setUniqueSlug(res.success))
        .catch(err => setUniqueSlug(''))
    } else {
        setUniqueSlug('');
    }
  }

  const handleSlug = (e) =>{
      if((!edit && e.target.name === 'title') || e.target.name === 'slug' ) {
          const slug = convertToSlug(e.target.value);
          setValue('slug', slug, { shouldValidate: true })
          // setFormData(prev => ({ ...prev, ['slug']: slug }));
          checkUniqueSlug(e.target.value, "HelpTopic")
      }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title"> Basic Info</h4>
        </div>
        <div className="block-body">
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3">
              Title <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                {...register("title")}
                type="text"
                className={errors.title ? "is-invalid" : ""}
                onChange= {(e)=>handleSlug(e)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.title?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="name">
              <Form.Label column sm="3">
                  Slug <span className="text-danger">*</span>
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                  <Form.Control
                      {...register("slug")}
                      type="text"
                      disabled={edit}
                      className={errors.title ? 'is-invalid' : ''}
                      onChange= {(e)=>handleSlug(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                      {errors.slug?.message}
                      {uniqueSlug === false? "Please provide a unique Slug" : ""}
                    </Form.Control.Feedback>
                
              </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3">
              BN Title <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                {...register("bn_title")}
                type="text"
                className={errors.bn_title ? "is-invalid" : ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bn_title?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="visibily">
            <Form.Label column sm="3">
              Public Visibility
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                className={errors.public_visibility ? "is-invalid" : ""}
                {...register("public_visibility")}
                as="select"
                type="text"
              >
                <option value={true}>True</option>
                <option value={false}>False</option>
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                {errors.public_visibility?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </div>
      </div>
      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title">Meta Info</h4>
        </div>
        <div className="block-body">
          <MetaInfo
            metaInfo={metaInfo}
            handleOnInputChange={handleOnMetaInputChange}
            handleOnChangeKeywordValue={handleOnChangeKeywordValue}
          />
        </div>
      </div>

      <div className="mt-3 text-right">
        <Link
          type="button"
          className="btn btn-sm btn-btn-sm mr-2"
          to={`${process.env.PUBLIC_URL}/help-topic/list`}
        >
          <Button type="button" variant="danger">
            Cancel
          </Button>
        </Link>
        <Button
            size="btn-sm"
            type={"submit"}
            variant="primary"
            disabled={uniqueSlug === false}
            >
            Submit
        </Button>
      </div>
    </Form>
  );
};

export default HelpModuleForm;