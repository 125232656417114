import React, { Component } from "react";
import { getData } from "../../services/baseServices";

class WarehouseBalance extends Component {
    constructor(...args) {
        super(...args);
		this.state = {
			warehouse_info: null,
			isLoading: true,
		};
	}

    getWarehouseBalanceInfo = (params) => {
        getData("warehouses/warehouse_balance",params)
        .then(res => {
            this.setState({warehouse_info: res});
        })
        .catch(errMsg => {
            console.log('Error Message: ', errMsg)
        })
    }

	componentDidMount() {
		this.getWarehouseBalanceInfo(this.props.queryParams);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
		    console.log(this.props.queryParams)
            this.getWarehouseBalanceInfo(this.props.queryParams);
        }
	}

	render() {
		return (
            <table className="order-table table table-bordered">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={2}>Warehouse Cash</th>
                        <th className="text-center" colSpan={2}>Returned Requests</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center font-weight-bold">Collected</td>
                        <td className="text-center font-weight-bold">Collectable</td>
                        <td className="text-center font-weight-bold">Collected</td>
                        <td className="text-center font-weight-bold">Collectable</td>
                    </tr>
                    <tr>
                        <td className="text-center">{this.state.warehouse_info?.warehouse_cash_collected}</td>
                        <td className="text-center">{this.state.warehouse_info?.warehouse_collectable}</td>
                        <td className="text-center">{this.state.warehouse_info?.total_returned_request}</td>
                        <td className="text-center">{this.state.warehouse_info?.total_return_collectable}</td>
                    </tr>
                </tbody>
            </table>
		);
	}
}

export default WarehouseBalance;