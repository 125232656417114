import React, { useState } from "react";
import App from "../../App";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import { postData } from "../../services/baseServices";
import { Button, Col, Form, Row } from "react-bootstrap";
import FileUploader from "./FileUploader";

const CompanyAdd = () => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    vat_rate: "",
    sd_rate: "",
    logo: null,
    source_tax: "",
  });
  const history = useHistory();
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOnFileChange = (name, files) => {
    setFormData((prev) => ({ ...prev, [name]: files[0] }));
    setErrors((prev) => ({ ...prev, [name]: null }));
  };

  const onFileUploadFailure = (name, err) => {
    setFormData((prev) => ({ ...prev, [name]: null }));
    setErrors((prev) => ({ ...prev, [name]: err }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const companyData = new FormData();
    companyData.append("name", formData.name);
    companyData.append("vat_rate", formData.vat_rate);
    companyData.append("sd_rate", formData.sd_rate);
    companyData.append("logo", formData.logo);
    companyData.append("source_tax", formData.source_tax);
    try {
      const response = await postData("companies", companyData);
      if (response.success) {
        toast.success("Company added successfully");
        setFormData({
          name: "",
          vat_rate: "",
          sd_rate: "",
          source_tax: "",
          logo: null,
        });
        history.push("/company/list");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Failed to add company");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Add Company</h2>
        <div className="ml-auto">
          <Link
            to={`${process.env.PUBLIC_URL}/company/list`}
            className="btn btn-sm btn-link"
          >
            <FaLongArrowAltLeft /> Back to Company List
          </Link>
        </div>
      </div>
      <div className="main-content">
        <Form onSubmit={handleSubmit}>
          <div className="card-block">
            <div className="block-body">
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3">
                  Company Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name={"name"}
                    value={formData?.name ? formData.name : ""}
                    onChange={handleChange}
                    type="text"
                    required
                  />
                  {formData?.name === "" && (
                    <Form.Text
                      style={{
                        color: "#cb0606",
                      }}
                    >
                      Company Name is required
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="vat_rate">
                <Form.Label column sm="3">
                  VAT Rate(%)
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name={"vat_rate"}
                    value={formData?.vat_rate ? formData.vat_rate : ""}
                    onChange={handleChange}
                    type="text"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="sd_rate">
                <Form.Label column sm="3">
                  SD Rate(%)
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name={"sd_rate"}
                    value={formData?.sd_rate ? formData.sd_rate : ""}
                    onChange={handleChange}
                    type="text"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="source_tax">
                <Form.Label column sm="3">
                  Source Tax(%)
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name={"source_tax"}
                    value={formData?.source_tax ? formData.source_tax : ""}
                    onChange={handleChange}
                    type="text"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="logo">
                <Form.Label column sm="3">
                  Logo
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <FileUploader
                    name="logo"
                    customError={errors.logo}
                    readOnly={false}
                    onFileSelect={handleOnFileChange}
                    onFailure={onFileUploadFailure}
                    dimension={{ width: 300, height: 300 }}
                    maxSize={160 * 1024}
                    acceptedFormats={[
                      "image/jpg",
                      "image/jpeg",
                      "image/png",
                      "image/webp",
                    ]}
                    isMultiple={false}
                  />
                  {formData.logo && (
                    <img
                      src={URL.createObjectURL(formData.logo)}
                      alt="Company Logo"
                      width="150px"
                      height="150px"
                    />
                  )}
                </Col>
              </Form.Group>
            </div>
          </div>
          <Button
            size="btn-sm"
            type={"submit"}
            disabled={isSubmitting}
            variant="primary"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      </div>
    </App>
  );
};

export default CompanyAdd;
