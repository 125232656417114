import { Controller } from "react-hook-form";
import { Input } from "antd";

const { TextArea } = Input;

const TextAreaControl = ({
  name,
  type = "text",
  control,
  errMsg,
  isDisabled = false,
  placeholder = "",
  maxLength = 1000,
  className = "",
}) => {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextArea
            allowClear
            {...field}
            id={name}
            className={`rounded my-1 ${className}`}
            status={errMsg && "error"}
            size="large"
            rows={4}
            maxLength={maxLength}
            disabled={isDisabled}
            placeholder={placeholder}
          />
        )}
      />
      <p className="text-danger">{errMsg}</p>
    </div>
  );
};

export default TextAreaControl;
