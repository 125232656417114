import React, {Component} from 'react';
import App from "../../App";
import {withRouter} from "react-router-dom";
import {getList} from "../../services/baseServices";
import DatePicker from "react-datepicker";
import ExportCSVAsync from "../common/ExportCSVAsync";
import { Button } from "react-bootstrap";

const headers = [
    { label: "Distributor", key: "distributor_name" },
    { label: "SR Name", key: "sr_name" },
    { label: "Route Title", key: "route_title" },
    { label: "Partner", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "Order Count", key: "order_count" },
    { label: "Margin", key: "margin_amount" },
  ];
  
  const loadData = (item) => {
    return {
        distributor_name: item.distributor_name,
        sr_name: item.sr_name,
        route_title: item.route_title,
        name: item.name,
        phone: item.phone,
        order_count: item.order_count,
        margin_amount: item.margin_amount
    };
  };

var d = localStorage.getItem("sr_margin_date") ? new Date(localStorage.getItem("sr_margin_date")): new Date();

class PartnerMargin extends Component {
    constructor(){
        super();
        this.state = {
            partnerMarginList :[],
            query_params: {
                month : d?.getMonth() + 1,
                year: d?.getFullYear(),
            },
            distributors: [],
			startDate: d,
        }
    }
                
    componentDidMount() {
        getList("distributors")
        .then((res) => {
            this.setState({distributors: res?.data});
        })
        .catch((errMsg) => {});
    }

	handleOnFieldChange = (e) => {
		const queryData = {...this.state.query_params};
		queryData[e.target.name] = e.target.value;
		this.setState({query_params: queryData});
	}

    handleOnMonthChange = (date) => {
        this.setState({startDate:date})
    }

    handleOnClear = ()=>{
		this.setState({
            query_params: {
                month : d?.getMonth() + 1,
                year: d?.getFullYear(),
                distributor_id: '',
                partner_schedule: '',
                title: ''
            }, 
            startDate: d
        })
	}

    render() {
        return (
            <App layout={`boxed`}>
                <div className="main-content">
                    <div className="page-header">
                        <div className="page-title">Partner Margin Report</div>
                    </div>
                </div>
                <form className="form-inline mb-3">
                    <div className="form-group mr-3">
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleOnMonthChange} 
                            dateFormat="MMMM-yyyy"
                            className='datePicker'
                            showMonthYearPicker 
                            placeholderText="--Select month & year--"
                            />
                    </div>
                    <div className="form-group mr-3">
                        <label htmlFor="distributor_id" className="sr-only">
                        Distributor
                        </label>
                        <select
                        id="distributor_id"
                        name="distributor_id"
                        className="form-control form-control-sm"
                        onChange={this.handleOnFieldChange}
                        value={this.state.query_params?.distributor_id}
                        >
                        <option value="">--Select Distributor--</option>
                        {this.state.distributors.map((item, i) => {
                            return (
                            <option
                                key={i}
                                value={item.id}
                            >
                                {item.name}
                            </option>
                            );
                        })}
                        </select>
                    </div>
                    <div className="form-group mr-3">
                        <select
                            id="partner_schedule"
                            name="partner_schedule"
                            className="form-control form-control-sm"
                            onChange={this.handleOnFieldChange}
                            value={this.state?.query_params?.partner_schedule}
                        >
                            <option value="">--Select section Date--</option>
                            <option value="sat">SAT</option>
                            <option value="sun">SUN</option>
                            <option value="mon">MON</option>
                            <option value="wed">WED</option>
                            <option value="thurs">THURS</option>
                            <option value="sat_mon_wed">SAT-MON-WED</option>
                            <option value="sun_tues_thurs">SUN-TUE-THURS</option>
                            <option value="sat_sun_mon_tues_wed_thurs">
                                SAT-SUN-MON-TUE-WED-THURS
                            </option>
                            <option value="sat_sun_mon_tues_wed_thurs_fri">
                                SAT-SUN-MON-TUE-WED-THURS-FRI
                            </option>
                        </select>
                    </div>
                    <div className="form-group mr-3">
                        <label htmlFor="searchInput" className="sr-only">
                        Router Name
                        </label>
                        <input
                        type="search"
                        name="title"
                        id="title"
                        value={this.state.query_params?.title}
                        className="form-control form-control-sm"
                        placeholder="Search by SR Name"
                        onChange={this.handleOnFieldChange}
                        />
                    </div>
                    {
                        this.state.query_params?.distributor_id && this.state.query_params?.month && this.state.query_params?.year ?
                        <>
                            <ExportCSVAsync
                                title="Partner Margin Report"
                                fileName={
                                "Partner-Margin-" +this.state.query_params?.month+'-'+this.state.query_params?.year+
                                ".csv"
                                }
                                Url="route_margins/partners_export"
                                headers={headers}
                                params={this.state.query_params}
                                loadData={loadData}
                            />
                            <Button
                                size="sm"
                                type={`button`}
                                className="ml-2"
                                onClick={this.handleOnClear}
                                variant="danger"
                            >
                                Clear
                            </Button>
                        </>
                        :
                        <>
                            <Button
                                size="sm"
                                type={`button`}
                                className="ml-2"
                                disabled
                                variant="warning"
                            >
                                Export
                            </Button>
                        </>
                    }
                    
                    </form>
                <div>
                </div>
                
            </App>
        );
    }
}

export default withRouter(PartnerMargin);