import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import BrowseButton from "../../components/BrowseButton";
import "react-datepicker/dist/react-datepicker.css";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { getThanas, getAreas, getList } from "../../services/baseServices";
import { getBusinessType } from "../../helper/utils";
import { Select } from "antd";
import _ from "lodash";
function PartnerForm(props) {
  const { isEditable, dataToUpdate } = props;
  const businessType = getBusinessType();
  const [schedule, setSchedule] = useState(props.formData?.schedule);
  const [addressLine, setAddressLine] = useState(props.formData?.address_line);
  const [route, setRoute] = useState(props.formData?.route_id);
  const [batRouteList, setBatRouteList] = useState([]);
  const [batRoute, setBatRoute] = useState(props?.formData?.bat_route_id);
  const [status, setStatus] = useState(props.formData?.status || "");
  const [prompt_sale_status, setPromptSaleStatus] = useState(
    props.formData?.prompt_sale_status || ""
  );
  const [salesMan, setSalesMan] = useState(props.formData?.sr_id || "");
  const [selectedChannels, setChannels] = useState(
    props.formData?.channels || []
  );
  const [channelList, setChannelList] = useState([]);
  const [areas, setAreas] = useState([]);
  const [thanas, setThanas] = useState([]);
  const [selectedDistributor, setDistributor] = useState(
    props.formData.distributor_id || ""
  );
  const [distributorList, setDistributorList] = useState("");

  const [selectedCompanies, setCompanies] = useState(
    props.formData?.companies || []
  );
  const [companyList, setCompanyList] = useState([]);
  const [routes, setRoutes] = useState([]);
  const scheduleList = [
    {
      label: "Saturday",
      value: "sat",
    },
    {
      label: "Sunday",
      value: "sun",
    },
    {
      label: "Monday",
      value: "mon",
    },
    {
      label: "Tuesday",
      value: "tue",
    },
    {
      label: "Wednesday",
      value: "wed",
    },
    {
      label: "Thursday",
      value: "thu",
    },
    {
      label: "Friday",
      value: "fri",
    },
  ];

  const callThanas = (distributor_id) => {
    getThanas({ distributor_id: distributor_id })
      .then((res) => {
        setThanas(res);
      })
      .catch((errMsg) => {});
  };

  const callAreas = (thana_id) => {
    getAreas({ thana_id: thana_id })
      .then((res) => {
        setAreas(res);
      })
      .catch((errMsg) => {});
  };

  useEffect(() => {
    setSchedule(props.formData.schedule);
    setAddressLine(props.formData.address_line);
    setRoute(props.formData.route_id);
    // setChannel(props?.formData?.channel_id);
    setStatus(props.formData.status);
    // setCredit(props.formData.is_credit_applicable)
    // props.handleServiceHourOnChange("work_days", work_days);
    if (routes?.length === 0) {
      getList("routes")
        .then((res) => {
          setRoutes(res);
        })
        .catch((errMsg) => {});
    }
    if (batRouteList?.length === 0) {
      getList("options/bat_routes")
        .then((res) => {
          if (res?.success) {
            setBatRouteList(res?.data);
          }
        })
        .catch((errMsg) => {});
    }
    if (companyList?.length === 0) {
      getList("options/companies")
        .then((res) => {
          if (res?.success) {
            setCompanyList(res?.data);
          }
        })
        .catch((errMsg) => {});
    }
    if (salesMan?.length === 0) {
      getList("options/salesmans")
        .then((res) => {
          if (res?.success) {
            setSalesMan(res?.data);
          }
        })
        .catch((errMsg) => {});
    }
  }, []);

  useEffect(() => {
    if (props.formData.route_id) {
      setRoute(props.formData.route_id);
      const xxx = routes?.find(
        (i) => i.id === parseInt(props.formData.route_id)
      )?.distributor_id;
      setDistributor(xxx);
      props.setDistributorId(xxx);
    }
    //eslint-disable-next-line
  }, [props.formData.route_id]);

  useEffect(() => {
    if (props.formData.distributor_id)
      callThanas(props.formData.distributor_id);
  }, [props.formData.distributor_id]);

  useEffect(() => {
    if (props.formData.thana_id !== undefined)
      callAreas(props.formData.thana_id);
  }, [props.formData.thana_id]);

  useEffect(() => {
    if (props.formData.address_line !== undefined)
      setAddressLine(props.formData.address_line);
  }, [props.formData.address_line]);

  useEffect(() => {
    if (props.formData?.companies && props?.formData?.companies?.length > 0) {
      if (_.some(props?.formData?.companies, "label", "value")) {
        getList("options/channels", {
          company_ids: props.formData?.companies?.map((i) => i.value),
        }).then((res) => {
          if (res?.success) {
            setChannelList(res?.data);
          }
        });
      } else {
        setChannelList([]);
      }
    }
  }, [props.formData.companies]);

  const handleCompanyChange = (target) => {
    if (props.formData.companies === target) {
      //no change just return
      return;
    } else if (props.formData.companies?.length > target.length) {
      //selection cleared so removed everything from channel and update company
      props.handleInputOnChange({
        target: {
          name: "companies",
          value: target,
        },
      });
      props.handleInputOnChange({
        target: {
          name: "channels",
          value: [],
        },
      });
    } else if (props.formData.companies.length < target.length) {
      //new selection added call api update company
      props.handleInputOnChange({
        target: {
          name: "companies",
          value: target,
        },
      });
      getList("options/channels", { company_ids: target }).then((res) => {
        if (res?.success) {
          setChannelList(res?.data);
        }
      });
    } else if (target === null || target.length === 0) {
      // Clear action
      // Perform necessary actions when the selection is cleared
      props.handleInputOnChange({
        target: {
          name: "channels",
          value: [],
        },
      });
      props.handleInputOnChange({
        target: {
          name: "companies",
          value: [],
        },
      });
    }
  };

  const handleChange = (value) => {
    props.handleInputOnChange({
      target: {
        name: "channels",
        value: value,
      },
    });
  };

  const handleSalesManChange = (value) => {
    props.handleInputOnChange({
      target: {
        name: "sr_id",
        value: value,
      },
    });
  };

  const handleScheduleChange = (target) => {
    if (target === null || target.length === 0) {
      props.handleInputOnChange({
        target: {
          name: "schedule",
          value: [],
        },
      });
    } else {
      props.handleInputOnChange({
        target: {
          name: "schedule",
          value: Array.isArray(target) ? target : [target],
        },
      });
    }
  };

  const hanldeAddCompanyChange = (target) => {
    if (target?.length > 0) {
      getList("options/channels", { company_ids: target }).then((res) => {
        if (res?.success) {
          setChannelList(res?.data);
        }
      });
    } else {
      setChannelList([]);
    }
    props.handleInputOnChange({
      target: {
        name: "companies",
        value: target,
      },
    });
  };
  return (
    <Form noValidate onSubmit={props.handleClick}>
      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title">Basic Info</h4>
        </div>
        <div className="block-body">
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              BAT Route
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name="bat_route_id"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                value={batRoute}
                as="select"
                type="text"
              >
                <option value="">--Select--</option>
                {batRouteList && batRouteList?.length > 0
                  ? batRouteList.map((item, i) => {
                      return (
                        <option
                          key={i}
                          value={item.id}
                          selected={props.batRoute === item?.code}
                        >
                          {item?.code}
                        </option>
                      );
                    })
                  : ""}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select BAT Route.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Companies <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                allowClear
                placeholder="Please select"
                onChange={
                  props.isEditable
                    ? handleCompanyChange
                    : hanldeAddCompanyChange
                }
                value={props.formData.companies}
                options={
                  companyList && companyList?.length > 0 ? companyList : []
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Channels <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                allowClear={true}
                disabled={companyList && companyList?.length === 0}
                placeholder="Please select"
                onChange={handleChange}
                value={props.formData.channels}
                options={
                  channelList && channelList?.length > 0 ? channelList : []
                }
              />
            </Col>
            <Form.Control.Feedback type="invalid">
              Please enter channels.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Distributor
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                readOnly
                defaultValue={
                  routes?.find((i) => i.id === parseInt(route))
                    ?.distributor_name
                }
                required={!isEditable}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Business Type
              {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <label className="sr-only">Business Type</label>
              <select
                id="business_type"
                name="business_type"
                className="form-control"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                value={props.formData.business_type}
              >
                <option value={"multicat"} selected>
                  Multicat
                </option>
              </select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Schedule {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                allowClear={true}
                placeholder="Please select"
                onChange={(e) => {
                  handleScheduleChange(e);
                }}
                value={props.formData.schedule}
                options={
                  scheduleList && scheduleList?.length > 0 ? scheduleList : []
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Commission Applicable
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <label className="sr-only">Commission Applicable</label>
              <select
                id="schedule"
                name="is_commission_applicable"
                className="form-control"
                onChange={props.handleInputOnChange}
                value={props.formData?.is_commission_applicable}
              >
                <option value="">--Select--</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Outlet Name (English)
              {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"name"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.name}
                required={!isEditable}
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                Please enter name.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Delivery Man
              {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                required={!isEditable}
                name="route_id"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                value={route}
                as="select"
                type="text"
              >
                <option value="">--Select--</option>
                {routes?.length > 0
                  ? routes.map((item, i) => {
                      return (
                        <option
                          key={i}
                          value={item.id}
                          selected={props.formData.route_id === item.id}
                        >
                          {item?.title}
                        </option>
                      );
                    })
                  : ""}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select Delivery Man.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Salesman
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Select
                style={{
                  width: "100%",
                }}
                allowClear={true}
                placeholder="Please select"
                onChange={(e) => {
                  handleSalesManChange(e);
                }}
                value={props.formData.sr_id}
                options={
                  salesMan && salesMan?.length > 0
                    ? salesMan?.map((x) => ({ label: x.name, value: x.id }))
                    : []
                }
              />
            </Col>
          </Form.Group>
          {businessType === "multicat" && (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Credit Applicable
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <label className="sr-only">Credit Applicable</label>
                  <select
                    id="schedule"
                    name="is_credit_applicable"
                    className="form-control"
                    onChange={props.handleInputOnChange}
                    value={props.formData?.is_credit_applicable}
                  >
                    <option value="">--Select--</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                  Credit Value
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name="credit_value"
                    disabled={
                      props?.formData && !props?.formData?.is_credit_applicable
                    }
                    onChange={props.handleInputOnChange}
                    defaultValue={props?.formData?.credit_value}
                    type="text"
                    placeholder=""
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="additionalCreditValue"
                style={{ display: "none" }}
              >
                <Form.Label column sm="3">
                  Credit Type
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name="credit_type"
                    onChange={props.handleInputOnChange}
                    value={props.formData?.credit_type || "fixed"}
                    required={!isEditable}
                    type="text"
                    placeholder=""
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter additional credit type
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </>
          )}
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Outlet Name (Bangla)
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"bn_name"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.bn_name}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formName">
            <Form.Label column sm="3">
              Slug {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                type="text"
                required={!isEditable}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                value={props.formData.slug}
                name="slug"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a slug.
              </Form.Control.Feedback>
              {props.uniqueSlug === false && (
                <div class="slug-err-msg">Please provide an unique slug.</div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Phone {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"phone"}
                autoComplete="new-phone"
                autoCorrect="off"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.phone}
                required={!isEditable}
                pattern="(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$"
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                Please enter phone no.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Email
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"email"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.email}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Password {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"password"}
                autoComplete="new-password"
                autoCorrect="off"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                type="password"
              />
              {props.partnerInfo?.password_presence && (
                <i className="text-help text-muted text-danger">
                  Please keep this field blank if you don't want to change the
                  password.
                </i>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Confirm Password{" "}
              {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"password_confirmation"}
                autoComplete="new-password"
                autoCorrect="off"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                type="password"
              />
              {props.partnerInfo?.password_presence && (
                <i className="text-help text-muted text-danger">
                  Please keep this field blank if you don't want to change the
                  password.
                </i>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Status
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <RadioGroup
                row
                name="status"
                value={status}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
              >
                <FormControlLabel
                  value={"active"}
                  control={<Radio />}
                  label="Active"
                  checked={props.formData.status === "active"}
                />
                <FormControlLabel
                  value={"inactive"}
                  control={<Radio />}
                  label="Inactive"
                  checked={props.formData.status === "inactive"}
                />
              </RadioGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Prompt Sale Status
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <RadioGroup
                row
                name="prompt_sale_status"
                value={prompt_sale_status}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
              >
                <FormControlLabel
                  value={"enable"}
                  control={<Radio />}
                  label="Enable"
                  checked={props.formData.prompt_sale_status === "enable"}
                />
                <FormControlLabel
                  value={"disable"}
                  control={<Radio />}
                  label="Disable"
                  checked={props.formData.prompt_sale_status === "disable"}
                />
              </RadioGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              TSA Id
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"tsa_id"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.tsa_id}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Retailer Code
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"retailer_code"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.retailer_code}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Partner Code
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"partner_code"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.partner_code}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Region
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"region"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.region}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Territory
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"territory"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.territory}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Point
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"point"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.point}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Owner Name
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"owner_name"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.owner_name}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Cluster Name
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"cluster_name"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.cluster_name}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Sub Channel
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"sub_channel"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.sub_channel}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              bKash Number
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"bkash_number"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.bkash_number}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Area
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"area"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.area}
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Latitude
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"latitude"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.latitude}
                type="number"
                step="any"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Longitude
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                name={"longitude"}
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={props.partnerInfo.longitude}
                type="number"
                step="any"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Thana {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                required={!isEditable}
                name="thana_id"
                onChange={(e) => {
                  callAreas(e.target.value);
                  props.handleInputOnChange(e);
                }}
                value={props.formData.thana_id}
                as="select"
                type="text"
              >
                <option value="">--Select Thana--</option>
                {thanas?.length > 0
                  ? thanas.map((item) => {
                      return (
                        <option
                          value={item.id}
                          selected={props.formData.thana_id === item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })
                  : ""}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter address.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Area Code {!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                required={!isEditable}
                name="area_id"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                as="select"
                type="text"
                value={props.formData.area_id}
              >
                <option value="">--Select Area--</option>
                {areas?.length > 0
                  ? areas.map((item) => {
                      return (
                        <option
                          value={item.id}
                          selected={props.formData.area_id === item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })
                  : ""}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter address.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="3">
              Address{!isEditable && <span className="text-danger">*</span>}
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                required={!isEditable}
                name="address_line"
                onChange={(e) => {
                  props.handleInputOnChange(e);
                }}
                defaultValue={addressLine}
                as="textarea"
                type="text"
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter address.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formImages">
            <Form.Label column sm="3">
              Image
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <BrowseButton
                onChange={(e) => {
                  props.handleInputOnChange({
                    target: { name: "image_file", value: e.target.files[0] },
                  });
                }}
                id={"imageFile"}
                name={"image_file"}
              />
              {props.partnerInfo.image && (
                <div className="existing-images">
                  <div className="existing-img">
                    <span className="btn-remove">×</span>
                    <img src={props.partnerInfo.image} alt="" />
                  </div>
                </div>
              )}
            </Col>
          </Form.Group>
        </div>
      </div>

      <div className="mt-3 text-right">
        <Button
          size="lg"
          type={`submit`}
          disabled={
            props.isSubmitting ||
            !props.readyToSubmit ||
            props.uniqueSlug === false ||
            (dataToUpdate && _.isEmpty(dataToUpdate))
          }
          variant="primary"
        >
          {props.isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </div>
    </Form>
  );
}

export default PartnerForm;
