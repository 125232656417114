import React, { Component } from 'react';
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import OrderList from "./OrderList";
import DateRangeFilter from "./common/DateRangeFilter";
import moment from "moment";
import ReturnRequests from './ReturnRequests';
import CustomerReturnOrders from './CustomerReturnOrders';
import OrdersSummary from './OrdersSummary';
import { getData } from '../../services/baseServices'
import { getFormattedDate } from "../../helper/utils";

class RouteCollect extends Component {
	constructor(){
		super();
		var startDate = localStorage.getItem("reconciliation_st_d") ? moment(localStorage.getItem("reconciliation_st_d")): moment().subtract(29, 'days');
		var today = localStorage.getItem("reconciliation_ed_d") ? moment(localStorage.getItem("reconciliation_ed_d")): moment();

		this.state = {
			riderDetails: {},
			isWaiting: false,
			start_date_time: startDate,
			end_date_time: today,
            temp_params: { start_date_time:  getFormattedDate(startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(today, "YYYY-MM-DD") },
            query_params: { start_date_time:  getFormattedDate(startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(today, "YYYY-MM-DD") }, 
			tablesToRefresh: [],
            tableRefreshed: []
		};
	}

	refreshData = (tablesToRefresh) => {
		console.log(tablesToRefresh)
        this.setState({tablesToRefresh})
        // console.log("reload")
        // setTimeout(() => { window.location.reload() }, 1000)
    }

    stopReloading = (tableToRefresh) => {
        let tableRefreshed = this.state.tableRefreshed;
        tableRefreshed.push(tableToRefresh)
		let filteredTables = this.state.tablesToRefresh.filter(i => i !== tableToRefresh)
        this.setState({tablesToRefresh: filteredTables, tableRefreshed})
        // console.log("reload")
        // setTimeout(() => { window.location.reload() }, 1000)
    }

	getQueryParams = () => {
		let params = Object.assign(this.state.query_params);
		return params;
	}

	handleOnFilter = (e) => {
		e.preventDefault();
		const tempParam = this.state.temp_params;

		localStorage.setItem("reconciliation_st_d", this.state.temp_params.start_date_time)
        localStorage.setItem("reconciliation_ed_d", this.state.temp_params.end_date_time)
		this.setState({query_params: tempParam});
	}

	handleOnApply = (e, picker)=>{
        this.setState({start_date_time: getFormattedDate(picker.startDate,''), end_date_time: getFormattedDate(picker.endDate,'')})
        this.setState({temp_params: {...this.state.temp_params, start_date_time: getFormattedDate(picker.startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(picker.endDate, "YYYY-MM-DD")}})
    }

	componentDidMount = () => {
		getData(`riders/${this.props.match.params.id}`)
		.then(res => {
			this.setState({riderDetails: res})
			}
		)
	}

  render() {
	  console.log('rider details', this.state.riderDetails);
    return (
      <App layout={`boxed`}>
        <div className="main-content">
          <div className="page-header">
            <div className="page-title"/>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/reconciliation`} className={`btn btn-sm btn-link`}><MdArrowBack/> Back
                to List</Link>
            </div>
          </div>
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Rider Details</h4>
            </div>
            <div className="block-body">
				<>
					<table className="info-table">
						<tbody>
							<tr>
								<td style={{width: '250px'}}>Rider Title</td>
								<td style={{width: '20px', textAlign: 'center'}}>:</td>
								<td className={`text-bold`}>{this.state.riderDetails?.name}</td>
							</tr>
							<tr>
								<td style={{width: '250px'}}>Phone</td>
								<td style={{width: '20px', textAlign: 'center'}}>:</td>
								<td>{this.state.riderDetails?.phone}</td>
							</tr>
						</tbody>
					</table>
					<hr/>

					<div>
						<div align="center">
							<DateRangeFilter start_date_time={this.state.start_date_time} end_date_time={this.state.end_date_time} handleOnApply={(e, picker) => this.handleOnApply(e, picker)} filterList={this.handleOnFilter}/>
						</div>

						<OrdersSummary 
							queryParams={this.state.query_params} 
							whom={'rider'} 
							url={`riders/${this.props.match.params.id}/cash_collected_summary`}
							tablesToRefresh = {this.state.tablesToRefresh}
							tableRefreshed = {this.state.tableRefreshed}
							stopReloading = {this.stopReloading}
							onAfterAnyAction = {this.refreshData}/>
						<OrderList 
							queryParams={this.state.query_params} 
							whom={'rider'} 
							url={`riders/${this.props.match.params.id}/cash_collected_orders`}
							tablesToRefresh = {this.state.tablesToRefresh}
							tableRefreshed = {this.state.tableRefreshed}
							stopReloading = {this.stopReloading}
							onAfterAnyAction = {this.refreshData}/>
						
					</div>
					<br/>

					<ReturnRequests 
						whom={'rider'} 
						rider_id={this.state.riderDetails.id}
						queryParams={this.state.query_params} 
						url={`riders/${this.props.match.params.id}/return_requests`}
						stopReloading = {this.stopReloading}
						onAfterAnyAction = {this.refreshData}/>
					<br></br>
					<CustomerReturnOrders 
						whom={'rider'} 
						queryParams={this.state.query_params} 
						url={`riders/${this.props.match.params.id}/return_orders`}
						stopReloading = {this.stopReloading}
						onAfterAnyAction = {this.refreshData}/>
				</>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(RouteCollect);
