import React, {useEffect, useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import App from "../../App";
import { Link, Redirect, useHistory } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getThanas, getAreas, getList, postData2 } from "../../services/baseServices";
import { humanize } from "../../helper/utils";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { getWarehouseType } from "../../helper/utils"

const Create = () => {
    const [categories, setCategories] = useState([])
    const [thanas, setThanas] = useState([])
    const [areas, setAreas] = useState([])
    const [distributors, setDistributors] = useState([]);

    useEffect(()=>{
        getList('retailer_assistants/ra-categories')
        .then((res) => {
            setCategories(res)
        })
        .catch((errMsg) => {});

      getList("distributors")
      .then((res) => {
          setDistributors(res?.data);
      })
      .catch((errMsg) => {});
    },[])

    const history = useHistory()

    const schema = yup.object().shape({
        category: yup.string().required("Please enter category."),
        distributor_id: yup.string().required("Please enter Distributor."),
        name: yup.string()
            .required("Name is a required"),
            // .matches(/^[a-zA-Z]+(\.?)(\s[a-zA-Z]+(\.?))*$/, "No special characters and numbers are allowed"),
        fathers_name: yup.string()
            .required("Fathers Name is a required")
            .matches(/^[a-zA-Z]+(\.?)(\s[a-zA-Z]+(\.?))*$/, "No special characters and numbers are allowed"),
        email: yup.string().email("Please provide valid email"),
        date_of_birth:yup.string().required(),
        phone: yup.string()
            .required("Phone is required")
            .matches(/(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$/, "Please provide valid BD phone number"),
        password: yup.string()
            .required("Password is required")
            .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,"At least one number and one uppercase and lowercase letter, and more than 8 characters"),
        password_confirmation: yup.string()
            .required('Password confirmation is required')
            .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,"At least one number and one uppercase and lowercase letter, and more than 8 characters")
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
        education: yup.string().required("Education is required").trim(),
        tech_skill: yup.string().required("Tech savvyness is required").trim(),
        experience: yup.string().required("Experience is required").trim(),
        thana_id: yup.string().required("Please enter thana."),
        area_id: yup.string().required("Please enter area."),
        address_line: yup.string().required("Please enter address line.").trim(),
      });

    const { register, handleSubmit, control, formState:{ errors }, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            category: "dedicated",
          }
      });

      const selected_thana = watch('thana_id','0')
      const selected_distributor = watch('distributor_id', '')

      useEffect(()=>{
        selected_distributor &&
        getThanas({ distributor_id: selected_distributor })
        .then((res) => {
            setThanas(res)
            setValue('area_id', '')
            setAreas([])
        })
        .catch((errMsg) => {});
      }, [selected_distributor])

      useEffect(()=>{
         callAreas(selected_thana)
      },[selected_thana])

      const callAreas = (thana_id) => {
        setValue('area_id', '')
        getAreas({ thana_id: thana_id })
          .then((res) => {
            setAreas(res)
          })
          .catch((errMsg) => {
          });
      };

      const onSubmit = data => {
          let phone_no = ''
          if(data.phone.length > 11){
              phone_no = data.phone.slice(3)
          }else{
            phone_no = data.phone
          }
          const params = {
            address_attributes:{
                address_line:data.address_line,
                area_id: +data.area_id,
                thana_id: +data.thana_id
            },
            category: data.category,
            date_of_birth: new Date(data.date_of_birth),
            education: data.education,
            email: data.email,
            experience: data.experience,
            father_name: data.fathers_name,
            name: data.name,
            nid: data.nid,
            password: data.password,
            password_confirmation: data.password_confirmation,
            phone: phone_no ,
            tech_skill: data.tech_skill,
            distributor_id: data.distributor_id
          }
          postData2("retailer_assistants",params)
          .then(res => {
            if(res.success) {
              history.push(`/ra/list`);
              toast.success("Sales Representatives has been created successfully.");
            }
            else {
              toast.error(res.message);
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
          });  
        };

    if (getWarehouseType()=='central')
        return (<Redirect to={{pathname: '/ra/list'}}/>);
    return (
        <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Create RA</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/ra/list`}
              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
        <Form
            onSubmit={handleSubmit(onSubmit)}
            >
            <Form.Group as={Row} controlId="ds">
                <Form.Label column sm="3">
                    Distributor <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                    required
                    name="distributor_id"
                    {...register("distributor_id")}
                    as="select"
                    type="text"
                    >
                    <option value="">--Select--</option>
                    {distributors.map((item, i) => {
                        return (
                            <option
                                key={i}
                                value={item.id}
                            >
                                {item.name}
                            </option>
                        );
                    })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                    Please select distributor.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="thana">
                <Form.Label column sm="3">
                Category <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    as="select"
                    type="text"
                    {...register("category")}
                    className={errors.category? 'is-invalid' : ''}
                >
                    {
                    Object.keys(categories).map((key, i) => (
                        <option key={i+1} value={key}>{humanize(key)}</option>
                    ))
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {errors.category?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>


            <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3">
                    Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        {...register("name")}
                        type="text"
                        className={errors.name ? 'is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.name?.message}
                     </Form.Control.Feedback>
                 
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="fathers_name">
                <Form.Label column sm="3">
                    Father's Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        type="text"
                        {...register("fathers_name")}
                        className={errors.fathers_name ? 'is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.fathers_name?.message}
                     </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="email">
                <Form.Label column sm="3">
                    Email
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        type="email"
                        {...register("email")}
                        className={errors.email ? 'is-invalid' : ''}
                    />
                     <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                     </Form.Control.Feedback>
                </Col>
            </Form.Group>

              <Form.Group as={Row} controlId="age">
                <Form.Label column sm="3">
                    Date of Birth <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Controller
                    control={control}
                    {...register("date_of_birth")}
                    render={({ field}) => (
                    <DatePicker
                        className={`form-control ${errors.date_of_birth ? 'is-invalid' : ''}`}
                        placeholderText='Select birth date'
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                    />
                )}
                />
                <Form.Control.Feedback type="invalid" >
                    {errors.date_of_birth?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="phone">
                <Form.Label column sm="3">
                    Phone Number <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    type="text"
                    {...register("phone")}
                    className={errors.phone ? 'is-invalid' : ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.phone?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
         
            <Form.Group as={Row} controlId="password">
                <Form.Label column sm="3">
                    Password <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    autoComplete="new-password"
                    autoCorrect="off"
                    type="password"
                    {...register("password")} 
                    className={errors.password ? 'is-invalid' : ''}       
                />
                <Form.Control.Feedback type="invalid">
                    {errors.password?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="password_confirmation">
                <Form.Label column sm="3">
                Confirm Password{" "}
                <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    {...register("password_confirmation")}        
                    autoComplete="new-password"
                    autoCorrect="off"
                    type="password"
                    className={errors.password_confirmation? 'is-invalid' : ''}   
                />
                <Form.Control.Feedback type="invalid">
                    {errors.password_confirmation?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
          
            <Form.Group as={Row} controlId="education">
                <Form.Label column sm="3">
                    NID
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    {...register("nid")}        
                    type="number"
                />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="education">
                <Form.Label column sm="3">
                    Eduction <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    type="text"
                    {...register("education")}
                    className={errors.education ? 'is-invalid' : ''}        
                />
                <Form.Control.Feedback type="invalid">
                    {errors.education?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tech_savvyness">
                <Form.Label column sm="3">
                    Tech Savvyness <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <textarea
                    {...register("tech_skill")}        
                    row={5}
                    type="text"
                    className={`form-control ${errors.tech_skill ? 'is-invalid' : ''}`}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.tech_skill?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="experience">
                <Form.Label column sm="3">
                    Experience <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <textarea
                    type="text"
                    {...register("experience")}        
                    row={5}
                    className={`form-control ${errors.experience ? 'is-invalid' : ''}`}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.experience?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>


             <Form.Group as={Row} controlId="thana">
                <Form.Label column sm="3">
                Thana <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                        className={errors.thana_id? 'is-invalid' : ''} 
                        {...register("thana_id")}          
                        as="select"
                        type="text"
                    >
                      <option value="">--Select Thana--</option>
                      {thanas.length > 0
                      ? thanas.map((item) => {
                          return <option value={item.id} key={item.id}>{item.name}</option>;
                          })
                      : ""}
                  </Form.Control>
                
                <Form.Control.Feedback type="invalid">
                    {errors.thana_id?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="area">
                <Form.Label column sm="3">
                Area <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    {...register("area_id")} 
                    className={errors.area_id? 'is-invalid' : ''}                  
                    as="select"
                    type="text"
                >
                    <option value="">--Select Area--</option>
                    {areas.length > 0
                    ? areas.map((item) => {
                        return <option value={item.id} key={item.id}>{item.name}</option>;
                        })
                    : ""}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {errors.area_id?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                Address<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                <Form.Control
                    {...register("address_line")}        
                    rows={2}
                    as="textarea"
                    type="text"
                    className={errors.address_line ? 'is-invalid' : ''}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                {errors.address_line?.message}
                </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <div className="mt-3 text-center">
                <Link
                    type="button"
                    className="btn btn-sm btn-btn-sm mr-2"
                    to={`${process.env.PUBLIC_URL}/ra/list`}
                    >
                <Button type="button" variant="danger">
                    Cancel
                </Button>
                </Link>
                <Button
                    size="btn-sm"
                    type={"submit"}
                    variant="primary"
                    >
                    Submit
                </Button>
            </div>
            </Form>
            </div>
        </div>
      </App>
            
    )
}

export default Create



// import React, { Component } from 'react';
// import App from "../../App";
// import { Link, withRouter, Redirect } from "react-router-dom";
// import { FaLongArrowAltLeft } from "react-icons/all";
// import RAForm from "./Form";
// import { toast } from 'react-toastify';
// import { getWarehouseType } from "../../helper/utils"
// import { postData } from "../../services/baseServices"

// class Create extends Component {
//   constructor(props) {
//     super(props);

//     this.handleAddRA = this.handleAddRA.bind(this);
//     this.handleInputOnChange = this.handleInputOnChange.bind(this);
//   }
//   state = {
//     formData: { category: 'dedicated' },
//     raInfo: {},
//     isFormValidated: false,
//     isLoadingData: true,
//     isSubmitting: false,
//     roles: {},
//     errors:{
//       name: 'Name is required',
//       father_name: 'Father Name is required',
//       email: 'Email is required',
//       phone: 'Phone is required',
//       password: 'Password is required',
//       password_confirmation: 'Password confirmation is required',
//       address: 'Please enter address.',
//       education: 'Education is required',
//       tech_skill: "Tech savvyness is required",
//       experience: "Experience is required",
//       date_of_birth: "Date of birth is required"
//     }
//   }


//   handleInputOnChange = (e) => {
//     const formData = {...this.state.formData};
//     const pattern = "^[a-zA-Z]+(\.?)(\s[a-zA-Z]+(\.?))*$";
//     if (e.target.name==='name' && e.target.value.match(e.target.pattern) === null) {
//       this.setState({errors: {...this.state.errors, name: 'No special characters and numbers are allowed'}});
//       return;
//     } else if (e.target.name==='father_name' && e.target.value.match(e.target.pattern) === null) {
//       this.setState({errors: {...this.state.errors, fathers_name: 'No special characters and numbers are allowed'}});
//       return;
//     } else if(e.target.name==='email' && e.target.value.match(e.target.pattern) === null){
//       this.setState({errors: {...this.state.errors, email: 'Please provide valid email'}});
//       return;
//     } else if(e.target.name==='phone' && e.target.value.match(e.target.pattern) === null){
//       this.setState({errors: {...this.state.errors, phone: 'Please provide valid BD phone number'}});
//       return;
//     } else if(e.target.name==='password' && e.target.value.match(e.target.pattern) === null){
//       this.setState({errors: {...this.state.errors, password: 'At least one number and one uppercase and lowercase letter, and more than 8 characters'}});
//       return;
//     } else if(e.target.name==='password_confirmation' && formData["password"]!==e.target.value){
//       this.setState({errors: {...this.state.errors, password_confirmation: 'Password doesn\'t match'}});
//       return;
//     } else if(e.target.name==='education' && e.target.value.match(e.target.pattern) === null){
//       this.setState({errors: {...this.state.errors, education: 'No special characters are allowed'}});
//       return;
//     } else if(e.target.name==='tech_skill' && !e.target.value.trim()){
//       this.setState({errors: {...this.state.errors, tech_skill: 'No special characters are allowed'}});
//       return;
//     } else if(e.target.name==='experience' && e.target.value.match(pattern) === null){
//       this.setState({errors: {...this.state.errors, experience: 'No special characters are allowed'}});
//       return;
//     } 
    
//     if (e.target.name==='thana_id') {
//       delete formData.area_id
//     }

//     formData[e.target.name] = e.target.value;
//     this.setState({formData}, () => {
//     });
//   }

//   checkDateValidation = () => {
//     const formData = {...this.state.formData};
//     if(!formData.date_of_birth){
//       this.setState({hasDateError: true})
//     } else {
//       this.setState({hasDateError: false})
//     }
//   }

//   handleDateOnChange = (date) => {
//     const formData = {...this.state.formData};
//     formData["date_of_birth"] = date;
//     this.setState({formData}, () => {
//       this.checkDateValidation();
//     });
//   }

//   handleAddRA = (e) => {
//     e.preventDefault();
//     const form = e.currentTarget;
//     if (form.checkValidity() === false) {
//       e.preventDefault();
//       e.stopPropagation();
//       form.className += " was-validated";
//       this.setState({isFormValidated: true})
//       this.checkDateValidation();
//     } else {
//       this.setState({isSubmitting: true}, () => {
//         const ra = {...this.state.formData}
//         const address_attributes = {
//           address_attributes : {
//             address_line: ra.address_line,
//             area_id: parseInt(ra.area_id)
//           }
//         }
//         delete ra.address_line;
//         delete ra.area_id;
//         delete ra.thana_id;

//         const raForm = Object.assign(ra, address_attributes)
//         console.log('raFormmmmmmmmmm', raForm);
        
//         if(raForm.password === raForm.password_confirmation) {
//           postData("retailer_assistants",raForm)
//             .then(res => {
//               if(res.status_code == 200 || res.status_code == 201) {
//                 this.props.history.push(`/ra/list`);
//                 toast.success("RA has been created successfully.");
//               }
//               else {
//                 toast.error(res.message);
//                 this.setState({isSubmitting: false})
//               }
//             })
//             .catch(errMsg => {
//               toast.error(errMsg);
//               this.setState({isSubmitting: false})
//             });
//         } else {
//           toast.error("Password doesn't match with confirmed password");
//           this.setState({isSubmitting: false})
//         }
//       })
//     }
//   }
//   render() {
//     if (getWarehouseType()=='central')
//       return (<Redirect to={{pathname: '/ra/list'}}/>);
//     return (
//       <App layout="boxed">
//         <div className="page-header">
//           <h2 className="page-title">Create RA</h2>
//           <div className="ml-auto">
//             <Link to={`${process.env.PUBLIC_URL}/ra/list`}
//               className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
//           </div>
//         </div>

//         <div className="card-block">
//           <div className="block-body">
//             <>
//               <RAForm
//                 handleClick={this.handleAddRA}
//                 handleInputOnChange={this.handleInputOnChange}
//                 handleDateOnChange={this.handleDateOnChange}
//                 formData={this.state.formData}
//                 isSubmitting={this.state.isSubmitting}
//                 isCreateForm={true}
//                 isFormValidated={this.state.isFormValidated}
//                 errors={this.state.errors}
//                 raInfo={this.state.raInfo}
//                 hasDateError={this.state.hasDateError}
//               />
//             </>
//           </div>
//         </div>
//       </App>
//     );
//   }
// }
// export default withRouter(Create);