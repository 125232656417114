import React, { useEffect, useState } from 'react';
import App from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {FiEdit, FiPlus, FiEye } from "react-icons/all";
import { Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import '../../assets/scss/header.scss';
import { getList } from "../../services/baseServices";

const List = () => {
    const [pages, setPages] = useState([])
    useEffect(() =>{
        getList('static_pages')
        .then(res =>{
            if(res?.success){
                setPages(res.data)
            }
        })
    },[])


    return (
        <App layout="">
          <div className="main-content">
            <div className="page-header">
              <h2 className="page-title">Meta Info List</h2>
              <div className="ml-auto">
                <Link to={`${process.env.PUBLIC_URL}/meta-info/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Meta Info</Link>
              </div>
            </div>

              <div className="card-block">
                <div className="block-body">
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th style={{width: '50px'}}>#</th>
                        <th>Page</th>
                        <th>Title</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {pages?.length > 0 ? pages.map((item, index) => {
                        return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="order-item">
                                 {item.page_type}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.meta_title}
                                </div>
                              </td>
                              <td style={{width: '150px'}}>
                                <div className="btn-group">
                                  <OverlayTrigger
                                    key="view"
                                    placement="top"
                                    overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      View Meta Info 
                                    </Tooltip>
                                    }
                                  >
                                  <Link to={`/meta-info/details/${item?.id}`} className="btn btn-sm btn-default"><FiEye/></Link>
                                </OverlayTrigger>
                                  <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          Edit Meta Info
                                        </Tooltip>
                                      }
                                  >
                                  <Link to={`/meta-info/edit/${item?.id}`} className="btn btn-sm btn-default"><FiEdit/></Link>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                        );
                      }) : ""}
                      </tbody>
                    </table>
                  </div>
              </div>
          
            </div>
          <ToastsContainer store={ToastsStore}/>
        </App>
    );
}

export default List
