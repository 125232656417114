import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import { withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {add, getAreas, getLocations, getThanas} from "../../services/baseServices";
import { Select } from 'antd';

class Add extends Component {
  constructor() {
    super();
    this.state = {
      formData: {is_commission_applicable: true},
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
      districts: [],
      thanas: [],
      areas: [],
      thanaVisibility: true,
      areaVisibility: true,
    };
  }

  componentDidMount() {
    getLocations('districts')
      .then(res => {
        this.setState({districts: res})
      })
      .catch(errMsg => {
      })
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
    });
  }
  handleDistrictsSelect = (e) => {
    const formData = { ...this.state.formData }
    let selectedDistrict = [];
    this.state.districts.some(r=> {
      if(e.includes(r.name)) {
        selectedDistrict.push(r)
      }
    })
    formData.districts = selectedDistrict;
    this.setState({formData: formData})
}
  callThanas = district_id => {
    getThanas({district_id: district_id})
      .then(res => {
        this.setState({thanas: res.data, thanaVisibility: false})
      })
      .catch(errMsg => {
      })
  }
  callAreas = thana_id => {
    getAreas({thana_id: thana_id})
      .then(res => {
        this.setState({areas: res, areaVisibility: false})
      })
      .catch(errMsg => {
      })
  }
  handleAddDwh = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        const formData = {...this.state.formData}
        if(formData.districts?.length <1){
          toast.error('Select districts first')
          this.setState({ isSubmitting: false })
          return
        }
        // formData.address_attributes = {
        //   address_line: formData.address_line,
        //   bn_address_line: formData.bn_address_line,
        //   district_id: parseInt(formData.district_id),
        //   thana_id: parseInt(formData.thana_id),
        //   area_id: parseInt(formData.area_id),
        // }

        delete formData.district_id;
        delete formData.thana_id;

        add("warehouses", "warehouse", formData)
          .then(res => {
            this.props.history.push(`/dwh/list`);
            toast.success("Warehouse has been added successfully");
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }

  render() {
    const districtOptions =
      this.state.districts.length > 0 &&
      this.state.districts.map((item) => {
        return {
          key: item.name,
          id: item.id,
          dwh_assigned: item.dwh_assigned
        }
      });
      console.log(this.state)
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Distribution Warehouse</h2>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form
                noValidate
                validated={this.state.isFormValidated}
                onSubmit={this.handleAddDwh}>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Warehouse Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      onChange={this.handleInputOnChange}
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter warehouse name
                      name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">Commission Applicable<span className="text-danger">*</span></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                  <label className="sr-only">Commission Applicable</label>
                  <select id="schedule" name="is_commission_applicable" className='form-control' onChange={this.handleInputOnChange} value={this.state.formData?.is_commission_applicable}>
                    <option value="">--Select--</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Name in Bangla <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"bn_name"}
                      required
                      onChange={this.handleInputOnChange}
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter warehouse name in bangla
                      name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"email"}
                      onChange={this.handleInputOnChange}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter email.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"password"}
                      onChange={this.handleInputOnChange}
                      required
                      type="password"/>
                    <Form.Control.Feedback type="invalid">Please enter password.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Confirm Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"password_confirmation"}
                      onChange={this.handleInputOnChange}
                      required
                      type="password"/>
                    <Form.Control.Feedback type="invalid">Please repeat password.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="branding_button_url">
                  <Form.Label column sm="3">
                    Covering Districts <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                      <Select
                          mode="multiple"
                          size="large"
                          placeholder="Select Districts filter options"
                          onChange={this.handleDistrictsSelect}
                          style={{ width: '100%' }}
                      >
                          {districtOptions && districtOptions?.map((item) => (
                              <Select.Option  key={item.key}>
                                  {item.key}
                              </Select.Option>
                          ))}
                      </Select>
                  </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    FC District <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      required
                      name="district_id"
                      onChange={e => {
                        const formData = {...this.state.formData};
                        formData[e.target.name] = e.target.value;
                        this.setState({formData})
                        this.callThanas(e.target.value);
                      }}
                      as="select" type="text">
                      <option value="">--Select District--</option>
                      {this.state.districts.length > 0 ?
                        this.state.districts.map((item) => {
                          return (
                            <option value={item.id} selected={item.id === this.state.formData.district_id}>{item.name}</option>
                          )
                        })
                        : ""
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    FC Thana <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      required
                      disabled={this.state.thanaVisibility}
                      name="thana_id"
                      onChange={e => {
                        const formData = {...this.state.formData};
                        formData[e.target.name] = e.target.value;
                        this.setState({formData})
                        this.callAreas(e.target.value);
                      }}
                      as="select" type="text">
                      <option value="">--Select Thana--</option>
                      {this.state.thanas.length > 0 ?
                        this.state.thanas.map((item) => {
                          return (
                            <option value={item.id} selected={item.id === this.state.formData.thana_id}>{item.name}</option>
                          )
                        })
                        : ""
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    FC Area <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      required
                      disabled={this.state.areaVisibility}
                      name="area_id"
                      onChange={this.handleInputOnChange}
                      as="select" type="text"
                    >
                      <option value="">--Select Area--</option>
                      {this.state.areas.length > 0 ?
                        this.state.areas.map((item) => {
                          return (
                            <option value={item.id} selected={item.id === this.state.formData.area_id}>
                              {item.name}
                            </option>
                          )
                        })
                        : ""
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Address <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"address_line"}
                      onChange={this.handleInputOnChange}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Address in Bangla <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"bn_address_line"}
                      onChange={this.handleInputOnChange}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter address.</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Phone no.
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"phone"}
                      onChange={this.handleInputOnChange}
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter phone.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="public_visibility">
                  <Form.Label column sm="3">
                    Public Visibility
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    required
                    name="public_visibility"
                    onChange={this.props.handleInputOnChange}
                    as="select"
                    type="text"
                  >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                  </Col>
              </Form.Group>
                <div className="mt-3">
                  <Button
                    size="lg"
                    type={"submit"}
                    disabled={this.state.isSubmitting}
                    variant="primary">{this.state.isSubmitting ? 'Adding...' : 'Add'}
                  </Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);