import React, { useEffect, useState, useRef } from 'react';
import App from "../../App";
import { AiOutlineArrowLeft, FaPrint, FiEye } from "react-icons/all";
import { Link, withRouter, useParams, useHistory } from "react-router-dom";
import Loader from 'react-loader';
import { getData, destroy2, putData2 } from '../../services/baseServices'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import ChallanCopy from './ChallanCopy';
import { humanize, getFormattedDate } from '../../helper/utils';
import ConfirmationModal from "../common/Modal";
import _ from 'lodash'
import { toast } from 'react-toastify';
import { DEBOUNCE_TIME } from "../../helper/env"

const ForwardChallanDetails = () => {
    const [challanDetails, setChallanDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const componentRef = useRef();
    const {id} = useParams();
    const history = useHistory()

    const getChallan = () => {
      getData(`challans/${id}`)
      .then(res => {
        if(res?.success) {
          setChallanDetails(res?.data)
        } else {
          history.push('/challan/list')
        }

        setLoading(false)
      })
      .catch(errMsg => {
        console.log('Error Message: ', errMsg)
        history.push('/challan/list')
        setLoading(false)
      });
    }
  
    useEffect (() =>{
        setLoading(true)
        getChallan()
    }, [])

    const handleRemoveOrder = (orderId) => {
      destroy2(`challans/${challanDetails?.id}/remove_order`, {order_id: orderId})
        .then(res => {
            if(res.success){
              toast.success(res?.message);
              history.go(0)
            } else {
                toast.error(res?.message)
            }
        })
        .catch(errMsg => {
            toast.error(errMsg)
        })
    }

    const dispatchChallan = (challanId)=>{
      putData2(`challans/${challanId}/dispatch`)
        .then(res => {
          if(res.success) {
            toast.success(res.message);
            history.go(0)
          }
          else {
            toast.error(res.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg);
        }); 
    }

    const debouncedOnDispatch = _.debounce((challanId)=>{
      dispatchChallan(challanId)
    }, DEBOUNCE_TIME);
  
    const debouncedOnDispatchChallan = (challanId) => {
      debouncedOnDispatch(challanId);
    }

      return (
        <App layout="">
          <div className="page-header">
            <h2 className="page-title">Challan Details</h2>
            <div className="ml-auto">
              <Link to="/challan/list"
                    className="btn btn-sm btn-link mr-2"><AiOutlineArrowLeft/> Back to List</Link>
            </div>
          </div>
          
          <div className='d-flex justify-content-end mb-2'>
            {
                challanDetails.status === 'initiated'
                ?
                <OverlayTrigger
                key="view"
                placement="top"
                overlay={
                <Tooltip id={`tooltip-view`}>Send to DH</Tooltip>
                }
            >
                <ConfirmationModal 
                    customBtn = {true}
                    btnText = "Send to DH"
                    variant = "primary"
                    title="Send to DH" 
                    body="Are you sure to dispatch the challan?" 
                    handleAction={() =>debouncedOnDispatchChallan(challanDetails.id)}/>
              </OverlayTrigger>
              :
              <button className='btn btn-sm btn-primary' disabled>
                Send to DH
              </button>
            }
           
              <ReactToPrint
                trigger={() => {
                  return (<a href="#">
                    <Button className='ml-2' variant={"default"}  size={"sm"}><FaPrint/> Print Challan
                    </Button>
                  </a>)
                }}
                content={() => componentRef.current}
              />
            <div  ref={componentRef} >
              <ChallanCopy 
                orderItems={challanDetails?.challan_line_items || []}
                to={challanDetails?.distributor} 
                from={challanDetails?.fulfillment_center}/>
            </div>
          </div>
            
          <div className="main-content">
              <Loader loaded={!loading}>
                  <div className="card-block">
                      <div className="block-body">
                          <table className="info-table">
                              <tbody>
                                <tr>
                                    <td style={{width: '200px'}}>Challan No</td>
                                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                    <td className={`text-bold`}>{challanDetails?.id}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '200px'}}>Distributor</td>
                                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                    <td className={`text-bold`}>{challanDetails.distributor?.name}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '200px'}}>Status</td>
                                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                    <td>{humanize(challanDetails?.status)}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '200px'}}>Created Date</td>
                                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                    <td>{getFormattedDate(challanDetails?.created_at, 'DD-MM-YYYY')}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '200px'}}>Total Orders</td>
                                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                    <td>{challanDetails?.challan_line_items?.length}</td>
                                </tr>
                                {
                                    challanDetails?.status !== 'initiated' &&
                                    <tr>
                                        <td style={{width: '200px'}}>Received Orders</td>
                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                        <td>{challanDetails?.challan_line_items?.filter(i=>i.return_status !== 'initiated')?.length}</td>
                                    </tr>
                                }
                              </tbody>
                          </table>
                          <h6 className='mt-3'>Order List</h6>
                          <table className="order-table table table-striped">
                            <thead>
                              <tr>
                                <th>Order Id</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Shipping Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                challanDetails?.challan_line_items?.map((lineItem, index)=>{
                                  return(
                                    <tr key={index}>
                                      <td>{lineItem?.order?.id}</td>
                                      <td>{lineItem?.order?.total_price}</td>
                                      <td>{humanize(lineItem?.order?.status)}</td>
                                      <td>{lineItem?.order?.shipping_type}</td>
                                      <td>
                                        
                                        <div className="btn-group">
                                          <OverlayTrigger
                                            key="view"
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-view`}>View</Tooltip>
                                            }
                                          >
                                            <Link
                                              to={`${process.env.PUBLIC_URL}/customerOrder/details/${lineItem?.order?.id}`}
                                              className="btn btn-sm btn-default"
                                            >
                                              <FiEye />
                                            </Link>
                                          </OverlayTrigger>
                                            {
                                              challanDetails?.status === 'initiated' &&
                                                <OverlayTrigger
                                                key="view"
                                                placement="top"
                                                overlay={
                                                <Tooltip id={`tooltip-view`}>Send to DH</Tooltip>
                                                }
                                            >
                                                <ConfirmationModal title="Delete" body="Are you sure you want to remove?" handleAction={() => handleRemoveOrder(lineItem?.order?.id)}/>
                                              </OverlayTrigger>
                                            }
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                      </div>
                  </div>
              </Loader>
          </div>
        </App>
      );
    }
  
  export default withRouter(ForwardChallanDetails);
