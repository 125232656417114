import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import SupplierForm from "./Form";
import {getDetails, getList, update} from "../../services/baseServices";

class Add extends Component {
  state = {
    formData: {},
    supplierInfo: {},
    areas: [],
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false,
  };

  componentDidMount() {
    getDetails("suppliers", this.props.match.params.id)
      .then(res => {
        this.setState({supplierInfo: res});
          const formData = {...this.state.formData};
          formData['address_line'] = res.address_line;
          // formData['bn_address_line'] = res.address.bn_address_line;
          // formData['area_id'] = res.address.area.id;
          // formData['thana_id'] = res.address.thana.id;
          // formData['district_id'] = res.address.district.id;
          formData['product_quality_rating'] = res.product_quality_rating;
          formData['deliver_time_rating'] = res.deliver_time_rating;
          formData['service_quality_rating'] = res.service_quality_rating;
          formData['professionalism_rating'] = res.professionalism_rating;
          formData['pre_payment'] = res.pre_payment;
          formData['post_payment'] = res.post_payment;
          formData['credit_payment'] = res.credit_payment;
          formData['contract_start_date'] = res.contract_start_date ? new Date(res.contract_start_date) : null;
          formData['contract_end_date'] = res.contract_end_date ? new Date(res.contract_end_date) : null;
          this.setState({formData}, () => {
            console.log(this.state.formData);
          });
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      });
  }

  setDate = (date, attributeName) => {
    const formData = {...this.state.formData};
    formData[attributeName] = date;
    this.setState({formData}, () => {
      console.log(this.state.formData);
    });
  };

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
        console.log(this.state.formData);
    });
  }

  handleUpdateItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        const formData = {...this.state.formData}
        // formData.address_attributes = {
        //   address_line: formData.address_line,
        //   bn_address_line: formData.bn_address_line,
        //   district_id: parseInt(formData.district_id),
        //   thana_id: parseInt(formData.thana_id),
        //   area_id: parseInt(formData.area_id),
        // }
        // delete formData.address_line;
        // delete formData.bn_address_line;
        // delete formData.district_id;
        // delete formData.thana_id;
        // delete formData.area_id;
        update("suppliers", this.props.match.params.id, "supplier", formData)
          .then(res => {
            this.props.history.push(`/supplier/list`);
            toast.success("updated");
          })
          .catch(errMsg => {
           toast.error(errMsg);
           this.setState({isSubmitting: false})
          });
      })
    }
  }
  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Update Supplier</h2>
        </div>
        <div className="card-block">
          <div className="block-body">
            <>
              <SupplierForm
                handleClick={this.handleUpdateItem}
                handleInputOnChange={this.handleInputOnChange}
                supplierInfo={this.state.supplierInfo}
                isSubmitting={this.state.isSubmitting}
                setDate={this.setDate}
                formData={this.state.formData}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);
