import React, {Component} from 'react';
import App from "../../App";
import {Button, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FiCheck, FiEdit, FiPlus, FiUpload, IoMdClose} from "react-icons/all";
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {getList} from "../../services/baseServices";
import ConfirmationModal from "../common/Modal";

class temporaryImages extends Component {
  constructor() {
    super();
    this.state = {
      tempImages: [],
      formData: {},
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
    };
  }

  componentDidMount() {
    getList("products/temporary_image_list")
      .then(res => {
        this.setState({tempImages: res})
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })
  }
  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Temporary Images</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/uploadImage`} className="btn btn-sm btn-secondary"><FiPlus/> Add Image</Link>
            </div>
          </div>
          {
            this.state.isLoading ?
              <div className={`card-block`}>
                <div className="block-body">
                  Loading...
                </div>
              </div>
              :
              <div className="card-block">
                <div className="block-body">
                  <table className="order-table table table-striped">
                    <thead>
                    <tr>
                      <th style={{width: '20px'}}>#</th>
                      <th>File Name</th>
                      <th>Image</th>
                      {/*<th>Action</th>*/}
                    </tr>
                    </thead>
                    <tbody>

                    {this.state.tempImages.length > 0 ? this.state.tempImages.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="order-item">
                              <div className="user-avatar">
                                <img src={item.image} alt="" style={{width: "140px", height: "140px"}}/>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="order-item">
                              {item.file_name}
                            </div>
                          </td>
                          {/*<td style={{width: '150px'}}>
                            <div className="btn-group">
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() =>this.handleDeleteWarehouse(item.id)}/>
                              </OverlayTrigger>
                            </div>
                          </td>*/}
                        </tr>
                      );
                    }) : ""}
                    </tbody>
                  </table>
                </div>
              </div>
          }
        </div>
      </App>
    );
  }
}

export default withRouter(temporaryImages);
