import React, { Component } from 'react';
import { FaLongArrowAltLeft } from 'react-icons/all';
import { Link } from 'react-router-dom';
import App from "../../../App";
import { Rating } from '@material-ui/lab/Rating';
import { getDetails } from '../../../services/baseServices'
import { withRouter } from 'react-router-dom';

class ThanaDetails extends Component {
  constructor() {
    super();
    this.state = {
      thana: {},
      district: {},
      isLoading: true
    }
  };

  componentWillMount() {
    this.getThanaDetails();
  }
  getThanaDetails = () => {
    getDetails("thanas", this.props.match.params.id)
      .then((res) => {
        if (res.success) {
          this.setState({ thana: res.data }, () => {
            this.setState({ isLoading: false })
          });
          this.getDistrictDetails()
        } else {
          this.setState({ isLoading: false })
        }
      })
      .catch((errMsg) => {
        this.setState({ isLoading: false })
        console.log("Error Message: ", errMsg);
      });
  }

  getDistrictDetails() {
    getDetails("districts",  this.state.thana && this.state.thana.district_id)
        .then((res) => {
          console.log("response", res);
          if (res.success) {
            this.setState({ district: res.data }, () => {
              this.setState({ isLoading: false })
            });
          } else {
            this.setState({ isLoading: false })
          }
        })
        .catch((errMsg) => {
          this.setState({ isLoading: false })
          console.log("Error Message: ", errMsg);
        });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Thana Details</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/config/thanas/list`}
                className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to List</Link>
            </div>
          </div>

          {
            this.state.isLoading ?
              <div className={`card-block`}>
                <div className="block-body">
                  Loading...
                </div>
              </div>
              :
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Thana Details</h4>
                </div>
                {this.state.isLoading ? (
                  <div className={`card-block`}>
                    <div className="block-body">Loading...</div>
                  </div>
                ) : (
                  <div className="block-body">
                    <>
                      <table className="info-table col-12">
                        <tbody>
                          <tr>
                            <td style={{ width: "250px" }}>
                              Name
                            </td>
                            <td style={{ width: "20px", textAlign: "center" }}>
                              :
                            </td>
                            <td>{this.state.thana.name}</td>
                          </tr>
                          <tr>
                            <td style={{ width: "250px" }}>BN Name</td>
                            <td style={{ width: "20px", textAlign: "center" }}>
                              :
                            </td>
                            <td>{this.state.thana.bn_name}</td>
                          </tr>
                          <tr>
                            <td style={{ width: "250px" }}>District Name</td>
                            <td style={{ width: "20px", textAlign: "center" }}>
                              :
                            </td>
                            <td>{this.state.district.bn_name}</td>
                          </tr>
                          <tr>
                            <td style={{ width: "250px" }}>Home Delivery</td>
                            <td style={{ width: "20px", textAlign: "center" }}>
                              :
                            </td>
                            <td>{this.state.thana.home_delivery ? "Yes" : "No"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  </div>
                )}
              </div>

          }

        </div>
      </App>
    );
  }
}

export default withRouter(ThanaDetails);