import React, { Component } from 'react';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'react-js-pagination';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import App from '../../../App';
import { destroy, getPaginatedList } from '../../../services/baseServices';
import { FiEye, FiEdit, FiPlus } from 'react-icons/all';
import ConfirmationModal from "../../common/Modal";
import {toast} from 'react-toastify';

class ThanaList extends Component {
    constructor() {
        super();
        this.state = {
            thanas: [],
            activePage: 1,
            per_page :25,
            page:1,
            isLoading: false,
            temp_params: {},
            query_params: {},
        };
    }

    componentDidMount() {
        this.getPaginatedArticle(this.getQueryParams());
    }

    getQueryParams = () => {
        let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
        return params;
    }

    handlePageChange = (pageNumber) => {
      this.setState({page: pageNumber, activePage: pageNumber}, () => {
          this.getPaginatedArticle(this.getQueryParams());
      });
  }

    getPaginatedArticle = (params) => {
        this.setState({...this.state, isLoading: true});
        getPaginatedList("thanas",params)
          .then(res => {
              if(res.data?.success === true){
                this.setState({
                    thanas: res.data.data, 
                    isLoading: false,
                    per_page: parseInt(res.headers['x-per-page']),
                    total_data: parseInt(res.headers['x-total']),
                  }, () => {
                  });
              }else{
                this.setState({...this.state, isLoading: false});
              }
          })
          .catch(errMsg => {
            this.setState({...this.state, isLoading: false});
            console.log('Error Message: ', errMsg)
          })
    }
    
    handleOnFieldChange = (e) => {
      const queryData = {...this.state.temp_params};
      queryData[e.target.name] = e.target.value;
      this.setState({temp_params: queryData}, () => {
      });
    }

    handleOnSearch = (e) => {
      e.preventDefault();
      const tempParam = this.state.temp_params;
        this.setState({page: 1, query_params: tempParam, isLoading:true}, () => {
          this.getPaginatedArticle(this.state.temp_params);
        });
    }

    handleItemProduct = id => {
      destroy("thanas", id)
        .then(res => {
          if(res.success){
            toast.success('Deleted successfully');
            this.getPaginatedArticle(this.getQueryParams());
          } else {
            toast.error(res?.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg)
        })
    }
    
    render() {
        return (
            <App layout="">
          <div className="main-content">
            <div className="page-header">
              <h2 className="page-title">Thana List</h2>
              <div className="ml-auto">
                <Link to={`${process.env.PUBLIC_URL}/config/thanas/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Thana</Link>
              </div>
            </div>

              <div className="card-block">
                <div className="block-body">

                  {/* <form className="form-inline mb-3">
                  <div className="form-group mr-3">
                    <label htmlFor="title" className="sr-only">Search by Phone</label>
                    <input type="search" name="phone" id="phone" onChange={this.handleOnFieldChange}
                          className='form-control form-control-sm' placeholder="Search by phone"/>
                  </div>

                  <div className="form-group mr-3">
                    <label htmlFor="brand" className="sr-only">Search by Email</label>
                    <input type="text" name="email" id="email" onChange={this.handleOnFieldChange}
                          className='form-control form-control-sm' placeholder="Search by Email"/>
                  </div>
                    <Button type={`submit`} onClick={this.handleOnSearch} size="sm" variant="primary">Search</Button>
                  </form> */}
                  <Loader loaded={!this.state.isLoading}>
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th>SL No.</th>
                        <th>Name</th>
                        <th>BN Name</th>
                        <th>Home Delivery</th>
                        <th>ACTIONS</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.thanas.length > 0 ? this.state.thanas.map((item, index) => {
                        return (
                            <tr key={item.id}>
                              <td>{index+1}</td>
                              <td>
                                <div className="order-item">
                                    {item.name}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.bn_name}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.home_delivery? "True": "False"}
                                </div>
                              </td>
                              <td style={{width: '150px'}}>
                                <div className="btn-group">
                                <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          View Details
                                        </Tooltip>
                                      }
                                  >
                                  <Link to={`/config/thanas/details/${item.id}`} className="btn btn-sm btn-default"><FiEye/></Link>
                                  </OverlayTrigger>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`${process.env.PUBLIC_URL}/config/thanas/edit/${item.id}`}
                                        className="btn btn-sm btn-default"><FiEdit/></Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() => this.handleItemProduct(item.id)}/>
                                </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                        );
                      }) : ""}
                      </tbody>
                    </table>
                  </Loader>
                  </div>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
          
            </div>
          <ToastsContainer store={ToastsStore}/>
        </App>
        );
    }
}

export default ThanaList;