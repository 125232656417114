import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  postData,
  getList,
  getLocations,
  getThanas,
  getAreas,
} from "../../services/baseServices";
import { Select, DatePicker, Radio, Input, InputNumber } from "antd";
import DebounceSelect from "./DebounceSelect";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";

const { Option } = Select;

const CouponForm = ({ formMode, validationSchema, couponDetails }) => {
  const [warehouseList, setWarehouseList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [thanaList, setThanaList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: { productRule: "", userRule: "" },
    resolver: yupResolver(validationSchema),
  });
  const selectedProductRule = watch("productRule");
  const selectedUserRule = watch("userRule");
  const selectedFcs = watch("fcs");
  const selectedDistrict = watch("district");
  const selectedThana = watch("thana");
  const selectedDiscountType = watch("discountType");


  useEffect(() => {
    setWarehouseList([]);
    setDistrictList([]);
    setThanaList([]);
    setAreaList([]);
    setValue("fcs");
    setValue("district");
    setValue("thana");
    setValue("area");
    setValue("customers");
    setValue("partners");
    setValue("noOfCoupon", 1);
  }, [selectedUserRule]);

  useEffect(() => {
    setValue("brandNames");
    setValue("skus");
    setValue("categories");
  }, [selectedProductRule]);

  useEffect(() => {
    setValue("discountAmount");
    setValue("maximumDiscountAmount");
  }, [selectedDiscountType]);

  useEffect(() => {
    if (selectedFcs) {
      getDistrictList(selectedFcs);
      setValue("district");
      setValue("thana");
      setValue("area");
    }
  }, [selectedFcs]);

  useEffect(() => {
    if (selectedDistrict) {
      getThanaList(selectedDistrict);
      setValue("thana");
      setValue("area");
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedThana) {
      getAreaList(selectedThana);
      setValue("area");
    }
  }, [selectedThana]);

  async function getSKUList(sku) {
    return getList("promo_coupons/search_by_sku", { sku }).then((res) => {
      if (res?.success) {
        return res.data.map((item) => ({
          label: item.sku,
          value: item.id,
        }));
      } else {
        toast.error(res?.message);
      }
    });
  }

  async function getDataByTypeAndKeyword(key) {
    const params = {
      keyword: key,
    };
    if (selectedProductRule === "Brand" || selectedProductRule === "Category") {
      params.type = selectedProductRule;
    }
    if (selectedUserRule === "Warehouse") {
      params.type = selectedUserRule;
    }

    return getList("promo_coupons/search_by_title", params).then((res) => {
      if (res.success) {
        if (selectedUserRule === "Warehouse") {
          setWarehouseList(res?.data);
        } else {
          return res.data.map((item) => ({
            label: selectedProductRule === "Category" ? item.title : item.name,
            value: item.id,
          }));
        }
      } else {
        toast.error(res?.message);
      }
    });
  }

  const handleWarehouseSearch = (value) => {
    if (value) {
      getDataByTypeAndKeyword(value);
    } else {
      setWarehouseList([]);
    }
  };

  async function getDataByTypeAndPhone(phone) {
    const params = { phone };
    if (selectedUserRule === "User" || selectedUserRule === "Partner") {
      params.type = selectedUserRule;
    }

    return getList("promo_coupons/search_by_phone", params).then((res) => {
      if (res?.success) {
        return res?.data?.map((item) => ({
          label: item.phone,
          value: item.id,
        }));
      } else {
        toast.error(res?.message);
      }
    });
  }

  const getDistrictList = async (id) => {
    try {
      const res = await getLocations("districts",{warehouse_id:id});
      setDistrictList(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getThanaList = async (districtId) => {
    try {
      const res = await getThanas({ district_id: districtId });
      setThanaList(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getAreaList = async (thanaId) => {
    try {
      const res = await getAreas({ thana_id: thanaId });
      setAreaList(res);
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (data) => {
    const coupon_rules = [];
    let confirmMessage = "Are you sure to generate";
    if (data.brandNames && data.brandNames.length > 0) {
      data.brandNames.map((item) => {
        coupon_rules.push({
          ruleable_type: "Brand",
          ruleable_id: item.value,
        });
      });
    }

    if (data.skus && data.skus.length > 0) {
      data.skus.map((item) => {
        coupon_rules.push({
          ruleable_type: "Variant",
          ruleable_id: item.value,
        });
      });
    }

    if (data.categories && data.categories.length > 0) {
      data.categories.map((item) => {
        coupon_rules.push({
          ruleable_type: "Category",
          ruleable_id: item.value,
        });
      });
    }

    if (data.customers && data.customers.length > 0) {
      data.customers.map((item) => {
        coupon_rules.push({
          ruleable_type: "User",
          ruleable_id: item.value,
        });
      });
      confirmMessage += ` ${data.noOfCoupon} coupoun(s) for ${data.customers?.length} customer(s)?`;
    }
    if (data.partners && data.partners.length > 0) {
      data.partners.map((item) => {
        coupon_rules.push({
          ruleable_type: "Partner",
          ruleable_id: item.value,
        });
      });
      confirmMessage += ` ${data.noOfCoupon} coupoun(s) for ${data.partners?.length} partner(s)?`;
    }
    if (data.area && data.area.length > 0) {
      data.area.map((item) => {
        coupon_rules.push({
          ruleable_type: "Area",
          ruleable_id: item,
        });
      });
      confirmMessage += ` ${data.noOfCoupon} coupoun(s) for ${data.area?.length} area(s)?`;
    } else if (data.thana) {
      coupon_rules.push({
        ruleable_type: "Thana",
        ruleable_id: data.thana,
      });
      confirmMessage += ` ${data.noOfCoupon} coupoun(s) for ${data.thana?.length} thana(s)?`;
    } else if (data.district) {
      coupon_rules.push({
        ruleable_type: "District",
        ruleable_id: data.district,
      });
      confirmMessage += ` ${data.noOfCoupon} coupoun(s) for ${data.district?.length} district(s)?`;
    } else if (data.fcs) {
      coupon_rules.push({
        ruleable_type: "Warehouse",
        ruleable_id: data.fcs,
      });
      confirmMessage += ` ${data.noOfCoupon} coupoun(s) for ${data.fcs?.length} warehouse(s)?`;
    }

    const promo_coupon = {
      title: data.title,
      status: +data.status,
      order_type: +data.orderType,
      discount_type: +data.discountType,
      start_date: moment(data.startDate).format("yyyy-MM-DD"),
      end_date: moment(data.endDate).format("yyyy-MM-DD"),
      number_of_coupon: data.noOfCoupon,
      minimum_cart_value: data.minimumCartValue,
      discount: data.discountAmount,
      max_discount_amount: data.maximumDiscountAmount,
      promo_coupon_rules_attributes: [...coupon_rules],
    };

    confirmAlert({
      title: "Confirm to submit",
      message: confirmMessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            postData("promo_coupons", { promo_coupon })
              .then((res) => {
                if (res?.success) {
                  toast.success(res?.message);
                  history.push("/coupon/list");
                } else {
                  toast.error(res?.message);
                }
              })
              .catch(() => {
                toast.error("Error");
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const getSelectedProductRuleComp = (selectedRule) => {
    switch (selectedRule) {
      case "Brand":
        return (
          <Form.Group as={Row} controlId="brandNames">
            <Form.Label column sm="3">
              Brand Names <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Controller
                control={control}
                name="brandNames"
                render={({ field }) => (
                  <DebounceSelect
                    {...field}
                    mode="multiple"
                    placeholder="Please search by brand name and select"
                    fetchOptions={getDataByTypeAndKeyword}
                    style={{ width: "100%" }}
                  />
                )}
              />
              <p className="text-danger ">
                <small>{errors.brandNames?.message}</small>
              </p>
            </Col>
          </Form.Group>
        );
      case "Variant":
        return (
          <Form.Group as={Row} controlId="skus">
            <Form.Label column sm="3">
              SKUs<span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Controller
                control={control}
                name="skus"
                render={({ field }) => (
                  <DebounceSelect
                    {...field}
                    mode="multiple"
                    placeholder="Please search by sku and select"
                    fetchOptions={getSKUList}
                    style={{ width: "100%" }}
                  />
                )}
              />
              <p className="text-danger ">
                <small>{errors.skus?.message}</small>
              </p>
            </Col>
          </Form.Group>
        );
      case "Category":
        return (
          <Form.Group as={Row} controlId="categories">
            <Form.Label column sm="3">
              Categories<span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Controller
                control={control}
                name="categories"
                render={({ field }) => (
                  <DebounceSelect
                    {...field}
                    mode="multiple"
                    placeholder="Please search by category title and select"
                    fetchOptions={getDataByTypeAndKeyword}
                    style={{ width: "100%" }}
                  />
                )}
              />
              <p className="text-danger ">
                <small>{errors.categories?.message}</small>
              </p>
            </Col>
          </Form.Group>
        );
    }
  };

  const getSelectedUserRuleComp = (selectedRule) => {
    switch (selectedRule) {
      case "User":
        return (
          <Form.Group as={Row} controlId="customers">
            <Form.Label column sm="3">
              Customers <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Controller
                control={control}
                name="customers"
                render={({ field }) => (
                  <DebounceSelect
                    {...field}
                    mode="multiple"
                    placeholder="Please search by customer name and select"
                    fetchOptions={getDataByTypeAndPhone}
                    style={{ width: "100%" }}
                  />
                )}
              />
              <p className="text-danger ">
                <small>{errors.customers?.message}</small>
              </p>
            </Col>
          </Form.Group>
        );
      case "Partner":
        return (
          <Form.Group as={Row} controlId="partners">
            <Form.Label column sm="3">
              Outlets <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Controller
                control={control}
                name="partners"
                render={({ field }) => (
                  <DebounceSelect
                    {...field}
                    mode="multiple"
                    placeholder="Please search by outlet name and select"
                    fetchOptions={getDataByTypeAndPhone}
                    style={{ width: "100%" }}
                  />
                )}
              />
              <p className="text-danger ">
                <small>{errors.partners?.message}</small>
              </p>
            </Col>
          </Form.Group>
        );
      case "Warehouse":
        return (
          <Form.Group as={Row} controlId="fcs">
            <Form.Label column sm="3">
              Warehouse <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Controller
                control={control}
                name="fcs"
                render={({ field }) => (
                  <Select
                    {...field}
                    showSearch
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onSearch={handleWarehouseSearch}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                  >
                    {warehouseList.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              />
              <p className="text-danger ">
                <small>{errors.fcs?.message}</small>
              </p>
            </Col>
          </Form.Group>
        );
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Form.Group as={Row} controlId={"CouponTitle"}>
        <Form.Label column sm="3">
          Title <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                placeholder="Ex: Buy 1 get 1 free"
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          <p className="text-danger ">
            <small> {errors.title?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="startDate">
        <Form.Label column sm="3">
          Start Date<span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="startDate"
            render={({ field }) => (
              <DatePicker
                inputReadOnly
                {...field}
                style={{ width: "100%" }}
                placeholder="Select start date"
                onChange={(e) => {
                  field.onChange(e);
                }}
                format="DD/MM/yyyy"
                disabledDate={(current) => {
                  return moment().add(-1, "days") >= current;
                }}
              />
            )}
          />
          <p className="text-danger ">
            <small>{errors.startDate?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="endDate">
        <Form.Label column sm="3">
          End Date<span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <DatePicker
                inputReadOnly
                {...field}
                style={{ width: "100%" }}
                placeholder="Select end date"
                format="DD/MM/yyyy"
                onChange={(e) => {
                  field.onChange(e);
                }}
                disabledDate={(current) => {
                  return moment().add(-1, "days") >= current;
                }}
              />
            )}
          />
          <p className="text-danger ">
            <small>{errors.endDate?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="status">
        <Form.Label column sm="3">
          Status <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <Radio.Group onChange={onChange}>
                <Radio value={1}>Active</Radio>
                <Radio value={0}>Inactive</Radio>
              </Radio.Group>
            )}
          />
          <p className="text-danger ">
            <small>{errors.status?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={"minimumCartValue"}>
        <Form.Label column sm="3">
          Minimum Cart Value <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="minimumCartValue"
            render={({ field }) => (
              <InputNumber
                min={0}
                max={99999999}
                step={1}
                {...field}
                style={{ width: "100%" }}
                placeholder="Ex:87.53"
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          <p className="text-danger ">
            <small>{errors.minimumCartValue?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="orderType">
        <Form.Label column sm="3">
          Order Type <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="orderType"
            render={({ field }) => (
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select order type"
                {...field}
              >
                <Option value={0}>Both</Option>
                <Option value={1}>Induced</Option>
                <Option value={2}>Organic</Option>
              </Select>
            )}
          />
          <p className="text-danger ">
            <small>{errors.orderType?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="productRule">
        <Form.Label column sm="3">
          Applicable On
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="productRule"
            render={({ field }) => (
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select product rule"
                {...field}
              >
                <Option value="">All</Option>
                <Option value="Variant">SKU</Option>
                <Option value="Brand">Brand</Option>
                <Option value="Category">Category</Option>
              </Select>
            )}
          />
          <p className="text-danger ">
            <small>{errors.productRule?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <div>{getSelectedProductRuleComp(selectedProductRule)}</div>
      <Form.Group as={Row} controlId="userRule">
        <Form.Label column sm="3">
          Applicable For
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="userRule"
            render={({ field }) => (
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                {...field}
              >
                <Option value="">No Rule</Option>
                <Option value="User">Customer</Option>
                <Option value="Partner">Outlet</Option>
                <Option value="Warehouse">FCs</Option>
              </Select>
            )}
          />
        </Col>
      </Form.Group>
      <div>{getSelectedUserRuleComp(selectedUserRule)}</div>
      {selectedFcs && selectedUserRule === "Warehouse" && (
        <>
          <Form.Group as={Row} controlId="district">
            <Form.Label column sm="3">
              District
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Controller
                control={control}
                name="district"
                render={({ field }) => (
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    {...field}
                  >
                    {districtList?.length > 0 &&
                      districtList?.map((district, index) => (
                        <Option value={district?.id} key={index}>
                          {district?.name}
                        </Option>
                      ))}
                  </Select>
                )}
              />
            </Col>
          </Form.Group>
          {selectedDistrict && (
            <>
              <Form.Group as={Row} controlId="thana">
                <Form.Label column sm="3">
                  Thana
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Controller
                    control={control}
                    name="thana"
                    render={({ field }) => (
                      <Select
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        {...field}
                      >
                        {thanaList?.map((thana, index) => (
                          <Option value={thana?.id} key={index}>
                            {thana?.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </Col>
              </Form.Group>
              {selectedThana && (
                <Form.Group as={Row} controlId="area">
                  <Form.Label column sm="3">
                    Area
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Controller
                      control={control}
                      name="area"
                      render={({ field }) => (
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Please select"
                          {...field}
                        >
                          {areaList?.map((area, index) => (
                            <Option value={area?.id} key={index}>
                              {area?.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    />
                  </Col>
                </Form.Group>
              )}
            </>
          )}
        </>
      )}

      <Form.Group as={Row} controlId={"noOfCoupon"}>
        <Form.Label column sm="3">
          Total Coupon {selectedUserRule ? "(Per Item)" : ""}{" "}
          <span className="text-danger">*</span>
        </Form.Label>
        <Col sm="9" md={8} lg={7}>
          <Controller
            control={control}
            name="noOfCoupon"
            render={({ field }) => (
              <InputNumber
                disabled={selectedUserRule === "User"}
                min={1}
                max={500}
                step={1}
                {...field}
                style={{ width: "100%" }}
                placeholder="Ex:12"
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          <p className="text-danger ">
            <small>{errors.noOfCoupon?.message}</small>
          </p>
        </Col>
      </Form.Group>
      <>
        <Form.Group as={Row} controlId="discountType">
          <Form.Label column sm="3">
            Discount Type <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Controller
              control={control}
              name="discountType"
              render={({ field }) => (
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  {...field}
                >
                  <Option value={0}>Fixed</Option>
                  <Option value={1}>Percentage</Option>
                </Select>
              )}
            />
            <p className="text-danger ">
              <small>{errors.discountType?.message}</small>
            </p>
          </Col>
        </Form.Group>
        {(selectedDiscountType || selectedDiscountType === 0) && (
          <>
            <Form.Group as={Row} controlId={"discountAmount"}>
              <Form.Label column sm="3">
                Discount Amount{selectedDiscountType === 1 && "(%)"}{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
                <Controller
                  control={control}
                  name="discountAmount"
                  render={({ field }) => (
                    <InputNumber
                      min={1}
                      max={selectedDiscountType === 1 ? 99 : 99999999}
                      step={1}
                      {...field}
                      style={{ width: "100%" }}
                      placeholder="Ex:87.53"
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                <p className="text-danger ">
                  <small>{errors.discountAmount?.message}</small>
                </p>
              </Col>
            </Form.Group>

            {selectedDiscountType === 1 && (
              <Form.Group as={Row} controlId={"maximumDiscountAmount"}>
                <Form.Label column sm="3">
                  Maximum Discount Amount
                  <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Controller
                    control={control}
                    name="maximumDiscountAmount"
                    render={({ field }) => (
                      <InputNumber
                        required
                        min={1}
                        max={99999999}
                        step={1}
                        {...field}
                        style={{ width: "100%" }}
                        placeholder="Ex:87.53"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  <p className="text-danger ">
                    <small>{errors.maximumDiscountAmount?.message}</small>
                  </p>
                </Col>
              </Form.Group>
            )}
          </>
        )}
      </>
      <div className="mt-3 text-center">
        <Link
          to={`${process.env.PUBLIC_URL}/coupon/list`}
          className="mr-2 btn btn-md btn-danger"
        >
          Cancel
        </Link>
        <Button
          size="md"
          type="submit"
          disabled={isSubmitting}
          variant="primary"
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </div>
    </Form>
  );
};

export default CouponForm;
