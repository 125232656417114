import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {FiEye} from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getPaginatedList, getList } from "../../services/baseServices";
import ExportCSVAsync from "../common/ExportCSVAsync";
import { getFormattedDate } from "../../helper/utils";

const headers = [
	{ label: "Route Name", key: "name" },
	{ label: "Phone", key: "phone" },
	{ label: "Total Order", key: "total_order" },
	{ label: "Prepaid", key: "prepaid_order_count" },
	{ label: "Collected by FC", key: "collected_by_fc" },
	{ label: "Collected by SR", key: "collected_by_sr"}
];

const loadData = (item) => {
	return {
		name: item.title,
		phone: item.phone,
		total_order: item.total_order,
		prepaid_order_count: item.prepaid_order_count,
		collected: item.collected,
		collected_by_fc: item.collected_by_fc,
		collected_by_sr: item.collected_by_sr
	};
};

class Partners extends Component {
	constructor() {
		super();
		this.state = {
			routes: [],
			activePage: 1,
			per_page: 15,
			page: 1,
			query_params: {},
			isLoading: true,
			distributors: []
		};
	}

	getRRlistByPartner = (params) => {
		getPaginatedList("return_orders", params)
			.then((res) => {
				this.setState(
					{
						routes: res.data,
						isLoading: false,
						per_page: res.headers["x-per-page"],
						total_data: parseInt(res.headers["x-total"]),
					},
					() => {}
				);
			})
			.catch((errMsg) => {
				console.log("Error Message: ", errMsg);
			});
	};

	getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

	componentDidMount() {
		getList("distributors")
        .then((res) => {
            this.setState({distributors: res?.data});
        })
        .catch((errMsg) => {});
		this.setState({ query_params: this.props.queryParams }, () => {
			this.getRRlistByPartner(this.getQueryParams());
		})
	}

	handleOnSearch = (e) => {
		e.preventDefault();
		const tempParam = this.state.query_params;
		localStorage.setItem("reconciliation_st_d", tempParam.start_date_time)
        localStorage.setItem("reconciliation_ed_d", tempParam.end_date_time)
		this.setState({...this.state, page: 1}, () => {
			this.getRRlistByPartner(this.getQueryParams());
		});
	}

	handleOnFieldChange = (e) => {
		const queryData = {...this.state.query_params};
		queryData[e.target.name] = e.target.value;
			this.setState({query_params: queryData}, () => {
		});
	}

	handlePageChange = (pageNumber) => {
		this.setState({ page: pageNumber, activePage: pageNumber }, () => {
			this.getRRlistByPartner(this.getQueryParams());
		});
	};

	handleOnClear = ()=>{
		this.setState({
			query_params: {
				...this.state.query_params, 
				order_id: '', 
				distributor_id: ''
			}
		}, () =>{
			this.getRRlistByPartner(this.getQueryParams());
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString()
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
			this.setState({ query_params: this.props.queryParams }, () => {
            	this.getRRlistByPartner(this.getQueryParams());
			})
        }
	}

	render() {
		return (
		<div className="main-content">
			<div className="page-header">
				<h2 className="page-title">Partners List</h2>
			</div>
			<div align="center">
				<form className="form-inline mb-3">
					<div className="form-group mr-3">
						<label htmlFor="searchInput" className="sr-only">
							Order Id
						</label>
						<input
							type="search"
							name="order_id"
							id="order_id"
							className="form-control form-control-sm"
							placeholder="Search by Order Id"
							value={this.state.query_params?.order_id}
							onChange={this.handleOnFieldChange}
						/>
					</div>
					<div className="form-group mr-3">
						<label htmlFor="distributor_id" className="sr-only">
							Distributor
						</label>
						<select
							id="distributor_id"
							name="distributor_id"
							className="form-control form-control-sm"
							onChange={this.handleOnFieldChange}
							value={this.state.query_params?.distributor_id}
						>
							<option value="">--Select Distributor--</option>
							{this.state.distributors.map((item, i) => {
								return (
									<option
										key={i}
										value={item.id}
									>
										{item.name}
									</option>
								);
							})}
						</select>
					</div>
					<Button
						size="sm"
						type={`submit`}
						onClick={this.handleOnSearch}
						variant="primary"
					>
						Filter
					</Button>

					<Button
						size="sm"
						type={`button`}
						className="ml-2"
						onClick={this.handleOnClear}
						variant="danger"
					>
						Clear
					</Button>
				</form>
			</div>
			<table className="order-table table table-striped">
				<thead>
				<tr>
					<th>#</th>
					<th style={{width: '70px'}}>Req. No</th>
					<th>Order ID</th>
					<th>Requested On</th>
					<th>Customer</th>
					<th>Type</th>
					<th>Order type</th>
					<th>Price</th>
					<th>Status</th>
					<th>Action</th>
				</tr>
				</thead>
				<tbody>
				{
					this.state.routes?.length > 0 ?
						this.state.routes?.map((item, index) => {
							return (

								<tr key={index}>
									<td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
									<td><Link to={`${process.env.PUBLIC_URL}/returnOrder/partners/details/${item.id}`}>{item.id}</Link></td>
									<td><Link to={`/customerOrder/details/${item.order_id}`}>{item.order_id}</Link></td>
									<td>{getFormattedDate(item.created_at, 'LT, Do, MMMM, YYYY')}</td>
									<td>{item.customer_name}</td>
									<td>
										<div className="order-item">
											{item.return_type}
										</div>
									</td>
									<td>{item.customer_order_type}</td>
									<td>
										<div className="order-item">
											{item.price || '-'}
										</div>
									</td>
									<td>
										<div className="order-item">
											{item.return_status} {item.return_status === 'initiated' && item.initiated_by ? `(${item.initiated_by})`: ""}
										</div>
									</td>
									<td>
										<div className="btn-group">
											<Link to={`${process.env.PUBLIC_URL}/returnOrder/partners/details/${item.id}`}className="btn btn-sm btn-default"><FiEye/></Link>
										</div>
									</td>
								</tr>
							);
						})
						:
						<tr>
							<td colSpan={9} className='text-center'><h5>No Order Found</h5></td>
						</tr>
				}
				</tbody>
			</table>
			<div className="mt-3">
				<Pagination
					itemClass="page-item"
					linkClass="page-link"
					activePage={this.state.activePage}
					itemsCountPerPage={parseInt(this.state.per_page)}
					totalItemsCount={parseInt(this.state.total_data)}
					pageRangeDisplayed={10}
					onChange={this.handlePageChange.bind(this)}
				/>
			</div>
		</div>
		);
	}

}

export default withRouter(Partners);
