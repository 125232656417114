import axios from "axios";
import {API_BASE_URL} from "../helper/env";
import {getAuthToken} from "../helper/utils";

export const apiGetConfigByKey = (key) => {
    return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/configurations?key=${key}`, {
            headers: {
                Authorization: getAuthToken()
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log('Configuration fetch error: ', err);
                reject('An server error occurred!')
            })
    })
}
export const apiCreateOrUpdateByKey = (request_body) => {
    return new Promise((resolve, reject) => {
        axios.put(`${API_BASE_URL}/configurations/${request_body.key}`, request_body,{
            headers: {
                Authorization: getAuthToken()
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                console.log('Configuration fetch error: ', err);
                reject('An server error occurred!')
            })
    })
}
