import React, { useEffect, useState } from "react";
import { getList, postData } from "../../services";
import { toast } from "react-toastify";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectDeliveryPerson from "../../components/SelectDeliveryPerson";
import { message } from 'antd';

const List = () => {
  const [products, setProducts] = useState([]);
  const [deliveryPersons, setDeliveryPersons] = useState([]);
  const [selectedDeliveryPerson, setSelectedDeliveryPerson] = useState({});

  useEffect(() => {
    getList("/buffer_stocks/delivery_persons").then((res) => {
      if (res?.data?.delivery_person_list) {
        setDeliveryPersons(res?.data?.delivery_person_list);
      }
    });
  }, []);

  const handleSelectChange = (value) => {
    setSelectedDeliveryPerson(value);

    getList(
      "/buffer_stocks/fetch_stocks_returns?delivery_person_id=" + value?.id
    )
      .then((res) => {
        setProducts(res?.data);
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  const handleReconcile = () => {
    if (selectedDeliveryPerson && selectedDeliveryPerson?.id) {
      postData(
        "buffer_stocks/return?delivery_person_id=" + selectedDeliveryPerson?.id
      )
        .then((res) => {
          if (res?.status === 200) {
            toast.success(`Successfully reconciled`);
          }else{
            toast.success(`${res?.message}`);

          }
        })
        .catch((errMsg) => {
          toast.error(errMsg);
        });
    } else {
      toast.error("Select delivery person");
    }
  };

  return (
    <App layout={""}>
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Reconcile Buffer Stock List</h2>
        </div>
        <div>
          <Form.Group as={Row}>
            <Col sm="12" md={6} lg={6}>
              <SelectDeliveryPerson
                deliveryPersons={deliveryPersons}
                handleChange={handleSelectChange}
                selectedDeliveryPerson={selectedDeliveryPerson}
              />
            </Col>
          </Form.Group>
        </div>
        <div className="card-block">
          <div className="block-body">
            <table className="order-table table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "20px" }}>#</th>
                  <th>Product Title</th>
                  <th>SKU</th>
                  <th>Assigned Quantity</th>
                  <th>Returnable Quantity</th>
                </tr>
              </thead>
              <tbody>
                {products && products.length
                  ? products?.map((item, index) => (
                      <tr key={item?.variant_id}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="order-item">{item?.name}</div>
                        </td>
                        <td>
                          <div className="order-item">{item?.sku}</div>
                        </td>
                        <td>
                          <div className="order-item">
                            {item?.initial_buffer}
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            {item?.current_buffer}
                          </div>
                        </td>
                        <td style={{ width: "150px" }}></td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <div>
            <Button
              disabled={!products?.length}
              onClick={handleReconcile}
              className="float-right my-4"
            >
              Reconcile
            </Button>
          </div>
        </div>
      </div>
    </App>
  );
};

export default List;
