import React, { Component } from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import DatePicker from "react-datepicker";
import TimeInput from 'material-ui-time-picker'
import { Link } from "react-router-dom";
import { getList, getData } from "../../services/baseServices";
import WeekdayPicker from './WeekDayPicker';
import { WEEKDAYS } from './promoUtil'
import SelectComponent from './SelectComponent'
import moment from 'moment'
import "../../assets/scss/promo.scss";

class PromotionForm extends Component {
  state = {
    timeBound: false,
    startTime: new Date(),
    endTime: new Date(),
    // warehouses: [],
    promoCategories: [],
    promoRules: [],
    promoFields: [],
    promoSettings: '',
    weekDays: WEEKDAYS,
  }

  componentDidMount(){
    // getList('warehouses')
    // .then(res => {
    //   this.setState({warehouses: res})
    // })
    // .catch(errMsg => {
    // })

    getData('promotions/settings')
    .then(res => {
      this.setState({
        promoSettings: res, 
        promoCategories: res, 
        promoRules: res[this.props?.promotion?.promotion_category],
        // promotion_variants: this.groupBy(this.props?.promotion?.promotion_variants, "state"),
        promotion_variants: this.props?.promotion?.promotion_variants,
        promoBrands: this.props?.promotion?.promotion_brands
      })
      this.getPromoFields(this.props.promotion?.rule)
      
    })
    this.setState({
      fromDate: new Date(this.props?.promotion?.from_date), 
      toDate: new Date(this.props?.promotion?.to_date), 
      timeBound: this.props?.promotion?.is_time_bound,
    })

    if(this.props?.promotion?.is_time_bound) {
      this.assignTimeBoundFields()
    }

  }

  handleOnChange = (id, key, value) => {
    console.log(key,value)
    let xx =value.map(item => {return (item.value)} ) 
    console.log(xx,xx)
    this.props.onPromoFieldChange(id, key,xx)
  }

  assignTimeBoundFields = () => {
    let today = new Date();
    const startTimeParts = this.props?.promotion?.start_time.split(":");
    const endTimeParts = this.props?.promotion?.end_time.split(":");
    const localStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), startTimeParts[0], startTimeParts[1]);
    const localEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), endTimeParts[0], endTimeParts[1]);

    let selectedWeekDays = 
      this.state.weekDays.map(item => {
        var temp = Object.assign({}, item);
        if (this.props?.promotion?.days?.includes(item.day_value)) {
          temp.selected = true
        }
        return temp;
      })
    this.setState({
      startTime: localStartDate,
      endTime: localEndDate,
      weekDays: selectedWeekDays
    })
  }

  getPromoFields = (key) => {
    //setPromoFields(promoRules[key].fields)
    if(key) {
      let promoFields = this.state.promoRules[key]?.fields
      if(promoFields) {
        this.props.handleFieldsConfiguration(promoFields)
        this.setState({promoFields: promoFields})
      }
    }
    else {
      this.props.handleFieldsConfiguration([])
      this.setState({promoFields: []})
    }
  }

  onDaySelect = (day) => {
    const objIndex = this.state.weekDays.findIndex(item => item.day_value == day);
    const selected = this.state.weekDays[objIndex].selected
    const updatedObj = { ...this.state.weekDays[objIndex], selected: !selected};
    const updatedWeekDays = [
      ...this.state.weekDays.slice(0, objIndex),
      updatedObj,
      ...this.state.weekDays.slice(objIndex + 1),
    ];
    this.setState({weekDays:updatedWeekDays})
    this.props.handleDayChange(updatedWeekDays)
  }

  groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  
  render() {
    const errors = this.props.errors
    return (
      <Form
      noValidate
      //validated={this.props.isFormValidated}
      onSubmit={this.props?.handleClick}>
        <Form.Group as={Row} controlId={"promotion-title"}>
          <Form.Label column sm="3">
            Title <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
                defaultValue={this.props.promotion?.title}
                name="title"
                onChange={this.props.handleInputOnChange}
                required
                type="text"/>
            <Form.Control.Feedback type="invalid">Title is required</Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} controlId="warehouse">
          <Form.Label column sm="3">
            Warehouse <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              disabled
              value={this.props.promotion?.warehouse_id}
              name="warehouse_id"
              onChange={e => {
                this.props.handleInputOnChange(e);
              }}  
              as="select" type="text">
              <option value="">--Select--</option>
              {this.state.warehouses.length > 0 ?
                this.state.warehouses.map((item,i) => {
                  return (
                    <option key={i} value={item.id}>{item.name}</option>
                  )
                })
                : ""
            }
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select a warehouse.</Form.Control.Feedback>
          </Col>
        </Form.Group> */}

        <Form.Group as={Row} controlId="from-date">
          <Form.Label column sm="3">From Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
          <DatePicker
            selected={this.state.fromDate}
            dateFormat="dd/MM/yyyy"
            onChange={date => {
              //this.props?.formData["from_date", date]
              this.setState({fromDate:date})
              this.props.handleDateOnChange("from_date", date);
            }
            }
          />
            <Form.Control.Feedback className="d-block" type="invalid">{errors.from_date}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="to-date">
          <Form.Label column sm="3">To Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
          <DatePicker
            required
            selected={this.state.toDate}
            dateFormat="dd/MM/yyyy"
            onChange={date => {
              //this.props?.formData["to_date", date]
              this.setState({toDate:date})
              this.props.handleDateOnChange("to_date", date);
            }
            }
          />
            <Form.Control.Feedback type="invalid" className="d-block">{errors.to_date}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="active">
          <Form.Label column sm="3">Status</Form.Label>
          <Col sm="9" md={8} lg={7}>
            <RadioGroup row name="is_active" 
            value={this.props.formData?.is_active}
              onChange={this.props.handleInputOnChange}>
              <FormControlLabel className="promo-status" value={"true"} control={<Radio/>} label="Active"/>
              <FormControlLabel className="promo-status" value={"false"} control={<Radio/>} label="In Active"/>
            </RadioGroup>
          </Col>
        </Form.Group>

        {/* <Form.Group as={Row} controlId="istimebound">
          <Form.Label column sm="3">Time bound</Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Check 
              className="d-flex align-items-center"
              type="checkbox" 
              name="is_time_bound" 
              checked={this.props.formData?.is_time_bound}
              onChange= {(e) =>{
                this.props.handleInputOnChange(e); 
                this.setState({timeBound: !this.state.timeBound}) } 
              }
              label=" Enable" />
          </Col>
        </Form.Group> */}

        
        {this.state.timeBound &&
        <>
          <Form.Group as={Row} controlId="start_time">
            <Form.Label column sm="3">Start Time<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <TimeInput
                required
                value={this.state.startTime}
                mode='24h'
                onChange={time => {
                  this.setState({startTime: time}, () => {
                    this.props.handleTimeOnChange("start_time", moment(time).format("HH:mm"));
                  })
                  //this.props?.formData["start_time", time]
                }
                }
              />
              {/* <Form.Control.Feedback type="invalid">{errors.start_time}</Form.Control.Feedback> */}
            </Col>
          </Form.Group>  
          <Form.Group as={Row} controlId="end_time">
            <Form.Label column sm="3">End Time<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <TimeInput
                required
                value={this.state.endTime}
                mode='24h'
                onChange={time => {
                  this.setState({endTime: time}, () => {
                  this.props.handleTimeOnChange("end_time", moment(time).format("HH:mm"));
                  //this.props?.formData["end_time", time]
                  })
                }
                }
              />
              {/* <Form.Control.Feedback type="invalid">{errors.end_time}</Form.Control.Feedback> */}
            </Col>
          </Form.Group> 
          
          <Form.Group as={Row} controlId="days">
            <Form.Label column sm="3">Days<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <WeekdayPicker 
                weekDays = {this.state.weekDays}
                onDaySelect = {this.onDaySelect}
              />
              <Form.Control.Feedback type="invalid" className="d-block">{errors.days}</Form.Control.Feedback>
            </Col>
          </Form.Group> 
        </> 
        }

        <Form.Group as={Row} controlId="promo-category">
          <Form.Label column sm="3">
            Promo Category <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              required
              name="promotion_category"
              value={this.props.promotion.promotion_category}
              disabled={true}
              as="select" type="text">
              <option value="">--Select--</option>
              {
                
                Object.keys(this.state.promoCategories).map((key, i) => (
                  <option key={i+1} value={key}>{key}</option>
                ))
              }
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select a Category.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="promo-rule">
          <Form.Label column sm="3">
            Promo Rule <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              required
              name="promotion_rule"
              value={this.props.promotion?.rule}
              disabled={true}
              as="select" type="text">
              <option value="">--Select--</option>
              {
                Object.keys(this.state.promoRules).map((key,i) => (
                  <option key={i+1} value={key}>{key}</option>
                ))
              }
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select a Rule.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        {
          this.state.promoFields?.map((item,i) => {
            let fieldValue = {}
            if(item.name== "x_skus" || item.name=='y_skus' || item.name == 'variant_skus'){
              // fieldValue.value = this.state.promotion_variants[(item.name== "x_skus"?'buy':'get')].map(item => { return({label: item.sku, value: item.id}) })
              fieldValue.value = this.state.promotion_variants?.find(i => i.name === item.name)?.values?.map(item => { return({label: item.sku, value: item.variant_id}) })
            } else if (item.name== "brand_names") {
              fieldValue.value = this.state.promoBrands[0]?.values?.map(item => { return({label: item.brand_name, value: item.brand_id}) })
            } else {
              fieldValue = this.props.promotion?.promotion_rules.find(i => i.name == item.name);
            }
            switch (item.type) {
              case "number":
              case "text":
                return (
                  <Form.Group as={Row} controlId={"promo-field"+i} key={i}>
                    <Form.Label column sm="3">
                      {item.title} <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={item.name}
                        defaultValue={fieldValue?.value}
                        onChange={(e)=> { 
                          let array = new Array(e.target.value)
                          this.props.onPromoFieldChange(fieldValue.id, item.name, array) } }
                        required
                        min={item.minimum}
                        type={item.type}/>
                      <Form.Control.Feedback type="invalid">{item.title} is required</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
        
                );
                break;
              case "select":
                return(
                  <Form.Group as={Row} controlId={"promo-field"+i} key={i}>
                      <Form.Label column sm="3">
                        {item.title} <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <SelectComponent 
                          isMulti = {item.option==="multiple"}
                          selectedValues = {fieldValue?.value}
                          requested_url = {item.dataset_url}
                          name={item.name}
                          isEdit={true}
                          onPromoFieldChange = {this.props.onPromoFieldChange}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">{errors[item.name]}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                )
                break;
              default:
                return (
                  <Form.Group as={Row} controlId={"promo-field"+i} key={i}>
                    <Form.Label column sm="3">
                      {item.title} <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={item.name}
                        value={fieldValue?.value}
                        onChange={this.props.handleInputOnChange}
                        required
                        type={item.type}/>
                      <Form.Control.Feedback type="invalid">{item.title} is required</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
        
                );
                break;
          }
          })
        }
        <div className="mt-3 text-center">
          <Link to={`${process.env.PUBLIC_URL}/promotions/list`} className="mr-2 btn btn-md btn-danger">Cancel</Link>
          <Button size="md"
            type={`submit`}
            disabled={this.props.isSubmitting}
            variant="primary">{this.props.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
        </div>
      </Form>
    );
  }
}
export default PromotionForm;
