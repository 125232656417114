import React, {Component} from 'react';
import App from "../../App";
import {Button, Form, Row, Modal} from "react-bootstrap";
import {FiCheck, FiPlus, FiUpload, IoMdClose} from "react-icons/all";
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import '../../assets/scss/header.scss';

class List extends Component {
  state = {};

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Checklist</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/checklist/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Checklist</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">

              <form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="searchInput" className="sr-only">Search Key</label>
                  <input type="search" name="search_string" id="orderNumber"
                         className='form-control form-control-sm' placeholder="Search Key"/>
                </div>
                <Button size="sm" variant="secondary">Search</Button>
              </form>
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>Question</th>
                  <th>Category</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <div className="order-item">
                      Is the earphone okay?
                    </div>
                  </td>

                  <td>
                    <div className="order-item">
                      Mobile
                    </div>
                  </td>

                  <td>
                    <div className="order-item">
                      Inbound
                    </div>
                  </td>

                  <td style={{width: '150px'}}>
                    <Link to={`${process.env.PUBLIC_URL}/checklist/edit/1`} className="btn btn-block btn-sm btn-outline-info">Edit</Link>
                    <Button block size={`sm`} variant={`outline-danger`}>Delete</Button>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    <div className="order-item">
                      Is the earphone okay?
                    </div>
                  </td>

                  <td>
                    <div className="order-item">
                      Mobile
                    </div>
                  </td>

                  <td>
                    <div className="order-item">
                      Return
                    </div>
                  </td>

                  <td style={{width: '150px'}}>
                    <Link to={`${process.env.PUBLIC_URL}/checklist/edit/1`} className="btn btn-block btn-sm btn-outline-info">Edit</Link>
                    <Button block size={`sm`} variant={`outline-danger`}>Delete</Button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
