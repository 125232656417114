import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { add, addV2, getList } from "../../services/baseServices";
import "../../assets/scss/new-po.scss";
import SearchComponent from "../../components/SearchComponent";
import Variants from "../../components/Variants";
import { FaLongArrowAltLeft } from "react-icons/all";
import { refreshPage } from "../../helper/utils";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";

class Add extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    searchText: "",
    productType: "",
    searchResult: { item_count: 0, variants: [] },
    loadingState: "initial",
    selectedProducts: [],
    isSubmitting: false,
    totalVariantQuantity: 0,
    totalVariantPrice: 0,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ loadingState: "initial" });
    }
  }

  sendQuery = () => {
    const targetedURL = `products/skus/search?search_string=${this.state.searchText}&product_type=${this.state.productType}&order_type=rto`;
    this.setState({ loadingState: "loading" });
    getList(targetedURL)
      .then((res) => {
        console.log("return search", res);
        this.setState({ loadingState: "loaded", searchResult: res });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: "initial",
          searchResult: { item_count: 0, variants: [] },
        });
      });
  };

  handleOnChange = (e) => {
    e.target.name === "product_type"
      ? this.setState({ productType: e.target.value })
      : this.setState({ searchText: e.target.value });
    if (e.target.value.length >= 3 && e.target.name === "search_text") {
      this.sendQuery();
    } else {
      this.setState({
        loadingState: "initial",
        searchResult: { item_count: 0, variants: [] },
      });
    }
  };

  handleOnSelect = (variant) => {
    let selectedProducts = [...this.state.selectedProducts];
    const found = selectedProducts.find((element) => element.id === variant.id);

    if (found === undefined) {
      variant.quantity = "";
      selectedProducts.unshift(variant);
      this.setState({
        loadingState: "initial",
        selectedProducts,
      });
    } else {
      toast.info("Already selected.");
    }
  };

  handleVariantQuantityChange = (e, product, variant_id) => {
    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex((x) => x.id === variant_id);

    if (productIndex !== -1) {
      let theProduct = selectedProducts[productIndex];
      theProduct.quantity = parseInt(e.target.value);
      let pareseQuentity = theProduct.quantity || 0;
      theProduct.totalPrice = pareseQuentity * product.price_distribution;
      selectedProducts[productIndex] = theProduct;
      this.setState({
        selectedProducts,
      });
      this.setState({ selectedProducts }, () => {
        let totalQuantity = 0;
        let totalPrice = 0;
        for (let i = 0; i < selectedProducts.length; i++) {
          if (selectedProducts[i].quantity && selectedProducts[i].totalPrice) {
            totalQuantity = totalQuantity + selectedProducts[i].quantity;
            totalPrice = totalPrice + selectedProducts[i].totalPrice;
          }
        }
        this.setState({
          totalVariantQuantity: totalQuantity,
          totalVariantPrice: totalPrice,
        });
      });
    } else {
      this.setState({
        selectedProducts,
        totalVariantQuantity: 0,
        totalVariantPrice: 0,
      });
    }
  };

  handleVariantRemove = (e, variant_id) => {
    e.preventDefault();

    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex((x) => x.id === variant_id);

    if (productIndex !== -1) {
      selectedProducts.splice(productIndex, 1);

      this.setState({ selectedProducts }, () => {
        toast.success("Removed successfully.");

        let totalQuantity = 0;
        let totalPrice = 0;
        for (let i = 0; i < selectedProducts.length; i++) {
          if (selectedProducts[i].quantity && selectedProducts[i].totalPrice) {
            totalQuantity = totalQuantity + selectedProducts[i].quantity;
            totalPrice = totalPrice + selectedProducts[i].totalPrice;
          }
        }
        this.setState({
          totalVariantQuantity: totalQuantity,
          totalVariantPrice: totalPrice,
        });
      });
    } else {
      this.setState({
        selectedProducts,
        totalVariantQuantity: 0,
        totalVariantPrice: 0,
      });
    }
  };

  debouncedOnPOSubmit = _.debounce(() => {
    this.setState({ isSubmitting: true }, () => {
      this.proceedPurchaseOrder();
    });
  }, DEBOUNCE_TIME);

  handleWithDebouncePO = async (e) => {
    e.preventDefault();
    this.debouncedOnPOSubmit();
  };

  proceedPurchaseOrder = () => {
    let variantsToSend = [];
    this.state.selectedProducts.forEach((product) => {
      if (product.quantity && product.quantity > 0) {
        variantsToSend.push({
          variant_id: product.id,
          quantity: product.quantity,
        });
      }
    });
    if (variantsToSend.length > 0) {
      const postData = variantsToSend;

      const body = {
        business_type: this.state.productType,
        return_transfer_order_params: postData,
      };
      addV2("return_transfer_orders", body)
        .then((res) => {
          if (res?.success) {
            toast.success(res?.message);
            refreshPage(`/dhReturnOrder/details/${res?.data?.id}`, () => {
              this.setState({ isSubmitting: false });
            });
          } else if (res?.message) {
            toast.error(res?.message);
            this.setState({ isSubmitting: false });
          } else {
            toast.error("Please fill all required fields/quantities");
            this.setState({ isSubmitting: false });
          }
        })
        .catch((errMsg) => {
          toast.error(errMsg);
          this.setState({ isSubmitting: false });
        });
    } else {
      toast.error("Please create RTO for atleast one item");
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    return (
      <App>
        <div className="page-header">
          <h2 className="page-title">Create Inter Godown Transfer Order</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/dhReturnOrder/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <SearchComponent
              wrapperRef={this.wrapperRef}
              loadingState={this.state.loadingState}
              handleOnChange={this.handleOnChange}
              handleOnSelect={this.handleOnSelect}
              searchResult={this.state.searchResult}
            />
          </div>
        </div>

        <div>
          <Variants
            selectedProducts={this.state.selectedProducts}
            isSubmitting={this.state.isSubmitting}
            type="dhPurchaseOrder"
            cancelUrl="dhReturnOrder"
            handleVariantQuantityChange={this.handleVariantQuantityChange}
            proceedPurchaseOrder={this.proceedPurchaseOrder}
            handleVariantRemove={this.handleVariantRemove}
            handlePurchaseOrder={this.handleWithDebouncePO}
            totalVariantQuantity={this.state.totalVariantQuantity}
            totalVariantPrice={this.state.totalVariantPrice}
            showTotalRow={true}
          />
        </div>
      </App>
    );
  }
}

export default withRouter(Add);
