import React, { useState, useEffect } from "react";
import { Formik, Form} from "formik";
import * as yup from "yup";
import { Button, Modal } from "react-bootstrap";
import FormControl from "../../components/controls/FormControl";
import { postData } from "../../services/baseServices";
import { toast } from "react-toastify";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"

const BlockModal = ({ show, handleClose, blokModalDetails }) => {
  const [locationId, setLocationId] = useState("");
  const [submitting, setSubmitting] = useState(false)
  const [selectedLocatonDetails, setSelectedLocatonDetails] = useState(null);
  useEffect(()=> {
    if (show) setSubmitting(false)
  }, [show]) 
  useEffect(() => {
    let selectedLocation = blokModalDetails?.locations?.find(
      (item) => item.id == locationId
    );
    setSelectedLocatonDetails(selectedLocation);
  }, [locationId]);

  const validationSchema = yup.object().shape({
    block_qunatity: yup
      .number()
      .required("Block quantity is required field")
      .min(1, "Block quantity must be greater than or equal to 1")
      .max(
        selectedLocatonDetails?.quantity,
        `Block qunatity must be less than or equal to ${selectedLocatonDetails?.quantity}`
      )
      .integer('Only Integer Value is allowed'),
     
    block_reason: yup.string().required("Block reason is required field"),
    location: yup.string().required("Location is required field"),
  });



  const initialValues = {
    block_qunatity: "",
    block_reason: "",
    block_reason_opt: "",
    location: "",
    location_quantity: "",
  };

  const handleBlockSubmit = (values) => {
    const params = {
      variant_id: blokModalDetails?.variant.id,
      blocked_quantity: values.block_qunatity,
      blocked_reason: values.block_reason,
      note: values.block_reason_opt,
      location_id: values.location,
    };
    postData("blocked_items", params)
      .then((res) => {
        if (res.status_code === 201 || res.status_code === 200) {
          toast.success(res.message);
          handleClose(true);
          //setSubmitting(false)
        } else {
          toast.error(res.message);
          setSubmitting(false)
        }
      })
      .catch((err) => {
        toast.error(err);
        setSubmitting(false)
      });
  }

  const debouncedOnBlock = _.debounce((values)=>{
    handleBlockSubmit(values)
  }, DEBOUNCE_TIME);

  const handleWithDebounceBlockSubmit = (values) => {
    setSubmitting(true)
    debouncedOnBlock(values);
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="ml-3">Block Product Details</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleWithDebounceBlockSubmit}
        >
          {(
            {
            handleChange,
            values,
            setFieldValue,
         
          }      
          ) => {
            setLocationId(values.location);
            return (
              <Form>
                <Modal.Body className="p-5">
                  <div className="row mb-2">
                    <div className="col-sm-5">
                      <p>Title</p>
                    </div>
                    <div className="col-sm-7">
                      <p>{blokModalDetails?.variant?.title}</p>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-5">
                      <p>SKU</p>
                    </div>
                    <div className="col-sm-7">
                      <p>{blokModalDetails?.variant?.sku}</p>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-5">
                      <p>Available Quantity</p>
                    </div>
                    <div className="col-sm-7">
                      <p>{blokModalDetails?.variant?.available_quantity}</p>
                    </div>
                  </div>
                  <FormControl
                    control="select"
                    name="location"
                    label="Location"
                    onChange={
                      (e) => {
                      handleChange(e);
                      setFieldValue("block_qunatity", "");
                    }
                  }
                    required="*"
                    options={blokModalDetails?.locations}
                  />
                  {selectedLocatonDetails && (
                    <>
                      <div className="row mb-2">
                        <div className="col-sm-5">
                          <p>Location Quantity</p>
                        </div>
                        <div className="col-sm-7">
                          <p>{selectedLocatonDetails?.quantity}</p>
                        </div>
                      </div>
                      <FormControl
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        control="input"
                        type="number"
                        name="block_qunatity"
                        label="Block Quantity"
                        defaultValue
                        required="*"
                      />
                    </>
                  )}

                  <FormControl
                    control="select"
                    name="block_reason"
                    label="Block Reason"
                    required="*"
                    options={blokModalDetails?.blocked_reasons}
                  />
                  <FormControl
                    control="textarea"
                    name="block_reason_opt"
                    label="Block Reason(Optional)"
                    rows={3}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit" disabled={submitting}>
                    Block
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default BlockModal;
