import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import LocationForm from "../location/Form";
import { add } from "../../services/baseServices";
import {AiOutlineArrowLeft, FaLongArrowAltLeft} from "react-icons/all";

class Add extends Component {
  state = {
    formData: {},
  };

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
    });
  }

  handleAddItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        const formData = {...this.state.formData};
          add("locations", "location", formData)
            .then(res => {
              if(res?.id) {
              this.props.history.push(`/location/list`);
              toast.success("Location code has been created successfully.");
              } else {
                toast.error(res?.message);
                this.setState({isSubmitting: false})
              }
            })
            .catch(errMsg => {
              toast.error(errMsg);
              this.setState({isSubmitting: false})
            });
      })
    }
  }

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Location</h2>
          <Link to={`${process.env.PUBLIC_URL}/location/list`}
            className="btn btn-sm btn-link mr-2"><AiOutlineArrowLeft/> Back to List
          </Link>
        </div>

        <div className="card-block">
          <div className="block-body">
              <>
                <LocationForm
                  handleClick={this.handleAddItem}
                  handleInputOnChange={this.handleInputOnChange}
                  formData={this.state.formData}
                  isSubmitting={this.state.isSubmitting}
                  isAddForm={true}
                />
              </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Add);