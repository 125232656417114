import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import { postData } from "../../services/baseServices";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { TiDelete } from "react-icons/all";

class PackForm extends Component {
  state = {
    isLoadingData: true,
    isSubmitting: false,
    isFormValidated: false,
    qrCodeMatching: [],
    variants_locations: [],
    total_bundle: 1
  };

  setTotalBundle = (e) =>{
    const val = e.target.value ? parseInt(e.target.value) : 1;
    this.setState({total_bundle: val > 0 ? val : 1})
  }

  debouncedOnPackSubmit = _.debounce((e, form) => {
    this.handleSubmitPack(e, form);
  }, DEBOUNCE_TIME);

  handleWithDebouncePackSubmit = (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    this.debouncedOnPackSubmit(e, e.currentTarget);
  };

  handleSubmitPack = (e, form) => {
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true, isSubmitting: false });
    } else {
      const formData = this.props.selectedProducts[0];
      let DataToPost = {
        bundle_variant_id: formData.bundle_variant_id,
        bundle_location_id: formData.bundle_location_id,
        bundle_quantity: this.state.total_bundle,
        bundle_variants: [],
      };
      formData.bundle_variants.forEach((bv) => {
        bv.packed.forEach((item) => {
          DataToPost.bundle_variants.push({
            variant_id: bv.variant_id,
            packed_quantity: item.quantity,
            location_id: item.location_id,
            qr_code: item.qr_code,
          });
        });
      });

      postData("bundles/pack", DataToPost)
        .then((res) => {
          if (res.success) {
            this.props.history.push(`/bundle/list`);
            toast.success(res.message);
          } else {
            toast.error(res.message);
            this.setState({ isSubmitting: false });
          }
        })
        .catch((errMsg) => {
          toast.error(errMsg);
          this.setState({ isSubmitting: false });
        });
    }
  };

  handleSubmitUnack = (e, form) => {};

  render() {
    const {
      selectedProducts,
      handleVariantQuantityChange,
      handleSKURemove,
      handleInputQRCodeOnChange,
      setVariantsLocations,
      addItemIntoPackUnderSKU,
      handleVariantRemove,
      locations,
      setBundleLocation,
    } = this.props;

    const variants = selectedProducts.map((product) => {
      return (
        <>
          <div>
            <div className=" mb-2">
              <table className="info-table">
                <tbody>
                  <tr>
                    <td style={{width: '250px'}}>Bundle Product</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td className='d-flex text-bold'>{product.product_title}
                      <div className="m-1">
                          <TiDelete
                            size={28}
                            style={{ color: "#C82333", cursor: "pointer" }}
                            onClick={(e) => handleSKURemove(e, product.bundle_variant_id)}
                            title="Delete"
                          />
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>SKU</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>
                      {product.bundle_variant_sku}
                    </td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Bundle Quantity</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>
                      <input
                       style={{width: '250px'}}
                      name='bundle_quantity'
                      className="form-control form-control-sm"
                      required
                      min="1"
                      type="number"
                      defaultValue={1}
                      // value={this.state.total_bundle}
                      onKeyPress={(e) => {
                        if (["Enter", "-"].includes(e.key)) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onChange={this.setTotalBundle}
                    />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h6>Variants</h6>
          <div className="card-block">
            <div className="block-body">
              {product.bundle_variants.map((bv) => {
                return (
                  <>
                    <div className="d-flex">
                      <div>
                        <table className="table table-bordered table-responsive">
                          <tr>
                            <th>SKU</th>
                            <th>Available quantity</th>
                            <th>Quantity</th>
                            <th>Action</th>
                          </tr>
                          <tbody>
                            <tr>
                              <td>{bv.sku}</td>
                              <td>{bv.available_quantity}</td>
                              <td>{bv.quantity * this.state.total_bundle}</td>
                              <td>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  disabled={bv.quantity*this.state.total_bundle <= bv.allocated}
                                  onClick={() =>
                                    addItemIntoPackUnderSKU(
                                      this.state.total_bundle,
                                      product.bundle_variant_id,
                                      bv.sku
                                    )
                                  }>
                                  Add
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="ml-5">
                        <table className="table table-bordered">
                          <tbody>
                            {bv.packed.length > 0 && (
                              <tr>
                                <th>Quantity</th>
                                <th>QR Code</th>
                                <th>Location</th>
                                <th>Action</th>
                              </tr>
                            )}
                            {bv.packed.map((item) => (
                              <tr>
                                <td>
                                  <input
                                    name={item.id}
                                    className="form-control form-control-sm"
                                    required
                                    min="1"
                                    max={bv.allocated * this.state.total_bundle}
                                    type="number"
                                    defaultValue="1"
                                    // value={
                                    //   item.quantity !== undefined
                                    //     ? item.quantity
                                    //     : ""
                                    // }
                                    onKeyPress={(e) => {
                                      //const xxx =parseInt(e.target.value+e.key)
                                      // / || xxx <=0 || xxx > bv.allocated * this.state.total_bundle
                                      if (["Enter", "-"].includes(e.key)) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }
                                    }}
                                    onChange={(e) =>
                                      e.target.value &&
                                      handleVariantQuantityChange(
                                        this.state.total_bundle,
                                        e,
                                        product.bundle_variant_id,
                                        bv.sku,
                                        item.id
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Form.Group
                                    as={Row}
                                    controlId="displayOrShopName"
                                    className="qrCodeField">
                                    <Col sm="12">
                                      <Form.Control
                                        name={"qr_code"}
                                        required
                                        value={item.qr_code}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            e.stopPropagation();
                                          }
                                        }}
                                        onChange={(e) =>
                                          handleInputQRCodeOnChange(
                                            e,
                                            product.bundle_variant_id,
                                            bv.sku,
                                            item.id
                                          )
                                        }
                                        type="text"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please enter QR code.
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>
                                </td>
                                <td>
                                  <select
                                    id={item.id}
                                    required
                                    name="location_id"
                                    className="form-control form-control-sm"
                                    onChange={(e) => {
                                      setVariantsLocations(
                                        e,
                                        product.bundle_variant_id,
                                        bv.sku,
                                        item.id
                                      );
                                    }}>
                                    <option value="">--Select--</option>
                                    {bv.locations?.length > 0
                                      ? bv.locations?.map((loc) => {
                                          return (
                                            <option
                                              value={loc?.id}
                                              disabled={loc.quantity === 0}
                                            >
                                              {loc?.code} [Q: {loc.quantity}]
                                            </option>
                                          );
                                        })
                                      : ""}
                                  </select>
                                </td>
                                <td>
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(e) =>
                                      handleVariantRemove(
                                        e,
                                        product.bundle_variant_id,
                                        bv.sku,
                                        item.id
                                      )
                                    }>
                                    X
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })}
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="p-2">
                <select
                  id="location"
                  required
                  name="bundle_location_id"
                  className="form-control form-control-sm"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  onChange={(e) => {
                    setBundleLocation(e, product.bundle_variant_id);
                  }}>
                  <option value="" selected={product.bundle_variant_id === ""}>
                    --Select--
                  </option>
                  {locations?.length > 0
                    ? locations?.map((loc) => {
                        return (
                          <option
                            value={loc?.id}
                            selected={product.bundle_location_id === loc.id}>
                            {loc?.code}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
              <div className="p-2">
                <label>Location: </label>
              </div>
            </div>
          </div>
        </>
      );
    });

    return (
      <div>
        {selectedProducts.length > 0 && (
          <>
            <Form
              noValidate
              validated={this.state.isFormValidated}
              onSubmit={this.handleWithDebouncePackSubmit}>
              {variants}
              <div align="right">
                <Button
                  size="sm"
                  type={"submit"}
                  disabled={this.state.isSubmitting}
                  variant="primary">
                  {this.state.isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(PackForm);
