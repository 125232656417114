import React, {Component} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {FiChevronRight} from "react-icons/all";
import {connect} from "react-redux";
import {getCategory} from "../services/categoryServices";
import {getList} from "../services/baseServices";

class ChooseCategory extends Component {
  state = {
    isModalShowing: false,
    categories: [],
    selectedCategories: null,
    childCategories: [],
    selectedCatArr: [],
    isLoading: false
  }

  componentDidMount() {
    /*getList('categories')
      .then(res => {
        this.setState({categories: res})
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })*/
    getCategory()
      .then(res => {
        if(res.status !== 500){
          this.setState({categories: res.data, isLoading: false}, () => {
            if (this.props.selectedCategories) {
              this.setChildCategories(this.state.categories);
            }
          })
        }

      })
      .catch(errMsg => {
        this.setState({isLoading: false});
        console.log('Err Msg: ', errMsg)
      })
  }

  setChildCategories = (categories) => {
    console.log('uuu', this.props.selectedCategories)
    const selectedCatArr = [...this.state.selectedCatArr];
    const childCategories = [...this.state.childCategories];
    let newCategories = categories;

    this.props.selectedCategories.forEach(item => {
      const category = newCategories.filter(category => category.id === parseInt(item));
      console.log('item', category)
      if (category.length > 0) {
        selectedCatArr.push({id: category[0].id, catName: category[0].title, sub_categories: category[0].sub_categories})
        if (category[0]?.sub_categories?.length > 0) {
          childCategories.push(category[0].sub_categories);
          newCategories = category[0].sub_categories;
        }
      }
    });

    this.setState({childCategories, selectedCatArr}, () => {
      this.setState({selectedCategories: this.renderTargetCategory()})
    })
  }

  handleChildCategory = (index, catObj) => {
    let selectedCatArr = [...this.state.selectedCatArr];
    let childCategories = [...this.state.childCategories];

    if (index === 'root') {
      childCategories = [];
      selectedCatArr = [];

      const categoryObj = this.state.categories.filter(obj => {
        return obj.id === catObj.id;
      })

      if (!selectedCatArr.length > 0) {
        selectedCatArr.push(catObj)
      } else {
        selectedCatArr[0] = catObj;
      }

      if (this.state.childCategories.length > 0) {
        if (categoryObj[0].sub_categories?.length > 0) {
          childCategories[0] = categoryObj[0].sub_categories;
        } else {
          childCategories = []
        }
      } else {
        if (categoryObj[0].sub_categories?.length > 0) {
          childCategories.push(categoryObj[0].sub_categories);
        } else {
          childCategories = []
        }
      }
    } else {
      childCategories.splice(index + 1, childCategories.length - 1);
      selectedCatArr.splice(index + 1, selectedCatArr.length - 1);

      const childCategoriesObj = this.state.childCategories[index].filter(obj => {
        return obj.id === catObj.id;
      })

      if (selectedCatArr[index + 1]) {
        selectedCatArr[index + 1] = catObj;
      } else {
        selectedCatArr.push(catObj);
      }

      if (childCategories[index + 1]) {
        if (childCategoriesObj[0].sub_categories?.length > 0) {
          childCategories[index + 1] = childCategoriesObj[0].sub_categories;
        }
      } else {
        if (childCategoriesObj[0].sub_categories?.length > 0) {
          childCategories.push(childCategoriesObj[0].sub_categories);
        }
      }
    }

    this.setState({selectedCatArr, childCategories}, () => {
      console.log('Selected Category Array: ', this.state);
    });
  }

  handleChangeCategory = () => {
    this.setState({isModalShowing: true})
  };

  handleModalHide = () => {
    this.setState({isModalShowing: false})
  };

  handleCategoryConfirm = (selectedCategories) => {
    this.setState({selectedCategories: selectedCategories, isModalShowing: false}, () => {
      this.props.onConfirm(this.state.selectedCatArr);
    })
  };

  renderTargetCategory = () => {
    const targetCategory = this.state.selectedCatArr?.map(cat => cat?.catName);
    console.log('testing', targetCategory)
    return targetCategory.join(" > ")
  }

  render() {
    return (
      <>
        {this.state.isModalShowing &&
        <Modal
          size="lg"
          dialogClassName="cateogry-change-modal"
          show={this.state.isModalShowing} onHide={this.handleModalHide}>
          <Modal.Header closeButton>
            <Modal.Title onClick={() => {
              console.log(this.state)
            }}>Change Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.isLoading ?
              <span>Loading...</span>
              :
              <>
                {this.state.selectedCatArr.length > 0 &&
                <p className="target-category">Target Category: {this.renderTargetCategory()}</p>}

                <div className="category-row">
                  <div className="category-col">
                    {/*<div className="form-group">*/}
                    {/*  <input type="search" className="form-control form-control-sm" placeholder="Search"/>*/}
                    {/*</div>*/}

                    {this.state.categories.length > 0 &&
                    <ul className="category-list">
                      {this.state.categories.map(cat => (
                        <li key={cat.id}
                            className={this.state.selectedCatArr[0]?.id === cat.id ? 'selected' : ''}
                            onClick={() => this.handleChildCategory('root', {catName: cat.title, id: cat.id, sub_categories: cat.sub_categories})}>
                          <span className='text'>{cat.title}</span>
                          {cat.sub_categories?.length > 0 && <span className="caret-icon"><FiChevronRight/></span>}
                        </li>
                      ))}
                    </ul>
                    }
                  </div>

                  {this.state.childCategories.map((childCategories, index) => (
                    <div key={index} className="category-col">
                      {/*<div className="form-group">*/}
                      {/*  <input type="search" className="form-control form-control-sm" placeholder="Search"/>*/}
                      {/*</div>*/}

                      <ul className="category-list">
                        {childCategories.map(childCat => (
                          <li key={childCat.id}
                              onClick={() => this.handleChildCategory(index, {
                                catName: childCat.title,
                                id: childCat.id,
                                sub_categories: childCat.sub_categories
                              })}
                              className={this.state.selectedCatArr[index + 1]?.id === childCat.id ? 'selected' : ''}>
                            <span className='text'>{childCat.title}</span>
                            {childCat.sub_categories?.length > 0 &&
                            <span className="caret-icon"><FiChevronRight/></span>}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" onClick={this.handleModalHide}>
              Cancel
            </Button>
            <Button variant="primary"
                    onClick={() => this.handleCategoryConfirm(this.renderTargetCategory())}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>}

        <Form.Control type="text"
                      value={this.state.isLoading ? 'Loading...' : this.state.selectedCategories}
                      onChange={(e) => console.log(e.target.value)}
                      onClick={this.handleChangeCategory} readOnly placeholder="Select category"/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCategory);
