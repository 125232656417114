

export const FieldLabel= ({
  name,
  label,
  required = false,
  className = "",
}) => {
  return (
    <label
      htmlFor={name}
      className={`  ${className}`}
    >
      {label} {required && <span className="text-danger">*</span>}
    </label>
  );
};
