import React, { Component } from 'react';
import { FaLongArrowAltLeft } from 'react-icons/all';
import { Link } from 'react-router-dom';
import App from './../../App';
import { Rating } from '@material-ui/lab/Rating';
import { getDetails } from './../../services/baseServices';
import { withRouter } from 'react-router-dom';

class ContactDetails extends Component {
    constructor() {
        super();
        this.state = {
            contactDetails: {},
            isLoading: true
        }
      };

      componentWillMount() {
        this.getContactDetails();
        
      }

      getContactDetails = () =>{
        getDetails("contact_us", this.props.match.params.id)
        .then((res) => {
          console.log("response", res);
          if(res.status === 200){
            this.setState({ contactDetails: res.data}, ()=>{
              this.setState({isLoading: false})
            });
          }else{
            this.setState({isLoading: false})
          }
        })
        .catch((errMsg) => {
          this.setState({isLoading: false})
          console.log("Error Message: ", errMsg);
        });
      }

    render() {
      
        return (
            <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Contact Details</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/contact-us/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
            </div>
          </div>

          {
            this.state.isLoading ?
              <div className={`card-block`}>
                <div className="block-body">
                  Loading...
                </div>
              </div>
              :
              <div className="card-block">
						{this.state.isLoading ? (
							<div className={`card-block`}>
								<div className="block-body">Loading...</div>
							</div>
						) : (
							<div className="block-body">
								<>
									<table className="info-table col-12">
										<tbody>
											<tr>
												<td style={{ width: "250px" }}>Contact Id</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.contactDetails.id}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>
													Name
												</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.contactDetails.name}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Contact Number</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.contactDetails.phone}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Email</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.contactDetails.email}</td>
											</tr>
											
											<tr>
												<td style={{ width: "250px" }}>Message</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.contactDetails.message}</td>
											</tr>
										</tbody>
									</table>
								</>
							</div>
						)}
					</div>
					
          }
          
        </div>
      </App>
        );
    }
}

export default withRouter(ContactDetails);