import React, { Component } from "react";
import App from "../../App";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEye, FaLongArrowAltLeft } from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getList, getPaginatedList, postData2 } from "../../services/baseServices";
import { getFormattedDate } from "../../helper/utils";
import Loader from 'react-loader';
import moment from 'moment'
import { toast } from "react-toastify";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"
import ConfirmationModal from "../common/Modal";

var startDate = moment().subtract(29, 'days');
var today = moment();
class ForwardChallanForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemList: null,
			formData: {
				start_date_time: getFormattedDate(startDate,"YYYY-MM-DD"),
				end_date_time: getFormattedDate(today,"YYYY-MM-DD"),
				status: "Ready To Dispatch", 
				distributor_id:''
			},
			isLoading: false,
			activePage: 1,
			page: 1,
			counter: 1,
			start_date_time: startDate,
			end_date_time: today,
			distributors: [],
            includedIds: [],
            allSeleted: {
                '1': false 
            }
		};
	}

	getData() {
        if(!this.state.formData.distributor_id || !this.state.formData.start_date_time || !this.state.formData.end_date_time) {
            toast.warning('Please choose Distribution and date range')
            return
        }
		const obj = {};
		obj.per_page = 50;
		if (this.state.page > 0) obj.page = this.state.page;
        obj.status = this.state.formData.status;
		if (this.state.start_date_time)
			obj.start_date_time = getFormattedDate(this.state.formData.start_date_time,"YYYY-MM-DD");
		if (this.state.end_date_time)
			obj.end_date_time = getFormattedDate(this.state.formData.end_date_time,"YYYY-MM-DD");

		obj.distributor_id = this.state.formData.distributor_id;

		this.setState({ isLoading: true });
		getPaginatedList('challans/orders',obj)
			.then((res) => {
				this.setState({
					itemList: res?.data?.data,
					counter:
						this.state.activePage - 1 > 0
							? (this.state.activePage - 1) * this.state.per_page + 1
							: 1,
					per_page: res.headers["x-per-page"],
					total_data: res.headers["x-total"],
					isLoading: false,
				});
			})
			.catch((message) => {toast.error(message)});
	}

	componentDidMount() {
		getList("distributors")
		.then((res) => {
			this.setState({distributors: res?.data});
		})
		.catch((errMsg) => {});
	}

	handleFilterOnChange = (e) => {
		const formData = { ...this.state.formData };
		formData[e.target.name] = e.target.value;
		this.setState({ formData, page: 1 });
	};

	filterList = (e) => {
		e.preventDefault();
		this.getData();
        this.setState({ activePage: 1, includedIds: [], allSeleted : { '1': false }})
	};

	handlePageChange = (pageNumber) => {
		this.setState(
			{ page: pageNumber, activePage: pageNumber, isLoading: true},
			() => {
				this.getData();
			}
		);
	};

	handleOnApply = (e, picker) => {
		this.setState({
			start_date_time: picker.startDate.format(),
			end_date_time: picker.endDate.format(),
		});
		this.setState({
			formData: {
				...this.state.formData,
				start_date_time: picker.startDate.format(),
				end_date_time: picker.endDate.format(),
			},
		});
	};

    setMark = (all) => {
        if(all) {
          this.setState({ allSeleted : {...this.state.allSeleted, [this.state.page]: true}})
          const xxx = this.state.itemList?.map(item=>+item.id)
          let ids = [...this.state.includedIds]
          ids = ids?.filter(i => !xxx.includes(i))
          ids = ids.concat(xxx)
          this.setState({ includedIds : ids})
        } else {
            this.setState({ allSeleted : {...this.state.allSeleted, [this.state.page]: false}})
            let selectedIds = [...this.state.includedIds]
            const orderIds = this.state.itemList?.map(i => i.id)
            selectedIds = selectedIds?.filter(i => !orderIds.includes(i))
            this.setState({ includedIds : selectedIds})
        }
      }

    selectOrder = (orderId) => {
        let ids = [...this.state.includedIds];
        if (ids?.includes(orderId)) {
          ids = ids.filter(function (e) {
            return e != orderId
          });
        } else {
          ids.push(+orderId);
            
        }
        this.setState({ includedIds : ids})
      }
    
    submitChallanForm = () => {
        const params = {
            distributor_id : this.state.formData.distributor_id,
            order_ids: this.state.includedIds
        }
        postData2("challans",params)
        .then(res => {
          if(res.success) {
            this.props.history.push(`/challan/list`);
            toast.success("Challan has been created successfully.");
          }
          else {
            toast.error(res.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg);
        }); 
    }


  debouncedOnSubmitChallan = _.debounce(()=>{
    this.submitChallanForm()
  }, DEBOUNCE_TIME);

  handleWithDebounceSubmitForm = () => {
    this.debouncedOnSubmitChallan();
  }

	setOrderClassName = (inputValue) => {
		switch (inputValue) {
			case "express_delivery":
				return "text-danger";
			case "in_transit_delivery_switch":
				return "text-primary";
			case "in_transit_partner_switch":
				return "text-warning";
			default:
				return "";
		}
	};

	render() {
		return (
			<App layout="">
				<div className="main-content">
					<div className="page-header">
						<h2 className="page-title">Challan Creation Form</h2>
                        <div className="ml-auto" style={{display: "flex"}}>
                          <Link to={`/challan/list`} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Challan list</Link>
                        </div>
					</div>
					<form className="form-inline mb-3">
						<div className="form-group mr-3">
							<label htmlFor="distributor_id" className="sr-only">
							Distributor
							</label>
							<select
							id="distributor_id"
							name="distributor_id"
							className="form-control form-control-sm"
							onChange={this.handleFilterOnChange}
							value={this.state.formData?.distributor_id}
							>
							<option value="">--Select Distributor--</option>
							{this.state.distributors.map((item, i) => {
								return (
								<option
									key={i}
									value={item.id}
								>
									{item.name}
								</option>
								);
							})}
							</select>
						</div>
	
						<div className="form-group mr-3">
							<label htmlFor="status" className="sr-only">
								Date Range
							</label>
							<DateRangePicker
								initialSettings={{ 
									showDropdowns: true,
									locale:{format: "DD/MM/YYYY"},
									startDate: this.state.start_date_time,
									endDate: this.state.end_date_time,
									maxSpan:{"months": 3},
									autoApply: true,
									}}
								onApply={(e, picker) => this.handleOnApply(e, picker)}
							>
								<input
									type="text"
									value={
										this.state.start_date_time && this.state.end_date_time
											? `${getFormattedDate(this.state.start_date_time, "DD/MM/YYYY"
												)} - ${getFormattedDate(this.state.end_date_time,"DD/MM/YYYY"
												)}`
											: ""
									}
									style={{ textAlign: "left", minWidth: "150px" }}
									placeholder={"Select date range"}
									className="form-control form-control-sm"
								/>
							</DateRangePicker>
						</div>
						<Button
							size="sm"
							type={`submit`}
							onClick={this.filterList}
							variant="primary"
                            disabled={!this.state.formData.distributor_id || !this.state.formData.start_date_time || !this.state.formData.end_date_time}
						>
							Filter
						</Button>
					</form>
                    {
                    this.state.itemList &&
					<Loader loaded={!this.state.isLoading}>
                        <div className="card-block">
                            <div className="block-body">
                                {
                                    this.state.includedIds?.length > 0 &&
                                    <div className="block-body d-flex justify-content-between">
                                        <div>
                                            Selected Orders: <strong>{this.state.includedIds?.length}</strong>
                                            <br/>
                                            Total Orders: <strong>{this.state.total_data}</strong>
                                        </div>
                                        <div>
                                            <OverlayTrigger
                                                key="view"
                                                placement="top"
                                                overlay={
                                                <Tooltip id={`tooltip-view`}>Create Challan</Tooltip>
                                                }
                                            >
                                                <ConfirmationModal 
                                                    customBtn = {true}
                                                    btnText = "Create Challan"
                                                    variant = "primary"
                                                    title="Create Challan" 
                                                    body="Are you sure to create the challan?" 
                                                    handleAction={() =>this.debouncedOnSubmitChallan()}/>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                }
                                <table className="order-table table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                            {
                                            !this.state.allSeleted?.[this.state.page] ?
                                            <span><input name="select-all" id="select-all" type="checkbox" onChange={()=>this.setMark(true)}/>  Select All</span>
                                            : <span><input name="unselect-all" type="checkbox" id="unselect-all" checked={true} onChange={()=> this.setMark(false)}/>  Unselect All</span>
                                            }
                                            </th>
                                            <th>Order No</th>
                                            <th>Distributor</th>
                                            <th>Shipping Type</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.itemList?.length > 0 ? (
                                            this.state.itemList?.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                    >
                                                        <td>
                                                            <input 
                                                                custom
                                                                inline
                                                                type="checkbox" 
                                                                id={`custom_checkbox_${index}`} 
                                                                className="input"
                                                                value={item.id} 
                                                                name="select_order" 
                                                                checked={this.state.includedIds?.includes((item.id))}
                                                                onChange={()=>this.selectOrder(item.id)}/>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <div className="order-item">
                                                                <span>{item?.distributor_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="order-item">
                                                                {item.shipping_type}
                                                            </div>
                                                        </td>
                                                    
                                                        <td>
                                                            <div className="order-item">{item.total_price}</div>
                                                        </td>
                                                        <td style={{ width: "150px" }} className={"nowrap"}>
                                                            <div className="btn-group">
                                                                <OverlayTrigger
                                                                    key="view"
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-view`}>View</Tooltip>
                                                                    }
                                                                >
                                                                    <Link
                                                                        to={`${process.env.PUBLIC_URL}/customerOrder/details/${item.id}`}
                                                                        className="btn btn-sm btn-default"
                                                                    >
                                                                        <FiEye />
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <h5>No Order Found</h5>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-3">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.per_page}
                                    totalItemsCount={this.state.total_data}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </Loader>
                    }
				</div>
			</App>
		);
	}
}

export default withRouter(ForwardChallanForm);
