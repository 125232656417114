import React, { useEffect, useState } from "react";
import { getList, postData } from "../../services/baseServices";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectDeliveryPerson from "../../components/SelectDeliveryPerson";
import { toast } from "react-toastify";

const EditBuffer = () => {
  const [deliveryPersons, setDeliveryPersons] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedDeliveryPerson, setSelectedDeliveryPerson] = useState();
  const [bufferStocks, setBufferStocks] = useState(new Map());

  useEffect(() => {
    getList("buffer_stocks/delivery_persons").then((res) => {
      if (res?.data?.delivery_person_list) {
        setDeliveryPersons(res?.data?.delivery_person_list);
      }
    });
  }, []);

  const getProductList = (id) =>
    getList(
      "buffer_stocks/fetch_assigned_stocks?delivery_person_id=" + id
    ).then((res) => {
      if (res?.data) {
        setProducts(res?.data);
        setBufferStocks((prevStocks) => {
          const updatedStocks = new Map(prevStocks);

          res?.data?.forEach((element) => {
            updatedStocks.set(element?.id, {
              id: element?.id,
              variant_id: element?.variant_id,
              allowed_buffer: element?.current_buffer,
            });
          });

          return updatedStocks;
        });
      }
    });

  const handleSelectChange = (value) => {
    getProductList(value?.id);
    setSelectedDeliveryPerson(() => value);
  };

  const handleQuantityChange = (productId, variantId, newBufferQty, availableQty, initialBufferQty) => {
    setBufferStocks((prevStocksMap) => {
      if (newBufferQty > availableQty + initialBufferQty) {
        toast.warn("Buffer quantity can not be greater than available quantity", {
          toastId: "buffer_stock_warn",
        });
        return prevStocksMap; // Return the previous state
      }
      const updatedStocksMap = new Map(prevStocksMap);

      const product = updatedStocksMap.get(productId);
      if (product) {
        product.allowed_buffer = Math.max(0, parseInt(newBufferQty));
      } else {
        updatedStocksMap.set(productId, {
          id: productId,
          variant_id: variantId,
          allowed_buffer: Math.max(0, parseInt(newBufferQty)),
        });
      }

      return updatedStocksMap;
    });
  };

  const handleSubmit = () => {
    const buffers = Array.from(bufferStocks.values());

    const payload = {
      delivery_person_id: selectedDeliveryPerson?.id,
      assigned_buffers: buffers,
    };

    postData("/buffer_stocks/assign_buffer", payload)
      .then((res) => {
        if (res.success) {
          toast.success(
            `Assigned sucessfully to ${selectedDeliveryPerson?.sr_name}`
          );
          getProductList(selectedDeliveryPerson?.id);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <App layout={""}>
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Edit Buffer Stock</h2>
        </div>
        <div>
          <Form.Group as={Row}>
            <Col sm="12" md={6} lg={6}>
              <SelectDeliveryPerson
                deliveryPersons={deliveryPersons}
                handleChange={handleSelectChange}
                selectedDeliveryPerson={selectedDeliveryPerson}
              />
            </Col>
          </Form.Group>
        </div>
        <div className="card-block">
          <div className="block-body">
            <table className="order-table table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "20px" }}>#</th>
                  <th>Product Title</th>
                  <th>SKU</th>
                  <th>Available Quantity</th>
                  <th>Assigned Quantity</th>
                </tr>
              </thead>
              <tbody>
                {products && products.length
                  ? products?.map((item, index) => (
                      <tr key={item?.id}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="order-item">{item?.name}</div>
                        </td>
                        <td>
                          <div className="order-item">{item?.sku}</div>
                        </td>
                        <td>
                          <div className="order-item">
                            {item?.available_quantity}
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            <Form.Control
                              width={50}
                              type="number"
                              defaultValue={item?.current_buffer}
                              onChange={(e) =>
                                handleQuantityChange(
                                  item?.id,
                                  item?.variant_id,
                                  e.target?.value,
                                  item?.available_quantity,
                                  item?.initial_buffer,
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          <div>
            <Button onClick={handleSubmit} className="float-right my-4">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </App>
  );
};

export default EditBuffer;
