import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";

class Edit extends Component {
  state = {
  };

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Purchase Order</h2>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Product <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"role"}
                      as="select">
                      <option value="">--Select Product--</option>
                      <option value="1">Product 1</option>
                      <option value="2" selected={`true`}>Product 2</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Quantity <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      defaultValue={`2000`}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter quantity</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Supplier <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"role"}
                      as="select">
                      <option value="">--Select Supplier--</option>
                      <option value="1" selected={`true`}>Supplier 1</option>
                      <option value="2">Supplier 2</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Supplier Price <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      defaultValue={`300`}
                      required
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter supplier price</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Logistic <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"role"}
                      as="select">
                      <option value="">--Select Logistic--</option>
                      <option value="1" >Supplier 1</option>
                      <option value="2"  selected={`true`}>Supplier 2</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <div className="mt-3">
                  <Link to={`/purchaseOrder/list`}>
                    <Button size="lg" type={"submit"} disabled={this.state.isAdding} variant="primary">{this.state.isAdding ? 'Updating...' : 'Update'}</Button>
                  </Link>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);