import {apiGetConfigByKey,apiCreateOrUpdateByKey} from "../../services/configurationService";

export const getConfigByKey = (key, callback = () => null) => {
    return () => {
        apiGetConfigByKey(key).then(res => {
            if (res.status === 200 && res.success) {
                callback(true, res.data)
            } else {
                callback(false, res.data)
            }
        })
            .catch(err => {
                callback(false, 'An server error occurred!')
            })
    }
}

export const createOrUpdate = (request_body, callback = () => null) => {
    return () => {
        apiCreateOrUpdateByKey(request_body).then(res => {
            if (res.status === 200 && res.success) {
                callback(true, res?.message)
            } else {
                callback(false, res?.message)
            }
        })
            .catch(err => {
                callback(false, 'An server error occurred!')
            })
    }
}
