import React, { Component } from "react";
import App from "../../App";
import { FaLongArrowAltLeft, IoMdClose } from "react-icons/all";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import "../../assets/scss/add-product.scss";
import styleReactSelect from "../../assets/styleReactSelect";
import { toast } from "react-toastify";
import { getProductType } from "../../services/productTypeService";
import { addProduct } from "../../services/productServices";
import BrowseButton from "../../components/BrowseButton";
import JoditEditorComponent from "../../components/JoditEditorComponent";
import ChooseCategory from "../../components/ChooseCategory";
import { getData, getList } from "../../services/baseServices";
import VariantForm from "./VariantForm";
import BundleForm from "./Bundle/Index";
import { difference } from "lodash";
import { convertToSlug } from "../../helper/utils";

class AddProduct extends Component {
  constructor() {
    super();
    this.state = {
      supplier_id: "",
      suppliers: [],
      uniqueSlug: "",
      product: {
        slug: "",
        business_type: "multicat",
        public_visibility: false,
        product_features_attributes: [],
        product_type_ids: [],
        sku_type: "simple_product",
        variants_attributes: [
          {
            primary: true,
            price_consumer: "",
            price_retailer: "",
            price_distribution: "",
            discount_type: "percentage",
          },
        ],
        product_attribute_images_attributes: [],
        bundle_variants: [],
        is_emi_available: false,
        tenures: [],
      },
      variant_attributeValues: [],
      copy_variant_attribute_values: [],
      productTypes: [],
      brands: [],
      companies: [],
      category_ids: [],
      attributeSet: [],
      product_type: [],
      selectedAttributeSet: {},
      selectedAttribute: {},
      attributeValues: [],
      metaInfo: {},
      faq: {},
      notLastChild: false,
      selectedBrand: {},
      selectedCompany: {},
      selectedSupplier: {},
      updateVariantFor: null,
    };
  }

  componentDidMount() {
    getProductType()
      .then(async (res) => {
        await this.setState(
          {
            productTypes: res,
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });

    getList("options/companies")
      .then(async (res) => {
        let companies = [];
        await res.data.forEach((item) => {
          let company = { ...item, label: item.label, value: item.value };
          companies.push(company);
        });

        await this.setState({ companies });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
    getList("/options/suppliers")
      .then(async (res) => {
        let suppliers = [];
        await res.data.forEach((item) => {
          let supplier = { ...item, label: item.label, value: item.value };
          suppliers.push(supplier);
        });
        await this.setState({ suppliers });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
    getList("brands")
      .then(async (res) => {
        let brands = [];
        await res.data.forEach((item) => {
          let brand = { ...item, label: item.name, value: item.id };
          brands.push(brand);
        });

        await this.setState({ brands });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });

    getList("attribute_sets")
      .then((res) => {
        if (res.status === 200) {
          let attrSets = [];
          res.data.map((attr) => {
            let attrSet = { ...attr, value: attr.id, label: attr.title };
            // attrSet['id'] = attr.id;
            // attrSet['label'] = attr.title;
            attrSets.push(attrSet);
          });
          this.setState({ attributeSet: attrSets });
        }
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  handleBundleVariations = (data) => {
    let product = { ...this.state.product };
    product = { ...product, bundle_variants: data };
    this.setState({ product }, () => {});
  };

  handleOnInputChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    if (name == "business_type") return;
    let product = { ...this.state.product };
    if (name === "sku_type") {
      if (val === "simple_product" || val === "bundle_product") {
        product = {
          ...product,
          variants_attributes: [
            {
              primary: true,
              price_consumer: "",
              price_retailer: "",
              price_distribution: "",
              discount_type: "percentage",
            },
          ],
          product_attribute_images_attributes: [],
        };
        this.setState({ selectedAttributeSet: {}, attributeValues: [] });
      } else {
        product = { ...product, variants_attributes: [] };
      }
    }
    product = { ...product, [name]: val };
    this.setState({ product }, () => {
      if (name === "title") {
        const slug = convertToSlug(this.state.product.title);
        this.setState({ product: { ...product, slug } });
      } else if (name === "slug") {
        const slug = convertToSlug(this.state.product.slug);
        this.setState({ product: { ...product, slug } });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { product } = this.state;
    if (prevState.product?.slug !== product?.slug) {
      if (product?.slug) {
        getData("slugs/uniqueness", { slug: product.slug, type: "product" })
          .then((res) => this.setState({ uniqueSlug: res.success }))
          .catch((err) => this.setState({ uniqueSlug: "" }));
      } else {
        this.setState({ uniqueSlug: "" });
      }
    }
  }

  handleProductTypeInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let product = { ...this.state.product };
    let productTypeArr = product.product_type_ids;
    if (target.checked) {
      productTypeArr.push(value);
    } else {
      productTypeArr = productTypeArr.filter(function (e) {
        return e != value;
      });
    }
    product.product_type_ids = productTypeArr;
    this.setState({ product });
  };

  handleAttributeSetChange = (e) => {
    const attributeSet = [...this.state.attributeSet];
    const selectedAttributeSet = attributeSet.find((item) => {
      return item.id === e.id;
    });
    this.setState({ selectedAttributeSet });
    let product = { ...this.state.product };

    if (product.attribute_set_id !== e.id) {
      product.selectedAttributeSet = {};
    }
    product = { ...product, attribute_set_id: e.id };
    this.setState({ product });
  };

  handleAttributeChange = (e) => {
    const product_attribute =
      this.state.selectedAttributeSet?.product_attributes?.find(
        (item) => item.id == e.target.value
      );
    const product = {
      ...this.state.product,
      image_attribute_id: e.target.value,
      product_attribute_images_attributes: [],
    };
    const attributeValues = product_attribute?.product_attribute_values;
    this.setState({ product, attributeValues });
  };

  handleJoEditorOnChange = (name, data) => {
    const product = { ...this.state.product, [name]: data };
    this.setState({ product });
  };

  onConfirmCategory = async (selectedCategories) => {
    const lastCat = selectedCategories[selectedCategories.length - 1];
    const selectedCatIds = selectedCategories?.map((item) => {
      return item.id;
    });
    const product = {
      ...this.state.product,
      category_ids: selectedCatIds,
      leaf_category_id: lastCat.id,
    };
    this.setState({ product });
    if (lastCat?.sub_categories?.length > 0) {
      this.setState({ notLastChild: true });
    } else {
      this.setState({ notLastChild: false });
    }
  };

  handleOnChangeKeywordValue = (en_bn, value) => {
    const product = { ...this.state.product };
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach((val) => {
        valueIdArr.push(val.value);
      });
    }
    if (en_bn === "en") {
      product.meta_keyword = valueIdArr;
    } else {
      product.bn_meta_keyword = valueIdArr;
    }
    this.setState({ product }, () => {});
  };

  handleReactSelectOnChange = (name, option) => {
    const product = { ...this.state.product };
    product[name] = option.value;
    this.setState({ product, selectedBrand: option }, () => {});
  };
  handleCompanySelectOnChange = (name, option) => {
    const product = { ...this.state.product };
    product[name] = option.value;
    this.setState({ product, selectedCompany: option }, () => {});
  };
  handleSupplierSelectOnChange = (name, option) => {
    this.setState(
      { supplier_id: option.value, selectedSupplier: option },
      () => {}
    );
  };

  handleImages = (name, files, multiple) => {
    const product = { ...this.state.product };
    let fff = [];
    for (let i = 0; i < files.length; i++) {
      fff.push(files[i]);
    }
    product[name] = multiple ? fff : fff[0];
    this.setState({ product });
  };

  addOneKeyFeature = () => {
    let { product } = this.state;
    product.product_features_attributes.push({
      title: "",
      bn_title: "",
      description: "",
      bn_description: "",
    });
    this.setState({ product });
  };

  handleKeyFeature = (e, index, prop) => {
    let { product } = this.state;
    product.product_features_attributes[index][prop] = e.target.value;
    this.setState({ product });
  };

  removeKeyFeature = (index) => {
    let { product } = this.state;
    product.product_features_attributes.splice(index, 1);
    this.setState({ product });
  };

  getEffectiveMRP = (discount_type, discountAmount, price) => {
    return Math.round(
      discount_type === "percentage"
        ? price - (discountAmount / 100) * price
        : price - discountAmount
    );
  };

  handleSKUInputOnChange = (e, index) => {
    const variants_attributes = [...this.state.product.variants_attributes];
    variants_attributes[index][e.target.name] = e.target.value;
    switch (e.target.name) {
      case "price_consumer":
        if (variants_attributes[index].consumer_discount) {
          variants_attributes[index].effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.discount_type,
            variants_attributes[index].consumer_discount,
            e.target.value
          );
        } else {
          variants_attributes[index].effective_mrp = e.target.value;
        }
        break;
      case "consumer_discount":
        if (variants_attributes[index].price_consumer) {
          variants_attributes[index].effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.discount_type,
            e.target.value,
            variants_attributes[index].price_consumer
          );
        } else {
          variants_attributes[index].effective_mrp = 0;
        }
        break;
      case "discount_type":
        if (
          variants_attributes[index].price_consumer &&
          variants_attributes[index].consumer_discount
        ) {
          variants_attributes[index].effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.discount_type,
            variants_attributes[index].consumer_discount,
            variants_attributes[index].price_consumer
          );
        } else {
          variants_attributes[index].effective_mrp = 0;
        }
        break;
      case "b2b_discount_type":
        debugger;
        if (
          variants_attributes[index].b2b_price &&
          variants_attributes[index].b2b_discount
        ) {
          variants_attributes[index].b2b_effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.b2b_discount_type,
            variants_attributes[index].b2b_discount,
            variants_attributes[index].b2b_price
          );
        } else {
          variants_attributes[index].b2b_effective_mrp = 0;
        }
        break;
      case "b2b_price":
        if (variants_attributes[index].b2b_discount) {
          variants_attributes[index].b2b_effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.b2b_discount_type,
            variants_attributes[index].b2b_discount,
            e.target.value
          );
        } else {
          variants_attributes[index].b2b_effective_mrp = e.target.value;
        }
        break;
      case "b2b_discount":
        if (variants_attributes[index].b2b_price) {
          variants_attributes[index].b2b_effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.b2b_discount_type,
            e.target.value,
            variants_attributes[index].b2b_price
          );
        } else {
          variants_attributes[index].b2b_effective_mrp = 0;
        }
        break;
      default:
        break;
    }

    const product = { ...this.state.product, variants_attributes };
    this.setState({ product });
  };

  handleAttributeImageChange = (attrValueId, files) => {
    const product = { ...this.state.product };
    let fff = [];
    for (let i = 0; i < files.length; i++) {
      fff.push(files[i]);
    }
    let existingAttrImageIndex =
      product.product_attribute_images_attributes.findIndex(
        (item) => item.product_attribute_value_id == attrValueId
      );
    if (existingAttrImageIndex < 0) {
      product.product_attribute_images_attributes.push({
        product_attribute_value_id: attrValueId,
        is_default: false,
        images_file: fff,
      });
    } else {
      product.product_attribute_images_attributes[existingAttrImageIndex] = {
        product_attribute_value_id: attrValueId,
        is_default: false,
        images_file: fff,
      };
    }
    this.setState({ product });
  };

  handleCloseVariationModal = () => {
    this.setState({
      isShowVariationModal: false,
      updateVariantFor: null,
      product_attribute_value_ids: [],
    });
  };

  handleShowVariationModal = () => {
    this.setState({
      isShowVariationModal: true,
      editSKUIndex: null,
      variant_attributeValues: [],
    });
  };

  handleEditVariantModal = (index) => {
    this.setState({
      updateVariantFor: index,
      isShowVariationModal: true,
      variant_attributeValues: [],
    });
  };

  handleComboOnChange = (e, index, attrValue) => {
    let selectedAttrValue = attrValue?.product_attribute_values?.find(
      (i) => i.id == e.target.value
    );
    let variant_attributeValues = [...this.state.variant_attributeValues];
    // let variants_attributes = product.variants_attributes
    variant_attributeValues[index] = selectedAttrValue; //attrValue?.product_attribute_values[index]
    // product = {...product, variants_attributes};

    this.setState({ variant_attributeValues });
  };

  handleAddVariation = () => {
    if (
      this.state.variant_attributeValues?.filter((item) => item?.id > 0)
        .length < this.state.selectedAttributeSet?.product_attributes.length
    ) {
      toast.error("Please choose a combination");
      return;
    }

    let variants_attributes = [...this.state.product.variants_attributes];
    let variantAttrIds = variants_attributes.map(
      (i) => i.product_attribute_value_ids
    );
    let attrValueIds = this.state.variant_attributeValues.map((i) => i.id);
    if (
      variants_attributes?.length > 0 &&
      variantAttrIds.some((i) => difference(i, attrValueIds).length == 0)
    ) {
      toast.error("The combination is already exists");
      return;
    }

    let copy_variant_attribute_values = [
      ...this.state.copy_variant_attribute_values,
    ];
    copy_variant_attribute_values.push(this.state.variant_attributeValues);
    variants_attributes.push({
      discount_type: "percentage",
      product_attribute_value_ids: this.state.variant_attributeValues.map(
        (item) => {
          return item.id;
        }
      ),
    });
    const product = { ...this.state.product, variants_attributes };
    this.setState({
      product,
      copy_variant_attribute_values,
      variant_attributeValues: [],
    });
    this.handleCloseVariationModal();
  };

  handleUpdateVariation = () => {
    if (
      this.state.variant_attributeValues?.filter((item) => item?.id > 0)
        .length < this.state.selectedAttributeSet?.product_attributes.length
    ) {
      toast.error("Please choose a combination");
      return;
    }
    let variants_attributes = [...this.state.product.variants_attributes];
    let variantAttrIds = variants_attributes.map(
      (i) => i.product_attribute_value_ids
    );
    let attrValueIds = this.state.variant_attributeValues.map((i) => i.id);
    if (
      variants_attributes?.length > 0 &&
      variantAttrIds.some((i) => difference(i, attrValueIds).length == 0)
    ) {
      toast.error("The combination is already exists");
      return;
    }
    const product = { ...this.state.product };
    let copy_variant_attribute_values = [
      ...this.state.copy_variant_attribute_values,
    ];
    copy_variant_attribute_values[this.state.updateVariantFor] =
      this.state.variant_attributeValues;
    product.variants_attributes[
      this.state.updateVariantFor
    ].product_attribute_value_ids = this.state.variant_attributeValues.map(
      (item) => {
        return item.id;
      }
    );
    this.setState({
      product,
      updateVariantFor: null,
      copy_variant_attribute_values,
    });
    this.handleCloseVariationModal();
  };

  handleDeleteSku = (index) => {
    const product = { ...this.state.product };
    let variants_attributes = product?.variants_attributes;
    variants_attributes = variants_attributes?.filter((i, j) => j !== index);
    product.variants_attributes = variants_attributes;
    let copy_variant_attribute_values = [
      ...this.state.copy_variant_attribute_values,
    ];
    copy_variant_attribute_values = copy_variant_attribute_values?.filter(
      (i, j) => j !== index
    );
    this.setState({ product, copy_variant_attribute_values });
  };

  handleAddProduct = async (e) => {
    e.preventDefault();
    const productData = { ...this.state.product };
    this.setState({ product: productData });

    const form = e.currentTarget;
    const body = {
      product: productData,
      supplier_id: this.state.supplier_id,
    };
    if (
      form.checkValidity() === false ||
      this.state.notLastChild ||
      !this.state.product.brand_id ||
      !this.state.product.company_id
    ) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({ isFormValidated: true });
    } else {
      if (
        this.state.product?.sku_type === "bundle_product" &&
        this.state.product?.bundle_variants.length === 0
      ) {
        toast.error("Please select atleast one SKU for Bundle Product");
      } else {
        this.setState({ isSubmitting: true }, () => {
          addProduct(body)
            .then((res) => {
              if (res?.status_code === 201) {
                toast.success("Product has been added successfully.");
                this.props.history.push(`/product/list`);
              } else {
                toast.error(res.message);
                this.setState({ isSubmitting: false });
              }
            })
            .catch((errMsg) => {
              toast.error(errMsg);
              this.setState({ isSubmitting: false });
            });
        });
      }
    }
  };

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Product</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/product/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Product List
            </Link>
          </div>
        </div>

        <div className="main-content">
          <Form
            noValidate
            validated={this.state.isFormValidated}
            onSubmit={this.handleAddProduct}
          >
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">What You're Selling</h4>
              </div>

              <div className="block-body">
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Title <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      type="text"
                      required
                      onChange={(e) => {
                        this.handleOnInputChange(e);
                      }}
                      name="title"
                      placeholder=""
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid product title.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Slug <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      type="text"
                      required
                      onChange={(e) => {
                        this.handleOnInputChange(e);
                      }}
                      name="slug"
                      value={this.state.product?.slug}
                      className={
                        this.state.uniqueSlug === ""
                          ? ""
                          : [
                              this.state.uniqueSlug
                                ? "slug-is-valid"
                                : "slug-is-invalid",
                            ]
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a slug.
                    </Form.Control.Feedback>
                    {this.state.uniqueSlug === false && (
                      <div class="slug-err-msg">
                        Please provide an unique slug.
                      </div>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Title in Bangla
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        this.handleOnInputChange(e);
                      }}
                      name="bn_title"
                      placeholder=""
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid product title in Bangla.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Company <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Select
                      value={this.state.selectedCompany}
                      styles={styleReactSelect}
                      placeholder="Select Company..."
                      onChange={(opt) =>
                        this.handleCompanySelectOnChange("company_id", opt)
                      }
                      options={this.state.companies}
                      name="company_id"
                      required={true}
                    ></Select>
                    <Form.Control.Feedback
                      type="invalid"
                      className={`company ${
                        this.state.isFormValidated &&
                        !this.state.product.company_id
                          ? "d-block"
                          : ""
                      }`}
                    >
                      Company is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {/* //add supplier */}
                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Supplier<span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Select
                      value={this.state.selectedSupplier}
                      styles={styleReactSelect}
                      placeholder="Select Supplier..."
                      options={this.state.suppliers}
                      onChange={(opt) =>
                        this.handleSupplierSelectOnChange("supplier_id", opt)
                      }
                      name="supplier_id"
                      required={true}
                    ></Select>
                    <Form.Control.Feedback
                      type="invalid"
                      className={`supplier ${
                        this.state.isFormValidated && !this.state.supplier_id
                          ? "d-block"
                          : ""
                      }`}
                    >
                      Supplier is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formCategory">
                  <Form.Label column sm="3">
                    Category <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <ChooseCategory onConfirm={this.onConfirmCategory} />
                    {this.state.notLastChild && (
                      <span className="text-danger">
                        {" "}
                        Please select a sub-category.{" "}
                      </span>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Weight To set Priority
                  </Form.Label>
                  <Col sm="4" md={4} lg={4}>
                    <Form.Control
                      name="weight"
                      size={"sm"}
                      min="0"
                      step="1"
                      defaultValue={0}
                      onChange={this.handleOnInputChange}
                      type={"number"}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please set weight to the product
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formWarrantyPeriod">
                  <Form.Label column sm="3">
                    Publish
                  </Form.Label>

                  <Col sm="4" md={4} lg={4}>
                    <Form.Control
                      name="public_visibility"
                      onChange={this.handleOnInputChange}
                      as="select"
                      type="text"
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </div>
            </div>

            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Basic Information</h4>
              </div>

              <div className="block-body">
                <Form.Group as={Row} controlId="formHighlight">
                  <Form.Label column sm="3">
                    Short Description
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <JoditEditorComponent
                      tabIndex={122}
                      onChange={(value) => {
                        this.handleJoEditorOnChange("short_description", value);
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHighlight">
                  <Form.Label column sm="3">
                    Short Description in Bangla
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <JoditEditorComponent
                      tabIndex={2}
                      onChange={(value) => {
                        this.handleJoEditorOnChange(
                          "bn_short_description",
                          value
                        );
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="productDescription">
                  <Form.Label column sm="3">
                    Specification
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <JoditEditorComponent
                      tabIndex={3}
                      onChange={(value) => {
                        this.handleJoEditorOnChange("description", value);
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="productDescription">
                  <Form.Label column sm="3">
                    Specification in Bangla
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <JoditEditorComponent
                      tabIndex={4}
                      onChange={(value) => {
                        this.handleJoEditorOnChange("bn_description", value);
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Dangerous Goods
                  </Form.Label>

                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name="dangerous_goods"
                      onChange={this.handleOnInputChange}
                      type={"text"}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Product Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        required
                        name="product_type"
                        onChange={this.handleOnInputChange}
                        type="text"
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Value is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Brand <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Select
                      value={this.state.selectedBrand}
                      styles={styleReactSelect}
                      placeholder="Select Brand..."
                      onChange={(opt) =>
                        this.handleReactSelectOnChange("brand_id", opt)
                      }
                      options={this.state.brands}
                      name="brand_id"
                      required={true}
                    ></Select>
                    <Form.Control.Feedback
                      type="invalid"
                      className={`brand ${
                        this.state.isFormValidated &&
                        !this.state.product.brand_id
                          ? "d-block"
                          : ""
                      }`}
                    >
                      Brand is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Order Limit
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        type="number"
                        min={1}
                        pattern={"^+?(0|[1-9]d*)$"}
                        name="max_quantity_per_order"
                        onChange={this.handleOnInputChange}
                      />
                    </div>
                  </Col>
                </Form.Group>
              </div>
            </div>

            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Gallery</h4>
              </div>
              <div className="block-body">
                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Hero Image <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseButton
                      multiple={false}
                      // onChange={e => {
                      //     this.setState({hero_image_file: e.target.files[0]})
                      // }}
                      onChange={(e) =>
                        this.handleImages(
                          "hero_image_file",
                          e.target.files,
                          false
                        )
                      }
                      id={"heroImage"}
                      name={"hero_image_file"}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Product Images
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseButton
                      multiple
                      onChange={(e) =>
                        this.handleImages("images_file", e.target.files, true)
                      }
                      id={"mainImage"}
                      name={"images_file"}
                    />
                  </Col>
                </Form.Group>
              </div>
            </div>

            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">
                  {this.state.product.sku_type === "simple_product"
                    ? "SKU Information"
                    : [
                        this.state.product.sku_type === "bundle_product"
                          ? "Bundle Information"
                          : "Variants",
                      ]}
                </h4>
              </div>

              <div className="block-body">
                {this.state.product?.sku_type !== "bundle_product" &&
                  this.state.product &&
                  this.state.product?.variants_attributes?.map((variant, i) => {
                    return (
                      <VariantForm
                        handleSKUInputOnChange={this.handleSKUInputOnChange}
                        variant={variant}
                        position={i}
                        header={false}
                        attributeValues={
                          this.state.copy_variant_attribute_values[i]
                        }
                        handleEditSKUInfo={this.handleEditVariantModal}
                        handleDeleteSku={this.handleDeleteSku}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="card-block">
              <div className="block-body text-right">
                <Link
                  to={`/product/list`}
                  className={`btn btn-lg btn-default mr-2`}
                >
                  Cancel
                </Link>
                <Button
                  size="lg"
                  type={`submit`}
                  disabled={
                    this.state.isSubmitting || this.state.uniqueSlug === false
                  }
                  variant="primary"
                >
                  {this.state.isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </App>
    );
  }
}

export default withRouter(AddProduct);
