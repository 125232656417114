import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {FaLongArrowAltLeft} from "react-icons/all";
import {add} from "../../services/baseServices";
import RouteForm from "../route/Form";

class Add extends Component {
  constructor() {
    super();

    this.handleAddRoute = this.handleAddRoute.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
  }
  state = {
    formData: {},
    routeInfo: {},
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
    });
  }

  handleAddRoute = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        let formData = {...this.state.formData}
        const phone = formData["phone"].length === 14 ? formData["phone"].substring(3) : formData["phone"];
        formData = {...formData, phone};

        add("routes","route", formData)
          .then(res => {
            if(res.status_code  === 200 || res.status_code === 201) {
              this.props.history.push(`/route/list`);
              toast.success(res.message);
            }else{
              toast.error(res.message);
              this.setState({isSubmitting: false})
            }
            
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }
  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Delivery Man</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/route/list`}
              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Delivery Man List</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <RouteForm
                handleClick={this.handleAddRoute}
                handleInputOnChange={this.handleInputOnChange}
                routeInfo={this.state.routeInfo}
                formData={this.state.formData}
                isSubmitting={this.state.isSubmitting}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Add);