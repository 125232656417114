import React, { Component } from "react";
import { Button } from "react-bootstrap";

class Variants extends Component {
  render() {
    const {
      selectedProducts,
      handleVariantQuantityChange,
      handleVariantRemove,
    } = this.props;

    const variants = selectedProducts.map((product) => {
      return (
        <>
          <div className="card-block">
            <div className="block-body">
              <>
                <div
                  className="variation-card"
                  style={{ borderBottom: "none" }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Quantity</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{product.bundle_sku}</td>
                        <td style={{ width: "10%" }}>
                          <input
                            name={product.bundle_sku}
                            className="form-control form-control-sm"
                            required
                            min="1"
                            type="number"
                            value={
                              product.quantity !== undefined
                                ? product.quantity
                                : ""
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            onChange={(e) =>
                              handleVariantQuantityChange(e, product.bundle_sku)
                            }
                            disabled={this.props.isBundleEdit}
                          />
                        </td>
                        <td style={{ width: "15%" }}>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={(e) =>
                              handleVariantRemove(e, product.bundle_sku)
                            }
                            disabled={this.props.isBundleEdit}
                          >
                            X
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            </div>
          </div>
        </>
      );
    });

    return (
      <div>
        {selectedProducts.length > 0 && variants}
      </div>
    );
  }
}

export default Variants;
