import React, {Component, useRef} from 'react';
import JoditEditor from "jodit-react";

class JoditEditorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 'content',
    }
  }

  updateContent = (value) => {
    this.setState({content: value})
  }

  /**
   * @property Jodit jodit instance of native Jodit
   */

  jodit;
  setRef = jodit => this.jodit = jodit;

  config = {
    readonly: false,
    height: 300,
    uploader: {
      insertImageAsBase64URI: true,
      defaultHandlerSuccess: function (response) {
        if (response.files && response.files.length) {
          for (let i = 0; i < response.files.length; i++) {
            let full_file_path = response.files[i];
            this.selection.insertImage(full_file_path, null, 250)
          }
        }
      }
    }
  }

  render() {
    return (
      <JoditEditor
        ref={this.setRef}
        value={this.props.value || ''}
        config={this.config}
        tabIndex={this.props.tabIndex || 1}
        onBlur={newContent => {
          if (this.props.onBlur) this.props.onBlur(newContent)
        }}
        onChange={newContent => {
          if (this.props.onChange) this.props.onChange(newContent)
        }}
      />
    );
  }
}

export default JoditEditorComponent;