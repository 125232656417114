import React from 'react'
import PoDetails from './PoDetails'
import StoDetails from './StoDetails'
import { getWarehouseType } from "../../helper/utils";

const warehouseType = getWarehouseType();

const Details = () => {
  return (
    <div>
      {
        warehouseType === "central"? <PoDetails/>:<StoDetails/>
      }
    </div>
  )
}

export default Details
