import React, { Component } from 'react';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'react-js-pagination';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import App from '../../App';
import { destroy, getPaginatedList } from '../../services/baseServices';
import { FiEye, FiEdit, FiPlus } from 'react-icons/all';
import ConfirmationModal from "../common/Modal";
import {toast} from 'react-toastify';

class HelpTopicList extends Component {
    constructor() {
        super();
        this.state = {
            helpTopics: [],
            activePage: 1,
            per_page :25,
            page:1,
            isLoading: false,
            temp_params: {},
            query_params: {},
        };
    }

    componentDidMount() {
        this.getPaginatedHelpTopic(this.getQueryParams());
    }

    getQueryParams = () => {
        let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
        return params;
    }

    handlePageChange = (pageNumber) => {
      this.setState({page: pageNumber, activePage: pageNumber}, () => {
          this.getPaginatedHelpTopic(this.getQueryParams());
      });
  }

    getPaginatedHelpTopic = (params) => {
        this.setState({...this.state, isLoading: true});
        getPaginatedList("help_topics",params)
          .then(res => {
              if(res.data?.success === true){
                this.setState({
                  helpTopics: res.data.data, 
                    isLoading: false,
                    per_page: parseInt(res.headers['x-per-page']),
                    total_data: parseInt(res.headers['x-total']),
                  }, () => {
                  });
              }else{
                this.setState({...this.state, isLoading: false});
              }
          })
          .catch(errMsg => {
            this.setState({...this.state, isLoading: false});
            console.log('Error Message: ', errMsg)
          })
    }
    
    handleOnFieldChange = (e) => {
      const queryData = {...this.state.temp_params};
      queryData[e.target.name] = e.target.value;
      this.setState({temp_params: queryData}, () => {
      });
    }

    handleOnSearch = (e) => {
      e.preventDefault();
      const tempParam = this.state.temp_params;
        this.setState({page: 1, query_params: tempParam, isLoading:true}, () => {
          this.getPaginatedHelpTopic(this.state.temp_params);
        });
    }

    handleItemProduct = id => {
      destroy("help_topics", id)
        .then(res => {
          if(res.success){
            toast.success('Deleted successfully');
            this.getPaginatedHelpTopic(this.getQueryParams());
          } else {
            toast.error(res?.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg)
        })
    }

    render() {
        return (
            <App layout="">
          <div className="main-content">
            <div className="page-header">
              <h2 className="page-title">Help Topic List</h2>
              <div className="ml-auto">
                <Link to={`${process.env.PUBLIC_URL}/help-topic/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Help Topic</Link>
              </div>
            </div>

              <div className="card-block">
                <div className="block-body">
                  <Loader loaded={!this.state.isLoading}>
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th>SL No.</th>
                        <th>Title</th>
                        <th>BN Title</th>
                        <th>Public visibility</th>
                        <th>Article Count</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.helpTopics.length > 0 ? this.state.helpTopics.map((item, index) => {
                        return (
                            <tr key={item.id}>
                              <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                              <td>
                                <div className="order-item">
                                    {item.title}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.bn_title}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.public_visibility? "True": "False"}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.article_count}
                                </div>
                              </td>
                              <td style={{width: '150px'}}>
                                <div className="btn-group">
                                  <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          View Details
                                        </Tooltip>
                                      }
                                  >
                                  <Link to={`/help-topic/details/${item.id}`} className="btn btn-sm btn-default"><FiEye/></Link>
                                  </OverlayTrigger>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`${process.env.PUBLIC_URL}/help-topic/edit/${item.id}`}
                                        className="btn btn-sm btn-default"><FiEdit/></Link>
                                </OverlayTrigger>
                                {
                                  item.is_deletable &&
                                  <OverlayTrigger
                                        key="view"
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-view`}>
                                            Delete
                                          </Tooltip>
                                        }
                                      >
                                        <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() => this.handleItemProduct(item.id)}/>
                                  </OverlayTrigger>
                                }
                                </div>
                              </td>
                            </tr>
                        );
                      }) : ""}
                      </tbody>
                    </table>
                  </Loader>
                  </div>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
          
            </div>
          <ToastsContainer store={ToastsStore}/>
        </App>
        );
    }
}

export default HelpTopicList;