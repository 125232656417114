import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DepositUpload from "../views/dhPurchaseOrder/DepositUpload";
import { getData2 } from "../services";
import { toast } from "react-toastify";

class Variants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balanceStatus: this.calculateStatus(
        props.walletBalance,
        props.totalVariantPrice
      ),
      updatedBalance: 0,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.walletBalance !== this.props.walletBalance ||
      prevProps.totalVariantPrice !== this.props.totalVariantPrice
    ) {
      const newStatus = this.calculateStatus(
        this.props.walletBalance,
        this.props.totalVariantPrice
      );
      this.setState({
        balanceStatus: newStatus,
        updatedBalance: Math.abs(
          this.props.walletBalance - this.props.totalVariantPrice
        ),
      });
    }
  }
  calculateStatus(walletBalance, totalVariantPrice) {
    return walletBalance - totalVariantPrice < 0 ? "Payable" : "Available";
  }

  render() {
    const {
      selectedProducts,
      isSubmitting,
      type,
      cancelUrl,
      handleVariantQuantityChange,
      handleVariantRemove,
      handlePurchaseOrder,
      productType,
    } = this.props;

    const variants = selectedProducts.map((product, index) => {
      return (
        <>
          <div className="card-block" key={index}>
            <div className="block-body">
              <>
                <div
                  className="variation-card"
                  style={{ borderBottom: "none" }}
                >
                  <div className="variation-header v-middle">
                    <div className="d-flex v-middle">
                      <h4 className="variation-title mb-0 nowrap">
                        <span>
                          {product.product_title
                            ? product.product_title
                            : "N/A"}
                        </span>
                        <span> - </span>
                        <span>
                          {type === "purchaseOrder" &&
                          product.product_attribute_values.length > 0
                            ? product.product_attribute_values.map(
                                (val, index) => {
                                  let dash =
                                    index <
                                    product.product_attribute_values.length - 1
                                      ? "-"
                                      : "";
                                  return val.value + dash;
                                }
                              )
                            : ""}
                          (<small>{product.sku}</small>)
                        </span>
                      </h4>
                    </div>
                  </div>

                  <table className="table">
                    {type === "purchaseOrder" ? (
                      <thead>
                        <tr>
                          <th>Supplier</th>
                          <th>Price (BDT)</th>
                          <th>Quantity</th>
                          <th>Total price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    ) : (
                      <thead>
                        <tr>
                          <th>Price (BDT)</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                          <th>CWH Stock</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    )}

                    <tbody>
                      {type === "purchaseOrder" &&
                        product.suppliers_variants.map((val, i) => {
                          return (
                            <tr>
                              <td style={{ width: "25%" }}>
                                <strong className="nowrap">
                                  {val.supplier_name
                                    ? val.supplier_name
                                    : "N/A"}
                                </strong>
                              </td>
                              <td style={{ width: "20%" }}>
                                <Form.Control
                                  name="special_price"
                                  size={"sm"}
                                  step="0.01"
                                  className="mr-3"
                                  value={val.supplier_price}
                                  disabled={true}
                                  readOnly
                                  type={"text"}
                                />
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  name={val.supplier_name}
                                  className="form-control form-control-sm"
                                  required
                                  min="1"
                                  type="number"
                                  value={
                                    val.quantity !== undefined
                                      ? val.quantity
                                      : ""
                                  }
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }}
                                  onChange={(e) =>
                                    handleVariantQuantityChange(
                                      e,
                                      val,
                                      val.variant_id
                                    )
                                  }
                                />
                              </td>

                              <td style={{ width: "20%" }}>
                                {val.totalPrice !== undefined
                                  ? val.totalPrice
                                  : "_"}
                              </td>
                              <td style={{ width: "15%" }}>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={(e) =>
                                    handleVariantRemove(e, val.id, product.id)
                                  }
                                >
                                  X
                                </Button>
                              </td>
                            </tr>
                          );
                        })}

                      {type === "dhPurchaseOrder" && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <Form.Control
                              name="special_price"
                              size={"sm"}
                              step="0.01"
                              className="mr-3"
                              value={product.price_distribution}
                              readOnly
                              autoComplete="off"
                              type={"text"}
                            />
                          </td>
                          <td style={{ width: "25%" }}>
                            <input
                              name="quantity"
                              className="form-control form-control-sm"
                              autoComplete="off"
                              required
                              min="1"
                              type="number"
                              value={
                                product.quantity !== undefined
                                  ? product.quantity
                                  : ""
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }
                              }}
                              onChange={(e) =>
                                handleVariantQuantityChange(
                                  e,
                                  product,
                                  product.id,
                                  product.product_id
                                )
                              }
                            />
                          </td>

                          <td style={{ width: "25%" }}>
                            {product.totalPrice !== undefined
                              ? product.totalPrice
                              : "_"}
                          </td>
                          <td style={{ width: "25%" }}>
                            {product?.stock_available_quantity !== undefined
                              ? product.stock_available_quantity
                              : "_"}
                          </td>
                          <td style={{ width: "25%" }}>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={(e) =>
                                handleVariantRemove(e, product.id)
                              }
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
          </div>
        </>
      );
    });

    return (
      <div>
        {selectedProducts.length > 0 && (
          <Form onSubmit={handlePurchaseOrder}>
            {variants}
            <div className="card-block">
              {this.props?.showTotalRow && (
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Total Quantity</th>
                      <th>Total Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "25%" }}>
                        <h6>Total</h6>
                      </td>
                      <td style={{ width: "25%" }}>
                        <h6>{this.props.totalVariantQuantity}</h6>
                      </td>
                      <td style={{ width: "25%" }}>
                        <h6>{this.props?.totalVariantPrice}</h6>
                      </td>
                      <td style={{ width: "25%" }}></td>
                    </tr>
                    {cancelUrl === "dhPurchaseOrder" ? (
                      <>
                        <tr>
                          <td style={{ width: "25%" }}>
                            <h6>Wallet Credit</h6>
                          </td>
                          <td style={{ width: "25%" }}>
                            <h6>-</h6>
                          </td>
                          <td style={{ width: "25%" }}>
                            <h6>{this.props.walletBalance}</h6>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#f2f2f2",
                            marginLeft: "5px",
                            paddingLeft: "5px",
                          }}
                        >
                          <td style={{ width: "25%", alignItems: "right" }}>
                            <h6>{this.state.balanceStatus}</h6>
                          </td>
                          <td style={{ width: "25%" }}>
                            <h6> </h6>
                          </td>
                          <td style={{ width: "25%" }}>
                            <h6>{this.state.updatedBalance}</h6>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              )}
            </div>
            {cancelUrl === "dhPurchaseOrder" ? (
              <div>
                <DepositUpload
                  handleInputChange={this.props.handleInputChangeDepositUpload}
                  onFileChange={this.props.onFileChangeDepositUpload}
                />
              </div>
            ) : null}
            <div className="card-block">
              <div className="block-body d-flex">
                <div className="ml-auto">
                  <Link
                    className="btn btn-lg btn-default mr-2"
                    to={`/${cancelUrl}/list`}
                  >
                    Cancel
                  </Link>

                  <Button
                    size="lg"
                    type={`submit`}
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default Variants;
