import React, {useEffect} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const DistrictForm = ({district, onFormSubmit,}) => {
    const schema = yup.object().shape({
        name: yup.string().trim().required("Title is a required"),
        bn_name: yup.string().trim().required("Bn Title is a required"),
      });

    const { reset, register, handleSubmit, control, formState:{ errors }, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        // defaultValues: {
        //     public_visibility: true,
        //   }
      });


      useEffect(()=>{
        reset({
            name: district.name,
            bn_name: district.bn_name
        })
      },[district])

      
      const onSubmit = data => {
        onFormSubmit(data)
        // console.log(data)
    };
    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            >
            <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3">
                    Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        {...register("name")}
                        type="text"
                        className={errors.name ? 'is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.name?.message}
                     </Form.Control.Feedback>
                 
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3">
                    BN Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                    <Form.Control
                        {...register("bn_name")}
                        type="text"
                        className={errors.bn_name ? 'is-invalid' : ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.bn_name?.message}
                     </Form.Control.Feedback>
                 
                </Col>
            </Form.Group>

            <div className="mt-3 text-center">
                <Link
                    type="button"
                    className="btn btn-sm btn-btn-sm mr-2"
                    to={`${process.env.PUBLIC_URL}/config/districts/list`}
                    >
                <Button type="button" variant="danger">
                    Cancel
                </Button>
                </Link>
                <Button
                    size="btn-sm"
                    type={"submit"}
                    variant="primary"
                    >
                    Submit
                </Button>
            </div>
            </Form>
    )
}

export default DistrictForm
