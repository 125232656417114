import React,{useState, useEffect} from "react";
import { Button, Modal} from "react-bootstrap";
import {putData} from '../../services/baseServices'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";


const schema = yup.object().shape({
  boxitem: yup.string().required("Please Select a box"),
});

const BoxModal = ({
  show,
  handleClose,
  existingBoxes,
  currentBox,
  dh_po_id,
  lineItemId,
  type,
  getPurchaseOrder
}) => {
  const [unpackedBoxes, setUnpackedBoxes] = useState([])
  useEffect(()=>{
      const boxes = existingBoxes?.filter(item =>(
        item.status === 'unpacked'
      ))
      setUnpackedBoxes(boxes)
  },[existingBoxes])


  const {register,handleSubmit,formState: { errors },} = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let lineItems = []
    if(Array.isArray(lineItemId)){
      lineItems.push(...lineItemId)
    }else{
      lineItems.push(lineItemId)
    }

    const param ={
      rt_order_id: dh_po_id,  
      box_id: +data.boxitem,
      line_item_ids: lineItems
  
    }

    putData('return_transfer_orders/boxes/move', param)
    .then(res=>{
      if(res.status_code === 200){
        toast.success(res.message)
        handleClose()
        getPurchaseOrder()

      }else{
        toast.error(res.message)
      }
    })
    .catch(err=>{
      toast.error(err)
    })
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                type === 'Ch_BoxingItem'?(`Box ID: #${currentBox.id}`):'Available Box List'              
              }             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-4">
            <p>Please Select a box where want to move to the line item</p>
            <div>
              <select {...register("boxitem")} className="form-control">
                <option value="">Please Select a Box</option>
                {unpackedBoxes?.map((item) => (
                  <option value={item.id} key={item.id}>
                    BOX ID #{item.id}
                  </option>
                ))}
              </select>
              <p className="text-danger">{errors.boxitem?.message}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Move
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default BoxModal;
