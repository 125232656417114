import React, { Component } from "react";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { getCategoryDetails } from "../../services/categoryServices";
import { getData, serializeAndUpdate } from "../../services/baseServices";
import BrowseButton from "../../components/BrowseButton";
import { FaLongArrowAltLeft } from "react-icons/all";
import ChooseCategory from "../../components/ChooseCategory";
import { convertToSlug } from "./../../helper/utils";
import MetaInfo from "../../components/MetaInfo";

class Add extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      categoryInfo: {},
      formData: { image_file: "", banner_image_file: "" },
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
      parent_ids: [],
      metaInfo: {},
      uniqueSlug: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { formData } = this.state;
    console.log(prevState);
    if (prevState.formData?.slug !== formData?.slug) {
      if (formData?.slug) {
        getData("slugs/uniqueness", {
          slug: formData.slug,
          id: this.props.match.params.id,
          type: "Category",
        })
          .then((res) => this.setState({ uniqueSlug: res.success }))
          .catch((err) => this.setState({ uniqueSlug: "" }));
      } else {
        this.setState({ uniqueSlug: "" });
      }
    }
  }

  componentDidMount() {
    getCategoryDetails(this.props.match.params.id)
      .then(async (res) => {
        let catArr = [];
        if (res.parent_category !== null) {
          catArr = await this.getParentcat(res.parent_category, catArr);
        }
        let formData = { ...this.state.formData };
        formData.title = res?.title;
        formData.position = res?.position;
        formData.bn_title = res?.bn_title;
        formData.slug = res?.slug;
        formData.business_type = res?.business_type;
        this.setState(
          {
            formData,
            categoryInfo: res,
            parent_ids: catArr.reverse(),
          },
          () => {
            console.log(this.state);
          }
        );
        const metaData = {
          metaTitle: res?.meta_info?.meta_title,
          metaTitleBn: res?.meta_info?.bn_meta_title,
          metaDescription: res?.meta_info?.meta_description,
          metaDescriptionBn: res?.meta_info?.bn_meta_description,
          metaKeywords: res?.meta_info?.meta_keyword,
          metaKeyKeywordsBn: res?.meta_info?.bn_meta_keyword,
        };
        this.setState({ metaInfo: metaData });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  getParentcat = (obj, catArr) => {
    if (obj.parent_category !== null) {
      catArr.push(obj.id);
      this.getParentcat(obj.parent_category, catArr);
    } else {
      catArr.push(obj.id);
    }
    return catArr;
  };

  handleInputOnChange = (e) => {
    const formData = { ...this.state.formData };
    if (e.target.name === "slug") {
      const slug = convertToSlug(e.target.value);
      formData["slug"] = slug;
    } else formData[e.target.name] = e.target.value;
    this.setState({ formData }, () => {
      console.log(this.state.formData);
    });
  };

  handleOnMetaInputChange = (e) => {
    const metainfo = { ...this.state.metaInfo };
    const name = e.target.name;
    const value = e.target.value;
    const updatedMetaInfo = { ...metainfo, [name]: value };
    this.setState({ metaInfo: updatedMetaInfo });
  };
  handleOnChangeKeywordValue = (en_bn, value) => {
    const mitainfoDetails = { ...this.state.metaInfo };
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach((val) => {
        valueIdArr.push(val.value);
      });
    }
    if (en_bn === "en") {
      mitainfoDetails.metaKeywords = valueIdArr;
    } else {
      mitainfoDetails.metaKeyKeywordsBn = valueIdArr;
    }
    this.setState({ metaInfo: mitainfoDetails });
  };

  handleUpdateCategory = (e) => {
    e.preventDefault();
    const formInfo = { ...this.state.formData };
    const metaData = { ...this.state.metaInfo };
    const meta_datum_attributes = {
      meta_title: metaData.metaTitle,
      bn_meta_title: metaData.metaTitleBn,
      meta_description: metaData.metaDescription,
      bn_meta_description: metaData.metaDescriptionBn,
      meta_keyword: metaData.metaKeywords,
      bn_meta_keyword: metaData.metaKeyKeywordsBn,
    };
    const updatedFormInfo = { ...formInfo, meta_datum_attributes };
    this.setState({ formData: updatedFormInfo });
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        const formData = { ...this.state.formData };
        formData.home_page_visibility = 1;
        if (!formData.image_file) {
          delete formData.image_file;
        }
        if (!formData.banner_image_file) {
          delete formData.banner_image_file;
        }
        formData.parent_id = this.state.parent_ids.slice(-1)[0];
        // return;
        serializeAndUpdate(
          "categories",
          "category",
          this.props.match.params.id,
          formData
        )
          .then((res) => {
            if (res?.id) {
              this.props.history.push(`/category/list`);
              toast.success("Category has been updated successfully.");
            } else {
              toast.error(res?.message);
              this.setState({ isSubmitting: false });
            }
          })
          .catch((errMsg) => {
            toast.error(errMsg);
            this.setState({ isSubmitting: false });
          });
      });
    }
  };

  onFileChange = (event, name) => {
    const formData = { ...this.state.formData };
    formData[name] = event.target.files[0];
    this.setState({ formData });
  };

  getSubcat = (category, categories) => {
    let cat = categories;
    category.forEach((item) => {
      cat.push(item);
      if (item?.sub_categories?.length > 0) {
        this.getSubcat(item?.sub_categories, categories);
      }
    });
    return cat;
  };

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Category</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/category/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Category List
            </Link>
          </div>
        </div>

        <Form
          noValidate
          validated={this.state.isFormValidated}
          onSubmit={this.handleUpdateCategory}
        >
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Basic Info</h4>
            </div>
            <div className="block-body">
              <Form.Group as={Row} controlId="business_type">
                <Form.Label column sm="3">
                  Business Type <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    name="business_type"
                    id={`custom-radio-1`}
                    required
                    onChange={this.handleInputOnChange}
                    checked={this.state.formData.business_type === "b2c"}
                    value={"b2c"}
                    label={`B2C`}
                  />
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    name="business_type"
                    required
                    onChange={this.handleInputOnChange}
                    checked={this.state.formData.business_type === "b2b"}
                    value={"b2b"}
                    id={`custom-radio-4`}
                    label={`B2B`}
                  />
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    name="business_type"
                    required
                    onChange={this.handleInputOnChange}
                    checked={this.state.formData.business_type === "both"}
                    value={"both"}
                    id={`custom-radio-2`}
                    label={`Both(B2B and B2C)`}
                  />

                  <Form.Check
                    custom
                    inline
                    type="radio"
                    name="business_type"
                    required
                    onChange={this.handleInputOnChange}
                    checked={this.state.formData.business_type === "multicat"}
                    value={"multicat"}
                    id={`custom-radio-3`}
                    label={`Multicat`}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please select a Business type.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                  Category <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name={"title"}
                    defaultValue={this.state.formData?.title}
                    onChange={this.handleInputOnChange}
                    required
                    type="text"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please enter category.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                  Slug <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name={"slug"}
                    value={this.state.formData?.slug}
                    // disabled
                    // readOnly
                    onChange={this.handleInputOnChange}
                    required
                    type="text"
                  />
                  {/* <Form.Control.Feedback type="invalid">
                      Please enter slug.
                    </Form.Control.Feedback> */}
                  {this.state.uniqueSlug === false && (
                    <div class="slug-err-msg">
                      Please provide an unique slug.
                    </div>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                  Bangla <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    name={"bn_title"}
                    defaultValue={this.state.formData?.bn_title}
                    onChange={this.handleInputOnChange}
                    required
                    type="text"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please enter category in bangla.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formCategory">
                <Form.Label column sm="3">
                  Parent Category
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <ChooseCategory
                    onConfirm={async (attr) => {
                      let catArr = [];
                      await attr.map((item) => {
                        catArr.push(item.id);
                      });
                      this.setState({ parent_ids: catArr });
                    }}
                    selectedCategories={this.state.parent_ids}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="position">
                <Form.Label column sm="3">
                  Position <span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <Form.Control
                    required
                    name={"position"}
                    onChange={this.handleInputOnChange}
                    value={this.state.formData?.position}
                    pattern="\d*"
                    min={0}
                    type="number"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter position to show in ecom.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="3">
                  Image<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseButton
                    id={"image_file"}
                    name={"image_file"}
                    onChange={(e) => this.onFileChange(e, "image_file")}
                  />
                </Col>
              </Form.Group>
              {this.state.categoryInfo.image && (
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3"></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="existing-images">
                      <div className="existing-img">
                        <img src={this.state.categoryInfo.image} alt="" />
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} controlId="banner_image_file">
                <Form.Label column sm="3">
                  Banner Image<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="9" md={8} lg={7}>
                  <BrowseButton
                    id={"banner_image_file"}
                    name={"banner_image_file"}
                    onChange={(e) => this.onFileChange(e, "banner_image_file")}
                  />
                </Col>
              </Form.Group>
              {this.state.categoryInfo.banner_image && (
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3"></Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="existing-images">
                      <div className="existing-img">
                        <img
                          src={this.state.categoryInfo.banner_image}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              )}
            </div>
          </div>
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Meta Info</h4>
            </div>
            <div className="block-body">
              <MetaInfo
                metaInfo={this.state.metaInfo}
                handleOnInputChange={this.handleOnMetaInputChange}
                handleOnChangeKeywordValue={this.handleOnChangeKeywordValue}
              />
            </div>
          </div>
          <div className="mt-3 text-right">
            <Button
              size="lg"
              type={`submit`}
              disabled={
                this.state.isSubmitting || this.state.uniqueSlug === false
              }
              variant="primary"
            >
              {this.state.isSubmitting ? "Updating..." : "Update"}
            </Button>
          </div>
        </Form>
      </App>
    );
  }
}

export default withRouter(Add);
