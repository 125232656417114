import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";

class Edit extends Component {
  state = {
  };

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Role</h2>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Role <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter role.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Role in Bangla <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"bn_name"}
                      required
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter role in bangla.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div className="mt-3">
                  <Link to={`/role/list`}>
                    <Button size="lg" type={"submit"} disabled={this.state.isAdding} variant="primary">{this.state.isAdding ? 'Updating...' : 'Update'}</Button>
                  </Link>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);