import React, { Component } from "react";
import ModalForm from "../common/ModalFormClassComponent";
import { Form } from "react-bootstrap";
import { addV2 } from "../../services";
import { toast } from "react-toastify";
import { refreshPage } from "../../helper/utils";

class AddDepositForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deposit_no: "",
      deposit_slip: null,
      deposit_amount: "",
      sto_id: props.stoId,
      isSubmitting: false,
      getPurchaseOrder: props.getPurchaseOrder,
    };
  }

  handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      this.setState({ deposit_slip: files[0] });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("deposit_no", this.state.deposit_no);
    formData.append("deposit_slip", this.state.deposit_slip);
    formData.append("deposit_amount", this.state.deposit_amount);
    formData.append("sto_id", this.state.sto_id);

    addV2("dh_purchase_orders/add_deposit", formData)
      .then((res) => {
        if (res?.success) {
          this.props.toggleModalState();
          toast.success(`${res.message}`);
          this.state.getPurchaseOrder();
        } else {
          toast.error(res?.message || res?.error);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  render() {
    return (
      <ModalForm
        isModalOpen={this.props.isModalOpen}
        title="Add Deposit Info"
        handleClose={this.props.toggleModalState}
        handleSubmit={this.handleSubmit}
      >
        <>
          <Form.Group>
            <Form.Label htmlFor="deposit_amount">
              Deposit Amount <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              id="deposit_amount"
              name="deposit_amount"
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="deposit_no">
              Deposit NO. <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="deposit_no"
              name="deposit_no"
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="deposit_slip">
              Attach Deposit Slip <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="file"
              id="deposit_slip"
              name="deposit_slip"
              onChange={this.handleInputChange}
              accept=".pdf, .jpg, .png"
              required
            />
          </Form.Group>
        </>
      </ModalForm>
    );
  }
}

export default AddDepositForm;
