import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import { postData } from "../../services/baseServices";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

class PackForm extends Component {
  state = {
    isLoadingData: true,
    isSubmitting: false,
    isFormValidated: false,
    qrCodeMatching: [],
    variants_locations: [],
    total_bundle: 1
  };

  setTotalBundle = (e, maxQty) =>{
    const val = e.target.value ? parseInt(e.target.value) : 1;
    this.setState({total_bundle: val > 0 ? val > maxQty ? maxQty : val : 1})
  }

  setVariantsLocations = (e, variant_id) => {
    let { variants_locations } = this.state;
    const found = variants_locations.find(
      (element) => element.variant_id === variant_id
    );

    if (found === undefined) {
      variants_locations.push({ variant_id, location_id: e.target.value });
    } else {
      const index = variants_locations.findIndex(
        (x) => x.variant_id === variant_id
      );
      variants_locations[index].location_id = e.target.value;
    }

    this.setState({ variants_locations });
  };

  generateQRInput = (item, index) => {
    let sku = 123; // TBc 26, 28
    let inputFields = [];
    for (let i = 0; i < 2; i++) {
      let indText = i.toString() + index.toString();
      // this.filledQr(ind)
      inputFields.push(
        <Form.Group
          as={Row}
          controlId="displayOrShopName"
          className="qrCodeField">
          <Col sm="12">
            <Form.Control
              name={"qr_code"}
              required
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => this.handleInputOnChange(e, i, sku, index)}
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              Please enter QR code.
            </Form.Control.Feedback>
            {this.state.qrCodeMatching.includes(indText) && (
              <span style={{ color: "red" }}>
                <i>Please enter valid code.</i>
              </span>
            )}
          </Col>
        </Form.Group>
      );
    }
    return inputFields;
  };

  debouncedOnPackSubmit = _.debounce((e, form) => {
    this.handleSubmitUnPack(e, form);
  }, DEBOUNCE_TIME);

  handleWithDebouncePackSubmit = (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    this.debouncedOnPackSubmit(e, e.currentTarget);
  };

  handleSubmitUnPack = (e, form) => {
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true, isSubmitting: false });
    } else {
      const formData = this.props.selectedProducts[0];
      let DataToPost = {
        bundle_variant_id: parseInt(formData.bundle_variant_id),
        bundle_location_id: formData.bundle_location_id,
        bundle_quantity: this.state.total_bundle,
        bundle_variants: [],
      };
      formData.bundle_variants.forEach((bv) => {
        bv.packed.forEach((item) => {
          DataToPost.bundle_variants.push({
            variant_id: bv.variant_id,
            packed_quantity: item.quantity,
            location_id: item.location_id,
            qr_code: item.qr_code,
          });
        });
      });
      postData("bundles/un_pack", DataToPost)
        .then((res) => {
          if (res.success) {
            this.props.history.push(`/bundle/list`);
            toast.success(res.message);
          } else {
            toast.error(res.message);
            this.setState({ isSubmitting: false });
          }
        })
        .catch((errMsg) => {
          toast.error(errMsg);
          this.setState({ isSubmitting: false });
        });
    }
  };

  handleSubmitUnack = (e, form) => {};

  render() {
    const {
      selectedProducts,
      handleVariantQuantityChange,
      handleInputQRCodeOnChange,
      setVariantsLocations,
      addItemIntoPackUnderSKU,
      handleVariantRemove,
      setBundleLocation,
    } = this.props;

    const variants = selectedProducts.map((product) => {
      return (
        <>
          <div>
            <div className=" mb-2">
              <table className="info-table">
                <tbody>
                  <tr>
                    <td style={{width: '250px'}}>Bundle Product</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>{product.product_title}</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>SKU</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td className='d-flex text-bold'>
                      {product.bundle_variant_sku}
                    </td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Available Quantity</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td className='d-flex text-bold'>
                      {product.available_quantity}
                    </td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Bundle Quantity</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>
                      <input
                       style={{width: '250px'}}
                      name='bundle_quantity'
                      className="form-control form-control-sm"
                      required
                      min="1"
                      max={product?.available_quantity}
                      type="number"
                      // value={this.state.total_bundle}
                      onKeyPress={(e) => {
                        // const xxx =parseInt(e.target.value+e.key)
                        // || xxx> product.available_quantity || xxx <=0
                        if (["Enter", "-"].includes(e.key)) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onChange={(e)=>{
                          this.setTotalBundle(e, product.available_quantity)
                      }}
                    />
                    </td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Location</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>
                      <select
                        style={{width: '250px'}}
                        id="location"
                        required
                        name="bundle_location_id"
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          setBundleLocation(e, product.bundle_variant_id);
                        }}>
                        <option value="" selected={product.bundle_variant_id === ""}>
                          --Select--
                        </option>
                        {product.bundle_locations?.length > 0
                          ? product.bundle_locations?.map((loc) => {
                              return (
                                <option
                                  value={loc?.id}
                                  disabled={loc.quantity === 0}
                                  selected={product.bundle_location_id === loc.id}>
                                  {loc?.code} [Q: {loc.quantity}]
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h6>Variants</h6>
          <div className="card-block">
            <div className="block-body">
              {product.bundle_variants.map((bv) => {
                return (
                  <>
                    <div className="d-flex">
                      <div>
                        <table className="table table-bordered table-responsive">
                          <tr>
                            <th>SKU</th>
                            <th>Available Quantity</th>
                            <th>Quantity</th>
                            <th>Action</th>
                          </tr>
                          <tbody>
                            <tr>
                              <td>{bv.sku}</td>
                              <td>{bv.available_quantity}</td>
                              <td>{bv.quantity * this.state.total_bundle}</td>
                              <td>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  disabled={bv.quantity * this.state.total_bundle <= bv.allocated}
                                  onClick={() =>
                                    addItemIntoPackUnderSKU(
                                      this.state.total_bundle,
                                      product.bundle_variant_id,
                                      bv.sku
                                    )
                                  }>
                                  Add
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="ml-5">
                        <table className="table table-bordered">
                          <tbody>
                            {bv.packed.length > 0 && (
                              <tr>
                                <th>Quantity</th>
                                <th>QR Code</th>
                                <th>Location</th>
                                <th>Action</th>
                              </tr>
                            )}
                            {bv.packed.map((item) => (
                              <tr>
                                <td>
                                  <input
                                    name={item.id}
                                    className="form-control form-control-sm"
                                    required
                                    min="1"
                                    max={bv.allocated * this.state.total_bundle}
                                    type="number"
                                    // value={
                                    //   item.quantity !== undefined
                                    //     ? item.quantity
                                    //     : ""
                                    // }
                                    onKeyPress={(e) => {
                                      // const xxx =parseInt(e.target.value+e.key)
                                      // || xxx <=0 || xxx > bv.allocated * this.state.total_bundle
                                      if (["Enter", "-"].includes(e.key)) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }
                                    }}
                                    onChange={(e) =>
                                      e.target.value &&
                                      handleVariantQuantityChange(
                                        this.state.total_bundle,
                                        e,
                                        product.bundle_variant_id,
                                        bv.sku,
                                        item.id
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Form.Group
                                    as={Row}
                                    controlId="displayOrShopName"
                                    className="qrCodeField">
                                    <Col sm="12">
                                      <Form.Control
                                        name={"qr_code"}
                                        required
                                        value={item.qr_code}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            e.stopPropagation();
                                          }
                                        }}
                                        onChange={(e) =>
                                          handleInputQRCodeOnChange(
                                            e,
                                            product.bundle_variant_id,
                                            bv.sku,
                                            item.id
                                          )
                                        }
                                        type="text"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please enter QR code.
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>
                                </td>
                                <td>
                                  <select
                                    id={item.id}
                                    required
                                    name="location_id"
                                    className="form-control form-control-sm"
                                    onChange={(e) => {
                                      setVariantsLocations(
                                        e,
                                        product.bundle_variant_id,
                                        bv.sku,
                                        item.id
                                      );
                                    }}>
                                    <option
                                      value=""
                                      selected={item.location_id === null}>
                                      --Select--
                                    </option>
                                    {bv.locations?.length > 0
                                      ? bv.locations?.map((loc) => {
                                          return (
                                            <option
                                              value={loc?.id}
                                              selected={
                                                item.location_id === loc.id
                                              }>
                                              {loc?.code}
                                            </option>
                                          );
                                        })
                                      : ""}
                                  </select>
                                </td>
                                <td>
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(e) =>
                                      handleVariantRemove(
                                        e,
                                        product.bundle_variant_id,
                                        bv.sku,
                                        item.id
                                      )
                                    }>
                                    X
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })}
            </div>
          </div>
        </>
      );
    });

    return (
      <div>
        {selectedProducts.length > 0 && (
          <>
            <Form
              noValidate
              validated={this.state.isFormValidated}
              onSubmit={this.handleWithDebouncePackSubmit}>
              {variants}
              <div align="right">
                <Button
                  size="sm"
                  type={"submit"}
                  disabled={this.state.isSubmitting}
                  variant="primary">
                  {this.state.isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(PackForm);
