import React, { useEffect, useState } from 'react'
import {Modal, Button} from 'react-bootstrap'
import {postData} from '../services/baseServices'
import {refreshPage} from '../helper/utils'
import {toast} from "react-toastify";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../helper/env"

const LocationTransferModal = ({show, handleClose, locationDetailsForModals}) => {
    const [submitting, setSubmitting] = useState(false)
    useEffect(()=> {
        if (show) setSubmitting(false)
    }, [show])
    const debouncedOnTransferLocation = _.debounce(()=>{
        postTransferLocation()
    }, DEBOUNCE_TIME);

    const handleWithDebounceTransferLocation = async () => {
        setSubmitting(true)
        debouncedOnTransferLocation();
    }

    const postTransferLocation = () =>{
        setSubmitting(true)
        const transferObj = {
            present_location_id:locationDetailsForModals.oldLocationId,
            transfer_location_id:locationDetailsForModals.newLocationId,
            variant_id:locationDetailsForModals.variant_id,
            quantity:locationDetailsForModals.newQuantity
        }
        postData("locations/transfer", transferObj)
        .then(res => {
          if(res.status_code === 200) {
            toast.success(res.message);
            handleClose(true)
            //setSubmitting(false)
          }
          else{
            toast.error(res.message);
            setSubmitting(false)
          }
        })
        .catch(errMsg => {
          toast.error(errMsg);
          setSubmitting(false)
        });
    }
    return (
        <div>
          <Modal show={show} onHide={()=> handleClose(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                  Transfer {locationDetailsForModals.product_title} 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure to transfer <b>{locationDetailsForModals.newQuantity} </b>
                  quantity of  <b>{locationDetailsForModals.product_title} </b> 
                  from <b>{locationDetailsForModals.oldLocationName}</b> to  <b>{locationDetailsForModals.newLocationName}</b>?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=> handleClose(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleWithDebounceTransferLocation} disabled={submitting}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    )
}
export default LocationTransferModal
