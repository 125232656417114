
import React, { useState } from 'react';
import App from "../../App";
import { Link, useHistory, withRouter } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import {postData} from '../../services/baseServices'
import { toast } from 'react-toastify';
import ArticleForm from './Form';

const Create = () => {
    const [article, setArticle] = useState({
        title: '',
        bn_title: '',
        body:'',
        bn_body: '',
        help_topic_id: '',
        public_visibility: true,
        footer_visibility: false,
        position: 0,
    })
    const [metaInfo, setMetaInfo] = useState({})
    const history = useHistory()

    const onFormSubmit = (data) => {
        const meta_datum_attributes = {
            meta_title: metaInfo.metaTitle,
            bn_meta_title: metaInfo.metaTitleBn, 
            meta_description: metaInfo.metaDescription, 
            bn_meta_description: metaInfo.metaDescriptionBn,
            meta_keyword: metaInfo.metaKeywords,
            bn_meta_keyword: metaInfo.metaKeyKeywordsBn
        }
          let updatedData = {...data, meta_datum_attributes}

          postData("articles",updatedData)
          .then(res => {
            if(res.success) {
              history.push(`/articles/list`);
              toast.success(res.message);
            }
            else {
              toast.error(res.message);
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
          }); 
    }
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Create Article</h2>
                <div className="ml-auto">
                    <Link to={`${process.env.PUBLIC_URL}/articles/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
                </div>
            </div>
                <ArticleForm
                    article = {article}
                    metaInfo={metaInfo}
                    setMetaInfo={setMetaInfo}
                    onFormSubmit = {onFormSubmit}
                />
        </App>
    );
}
export default withRouter(Create);