import React, { Component } from "react";
import App from "../../App";
import { AiOutlineArrowLeft, FiChevronRight } from "react-icons/all";
import { Link, withRouter, Redirect } from "react-router-dom";
import { getProductType } from "../../services/productTypeService";
import { getProductDetails } from "../../services/productServices";
import JoditEditor from "jodit-react";
import { getFormattedDate, getWarehouseType } from "../../helper/utils";
import { getList, getData } from "../../services/baseServices";
import { Button } from "react-bootstrap";
import LogModal from "./LogModal";
import { toUpper } from "lodash";

const warehouseType = getWarehouseType();

class Details extends Component {
  constructor() {
    super();
    this.state = {
      productDetails: null,
      isDetailLoading: false,
      redirect: false,
      isOpen: false,
      status: "",
      btnText: "",
      activeBlockTab: "Description",

      productAttrsOption: {},
      jsonString: "",
      selectedCategory: "",
      selectedCategories: [],
      skuColorVariant: [],
      faq: {},
      productAttrs: [],
      attributes: [],
      attribute: null,
      isShowVariationModal: false,
      categories: [],
      childCategories: [],
      selectedCatArr: [],
      product: {},
      attrCombo: [],
      editFaqIndex: null,
      editSKUIndex: null,
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      product_type: [],
      product_attribute_value_ids: [],
      productTypes: [],
      editProductTypes: [],
      isEmptyCategory: false,
      product_attribute_values_images: [],
      product_attribute_images_attributes: [],
      categoryList: [],
      attributeSet: [],
      selectedAttributeSet: {},
      metaInfo: {},
      modalData: [],
      showModal: false,
      logType: "",
    };
  }

  getCategoryArray = (category, array) => {
    if (category.parent_category) {
      array.push(category.title);
      this.getCategoryArray(category.parent_category, array);
    } else {
      array.push(category.title);
    }
  };

  componentDidMount() {
    getProductType()
      .then(async (res) => {
        await this.setState(
          {
            productTypes: res,
          },
          () => {
            console.log(this.state.productTypes);
          }
        );
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });

    getList("attribute_sets")
      .then(async (res) => {
        if (res.success) {
          let attrSets = res?.data;
          let selectedAttributeSet = attrSets.find(
            (item) => item.value === this.state.product?.attribute_set_id
          );
          await this.setState({ attributeSet: attrSets, selectedAttributeSet });
        }
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });

    getProductDetails(this.props.match.params.id)
      .then(async (res) => {
        const productDetailsRes = {
          business_type: res?.business_type,
          title: res?.title,
          id: res?.id,
          bn_title: res?.bn_title,
          created_at: res?.created_at,
          created_by: res?.created_by?.name,
          description: res?.description,
          bn_description: res?.bn_description,
          short_description: res?.short_description,
          bn_short_description: res?.bn_short_description,
          is_custom_price_exist: res?.is_custom_price_exist,
          dangerous_goods: res?.dangerous_goods,
          sku_type: res?.sku_type,
          product_type: res?.product_type,
          company: res?.company,
          brand_name: res?.brand_name,
          keywords: res?.keywords,
          max_quantity_per_order: res?.max_quantity_per_order,
          faq: res?.frequently_asked_questions,
          is_refundable: res?.is_refundable,
          return_policy: res?.return_policy,
          bn_return_policy: res?.bn_return_policy,
          attribute_set_id: res?.attribute_set_id,
          bundle_variants: res?.bundle_variants,
          slug: res?.slug,
          public_visibility: res?.public_visibility,
          weight: res?.weight,
        };
        this.setState({ metaInfo: res?.meta_info });
        let selectedAttributeSet = this.state.attributeSet?.find(
          (item) => item.id === productDetailsRes.attribute_set_id
        );
        let catArr = [];
        this.setState({ categoryList: catArr });

        let variantsArr = [];
        let productAttr = [];

        res.variants.forEach((item) => {
          const attr_combo = [];
          const product_attribute_value_ids = [];
          if (item.product_attribute_values.length > 0) {
            item.product_attribute_values.forEach((attr) => {
              product_attribute_value_ids.push(attr.id);

              attr_combo.push(attr.value);
              let ind = productAttr.findIndex(
                (x) => attr.product_attribute.name === x.attribute_name
              );
              if (ind === -1) {
                productAttr.push({
                  attribute_name: attr.product_attribute.name,
                  data: [],
                });
              }
            });
          }
          const variant = {
            attr_combo: attr_combo,
            id: item.id,
            created_by: item?.created_by,
            sku: item.sku,
            weight: item.weight,
            weight_unit: item.weight_unit,
            height: item.height,
            height_unit: item.height_unit,
            width: item.width,
            width_unit: item.width_unit,
            depth: item.depth,
            depth_unit: item.depth_unit,
            product_id: item.product_id,
            primary: item.primary,
            product_attribute_value_ids: product_attribute_value_ids,
            price_distribution: item.price_distribution,
            price_retailer: item.price_retailer,
            discount_type: item.discount_type,
            effective_mrp: item.effective_mrp,
            b2b_price: item.b2b_price,
            b2b_discount_type: item.b2b_discount_type,
            b2b_discount: item.b2b_discount,
            b2b_effective_mrp: item.b2b_effective_mrp,
            available_quantity: item.inventory_status.available_quantity,
            booked_quantity: item.inventory_status.booked_quantity,
            ready_to_ship_from_fc_quantity:
              item.inventory_status.ready_to_ship_from_fc_quantity,
            blocked_quantity: item.inventory_status.blocked_quantity,
            locations: item.locations,
          };
          variantsArr.push(variant);
        });
        const attributes = [...this.state.attributes];
        let product_attribute_values_images = [
          ...this.state.product_attribute_values_images,
        ];
        const product_attribute_images_attributes = [
          ...this.state.product_attribute_images_attributes,
        ];
        if (res.attributes && res.attributes.length > 0) {
          res.attributes.forEach((attr) => {
            const selectedOptions = [];
            attr.attribute_values.forEach((attrVal) => {
              let obj = {
                id: attrVal.attribute_image_id,
                product_attribute_value_id: attrVal.id,
                images_file: [],
              };
              product_attribute_images_attributes.push(obj);
              product_attribute_values_images[attrVal.id] = attrVal.images
                ? attrVal.images
                : [];
              selectedOptions.push({
                label: attrVal.value,
                value: attrVal.id,
              });
            });

            attributes.push({
              id: 1 /*item.product_attribute_values[0].product_attribute.id*/,
              label: attr.attribute.name,
              value: attr.attribute.id,
              selectedOptions: selectedOptions,
            });
          });
        }

        this.setState(
          { attributes, product_attribute_values_images },
          () => {}
        );

        let productTypes = [];
        res.product_types.forEach((item) => {
          productTypes.push(item.id);
        });

        productDetailsRes.sku_information = variantsArr;
        productDetailsRes.attributes = productAttr;
        productDetailsRes.product_attribute_images_attributes =
          product_attribute_images_attributes;
        this.setState(
          {
            product: productDetailsRes,
            categories: catArr.reverse(),
            editProductTypes: productTypes,
            product_type: productTypes.map(String),
            productAttrs: this.state.attributes,
            hero_mini_img: res.hero_image_variant_path?.mini_img,
            existingImages: res.images,
            isLoadingData: false,
            selectedAttributeSet,
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
  }

  handleBlockTab = (label) => {
    this.setState({ activeBlockTab: label });
  };

  getParentcat = (obj, catArr) => {
    if (obj.parent_category !== null) {
      catArr.push(obj.id);
      this.getParentcat(obj.parent_category, catArr);
    } else {
      catArr.push(obj.id);
    }
    return catArr;
  };

  handleDeatilsLog = (modalTile, url, params) => {
    getData(url, params)
      .then((res) => {
        this.setState({
          logType: modalTile,
          modalData: res?.data,
          showModal: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  hideModalHandler = () => {
    this.setState({ showModal: false });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/products/list" />;
    }
    const details = this.state.product;
    const skuInfos = details.sku_information;
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Product Details</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/product/list`}
              className="btn btn-sm btn-link mr-2"
            >
              <AiOutlineArrowLeft /> Back to Product List
            </Link>
          </div>
        </div>
        <LogModal
          show={this.state.showModal}
          onHide={this.hideModalHandler}
          logType={this.state.logType}
          data={this.state.modalData}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        {this.state.isLoadingData ? (
          <div className={`card-block`}>
            <div className={`block-body`}>Loading...</div>
          </div>
        ) : (
          <div className="main-content">
            <div className="card-block">
              <div className="block-body">
                <div className="product-item">
                  <div className="product lg">
                    <div className="product-thumb">
                      <img
                        src={this.state.hero_mini_img}
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                      />
                    </div>
                    <div className="product-desc">
                      <h4 className="product-title">{details?.title}</h4>
                      <h5>{details?.bn_title}</h5>
                      <p className="date mb-0">
                        <em>Business Type : </em>{" "}
                        <strong>{toUpper(details?.business_type)}</strong>
                      </p>
                      <p className="date mb-0">
                        <em>Product Id : </em> <strong>{details?.id}</strong>
                      </p>
                      <p className="date mb-0">
                        <em>Created at : </em>{" "}
                        {getFormattedDate(details?.created_at, "LL")}
                      </p>
                      {warehouseType === "central" && (
                        <p className="date mb-0">
                          <em>Created By : </em>{" "}
                          <strong>{details?.created_by}</strong>
                        </p>
                      )}

                      <p className="date mb-0">
                        <em>Weight : </em> <strong>{details?.weight}</strong>
                      </p>
                    </div>
                    {details?.is_custom_price_exist && (
                      <div className="ml-auto">
                        <Button
                          size="sm"
                          onClick={() => {
                            this.props.history.push(
                              `${
                                process.env.PUBLIC_URL
                              }/product/custom-product-price-list/${
                                details?.sku_type === "simple_product" &&
                                details?.sku_information[0]?.sku
                              }`
                            );
                          }}
                        >
                          Custom Price
                        </Button>
                      </div>
                    )}

                    {warehouseType === "central" && (
                      <div className="ml-auto">
                        <Button
                          size="sm"
                          onClick={() =>
                            this.handleDeatilsLog(
                              "Change Log",
                              `products/${details?.id}/changes_log`
                            )
                          }
                        >
                          Change Log
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {/* end working area */}

                {details?.sku_type !== 1 && (
                  <>
                    <hr />
                    <table className="info-table">
                      <tbody>
                        <tr>
                          <td style={{ width: "250px" }}>Slug</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.slug}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Refunable</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.is_refundable ? "YES" : "NO"}</td>
                        </tr>

                        <tr>
                          <td style={{ width: "250px" }}>Short Description</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>
                            <JoditEditor
                              value={details?.short_description}
                              config={{
                                readonly: true,
                                toolbar: false,
                                showCharsCounter: false,
                                showWordsCounter: false,
                                showXPathInStatusbar: false,
                              }}
                              tabIndex={1} // tabIndex of textarea
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>
                            Short Description in Bangla{" "}
                          </td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>
                            <JoditEditor
                              value={details?.bn_short_description}
                              config={{
                                readonly: true,
                                toolbar: false,
                                showCharsCounter: false,
                                showWordsCounter: false,
                                showXPathInStatusbar: false,
                              }}
                              tabIndex={1} // tabIndex of textarea
                            />
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "250px" }}>Dangerous Goods</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.dangerous_goods}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Product Type</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.product_type}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Company in English</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.company?.name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Brand</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.brand_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Keywords</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.keywords}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Order Limit</td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>{details?.max_quantity_per_order}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>

            {details?.sku_type === "simple_product" && (
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">SKU Information</h4>
                </div>
                <div className="block-body">
                  <table className="info-table">
                    <tbody>
                      <tr>
                        <td style={{ width: "250px" }}>Created By</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{details?.created_by}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>SKU Type</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>Simple Product</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>SKU</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{details?.sku_information[0]?.sku}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Available Quantity</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {details?.sku_information[0]?.available_quantity}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Booked Quantity</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{details?.sku_information[0]?.booked_quantity}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>
                          Ready to Ship From FC Quantity
                        </td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {
                            details?.sku_information[0]
                              ?.ready_to_ship_from_fc_quantity
                          }
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Blocked Quantity</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{details?.sku_information[0]?.blocked_quantity}</td>
                      </tr>
                      {details?.business_type === "multicat" ? (
                        <tr>
                          <td style={{ width: "250px" }}>
                            Distribution Price (BDT)
                          </td>
                          <td style={{ width: "20px", textAlign: "center" }}>
                            :
                          </td>
                          <td>
                            {details?.sku_information[0]?.price_distribution}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td style={{ width: "250px" }}>B2B Price (BDT)</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>{details?.sku_information[0]?.b2b_price}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Locations</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {details?.sku_information[0].locations?.map(
                            (loc, i) => {
                              return (
                                <>
                                  <div key={i}>
                                    {loc?.code && loc?.quantity ? (
                                      <span>
                                        Code: {loc?.code}, Quantity:{" "}
                                        {loc?.quantity}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "250px" }}>Actions</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        {warehouseType === "central" && (
                          <td>
                            <Button
                              size="sm"
                              className="mr-2"
                              onClick={() =>
                                this.handleDeatilsLog(
                                  "Supplier Variant Log",
                                  `variants/${details?.sku_information[0].id}/supplier_variants_log`
                                )
                              }
                            >
                              Supplier Variant Log
                            </Button>
                            <Button
                              size="sm"
                              onClick={() =>
                                this.handleDeatilsLog(
                                  "Variant Change Log",
                                  "audit_logs",
                                  {
                                    auditable_type: "Variant",
                                    auditable_id:
                                      details?.sku_information[0].id,
                                  }
                                )
                              }
                            >
                              Variant Change Log
                            </Button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* {details?.sku_type === "bundle_product" && (
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Bundle Information</h4>
                </div>
                <div className="block-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td>SKU</td>
                        <td>Quantity</td>
                      </tr>
                    </thead>
                    <tbody>
                      {details?.bundle_variants?.map((item, index, array) => (
                        <tr>
                          <td style={{ width: "100px" }}>{item.bundle_sku}</td>
                          <td>{item.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {(details?.sku_type === "variable_product" ||
              details?.sku_type === "bundle_product") && (
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">SKU Information</h4>
                </div>
                <div className="block-body">
                  <table className="info-table">
                    <tbody>
                      <tr>
                        <td style={{ width: "100px" }}>SKU Type</td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {details?.sku_type === "variable_product"
                            ? "Variable"
                            : "Bundle"}{" "}
                          Product
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "100px" }}>Attributes</td>
                        <td style={{ width: "10px", textAlign: "center" }}>
                          :
                        </td>
                        <td>
                          {this.state.selectedAttributeSet?.product_attributes?.map(
                            (item, index, array) =>
                              index < array.length - 1 ? (
                                <>
                                  <span className="text">{item.name}</span>
                                  <span className="caret-icon">, </span>
                                </>
                              ) : (
                                <span className="text">{item.name}</span>
                              )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Log</th>
                        <th>Created By</th>
                        <th>Code By Supplier</th>
                        <th>Attributes Combo</th>
                        <th>Inventory Status</th>
                        <th className={"text-center"}>Locations</th>
                        <th className="text-right">Price Distribution (৳)</th>
                        <th className="text-right">Prices B2C (৳)</th>
                        <th className="text-right">Prices B2B (৳)</th>
                      </tr>
                    </thead>

                    <tbody>
                      {skuInfos?.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.sku}</td>
                            {warehouseType === "central" && (
                              <td>
                                <Button
                                  size="sm"
                                  className="mb-2"
                                  onClick={() =>
                                    this.handleDeatilsLog(
                                      "Supplier Log",
                                      `variants/${item.id}/supplier_variants_log`
                                    )
                                  }
                                >
                                  Supplier Log
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    this.handleDeatilsLog(
                                      "Change Log",
                                      "audit_logs",
                                      {
                                        auditable_type: "Variant",
                                        auditable_id: item.id,
                                      }
                                    )
                                  }
                                >
                                  Change Log
                                </Button>
                              </td>
                            )}

                            <td>{item?.created_by?.name}</td>
                            <td>{item.code_by_supplier}</td>
                            <td>{item.attr_combo.join(" - ")}</td>
                            <td>
                              <div>
                                Available Quantity: {item.available_quantity}
                              </div>
                              <div>Booked Quantity: {item.booked_quantity}</div>
                              <div>
                                Ready to Ship From FC Quantity:{" "}
                                {item.ready_to_ship_from_fc_quantity}
                              </div>
                              <div>
                                Blocked Quantity: {item.blocked_quantity}
                              </div>
                            </td>
                            <td className="text-center">
                              {item.locations?.map((loc, i) => {
                                return (
                                  <>
                                    {loc?.code && loc?.quantity ? (
                                      <div>
                                        <div key={i}>Code: {loc?.code}</div>
                                        <div key={i}>
                                          Quantity: {loc?.quantity}
                                        </div>
                                        <hr />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </td>
                            <td>{item.price_distribution}</td>
                            <td className="text-right">
                              <div>Consumer Price: {item.price_consumer}</div>
                              <div>
                                Consumer Discount{" "}
                                {item.discount_type === "percentage"
                                  ? " (%)"
                                  : "(BDT)"}
                                : {item.consumer_discount}
                              </div>
                              <div>Effective MRP: {item.effective_mrp}</div>
                            </td>
                            <td className="text-right">
                              <div>B2B Price: {item.b2b_price}</div>
                              <div>
                                B2B Discount{" "}
                                {item.b2b_discount_type === "percentage"
                                  ? " (%)"
                                  : "(BDT)"}
                                : {item.b2b_discount}
                              </div>
                              <div>
                                B2B Effective MRP: {item.b2b_effective_mrp}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )} */}
          </div>
        )}
      </App>
    );
  }
}

export default withRouter(Details);
