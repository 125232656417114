import React, { Component } from 'react';
import App from "../../App";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { BiLayerPlus, FiEdit, FiPlus } from "react-icons/all";
import Pagination from 'react-js-pagination';
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { toast } from 'react-toastify';
import '../../assets/scss/header.scss';
import { destroy, getList, getPaginatedList } from "../../services/baseServices";
import ConfirmationModal from "../common/Modal";
import Loader from 'react-loader';

class List extends Component {
  constructor() {
    super();
    this.state = {
      suppliers: [],
      activePage: 1,
      per_page: 25,
      page: 1,
      isLoading: false,
      temp_params: {},
      query_params: {},
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, { per_page: this.state.per_page, page: this.state.page });
    return params;
  }

  getPaginatedSuppliers = (params) => {
    this.setState({ ...this.state, isLoading: true });
    getPaginatedList("suppliers", params)
      .then(res => {
        this.setState({
          suppliers: res.data,
          isLoading: false,
          per_page: parseInt(res.headers['x-per-page']),
          total_data: parseInt(res.headers['x-total']),
        }, () => {
        });
      })
      .catch(errMsg => {
        this.setState({ ...this.state, isLoading: false });
        console.log('Error Message: ', errMsg)
      })
  }

  componentDidMount() {
    this.getPaginatedSuppliers(this.getQueryParams());
  }

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    this.setState({ ...this.state, page: 1, query_params: tempParam }, () => {
      this.getPaginatedSuppliers(this.state.temp_params);
    });
  }

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {
    });
  }

  handleDeleteSupplier = id => {
    destroy("suppliers", id)
      .then(res => {
        if (res.status_code === 200) {
          toast.success('Successfully deleted');
          getList("suppliers")
            .then(res => {
              this.setState({ suppliers: res })
            })
            .catch(errMsg => {
              console.log('Err Msg: ', errMsg)
            })
        } else {
          toast.error(res?.message)
        }
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedSuppliers(this.getQueryParams());
    });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Suppliers List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/supplier/add`} className="btn btn-sm btn-secondary"><FiPlus /> Add Supplier</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">

              <form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="name" className="sr-only">Search Key</label>
                  <input type="search" name="name" id="name" onChange={this.handleOnFieldChange}
                    className='form-control form-control-sm' placeholder="Search by Name" />
                </div>
                <Button type={`submit`} onClick={this.handleOnSearch} size="sm" variant="primary">Search</Button>
              </form>
              <Loader loaded={!this.state.isLoading}>
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: '20px' }}>#</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Created By</th>
                      <th>Contact Person</th>
                      <th>Mou Document Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.suppliers.length > 0 ? this.state.suppliers.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                          <td>
                            <div className="order-item">
                              <Link to={`${process.env.PUBLIC_URL}/supplier/edit/${item.id}`}>
                                {item.supplier_name}
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="order-item">
                              {item?.phone}
                            </div>
                          </td>
                          <td>
                            <div className="order-item">
                              {item?.email}
                            </div>
                          </td>
                          <td>
                            <div className="order-item">
                              {item?.created_by?.name}
                            </div>
                          </td>
                          <td>
                            <div className="order-item">
                              {item?.contact_person}
                            </div>
                          </td>

                          <td>
                            <div className="order-item">
                              {item?.mou_document_number}
                            </div>
                          </td>
                          <td style={{ width: '150px' }}>
                            <div className="btn-group">
                              {/* <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Assign Product
                                  </Tooltip>
                                }
                              >
                                <Link to={`/supplier/assign-product/${item.id}`} className="btn btn-sm btn-default"><BiLayerPlus /></Link>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Edit Assign Product
                                  </Tooltip>
                                }
                              >
                                <Link to={`/supplier/edit-assign-product/${item.id}`} className="btn btn-sm btn-default"><FiEdit /></Link>
                              </OverlayTrigger> */}
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() => this.handleDeleteSupplier(item.id)} />
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      );
                    }) : ""}
                  </tbody>
                </table>
              </Loader>
            </div>
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>

        </div>
        <ToastsContainer store={ToastsStore} />
      </App>
    );
  }
}

export default withRouter(List);
