import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import {API_BASE_URL} from "../../helper/env";
import { getLocalAuthToken } from "../../helper/utils";

class SelectComponent extends Component {
  state = {
    inputValue: ''
  }

  searchedData = async(inputText) => {
    let promise = new Promise((resolve, reject) => {
      const authData = getLocalAuthToken();
      axios.get(API_BASE_URL + `/` + this.props.requested_url+inputText, {
        headers: {
          Authorization: authData?.message?.token
        }
      }).then(res => {
        if (res.data.error) {
          reject(res.data.error)
        } else {
          resolve(res.data)
        }
      })
      .catch(err => {
        reject('Something went wrong')
      })
    }).then((json) => {
    
      return json ;
    }).catch(function(error) {
        console.log("error",error);
        return []
    });;
  
    let result = await promise; // wait until the promise resolves (*)
    return result
    //alert(result); // "done!"
  }

  getOptions = (input) => {
    console.log("input",input)
    const authData = getLocalAuthToken();
    return fetch(API_BASE_URL + `/` + this.props.requested_url+input,{
      method:'GET',
      headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authData?.message?.token
      }
    })
    .then((response) => {
      
      return response.json();
    }).then((json) => {
    
      return json ;
    }).catch(function(error) {
        console.log("error",error);
        return []
    });
}

  loadOptions = (inputValue, callback) => {
    this.searchedData(inputValue).then(res=> 
      setTimeout(() => {
        callback(res);
     }, 1000)
     )
  };

  handleInputChange = (e) => {
    const inputValue = e.replace(/\W/g, '');
    this.setState({inputValue: inputValue});
    return inputValue;
  };

  handleOnSelectionChange = (key, value) => {
    let values
    if(!value) values=[]
    else if(!Array.isArray(value))
      values = [value.value]
    else values = value?.map(item => {return (item.value)} ) 
    if(this.props.isEdit) this.props.onPromoFieldChange(0, key, values)
    else this.props.onPromoFieldChange(key, values)
  }

  render() {
    return (
      <AsyncSelect
        required={true}
        cacheOptions
        loadOptions={this.loadOptions}
        isSearchable={true}
        isClearable={false}
        defaultOptions
        defaultValue={this.props.selectedValues}
        isMulti={this.props.isMulti}
        onInputChange={this.handleInputChange}
        onChange={(e) => this.handleOnSelectionChange(this.props.name, e)}
      />         
    );
  }
}
export default SelectComponent;
