import axios from "axios";
import { API_BASE_URL } from "../helper/env";
import {
  getDeviceType,
  getLocalAuthData,
  getObjectToFormData,
} from "../helper/utils";

export const addProduct = (data) => {
  const authData = getLocalAuthData();
  delete data.attributes;
  delete data.sku_information;
  if (data.sku_type === 0) {
    delete data.variants_attributes[0].product_attribute_value_ids;
  }
  delete data.faq;
  delete data.product_categories_attributes;
  const formData = getObjectToFormData(data);
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "/products", formData, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const tempImageAdd = (data) => {
  const formData = getObjectToFormData({ bulk_upload_tmp_image: data });
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "/products/upload_temporary_image", formData, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const bulkImport = (data) => {
  const formData = getObjectToFormData({ file: data });
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_URL + "/products/import", formData, {
        headers: {},
      })
      .then((res) => {
        if (res.data.error) {
          reject(res.data.error);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const assignProduct = (formData, supplier_id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_BASE_URL + `/suppliers/${supplier_id}/suppliers_variants`,
        formData,
        {
          headers: {
            // Authorization: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOjgsImV4cCI6MTYwOTIyOTQ0MX0.WPpWfj27KMN7xN-d4frbpE9YV-YdBx9WnLp8AOF8Uco'
          },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};
export const editAssignProduct = (formData, supplier_id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .put(
        API_BASE_URL + `/suppliers/${supplier_id}/suppliers_variants`,
        formData,
        {
          headers: {},
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject("Something went wrong");
      });
  });
};

export const updateProduct = (id, data) => {
  const authData = getLocalAuthData();
  delete data.attributes;
  delete data.sku_information;
  if (data.sku_type === 0) {
    delete data.variants_attributes[0].product_attribute_value_ids;
  }
  delete data.faq;
  delete data.product_categories_attributes;
  // return
  const formData = getObjectToFormData({ product: data });
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/products/${id}`, formData, {
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject("Something went wrong");
      });
  });
};

export const getProduct = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/products`, {
        params,
        headers: {},
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getViewDhAssignment = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/warehouse_products/export`, {
        params,
        headers: {},
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getCustomVariantPrice = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/custom_variant_prices`, {
        params,
        headers: {},
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getProductDetails = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/products/${id}`, {
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getSearchedProduct = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/products/search`, {
        params,
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getWHSearchedProduct = (params) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE_URL + `/products/suppliers_variant/search`, {
        params,
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const deleteProduct = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/products/${id}`, {
        headers: {},
      })
      .then((res) => {
        if (res.data.status === 1) {
          resolve(res.data.message);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const deleteVariation = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/products/variant_delete/${id}`, {
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const deleteAttributeImage = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/products/${id}/attribute_image`, {
        headers: {},
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
