
import React, { useState, useEffect } from 'react';
import App from "../../../App";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ThanaForm from "./Form";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getData, putData } from "../../../services/baseServices"

const Create = () => {
    const [thana, setThana] = useState({
        name: '',
        bn_name: '',
        home_delivery: '',
        district_id:''
    });
    const [district, setDistrict] = useState([]);
    const history = useHistory()
    const { id } = useParams()
    useEffect(() => {
        getData(`thanas/${id}`)
            .then(res => {
                if (res.success) setThana(res?.data)
            })
            .catch(errMsg => {
            });
    }, [id])

    const onFormSubmit = (data) => {
        const params = {
            thana: {
                name: data.name,
                bn_name: data.bn_name,
                home_delivery: data.home_delivery,
                district_id: data.district_id
            }
        }
        putData(`thanas/${id}`, params)
            .then(res => {
                if (res.success) {
                    history.push(`/config/thanas/list`);
                    toast.success(res.message);
                }
                else {
                    toast.error(res.message);
                }
            })
            .catch(errMsg => {
                toast.error(errMsg);
            });
    }
    useEffect(() => {
        getData('districts')
          .then(res => {
            setDistrict(res.data)
          })
          .catch(errMsg => {
          })
    }, [])
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Edit Thana</h2>
                <div className="ml-auto">
                    <Link to={`${process.env.PUBLIC_URL}/config/thanas/list`}
                        className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to List</Link>
                </div>
            </div>

            <div className="card-block">
                <div className="block-body">
                    <>
                        <ThanaForm
                            district={district}
                            thana={thana}
                            onFormSubmit={onFormSubmit}
                        />
                    </>
                </div>
            </div>
        </App>
    );
}
export default withRouter(Create);