import React, {Component} from 'react';
import App from "../../App";
import {OverlayTrigger, Tooltip, Button} from "react-bootstrap";
import {FiEdit, FiPlus } from "react-icons/all";
import  Pagination from 'react-js-pagination';
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {toast} from 'react-toastify';
import { destroy, getList, getPaginatedList } from "../../services/baseServices";
import ConfirmationModal from "../common/Modal";
import { getWarehouseType } from "../../helper/utils";

const wareHouseType = getWarehouseType()
class List extends Component {
  constructor() {
    super();
    this.state = {
      riders: [],
      activePage: 1,
      per_page: 15,
      page: 1,
      distributors: [],
      query_params: {}
    };
  }

  getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

  getRiderList = (params) => {
    this.setState({loading: true})
    getPaginatedList('riders',params)
    .then(res => {
      this.setState({ riders: res.data,
        per_page: res.headers['x-per-page'],
        total_data: res.headers['x-total'],
        loading: false
       })
    })
    .catch(errMsg => {
      this.setState({loading: false})
      console.log('Err Msg: ', errMsg)
    })
  }

  componentDidMount() {
    this.getRiderList(this.getQueryParams())
    getList("distributors")
        .then((res) => {
            this.setState({distributors: res?.data});
        })
        .catch((errMsg) => {});
  }

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    this.setState({...this.state, page: 1, query_params: tempParam}, () => {
      this.getRiderList(this.state.temp_params);
    });
  }

  handleOnFieldChange = (e) => {
    const queryData = {...this.state.temp_params};
    queryData[e.target.name] = e.target.value;
    this.setState({temp_params: queryData}, () => {
    });
  }

  handleOnClear = ()=>{
		this.setState({
			temp_params: {...this.state.temp_params, 
				title: '', 
				distributor_id:''
			},
			query_params: {...this.state.temp_params, 
				title: '', 
				distributor_id:''
			}
		}, () =>{
			this.getRiderList(this.getQueryParams());
		})
	}

  handleDeleteRider = id => {
    destroy("riders", id)
      .then(res => {
        if(res.status_code === 200){
          toast.success('Successfully deleted');
          this.getRiderList()
        } else {
            toast.error(res?.message)
        }
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber, isLoading: true}, () => {
    });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Rider List</h2>
            {
               wareHouseType !== 'central' &&
               <div className="ml-auto">
                <Link to={`${process.env.PUBLIC_URL}/rider/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Rider</Link>
            </div>
            }
            
          </div>
          <div align="center">
            <form className="form-inline mb-3">
              <div className="form-group mr-3">
                <label htmlFor="distributor_id" className="sr-only">
                  Distributor
                </label>
                <select
                  id="distributor_id"
                  name="distributor_id"
                  className="form-control form-control-sm"
                  onChange={this.handleOnFieldChange}
                  value={this.state.temp_params?.distributor_id}
                >
                  <option value="">--Select Distributor--</option>
                  {this.state.distributors.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <Button
                size="sm"
                type={`submit`}
                onClick={this.handleOnSearch}
                variant="primary"
              >
                Filter
              </Button>
              <Button
                size="sm"
                type={`button`}
                className="ml-2"
                onClick={this.handleOnClear}
                variant="danger"
              >
                Clear
              </Button>
            </form>
          </div>
          <div className="card-block">
            <div className="block-body">

              {/* <form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="searchInput" className="sr-only">Search Key</label>
                  <input type="search" name="search_string" id="orderNumber"
                         className='form-control form-control-sm' placeholder="Search Key"/>
                </div>
                <Button size="sm" variant="secondary">Search</Button>
              </form> */}

              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>Rider Name</th>
                  <th>Distributor Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  {
                     wareHouseType !== 'central' && <th>Action</th>

                  }
                </tr>
                </thead>
                <tbody>
                  {this.state.riders?.length > 0 &&
                    this.state.riders?.map((rider, index) => {
                      return ( 
                        <tr key={index}>
                          <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                          <td>
                            <div className="order-item text-break">
                                  {rider.name}
                            </div>
                          </td>
                          <td>
                            <div className="order-item text-break">
                                  {rider.distributor_name}
                            </div>
                          </td>
                          <td>
                            <div className="order-item text-break">
                                {rider.email}
                            </div>
                          </td>
                          <td>
                            <div className="order-item">
                                {rider.phone}
                            </div>
                          </td>
                          {
                             wareHouseType !== 'central' &&
                             <td style={{width: '150px'}}>
                            <div className="btn-group">
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Edit
                                  </Tooltip>
                                }
                              >
                              <Link to={`${process.env.PUBLIC_URL}/rider/edit/${rider.id}`}
                                className="btn btn-sm btn-default"><FiEdit/></Link>
                              </OverlayTrigger>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() =>this.handleDeleteRider(rider.id)}/>
                              </OverlayTrigger>
                              </div>
                          </td>
                          }
                          
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
