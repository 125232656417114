import React, { Component } from 'react';
import { FaLongArrowAltLeft } from 'react-icons/all';
import { Link } from 'react-router-dom';
import App from './../../App';
import { Rating } from '@material-ui/lab/Rating';
import { getDetails } from './../../services/baseServices';
import { withRouter } from 'react-router-dom';

class HelpTopicDetails extends Component {
    constructor() {
        super();
        this.state = {
            helpTopic: {},
            isLoading: true,
			metaInfo:{}

        }
      };

      componentWillMount() {
        this.getContactDetails();
        
      }

      getContactDetails = () =>{
        getDetails("help_topics", this.props.match.params.id)
        .then((res) => {
          if(res.success){
            this.setState({ helpTopic: res.data, metaInfo:res.data?.meta_info}, ()=>{
              this.setState({isLoading: false})
            });
          }else{
            this.setState({isLoading: false})
          }
        })
        .catch((errMsg) => {
          this.setState({isLoading: false})
          console.log("Error Message: ", errMsg);
        });
      }

    render() {
		const metaInfo = this.state.metaInfo
        return (
            <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Help topic Details</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/help-topic/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
            </div>
          </div>

          {
            this.state.isLoading ?
              <div className={`card-block`}>
                <div className="block-body">
                  Loading...
                </div>
              </div>
              :
              <div className="card-block">
						<div className="block-header">
							<h4 className="block-title">Help topic Details</h4>
						</div>
						{this.state.isLoading ? (
							<div className={`card-block`}>
								<div className="block-body">Loading...</div>
							</div>
						) : (
							<div className="block-body">
								<>
									<table className="info-table col-12">
										<tbody>
											<tr>
												<td style={{ width: "250px" }}>Help topic Id</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.helpTopic.id}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>
													Slug
												</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.helpTopic.slug}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>
													Title
												</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.helpTopic.title}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>BN Title</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.helpTopic.bn_title}</td>
											</tr>
											
											<tr>
												<td style={{ width: "250px" }}>Public Visibility</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.helpTopic.public_visibility? "True": "False"}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Article Count</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.helpTopic.article_count}</td>
											</tr>
										</tbody>
									</table>
								</>
							</div>
						)}
					</div>
					
          }
          
        </div>

		<div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Meta Info</h4>
          </div>
          <div className="block-body">
            <table className="info-table">
              <tbody>
                <tr>
                  <td style={{ width: "200px" }}>Meta Title</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.meta_title}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Title in Bangla</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.bn_meta_title}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Description</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.meta_description}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Description in Bangla</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.bn_meta_description}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Keywords</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{
                      metaInfo?.meta_keyword?.map((item,index)=>(
                        <span key={index}>{item}, </span>
                      ))
                  }</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Keywords in Bangla</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{
                     metaInfo?.bn_meta_keyword?.map((item,index)=>(
                      <span key={index}>{item}, </span>
                    ))
                    }</td>
                </tr>
              </tbody>
            </table>
            </div>
            </div>
      </App>
        );
    }
}

export default withRouter(HelpTopicDetails);