import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import {
  FieldLabel,
  Input,
  TextArea,
  Select,
  ImageUploader,
} from "../../../components/ant-controls";
import { Button, Col, Form, Row } from "react-bootstrap";
import { postData } from "../../../services/baseServices";
import { serialize } from "object-to-formdata";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { Divider } from "antd";
import { toast } from "react-toastify";

const layoutOptions = [
  { id: 1, value: 1, label: "Full" },
  { id: 2, value: 2, label: "Half" },
  { id: 3, value: 3, label: "One Third" },
];

const publishOptions = [
  { id: 1, value: true, label: "Yes" },
  { id: 2, value: false, label: "No" },
];

const ACCEPT_FILE_FORMAT = ".png, .jpg, .jpeg, .webp";

export const PromotionalSliderForm = ({ schema, setSelectedLayout }) => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      layout: 1,
      is_visible: false,
      bannerWeb: [
        {
          image_type: "web",
          image_title: "",
          description: "",
          redirect_url: "",
          image_file: "",
        },
      ],
      bannerApp: [
        {
          image_type: "app",
          image_title: "",
          description: "",
          redirect_url: "",
          image_file: "",
        },
      ],
    },
  });

  const {
    fields: webFields,
    append: webAppend,
    remove: webRemove,
  } = useFieldArray({
    control,
    name: "bannerWeb",
  });

  const {
    fields: appFields,
    append: appAppend,
    remove: appRemove,
  } = useFieldArray({
    control,
    name: "bannerApp",
  });

  const numOfLayout = watch("layout");

  useEffect(() => {
    setSelectedLayout(numOfLayout);

    const newVal = parseInt(numOfLayout || 1);
    const oldVal = webFields.length;
    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        webAppend({
          image_type: "web",
          image_title: "",
          description: "",
          redirect_url: "",
          image_file: "",
        });
        appAppend({
          image_type: "app",
          image_title: "",
          description: "",
          redirect_url: "",
          image_file: "",
        });
      }
    } else {
      for (let i = oldVal; i > newVal; i--) {
        webRemove(i - 1);
        appRemove(i - 1);
      }
    }
  }, [numOfLayout]);

  const handleAddBanner = (bannerType) => {
    if (bannerType === "web") {
      webAppend({
        image_type: bannerType,
        image_title: "",
        description: "",
        redirect_url: "",
        image_file: "",
      });
    } else {
      appAppend({
        image_type: bannerType,
        image_title: "",
        description: "",
        redirect_url: "",
        image_file: "",
      });
    }
  };

  const onSubmit = (data) => {
    setIsSubmitting(true)
    const images_attributes = [];
    data?.bannerWeb?.map((item) =>
      images_attributes.push({
        image_type: item.image_type,
        image_title: item.image_title,
        description: item.description,
        redirect_url: item.redirect_url,
        image_file: item.image_file.file,
      })
    );

    data?.bannerApp?.map((item) =>
      images_attributes.push({
        image_type: item.image_type,
        image_title: item.image_title,
        description: item.description,
        redirect_url: item.redirect_url,
        image_file: item.image_file.file,
      })
    );

    const formData = serialize({
      title: data.promo_title,
      layout: data.layout,
      is_visible: data.is_visible,
      banner_images_attributes: [...images_attributes],
    });

    postData("promo_banners", formData)
      .then((res) => {
        setIsSubmitting(false)
        if (res?.success) {
          toast.success(res?.message);
          history.push("/promotional-slider/list");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error("Something went worng");
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={Row} controlId="promo_title" className="mt-5">
        <Col sm={{ span: 2, offset: 1 }} className="d-flex align-items-center">
          <FieldLabel name="promo_title" label="Promotion Title" required />
        </Col>

        <Col sm={6}>
          <Input
            control={control}
            name="promo_title"
            errMsg={errors?.promo_title?.message}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="layout" className="mb-4">
        <Col sm={{ span: 2, offset: 1 }} className="d-flex align-items-center">
          <FieldLabel name="layout" label="Layout" required />
        </Col>

        <Col sm={3}>
          <Select
            control={control}
            name="layout"
            options={layoutOptions}
            placeholder="-- Please Select --"
            errMsg={errors?.layout?.message}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="is_visible" className="mb-4">
        <Col sm={{ span: 2, offset: 1 }} className="d-flex align-items-center">
          <FieldLabel name="is_visible" label="Publish" required />
        </Col>

        <Col sm={3}>
          <Select
            control={control}
            name="is_visible"
            options={publishOptions}
            errMsg={errors?.is_visible?.message}
            placeholder="-- Please Select --"
          />
        </Col>
      </Form.Group>

      <Divider>
        <p className="font-weight-bold">For Web</p>
      </Divider>

      <Form.Group as={Row} controlId="image_type">
        <Col sm={{ span: 2, offset: 1 }}>
          <FieldLabel name="image_type" label="Image Type" />
        </Col>

        <Col sm={9}>
          <h6>Web</h6>
        </Col>
      </Form.Group>

      {webFields.map((item, i) => {
        const webField = `bannerWeb.${i}`;

        const titleError = _.isEmpty(errors)
          ? ""
          : errors?.bannerWeb?.[i]?.image_title?.message;

        const descError = _.isEmpty(errors)
          ? ""
          : errors?.bannerWeb?.[i]?.description?.message;

        const redirectUrlError = _.isEmpty(errors)
          ? ""
          : errors?.bannerWeb?.[i]?.redirect_url?.message;

        const imgFileError = _.isEmpty(errors)
          ? ""
          : errors?.bannerWeb?.[i]?.image_file?.message;

        return (
          <div key={item.id} className="mb-4">
            <Form.Group as={Row} controlId="image_title">
              <Col
                sm={{ span: 2, offset: 1 }}
                className="d-flex align-items-center"
              >
                <FieldLabel name="image_title" label="Banner Title" required />
              </Col>
              <Col sm={6}>
                <Input
                  control={control}
                  name={`${webField}.image_title`}
                  errMsg={titleError}
                />
              </Col>
              <Col sm={2} className="text-right">
                {webFields.length > numOfLayout && (
                  <Button
                    size="md"
                    type="button"
                    variant="danger"
                    onClick={() => webRemove(i)}
                  >
                    <RiDeleteBinLine size={20} />
                  </Button>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="description">
              <Col sm={{ span: 2, offset: 1 }}>
                <FieldLabel name="description" label="Description" required />
              </Col>
              <Col sm={6}>
                <TextArea
                  control={control}
                  name={`${webField}.description`}
                  errMsg={descError}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="redirect_url">
              <Col
                sm={{ span: 2, offset: 1 }}
                className="d-flex align-items-center"
              >
                <FieldLabel name="redirect_url" label="Redirect Url" required />
              </Col>
              <Col sm={6}>
                <Input
                  control={control}
                  name={`${webField}.redirect_url`}
                  errMsg={redirectUrlError}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="image_file">
              <Col sm={{ span: 2, offset: 1 }}>
                <FieldLabel name="image_file" label="Banner Image" required />
              </Col>

              <Col sm={2}>
                <ImageUploader
                  control={control}
                  name={`${webField}.image_file`}
                  acceptFileFormat={ACCEPT_FILE_FORMAT}
                  setValue={setValue}
                />
              </Col>
              <Col sm={4}>
                <ul>
                  <li> Image format must be "jpg, jpeg, png or webp".</li>
                  <li>Image size not more than 1MB.</li>
                  <li>
                    Image dimension must be - Full width 1440 x 250, Half width
                    720 x 250 or One third width 500 x 200 pixel.
                  </li>
                </ul>
              </Col>
              <Col sm={{ span: 8, offset: 3 }}>
                <p className="text-danger">{imgFileError}</p>
              </Col>
            </Form.Group>
          </div>
        );
      })}

      <Row>
        <Col
          sm={{ span: 6, offset: 3 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 7, offset: 3 }}
        >
          <div className="mb-4">
            <Button
              size="md"
              type="button"
              variant="info"
              onClick={() => handleAddBanner("web")}
            >
              <AiOutlinePlus className="mr-1" />
              <span>Add More</span>
            </Button>
          </div>
        </Col>
      </Row>

      <Divider>
        <p className="font-weight-bold">For App</p>
      </Divider>

      <Form.Group as={Row} controlId="image_type">
        <Col sm={{ span: 2, offset: 1 }}>
          <FieldLabel name="image_type" label="Image Type" />
        </Col>

        <Col sm="9" md={8} lg={7}>
          <h6>App</h6>
        </Col>
      </Form.Group>

      {appFields?.map((item, i) => {
        const appField = `bannerApp.${i}`;

        const titleError = _.isEmpty(errors)
          ? ""
          : errors?.bannerApp?.[i]?.image_title?.message;

        const descError = _.isEmpty(errors)
          ? ""
          : errors?.bannerApp?.[i]?.description?.message;

        const redirectUrlError = _.isEmpty(errors)
          ? ""
          : errors?.bannerApp?.[i]?.redirect_url?.message;

        const imgFileError = _.isEmpty(errors)
          ? ""
          : errors?.bannerApp?.[i]?.image_file?.message;
        return (
          <div key={item.id} className="mb-4">
            <Form.Group as={Row} controlId="image_title">
              <Col
                sm={{ span: 2, offset: 1 }}
                className="d-flex align-items-center"
              >
                <FieldLabel name="image_title" label="Banner Title" required />
              </Col>
              <Col sm={6}>
                <Input
                  control={control}
                  name={`${appField}.image_title`}
                  errMsg={titleError}
                />
              </Col>
              <Col sm={2} className="text-right">
                {appFields.length > numOfLayout && (
                  <Button
                    size="md"
                    type="button"
                    variant="danger"
                    onClick={() => appRemove(i)}
                  >
                    <RiDeleteBinLine size={20} />
                  </Button>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="description">
              <Col sm={{ span: 2, offset: 1 }}>
                <FieldLabel name="description" label="Description" required />
              </Col>
              <Col sm={6}>
                <TextArea
                  control={control}
                  name={`${appField}.description`}
                  errMsg={descError}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="redirect_url">
              <Col
                sm={{ span: 2, offset: 1 }}
                className="d-flex align-items-center"
              >
                <FieldLabel name="redirect_url" label="Redirect Url" required />
              </Col>

              <Col sm={6}>
                <Input
                  control={control}
                  name={`${appField}.redirect_url`}
                  errMsg={redirectUrlError}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="image_file">
              <Col sm={{ span: 2, offset: 1 }}>
                <FieldLabel name="image_file" label="Banner Image" required />
              </Col>

              <Col sm={2}>
                <ImageUploader
                  control={control}
                  name={`${appField}.image_file`}
                  acceptFileFormat={ACCEPT_FILE_FORMAT}
                  setValue={setValue}
                />
              </Col>
              <Col sm={4}>
                <ul>
                  <li> Image format must be "jpg, jpeg, png or webp".</li>
                  <li>Image size not more than 1MB.</li>
                  <li>Image dimension must be 767 x 250 pixel.</li>
                </ul>
              </Col>
              <Col sm={{ span: 8, offset: 3 }}>
                <p className="text-danger">{imgFileError}</p>
              </Col>
            </Form.Group>
          </div>
        );
      })}

      <Row>
        <Col
          sm={{ span: 6, offset: 3 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 7, offset: 3 }}
        >
          <div className="mb-4">
            <Button
              size="md"
              type="button"
              variant="info"
              onClick={() => handleAddBanner("app")}
            >
              <AiOutlinePlus className="mr-1" />
              <span>Add More</span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          sm={{ span: 9, offset: 3 }}
          md={{ span: 8, offset: 3 }}
          lg={{ span: 7, offset: 3 }}
        >
          <div className="mt-3 text-right">
            <Link
              to={`${process.env.PUBLIC_URL}/promotional-slider/list`}
              className="mr-2 btn btn-md btn-danger"
            >
              Cancel
            </Link>
            <Button
              size="md"
              type={`submit`}
              variant="primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
