import React, {Component} from 'react';
import App from "../../App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEdit, FiPlus } from "react-icons/all";
import Pagination from 'react-js-pagination';
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {toast} from 'react-toastify';
import ConfirmationModal from "../common/Modal";
import {getAttributeList, deleteAttribute} from "../../services/attributeServices";

class List extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      formData: {image: ''},
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedFile: null,
      activePage: 1,
      per_page: 10,
      page: 1,
      counter: 1
    };
  }

  componentDidMount() {
    getAttributeList({per_page: this.state.per_page, page: this.state.page})
      .then(res => {
        this.setState({items: res.data,
          counter: this.state.activePage - 1 > 0 ? ((this.state.activePage - 1) * this.state.per_page) + 1 : 1,
          per_page: res.headers['x-per-page'],
          total_data: res.headers['x-total'],
        })
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber, isLoading: true}, () => {
      // this.getQueryData();
      getAttributeList({per_page: this.state.per_page, page: this.state.page})
        .then(res => {
          this.setState({
            items: res.data,
            isLoading: false,
            counter: this.state.activePage - 1 > 0 ? ((this.state.activePage - 1) * this.state.per_page) + 1 : 1,
          }, () => {
            console.log(this.state.productAttrsOption);
          });
        })
        .catch(errMsg => {
          console.log('Error Message: ', errMsg)
        })
    });
  }


  handleDeleteAttribute = (id) => {

    deleteAttribute(id)
      .then(res => {
        if(res.status_code === 200) {
          toast.success('Successfully deleted');
          getAttributeList({per_page: this.state.per_page, page: this.state.page})
            .then(res => {
              this.setState({
                items: res.data,
                isLoading: false,
                counter: this.state.activePage - 1 > 0 ? ((this.state.activePage - 1) * this.state.per_page) + 1 : 1,  
              })
            })
            .catch(errMsg => {
              console.log('Err Msg: ', errMsg)
            })
        }
        else {
          toast.error(res.message);
        }
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
    {/*confirmAlert({*/
    }
    //   title: 'Delete',
    //   message: 'Are you sure to delete this category permanently?',
    //   buttons: [
    {/*    {*/
    }
    {/*      label: 'Yes',*/
    }
    {/*      onClick: () => deleteCategory(id)*/
    }
    //         .then(res => {
    {/*          toast.success('Successfully deleted');*/
    }
    //           getCategory()
    //             .then(res => {
    //               this.setState({categories: res})
    //             })
    //             .catch(errMsg => {
    //               console.log('Err Msg: ', errMsg)
    //             })
    //         })
    //         .catch(errMsg => {
    //           toast.error(errMsg)
    //         })
    //     },
    //     {
    //       label: 'No',
    //     }
    //   ]
    // });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Attribute List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/attribute/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add
                Attribute</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">

              {/*<form className="form-inline mb-3">*/}
              {/*  <div className="form-group mr-3">*/}
              {/*    <label htmlFor="searchInput" className="sr-only">Search Key</label>*/}
              {/*    <input type="search" name="search_string" id="orderNumber"*/}
              {/*           className='form-control form-control-sm' placeholder="Search Key"/>*/}
              {/*  </div>*/}
              {/*  <Button size="sm" variant="secondary">Search</Button>*/}
              {/*</form>*/}
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>Name</th>
                  <th>Values</th>
                  {/*<th>Bangla Name</th>*/}
                  {/*<th>Bangla Values</th>*/}
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>

                {this.state.items.length > 0 ? this.state.items.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                      <td>
                        <div className="order-item">
                          {item.name}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item?.product_attribute_values && item?.product_attribute_values.length > 0 ?
                            item.product_attribute_values.map((value)=>{
                              return (
                                <span>
                                  {value.value + ','}
                                </span>
                              );
                            })
                            : ""}
                        </div>
                      </td>
                      {/*<td>*/}
                      {/*  <div className="order-item">*/}
                      {/*    {item?.bn_name}*/}
                      {/*  </div>*/}
                      {/*</td>*/}
                      {/*<td>*/}
                      {/*  <div className="order-item">*/}
                      {/*    {item?.product_attribute_values && item?.product_attribute_values.length > 0 ?*/}
                      {/*      item.product_attribute_values.map((value)=>{*/}
                      {/*        return (*/}
                      {/*          <span>*/}
                      {/*            {value.bn_value + ','}*/}
                      {/*          </span>*/}
                      {/*        );*/}
                      {/*      })*/}
                      {/*      : ""}*/}
                      {/*  </div>*/}
                      {/*</td>*/}

                      <td style={{width: '150px'}}>
                        <div className="btn-group">
                          <OverlayTrigger
                            key="view"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-view`}>
                                Edit
                              </Tooltip>
                            }
                          >
                          <Link to={`${process.env.PUBLIC_URL}/attribute/edit/${item.id}`}
                            className="btn btn-sm btn-default"><FiEdit/></Link>
                          </OverlayTrigger>
                            <OverlayTrigger
                              key="view"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-view`}>
                                  Delete
                                </Tooltip>
                              }
                            >
                            <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() =>this.handleDeleteAttribute(item.id)}/>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  );
                }) : ""}

                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
