import React, {Component} from 'react';
import App from "../../App";
import {Button, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FiCheck, FiEdit, FiPlus, FiUpload, FiEye} from "react-icons/all";
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {toast} from 'react-toastify';
import { destroy, getList } from "../../services/baseServices";
import {getWarehouseType} from "../../helper/utils";
import {getPaginatedList} from "../../services/paginatedService";
import  Pagination from 'react-js-pagination';
import ConfirmationModal from "../common/Modal";

const warehouseType = getWarehouseType();

class List extends Component {
  constructor() {
    super();
    this.state = {
      locations: [],
      activePage: 1,
      per_page: 15,
      page: 1,
      counter: 1
    };
  }

  componentDidMount() {
    getPaginatedList({per_page: this.state.per_page, page: this.state.page, skip_pagination: true}, '/locations')
      .then(res => {
        this.setState({ locations: res.data,
          counter: this.state.activePage - 1 > 0 ? ((this.state.activePage - 1) * this.state.per_page) + 1 : 1,
          per_page: res.headers['x-per-page'],
          total_data: res.headers['x-total'],
         })
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })
  }

  handleDeleteLocation = id => {
    destroy("locations", id)
      .then(res => {
        if(res.status_code === 200){
          toast.success('Successfully deleted');
          getPaginatedList({per_page: this.state.per_page, page: this.state.page, skip_pagination: true}, '/locations')
            .then(res => {
              this.setState({ locations: res.data,
                counter: this.state.activePage - 1 > 0 ? ((this.state.activePage - 1) * this.state.per_page) + 1 : 1,
                per_page: res.headers['x-per-page'],
                total_data: res.headers['x-total'],
              })
            })
            .catch(errMsg => {
              console.log('Err Msg: ', errMsg)
            })
        } else{
          toast.error('err message')
        }
      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber, isLoading: true}, () => {
      // this.getQueryData();
      getPaginatedList({per_page: this.state.per_page, page: this.state.page, skip_pagination: true}, '/locations')
        .then(res => {
          this.setState({
            locations: res.data,
            isLoading: false,
            counter: this.state.activePage - 1 > 0 ? ((this.state.activePage - 1) * this.state.per_page) + 1 : 1,
          }, () => {
            console.log(this.state.productAttrsOption);
          });
        })
        .catch(errMsg => {
          console.log('Error Message: ', errMsg)
        })
    });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Location List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/location/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add Location</Link>
            </div>
          </div>
          <div className="card-block">
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>Location Code</th>
                  <th>Warehouse</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                  {this.state.locations?.length > 0 &&
                    this.state.locations?.map((location, index) => {
                      return ( 
                        <tr key={index}>
                          <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                          <td>
                            <div className="order-item">
                              <h4 className="order-id mb-0">
                                <Link to={`${process.env.PUBLIC_URL}/location/details/${location.id}`}>
                                  {location.code}
                                </Link>
                              </h4>
                            </div>
                          </td>
                          <td>
                            <div className="order-item">
                              {location.warehouse.name}
                            </div>
                          </td>
                          <td style={{width: '150px'}}>
                            <div className="btn-group">
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    View
                                  </Tooltip>
                                }
                              >
                                <Link to={`${process.env.PUBLIC_URL}/location/details/${location.id}`}
                                      className="btn btn-sm btn-default"><FiEye/></Link>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Edit
                                  </Tooltip>
                                }
                              >
                                <Link to={`${process.env.PUBLIC_URL}/location/edit/${location.id}`}
                                      className="btn btn-sm btn-default"><FiEdit/></Link>
                              </OverlayTrigger>
                              {/* <OverlayTrigger
                                key="view"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-view`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() =>this.handleDeleteLocation(location.id)}/>
                              </OverlayTrigger> */}
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          {/*</div>*/}
        </div>
         {/*<ToastsContainer store={ToastsStore}/>*/}
      </App>
    );
  }
}

export default withRouter(List);
