import ShippingMethods from "./ShippingMethods";
import Commissions from "./Commissions";
import {withRouter} from "react-router-dom";
import App from "../../App";

const components = {
    shipping_methods: ShippingMethods,
    commissions: Commissions
};

function loadComponent(key) {
    const Component = components[key];
    return <Component/>;
}

const Edit = (props) => {
    return (
        <App layout="boxed">
            {loadComponent(props.match.params.key)}
        </App>
    );
}
export default withRouter(Edit);