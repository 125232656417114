import React, { Component } from 'react';
import { FaLongArrowAltLeft } from 'react-icons/all';
import { Link } from 'react-router-dom';
import App from './../../App';
import { getDetails } from './../../services/baseServices';
import { withRouter } from 'react-router-dom';
import { humanize } from  "../../helper/utils";

class ReasonDetails extends Component {
    constructor() {
        super();
        this.state = {
            reason: {},
            isLoading: true,
        }
      };

      componentWillMount() {
        this.getContactDetails();
      }

      getContactDetails = () =>{
        getDetails("modify_reasons", this.props.match.params.id)
        .then((res) => {
          if(res.success){
            this.setState({ reason: res.data, metaInfo:res.data?.meta_info}, ()=>{
              this.setState({isLoading: false})
            });
          }else{
            this.setState({isLoading: false})
          }
        })
        .catch((errMsg) => {
          this.setState({isLoading: false})
          console.log("Error Message: ", errMsg);
        });
      }

    render() {
        return (
            <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Reason Details</h2>
            <div className="ml-auto">
              <Link to={`/acc-delete-reason/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
            </div>
          </div>

          {
            this.state.isLoading ?
              <div className={`card-block`}>
                <div className="block-body">
                  Loading...
                </div>
              </div>
              :
              <div className="card-block">
						<div className="block-header">
							<h4 className="block-title">Reason Details</h4>
						</div>
						{this.state.isLoading ? (
							<div className={`card-block`}>
								<div className="block-body">Loading...</div>
							</div>
						) : (
							<div className="block-body">
								<>
									<table className="info-table col-12">
										<tbody>
											<tr>
												<td style={{ width: "250px" }}>Reason Id</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reason.id}</td>
											</tr>
											
											<tr>
												<td style={{ width: "250px" }}>
													Title
												</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reason.title}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>BN Title</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{this.state.reason.title_bn}</td>
											</tr>
											<tr>
												<td style={{ width: "250px" }}>Reason Type</td>
												<td style={{ width: "20px", textAlign: "center" }}>
													:
												</td>
												<td>{humanize(this.state.reason.reason_type)}</td>
											</tr>
											
										</tbody>
									</table>
								</>
							</div>
						)}
					</div>
          }
        </div>
      </App>
        );
    }
}

export default withRouter(ReasonDetails);