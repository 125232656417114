import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import { getDetails, getData } from "../../services/baseServices";
import { getWarehouseType, getFormattedDate } from "../../helper/utils";
import ExportCSVAsync from "../common/ExportCSVAsync";

const headers = [
	{ label: "Coupon Code", key: "coupon_code" },
	{ label: "Used", key: "is_used" },
	{ label: "Customer Id", key: "customer_id" },
	{ label: "Customer Order Id", key: "customer_order_id" },
	{ label: "Cart Value", key: "cart_value" },
	{ label: "Discount", key: "discount_amount" },
];

const loadData = (item) => {
	return {
		coupon_code: item.coupon_code,
		is_used: item.is_used,
		customer_id: item.customer_id,
		customer_order_id: item.customer_order_id,
		cart_value: item.cart_value,
		discount_amount: item.discount_amount,
	};
};

class Details extends Component {
	state = {
		promotion: {},
		promoSettings: {},
		promoFields: [],
		promotion_variants: {},
	};

	componentDidMount() {
		getData("promotions/settings").then((res) => {
			this.setState({ promoSettings: res });
		});

		getDetails("promotions", this.props.match.params.id)
			.then((res) => {
				this.setState({
					promotion: res,
					promotion_variants: res.promotion_variants,//this.groupBy(res.promotion_variants, "state"),
					promoRules: this.state.promoSettings[res?.promotion_category],
					promoBrands: res?.promotion_brands
				});
				this.getPromoFields(res.rule);
			})
			.catch((errMsg) => {
				console.log("Error Message: ", errMsg);
			});
	}

	getPromoFields = (key) => {
		if (key) {
			let promoFields = this.state.promoRules[key]?.fields;
			if (promoFields) {
				this.setState({ promoFields: promoFields });
			}
		} else {
			this.setState({ promoFields: [] });
		}
	};

	groupBy = (objectArray, property) => {
		return objectArray?.reduce(function (acc, obj) {
			var key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(obj);
			return acc;
		}, {});
	};

	render() {
		const { promotion } = this.state;

		return (
			<App layout="">
				<div className="main-content">
					<div className="page-header">
						<div className="page-title" />
						<div className="ml-auto">
							<Link
								to={`${process.env.PUBLIC_URL}/promotions/list`}
								className={`btn btn-sm btn-link`}
							>
								<MdArrowBack /> Back to List
							</Link>
						</div>
					</div>

					<div className="card-block">
						<div className="block-header">
							<h4 className="block-title">Promotion Details</h4>
							<div className="ml-auto">
								{getWarehouseType() === "central" &&
									(promotion.promotion_category === "customer_coupon" || promotion.promotion_category === "minimum_cart_value") && (
										<ExportCSVAsync
											fileName={
												"Customer-Coupon" +
												getFormattedDate(new Date(), "DD-MM-YYYY") +
												".csv"
											}
											Url={`promotions/${this.props.match.params.id}/coupons/export`}
											headers={headers}
											loadData={loadData}
											title="Promotion Details"
										/>
									)}
							</div>
						</div>
						<div className="block-body">
							<>
								<table className="info-table">
									<tbody>
										<tr>
											<td style={{ width: "250px" }}>Promotion Title</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{promotion.title}</td>
										</tr>
										{/* <tr>
											<td style={{ width: "250px" }}>Warehouse</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{promotion.warehouse_name}
											</td>
										</tr> */}
										<tr>
											<td style={{ width: "250px" }}>Promotion Category</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{promotion.promotion_category}
											</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Promotion Rule</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{promotion.rule}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Applicable From</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{getFormattedDate(promotion.from_date, "DD/MM/YYYY")}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Applicable To</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{getFormattedDate(promotion.to_date, "DD/MM/YYYY")}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Created By</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}> {promotion?.created_by?.name} </td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Status</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{promotion.is_active ? "Active" : "Inactive"}
											</td>
										</tr>
										{/* <tr>
											<td style={{ width: "250px" }}>Time Bound</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{promotion.is_time_bound ? "Yes" : "No"}
											</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Start Time</td>
											<td style={{ width: "20px", textAlign: "center" }}>
												:
											</td>
											<td className={`text-bold`}>
												{promotion.start_time}
											</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>End Time</td>
											<td style={{ width: "20px", textAlign: "center" }}>
												:
											</td>
											<td className={`text-bold`}>{promotion.end_time}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Days</td>
											<td style={{ width: "20px", textAlign: "center" }}>
												:
											</td>
											<td className={`text-bold`}>
												{promotion.days?.toString()}
											</td>
										</tr> */}
										{this.state.promoFields?.map((item, i) => {
											let fieldValue = {};
											if (item.name == "x_skus" || item.name == "y_skus" || item.name == 'variant_skus') {
												fieldValue.value = this.state.promotion_variants?.find(i => i.name === item.name)?.values?.map((item) => {
													return item.sku;
												})
													.toString();
												// fieldValue.value = this.state.promotion_variants[
												// 	item.name == "x_skus" ? "buy" : "get"
												// ]
												// .map((item) => {
												// 	return item.sku;
												// })
												// .toString();
											} else if (item.name == "brand_names") {
												fieldValue.value = this.state.promoBrands[0]?.values?.map((item) => {
													return item.brand_name
												}).toString()
											} else {
												fieldValue = promotion?.promotion_rules.find(
													(i) => i.name == item.name
												);
											}

											return (
												<tr key={i}>
													<td style={{ width: "250px" }}>{item.title}</td>
													<td style={{ width: "20px", textAlign: "center" }}>
														:
													</td>
													<td className={`text-bold`}>{fieldValue?.value}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</>
						</div>
					</div>
				</div>
			</App>
		);
	}
}

export default withRouter(Details);
