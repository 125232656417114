import React, { useCallback, useEffect, useState } from "react";
import App from "../../App";
import { Button } from "react-bootstrap";
import { FiPlus, FaFileExport } from "react-icons/all";
import { toast } from "react-toastify";
import { Select, Space } from "antd";
import { convertToCSV } from "../../helper/utils";
import { getData, postData } from "../../services";

const BufferStockSR = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [options, setOptions] = useState([]);
  const [salesMan, setSalesMan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const salesManFetchData = async () => {
    try {
      const response = await getData("options/salesmans");
      if (response.status === 200) {
        setOptions([{ id: "all", name: "Select All" }, ...response?.data]);
      }
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const salesBufferStocks = async () => {
    try {
      setLoading(true);
      const data = await getData("sales_buffer_stocks", {
        salesman_ids: salesMan,
      });
      await downloadCSV(data);
    } catch (error) {
      toast.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  const exportCSV = async () => {
    if (salesMan.length === 0) {
      toast.error("Please select at least one sales representative.");
      return;
    }
    try {
      await salesBufferStocks();
    } catch (error) {
      toast.error("Error exporting CSV");
    }
  };

  useEffect(() => {
    salesManFetchData();
  }, []);

  const downloadCSV = async (data) => {
    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(data);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sales_man_buffer_stock.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("CSV Exported Successfully");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file ? file.name : "");
  };

  const handleUpload = useCallback(() => {
    if (selectedFile && !uploading) {
      setUploading(true);
      uploadCSV();
    } else {
      toast.error("Please select a CSV file to upload.");
    }
    return () => {
      setSelectedFile(null);
      setSelectedFileName("");
      setUploading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, uploading]);

  const uploadCSV = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    try {
      await postData("sales_buffer_stocks", formData, config);
      toast.success("CSV data imported successfully");
      setSelectedFile(null);
      setSelectedFileName("");
      setUploading(false);
    } catch (error) {
      toast.error("Error uploading CSV");
      setSelectedFile(null);
      setSelectedFileName("");
      setUploading(false);
    }
  };

  const handleChange = (value) => {
    setSalesMan([...value]);
  };

  return (
    <App>
      <h5 className="page-title">Buffer Stock For Sales Representative</h5>
      <div className="mt-5">
        <div className="d-flex justify-content-around">
          <div>
            <div className="main-content">
              <h6 className="page-title">Export Buffer Stock</h6>
              <div className="ml-auto d-flex justify-content-center">
                <div className="d-flex flex-column md:flex-row">
                  <Space
                    direction="horizontal"
                    style={{
                      width: "300px",
                    }}
                  >
                    <Select
                      mode="multiple"
                      size="middle"
                      allowClear
                      placeholder="Select SR..."
                      onChange={handleChange}
                      style={{
                        width: "300px",
                      }}
                      disabled={
                        selectedValue?.length && selectedValue[0] === "all"
                      }
                      onSelect={(value) => {
                        if (value === "all") {
                          setSelectedValue(["all"]);
                          setSalesMan(["all"]);
                        }
                      }}
                      optionFilterProp="children"
                      options={options?.map((option) => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={
                        selectedValue?.length &&
                        selectedValue[0] === "all" && ["all"]
                      }
                    />
                  </Space>
                </div>
                <div className="align-self-start">
                  <Button
                    disabled={loading || salesMan.length === 0}
                    onClick={exportCSV}
                    className="btn btn-sm btn-secondary ml-2"
                  >
                    <FaFileExport /> Download CSV
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="main-content">
              <h6 className="page-title">Assign Buffer Stock</h6>
              <div className="ml-auto d-flex justify-content-center">
                <label
                  htmlFor="fileInput"
                  className="btn btn-sm btn-secondary ml-2"
                >
                  <FiPlus /> Select CSV File
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              <br />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {selectedFileName && (
                <div className="ml-2">
                  <b>Selected File: </b>
                  {selectedFileName}
                </div>
              )}
            </div>
            <br />
            <div className="d-flex justify-content-center align-items-center">
              <Button
                onClick={handleUpload}
                className="btn btn-sm btn-secondary ml-2"
                disabled={uploading || !selectedFile}
                style={{
                  opacity: uploading || !selectedFile ? 0.5 : 1,
                }}
              >
                {uploading ? "Uploading..." : "Upload"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </App>
  );
};

export default BufferStockSR;
