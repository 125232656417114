import React, {Component} from 'react';
import App from "../../App";
import {FiPlus} from "react-icons/all";
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {getList} from "../../services/baseServices";
import { getRouteList } from "../../services/routeServices";

class List extends Component {
  constructor() {
    super();
    this.state = {
      routes: [],
      activePage: 1,
      per_page: 15,
      page: 1,
    };
  }

  componentDidMount() {
    getList("bundles/packed_variants", {per_page: this.state.per_page, page: this.state.page})
      .then(res => {
        this.setState({
          routes: res.data,
          // per_page: res?.headers['x-per-page'],
          // total_data: res?.headers['x-total'],
        })
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber, isLoading: true}, () => {
      // this.getQueryData();
      getRouteList({per_page: this.state.per_page, page: this.state.page})
        .then(res => {
          this.setState({
            routes: res.data,
            isLoading: false,
          });
        })
        .catch(errMsg => {
          console.log('Error Message: ', errMsg)
        })
    });
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">

          <div className="page-header">
            <h2 className="page-title">Bundle Pack List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/bundle/create-pack`} className="btn btn-sm btn-secondary"><FiPlus/> Pack Bundle</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>Product Title</th>
                  <th>SKU</th>
                  <th>No of Variants</th>
                  <th>Available Quantity</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {this.state.routes.length > 0 ? this.state.routes.map((item, index) => {
                  return (
                    <tr key={1}>
                      <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                      <td>
                        <div className="order-item">
                          {item.product_title || '_'}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item.sku || '_'}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item.bundle_variants_count || '0'}
                        </div>
                      </td>
                      <td>
                        <div className="order-item">
                          {item.available_quantity || '0'}
                        </div>
                      </td>
                      <td style={{width: '150px'}}>
                        <div className="btn-group">
                          <Link to={`/bundle/unpack/${item.id}`} className="btn btn-sm btn-primary">Unpack</Link>
                        </div>
                      </td>
                    </tr>
                  );
                }) : <tr><td colSpan="4" className="text-center">No data found</td></tr>}
                </tbody>
              </table>
            </div>
            {/* <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div> */}
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
