
import React, {useState, useEffect} from 'react';
import App from "../../App";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import AccoutDeactivationReasonForm from "./Form";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getData, putData } from "../../services/baseServices"

const Create = () => {
    const [reason, setReason] = useState({
        id: 0,
        title: '',
        title_bn: '',
    }) 
    
    const history = useHistory()
    const {id} = useParams()
    useEffect(()=>{
        getData(`modify_reasons/${id}`)
        .then(res => {
            setReason(res?.data)
           
        })
        .catch(errMsg => {
        });
    },[id])

    const onFormSubmit = (data) => {
      
        putData(`modify_reasons/${id}`,data)
        .then(res => {
          if(res.success) {
            history.push(`/acc-delete-reason/list`);
            toast.success(res.message);
          }
          else {
            toast.error(res.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg);
        }); 
    }
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Edit Reason</h2>
                <div className="ml-auto">
                    <Link to={`/acc-delete-reason/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
                </div>
            </div>

            <div className="card-block">
                <div className="block-body">
                    <>
                    <AccoutDeactivationReasonForm
                        reason = {reason}
                        onFormSubmit = {onFormSubmit}
                        edit= {true}
                    />
                    </>
                </div>
            </div>

        </App>
    );
}
export default withRouter(Create);