import React, { Component } from "react";
import "../assets/scss/sidebar.scss";
import SidebarNav from "../components/SidebarNav";
import CentralNavBar from "../components/CentralNavBar";
import { connect } from "react-redux";
import { FiPlus } from "react-icons/all";
import { getWarehouseType } from "../helper/utils";
const warehouseType = getWarehouseType();

class Sidebar extends Component {
  render() {
    console.log("wh", warehouseType);
    return (
      <div
        className={
          this.props.navbar.isNavbarShowing
            ? "sidebar"
            : "sidebar collapsed-sidebar"
        }
      >
        {warehouseType === "central" ? <CentralNavBar /> : <SidebarNav />}
        <div className="sidebar-footer">
          <span>
            &copy; 2020{" "}
            <span
              className="pointer-event"
              style={{ marginLeft: "20px", marginRight: "20px" }}
              onClick={() => window.open(`https://agami.ltd/`, "_blank")}
            >
              Agami Ltd.
            </span>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navbar: state.navbar,
  };
};

export default connect(mapStateToProps)(Sidebar);
