import React, { Component } from "react";
import App from "../../App";
import { FaLongArrowAltLeft, FaPrint } from "react-icons/all";
import "../../assets/scss/invoice.scss";
import { withRouter, Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { assignRiderCustomerOrder } from "../../services/baseServices";
import {
  cancelCustomerOrder,
  getData,
  getDetails,
  getList,
  unpackCustomerOrder,
} from "../../services/baseServices";
import {
  twoDecimalPlace,
  getWarehouseType,
  getFormattedDate,
  getDate,
  humanize,
} from "../../helper/utils";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";
import moment from "moment";
import RescheduleDeliveryDate from "./rescheduleDeliveryDate";

const warehouseType = getWarehouseType();
const default_state = {
  isLoading: true,
  status: null,
  changingStoreId: null,
  changeStatusMsg: "",
  isShowingStatusChangeConfirmModal: false,
  isSubmitting: false,
  packedStatus: false,
  isFormValidated: false,
  pickUpPointOrder: false,
  ridersList: [],
  colspan: 4,
  printcolspan: 2,
  invoiceShow: false,
  readyToShip: false,
  cancelled: false,
  orderDetail: {},
  riderId: null,
  qrCodes: [],
  packedOrders: [{ line_item_id: "", qr_codes: [] }],
  qrCodeMatching: [],
  packedBtn: false,
  filledQr: [],
  StatusMsgRequired: false,
  orderLocations: {},
  routeDetails: {},
  schedule: [],
  orderToBeUnpacked: false,
  packedCancelled: false,
  variants_locations: [],
  locations: [],
  data: [],
  isModalOpen: false,
};

class OrderDetails extends Component {
  state = default_state;

  componentDidMount() {
    getList("locations")
      .then((res) => {
        this.setState({ locations: res });
      })
      .catch((errMsg) => { });
    this.loadData();
  }

  toggleModalState = () => {
    this.setState((prevState) => {
      return { isModalOpen: !prevState.isModalOpen };
    });
  };

  loadData = () => {
    this.setState(default_state);
    getDetails("customer_orders", this.props.match.params.id)
      .then((res) => {
        this.setState({ orderDetail: res, isLoading: false });
        if (res.rider) {
          this.setState({ riderId: res.rider.id });
        }
        if (res.status_key == "ready_to_shipment") {
          this.setState({ readyToShip: true });
        }
        if (res.status_key === "cancelled") {
          this.setState({ cancelled: true });
        }
        if (res.status_key == "packed_cancelled") {
          this.setState({ packedCancelled: true });
        }
        if (res.partner.schedule) {
          this.setState({
            schedule: res.partner.schedule.toUpperCase().split("_"),
          });
        }
        if (res.shipping_type != "Pick up point") {
          getList("riders", { distributor_id: res?.distributor_id })
            .then((res) => {
              this.setState({ pickUpPointOrder: true });
              this.setState(
                {
                  ridersList: res,
                  isLoading: false,
                },
                () => { }
              );
            })
            .catch((errMsg) => { });
        } else {
          getDetails("routes", res.partner.route_id)
            .then((res) => {
              this.setState({ routeDetails: { ...res } });
            })
            .catch((errMsg) => {
              console.log(errMsg);
            });
        }
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  };

  hideChangeStatusModal = () => {
    this.setState({ isShowingStatusChangeConfirmModal: false });
  };

  handleInputOnChange = (e, i, sku, ind) => {
    let indText = i.toString() + ind.toString();
    const qrCodes = { ...this.state.qrCodes };
    const qrCodeMatching = [...this.state.qrCodeMatching];
    let line_qr_array = qrCodes[ind] ? qrCodes[ind] : [];
    line_qr_array[i] = e.target.value;
    qrCodes[ind] = line_qr_array;
    if (sku !== e.target.value) {
      const index = qrCodeMatching.indexOf(indText);
      if (index === -1) {
        qrCodeMatching.push(indText);
      }
    } else {
      const index = qrCodeMatching.indexOf(indText);
      if (index > -1) {
        qrCodeMatching.splice(index, 1);
      }
    }
    this.setState({ qrCodes, qrCodeMatching }, () => {
      console.log(this.state.qrCodeMatching);
    });
  };

  assignRider = (e) => {
    const params = {
      rider_id: e.target.value,
    };
    assignRiderCustomerOrder(this.props.match.params.id, params)
      .then((res) => {
        if (res?.status_code === 200 || res?.status_code === 201) {
          toast.success(res?.message);
          this.loadData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  generateQRInput = (item, index) => {
    let sku = item.item.sku;
    let inputFields = [];
    for (let i = 0; i < 1; i++) {
      let indText = i.toString() + index.toString();
      // this.filledQr(ind)
      inputFields.push(
        <Form.Group
          as={Row}
          controlId="displayOrShopName"
          className="qrCodeField"
        >
          <Col sm="12">
            <Form.Control
              name={"qr_code"}
              required
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => this.handleInputOnChange(e, i, sku, index)}
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              Please enter QR code.
            </Form.Control.Feedback>
            {this.state.qrCodeMatching.includes(indText) && (
              <span style={{ color: "red" }}>
                <i>Please enter valid code.</i>
              </span>
            )}
          </Col>
        </Form.Group>
      );
    }
    return inputFields;
  };

  renderPaymentStatus = (status) => {
    let payment_status = {
      0: "Unpaid",
      1: "Partially Paid",
      2: "Paid",
      3: "Cancelled",
      4: "Failed",
    };
    return payment_status[status];
  };

  filledQr = (ind) => {
    const filledQr = [...this.state.filledQr];
    filledQr.push(ind);
    this.setState({ filledQr }, () => {
      console.log(this.state.filledQr);
    });
  };

  selectRider = (e) => {
    this.setState({ riderId: e.target.value });
  };

  debouncedOnUnpackSubmit = _.debounce((e, form) => {
    this.handleUnpackOrderSubmit(e, form);
  }, DEBOUNCE_TIME);

  handleWithDebounceUnpackSubmit = (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    this.debouncedOnUnpackSubmit(e, e.currentTarget);
  };

  handleUnpackOrderSubmit = (e, form) => {
    e.preventDefault();
    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true, isSubmitting: false });
    } else {
      this.setState({ isSubmitting: true }, () => {
        unpackCustomerOrder(this.props.match.params.id, {
          variants_locations: this.state.variants_locations,
        })
          .then((res) => {
            if (res.status_code === 200 || res.status_code === 201) {
              toast.success(res.message);
              this.loadData();
              this.setState({ isSubmitting: false });
            } else {
              toast.error(res.message);
              this.setState({ isSubmitting: false });
            }
          })
          .catch((errMsg) => {
            toast.error(errMsg);
            this.setState({ isSubmitting: false });
          });
      });
    }
  };

  debouncedOnCancelOrder = _.debounce(() => {
    this.cancelOrder();
  }, DEBOUNCE_TIME);

  handleWithDebounceCancelOrder = () => {
    this.debouncedOnCancelOrder();
  };

  cancelOrder = () => {
    if (this.state.changeStatusMsg === "") {
      this.setState({ StatusMsgRequired: true });
      return;
    }
    cancelCustomerOrder(this.props.match.params.id, {
      cancellation_reason: this.state.changeStatusMsg,
    })
      .then((res) => {
        this.setState({
          cancelled: !this.state.cancelled,
          isShowingStatusChangeConfirmModal: false,
        });
        toast.success("Order cancelled!");
        this.loadData();
      })
      .catch((errMsg) => {
        toast.error(errMsg);
        this.setState({ isSubmitting: false });
      });
  };

  setLineItemLocation = (e, order_item_id) => {
    const orderLocations = { ...this.state.orderLocations };
    orderLocations[order_item_id] = e.target.value;
    this.setState({ orderLocations: orderLocations });
  };

  setVariantsLocations = (e, variant_id) => {
    let { variants_locations } = this.state;
    const found = variants_locations.find(
      (element) => element.variant_id === variant_id
    );

    if (found === undefined) {
      variants_locations.push({ variant_id, location_id: e.target.value });
    } else {
      const index = variants_locations.findIndex(
        (x) => x.variant_id === variant_id
      );
      variants_locations[index].location_id = e.target.value;
    }

    this.setState({ variants_locations });
  };

  handleCustomerDetailsLog = (id) => {
    getData(`customer_orders/${id}/changes_log`)
      .then((res) => {
        this.setState({
          ...this.state,
          data: res?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { orderDetail } = this.state;
    const { routeDetails } = this.state;
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Order Details</h2>
            <div className="ml-auto">
              <Link
                onClick={() => this.props.history.go(-1)}
                className="btn btn-sm btn-link"
              >
                <FaLongArrowAltLeft /> Back to Previous
              </Link>
            </div>
          </div>
          {orderDetail?.cancellable && (
            <Modal
              show={this.state.isShowingStatusChangeConfirmModal}
              onHide={this.hideChangeStatusModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Cancellation Reason</Modal.Title>
              </Modal.Header>
              <Form onSubmit={this.submitChangeStatusForm}>
                <Modal.Body>
                  <Form.Control
                    as={`textarea`}
                    onChange={(e) => {
                      this.setState({ changeStatusMsg: e.target.value });
                    }}
                    placeholder={`Enter you message here...`}
                    rows={3}
                  />
                  {this.state.StatusMsgRequired && (
                    <span style={{ color: "red" }}>
                      Please insert cancellation reason
                    </span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="default"
                    onClick={this.hideChangeStatusModal}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={this.state.isSubmitting}
                    variant="primary"
                    type={`button`}
                    onClick={() => {
                      this.handleWithDebounceCancelOrder();
                    }}
                  >
                    {this.state.isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          )}

          {this.state.isLoading ? (
            <div className={`card-block`}>
              <div className="block-body">Loading...</div>
            </div>
          ) : (
            <div className="card-block">
              <>
                <Form
                  noValidate
                  validated={this.state.isFormValidated}
                  onSubmit={
                    !this.state.orderToBeUnpacked
                      ? this.handleWithDebouncePackSubmit
                      : this.handleWithDebounceUnpackSubmit
                  }
                >
                  <div className="block-header text-right">
                    {warehouseType !== "central" && (
                      <>
                        <div className="block-title">
                          {this.state.orderDetail?.status_key ===
                            "order_placed" ? (
                            //!this.state.readyToShip && !this.state.cancelled &&
                            // <Button
                            //   size="sm"
                            //   type={"button"}
                            //   className="mr-1"
                            //   onClick={(e) => {
                            //     e.preventDefault()
                            //     this.setState({
                            //       packedStatus: !this.state.packedStatus,
                            //       colspan: this.state.packedStatus ? 4 : 6,
                            //     })
                            //   }}
                            //   variant="info"
                            //   disabled={orderDetail.pay_type !== "Cash on delivery" && orderDetail.is_customer_paid === false}
                            //   >

                            //   Pack
                            // </Button>
                            !(orderDetail.pay_type == "Cash on delivery" || orderDetail.pay_type == "Credit") &&
                              orderDetail.is_customer_paid === false ? (
                              <Button
                                disabled
                                className="mr-1"
                                size="sm"
                                type={"button"}
                              >
                                Pack
                              </Button>
                            ) : (
                              <Link
                                to={`/customerOrder/pack/${orderDetail?.order_id}`}
                                className="btn btn-sm btn-primary mr-2"
                              >
                                Pack
                              </Link>
                            )
                          ) : (
                            ""
                          )}
                          {this.state.packedCancelled && (
                            <Button
                              size="sm"
                              type={"button"}
                              className="mr-1"
                              onClick={() => {
                                this.setState({
                                  orderToBeUnpacked:
                                    !this.state.orderToBeUnpacked,
                                  colspan: this.state.orderToBeUnpacked ? 4 : 5,
                                });
                              }}
                              variant="info"
                            >
                              Unpack
                            </Button>
                          )}
                          {orderDetail?.cancellable && (
                            <Button
                              size="sm"
                              className="mr-1"
                              type={"button"}
                              onClick={() => {
                                // this.cancelOrder();
                                this.setState({
                                  isShowingStatusChangeConfirmModal: true,
                                });
                              }}
                              variant="danger"
                            >
                              Cancel Order
                            </Button>
                          )}
                          {orderDetail?.status !== "Undelivered" && (
                            <Button
                              size="sm"
                              type={"button"}
                              className="mr-1"
                              onClick={() => {
                                this.setState({ isModalOpen: true });
                              }}
                              variant="info"
                            >
                              Reschedule Delivery Date
                            </Button>
                          )}

                        </div>

                        {this.state.isModalOpen ? (
                          <RescheduleDeliveryDate
                            isModalOpen={this.state.isModalOpen}
                            toggleModalState={this.toggleModalState}
                            order_id={this.props.match.params.id}
                            loadData={this.loadData}
                          />
                        ) : null}

                        <div className="right-section">
                          {((!this.state.readyToShip &&
                            this.state.packedStatus) ||
                            this.state.orderToBeUnpacked) && (
                              <>
                                <Button
                                  size="sm"
                                  className="mr-1"
                                  type={"button"}
                                  onClick={() => {
                                    this.state.orderToBeUnpacked
                                      ? this.setState({
                                        orderToBeUnpacked:
                                          !this.state.orderToBeUnpacked,
                                        colspan: this.state.orderToBeUnpacked
                                          ? 4
                                          : 5,
                                      })
                                      : this.setState({
                                        packedStatus: !this.state.packedStatus,
                                        colspan: this.state.packedStatus
                                          ? 4
                                          : 6,
                                      });
                                  }}
                                  variant="danger"
                                >
                                  Close
                                </Button>
                                <Button
                                  size="sm"
                                  type={"submit"}
                                  /*onClick={() => {
                                    this.submitPack(orderDetail)
                                  }}*/
                                  disabled={
                                    this.state.qrCodeMatching.length > 0 ||
                                    this.state.isSubmitting
                                  }
                                  variant="primary"
                                >
                                  {this.state.isSubmitting
                                    ? "Submitting..."
                                    : "Submit"}
                                </Button>
                              </>
                            )}
                        </div>
                      </>
                    )}

                    {!this.state.packedStatus &&
                      !this.state.orderToBeUnpacked && (
                        <div>
                          <ReactToPrint
                            trigger={() => {
                              return (
                                <a href="#">
                                  <Button
                                    variant={"default"}
                                    onClick={this.handlePrint}
                                    size={"sm"}
                                  >
                                    <FaPrint /> Print Invoice
                                  </Button>
                                </a>
                              );
                            }}
                            content={() => this.componentRef}
                          />
                        </div>
                      )}
                  </div>
                  {this.state.readyToShip &&
                    orderDetail?.shipping_type !== "Pick up point" && (
                      <div className="block-body">
                        <td>
                          <div>
                            <b>Assign Rider</b>
                          </div>
                          <select
                            id="rider"
                            name="rider"
                            value={this.state.riderId}
                            className="form-control form-control-sm"
                            required
                            onChange={(e) => this.assignRider(e)}
                          >
                            <option value="">--Select Rider--</option>
                            {this.state.ridersList &&
                              this.state.ridersList?.map((ride, index) => {
                                return (
                                  <option value={ride?.id}>{ride?.name}</option>
                                );
                              })}
                          </select>
                        </td>
                      </div>
                    )}
                  <div
                    className="block-body print-outer"
                    ref={(el) => (this.componentRef = el)}
                  >
                    <div className="only-print">
                      <br />
                      <br />
                      <div className="text-alignment-center">
                        <h6>
                          <span>
                            Government of the People's Republic of Bangladesh
                          </span>
                          <br />
                          <span className="bold">
                            National Board of Revenue <br />
                          </span>
                        </h6>
                        <br />
                        <h7>
                          <span>
                            <span className="bold">Tax Challan</span> <br />
                            [See clauses (c) and (f) of Sub-Rule(1) of Rule 40]
                          </span>
                          <br />
                          <br />
                          <span>
                            Agami LTD - Online Sales Division (SHOPOTH)
                            <br />
                            Registered Address : T-131, Wakil Tower <br />
                            Level : 8, Middle Badda, Link Road, Dhaka, PO : 1212
                            <br />
                          </span>
                          <span>
                            Central Vat. Reg. No :{" "}
                            {getDate(orderDetail?.order_at) >=
                              moment("2021-09-19")
                              ? "003279334-0101"
                              : "003279334-0203"}{" "}
                          </span>
                        </h7>
                      </div>
                      <br />
                      <br />
                      <QRCode value={`${orderDetail?.order_id}`} />
                      <br />
                      <h7 className="text-alignment-right-mushok">
                        Mushok 6.3
                      </h7>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>

                    <table className="invoice-table">
                      <tbody>
                        <tr className="v-top">
                          <td>
                            <h3 className="invoice-title">
                              Order # 0000{orderDetail?.order_id}
                            </h3>
                            <span className="d-block">
                              <strong>Status</strong> :{" "}
                              {humanize(orderDetail?.status)}
                            </span>
                            <span className="d-block">
                              <strong>Distributor</strong> :{" "}
                              {orderDetail?.distributor_name}
                            </span>
                            <span className="d-block">
                              <strong>Order Date</strong> :{" "}
                              {getFormattedDate(
                                orderDetail?.order_at,
                                "LT, ll"
                              )}
                            </span>
                            <span
                              className={`d-block ${orderDetail?.preferred_delivery_date
                                ? ""
                                : "mb-2"
                                }`}
                            >
                              {orderDetail?.business_type !== "multicat" ? (
                                <>
                                  <strong>Order Deadline</strong> : " "
                                  {orderDetail?.shipping_type ===
                                    "Express delivery"
                                    ? getDate(orderDetail?.order_at)
                                      .add(3, "hours")
                                      .format("LT, ll")
                                    : getDate(orderDetail?.order_at)
                                      .add(72, "hours")
                                      .format("LT, ll")}
                                </>
                              ) : (
                                <></>
                              )}
                            </span>

                            {orderDetail?.preferred_delivery_date && (
                              <span className="d-block mb-2">
                                <strong>Preferred Delivery Date</strong> : {" "}
                              {orderDetail?.preferred_delivery_date}
                              </span>
                            )}
                            <span className="d-block">
                              <strong>Sale Type : </strong>
                              {orderDetail?.sales_type.replace('_', ' ').toUpperCase()}
                            </span>
                          </td>
                          <td className="text-right">
                            <span className="d-block">
                              <h6>Customer Information</h6>
                            </span>
                            <span className="d-block">
                              {orderDetail?.customer?.name}
                            </span>
                            <span className="d-block">
                              {orderDetail?.customer?.email}
                            </span>
                            <span className="d-block">
                              {orderDetail?.customer?.phone}
                            </span>
                            <span className="d-block">
                              NID :{" "}
                              {orderDetail?.nid ? orderDetail?.nid : "N/A"}
                            </span>
                            <span className="d-block">
                              BIN :{" "}
                              {orderDetail?.bin ? orderDetail?.bin : "N/A"}
                            </span>
                            <span className="d-block">
                              TIN :{" "}
                              {orderDetail?.tin ? orderDetail?.tin : "N/A"}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="2"></td>
                        </tr>

                        <tr>
                          <td colSpan="2">
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ paddingBottom: "20px" }}>
                                    <h4 className="invoice-title-alt">
                                      Sales Type
                                    </h4>
                                    <span className="d-block">
                                      {orderDetail?.sales_type.replace('_', ' ').toUpperCase()}
                                    </span>
                                  </td>
                                  {(orderDetail?.shipping_type ===
                                    "Home delivery" ||
                                    orderDetail?.shipping_type ===
                                    "Express delivery") && (
                                      <>
                                        <td style={{ paddingBottom: "20px" }}>
                                          <h4 className="invoice-title-alt">
                                            Shipping Address
                                          </h4>
                                          <span className="d-block">
                                            {
                                              orderDetail?.shipping_address
                                                ?.address_line
                                            }
                                          </span>
                                          <span className="d-block">
                                            {orderDetail?.shipping_address?.area}
                                          </span>
                                          <span className="d-block">
                                            {orderDetail?.shipping_address?.thana}
                                          </span>
                                          <span className="d-block">
                                            {
                                              orderDetail?.shipping_address
                                                ?.district
                                            }
                                          </span>
                                          <span className="d-block">
                                            {orderDetail?.shipping_address?.phone}
                                          </span>
                                        </td>

                                        <td style={{ paddingBottom: "20px" }}>
                                          <h4 className="invoice-title-alt">
                                            Billing Address
                                          </h4>
                                          <span className="d-block">
                                            {
                                              orderDetail?.billing_address
                                                ?.address_line
                                            }
                                          </span>
                                          <span className="d-block">
                                            {orderDetail?.billing_address?.area}
                                          </span>
                                          <span className="d-block">
                                            {orderDetail?.billing_address?.thana}
                                          </span>
                                          <span className="d-block">
                                            {
                                              orderDetail?.billing_address
                                                ?.district
                                            }
                                          </span>
                                          <span className="d-block">
                                            {orderDetail?.billing_address?.phone}
                                          </span>
                                        </td>

                                        {orderDetail?.rider?.name && (
                                          <td style={{ paddingBottom: "20px" }}>
                                            <h4 className="invoice-title-alt">
                                              Rider Info
                                            </h4>
                                            <span className="d-block">
                                              {orderDetail?.rider?.name}
                                            </span>
                                            <span className="d-block">
                                              {orderDetail?.rider?.phone}
                                            </span>
                                          </td>
                                        )}
                                      </>
                                    )}
                                  {orderDetail?.shipping_type ===
                                    "Pick up point" &&
                                    Object.keys(orderDetail.partner).length >
                                    0 && (
                                      <td style={{ paddingBottom: "20px" }}>
                                        <h4 className="invoice-title-alt">
                                          Pick-up Point
                                        </h4>
                                        <span className="d-block">
                                          {orderDetail?.partner?.name}
                                        </span>
                                        <span className="d-block">
                                          {orderDetail?.partner?.phone}
                                        </span>
                                        <span className="d-block">
                                          {orderDetail?.partner?.email}
                                        </span>
                                        <span className="d-block">
                                          {orderDetail?.partner?.area}
                                        </span>
                                        <span className="d-block">
                                          {this.state.schedule &&
                                            this.state.schedule.map(
                                              (item, index, array) =>
                                                index < array.length - 1 ? (
                                                  <>
                                                    <span className="text">
                                                      {item}
                                                    </span>
                                                    ,{" "}
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    &{" "}
                                                    <span className="text">
                                                      {item}
                                                    </span>{" "}
                                                  </>
                                                )
                                            )}
                                        </span>
                                        <span className="d-block">
                                        Delivery Man Name : {routeDetails?.title}
                                        </span>
                                        <span className="d-block">
                                          Section :{" "}
                                          {orderDetail?.partner?.section}
                                        </span>
                                        {/* <span className="d-block">Distributor : {_.capitalize(orderDetail?.partner?.distributor_name)}</span> */}
                                      </td>
                                    )}
                                  {!_.isEmpty(orderDetail.receiver_info) && (
                                    <td style={{ paddingBottom: "20px" }}>
                                      <h4 className="invoice-title-alt">
                                        Receiver Info
                                      </h4>
                                      <span className="d-block">
                                        {orderDetail?.receiver_info?.name}
                                      </span>
                                      <span className="d-block">
                                        {orderDetail?.receiver_info?.phone}
                                      </span>
                                    </td>
                                  )}
                                  <td style={{ paddingBottom: "20px" }}>
                                    <h4 className="invoice-title-alt">
                                      Payment
                                    </h4>
                                    <span className="d-block">
                                      Type: {orderDetail.pay_type}
                                    </span>
                                    <span className="d-block">
                                      Status:{" "}
                                      {orderDetail.is_customer_paid
                                        ? "Paid"
                                        : "Pending"}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2}>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th className={"text-center"}>Unit Price</th>
                                  <th className={"text-center"}>Quantity</th>
                                  <th className={"text-center"}>Discount</th>
                                  {this.state.packedStatus && (
                                    <th style={{ width: "200px" }}>QR Code</th>
                                  )}
                                  {(this.state.packedStatus ||
                                    this.state.orderToBeUnpacked) && (
                                      <th style={{ width: "200px" }}>
                                        Select Location
                                      </th>
                                    )}
                                  <th className="text-right">Amount (৳)</th>
                                </tr>
                              </thead>

                              <tbody>
                                {orderDetail?.shopoth_line_items &&
                                  orderDetail?.shopoth_line_items.length > 0
                                  ? orderDetail?.shopoth_line_items?.map(
                                    (item, index) => (
                                      <React.Fragment key={index}>
                                        <tr key={index + 1}>
                                          <td width={400}>
                                            <span className="d-block">
                                              <strong>
                                                {item.item?.product_title}
                                              </strong>
                                            </span>
                                            <span className="d-block text-muted">
                                              {item.item.product_attribute_values.map(
                                                (value, index) => {
                                                  return (
                                                    <>
                                                      {value.value}
                                                      {item.item
                                                        .product_attribute_values
                                                        .length >
                                                        index + 1
                                                        ? "-"
                                                        : ""}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </span>
                                          </td>
                                          <td className={`text-center`}>
                                            {twoDecimalPlace(
                                              item.item.unit_price
                                            )}
                                          </td>
                                          <td className={`text-center`}>
                                            {item.quantity}
                                          </td>
                                          <td className={`text-center`}>
                                            {twoDecimalPlace(
                                              item.item.product_discount
                                            )}
                                          </td>

                                          {!this.state.readyToShip &&
                                            this.state.packedStatus && (
                                              <td className={`text-center`}>
                                                {this.generateQRInput(
                                                  item,
                                                  index
                                                )}
                                              </td>
                                            )}
                                          {!this.state.readyToShip &&
                                            this.state.packedStatus && (
                                              <td className={`text-center`}>
                                                <select
                                                  id="location"
                                                  required
                                                  name="location_id"
                                                  className="form-control form-control-sm"
                                                  onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                    }
                                                  }}
                                                  onChange={(e) => {
                                                    this.setLineItemLocation(
                                                      e,
                                                      item.shopoth_line_item_id
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    --Locations--
                                                  </option>
                                                  {item?.locations?.length > 0
                                                    ? item?.locations?.map(
                                                      (item) => {
                                                        return (
                                                          <option
                                                            value={item?.id}
                                                          >
                                                            {item?.code}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                    : ""}
                                                </select>
                                              </td>
                                            )}
                                          {this.state.orderToBeUnpacked && (
                                            <td className={`text-center`}>
                                              <select
                                                id="location"
                                                required
                                                name="location_id"
                                                className="form-control form-control-sm"
                                                onKeyPress={(e) => {
                                                  if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  this.setVariantsLocations(
                                                    e,
                                                    item.item?.variant_id
                                                  );
                                                }}
                                              >
                                                <option value="">
                                                  --Locations--
                                                </option>
                                                {this.state.locations
                                                  ?.length > 0
                                                  ? this.state.locations?.map(
                                                    (item) => {
                                                      return (
                                                        <option
                                                          value={item?.id}
                                                        >
                                                          {item?.code}
                                                        </option>
                                                      );
                                                    }
                                                  )
                                                  : ""}
                                              </select>
                                            </td>
                                          )}
                                          <td className="text-right">
                                            {twoDecimalPlace(item?.sub_total)}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    )
                                  )
                                  : ""}
                                <tr className="text-bold">
                                  <td
                                    colSpan={this.state.colspan}
                                    className="text-right"
                                  >
                                    Sub-Total (inclusive of VAT @ 5% *)
                                  </td>
                                  <td className="text-right">
                                    {twoDecimalPlace(orderDetail?.sub_total)}
                                  </td>
                                </tr>

                                <tr className="text-bold">
                                  <td
                                    colSpan={this.state.colspan}
                                    className="text-right"
                                  >
                                    Discount
                                  </td>
                                  <td className="text-right">
                                    {twoDecimalPlace(
                                      orderDetail?.total_discount_amount
                                    )}
                                  </td>
                                </tr>

                                <tr className="text-bold">
                                  <td
                                    colSpan={this.state.colspan}
                                    className="text-right"
                                  >
                                    Shipping Charge
                                  </td>
                                  <td className="text-right">
                                    {twoDecimalPlace(
                                      orderDetail?.shipping_charge
                                    )}
                                  </td>
                                </tr>
                                <tr className="text-bold">
                                  <td
                                    colSpan={this.state.colspan}
                                    className="text-right"
                                  >
                                    VAT@ 15% on Shipping Charge
                                  </td>
                                  <td className="text-right">
                                    {twoDecimalPlace(
                                      orderDetail?.vat_shipping_charge
                                    )}
                                  </td>
                                </tr>

                                <tr className="text-bold">
                                  <td
                                    colSpan={this.state.colspan}
                                    className="text-right"
                                  >
                                    Grand Total
                                  </td>
                                  <td className="text-right">
                                    {twoDecimalPlace(orderDetail?.grand_total)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h6>
                      {" "}
                      <i>
                        *VAT @ 5% is on the differential between Sales &
                        Purchase price
                      </i>
                    </h6>
                  </div>
                </Form>
              </>
            </div>
          )}
          {warehouseType === "central" && (
            <Button
              size="lg"
              type={"button"}
              className="mb-4"
              onClick={() =>
                this.handleCustomerDetailsLog(this.props.match.params.id)
              }
              variant="info"
            >
              Status Log
            </Button>
          )}

          {this.state.data.length > 0 && (
            <table className="order-table table table-striped">
              <thead>
                <tr>
                  <th>Created At</th>
                  <th>Created By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data?.map((item, i) => {
                  return (
                    <tr>
                      <td>
                        <div className="order-item">
                          <h4 className="order-id mb-0">
                            {new Date(item?.created_at).toLocaleString()}
                          </h4>
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          <h4 className="order-id mb-0">
                            {humanize(item?.changed_by?.name)}
                          </h4>
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          <h4 className="order-id mb-0">
                            {humanize(item.order_status)}
                          </h4>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </App>
    );
  }
}

export default withRouter(OrderDetails);
