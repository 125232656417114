import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import App from './../../App';
import { getPaginatedList } from '../../services/baseServices';
import { FiEye } from 'react-icons/all';

class RequestedList extends Component {
    constructor() {
        super();
        this.state = {
            requests: [],
            activePage: 1,
            per_page :25,
            page:1,
            isLoading: false,
            temp_params: {},
            query_params: {},
        };
    }

    componentDidMount() {
        this.getPaginatedRequests(this.getQueryParams());
    }

    getQueryParams = () => {
        let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
        return params;
    }

    getPaginatedRequests = (params) => {
        this.setState({...this.state, isLoading: true});
        getPaginatedList("requested_variants",params)
          .then(res => {
              if(res.data?.success === true){
                this.setState({
                    requests: res.data.data, 
                    isLoading: false,
                    per_page: parseInt(res.headers['x-per-page']),
                    total_data: parseInt(res.headers['x-total']),
                  }, () => {
                  });
              }else{
                this.setState({...this.state, isLoading: false});
              }
          })
          .catch(errMsg => {
            this.setState({...this.state, isLoading: false});
            console.log('Error Message: ', errMsg)
          })
    }

    handlePageChange = (pageNumber) => {
      this.setState({page: pageNumber, activePage: pageNumber}, () => {
          this.getPaginatedRequests(this.getQueryParams());
      });
  }


    render() {
        return (
            <App layout="">
          <div className="main-content">
            <div className="page-header">
              <h2 className="page-title">Product request List</h2>
            </div>

              <div className="card-block">
                <div className="block-body">
                  <Loader loaded={!this.state.isLoading}>
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th style={{width: '20px'}}>ID</th>
                        <th>Product Title</th>
                        <th>Variant Id</th>
                        <th>SKU</th>
                        <th>Warehouse</th>
                        <th>Total Requested</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.requests.length > 0 ? this.state.requests.map((item) => {
                        return (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>
                                <div className="order-item">
                                    {item.product_title}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.variant_id}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.sku}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.warehouse}
                                </div>
                              </td>
                              <td>
                                  <div className="order-item">
                                    {item?.total_request_count}
                                  </div>
                              </td>
                              <td style={{width: '150px'}}>
                                <div className="btn-group">
                                  <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          View Details
                                        </Tooltip>
                                      }
                                  >
                                  <Link to={`/product-request/details/${item.variant_id}`} className="btn btn-sm btn-default"><FiEye/></Link>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                        );
                      }) : ""}
                      </tbody>
                    </table>
                  </Loader>
                  </div>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
          
            </div>
        </App>
        );
    }
}

export default RequestedList;