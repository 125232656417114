import React, { Component } from "react";
import App from "../../App";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FiPlus } from "react-icons/all";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../helper/env";
import { getLocalAuthData } from "../../helper/utils";

class DeliveryKpi extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      uploading: false,
      selectedFileName: "",
    };
  }

  handleFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({
      selectedFile: file,
      selectedFileName: file ? file.name : "",
    });
  };

  handleUpload = () => {
    if (this.state.selectedFile && !this.state.uploading) {
      this.setState({ uploading: true });
      this.uploadCSV();
    } else {
      toast.error("Please select a CSV file to upload.");
    }
  };

  uploadCSV = () => {
    const formData = new FormData();
    formData.append("delivery_target_file", this.state.selectedFile);

    axios
      .post(`${API_BASE_URL}/delivery_targets/set_target`, formData)
      .then((response) => {
        console.log("Upload successful", response.data);
        toast.success("Upload successful");
        this.setState({
          selectedFile: null,
          selectedFileName: "",
          uploading: false,
        });
      })
      .catch((error) => {
        console.error("Error uploading CSV", error);
        toast.error("Error uploading CSV");
        this.setState({
          selectedFile: null,
          selectedFileName: "",
          uploading: false,
        });
      });
  };

  render() {
    {
      console.log(getLocalAuthData, "getLocalAuthData");
    }
    return (
      <App>
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Delivery KPI</h2>
            <div className="ml-auto d-flex justify-content-center">
              <label
                htmlFor="fileInput"
                className="btn btn-sm btn-secondary ml-2"
              >
                <FiPlus /> Select CSV file
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={this.handleFileChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            onClick={this.handleUpload}
            className="btn btn-sm btn-secondary ml-2"
            disabled={this.state.uploading || !this.state.selectedFile}
            style={{
              opacity:
                this.state.uploading || !this.state.selectedFile ? 0.5 : 1,
            }}
          >
            {this.state.uploading ? "Uploading..." : "Upload"}
          </Button>
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.state.selectedFileName && (
            <div className="ml-2">
              <b>Selected File: </b>
              {this.state.selectedFileName}
            </div>
          )}
        </div>
      </App>
    );
  }
}

export default withRouter(DeliveryKpi);
