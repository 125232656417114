import React, { Component } from 'react';
import '../../../src/assets/scss/weekdays.scss'


class WeekdayPicker extends Component {

  render() {
    return (
        <div id="weekdays"> 
            <ul className="weekdays-list">
                {
                    this.props.weekDays.map((item, i) => {
                        return(
                            <li key={i} className={"weekdays-day "+(item.selected?"selected":"")} onClick={(e)=>this.props.onDaySelect(item.day_value)}>{item.day_name}</li>
                        )
                    })
                }
            </ul>
        </div>
    );
  }
}

export default WeekdayPicker;
