import React from "react";
import Select from "react-select";
import styleReactSelect from "../assets/styleReactSelect";

const SelectDeliveryPerson = (props) => {
  return (
    <>
      <Select
        key={1}
        styles={styleReactSelect}
        placeholder="Select person..."
        value={props.selectedDeliveryPerson}
        onChange={(value) => props.handleChange(value)}
        options={props?.deliveryPersons || []}
        name="attributes"
        getOptionLabel={(option) => option.sr_name}
        getOptionValue={(option) => option.id}
      />
    </>
  );
};

export default SelectDeliveryPerson;
