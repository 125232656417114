import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import { getDetails, getList } from "../../services/baseServices";
import { Button } from "react-bootstrap";
import LocationTransferModal from "../../components/LocationTransferModal";
import { getWarehouseType } from "../../helper/utils";

class Details extends Component {
  constructor() {
    super();
    this.state = {
      location: {},
      locationList: [],
      currentLocation: {},
      quantity: {},
      showLocationTransferModal: false,
      locationDetailsForModals: {},
      productVariants: [],
    };
  }

  getData = (id) => {
    getDetails("locations", id)
      .then((res) => {
        let locations = {
          id: res.id,
          code: res.code,
          variants: res.variants.map((item) => {
            return {
              variant_id: item.variant_id,
              product_id: item.product_id,
              product_title: item.product_title,
              quantity: item.quantity,
              sku: item.sku,
              isLocationTransfer: false,
            };
          }),
        };
        this.setState({ location: locations });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
  }

  componentDidMount() {
    getList("locations")
      .then((res) => {
        this.setState({ locationList: res });
      })
      .catch((errMsg) => { });

    this.getData(this.props.match.params.id)
  }

  avalibleLocation = () => {
    let locations = [...this.state.locationList];
    let current_loc = { ...this.state.location };
    let ablLocation = locations.filter((item) => item.id !== current_loc.id);
    this.setState({ locationList: ablLocation });
  };

  showOrHideLocationTransferOption = (index) => {
    let newObj = { ...this.state.location };
    let isLocationTranferVal = newObj.variants[index].isLocationTransfer;
    newObj.variants[index].isLocationTransfer = !isLocationTranferVal;
    this.setState({
      location: newObj,
    });
    this.avalibleLocation();
  };
  inputQuantity = (index, e) => {
    let quantityObj = {};
    quantityObj["newQuantity"] = e.target.value;
    quantityObj["oldQuantity"] = this.state.location.variants[index].quantity;
    this.setState({ quantity: quantityObj });
  };
  handleLocationTransferModal = (refresh) => {
    this.setState({ showLocationTransferModal: !this.state.showLocationTransferModal, }, () => {
      if (refresh) this.getData(this.props.match.params.id)
    });
  };

  setCurrentLocation = (e) => {
    let index = e.target.value;
    let newLocationArr = [...this.state.locationList];
    let locationObj = {};
    locationObj["newLocationId"] = newLocationArr[index].id;
    locationObj["newLocationName"] = newLocationArr[index].code;
    this.setState({ currentLocation: { ...locationObj } });
  };
  handleOnSubmit = (e, variant_id, product_title) => {
    e.preventDefault();
    this.setState({
      locationDetailsForModals: {
        ...this.state.quantity,
        ...this.state.currentLocation,
        oldLocationId: this.state.location?.id,
        oldLocationName: this.state.location?.code,
        variant_id,
        product_title,
      },
    });
    this.handleLocationTransferModal(false);
  };
  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="page-title" />
            <div className="ml-auto">
              <Link
                to={`${process.env.PUBLIC_URL}/location/list`}
                className={`btn btn-sm btn-link`}
              >
                <MdArrowBack /> Back to List
              </Link>
            </div>
          </div>
          <LocationTransferModal
            show={this.state.showLocationTransferModal}
            handleClose={this.handleLocationTransferModal}
            locationDetailsForModals={this.state.locationDetailsForModals}
          />
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Location Details</h4>
            </div>
            <div className="block-body">
              <>
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "130px" }}>Location Code</td>
                      <td style={{ width: "30px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {this.state.location?.code}
                      </td>
                    </tr>
                    {
                      getWarehouseType() === 'central' && <tr>
                        <td style={{ width: "130px" }}>Created By</td>
                        <td style={{ width: "30px", textAlign: "center" }}>:</td>
                        <td className={`text-bold`}>
                          {this.state.location?.created_by?.name}
                        </td>
                      </tr>
                    }

                  </tbody>
                </table>
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      <th>Product Variant</th>
                      <th>SKU</th>
                      <th className="text-left">Quantity</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.location.variants &&
                      this.state.location.variants.map((item, index) => {
                        return (
                          <tr key={item.variant_id}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`${process.env.PUBLIC_URL}/product/details/${item.product_id}`}
                              >
                                {item.product_title}
                              </Link>
                            </td>
                            <td>{item.sku}</td>
                            <td className="text-center">{item.quantity}</td>
                            <td className="d-flex justify-content-center">
                              {item.isLocationTransfer ? (
                                <form
                                  className="form-inline"
                                  onSubmit={(e) =>
                                    this.handleOnSubmit(
                                      e,
                                      item.variant_id,
                                      item.product_title
                                    )
                                  }
                                >
                                  <div className="d-flex">
                                    <select
                                      required
                                      id="location"
                                      name="location_id"
                                      className="form-control form-control-sm mr-2"
                                      onChange={(e) =>
                                        this.setCurrentLocation(e)
                                      }
                                    >
                                      <option value="">--Locations--</option>
                                      {this.state.locationList.length > 0
                                        ? this.state.locationList.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                value={index}
                                                key={index}
                                              >
                                                {item.code}
                                              </option>
                                            );
                                          }
                                        )
                                        : ""}
                                    </select>
                                    <input
                                      required
                                      type="number"
                                      className="form-control form-control-sm"
                                      name="qunatity"
                                      min="1"
                                      max={item.quantity}
                                      style={{ width: "80px" }}
                                      placeholder="Qty"
                                      onChange={(e) =>
                                        this.inputQuantity(index, e)
                                      }
                                    />
                                    <button
                                      className="btn btn-sm btn-info text-white ml-2"
                                      type="submit"
                                    >
                                      Apply
                                    </button>
                                    <button
                                      className="btn btn-sm btn-danger text-white ml-2"
                                      onClick={() =>
                                        this.showOrHideLocationTransferOption(
                                          index
                                        )
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                </form>
                              ) : (
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    this.showOrHideLocationTransferOption(index)
                                  }
                                >
                                  Transfer
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);
