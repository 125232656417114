import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {MdArrowBack} from "react-icons/all";
import {Button, Col, Form, Row} from "react-bootstrap";
import { connectRouteDevice, disconnectRouteDevice } from "../../services/routeServices";
import { getDetails } from "../../services/baseServices";
import {toast} from "react-toastify";
import { getWarehouseType } from "../../helper/utils";

const wareHouseType = getWarehouseType()
class Details extends Component {
  state = {
    device_id: null,
    route_id: null,
    formData: {},
    routeInfo: {},
    showModal: true,
    showChangePassForm: false,
    showChangeDeviceForm: false,
    showConnectDeviceForm: false,
    showConnectDeviceBtn: true,
  };

  componentDidMount() {
    getDetails("routes", this.props.match.params.id)
      .then(res => {
        this.setState({ routeInfo: res });
        this.setState({ device_id: res.route_device.device_id });
        this.setState({ route_id: res.route_device.route_id });
        const formData = {...this.state.formData};
        this.setState({formData}, () => {
          console.log(this.state.formData);
        });
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      });
  }
  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
    });
  }
  disconnectDevice = () => {
    disconnectRouteDevice(this.props.match.params.id)
      .then(res => {
        this.setState({device_id: res.device_id});
        this.setState({showConnectDeviceForm: false});
        this.props.history.push(`/route/details/${this.props.match.params.id}`);
        toast.success("added");
      })
      .catch(errMsg => {
        toast.error(errMsg);
        this.setState({isSubmitting: false})
      });
  }

  connectDevice = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({isSubmitting: true}, () => {
        const formData = {...this.state.formData}
        formData['route_id'] = this.props.match.params.id;
        connectRouteDevice("route_devices/connect","route_device", formData)
          .then(res => {
            this.setState({device_id: res.device_id});
            this.setState({showConnectDeviceForm: false});
            this.props.history.push(`/route/details/${this.props.match.params.id}`);
            toast.success("added");
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }
  render() {
    const {details} = this.state;

    return (
      <App layout={`boxed`}>
        <div className="main-content">
          <div className="page-header">
            <div className="page-title"/>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/route/list`} className={`btn btn-sm btn-link`}><MdArrowBack/> Back to
                List</Link>
            </div>
          </div>

          <div className="card-block">
            {
               wareHouseType !== 'central' &&
               <div className="block-header">
              <h4 className="block-title">Delivery Man Details</h4>
              <span className="block-title">
                {!this.state.device_id &&
                  <Button
                    size="sm"
                    type={"button"}
                    className="mr-1"
                    onClick={() => {
                      this.setState({
                        showConnectDeviceForm: !this.state.showConnectDeviceForm
                      })
                    }}
                    variant="info">
                    Connect Device
                  </Button>
                }
                {this.state.device_id &&
                  <Button
                    size="sm"
                    type={"button"}
                    className="mr-1"
                    onClick={() => {
                      this.disconnectDevice(this.props.match.params.id);
                      this.setState({ showConnectDeviceForm: true });
                    }}
                    variant="danger">
                    Disconnect
                  </Button>
                }
                </span>
            </div>
            }
            
            <div className="block-body">
              <>
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{width: '250px'}}>Distributor</td>
                      <td style={{width: '20px', textAlign: 'center'}}>:</td>
                      <td className={`text-bold`}>{this.state.routeInfo.distributor_name}</td>
                    </tr>
                    <tr>
                      <td style={{width: '250px'}}>Delivery Man Title</td>
                      <td style={{width: '20px', textAlign: 'center'}}>:</td>
                      <td className={`text-bold`}>{this.state.routeInfo.title}</td>
                    </tr>
                    <tr>
                      <td style={{width: '250px'}}>Delivery Man Title in Bangla</td>
                      <td style={{width: '20px', textAlign: 'center'}}>:</td>
                      <td className={`text-bold`}>{this.state.routeInfo.bn_title}</td>
                    </tr>
                    <tr>
                      <td style={{width: '250px'}}>Phone</td>
                      <td style={{width: '20px', textAlign: 'center'}}>:</td>
                      <td>{this.state.routeInfo.phone}</td>
                    </tr>
                    <tr>
                      <td style={{width: '250px'}}>Device No.</td>
                      <td style={{width: '20px', textAlign: 'center'}}>:</td>
                      <td className={`text-bold`}>{this.state.device_id}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            </div>
          </div>

          {this.state.showConnectDeviceForm &&
          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Connect Device</h4>
            </div>
            <div className="block-body">
              <>
                <Form onSubmit={this.connectDevice}>
                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3">
                      Unique ID <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"unique_id"}
                        required
                        onChange={this.handleInputOnChange}
                        type="text"/>
                      <Form.Control.Feedback type="invalid">Unique Id</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3">
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"password_hash"}
                        required
                        type="password"
                        onChange={this.handleInputOnChange}
                      />
                      <Form.Control.Feedback type="invalid">Please enter Password .</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3">
                      Confirm Password <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"password_confirmation"}
                        required
                        type="password"
                        // onChange={this.handleInputOnChange}
                      />
                      <Form.Control.Feedback type="invalid">Please confirm Password.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <div className="mt-3">
                    <Button size="lg" type={"submit"} disabled={this.state.isAdding}
                      variant="primary">{this.state.isAdding ? 'Submitting...' : 'Submit'}</Button>
                  </div>
                </Form>
              </>
            </div>
          </div>
          }

        {/*{this.state.showConnectDeviceForm &&*/}
        {/*  <div className="card-block">*/}
        {/*    <div className="block-header">*/}
        {/*      <h4 className="block-title">Connect Device</h4>*/}
        {/*    </div>*/}
        {/*    <div className="block-body">*/}
        {/*      <>*/}
        {/*        <Form>*/}
        {/*          <Form.Group as={Row} controlId="displayOrShopName">*/}
        {/*            <Form.Label column sm="3">*/}
        {/*              Device ID <span className="text-danger">*</span>*/}
        {/*            </Form.Label>*/}
        {/*            <Col sm="9" md={8} lg={7}>*/}
        {/*              <Form.Control*/}
        {/*                  name={"name"}*/}
        {/*                  required*/}
        {/*                  type="text"/>*/}

        {/*              <Form.Control.Feedback type="invalid">Please enter title in English .</Form.Control.Feedback>*/}
        {/*            </Col>*/}
        {/*          </Form.Group>*/}
        {/*          <div className="mt-3">*/}
        {/*            <Link to={`/route/list`}>*/}
        {/*              <Button size="lg" type={"submit"} disabled={this.state.isAdding}*/}
        {/*                      variant="primary">{this.state.isAdding ? 'Submitting...' : 'Submit'}</Button>*/}
        {/*            </Link>*/}
        {/*          </div>*/}
        {/*        </Form>*/}
        {/*      </>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  }*/}

        </div>
      </App>
    );
  }
}

export default withRouter(Details);