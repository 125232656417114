
import React, { useState, useEffect } from 'react';
import App from "../../../App";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import AreaForm from "./Form";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getData, putData } from "../../../services/baseServices"

const Create = () => {
    const [area, setArea] = useState({
        name: '',
        bn_name: '',
        home_delivery: true,
    })
    const [thana, setThana] = useState([]);
    const history = useHistory()
    const { id } = useParams()
    useEffect(() => {
        getData(`areas/${id}`)
            .then(res => {
                if (res.success) setArea(res?.data)
            })
            .catch(errMsg => {
            });
    }, [id])

    const onFormSubmit = (data) => {
        const params = {
            area: {
                name: data.name,
                bn_name: data.bn_name,
                home_delivery: data.home_delivery,
                thana_id: data.thana_id
            }
        }
        putData(`areas/${id}`, params)
            .then(res => {
                if (res.success) {
                    history.push(`/config/areas/list`);
                    toast.success(res.message);
                }
                else {
                    toast.error(res.message);
                }
            })
            .catch(errMsg => {
                toast.error(errMsg);
            });
    }
    useEffect(() => {
        getData('thanas')
            .then(res => {
                setThana(res.data)
            })
            .catch(errMsg => {
            })
    }, [])
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Edit Area</h2>
                <div className="ml-auto">
                    <Link to={`${process.env.PUBLIC_URL}/config/areas/list`}
                        className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to List</Link>
                </div>
            </div>

            <div className="card-block">
                <div className="block-body">
                    <>
                        <AreaForm
                            area={area}
                            thana={thana}
                            onFormSubmit={onFormSubmit}
                        />
                    </>
                </div>
            </div>
        </App>
    );
}
export default withRouter(Create);