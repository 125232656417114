import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import React, {Component} from 'react';
import App from "../../App";
import {withRouter} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Riders from "./Riders"
import Routes from "./Routes"
import DateRangeFilter from "./common/DateRangeFilter";
import moment from "moment";
import WarehouseBalance from './WarehouseBalance';
import { getFormattedDate } from "../../helper/utils";
import Distributors from './Distributors';

export const KEYS = {
    RIDER: 0,
    ROUTE: 1,
    DISTRIBUTOR: 2
}

export const TABLES = {
    ORDER_SUMMARY: 'ORDER_SUMMARY',
    ORDER_LIST: 'ORDER_LIST',
    RETURN_REQUEST: 'RETURN_REQUEST',
    CUSTOME_ORDER: 'CUSTOME_ORDER'
}

class Reconciliation extends Component {
    constructor() {
        super();
        var startDate = localStorage.getItem("reconciliation_st_d") ? moment(localStorage.getItem("reconciliation_st_d")): moment().subtract(29, 'days');
        var today = localStorage.getItem("reconciliation_ed_d") ? moment(localStorage.getItem("reconciliation_ed_d")): moment();

        var seletedTab = localStorage.getItem("reconciliation_tab") ? parseInt(localStorage.getItem("reconciliation_tab")) : KEYS.RIDER;
        this.state = {
            warehouse_info: null,
            start_date_time: startDate,
            end_date_time: today,
            temp_params: { start_date_time:  getFormattedDate(startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(today, "YYYY-MM-DD") },
            query_params: { start_date_time:  getFormattedDate(startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(today, "YYYY-MM-DD") }, 
            selectedTab: seletedTab
        };
    }

    componentWillUnmount() {
        this.setState({})
    }

    getQueryParams = () => {
        let params = Object.assign(this.state.query_params);
        return params;
    }

    handleOnFilter = (e) => {
        e.preventDefault();
        const tempParam = this.state.temp_params;

        localStorage.setItem("reconciliation_st_d", this.state.temp_params.start_date_time)
        localStorage.setItem("reconciliation_ed_d", this.state.temp_params.end_date_time)
        this.setState({query_params: tempParam});
    }

    handleOnApply = (e, picker)=>{
        this.setState({start_date_time: getFormattedDate(picker.startDate,''), end_date_time: getFormattedDate(picker.endDate,'')})
        this.setState({temp_params: {...this.state.temp_params, start_date_time: getFormattedDate(picker.startDate, "YYYY-MM-DD"), end_date_time: getFormattedDate(picker.endDate, "YYYY-MM-DD")}})
    }

    onTabChange = (key) => {
        localStorage.setItem("reconciliation_tab", key)
        this.setState({selectedTab: key})
    }

    render() {
        return (
            <App layout="">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Reconciliation</h2>
                    </div>
                        <div align="center">
                            <DateRangeFilter start_date_time={this.state.start_date_time} end_date_time={this.state.end_date_time} handleOnApply={(e, picker) => this.handleOnApply(e, picker)} filterList={this.handleOnFilter}/>
                        </div>
                    <div className="card-block">
                        <div className="block-body">
                            <WarehouseBalance queryParams={this.state.query_params}/>
                            <hr/>

                            <Tabs selectedIndex={this.state.selectedTab} onSelect={this.onTabChange}>
                                <TabList>
                                    {/* <Tab eventKey={KEYS.RIDER}>Riders</Tab>
                                    <Tab eventKey={KEYS.ROUTE}>Routes</Tab> */}

                                    <Tab eventKey={KEYS.DISTRIBUTOR}>Distributors</Tab>
                                </TabList>
                                <TabPanel>
                                    <Distributors queryParams={this.state.query_params}/>
                                </TabPanel>
                                {/* <TabPanel>
                                    <Riders queryParams={this.state.query_params} />
                                </TabPanel>
                                <TabPanel>
                                    <Routes queryParams={this.state.query_params} />
                                </TabPanel> */}
                            </Tabs>
                        </div>
                    </div>
                </div>

                <ToastsContainer store={ToastsStore}/>
            </App>
        );
    }
}

export default withRouter(Reconciliation);
