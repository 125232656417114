import React, { useState, useEffect, useRef } from 'react';
import App from "../../App";
import { FaLongArrowAltLeft } from "react-icons/all";
import "../../assets/scss/invoice.scss";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import { getDetails } from "../../services/baseServices";
import { updateOrderStatus } from "../../services/baseServices";
import { twoDecimalPlace, getWarehouseType, getFormattedDate, getDate, humanize } from "../../helper/utils";
import _ from 'lodash'
import moment from 'moment';
import { useForm } from "react-hook-form";
import OrderDetailsDynamicForm from './OrderDetailsDynamicForm';

const warehouseType = getWarehouseType();

const CustomersOrderPackForm = ({ props }) => {
    const inputElement = useRef();
    const history = useHistory()

    const [state, setState] = useState(
        {
            status: null,
            isSubmitting: false,
            packedStatus: false,
            isFormValidated: false,
            colspan: 4,
            printcolspan: 2,
            packedOrders: [{ line_item_id: '', qr_codes: [] }],
            qrCodeMatching: [],
            orderToBeUnpacked: false,
            schedule : []
        }
    );

    const [orderDetails, setOrderDetail] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [routeDetailsss, setRouteDetails] = useState({})
    const [lineItem, setLineItem] = useState([])


    const { register, control, reset, handleSubmit, watch } = useForm({
        defaultValues: {
            dyFieldRows1: [{ qtn: 1, qrcode: "", locqtn: ""}],
        }
    });

    const { id } = useParams()

    useEffect(() => {
        if(warehouseType === 'center') {
            history.push(`/customerOrder/details/${id}`)
            return
        }
        loadData()
    }, [])

    const loadData = () => {
        getDetails("customer_orders", id)
            .then(res => {
                if(res?.status_key !== "order_placed")
                {
                    history.push(`/customerOrder/details/${id}`)
                    return
                }
                setOrderDetail(res);
                setIsLoading(false);
                if (res.shopoth_line_items) {
                    setLineItem(res.shopoth_line_items)
                }

                if (res.partner.schedule) {
                    this.setState({schedule: res.partner.schedule.toUpperCase().split("_")})
                  }
                
                if (res.shipping_type === 'Pick up point') {
                    getDetails("routes", res.partner.route_id)
                        .then(res => {
                            setRouteDetails({ ...res })
                        })
                        .catch(errMsg => {
                            console.log(errMsg)
                        })
                }
            })
            .catch(errMsg => {
                console.log('Err Msg: ', errMsg)
            });
    }

    useEffect(() => {
        let formateDefauldValue = {}
        for (let i = 0; i < orderDetails?.shopoth_line_items?.length; i++) {
            formateDefauldValue[`dyFieldRows${i + 1}`] = [{ qtn: 1, qrcode: "", locqtn: "" }]
        }
        reset(formateDefauldValue)
    }, [lineItem])


    // onsubmit handler 

    const onSubmit = (data) => {
        setIsSubmitting(true)
        let size = Object.keys(data).length;
        let newArray = []
        let newArrayCount = []
        for (let i = 0; i < size; i++) {
            newArray.push(...Object.values(data)[i]?.filter((item) => item?.qrcode !== (orderDetails?.shopoth_line_items[i].item?.sku)));
            newArrayCount.push(Object.values(data)[i]?.reduce(
                (totalPrice, item) => parseInt(totalPrice) + parseInt(item.qtn), 0
            ));
        }

        const checkMatchQuantity = () => {
            let checkQTT = [...orderDetails?.shopoth_line_items?.map((item) => item.quantity)];
            const result = JSON.stringify(checkQTT) == JSON.stringify(newArrayCount)
            return result ? true : false
        }

        if (newArray?.length == 0 && checkMatchQuantity()) {
            const packedOrders = [];
            orderDetails.shopoth_line_items.map((value, i) => {
                return Object.values(data)[i].map((dt, dtIntex) => {
                    const newObject = {
                        line_item_id: value.shopoth_line_item_id,
                        qr_codes: [dt.qrcode],
                        location_id: dt.locqtn,
                        quantity: dt.qtn
                    };
                    packedOrders.push(newObject)
                })
            })

            if (packedOrders.length) {
                setIsSubmitting(true)
                updateOrderStatus(packedOrders, 'packed_items', id)
                    .then(res => {
                        if (res?.order_id) {
                            toast.success("Successfully packed");
                            history.replace(`/customerOrder/details/${id}`)
                            setIsSubmitting(false)
                        } else {

                            toast.error(res?.message);
                            setIsSubmitting(false)
                        }
                    })
                    .catch(errMsg => {
                        toast.error(errMsg);
                        setIsSubmitting(false)
                    });
            }
        } else {
            setIsSubmitting(false)
            toast.error('QR Code  and Total Quatity Should Match')
        }
    };

    return (
        <App layout="">
            <div className="main-content">
                <div className="page-header">
                    <h2 className="page-title">Order Details</h2>
                    <div className="ml-auto">
                        <Link
                            onClick={() => history.goBack()}
                            className="btn btn-sm btn-link"><FaLongArrowAltLeft /> Back to Previous</Link>
                    </div>
                </div>
                {isLoading ?
                    <div className={`card-block`}>
                        <div className="block-body">
                            Loading...
                        </div>
                    </div>
                    :
                    <div className="card-block">
                        <>
                            <Form
                                noValidate
                                validated={state.isFormValidated}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="block-header text-right">
                                    <div className="right-section">
                                        <>
                                            <Link
                                                to={`/customerOrder/details/${orderDetails?.order_id}`}
                                                className="btn btn-sm btn-danger mr-2">
                                                Close
                                            </Link>
                                            <Button
                                                size="sm"
                                                type={"submit"}
                                                disabled={state.qrCodeMatching.length > 0 || isSubmitting}
                                                variant="primary">{isSubmitting ? 'Submitting...' : 'Submit'}
                                            </Button>
                                        </>
                                    </div>
                                </div>
                                <div ref={inputElement} className="block-body print-outer" >
                                    <div className="only-print">
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <QRCode value={`${orderDetails?.order_id}`} />
                                        <br />
                                    </div>

                                    <table className="invoice-table">
                                        <tbody>
                                            <tr className="v-top">
                                                <td>
                                                    <h3 className="invoice-title">Order # 0000{orderDetails?.order_id}</h3>
                                                    <span
                                                        className="d-block"><strong>Central BIN</strong> : {getDate(orderDetails?.order_at) >= moment('2021-09-19') ? '003279334-0101' : '003279334-0203'}</span>
                                                    <span
                                                        className="d-block"><strong>Status</strong> : {humanize(orderDetails?.status)}</span>
                                                    <span
                                                        className="d-block"><strong>Distributor</strong> : {orderDetails?.distributor_name}</span>
                                                    <span className="d-block"><strong>Order Date</strong> : {getFormattedDate(orderDetails?.order_at, 'LT, ll')}
                                                    </span>
                                                    <span className={`d-block ${orderDetails?.preferred_delivery_date ? '' : 'mb-2'}`}><strong>Order Deadline</strong> : {orderDetails?.shipping_type === "Express delivery" ?
                                                        getDate(orderDetails?.order_at).add(3, 'hours').format('LT, ll')
                                                        : getDate(orderDetails?.order_at).add(72, 'hours').format('LT, ll')
                                                    }
                                                    </span>

                                                    {
                                                        orderDetails?.preferred_delivery_date && (
                                                            <span className="d-block mb-2"><strong>Preferred Delivery Date</strong> : {getFormattedDate(orderDetails?.preferred_delivery_date, 'LT, ll')}</span>
                                                        )
                                                    }
                                                </td>
                                                <td className="text-right">
                                                    <span className="d-block"><h6>Customer Information</h6></span>
                                                    <span className="d-block">{orderDetails?.customer?.name}</span>
                                                    <span className="d-block">{orderDetails?.customer?.email}</span>
                                                    <span className="d-block">{orderDetails?.customer?.phone}</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan="2">
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan="2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ paddingBottom: '20px' }}>
                                                                    <h4 className="invoice-title-alt">Shipping Type</h4>
                                                                    <span className="d-block">{orderDetails?.shipping_type}</span>
                                                                </td>
                                                                {(orderDetails?.shipping_type === "Home delivery" || orderDetails?.shipping_type === "Express delivery") &&
                                                                    <>
                                                                        <td style={{ paddingBottom: '20px' }}>
                                                                            <h4 className="invoice-title-alt">Shipping Address</h4>
                                                                            <span className="d-block">{orderDetails?.shipping_address?.address_line}</span>
                                                                            <span className="d-block">{orderDetails?.shipping_address?.area}</span>
                                                                            <span className="d-block">{orderDetails?.shipping_address?.thana}</span>
                                                                            <span className="d-block">{orderDetails?.shipping_address?.district}</span>
                                                                            <span className="d-block">{orderDetails?.shipping_address?.phone}</span>
                                                                        </td>

                                                                        <td style={{ paddingBottom: '20px' }}>
                                                                            <h4 className="invoice-title-alt">Billing Address</h4>
                                                                            <span className="d-block">{orderDetails?.billing_address?.address_line}</span>
                                                                            <span className="d-block">{orderDetails?.billing_address?.area}</span>
                                                                            <span className="d-block">{orderDetails?.billing_address?.thana}</span>
                                                                            <span className="d-block">{orderDetails?.billing_address?.district}</span>
                                                                            <span className="d-block">{orderDetails?.billing_address?.phone}</span>
                                                                        </td>

                                                                        {orderDetails?.rider?.name &&
                                                                            <td style={{ paddingBottom: '20px' }}>
                                                                                <h4 className="invoice-title-alt">Rider Info</h4>
                                                                                <span className="d-block">{orderDetails?.rider?.name}</span>
                                                                                <span className="d-block">{orderDetails?.rider?.phone}</span>
                                                                            </td>}
                                                                    </>
                                                                }
                                                                {orderDetails?.shipping_type === "Pick up point" && Object.keys(orderDetails.partner).length > 0 &&
                                                                    <td style={{ paddingBottom: '20px' }}>
                                                                        <h4 className="invoice-title-alt">Pick-up Point</h4>
                                                                        <span className="d-block">{orderDetails?.partner?.name}</span>
                                                                        <span className="d-block">{orderDetails?.partner?.phone}</span>
                                                                        <span className="d-block">{orderDetails?.partner?.email}</span>
                                                                        <span className="d-block">{orderDetails?.partner?.area}</span>
                                                                        <span className="d-block">{state?.schedule &&
                                                                            state?.schedule.map((item, index, array) => (
                                                                                index < array.length - 1 ?
                                                                                    <><span className='text'>{item}</span>, </>
                                                                                    : <> & <span className='text'>{item}</span> </>
                                                                            ))
                                                                        }</span>
                                                                        <span className="d-block">Delivery Man Name : {routeDetailsss?.title}</span>
                                                                        <span className="d-block">Section : {orderDetails?.partner?.section}</span>
                                                                        {/* <span className="d-block">Distributor : {_.capitalize(orderDetail?.partner?.distributor_name)}</span> */}
                                                                    </td>
                                                                }
                                                                {!_.isEmpty(orderDetails?.receiver_info) &&
                                                                    <td style={{ paddingBottom: '20px' }}>
                                                                        <h4 className="invoice-title-alt">Receiver Info</h4>
                                                                        <span className="d-block">{orderDetails?.receiver_info?.name}</span>
                                                                        <span className="d-block">{orderDetails?.receiver_info?.phone}</span>
                                                                    </td>
                                                                }
                                                                <td style={{ paddingBottom: '20px' }}>
                                                                    <h4 className="invoice-title-alt">Payment</h4>
                                                                    <span className="d-block">Type: {orderDetails?.pay_type}</span>
                                                                    <span className="d-block">Status: {orderDetails?.is_customer_paid ? "Paid" : "Pending"}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Item</th>
                                                                <th className={'text-center'}>Packing Actions</th>
                                                                <th className={'text-center'}>Unit Price</th>
                                                                <th className={'text-center'}>Discount</th>
                                                                {state.packedStatus && <th style={{ width: '200px' }}>QR Code</th>}
                                                                {(state.packedStatus || state.orderToBeUnpacked) && <th style={{ width: '200px' }}>Select Location</th>}
                                                                <th className="text-right">Amount (৳)</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>

                                                            {orderDetails?.shopoth_line_items && lineItem.length > 0 && orderDetails?.shopoth_line_items.length > 0 ?
                                                                orderDetails?.shopoth_line_items?.map((item, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <tr key={index + 1}>
                                                                            <td width={400}>
                                                                                <span className="d-block"><strong>{item.item?.product_title}</strong></span>
                                                                                <span className="d-block text-muted">
                                                                                    {item.item.product_attribute_values.map((value, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                {value.value}{item.item.product_attribute_values.length > index + 1 ? '-' : ''}
                                                                                            </>

                                                                                        )
                                                                                    }
                                                                                    )}
                                                                                </span>
                                                                            </td>
                                                                            <OrderDetailsDynamicForm
                                                                                register={register}
                                                                                control={control}
                                                                                watch={watch}
                                                                                name={`dyFieldRows${index + 1}`}
                                                                                locations={item.locations}
                                                                                limit={item.quantity}
                                                                                quantity={item.quantity}
                                                                                QrCode={item.item.sku}
                                                                            />
                                                                            <td className={`text-center`}>{twoDecimalPlace(item.item.unit_price)}</td>
                                                                            <td className={`text-center`}>{twoDecimalPlace(item.item.product_discount)}</td>
                                                                            <td className="text-right">{twoDecimalPlace(item?.sub_total)}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))
                                                                :
                                                                ''
                                                            }
                                                            <tr className="text-bold">
                                                                <td colSpan={state.colspan} className="text-right">Sub-Total (inclusive of VAT @ 5% *)</td>
                                                                <td className="text-right">{twoDecimalPlace(orderDetails?.sub_total)}</td>
                                                            </tr>

                                                            <tr className="text-bold">
                                                                <td colSpan={state.colspan} className="text-right">Discount</td>
                                                                <td className="text-right">{twoDecimalPlace(orderDetails?.total_discount_amount)}</td>
                                                            </tr>

                                                            <tr className="text-bold">
                                                                <td colSpan={state.colspan} className="text-right">Shipping Charge</td>
                                                                <td className="text-right">{twoDecimalPlace(orderDetails?.shipping_charge)}</td>
                                                            </tr>
                                                            <tr className="text-bold">
                                                                <td colSpan={state.colspan} className="text-right">VAT@ 15% on Shipping Charge</td>
                                                                <td className="text-right">{twoDecimalPlace(orderDetails?.vat_shipping_charge)}</td>
                                                            </tr>

                                                            <tr className="text-bold">
                                                                <td colSpan={state.colspan} className="text-right">Grand Total</td>
                                                                <td className="text-right">{twoDecimalPlace(orderDetails?.grand_total)}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6> <i>*VAT @ 5% is on the differential between Sales & Purchase price</i></h6>

                                </div>
                            </Form>
                        </>
                    </div>
                }
            </div>
        </App>
    )
}

export default withRouter(CustomersOrderPackForm)