import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import {postData } from "../services/baseServices";
import {refreshPage} from '../helper/utils'
import {toast} from "react-toastify";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../helper/env"

const QCModal = ({ show, handleClose, questionnaires,question_details , whom, rider_id, route_id, handleAfterAction}) => {
	const [questionnaire, setQuestionnaire] = useState([])
	const [submitting, setSubmitting] = useState(false)
	useEffect(()=>{
		setQuestionnaire([...questionnaires])
	},[questionnaires])
	
	const selectedRadio = index => e => {
			let newArr = [...questionnaire]
			newArr[index].defaultStatus = e.target.value ==='false'?false:true
			setQuestionnaire(newArr)
		
	};

	const debouncedOnQCSubmit = _.debounce((e)=>{
        onSubmitHandler(e)
    }, DEBOUNCE_TIME);

    const handleWithDebounceQCSubmit = async (e) => {
		e.preventDefault()
		setSubmitting(true)
        debouncedOnQCSubmit(e);
    }

	const onSubmitHandler = (e) =>{
		e.preventDefault()
		setSubmitting(true)
		let failed_questionnare= questionnaire.filter(item => item.defaultStatus === false)
		let failed_reasons = []
		failed_questionnare.map(item =>{
			failed_reasons.push(item.id)
		})

		const failed_data = {
			return_order_id:question_details.return_order_id,
			failed_reasons:failed_reasons 
		}
		let url = null
		if(whom === 'rider'){
			url = `riders/${rider_id}/return_qc`;
		}else{
			url = `routes/${route_id}/return_qc`
		}
		postData(url,failed_data)
			.then(res => {
				if(res.status_code == "200" || res.status_code == "201") {
					toast.success(res.message);
					handleAfterAction()
					setSubmitting(false)
				  }    
				  else {
					toast.error(res.message);
					setSubmitting(false)
				  } 
				})
			.catch(err =>{
				toast.error(err.message);
				setSubmitting(false)
			})
	}
		
	return (
		<>
			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>QC for {question_details.return_type} products</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleWithDebounceQCSubmit}>
					<Modal.Body>
						<hr />
						{questionnaires.map((item, index) => {
							return (
								<Form.Group as={Row} key={item.id}>
									<Form.Label column sm="6">
										{index + 1}. {item.question}
									</Form.Label>
									<Col sm="9" md={8} lg={3}>
										<RadioGroup
											row
											name={item.question}
											value={item.defaultStatus}
											onChange={selectedRadio(index)}
										>
											<FormControlLabel
												value={true}
												control={<Radio />}
												label="Yes"
											/>
											<FormControlLabel
												value={false}
												control={<Radio />}
												label="No"
											/>
										
										</RadioGroup>
									</Col>
								</Form.Group>
							);
						})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" type='submit' disabled={submitting}>
							Done
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default QCModal;