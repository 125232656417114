import React, { Component } from "react";
import App from "../../App";
import { Button } from "react-bootstrap";
import { FiPlus } from "react-icons/all";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import Pagination from "react-js-pagination";
import { getPaginatedList } from "../../services/baseServices";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  getFormattedDate,
  humanize,
  getWarehouseType,
  getBusinessType,
} from "../../helper/utils";
import Loader from "react-loader";

import ExportCSVAsync from "../common/ExportCSVAsync";
import moment from "moment";
const businessType = getBusinessType();
const headers = [
  { label: "IGT ID", key: "id" },
  { label: "Quantity", key: "quantity" },
  { label: "Warehouse", key: "warehouse_name" },
  { label: "Price", key: "total_price" },
  { label: "Date", key: "order_date" },
  { label: "Order Status", key: "order_status" },
];

const loadData = (item) => {
  return {
    id: item.id,
    quantity: item.quantity,
    warehouse_name: item.warehouse_name,
    total_price: item.total_price,
    order_date: getFormattedDate(item?.created_at, "LT Do MMMM YYYY"),
    order_status: item.order_status ? item.order_status : "Unknown",
  };
};

var startDate = moment().subtract(29, "days");
var today = moment();

class List extends Component {
  constructor() {
    super();
    this.state = {
      orderList: [],
      isLoading: true,
      temp_params: {
        start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
        rto_no: "",
      },
      query_params: {
        start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
        rto_no: "",
      },
      isExporting: false,
      start_date_time: startDate,
      end_date_time: today,
      pickerRef: React.createRef(),
    };
  }

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  getPaginatedPOs = (params) => {
    this.setState({ ...this.state, isLoading: true });
    getPaginatedList("return_transfer_orders", params)
      .then((res) => {
        console.log("return list", res);
        this.setState(
          {
            orderList: res?.data?.data,
            isLoading: false,
            per_page: res.headers["x-per-page"],
            total_data: res.headers["x-total"],
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        this.setState({ ...this.state, isLoading: false });
        console.log("Error Message: ", errMsg);
      });
  };

  componentDidMount() {
    this.getPaginatedPOs(this.getQueryParams());
  }

  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    this.setState({ ...this.state, page: 1, query_params: tempParam }, () => {
      this.getPaginatedPOs(this.state.temp_params);
    });
  };

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {});
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getPaginatedPOs(this.getQueryParams());
    });
  };

  handleOnApply = (e, picker) => {
    this.setState({
      start_date_time: getFormattedDate(picker.startDate, ""),
      end_date_time: getFormattedDate(picker.endDate, ""),
    });
    this.setState({
      temp_params: {
        ...this.state.temp_params,
        start_date_time: getFormattedDate(picker.startDate, "YYYY-MM-DD"),
        end_date_time: getFormattedDate(picker.endDate, "YYYY-MM-DD"),
      },
    });
  };
  handleOnClear = () => {
    this.state.pickerRef?.current?.setStartDate(startDate);
    this.state.pickerRef?.current?.setEndDate(today);
    this.setState(
      {
        start_date_time: getFormattedDate(startDate, ""),
        end_date_time: getFormattedDate(today, ""),
        temp_params: {
          ...this.state.temp_params,
          start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
          end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
          rto_no: "",
        },
        query_params: {
          ...this.state.query_params,
          start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
          end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
          rto_no: "",
        },
      },
      () => {
        this.getPaginatedPOs(this.getQueryParams());
      }
    );
  };
  render() {
    const { rto_no } = this.state.temp_params;
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">
              {businessType === "multicat"
                ? "Stock Return List"
                : "Inter Godown Transfer List"}
            </h2>
            <div className="ml-auto d-flex">
              <ExportCSVAsync
                fileName={
                  "IGT-" + getFormattedDate(new Date(), "DD-MM-YYYY") + ".csv"
                }
                Url="return_transfer_orders/export"
                headers={headers}
                params={this.state.query_params}
                loadData={loadData}
                title="IGT Order List"
              />
              {getWarehouseType() !== "central" && (
                <Link
                  to={`${process.env.PUBLIC_URL}/dhReturnOrder/add`}
                  className="btn btn-sm btn-secondary ml-2"
                >
                  <FiPlus /> Add
                </Link>
              )}
            </div>
          </div>
          <form className="form-inline mb-3">
            <div className="form-group mr-3">
              <label htmlFor="status" className="sr-only">
                Date Range
              </label>
              <DateRangePicker
                ref={this.state.pickerRef}
                initialSettings={{
                  startDate: this.state.start_date_time,
                  endDate: this.state.end_date_time,
                  maxSpan: { months: 3 },
                  showDropdowns: true,
                  locale: { format: "DD/MM/YYYY" },
                  autoApply: true,
                }}
                onApply={(e, picker) => this.handleOnApply(e, picker)}
              >
                <input
                  type="text"
                  value={
                    this.state.start_date_time && this.state.end_date_time
                      ? `${getFormattedDate(
                          this.state.start_date_time,
                          "DD/MM/YYYY"
                        )} - ${getFormattedDate(
                          this.state.end_date_time,
                          "DD/MM/YYYY"
                        )}`
                      : ""
                  }
                  style={{ textAlign: "left", minWidth: "150px" }}
                  placeholder={"Select date range"}
                  className="form-control form-control-sm"
                />
              </DateRangePicker>
            </div>
            <input
              type="text"
              name="rto_no"
              onChange={this.handleOnFieldChange}
              value={rto_no ? rto_no : ""}
              className="form-control form-control-sm mr-3"
              placeholder="Search by IGT ID"
            />
            <Button
              size="sm"
              type={`submit`}
              onClick={this.handleOnSearch}
              variant="primary"
            >
              Filter
            </Button>
            <Button
              size="sm"
              type={`button`}
              className="ml-2"
              onClick={this.handleOnClear}
              variant="danger"
            >
              Clear
            </Button>
          </form>
          <Loader loaded={!this.state.isLoading}>
            <div className="card-block">
              <div className="block-body">
                <table className="order-table table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>#</th>
                      <th>IGT ID</th>
                      <th>Quantity</th>
                      <th>Warehouse</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Order Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orderList.length > 0 ? (
                      this.state.orderList.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td width="120">
                              <div className="order-item">
                                # 000000{order.id}
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {order.quantity}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {order.warehouse_name}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                <h4 className="order-id mb-0">
                                  {order.total_price}
                                </h4>
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                {getFormattedDate(
                                  order?.created_at,
                                  "LT Do MMMM YYYY"
                                )}
                              </div>
                            </td>

                            <td>
                              <div className="order-item">
                                {order.order_status
                                  ? humanize(order?.order_status)
                                  : "Unknown"}
                              </div>
                            </td>
                            <td style={{ width: "150px" }} className={"nowrap"}>
                              <div className="btn-group">
                                <Link
                                  to={`${process.env.PUBLIC_URL}/dhReturnOrder/details/${order.id}`}
                                  className="btn btn-block btn-sm btn-outline-secondary"
                                >
                                  Details
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          {" "}
                          <h5>No Order Found</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-3">
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.per_page}
                  totalItemsCount={this.state.total_data}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </div>
          </Loader>
        </div>
      </App>
    );
  }
}

export default withRouter(List);
