import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const AccoutDeactivationReasonForm = ({ reason, onFormSubmit }) => {
  const schema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required("Title is a required")
      .max("50", "Max 50 characters allowed"),
    title_bn: yup
      .string()
      .trim()
      .required("Bn Title is a required")
      .max("50", "Max 50 characters allowed"),
      reason_type:yup
      .string()
      .required("Reason Type is a required")
  });

  const { reset, register, handleSubmit, formState:{ errors }, setValue } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        reason_type: 'deactivated_or_deleted'
        }
    });

    useEffect(()=>{
      reset({
          title: reason.title,
          title_bn: reason.title_bn,
          reason_type: 'deactivated_or_deleted'
      })
    },[reason])

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="card-block">
        <div className="block-header">
          <h4 className="block-title"> Basic Info</h4>
        </div>
        <div className="block-body">
          <Form.Group as={Row} controlId="reason_type">
              <Form.Label column sm="3">
              Reason Type <span className="text-danger">*</span>
              </Form.Label>
              <Col sm="9" md={8} lg={7}>
              <Form.Control
                  as="select"
                  type="text"
                  {...register("reason_type")}
                  className={errors.reason_type? 'is-invalid' : ''}
              >
                <option value='deactivated_or_deleted'>Deactive/Delete</option>
                <option  value='activated'>Active</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                  {errors.reason_type?.message}
              </Form.Control.Feedback>
              </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3">
              Title <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                {...register("title")}
                type="text"
                className={errors.title ? "is-invalid" : ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.title?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
         
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3">
              BN Title <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" md={8} lg={7}>
              <Form.Control
                {...register("title_bn")}
                type="text"
                className={errors.title_bn ? "is-invalid" : ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.title_bn?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </div>
      </div>

      <div className="mt-3 text-right">
        <Link
          type="button"
          className="btn btn-sm btn-btn-sm mr-2"
          to={`/acc-delete-reason/list`}
        >
          <Button type="button" variant="danger">
            Cancel
          </Button>
        </Link>
        <Button
            size="btn-sm"
            type={"submit"}
            variant="primary"
            >
            Submit
        </Button>
      </div>
    </Form>
  );
};

export default AccoutDeactivationReasonForm;