import { FC } from "react";
import { Controller } from "react-hook-form";
import { InputNumber } from "antd";


 const InputNumberControl = ({
  name,
  control,
  errMsg,
  isDisabled = false,
  placeholder = "",
  max = 999999999,
  min = 0,
  className = "",
}) => {
  
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <InputNumber
            {...field}
            id={name}
            className={`!rounded-lg !my-1 !overflow-hidden ${className}`}
            status={errMsg && "error"}
            size="large"
            max={max}
            min={min}
            step={1}
            disabled={isDisabled}
            placeholder={placeholder}
            style={{ width: "100%" }}
          />
        )}
      />
      <p className="text-danger">{errMsg}</p>
    </>
  );
};

export default InputNumberControl