import React, { Component } from 'react';
import App from "../../App";
import { FaLongArrowAltLeft } from "react-icons/all";
import "../../assets/scss/invoice.scss";
import { withRouter, Link } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { getDetails, getList, postData } from "../../services/baseServices";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { getWarehouseType, getFormattedDate, twoDecimalPlace, humanize} from "../../helper/utils";

class RRDetails extends Component {
    state = {
        requestDetails: {},
        returnDetails: {},
        customer: {},
        rider: {},
        isLoading: true,
        status: null,
        changingStoreId: null,
        changeStatusMsg: '',
        closeOrderModal: false,
        isSubmitting: false,
        ridersList: [],
        closeRiderModal: false,
        reshecduleModal: false,
        home_pick_up: false,
        return_option: "",
        params: {},
        startDate : null
    }

    componentDidMount() {
        getDetails("return_orders", this.props.match.params.id)
            .then(res => {
                this.setState({
                    requestDetails: res.return_details,
                    returnDetails: res.return_order_details,
                    customer: res.customer,
                    rider: res.rider,
                    home_pick_up: (res.return_details.return_option_value == "0"),
                    return_option: res.return_details.return_option,
                    isLoading: false,
                });
            })
            .catch(errMsg => {
                console.log('Err Msg: ', errMsg)
            });
        getList('riders')
            .then(res => {
                this.setState({
                    ridersList: res,
                    isLoading: false,
                }, () => {
                });
            })
            .catch(errMsg => {
            })
    }

    handleOnFieldChange = (e) => {
        const queryData = {...this.state.params};
        queryData[e.target.name] = e.target.value;
        this.setState({params: queryData}, () => {
        });
    }
    handelRiderModal = () => {
        this.setState({closeRiderModal: !this.state.closeRiderModal})
    }

    assignRiderSubmit= (e) => {
        e.preventDefault();
        postData(`return_orders/${this.state.requestDetails.id}/assign_rider`, this.state.params)
            .then(res => {
                if(res.status_code == "200" || res.status_code == "201") {
                    toast.success("Rider assigned successful");
                    window.location.reload();
                }
                else {
                    toast.error("Please fill all required fields(* marked)");
                }
            })
            .catch(errMsg => {toast.error("Please fill all required fields(* marked)");});
    }
    getAttributes = (attrArray) => {
        let attrs = "";
        if(!!attrArray){
            attrArray.map((attr)=>(
                attrs+= attr.name+": "+attr.value+", "
            ))}
        return attrs.trimEnd();
    }
    render() {
        const {requestDetails} = this.state;
        const {returnDetails} = this.state;
        return (
            <App layout="">
                <div className="main-content">
                    <div className="page-header">
                        <h2 className="page-title">Return Request Details</h2>
                        <div className="ml-auto">
                            <Link to={`${process.env.PUBLIC_URL}/returnOrder/list`}
                                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Requset List</Link>
                        </div>
                    </div>
                    <Modal show={this.state.closeRiderModal} onHide={this.handelRiderModal}>
                        <Modal.Header >
                            <Modal.Title>Assign Rider </Modal.Title>
                        </Modal.Header>
                        <Form onSubmit={this.assignRiderSubmit}>
                            <Modal.Body>
                                <Form.Group controlId="rider_id">
                                    <Form.Label>Rider<span className="text-danger">*</span></Form.Label>
                                    <Form.Control as="select"
                                                  name="rider_id"
                                                  onChange={this.handleOnFieldChange}>
                                        <option value="">--Select Rider--</option>
                                        {
                                            this.state.ridersList.map((item,i)=>(
                                                <option key={i} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="default" onClick={this.handelRiderModal}>
                                    Close
                                </Button>
                                <Button variant="primary" type={`submit`}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {this.state.isLoading ?
                        <div className={`card-block`}>
                            <div className="block-body">
                                Loading...
                            </div>
                        </div>
                        :
                        <div className="card-block">
                            <div className="block-header text-right">
                                <div className="block-title">
                                    {
                                        this.state.home_pick_up && getWarehouseType() !== "central" &&
                                        <Button
                                            className="mr-2"
                                            size="sm"
                                            type={"button"}
                                            onClick={() => {
                                                this.setState({ closeRiderModal: true })
                                            }}
                                            variant="primary">
                                            Assign Rider
                                        </Button>
                                    }
                                </div>

                            </div>
                            <div className="block-body print-outer">

                                <table className="invoice-table">
                                    <tbody>

                                    <tr className="v-top">
                                        <td>
                                            <h3 className="invoice-title">Request # 0000{requestDetails?.id}</h3>
                                            <p>Order # {<Link to={`/customerOrder/details/${returnDetails.id}`}>{returnDetails.id}</Link>}</p>
                                            <span className="d-block">Status : {humanize(requestDetails?.return_status)}</span>
                                            <span  className="d-block">Pickup Type : {humanize(requestDetails?.form_of_return)}</span>
                                            <span className="d-block mb-2">Request Date : {getFormattedDate(requestDetails?.date, 'DD-MM-YYYY')}
                      </span>
                                        </td>
                                        <td className="text-right">
                                            <span className="d-block"><h6>Customer Information</h6></span>
                                            <span className="d-block">{this.state.customer?.name}</span>
                                            <span className="d-block">{this.state.customer?.email}</span>
                                            <span className="d-block">{this.state.customer?.phone}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    {/* <td style={{paddingBottom: '20px'}}>
                            <h4 className="invoice-title-alt">Type</h4>
                            <span className="d-block">{requestDetails?.return_option}</span>
                          </td> */}
                                                    {this.state.home_pick_up && <td style={{paddingBottom: '20px'}}>
                                                        <h4 className="invoice-title-alt">Pickup Address</h4>
                                                        <span className="d-block">{returnDetails?.shipping_address?.area}</span>
                                                        <span className="d-block">{returnDetails?.shipping_address?.thana}</span>
                                                        <span className="d-block">{returnDetails?.shipping_address?.district}</span>
                                                        <span className="d-block">{returnDetails?.shipping_address?.phone}</span>
                                                    </td>}
                                                    {!this.state.home_pick_up && <td style={{paddingBottom: '20px'}}>
                                                        <h4 className="invoice-title-alt">Partner Info</h4>
                                                        <span className="d-block"><Link to={`${process.env.PUBLIC_URL}/partner/details/${returnDetails?.partner_details?.id}`}>{returnDetails?.partner_details?.name}</Link></span>
                                                        <span className="d-block">{returnDetails?.partner_details?.phone}</span>
                                                        <span className="d-block">{returnDetails?.partner_details?.email}</span>
                                                        <span className="d-block">{returnDetails?.partner_details?.area}</span>
                                                    </td>}
                                                    <td style={{paddingBottom: '20px'}}>
                                                        <h4 className="invoice-title-alt">Distributor</h4>
                                                        <span className="d-block">{requestDetails?.distributor_name}</span>
                                                        {/* <span className="d-block">{returnDetails?.warehouse?.phone}</span> */}
                                                    </td>
                                                    {/* {this.state.home_pick_up &&
                                                    <td>
                                                        <h4 className="invoice-title-alt">Rider</h4>
                                                        <span className="d-block">{this.state.rider?.name}</span>
                                                        <span className="d-block">{this.state.rider?.phone}</span>
                                                    </td>
                                                    } */}
                                                    {!this.state.home_pick_up &&
                                                    <td>
                                                        <h4 className="invoice-title-alt">Delivery Man</h4>
                                                        <span className="d-block">{returnDetails?.router_details?.title}</span>
                                                        <span className="d-block">{returnDetails?.router_details?.phone}</span>
                                                    </td>
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            <>

                                                <h3>Reasons</h3>
                                                <table className="info-table">
                                                    <tbody>
                                                    <tr>
                                                        <td style={{width: '250px'}}>Type</td>
                                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                                        <td>{requestDetails?.return_reason}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: '250px'}}>Description</td>
                                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                                        <td>{requestDetails?.description}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <hr></hr>
                                            </>
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th className={"text-right"}>Unit Price</th>
                                                    <th className={'text-center'}>Quantity</th>
                                                    <th className={'text-right'}>Discount</th>
                                                    <th className="text-right">Amount (৳)</th>
                                                </tr>
                                                </thead>

                                                <tbody>

                                                {returnDetails?.items?.length > 0 ?
                                                    returnDetails?.items?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <tr key={index + 1}>
                                                                <td>
                                                                    <span className="d-block"><strong>{item?.product_title}</strong></span>
                                                                    <span className="d-block text-muted">
                                  {this.getAttributes(item?.product_attribute_values)}
                                    </span>
                                                                </td>
                                                                <td className={'text-right'}>{twoDecimalPlace(item.item?.unit_price)}</td>
                                                                <td className={`text-center`}>{twoDecimalPlace(item.quantity)}</td>
                                                                <td className={'text-right'}>{twoDecimalPlace(item.discount_amount)}</td>
                                                                <td className="text-right">{twoDecimalPlace(item?.sub_total)}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))
                                                    :
                                                    ''
                                                }

                                                <tr className="text-bold">
                                                    <td colSpan={4} className="text-right">Subtotal</td>
                                                    <td className="text-right">{twoDecimalPlace(returnDetails?.sub_total)}</td>
                                                </tr>

                                                {/* <tr className="text-bold">
                          <td colSpan={4} className="text-right">Discount</td>
                          <td className="text-right">{twoDecimalPlace(requestDetails?.total_discount_amount)}</td>
                        </tr> */}
                                                <tr className="text-bold">
                                                    <td colSpan={4} className="text-right">Shipping Charge</td>
                                                    <td className="text-right">{twoDecimalPlace(returnDetails?.shipping_charge)}</td>
                                                </tr>

                                                <tr className="text-bold">
                                                    <td colSpan={4} className="text-right">Grand Total</td>
                                                    <td className="text-right">{twoDecimalPlace(returnDetails?.total_price)}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <hr/>
                                <h4>Returned Items</h4>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Return Req. No</th>
                                        <th>Request Date</th>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        returnDetails?.returned_items?.length>0?
                                            returnDetails?.returned_items.map((item,index)=>(
                                                <tr key={index}>
                                                    <th><Link to={`${process.env.PUBLIC_URL}/returnOrder/details/${item.id}`}>{item.id}</Link></th>
                                                    <td>{getFormattedDate(returnDetails?.date, 'DD-MM-YYYY')}</td>
                                                    <td>{item.item_title}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.return_status}</td>
                                                </tr>
                                            ))
                                            :""
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </App>
        );
    }
}

export default withRouter(RRDetails);
