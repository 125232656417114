import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import styleReactSelect from "../../assets/styleReactSelect";
import {FaLongArrowAltLeft} from "react-icons/all";
import {getAttributeList} from "../../services/attributeServices";
import Select from 'react-select';
import {getDetails, putData} from '../../services/baseServices';

class Add extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        attributeIdList:[]
      },
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      attributeInfo: null,
      attributeValue: [],
      attributeBnValue: [],
      originalAttrValue: [],
      attributeSetDetails:{},
      attributeList:[],
      selectedattrList:[]
    };
  }

  componentDidMount() {
      getAttributeList()
      .then(res => {
        let attributes = []
        res.data.map(attr =>{
          let attribute = {}
          attribute['id'] = attr.id;
          attribute["label"] = attr.name;
          attribute["value"] = attr.name;
          attributes.push(attribute)
        })
        this.setState({ attributeList:attributes})
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      })
      getDetails('attribute_sets', this.props.match.params.id)
      .then(res =>{
        if(res.status === 200){
          const attributes = []
          const attrIds = []
          let form = {}
          res.data?.product_attributes.map(attr =>{
            const attribute={}
              attribute['id'] = attr.id;
              attribute['value'] = attr.name
              attribute['label'] = attr.name
              attributes.push(attribute)
              attrIds.push(attribute.id)
          })
          form['name'] = res.data?.title
          form['attributeIdList'] = [...attrIds]
          this.setState({attributeSetDetails:res.data});
          this.setState({selectedattrList:attributes})
          this.setState({formData:form})
        }else{
          toast.error(res?.message)
        }
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      });
  }
  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
      console.log(this.state.formData);
    });
  }
  selectAttrValues = (e) => {
    this.setState({selectedattrList:e})
    console.log('abbb',e)
    let attributeIds = [];
    if(e && e.length > 0){
      e.forEach(val =>{
        attributeIds.push(val.id);
      })
    }
   this.setState({formData:{...this.state.formData,attributeIdList:attributeIds }})
  }
  handleAddItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      const formData = {...this.state.formData}
      const body = {
        title:formData.name,
        product_attribute_ids:[...formData.attributeIdList]
      };
      if(formData.attributeIdList.length <= 0){
        this.setState({valueEmpty:true})
        return
      }
      this.setState({isSubmitting: true}, () => {
        console.log("update..",body)
        putData(`attribute_sets/${this.props.match.params.id}`,body)
          .then(res => {
            if(res.status === 201 || res.status === 200){
              this.props.history.push(`/attributeSet/list`);
              toast.success(res.message);
            }else{
              toast.error(res.message)
              this.setState({isSubmitting: false})
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Attribute Set</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/attributeSet/list`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Attribute List</Link>
          </div>
        </div>
        <div className="card-block">
          <div className="block-body">
            <>
              <Form
                noValidate
                validated={this.state.isFormValidated}
                onSubmit={this.handleAddItem}>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      defaultValue={this.state.attributeSetDetails?.title}
                      onChange={this.handleInputOnChange}
                      required
                      pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
                      type="text"/>
                    <Form.Control.Feedback type="invalid">Please enter attribute name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Attributes <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Select
                      value={this.state.selectedattrList}
                      required
                      isMulti
                      styles={styleReactSelect}
                      placeholder="Please select attributes..."
                      onChange={(e) => this.selectAttrValues(e)}
                      options={this.state?.attributeList}
                    />
                    {this.state.valueEmpty && <span type="invalid" style={{color:"red"}}><i>Please enter attributes</i></span>}
                  </Col>
                </Form.Group>
                <div className="mt-3">
                  <Button size="lg"
                          type={`submit`}
                          disabled={this.state.isSubmitting}
                          variant="primary">{this.state.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);