import React, { useState } from "react";
import App from "../../App";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import BrandForm from "./Form";
import { serialiseAndPost } from "../../services/baseServices";

const Add = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [details, setDetails] = useState({ name: "", bn_name: "", brand_info_visible: true });
  const [newBannerImages, setNewBannerImages] = useState([])
  const [metaInfo, setMetaInfo] = useState({})

  const onSubmit = (data) => {
    const meta_datum_attributes = {
      meta_title: metaInfo.metaTitle,
      bn_meta_title: metaInfo.metaTitleBn, 
      meta_description: metaInfo.metaDescription, 
      bn_meta_description: metaInfo.metaDescriptionBn,
      meta_keyword: metaInfo.metaKeywords,
      bn_meta_keyword: metaInfo.metaKeyKeywordsBn
  }
    const updatedData = {...data, meta_datum_attributes, banners_file:newBannerImages}
    setDetails(updatedData);
    setIsSubmitting(true);
    serialiseAndPost("brands", "brand", updatedData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          history.push("/brands/list");
        } else {
          toast.error(res?.message);
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsSubmitting(false);
      });
    console.log('add brand', updatedData);
  };

  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Add Brand</h2>
        <div className="ml-auto">
          <Link
            to={`${process.env.PUBLIC_URL}/brands/list`}
            className="btn btn-sm btn-link"
          >
            <FaLongArrowAltLeft /> Back to Brand List
          </Link>
        </div>
      </div>
      <>
        <BrandForm
          details={details}
          setNewBannerImages = {setNewBannerImages}
          metaInfo={metaInfo}
          setMetaInfo={setMetaInfo}
          handleSubmit={onSubmit}
          createForm={true}
          isSubmitting={isSubmitting}
        />
      </>
    </App>
  );
};

export default Add;
