import React, { Component } from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import DatePicker from "react-datepicker";
import TimeInput from 'material-ui-time-picker'
import { Link } from "react-router-dom";
import { getList, getData } from "../../services/baseServices";
import WeekdayPicker from './WeekDayPicker';
import { WEEKDAYS } from './promoUtil'
import SelectComponent from './SelectComponent'
import moment from 'moment'
import "../../assets/scss/promo.scss";

class PromotionForm extends Component {
  state = {
    timeBound: false,
    fromDate: '',
    toDate: '',
    promoCat: 0,
    promoRule: 0,
    // warehouses: [],
    promoCategories: [],
    promoRules: [],
    promoFields: [],
    prevPromoCat: null,
    inputValue: '',
    promoSettings: {},
    weekDays: WEEKDAYS,
  }

  componentDidMount(){
    // getList('warehouses')
    // .then(res => {
    //   this.setState({warehouses: res})
    // })
    // .catch(errMsg => {
    // })

    getData('promotions/settings')
    .then(res => {
      this.setState({promoSettings: res, promoCategories: res})
    })
  }

  handleInputChange = (e) => {
    console.log(e)
    const inputValue = e.replace(/\W/g, '');
    this.setState({inputValue: inputValue});
    return inputValue;
  };

  handleOnChange = (key, value) => {
    console.log(key,value)
    let xx =value.map(item => {return (item.value)} ) 
    console.log(xx,xx)
    this.props.onPromoFieldChange(key,xx)
  }

  getPromoRules = (key) => {
    //setPromoRules(promoSettings[key])
    if (key) {
      let prev_cat = this.state.prevPromoCat
      if(prev_cat != null && prev_cat !== key){
        this.setState({prevPromoCat: key});
        this.setState({promoRules: [], promoFields: []})
        this.setState({promoRules: this.state.promoSettings[key]})
        this.props.handleFieldsConfiguration([])
      }else {
        this.setState({prevPromoCat: key});
        this.setState({promoRules: this.state.promoSettings[key]})
      }
    }
    else {
      this.setState({promoRules: [], promoFields: []})
      this.props.handleFieldsConfiguration([])
    }
  }

  getPromoFields = (key) => {
    //setPromoFields(promoRules[key].fields)
    if(key) {
      let promoFields = this.state.promoRules[key].fields
      this.props.handleFieldsConfiguration(promoFields)
      this.setState({promoFields: promoFields})
    }
    else {
      this.props.handleFieldsConfiguration([])
      this.setState({promoFields: []})
    }
  }

  onDaySelect = (day) => {
    const objIndex = this.state.weekDays.findIndex(item => item.day_value == day);
    const selected = this.state.weekDays[objIndex].selected
    const updatedObj = { ...this.state.weekDays[objIndex], selected: !selected};
    const updatedWeekDays = [
      ...this.state.weekDays.slice(0, objIndex),
      updatedObj,
      ...this.state.weekDays.slice(objIndex + 1),
    ];
    this.setState({weekDays:updatedWeekDays})
    this.props.handleDayChange(updatedWeekDays)
  }

  render() {
    const errors = this.props.errors
    return (
      <Form
      noValidate
      //validated={this.props.isFormValidated}
      onSubmit={this.props?.handleClick}>
        <Form.Group as={Row} controlId={"promotion-title"}>
          <Form.Label column sm="3">
            Title <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              name="title"
              onChange={this.props.handleInputOnChange}
              required
              type="text"/>
            <Form.Control.Feedback type="invalid">Title is required</Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} controlId="warehouse">
          <Form.Label column sm="3">
            Warehouse <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              required
              name="warehouse_id"
              onChange={e => {
                this.props.handleInputOnChange(e);
              }}  
              as="select" type="text">
              <option value="">--Select--</option>
              {this.state.warehouses.length > 0 ?
                this.state.warehouses.map((item,i) => {
                  return (
                    <option key={i} value={item.id}>{item.name}</option>
                  )
                })
                : ""
            }
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select a warehouse.</Form.Control.Feedback>
          </Col>
        </Form.Group> */}

        <Form.Group as={Row} controlId="from-date">
          <Form.Label column sm="3">From Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
          <DatePicker
            required
            selected={this.state.fromDate}
            dateFormat="dd/MM/yyyy"
            onChange={date => {
              //this.props?.formData["from_date", date]
              this.setState({fromDate:date})
              this.props.handleDateOnChange("from_date", date);
            }
            }
          />
            <Form.Control.Feedback className="d-block" type="invalid">{errors.from_date}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="to-date">
          <Form.Label column sm="3">To Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
          <DatePicker
            required
            selected={this.state.toDate}
            dateFormat="dd/MM/yyyy"
            onChange={date => {
              //this.props?.formData["to_date", date]
              this.setState({toDate:date})
              this.props.handleDateOnChange("to_date", date);
            }
            }
          />
            <Form.Control.Feedback type="invalid" className="d-block">{errors.to_date}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="active">
          <Form.Label column sm="3">Status</Form.Label>
          <Col sm="9" md={8} lg={7}>
            <RadioGroup row name="is_active" 
              value={parseInt(this.props.formData.is_active)}
              onChange={this.props.handleInputOnChange}>
              <FormControlLabel className="promo-status" value={1} control={<Radio/>} label="Active"/>
              <FormControlLabel className="promo-status" value={0} control={<Radio/>} label="In Active"/>
            </RadioGroup>
          </Col>
        </Form.Group>

        {/* <Form.Group as={Row} controlId="istimebound">
          <Form.Label column sm="3">Time bound</Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Check 
              className="d-flex align-items-center"
              type="checkbox" 
              name="is_time_bound" 
              onChange= {(e) =>{
                this.props.handleInputOnChange(e); 
                this.setState({timeBound: !this.state.timeBound}) } 
              }
              label=" Enable" />
          </Col>
        </Form.Group> */}

        
        {this.state.timeBound &&
        <>
          <Form.Group as={Row} controlId="start_time">
            <Form.Label column sm="3">Start Time<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <TimeInput
                required
                mode='24h'
                onChange={time => {
                  this.props.handleTimeOnChange("start_time", moment(time).format("HH:mm"));
                  //this.props?.formData["start_time", time]
                }
                }
              />
              {/* <Form.Control.Feedback type="invalid">{errors.start_time}</Form.Control.Feedback> */}
            </Col>
          </Form.Group>  
          <Form.Group as={Row} controlId="end_time">
            <Form.Label column sm="3">End Time<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <TimeInput
                required
                defaultValue={null}
                mode='24h'
                onChange={time => {
                  this.props.handleTimeOnChange("end_time", moment(time).format("HH:mm"));
                  //this.props?.formData["end_time", time]
                }
                }
              />
              {/* <Form.Control.Feedback type="invalid">{errors.end_time}</Form.Control.Feedback> */}
            </Col>
          </Form.Group> 
          
          <Form.Group as={Row} controlId="days">
            <Form.Label column sm="3">Days<span className="text-danger">*</span></Form.Label>
            <Col sm="9" md={8} lg={7}>
              <WeekdayPicker 
                weekDays = {this.state.weekDays}
                onDaySelect = {this.onDaySelect}
              />
              <Form.Control.Feedback type="invalid" className="d-block">{errors.days}</Form.Control.Feedback>
            </Col>
          </Form.Group> 
        </> 
        }

        <Form.Group as={Row} controlId="promo-category">
          <Form.Label column sm="3">
            Promo Category <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              required
              name="promotion_category"
              onChange={e => {
                this.props.handleInputOnChange(e);
                this.getPromoRules(e.target.value);
              }}  
              as="select" type="text">
              <option value="">--Select--</option>
              {
                Object.keys(this.state.promoCategories).map((key, i) => (
                  <option key={i+1} value={key}>{key}</option>
                ))
              }
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select a Category.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="promo-rule">
          <Form.Label column sm="3">
            Promo Rule <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              required
              name="promotion_rule"
              onChange={e => {
                this.props.handleInputOnChange(e);
                this.getPromoFields(e.target.value);
              }}  
              as="select" type="text">
              <option value="">--Select--</option>
              {
                Object.keys(this.state.promoRules).map((key,i) => (
                  <option key={i+1} value={key}>{key}</option>
                ))
              }
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select a Rule.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        {
          this.state.promoFields?.map((item,i) => {
            switch (item.type) {
              case "number":
              case "text":
                return (
                  <Form.Group as={Row} controlId={"promo-field"+i} key={i}>
                    <Form.Label column sm="3">
                      {item.title} <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={item.name}
                        onChange={(e)=> { 
                          let array = new Array(e.target.value)
                          this.props.onPromoFieldChange(item.name, array) } }
                        required
                        min={item.minimum}
                        type={item.type}/>
                      <Form.Control.Feedback type="invalid">{item.title} is required</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
        
                );
                break;
              case "select":
                return(
                  <Form.Group as={Row} controlId={"promo-field"+i} key={i}>
                      <Form.Label column sm="3">
                        {item.title} <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <SelectComponent 
                          isMulti = {item.option==="multiple"}
                          selectedValues = {[]}
                          requested_url = {item.dataset_url}
                          name={item.name}
                          isEdit={false}
                          onPromoFieldChange = {this.props.onPromoFieldChange}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">{errors[item.name]}</Form.Control.Feedback>
                        {/* <Form.Control.Feedback type="invalid">{item.title} is required</Form.Control.Feedback> */}
                      </Col>
                    </Form.Group>
                )
                break;
              default:
                return (
                  <Form.Group as={Row} controlId={"promo-field"+i} key={i}>
                    <Form.Label column sm="3">
                      {item.title} <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={item.name}
                        onChange={this.props.handleInputOnChange}
                        required
                        type={item.type}/>
                      <Form.Control.Feedback type="invalid">{item.title} is required</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
        
                );
                break;
          }
          })
        }
        <div className="mt-3 text-center">
          <Link to={`${process.env.PUBLIC_URL}/promotions/list`} className="mr-2 btn btn-md btn-danger">Cancel</Link>
          <Button size="md"
            type={`submit`}
            disabled={this.props.isSubmitting}
            variant="primary">{this.props.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
        </div>
      </Form>
    );
  }
}
export default PromotionForm;
