import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

class DepositUpload extends Component {
  render() {
    const { isSubmitting, onFileChange, handleInputChange } = this.props;

    return (
      <div className="card-block">
        <div className="block-body">
          <div className="variation-card" style={{ borderBottom: "none" }}>
            <div className="variation-header v-middle">
              <div className="d-flex variation-title mb-0 nowrap">
                Deposit Upload
              </div>
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Deposited Amount</th>
                  <th>Deposit No</th>
                  <th>Deposit Slip Image</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "33%" }}>
                    <input
                      name="deposit_amount"
                      className="form-control form-control-sm"
                      type="number"
                      min="1"
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ width: "33%" }}>
                    <input
                      name="deposit_no"
                      className="form-control form-control-sm"
                      type="text"
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={{ width: "33%" }}>
                    <input
                      name="deposit_slip"
                      className="form-control form-control-sm"
                      type="file"
                      onChange={onFileChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default DepositUpload;
