import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import { FaFileExport } from "react-icons/all";
import { getFormattedDate } from "../../helper/utils";
import { toast } from "react-toastify";

class ExportCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
    };
    this.csvLinkEl = React.createRef();
  }

  downloadReport = async () => {
    setTimeout(() => {
      this.csvLinkEl.current.link.click();
      this.setState({ isExporting: false });
      toast.success(`${this.props.title} has been exported successfully`);
    });
  };

  render() {
    return (
      <div>
        <Button
          disabled={this.state.isExporting}
          onClick={() => this.downloadReport()}
          className={
            this.props.exportLabel
              ? "btn btn-sm btn-primary"
              : "btn btn-sm btn-secondary"
          }
        >
          <FaFileExport /> {this.state.isExporting ? "Exporting..." : "Export"}{" "}
          {!!this.props.exportLabel ? this.props.exportLabel : null}
        </Button>
        <CSVLink
          className="d-none"
          filename={
            this.props.title +
            "-" +
            getFormattedDate(new Date(), "DD-MM-YYYY") +
            ".csv"
          }
          data={this.props.data}
          headers={this.props.headers}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}

export default ExportCSV;
