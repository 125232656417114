import React, { useState, useEffect } from "react";
import { Button, Modal, Col, Form, Row } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import {
  getPurchaseOrderItem,
  getQuestionnaires,
  qcPurchaseOrderItem,
} from "../../services/purchaseOrderService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";

const QCModal = ({
  show,
  handleClose,
  id,
  po_id,
  box_id,
  handleGetQC,
  updateBoxList,
}) => {
  const [purchaseOrderItem, setPurchaseOrderItem] = useState(null);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [received_quantity, setReceived_quantity] = useState(0);
  const [passed_quantity, setPassed_quantity] = useState(0);
  const [failed_quantity, setFailed_quantity] = useState(0);
  const [qc_remaining_quantity, setQc_remaining_quantity] = useState(0);
  const [quality_count, setQuality_count] = useState(0);
  const [quantityCheck, setQuantityCheck] = useState(false);
  const [failed_reasons, setFailed_reasons] = useState([]);
  const [failed_ids, setFailed_ids] = useState([]);
  const [isFormValidated, setIsFormValidated] = useState(false);

  useEffect(() => {
    getPurchaseOrderItem(po_id, id, "DhPurchaseOrder")
      .then((res) => {
        setPurchaseOrderItem(res);
        getQuestionnaires(res.category_id)
          .then((res) => {
            if (Array.isArray(res)) {
              setQuestionnaire(res);
            } else {
              toast.info("No questionnaires to check");
            }
          })
          .catch((errMsg) => {
            console.log("Err Msg: ", errMsg);
          });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });

    return () => {
      setPassed_quantity(0);
      setFailed_quantity(0);
      setReceived_quantity(0);
      setQc_remaining_quantity(0);
      setQuality_count(0);
      setQuantityCheck(false);
      setFailed_reasons([]);
      setFailed_ids([]);
      setIsFormValidated(false);
      setPurchaseOrderItem(null);
      setQuestionnaire(null);
    };
  }, [id]);

  const quantityChangeHandler = (event) => {
    setReceived_quantity(event.target.value);
    setQc_remaining_quantity(event.target.value);
  };

  const submitQuantityChangeHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.className += " was-validated";
      setIsFormValidated(true);
    } else {
      event.preventDefault();
      setQuantityCheck(true);
      toast.success("Quantity received " + received_quantity);
    }
  };

  const selectedRadio = (event) => {
    let temp = [...failed_ids];
    if (event.target.value === "false" && temp.includes(event.target.name)) {
      temp.pop(event.target.name);
    } else if (
      event.target.value === "true" &&
      !temp.includes(event.target.name)
    ) {
      temp.push(event.target.name);
    }
    setFailed_ids(temp);
  };

  const qualityCountChangeHandler = (event) => {
    setQuality_count(event.target.value);
  };

  const debouncedOnQualityCheckSubmit = _.debounce((e) => {
    qualityCheckSubmitHandler(e);
  }, DEBOUNCE_TIME);

  const handleWithDebounceQualityCheck = (e) => {
    e.preventDefault();
    debouncedOnQualityCheckSubmit(e);
  };

  const qualityCheckSubmitHandler = (event) => {
    event.preventDefault();
    if (failed_ids.length > 0) {
      setFailed_quantity(parseInt(failed_quantity) + parseInt(quality_count));
      setFailed_reasons([
        ...failed_reasons,
        { quantity: quality_count, question_ids: failed_ids },
      ]);
      toast.warning("Quanlity check failed: " + quality_count);
    } else if (qc_remaining_quantity > 0) {
      setPassed_quantity(parseInt(passed_quantity) + parseInt(quality_count));
      toast.info("Quanlity check passed: " + quality_count);
    }

    if (qc_remaining_quantity === 0) {
      let line_item = purchaseOrderItem;

      let formData = {
        order: {
          order_id: po_id,
          order_type: "DhPurchaseOrder",
          variant_id: line_item.variant_id,
          received_quantity: received_quantity,
          passed_quantity:
            parseInt(received_quantity) - parseInt(failed_quantity),
          failed_quantity: failed_quantity,
          failed_reasons: failed_reasons,
        },
      };

      qcPurchaseOrderItem(formData)
        .then((res) => {
          handleGetQC(res);
          updateBoxList(box_id, res);
          toast.success("QC done !");
          handleClose();
        })
        .catch((errMsg) => {
          console.log("Err Msg: ", errMsg);
        });
    }

    let negation = parseInt(qc_remaining_quantity) - parseInt(quality_count);
    setQc_remaining_quantity(negation);
    setFailed_ids([]);
    document.getElementById("questionnaire-form").reset();
  };

  let checkForm;
  if (purchaseOrderItem && purchaseOrderItem.qc_status) {
    checkForm = (
      <div className="d-flex justify-content-center">
        <p>Qauntity & Quality Check Done!</p>
      </div>
    );
  } else if (quantityCheck === false) {
    checkForm = (
      <>
        <h5 className="card-title">Quantity Check</h5>
        <Form
          noValidate
          validated={isFormValidated}
          onSubmit={submitQuantityChangeHandler}
        >
          <Form.Group as={Row} controlId="displayOrShopName">
            <Form.Label column sm="4">
              Quantity Received <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="3" md={3} lg={3}>
              <Form.Control
                name={"name"}
                defaultValue={0}
                onChange={quantityChangeHandler}
                max={purchaseOrderItem && purchaseOrderItem.sent_quantity}
                min={0}
                required
                type="number"
              />
              <Form.Control.Feedback type="invalid">
                Quantity is required and not more than sent quantity
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div className="mt-3">
            <Button type={"submit"} variant="primary">
              {" "}
              Next{" "}
            </Button>
          </div>
        </Form>
      </>
    );
  } else {
    checkForm = (
      <>
        <h5 className="card-title">Quality Check</h5>
        <br />
        <Form id="questionnaire-form" onSubmit={handleWithDebounceQualityCheck}>
          {qc_remaining_quantity > 0 ? (
            <>
              {questionnaire &&
                questionnaire.map((item, index) => {
                  return (
                    <Form.Group as={Row}>
                      <Form.Label column sm="6">
                        {item.question}
                      </Form.Label>
                      <Col sm="9" md={8} lg={3}>
                        <RadioGroup
                          row
                          name={item.id}
                          value={failed_ids.includes(`${item.id}`)}
                          onChange={selectedRadio}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </Col>
                    </Form.Group>
                  );
                })}
              <br></br>
              <Form.Group as={Row} controlId="displayOrShopName">
                <Form.Label column sm="4">
                  Quantity of Products<span className="text-danger">*</span>
                </Form.Label>
                <Col sm="3" md={3} lg={3}>
                  <Form.Control
                    name={"name"}
                    defaultValue={0}
                    onChange={qualityCountChangeHandler}
                    min={0}
                    max={
                      qc_remaining_quantity > 0
                        ? qc_remaining_quantity
                        : received_quantity
                    }
                    required
                    type="number"
                  />
                </Col>
              </Form.Group>
            </>
          ) : (
            <> Quantity and Quality check complete ! Press done to confirm. </>
          )}
          <div className="mt-3">
            <Button
              type={"submit"}
              variant={qc_remaining_quantity > 0 ? "primary" : "success"}
            >
              {qc_remaining_quantity > 0 ? "Next" : "Done"}
            </Button>
          </div>
        </Form>
      </>
    );
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Purchase Order Product QC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-block">
            <div className="block-body">
              <table className="order-table table table-striped table-hover ">
                <thead>
                  <tr>
                    <th style={{ width: "20px" }}>#</th>
                    <th>Product</th>
                    <th>Ordered</th>
                    <th>Sent</th>
                    <th>Received</th>
                    <th>Passed</th>
                    <th>Failed</th>
                    <th>QC Status</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseOrderItem && (
                    <tr key={1}>
                      <td>{purchaseOrderItem.id}</td>
                      <td>
                        <div className="order-item">
                          <Link
                            to={`${process.env.PUBLIC_URL}/product/details/1`}
                          >
                            {purchaseOrderItem.product_title}
                          </Link>
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          {purchaseOrderItem.due_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item">
                          {purchaseOrderItem.sent_quantity}
                        </div>
                      </td>

                      <td>
                        <div className="order-item">{received_quantity}</div>
                      </td>

                      <td>
                        <div className="order-item">{passed_quantity}</div>
                      </td>

                      <td>
                        <div className="order-item">{failed_quantity}</div>
                      </td>

                      <td>
                        <div className="order-item">
                          {purchaseOrderItem.qc_status ? "Done" : "Not Done"}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row justify-content-md-center mb-5">
            <div className="col-sm-8">
              <div className="card">
                <div className="card-body">{checkForm}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QCModal;
