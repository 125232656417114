import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {FaLongArrowAltLeft} from "react-icons/all";
import {add} from "../../services/baseServices";
import "react-datepicker/dist/react-datepicker.css";
import SupplierForm from "./Form";
import {getList} from "../../services/baseServices";

class Add extends Component {
    constructor() {
        super();

        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleInputOnChange = this.handleInputOnChange.bind(this);
    }

    state = {
        date: '',
        rates: [],
        // product_quality_rating: 0.0,
        // deliver_time_rating: 0.0,
        // service_quality_rating: 0.0,
        // professionalism_rating: 0.0,
        attributeName: '',
        supplierInfo: {},
        formData: {
            // pickup_locations: [],
            // bn_pickup_locations: [],
            contract_start_date: null,
            contract_end_date: null,
        },
        isFormValidated: false,
        isLoadingData: true,
        contract_start_date: null,
        contract_end_date: null,
    };

    handleInputOnChange = (e) => {
        const formData = {...this.state.formData};
        formData[e.target.name] = e.target.value;
        this.setState({formData}, () => {
        });
    }

    handlePickupLocationOnChange = (newValue, type) => {
        let locations = [];
        if (newValue && type === 1) {
            newValue.forEach((val) => {
                locations.push(val.value);
            });
        }
        this.setState({
            formData: {...this.state.formData}
            // formData: {...this.state.formData, pickup_locations: locations}
        }, () => {
        });
    }

    handleBnPickupLocationOnChange = (e, index) => {
        // let locations = [...this.state.formData.bn_pickup_locations];
        // locations[index] = e.target.value;
        this.setState({
            formData: {...this.state.formData}
            // formData: {...this.state.formData, bn_pickup_locations: locations}
        }, () => {
        });
    }

    handleAddItem = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({isFormValidated: true})
        } else {
            this.setState({isSubmitting: true}, () => {
                const formData = {...this.state.formData};
                // formData.address_attributes = {
                //   address_line: formData.address_line,
                //   bn_address_line: formData.bn_address_line,
                //   district_id: parseInt(formData.district_id),
                //   thana_id: parseInt(formData.thana_id),
                //   area_id: parseInt(formData.area_id),
                // }
                // delete formData.address_line;
                // delete formData.bn_address_line;
                // delete formData.district_id;
                // delete formData.thana_id;
                // delete formData.area_id;

                // if (formData.pickup_locations.length > 0) {
                //   let bn_pickup_locations = [];
                //   formData.pickup_locations.map((item, index) => {
                //     bn_pickup_locations.push(formData.bn_pickup_locations[index]);
                //   });
                //   formData.bn_pickup_locations = bn_pickup_locations;
                // } else {
                //   formData.bn_pickup_locations = [];
                // }
                add('suppliers', 'supplier', formData)
                    .then(res => {
                        this.props.history.push(`/supplier/list`);
                        toast.success("Supplier has been created successfully.");
                    })
                    .catch(errMsg => {
                        toast.error(errMsg);
                        this.setState({isSubmitting: false})
                    });
            })
        }
    }

    setDate = (date, attributeName) => {
        const formData = {...this.state.formData};
        formData[attributeName] = date;
        this.setState({formData}, () => {
            console.log(this.state.formData);
        });
    };

    render() {
        const {formData} = this.state;

        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Add Supplier</h2>
                    <div className="ml-auto">
                        <Link to={`${process.env.PUBLIC_URL}/supplier/list`}
                              className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Supplier List</Link>
                    </div>
                </div>

                <div className="card-block">
                    <div className="block-body">
                        <>
                            <SupplierForm
                                handleClick={this.handleAddItem}
                                handleInputOnChange={this.handleInputOnChange}
                                supplierInfo={this.state.supplierInfo}
                                formData={this.state.formData}
                                isSubmitting={this.state.isSubmitting}
                                setDate={this.setDate}
                            />
                        </>
                    </div>
                </div>
                <ToastContainer/>
            </App>
        );
    }
}

export default withRouter(Add);
