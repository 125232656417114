import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import "../../../assets/scss/new-po.scss";

class SearchComponent extends Component {
  state = {
    size: 10,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loadingState !== this.props.loadingState) {
      if (this.props.loadingState === "loaded") this.setState({ size: 10 });
    }
  }

  render() {
    const { searchResult, handleOnChange, loadingState, handleOnSelect } =
      this.props;
    const { size } = this.state;

    const items = searchResult.variants?.slice(0, size).map((item, i) => {
      return (
        <div
          className="search-item"
          key={i}
          onClick={() => handleOnSelect(item)}
        >{`${item.sku} ${
          item.product_title ? "(" + item.product_title + ")" : ""
        }`}</div>
      );
    });

    return (
      <div className="search-container">
        <Form onSubmit={(e) => e.preventDefault()} ref={this.props.wrapperRef}>
          <Form.Group as={Row} controlId="search-input">
            <Form.Label column sm={3} className="text-center">
              Search
            </Form.Label>
            <Col sm={9} md={9} lg={9}>
              <DebounceInput
                className="form-control"
                placeholder="Search.."
                minLength={3}
                debounceTimeout={300}
                onChange={handleOnChange}
                disabled={this.props.isBundleEdit}
              />
              <div className="search-results">
                {loadingState === "loading" && <div>Loading</div>}

                {loadingState === "loaded" && searchResult && (
                  <>
                    {items}
                    <b
                      style={
                        size >= searchResult.item_count
                          ? { display: "none" }
                          : {
                              display: "block",
                              textAlign: "center",
                            }
                      }
                    >
                      ... {searchResult.item_count - size} more items available
                      (type more precisely to see the products you are looking).
                    </b>
                  </>
                )}

                {loadingState === "loaded" && searchResult?.item_count <= 0 && (
                  <div>No result found</div>
                )}
              </div>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default SearchComponent;
