import React, { useState } from "react";
import App from "../../App";
import { Link, useHistory, withRouter } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import AccoutDeactivationReasonForm from "./Form";
import { postData } from "../../services/baseServices";
import { toast } from "react-toastify";

const Create = () => {
  const [reason, setReason] = useState({
    title: "",
    bn_title: "",
  });
  const [metaInfo, setMetaInfo] = useState({})
  const history = useHistory();

  const onFormSubmit = (data) => {
    
    postData("modify_reasons", data)
      .then((res) => {
        if (res.success) {
          history.push(`/acc-delete-reason/list`);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };
  return (
    <App layout="boxed">
      <div className="page-header">
        <h2 className="page-title">Create Account Deactivation Reasons</h2>
        <div className="ml-auto">
          <Link
            to={`/acc-delete-reason/list`}
            className="btn btn-sm btn-link"
          >
            <FaLongArrowAltLeft /> Back to List
          </Link>
        </div>
      </div>
      <div>
        <AccoutDeactivationReasonForm 
            reason={reason} 
            onFormSubmit={onFormSubmit} 
        />
      </div>
    </App>
  );
};
export default withRouter(Create);
