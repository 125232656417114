import React, {Component} from 'react';
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import PromotionForm from "./EditForm";
import { MdArrowBack } from "react-icons/all";
import moment from 'moment';
import { updateData, getDetails } from '../../services/baseServices'
import {getObjectToFormData} from "../../helper/utils";

class Edit extends Component {
  state = {
    promoDetails: {},
    loading: true,
    formData: {
      is_active: true,
      file: null,
      start_time: moment(new Date()).format("HH:mm"),
      end_time: moment(new Date()).format("HH:mm"),
    },
    conf_fields: [],
    fields: [],
    errors:{
      from_date: '',
      to_date: '',
    }
  };

  componentDidMount() {
    getDetails("flash_sales", this.props.match.params.id)
      .then(res => {
        // console.log(res.data);
        this.setState({ promoDetails: res.data, loading: false, formData: {title: res.data.title, title_bn: res.data.title_bn, warehouse_id: res.data.warehouse_id, from_date: res.data.from_date, to_date: res.data.to_date, is_active: res.data.is_active, start_time: res.data.start_time, end_time: res.data.end_time} })
      })
      .catch(errMsg => {
        console.log('Error Message: ', errMsg)
        this.setState({loading: false})
      });
  }

  handleFieldsConfiguration = (fields) => {
    this.setState({conf_fields: fields})
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    if (e.target.type === "radio") {
      formData[e.target.name] = JSON.parse(e.target.value);
    } else {
      formData[e.target.name] = e.target.value;
    }
    this.setState({formData}, () => { });
  }

  onPromoFieldChange = (id, name, value) => {
    let fieldsArray = this.state.fields.filter(item => item.name !== name)
    let fieldObject =
      {
        id: id,
        name: name,
        value: value
      };
    fieldsArray.push(fieldObject);
    this.setState({fields: fieldsArray},() => {
      if(this.state.isFormValidated) {
        this.isValidFields()
      }
    })
  }

  checkDateValidation = (key) => {
    const formData = {...this.state.formData};
    let err = {...this.state.errors}
    if(!formData[key]){
      console.log(key)
      err[key] = key+' is required';
    } else {
      console.log(key)
      err[key] = '';
    }

    this.setState({errors: err});
  }

  handleTimeOnChange = (key, time) => {
    const formData = {...this.state.formData};
    formData[key] = time;
    this.setState({formData}, () => {
      //this.checkDateValidation(key);
    });
  }

  handleDateOnChange = (key ,date) => {
    const formData = {...this.state.formData};
    formData[key] = moment(date).format("YYYY-MM-DD");
    this.setState({formData}, () => {
      this.checkDateValidation(key);
    });
  }

  handleDayChange = (weekDays) => {
    const formData = {...this.state.formData};
    let days = 
      weekDays.filter(item => item.selected).map(item=> {
        return item.day_value
      })
    formData.days = days;
    this.setState({formData}, () => {
      console.log(formData)
    });
    if(this.state.isFormValidated && this.state.formData.is_time_bound && (!this.state.formData.days || this.state.formData.days?.length <= 0)) {
      this.setState({errors: {...this.state.errors, days: ''}});
    }
  }

  isValidFields = () => {
    let validFields = []
    let err = {...this.state.errors}
    this.state.conf_fields.map(con_field => {
      let field = this.state.fields.find(field => field.name == con_field.name)
      if(!field || !field.value || field.value.length < 0) {
        err[con_field.name] = con_field.title+' is required';
        validFields.push(false)
      }
      else {
        err[con_field.name] = '';
        validFields.push(true)
      }
    })
    this.setState({errors: err});
    let isInvalid = validFields.includes(false)
    
    return !isInvalid
  }

  isValid = () => {
    let valid = true;
    let err = {...this.state.errors}

    if(!this.state.formData.from_date) {
      valid = false
      err["from_date"] = 'From date is required';
    }
    if(!this.state.formData.to_date) {
      valid = false
      err["to_date"] = 'To date is required';
      //this.setState({errors: {...this.state.errors, to_date: 'To date is required'}});
    } 
    this.setState({errors: err});
    
    return valid //&& this.isValidFields();
  }

  handleUpdateFlashSale = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({isFormValidated: true})
      if(!this.isValid()) {
        toast.error("Please fill all(*) required fields")
        this.setState({isSubmitting: false})
      }
    } else {
      this.setState({isSubmitting: true}, () => {
        this.setState({isFormValidated: true})
        if(!this.isValid()) {
          toast.error("Please fill all(*) required fields")
          this.setState({isSubmitting: false})
          return
        }
        const formData = { ...this.state.formData };
        const parsedFormData = getObjectToFormData({flash_sale: formData})
        console.log(parsedFormData);
        updateData(`flash_sales/${this.props.match.params.id}`, parsedFormData)
          .then(res => {
            if(res.success) {
              this.props.history.push(`/flashSale/list`);
              toast.success(res.message);
            }
            else{
              toast.error(res.message);
              this.setState({isSubmitting: false})
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
    }
  }

  render() {

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Update Flash Sale</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/flashSale/list`} className={`btn btn-sm btn-link`}><MdArrowBack/>
              Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
              <>
              { !this.state.loading &&
                  <PromotionForm
                  handleClick={this.handleUpdateFlashSale}
                  handleInputOnChange={this.handleInputOnChange}
                  handleDateOnChange={this.handleDateOnChange}
                  handleTimeOnChange={this.handleTimeOnChange}
                  handleDayChange={this.handleDayChange}
                  onPromoFieldChange={this.onPromoFieldChange}
                  handleFieldsConfiguration={this.handleFieldsConfiguration}
                  formData={this.state.formData}
                  isSubmitting={this.state.isSubmitting}
                  isFormValidated={this.state.isFormValidated}
                  errors={this.state.errors}
                  promotion={this.state.promoDetails}
                />
              }
              </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Edit);