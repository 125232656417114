import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { FaLongArrowAltLeft } from "react-icons/all";
import { getList, getDetails } from "../../services/baseServices";
import { uuidv4 } from "../../helper/utils";
import UnpackForm from "./UnpackForm";
import $ from 'jquery'
class Add extends Component {
  state = {
    formData: {},
    routeInfo: {},
    isFormValidated: false,
    isLoadingData: true,
    isSubmitting: false,
    locations: [],
    selectedProducts: [],
  };

  handleVariantQuantityChange = (total_bundle, e, parentSku, childsku, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childsku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        const targetedRowIndex = theProduct.bundle_variants[
          cIndex
        ].packed.findIndex((x) => x.id === item_id);

        const canAdd = this.checkQuantityLimitOfSKU(
          total_bundle,
          parentSku,
          childsku,
          parseInt(e.target.value),
          item_id
        );
        // check can update Q?
        if (canAdd) {
          theProduct.bundle_variants[cIndex].packed[targetedRowIndex].quantity =
            parseInt(e.target.value);
          selectedProducts[pIndex] = theProduct;
          this.setState({ selectedProducts }, () =>
            this.countTotalNumOfAllocatedQuantityUnderSKU(
              parentSku,
              cIndex,
              this.state.selectedProducts[pIndex].bundle_variants[cIndex].packed
            )
          );
        } else toast.info("Could not upgrade due to quantity limit exceed");
      }
    }
  };

  isThisLocationAlreadyAssignedUnderSKU = (pIndex, cIndex, loc_id) => {
    let { selectedProducts } = this.state;
    return selectedProducts[pIndex].bundle_variants[cIndex].packed.some(
      (element) => element.location_id === loc_id
    );
  };

  setVariantsLocations = (e, parentSku, childSku, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childSku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        const targetedRowIndex = theProduct.bundle_variants[
          cIndex
        ].packed.findIndex((x) => x.id === item_id);

        // check already assigned location?
        if (
          !this.isThisLocationAlreadyAssignedUnderSKU(
            pIndex,
            cIndex,
            parseInt(e.target.value)
          )
        ) {
          const locIndex = theProduct.bundle_variants[
            cIndex
          ].locations.findIndex((loc) => loc.id === parseInt(e.target.value));
          if (pIndex !== -1) {
            if (
              theProduct.bundle_variants[cIndex].packed[targetedRowIndex]
                .quantity >
              theProduct.bundle_variants[cIndex].locations[locIndex].quantity
            ) {
              toast.info("Insuficient quantity");
            } else {
              theProduct.bundle_variants[cIndex].packed[
                targetedRowIndex
              ].location_id = parseInt(e.target.value);
              selectedProducts[pIndex] = theProduct;
              this.setState({ selectedProducts });
            }
          }
        } else {
          theProduct.bundle_variants[cIndex].packed[
            targetedRowIndex
          ].location_id = null;
          selectedProducts[pIndex] = theProduct;
          this.setState({ selectedProducts }, () => {
            $("select#"+item_id).val("").trigger("change");
            toast.info("Already assigned location. Please choose other.");
          });
        }
      }
    }
  };

  handleInputQRCodeOnChange = (e, parentSku, childSku, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childSku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        const targetedRowIndex = theProduct.bundle_variants[
          cIndex
        ].packed.findIndex((x) => x.id === item_id);

        theProduct.bundle_variants[cIndex].packed[targetedRowIndex].qr_code =
          e.target.value;
        selectedProducts[pIndex] = theProduct;
        this.setState({ selectedProducts });
      }
    }
  };

  countTotalNumOfAllocatedQuantityUnderSKU = (sku, cIndex, list) => {
    // count
    // update
    let total = 0;
    list.forEach((item) => {
      total = total + parseInt(item.quantity);
    });

    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === sku
    );

    if (productIndex !== -1) {
      const theProduct = selectedProducts[productIndex];
      theProduct.bundle_variants[cIndex].allocated = total;
      selectedProducts[productIndex] = theProduct;

      this.setState({ selectedProducts });
    }
  };

  setBundleLocation = (e, item_id) => {
    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === item_id
    );

    if (pIndex !== -1) {
      selectedProducts[pIndex].bundle_location_id = parseInt(e.target.value);
      this.setState({ selectedProducts });
    }
  };

  checkQuantityLimitOfSKU = (
    total_bundle,
    parentSKU,
    childsku,
    reqQuantity = 1,
    item_id = null
  ) => {
    let selectedProducts = [...this.state.selectedProducts];
    const parentFound = selectedProducts.find(
      (element) => element.bundle_variant_id === parentSKU
    );

    if (parentFound !== undefined) {
      const childFound = parentFound.bundle_variants.find(
        (element) => element.sku === childsku
      );
      
      if (childFound !== undefined) {
        if (item_id) {
          let total = 0;
          childFound.packed.forEach((item) => {
            if (item.id !== item_id) {
              total = total + parseInt(item.quantity);
            }
          });

          if (childFound.quantity*total_bundle >= total + reqQuantity) {
            return true;
          } else {
            return false;
          }
        } else {
          if (childFound.quantity*total_bundle - childFound.allocated >= reqQuantity) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  };

  addOneRowUnderSKU = (parentSKU, childsku) => {

    let selectedProducts = [...this.state.selectedProducts];
    const pIndex = selectedProducts.findIndex(
      (element) => element.bundle_variant_id === parentSKU
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childsku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        
        theProduct.bundle_variants[cIndex].packed.push({
          id: uuidv4(),
          qr_code: "",
          location_id: "",
          quantity: 1,
        });
        selectedProducts[pIndex] = theProduct;

        this.setState({ selectedProducts }, () =>
          this.countTotalNumOfAllocatedQuantityUnderSKU(
            parentSKU,
            cIndex,
            this.state.selectedProducts[pIndex].bundle_variants[cIndex].packed
          )
        );
      }
    }
  };

  addItemIntoPackUnderSKU = (total_bundle, parentSKU, childsku) => {
    const canAdd = this.checkQuantityLimitOfSKU(total_bundle, parentSKU, childsku);
    if (canAdd) this.addOneRowUnderSKU(parentSKU, childsku);
    else toast.info("Could not add due to quantity limit exceed");
  };

  handleVariantRemove = (e, parentSku, childSku, item_id) => {
    e.preventDefault();

    let { selectedProducts } = this.state;
    const pIndex = selectedProducts.findIndex(
      (x) => x.bundle_variant_id === parentSku
    );

    if (pIndex !== -1) {
      const cIndex = selectedProducts[pIndex].bundle_variants.findIndex(
        (element) => element.sku === childSku
      );

      if (cIndex !== -1) {
        const theProduct = selectedProducts[pIndex];
        if (theProduct.bundle_variants[cIndex].packed.length === 1) {
          // toast.error("Couldn't remove successfully.");
          toast.info("At least one bundle variant has to be attached.");
        } else {
          const targetedRowIndex = theProduct.bundle_variants[
            cIndex
          ].packed.findIndex((x) => x.id === item_id);

          theProduct.bundle_variants[cIndex].packed.splice(targetedRowIndex, 1);

          this.setState({ selectedProducts }, () => {
            toast.success("Removed successfully.");
            this.countTotalNumOfAllocatedQuantityUnderSKU(
              parentSku,
              cIndex,
              this.state.selectedProducts[pIndex].bundle_variants[cIndex].packed
            );
          });
        }
      }
    }
  };

  componentDidMount() {
    getList("locations")
      .then((res) => {
        this.setState({ locations: res }, () => {
          getDetails("bundles/variants", this.props.match.params.id)
            .then((res) => {
              let selectedProducts = [...this.state.selectedProducts];
              
              let tempBundleVariants = [];
              res.data.bundle_variants.forEach((bv) => {
                tempBundleVariants.push({
                  variant_id: bv.variant_id,
                  sku: bv.sku,
                  available_quantity: bv.warehouse_available_quantity,
                  quantity: bv.quantity,
                  locations: this.state.locations,
                  allocated: bv.quantity,
                  packed: [
                    {
                      id: uuidv4(),
                      qr_code: "",
                      location_id: null,
                      quantity: bv.quantity,
                    },
                  ],
                });
              });
              const toBpushed = {
                bundle_variant_sku: res.data.sku,
                product_title: res.data.product_title,
                available_quantity: res.data.bundle_available_quantity,
                bundle_variant_id: this.props.match.params.id,
                bundle_locations: res.data.bundle_locations,
                bundle_location_id: "",
                bundle_variants: tempBundleVariants,
              };

              selectedProducts.push(toBpushed);
              this.setState({selectedProducts});
            })
            .catch((errMsg) => {
              console.log("Err Msg: ", errMsg);
            });
        });
      })
      .catch((errMsg) => {});
  }

  render() {

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Unpack Bundle</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/bundle/list`}
              className="btn btn-sm btn-link">
              <FaLongArrowAltLeft /> Back to Bundle Pack List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
          <>
              <UnpackForm
                selectedProducts={this.state.selectedProducts}
                handleVariantRemove={this.handleVariantRemove}
                handleVariantQuantityChange={this.handleVariantQuantityChange}
                handleInputQRCodeOnChange={this.handleInputQRCodeOnChange}
                setVariantsLocations={this.setVariantsLocations}
                addItemIntoPackUnderSKU={this.addItemIntoPackUnderSKU}
                setBundleLocation={this.setBundleLocation}
              />
            </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Add);
