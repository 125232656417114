import React, { Component } from "react";
import App from "../../App";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiEye } from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getOrderList } from "../../services/customerOrderService";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getList } from "../../services/baseServices";
import { getFormattedDate, getWarehouseType } from "../../helper/utils";
import Loader from 'react-loader';
import moment from 'moment'

var startDate = moment().subtract(29, 'days');
var today = moment();

const warehouseType = getWarehouseType();

// noinspection JSUnresolvedVariable
class CustomerOrderList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemList: [],
			orderStatus: [],
			formData: {
				shipping_type: "",
				schedule: "",
				start_date_time: getFormattedDate(startDate, "YYYY-MM-DD"),
				end_date_time: getFormattedDate(today, "YYYY-MM-DD"),
				status: "", //order_order_placed,
				warehouse_id: '',
				distributor_id: ''
			},
			isLoading: true,
			activePage: 1,
			per_page: 20,
			page: 1,
			counter: 1,
			start_date_time: startDate,
			end_date_time: today,
			distributors: [],
			dh_list: [],
		};
	}

	getData() {
		const obj = {};
		if (this.state.per_page > 0) obj.per_page = this.state.per_page;
		if (this.state.page > 0) obj.page = this.state.page;

		if (this.state.formData.shipping_type.length > 0)
			obj.shipping_type = this.state.formData.shipping_type;
		if (this.state.formData.schedule.length > 0)
			obj.schedule = this.state.formData.schedule;
		if (this.state.formData.status.length > 0)
			obj.status = this.state.formData.status;
		if (this.state.start_date_time)
			obj.start_date_time = getFormattedDate(this.state.formData.start_date_time, "YYYY-MM-DD");
		if (this.state.end_date_time)
			obj.end_date_time = getFormattedDate(this.state.formData.end_date_time, "YYYY-MM-DD");
		if(this.state.formData.business_type) obj.business_type = this.state.formData.business_type;
		
		obj.order_no = this.state.formData.order_no;
		obj.warehouse_id = this.state.formData.warehouse_id;
		obj.distributor_id = this.state.formData.distributor_id;

		this.setState({ isLoading: true });
		getOrderList(obj)
			.then((res) => {
				this.setState({
					itemList: res.data,
					counter:
						this.state.activePage - 1 > 0
							? (this.state.activePage - 1) * this.state.per_page + 1
							: 1,
					per_page: res.headers["x-per-page"],
					total_data: res.headers["x-total"],
					isLoading: false,
				});
			})
			.catch(() => { });

	}

	componentDidMount() {
		this.getData();
		getList("order_status/admin")
			.then((res) => {
				this.setState({
					orderStatus: res,
					isLoading: false,
				});
			})
			.catch(() => { });

		getList("distributors")
			.then((res) => {
				this.setState({ distributors: res?.data });
			})
			.catch((errMsg) => { });

		getList("inventories/warehouses")
			.then((res) => {
				this.setState({ dh_list: res.warehouses });
			})
			.catch((errMsg) => { });
	}

	handleFilterOnChange = (e) => {
		const formData = { ...this.state.formData };
		formData[e.target.name] = e.target.value;
		this.setState({ formData, page: 1 });
	};

	filterList = (e) => {
		e.preventDefault();
		this.setState({ activePage: 1 });
		this.getData();
	};

	handlePageChange = (pageNumber) => {
		this.setState(
			{ page: pageNumber, activePage: pageNumber, isLoading: true },
			() => {
				this.getData();
			}
		);
	};

	handleOnApply = (e, picker) => {
		this.setState({
			start_date_time: picker.startDate.format(),
			end_date_time: picker.endDate.format(),
		});
		this.setState({
			formData: {
				...this.state.formData,
				start_date_time: picker.startDate.format(),
				end_date_time: picker.endDate.format(),
			},
		});
	};

	setOrderClassName = (inputValue) => {
		switch (inputValue) {
			case "express_delivery":
				return "text-danger";
			case "in_transit_delivery_switch":
				return "text-primary";
			case "in_transit_partner_switch":
				return "text-warning";
			default:
				return "";
		}
	};

	render() {
		return (
			<App layout="">
				<div className="main-content">
					<div className="page-header">
						<h2 className="page-title">Customer Orders List</h2>
					</div>
					<form className="form-inline mb-3">
						<div className="form-group mr-3">
							<label htmlFor="searchInput" className="sr-only">
								Search by Order No
							</label>
							<input
								type="search"
								name="order_no"
								id="order_no"
								onChange={this.handleFilterOnChange}
								defaultValue={this.state.formData.order_no}
								className="form-control form-control-sm"
								placeholder="Search by Order No"
							/>
						</div>
						<div className="form-group mr-3">
							<label htmlFor="status" className="sr-only">
								Shipping Type
							</label>
							<select
								id="status"
								name="shipping_type"
								className="form-control form-control-sm"
								onChange={this.handleFilterOnChange}
								value={this.state.formData.shipping_type}
							>
								<option value="">--Select Shipping Type--</option>
								<option value="pick_up_point">Pickup Point</option>
								<option value="home_delivery">Home Delivery</option>
								<option value="express_delivery">Express</option>
							</select>
						</div>
						<div className="form-group mr-3">
						<label htmlFor="business_type" className="sr-only">Business Type</label>
						<select id="business_type" name="business_type" className='form-control form-control-sm'
							onChange={ this.handleFilterOnChange}>
							<option value="">--Select Business Type--</option>
							<option value='b2c'>B2C</option>
							<option value='b2b'>B2B</option>
						</select>
						</div>
						<div className="form-group mr-3">
							<label htmlFor="schedule" className="sr-only">
								Shipping Date
							</label>
							<select
								id="schedule"
								name="schedule"
								className="form-control form-control-sm"
								onChange={this.handleFilterOnChange}
								value={this.state.formData.schedule}
							>
								<option value="">--Select Schedule Date--</option>
								<option value="sat_mon_wed">SAT-MON-WED</option>
								<option value="sun_tues_thurs">SUN-TUE-THURS</option>
								<option value="sat_sun_mon_tues_wed_thurs">
									SAT-SUN-MON-TUE-WED-THURS
								</option>
							</select>
						</div>

						{/* For FC */}

						{warehouseType === "central" ?
							<div className="form-group mr-3">
								<label htmlFor="warehouse_id" className="sr-only">
									Warehouse
								</label>
								<select
									id="warehouse_id"
									name="warehouse_id"
									value={this.state.formData.warehouse_id}
									className="form-control form-control-sm"
									onChange={(e) => {
										this.handleFilterOnChange(e);
									}}
								>
									<option value="">--Select FC--</option>
									{this.state.dh_list.length > 0
										? this.state.dh_list.map((item, i) => {
											return (
												<option key={i} value={item.id}>
													{item.name}
												</option>
											);
										})
										: ""}
								</select>
							</div>
							:
							<div className="form-group mr-3">
								<label htmlFor="distributor_id" className="sr-only">
									Distributor
								</label>
								<select
									id="distributor_id"
									name="distributor_id"
									className="form-control form-control-sm"
									onChange={this.handleFilterOnChange}
									value={this.state.formData?.distributor_id}
								>
									<option value="">--Select Distributor--</option>
									{this.state.distributors.map((item, i) => {
										return (
											<option
												key={i}
												value={item.id}
											>
												{item.name}
											</option>
										);
									})}
								</select>
							</div>

						}



						<div className="form-group mr-3">
							<label htmlFor="status" className="sr-only">
								Status
							</label>
							<select
								id="status"
								name="status"
								className="form-control form-control-sm"
								onChange={this.handleFilterOnChange}
								value={this.state.formData.status}
							>
								<option value="">--Select Status--</option>
								{this.state?.orderStatus &&
									this.state?.orderStatus?.map((item) => {
										return (
											<option value={item?.admin_order_status}>
												{item?.admin_order_status}
											</option>
										);
									})}
							</select>
						</div>
						<div className="form-group mr-3">
							<label htmlFor="status" className="sr-only">
								Date Range
							</label>
							<DateRangePicker
								initialSettings={{
									showDropdowns: true,
									locale: { format: "DD/MM/YYYY" },
									startDate: this.state.start_date_time,
									endDate: this.state.end_date_time,
									maxSpan: { "months": 3 },
									autoApply: true,
								}}
								onApply={(e, picker) => this.handleOnApply(e, picker)}
							>
								<input
									type="text"
									value={
										this.state.start_date_time && this.state.end_date_time
											? `${getFormattedDate(this.state.start_date_time, "DD/MM/YYYY"
											)} - ${getFormattedDate(this.state.end_date_time, "DD/MM/YYYY"
											)}`
											: ""
									}
									style={{ textAlign: "left", minWidth: "150px" }}
									placeholder={"Select date range"}
									className="form-control form-control-sm"
								/>
							</DateRangePicker>
						</div>
						<Button
							size="sm"
							type={`submit`}
							onClick={this.filterList}
							variant="primary"
						>
							Filter
						</Button>
					</form>
					<Loader loaded={!this.state.isLoading}>
						<div className="card-block">
							<div className="block-body">

								<table className="order-table table table-striped">
									<thead>
										<tr>
											<th style={{ width: "20px" }}>#</th>
											<th>Order Date</th>
											{
												warehouseType === "central" ?
													<th>FC</th> : <th>Distributor</th>
											}
											<th>Shipping Type</th>
											<th>Status</th>
											<th>Price</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{this.state.itemList?.length > 0 ? (
											this.state.itemList?.map((item, index) => {
												let highlightedClsName =
													item.status_type === 'cancelled' || item.status_type === 'completed' || item.status_type === 'packed_cancelled' ? '' :
														(this.setOrderClassName(item.status_type) + ' ' + this.setOrderClassName(item.shipping_type));
												return (
													<tr
														key={index}
														className={highlightedClsName}
													>
														<td>{item.order_id}</td>
														<td>
															<div className="order-item">
																{getFormattedDate(item.date, "LT, Do, MMMM, YYYY")}
															</div>
														</td>
														<td>
															<div className="order-item">
																{
																	warehouseType === "central" ?
																		<span>{item?.warehouse_name}</span> :
																		<span>{item?.distributor_name}</span>
																}
															</div>
														</td>
														<td>
															<div className="order-item">
																{item.shipping_type}
															</div>
														</td>

														<td>
															<div className="order-item">{item.status}</div>
														</td>
														<td>
															<div className="order-item">{item.price}</div>
														</td>
														<td style={{ width: "150px" }} className={"nowrap"}>
															<div className="btn-group">
																<OverlayTrigger
																	key="view"
																	placement="top"
																	overlay={
																		<Tooltip id={`tooltip-view`}>View</Tooltip>
																	}
																>
																	<Link
																		to={`/customerOrder/details/${item.order_id}`}
																		className="btn btn-sm btn-default"
																	>
																		<FiEye />
																	</Link>
																</OverlayTrigger>
															</div>
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan={7} className="text-center">
													<h5>No Order Found</h5>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="mt-3">
								<Pagination
									itemClass="page-item"
									linkClass="page-link"
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.per_page}
									totalItemsCount={this.state.total_data}
									pageRangeDisplayed={10}
									onChange={this.handlePageChange.bind(this)}
								/>
							</div>
						</div>
					</Loader>
				</div>
			</App>
		);
	}
}

export default withRouter(CustomerOrderList);
