import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, objectToFormData, getObjectToFormData, getLocalAuthToken} from "../helper/utils";

export const connectRouteDevice = (url, key, data) => {
    return new Promise((resolve, reject) => {
      axios.post(API_BASE_URL + '/' + url, { [key]: data }, {
        headers: { }
      }).then(res => {
        if(res.data.error){
            reject(res.data.error)
        } else{
            resolve(res.data)
        }
      })
        .catch(err => {
            reject('Something went wrong')
        })
    })
}

export const disconnectRouteDevice = route_id => {
    const authData = getLocalAuthData();
    return new Promise((resolve, reject) => {
      axios.put(API_BASE_URL + '/route_devices/' + route_id + '/disconnect', {
        headers: { }
      }).then(res => {
        if(res.data.error){
          reject(res.data.error)
        } else{
          resolve(res.data)
        }
      })
        .catch(err => {
          reject('Something went wrong')
        })
    })
}

export const getRouteList = params => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/routes/paginate`, {
      params,
      headers: {}
    }).then(res => {
      console.log(res)
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res)
      }
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getCollectionDetails = (url) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {

      axios.get(API_BASE_URL + ``+ url, {
          headers: {}
      }).then(res => {
          if(res.data.error){
              reject(res.data.error)
          } else{
              resolve(res.data)
          }
      }).catch(err => {
          reject(err.message)
      })
  })
}

export const collect = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + '/' + url, data, {
      headers: {}
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject('Something went wrong')
      })
  })
}


export const receiveReturn = (url, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(API_BASE_URL + '/' + url, data, {
      headers: {}
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject('Something went wrong')
      })
  })
}