import React, { Component } from "react";
import App from "../../App";
import { FaLongArrowAltLeft, IoMdClose } from "react-icons/all";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import "../../assets/scss/add-product.scss";
import styleReactSelect from "../../assets/styleReactSelect";
import { toast } from "react-toastify";
import { getProductType } from "../../services/productTypeService";
import {
  updateProduct,
  getProductDetails,
  deleteVariation,
  deleteAttributeImage,
} from "../../services/productServices";
import BrowseButton from "../../components/BrowseButton";
import JoditEditorComponent from "../../components/JoditEditorComponent";
import ChooseCategory from "../../components/ChooseCategory";
import { deleteData, getData, getList } from "../../services/baseServices";
import VariantForm from "./VariantForm";
import ConfirmationModal from "../common/Modal";
import { difference } from "lodash";
import { convertToSlug } from "../../helper/utils";
import BundleForm from "./Bundle/Index";
import MetaInfo from "../../components/MetaInfo";

class EditProduct extends Component {
  constructor() {
    super();
    this.state = {
      uniqueSlug: "",
      product: {
        product_features_attributes: [],
        product_type_ids: [],
        variants_attributes: [
          {
            primary: true,
            price_consumer: "",
            price_retailer: "",
            price_distribution: "",
            discount_type: "percentage",
          },
        ],
        product_attribute_images_attributes: [],
        bundle_variants: [],
        is_emi_available: false,
        tenures: [],
      },
      variant_attributeValues: [],
      copy_variant_attribute_values: [],
      productTypes: [],
      categories: [],
      brands: [],
      category_ids: [],
      attributeSet: [],
      product_type: [],
      selectedAttributeSet: {},
      selectedAttribute: {},
      attributeValues: [],
      metaInfo: {},
      faq: {},
      notLastChild: false,
      selectedBrand: {},
      updateVariantFor: null,
      attribute_value_images: [],
      deletedImages: {
        ProductImage: [],
        AttrValueImage: [],
        HeroImage: [],
        size_guideline_image: "",
      },
      heroImage: {},
      productImages: [],
      size_guideline_image: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { product } = this.state;
    if (prevState.product?.slug !== product?.slug) {
      if (product?.slug) {
        getData("slugs/uniqueness", {
          slug: product.slug,
          id: this.props.match.params.id,
          type: "product",
        })
          .then((res) => this.setState({ uniqueSlug: res.success }))
          .catch((err) => this.setState({ uniqueSlug: "" }));
      } else {
        this.setState({ uniqueSlug: "" });
      }
    }
  }

  deleteProductImage = (imgType, imageId) => {
    deleteData(`products/${imageId}/delete_image`, {
      product_id: this.state.product.id,
    })
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          const deletedImages = { ...this.state.deletedImages };
          const deletedImagesOnTypes = deletedImages[imgType];
          deletedImagesOnTypes.push(imageId);
          this.setState({ deletedImages });
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  handleOnMetaInputChange = (e) => {
    const metainfo = { ...this.state.metaInfo };
    const name = e.target.name;
    const value = e.target.value;
    const updatedMetaInfo = { ...metainfo, [name]: value };
    this.setState({ metaInfo: updatedMetaInfo });
  };
  handleOnChangeMetaKeywordValue = (en_bn, value) => {
    const mitainfoDetails = { ...this.state.metaInfo };
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach((val) => {
        valueIdArr.push(val.value);
      });
    }
    if (en_bn === "en") {
      mitainfoDetails.metaKeywords = valueIdArr;
    } else {
      mitainfoDetails.metaKeyKeywordsBn = valueIdArr;
    }
    this.setState({ metaInfo: mitainfoDetails });
  };

  deleteImage = (imgType, imageId) => {
    deleteAttributeImage(imageId)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success(res.message);
          const deletedImages = { ...this.state.deletedImages };
          const deletedImagesOnTypes = deletedImages[imgType];
          deletedImagesOnTypes.push(imageId);
          this.setState({ deletedImages });
        } else {
          toast.error(res.message);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  getProductDetails = () => {
    getProductDetails(this.props.match.params.id)
      .then(async (res) => {
        const productDetailsRes = {
          id: res?.id,
          product_attribute_images_attributes: [],
          is_refundable: res?.is_refundable,
          return_policy: res?.return_policy,
          bn_return_policy: res?.bn_return_policy,
          product_features_attributes:
            res.product_features_attributes !== undefined
              ? res.product_features_attributes?.map((item) => {
                  return {
                    bn_description: item?.bn_description,
                    bn_title: item?.bn_title,
                    description: item?.description,
                    id: item?.id,
                    title: item?.title,
                  };
                })
              : [],
          title: res?.title,
          slug: res?.slug,
          bn_title: res?.bn_title,
          description: res?.description,
          bn_description: res?.bn_description,
          short_description: res?.short_description,
          bn_short_description: res?.bn_short_description,
          sku_type: res?.sku_type,
          product_type: res?.product_type,
          company: res?.company,
          brand_id: res?.brand_id,
          keywords: res?.keywords,
          max_quantity_per_order: res?.max_quantity_per_order,
          product_type_ids: res?.product_types?.map((item) => {
            return item.id;
          }),
          attribute_set_id: res?.attribute_set_id,
          image_attribute_id: res?.image_attribute_id,
          public_visibility: res?.public_visibility,
          weight: res?.weight,
          business_type: res?.business_type,
        };
        this.setState({
          product: productDetailsRes,
        });

        if (this.state.brands?.length > 0) {
          let selectedBrand = this.state.brands.find(
            (item) => item.value === res?.brand_id
          );
          this.setState({ selectedBrand });
        }

        let catArr = [];
        let attributeValues = [];
        let selectedAttributeSet = {};
        if (this.state.attributeSet?.length > 0) {
          selectedAttributeSet = this.state.attributeSet?.find(
            (item) => item.id === productDetailsRes.attribute_set_id
          );
          const product_attribute =
            selectedAttributeSet?.product_attributes?.find(
              (item) => item.id == productDetailsRes.image_attribute_id
            );
          // const product = {...this.state.product, image_attribute_id: e.target.value, product_attribute_images_attributes: []}
          attributeValues = product_attribute?.product_attribute_values;
        }

        if (res?.product_category !== null) {
          catArr = await this.getParentcat(res.product_category, catArr);
        }
        let variantsArr = [];
        let copy_variant_attribute_values = [];
        res?.variants.forEach((item) => {
          const variant = {
            id: item?.id,
            sku: item?.sku,
            code_by_supplier: item?.code_by_supplier,
            weight: item?.weight,
            weight_unit: item?.weight_unit,
            height: item?.height,
            height_unit: item?.height_unit,
            width: item?.width,
            width_unit: item?.width_unit,
            depth: item?.depth,
            depth_unit: item?.depth_unit,
            product_id: item?.product_id,
            primary: item?.primary,
            product_attribute_value_ids:
              res.sku_type === "variable_product"
                ? item?.product_attribute_values?.map((i) => i.id)
                : [],
            price_consumer: item?.price_consumer,
            sku_case_dimension: item?.sku_case_dimension,
            case_weight: item?.case_weight,
            case_weight_unit: item?.case_weight_unit,
            consumer_discount: item?.consumer_discount,
            discount_type: item?.discount_type,
            effective_mrp: item?.effective_mrp,
            moq: item?.moq,
            sku_case_width: item?.sku_case_width,
            sku_case_length: item?.sku_case_length,
            sku_case_height: item?.sku_case_height,
            sku_case_width_unit: item?.sku_case_width_unit,
            sku_case_length_unit: item?.sku_case_length_unit,
            sku_case_height_unit: item?.sku_case_height_unit,
            b2b_price: item?.b2b_price,
            b2b_discount: item?.b2b_discount,
            b2b_discount_type: item?.b2b_discount_type,
            b2b_effective_mrp: item?.b2b_effective_mrp,
            price_distribution: item?.price_distribution,
          };
          copy_variant_attribute_values.push(item?.product_attribute_values);
          variantsArr.push(variant);
        });
        productDetailsRes.variants_attributes = variantsArr;
        // let attribute_value_images = res?.attribute_value_images
        this.setState({
          copy_variant_attribute_values,
          heroImage: res?.hero_image,
          productImages: res?.images,
          size_guideline_image: res?.size_guideline_image,
          selectedAttributeSet,
          attributeValues,
          attribute_value_images: res?.attribute_value_images,
          categories: catArr.reverse(),
          isLoadingData: false,
        });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
  };

  getParentcat = (obj, catArr) => {
    if (obj.parent_category !== null) {
      catArr.push(obj.id);
      this.getParentcat(obj.parent_category, catArr);
    } else {
      catArr.push(obj.id);
    }
    return catArr;
  };

  arrayToSelectOptionArray = (arr) => {
    const newArray = [];
    if (arr) {
      arr.forEach((item) => {
        newArray.push({
          label: item,
          value: item,
        });
      });
    }
    return newArray;
  };

  componentDidMount() {
    getProductType()
      .then(async (res) => {
        await this.setState(
          {
            productTypes: res,
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });

    getList("brands")
      .then(async (res) => {
        let brands = [];
        await res.data.forEach((item) => {
          let brand = { ...item, label: item.name, value: item.id };
          brands.push(brand);
        });
        let selectedBrand = brands.find(
          (item) => item.value === this.state.product?.brand_id
        );
        await this.setState({ brands, selectedBrand });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });

    getList("attribute_sets")
      .then(async (res) => {
        if (res.success) {
          let attrSets = [];
          await res.data.forEach((attr) => {
            let attrSet = { ...attr, value: attr.id, label: attr.title };
            attrSets.push(attrSet);
          });
          let selectedAttributeSet = attrSets.find(
            (item) => item.value === this.state.product?.attribute_set_id
          );
          let attributeValues = [];
          if (res.data?.length > 0) {
            const product_attribute =
              selectedAttributeSet?.product_attributes?.find(
                (item) => item.id == this.state.product.image_attribute_id
              );
            attributeValues = product_attribute?.product_attribute_values;
          }
          await this.setState({
            attributeSet: attrSets,
            selectedAttributeSet,
            attributeValues,
          });
        }
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });

    this.getProductDetails();
  }

  handleOnInputChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;
    let product = { ...this.state.product };
    if (name === "sku_type") {
      if (val === "simple_product" || val === "bundle_product") {
        product = {
          ...product,
          variants_attributes: [
            {
              primary: true,
              price_consumer: "",
              price_retailer: "",
              price_distribution: "",
              discount_type: "percentage",
            },
          ],
          product_attribute_images_attributes: [],
        };
        this.setState({ selectedAttributeSet: {}, attributeValues: [] });
      } else {
        product = { ...product, variants_attributes: [] };
      }
    }
    product = { ...product, [name]: val };
    this.setState(
      {
        product,
      },
      () => {
        if (name === "slug") {
          const slug = convertToSlug(this.state.product.slug);
          this.setState({ product: { ...product, slug } });
        }
      }
    );
  };

  handleProductTypeInputChange = (event) => {
    const target = event.target;
    let value = parseInt(target.value);
    let product = { ...this.state.product };
    let productTypeArr = product.product_type_ids;
    if (target.checked) {
      productTypeArr.push(value);
    } else {
      productTypeArr = productTypeArr.filter(function (e) {
        return e != value;
      });
    }
    product.product_type_ids =
      productTypeArr?.length > 0 ? productTypeArr : [""];
    this.setState({ product });
  };

  handleEMIOptionChange = (e) => {
    const name = e.target.name;
    const val = String(e.target.value) == "true";
    let product = { ...this.state.product };
    product = { ...product, [name]: val, tenures: product.tenures };
    this.setState({ product });
  };

  handleEMITenureInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let product = { ...this.state.product };
    let tenures = product.tenures;
    if (target.checked) {
      tenures.push(value);
    } else {
      tenures = tenures.filter(function (e) {
        return e != value;
      });
    }
    product = { ...product, tenures: tenures };
    this.setState({ product });
  };
  handleAttributeSetChange = (e) => {
    const attributeSet = [...this.state.attributeSet];
    const selectedAttributeSet = attributeSet.find((item) => {
      return item.id === e.id;
    });
    this.setState({ selectedAttributeSet });
    let product = { ...this.state.product };

    if (product.attribute_set_id !== e.id) {
      product.selectedAttributeSet = {};
    }
    product = { ...product, attribute_set_id: e.id };
    this.setState({ product });
  };

  handleAttributeChange = (e) => {
    const product_attribute =
      this.state.selectedAttributeSet?.product_attributes?.find(
        (item) => item.id == e.target.value
      );
    const product = {
      ...this.state.product,
      image_attribute_id: e.target.value,
      product_attribute_images_attributes: [],
    };
    const attributeValues = product_attribute?.product_attribute_values;
    this.setState({ product, attributeValues });
  };

  handleJoEditorOnChange = (name, data) => {
    const product = { ...this.state.product, [name]: data };
    this.setState({ product });
  };

  onConfirmCategory = async (selectedCategories) => {
    const lastCat = selectedCategories[selectedCategories.length - 1];
    const selectedCatIds = selectedCategories?.map((item) => {
      return item.id;
    });
    const product = {
      ...this.state.product,
      category_ids: selectedCatIds,
      leaf_category_id: lastCat.id,
    };
    this.setState({ product });

    if (lastCat?.sub_categories?.length > 0) {
      this.setState({ notLastChild: true });
    } else {
      this.setState({ notLastChild: false });
    }
  };

  handleOnChangeKeywordValue = (en_bn, value) => {
    const product = { ...this.state.product };
    const valueIdArr = [];
    if (value?.length > 0) {
      value.forEach((val) => {
        valueIdArr.push(val.value);
      });
    }
    if (en_bn === "en") {
      product.meta_keyword = valueIdArr;
    } else {
      product.bn_meta_keyword = valueIdArr;
    }
    this.setState({ product }, () => {});
  };

  handleReactSelectOnChange = (name, option) => {
    const product = { ...this.state.product };
    product[name] = option.value;
    this.setState({ product, selectedBrand: option }, () => {});
  };

  handleImages = (name, files, multiple) => {
    const product = { ...this.state.product };
    let fff = [];
    for (let i = 0; i < files.length; i++) {
      fff.push(files[i]);
    }
    product[name] = multiple ? fff : fff[0];
    this.setState({ product });
  };

  addOneKeyFeature = () => {
    let { product } = this.state;
    product.product_features_attributes.push({
      title: "",
      bn_title: "",
      description: "",
      bn_description: "",
    });
    this.setState({ product });
  };

  handleKeyFeature = (e, index, prop) => {
    let { product } = this.state;
    product.product_features_attributes[index][prop] = e.target.value;
    this.setState({ product });
  };

  removeKeyFeature = (index) => {
    let { product } = this.state;
    product.product_features_attributes.splice(index, 1);
    this.setState({ product });
  };

  handleFaqInputOnChange = (e) => {
    const faq = { ...this.state.faq, [e.target.name]: e.target.value };
    this.setState({ faq }, () => {});
  };

  handleAddFaq = () => {
    const faq = this.state.product?.frequently_asked_questions_attributes || [];
    if (
      this.state.faq.question &&
      this.state.faq.answer &&
      this.state.faq.bn_question &&
      this.state.faq.bn_answer
    ) {
      faq.push(this.state.faq);
      const product = {
        ...this.state.product,
        frequently_asked_questions_attributes: faq,
      };
      this.setState({ product }, () => {
        this.setState({ faq: {} }, () => {
          document.getElementById("formAddFaq").reset();
        });
      });
    } else {
      toast.error("Please fill all(*) FAQ data");
    }
  };

  handleUpdateFaq = (index) => {
    if (
      this.state.faq.question &&
      this.state.faq.answer &&
      this.state.faq.bn_question &&
      this.state.faq.bn_answer
    ) {
      const product = { ...this.state.product };
      product.frequently_asked_questions_attributes[index] = this.state.faq;
      this.setState({ product, faq: {}, editFaqIndex: null }, () => {
        document.getElementById("formAddFaq").reset();
      });
    } else {
      toast.error("Please fill all(*) FAQ data");
    }
  };

  handleEditFaq = (index) => {
    const faq = this.state.product.frequently_asked_questions_attributes[index];
    this.setState({ faq: faq, editFaqIndex: index });
  };

  handleDeleteFaq = (index) => {
    const product = { ...this.state.product };
    product.frequently_asked_questions_attributes.splice(index, 1);
    this.setState({ product });
  };

  getEffectiveMRP = (discount_type, discountAmount, price) => {
    if (this.state.product.business_type === "multicat") {
      return discount_type === "percentage"
        ? price - (discountAmount / 100) * price
        : price - discountAmount;
    }
    return Math.round(
      discount_type === "percentage"
        ? price - (discountAmount / 100) * price
        : price - discountAmount
    );
  };

  handleSKUInputOnChange = (e, index) => {
    const variants_attributes = [...this.state.product.variants_attributes];
    variants_attributes[index][e.target.name] = e.target.value;
    switch (e.target.name) {
      case "price_consumer":
        if (variants_attributes[index].consumer_discount) {
          variants_attributes[index].effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.discount_type,
            variants_attributes[index].consumer_discount,
            e.target.value
          );
        } else {
          variants_attributes[index].effective_mrp = e.target.value;
        }
        break;
      case "consumer_discount":
        if (variants_attributes[index].price_consumer) {
          variants_attributes[index].effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.discount_type,
            e.target.value,
            variants_attributes[index].price_consumer
          );
        } else {
          variants_attributes[index].effective_mrp = 0;
        }
        break;
      case "discount_type":
        if (
          variants_attributes[index].price_consumer &&
          variants_attributes[index].consumer_discount
        ) {
          variants_attributes[index].effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.discount_type,
            variants_attributes[index].consumer_discount,
            variants_attributes[index].price_consumer
          );
        } else {
          variants_attributes[index].effective_mrp = 0;
        }
        break;
      case "b2b_discount_type":
        // debugger;
        if (
          variants_attributes[index].b2b_price &&
          variants_attributes[index].b2b_discount
        ) {
          variants_attributes[index].b2b_effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.b2b_discount_type,
            variants_attributes[index].b2b_discount,
            variants_attributes[index].b2b_price
          );
        } else {
          variants_attributes[index].b2b_effective_mrp = 0;
        }
        break;
      case "b2b_price":
        if (variants_attributes[index].b2b_discount) {
          variants_attributes[index].b2b_effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.b2b_discount_type,
            variants_attributes[index].b2b_discount,
            e.target.value
          );
        } else {
          variants_attributes[index].b2b_effective_mrp = e.target.value;
        }
        break;
      case "b2b_discount":
        if (variants_attributes[index].b2b_price) {
          variants_attributes[index].b2b_effective_mrp = this.getEffectiveMRP(
            variants_attributes[index]?.b2b_discount_type,
            e.target.value,
            variants_attributes[index].b2b_price
          );
        } else {
          variants_attributes[index].b2b_effective_mrp = 0;
        }
        break;
      default:
        break;
    }

    const product = { ...this.state.product, variants_attributes };
    this.setState({ product });
  };

  handleAttributeImageChange = (attrValueId, files) => {
    const product = { ...this.state.product };
    let fff = [];
    for (let i = 0; i < files.length; i++) {
      fff.push(files[i]);
    }
    let existingAttrImageIndex =
      product.product_attribute_images_attributes.findIndex(
        (item) => item.product_attribute_value_id == attrValueId
      );
    if (existingAttrImageIndex < 0) {
      product.product_attribute_images_attributes.push({
        product_attribute_value_id: attrValueId,
        is_default: false,
        images_file: fff,
      });
    } else {
      product.product_attribute_images_attributes[existingAttrImageIndex] = {
        product_attribute_value_id: attrValueId,
        is_default: false,
        images_file: fff,
      };
    }
    this.setState({ product });
  };
  handleCloseVariationModal = () => {
    this.setState({
      isShowVariationModal: false,
      updateVariantFor: null,
      product_attribute_value_ids: [],
    });
  };
  handleShowVariationModal = () => {
    this.setState({
      isShowVariationModal: true,
      editSKUIndex: null,
      variant_attributeValues: [],
    });
  };

  handleEditVariantModal = (index) => {
    this.setState({
      updateVariantFor: index,
      isShowVariationModal: true,
      variant_attributeValues: [],
    });
  };

  handleComboOnChange = (e, index, attrValue) => {
    let selectedAttrValue = attrValue?.product_attribute_values?.find(
      (i) => i.id == e.target.value
    );
    let variant_attributeValues = [...this.state.variant_attributeValues];
    // let variants_attributes = product.variants_attributes
    variant_attributeValues[index] = selectedAttrValue; //attrValue?.product_attribute_values[index]
    // product = {...product, variants_attributes};

    this.setState({ variant_attributeValues });
  };

  handleAddVariation = () => {
    if (
      this.state.variant_attributeValues?.filter((item) => item?.id > 0)
        .length < this.state.selectedAttributeSet?.product_attributes.length
    ) {
      toast.error("Please choose a combination");
      return;
    }
    let variants_attributes = [...this.state.product.variants_attributes];
    let variantAttrIds = variants_attributes.map(
      (i) => i.product_attribute_value_ids
    );
    let attrValueIds = this.state.variant_attributeValues.map((i) => i.id);
    if (
      variants_attributes?.length > 0 &&
      variantAttrIds.some((i) => difference(attrValueIds, i).length == 0)
    ) {
      toast.error("The combination is already exists");
      return;
    }

    let copy_variant_attribute_values = [
      ...this.state.copy_variant_attribute_values,
    ];
    copy_variant_attribute_values.push(this.state.variant_attributeValues);
    variants_attributes.push({
      discount_type: "percentage",
      product_attribute_value_ids: this.state.variant_attributeValues.map(
        (item) => {
          return item.id;
        }
      ),
    });
    const product = { ...this.state.product, variants_attributes };
    this.setState({
      product,
      copy_variant_attribute_values,
      variant_attributeValues: [],
    });
    this.handleCloseVariationModal();
  };

  handleUpdateVariation = () => {
    if (
      this.state.variant_attributeValues?.filter((item) => item?.id > 0)
        .length < this.state.selectedAttributeSet?.product_attributes.length
    ) {
      toast.error("Please choose a combination");
      return;
    }
    let variants_attributes = [...this.state.product.variants_attributes];
    let variantAttrIds = variants_attributes.map(
      (i) => i.product_attribute_value_ids
    );
    let attrValueIds = this.state.variant_attributeValues.map((i) => i.id);
    if (
      variants_attributes?.length > 0 &&
      variantAttrIds.some((i) => difference(attrValueIds, i).length == 0)
    ) {
      toast.error("The combination is already exists");
      return;
    }
    const product = { ...this.state.product };
    let copy_variant_attribute_values = [
      ...this.state.copy_variant_attribute_values,
    ];
    copy_variant_attribute_values[this.state.updateVariantFor] =
      this.state.variant_attributeValues;
    product.variants_attributes[
      this.state.updateVariantFor
    ].product_attribute_value_ids = this.state.variant_attributeValues.map(
      (item) => {
        return item.id;
      }
    );
    this.setState({
      product,
      updateVariantFor: null,
      copy_variant_attribute_values,
    });
    this.handleCloseVariationModal();
  };

  handleDeleteVariation = (index, id) => {
    const product = { ...this.state.product };
    if (id) {
      deleteVariation(id)
        .then((res) => {
          if (res?.status_code === 200) {
            toast.success("Variation has been deleted successfully.");
            let variants_attributes = product?.variants_attributes;
            variants_attributes = variants_attributes?.filter(
              (i, j) => j !== index
            );
            product.variants_attributes = variants_attributes;
            let copy_variant_attribute_values = [
              ...this.state.copy_variant_attribute_values,
            ];
            copy_variant_attribute_values =
              copy_variant_attribute_values?.filter((i, j) => j !== index);
            this.setState({ product, copy_variant_attribute_values });
          } else {
            toast.error(res?.message);
          }
        })
        .catch((errMsg) => {
          toast.error(errMsg);
        });
    } else {
      let variants_attributes = product?.variants_attributes;
      variants_attributes = variants_attributes?.filter((i, j) => j !== index);
      product.variants_attributes = variants_attributes;
      let copy_variant_attribute_values = [
        ...this.state.copy_variant_attribute_values,
      ];
      copy_variant_attribute_values = copy_variant_attribute_values?.filter(
        (i, j) => j !== index
      );
      this.setState({ product, copy_variant_attribute_values });
    }
  };

  handleEditProduct = async (e) => {
    e.preventDefault();

    const productData = { ...this.state.product };
    const body = {
      slug: productData?.slug,
      public_visibility: productData?.public_visibility,
      sku_type: productData?.sku_type,
      variants_attributes: productData?.variants_attributes,
      business_type: productData?.business_type,
      title: productData?.title,
      category_ids: productData?.category_ids,
      leaf_category_id: productData?.leaf_category_id,
      product_type: productData?.product_type,
      brand_id: productData?.brand_id,
      hero_image_file: productData?.hero_image_file,
    };
    const updatedProduct = { ...body };
    this.setState({ product: updatedProduct });

    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      this.state.notLastChild ||
      !this.state.product.brand_id
    ) {
      e.preventDefault();
      e.stopPropagation();
      form.className += " was-validated";
      this.setState({ isFormValidated: true });
    } else {
      if (
        this.state.product?.sku_type === "bundle_product" &&
        this.state.product?.bundle_variants.length === 0
      ) {
        toast.error("Please select atleast one SKU for Bundle Product");
      } else {
        this.setState({ isSubmitting: true }, () => {
          delete this.state.product.bundle_status;
          console.log("this.state.product", this.state.product);
          updateProduct(this.props.match.params.id, this.state.product)
            .then((res) => {
              if (res?.status_code === 200) {
                toast.success("Product has been updated successfully.");
                this.props.history.push(`/product/list`);
              } else {
                toast.error(res.message);
                this.setState({ isSubmitting: false });
                this.props.history.push(`/product/list`);
              }
            })
            .catch((errMsg) => {
              toast.error(errMsg);
              this.setState({ isSubmitting: false });
            });
        });
      }
    }
  };

  handleBundleVariations = (data) => {
    // console.log('I see')
    let product = { ...this.state.product };
    // console.log(product.bundle_variants)
    product = { ...product, bundle_variants: data };
    this.setState({ product }, () => {
      // console.log(this.state.product.bundle_variants);
    });
  };

  render() {
    const { product } = this.state;
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Product</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/product/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to Product List
            </Link>
          </div>
        </div>

        <div className="main-content">
          <Form
            noValidate
            validated={this.state.isFormValidated}
            onSubmit={this.handleEditProduct}
          >
            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">What You're Selling</h4>
              </div>

              <div className="block-body">
                <Form.Group as={Row} controlId="business_type">
                  <Form.Label column sm="3">
                    Business Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      name="business_type"
                      id={`custom-radio-multicat`}
                      required
                      onChange={this.handleOnInputChange}
                      checked={this.state.product.business_type === "multicat"}
                      value={"multicat"}
                      label={`Multicat`}
                    />

                    <Form.Control.Feedback type="invalid">
                      Please select a Business type.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Title <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      type="text"
                      defaultValue={product.title}
                      required
                      onChange={(e) => {
                        this.handleOnInputChange(e);
                      }}
                      name="title"
                      placeholder=""
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid product title.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Slug <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      type="text"
                      required
                      disabled
                      // disabled
                      // readOnly
                      onChange={(e) => {
                        this.handleOnInputChange(e);
                      }}
                      name="slug"
                      value={this.state.product?.slug}
                      // className={
                      //   this.state.uniqueSlug === ""
                      //     ? ""
                      //     : [
                      //         this.state.uniqueSlug
                      //           ? "slug-is-valid"
                      //           : "slug-is-invalid",
                      //       ]
                      // }
                    />
                    {this.state.uniqueSlug === false && (
                      <div class="slug-err-msg">
                        Please provide an unique slug.
                      </div>
                    )}
                    {/* <Form.Control.Feedback type="invalid">
                          Please provide a slug.
                        </Form.Control.Feedback>
                        {this.state.uniqueSlug === false && (
                          <div class="slug-err-msg">
                            Please provide an unique slug.
                          </div>
                        )} */}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formCategory">
                  <Form.Label column sm="3">
                    Category <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <ChooseCategory
                      onConfirm={this.onConfirmCategory}
                      selectedCategories={this.state.categories}
                    />
                    {this.state.notLastChild && (
                      <span className="text-danger">
                        {" "}
                        Please select a sub-category.{" "}
                      </span>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formName">
                  <Form.Label column sm="3">
                    Weight To set Priority
                  </Form.Label>
                  <Col sm="4" md={4} lg={4}>
                    <Form.Control
                      name="weight"
                      size={"sm"}
                      min="0"
                      step="1"
                      value={product.weight}
                      onChange={this.handleOnInputChange}
                      type={"number"}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please set weight to the product
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formWarrantyPeriod">
                  <Form.Label column sm="3">
                    Publish
                  </Form.Label>

                  <Col sm="4" md={4} lg={4}>
                    <Form.Control
                      name="public_visibility"
                      onChange={this.handleOnInputChange}
                      as="select"
                      type="text"
                    >
                      <option
                        value={false}
                        selected={product.public_visibility === false}
                      >
                        No
                      </option>
                      <option
                        value={true}
                        selected={product.public_visibility === true}
                      >
                        Yes
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formWarrantyPeriod">
                  <Form.Label column sm="3">
                    EMI Enabled
                  </Form.Label>

                  <Col sm="4" md={4} lg={4}>
                    <Form.Control
                      name="is_emi_available"
                      onChange={this.handleEMIOptionChange}
                      as="select"
                      type="text"
                    >
                      <option
                        value={false}
                        selected={product.is_emi_available === false}
                      >
                        No
                      </option>
                      <option
                        value={true}
                        selected={product.is_emi_available === true}
                      >
                        Yes
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </div>
            </div>

            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Basic Information</h4>
              </div>

              <div className="block-body">
                {/* <Form.Group as={Row} controlId="formModel">
                      <Form.Label column sm="3">
                        Meta Keywords
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <CreatableSelect
                          noOptionsMessage={() => "Please type your values..."}
                          isMulti
                          styles={styleReactSelect}
                          onChange={(value) => {
                            this.handleOnChangeKeywordValue("en", value);
                          }}
                          value={
                            product.meta_keyword
                              ? this.arrayToSelectOptionArray(
                                  product.meta_keyword
                                )
                              : ""
                          }
                        />
                      </Col>
                    </Form.Group> */}
                {/* 
                    <Form.Group as={Row} controlId="formModel">
                      <Form.Label column sm="3">
                        Meta Keywords in Bangla
                      </Form.Label>
                      <Col sm="9" md={8} lg={7}>
                        <CreatableSelect
                          noOptionsMessage={() => "Please type your values..."}
                          isMulti
                          styles={styleReactSelect}
                          onChange={(value) => {
                            this.handleOnChangeKeywordValue("bn", value);
                          }}
                          value={
                            product.bn_meta_keyword
                              ? this.arrayToSelectOptionArray(
                                  product.bn_meta_keyword
                                )
                              : ""
                          }
                        />
                      </Col>
                    </Form.Group> */}
                <Form.Group as={Row} controlId="formWarrantyPeriod">
                  <Form.Label column sm="3">
                    Refundable
                  </Form.Label>

                  <Col sm="4" md={4} lg={4}>
                    <Form.Control
                      name="is_refundable"
                      onChange={this.handleOnInputChange}
                      as="select"
                      type="text"
                    >
                      <option
                        value={true}
                        selected={product.is_refundable === true}
                      >
                        Yes
                      </option>
                      <option
                        value={false}
                        selected={product.is_refundable === false}
                      >
                        No
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHighlight">
                  <Form.Label column sm="3">
                    Short Description
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <JoditEditorComponent
                      tabIndex={122}
                      value={product.short_description}
                      onChange={(value) => {
                        this.handleJoEditorOnChange("short_description", value);
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHighlight">
                  <Form.Label column sm="3">
                    Short Description in Bangla
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <JoditEditorComponent
                      tabIndex={2}
                      value={product.bn_short_description}
                      onChange={(value) => {
                        this.handleJoEditorOnChange(
                          "bn_short_description",
                          value
                        );
                      }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Dangerous Goods
                  </Form.Label>

                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name="dangerous_goods"
                      defaultValue={product.dangerous_goods}
                      onChange={this.handleOnInputChange}
                      type={"text"}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Product Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        required
                        name="product_type"
                        defaultValue={product.product_type}
                        onChange={this.handleOnInputChange}
                        type="text"
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Value is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Company in English <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        required
                        defaultValue={product?.company?.name}
                        name="company"
                        onChange={this.handleOnInputChange}
                        type="text"
                        disabled
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Value is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {/* <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Company in Bangla
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        name="bn_company"
                        defaultValue={product.bn_company}
                        onChange={this.handleOnInputChange}
                        type="text"
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Value is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group> */}

                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Brand <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Select
                      value={this.state.selectedBrand}
                      styles={styleReactSelect}
                      placeholder="Select Brand..."
                      onChange={(opt) =>
                        this.handleReactSelectOnChange("brand_id", opt)
                      }
                      options={this.state.brands}
                      name="brand_id"
                      required={true}
                    ></Select>
                    <Form.Control.Feedback
                      type="invalid"
                      className={`brand ${
                        this.state.isFormValidated &&
                        !this.state.product.brand_id
                          ? "d-block"
                          : ""
                      }`}
                    >
                      Brand is required.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Keywords
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        name="keywords"
                        defaultValue={product.keywords}
                        onChange={this.handleOnInputChange}
                        type="text"
                      />
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Tagline
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        name="tagline"
                        defaultValue={product.tagline}
                        onChange={this.handleOnInputChange}
                        type="text"
                      />
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPackageWeight">
                  <Form.Label column sm="3">
                    Order Limit
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <div className="input-group">
                      <Form.Control
                        type="number"
                        min={1}
                        // max={100}
                        pattern={"^+?(0|[1-9]d*)$"}
                        name="max_quantity_per_order"
                        value={product?.max_quantity_per_order}
                        onChange={this.handleOnInputChange}
                      />
                    </div>
                    {/* <Form.Control.Feedback 
                          type="invalid" className={` ${(
                              this.state.isFormValidated &&
                              !this.state.product.max_quantity_per_order) ?
                              "d-block":''}`}
                      >
                          Order Limit is required. minimum order limit is 1 and maximum limit is 100
                      </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </div>
            </div>

            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">Gallery</h4>
              </div>
              <div className="block-body">
                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Hero Image <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseButton
                      multiple={false}
                      // onChange={e => {
                      //     this.setState({hero_image_file: e.target.files[0]})
                      // }}
                      onChange={(e) =>
                        this.handleImages(
                          "hero_image_file",
                          e.target.files,
                          false
                        )
                      }
                      id={"heroImage"}
                      name={"hero_image_file"}
                    />
                    {!this.state.deletedImages["HeroImage"]?.includes(
                      this.state?.heroImage?.id
                    ) && (
                      <div className="existing-images">
                        <div className="existing-img">
                          {/* <ConfirmationModal
                                title="Delete"
                                body="Are you sure you want to delete?"
                                showBtn={`false`}
                                handleAction={() =>
                                  this.deleteImage(
                                    "HeroImage",
                                    this.state?.heroImage?.id
                                  )
                                }
                              /> */}

                          <img
                            src={this.state?.heroImage?.url}
                            alt="Hero Image"
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Product Images
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseButton
                      multiple
                      onChange={(e) =>
                        this.handleImages("images_file", e.target.files, true)
                      }
                      id={"mainImage"}
                      name={"images_file"}
                    />
                    <div className="existing-images">
                      {this.state.productImages &&
                      this.state.productImages.length > 0
                        ? this.state.productImages.map((img) => {
                            if (
                              !this.state.deletedImages[
                                "ProductImage"
                              ]?.includes(img.id)
                            ) {
                              return (
                                <div className="existing-img">
                                  <ConfirmationModal
                                    title="Delete"
                                    body="Are you sure you want to delete?"
                                    showBtn={`false`}
                                    handleAction={() =>
                                      this.deleteProductImage(
                                        "ProductImage",
                                        img.id
                                      )
                                    }
                                  />
                                  <img src={img.url} alt="" />
                                </div>
                              );
                            }
                          })
                        : ""}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formImages">
                  <Form.Label column sm="3">
                    Size Guideline <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <BrowseButton
                      multiple={false}
                      onChange={(e) =>
                        this.handleImages(
                          "size_guideline_image",
                          e.target.files,
                          false
                        )
                      }
                      id={"size_guideline_image"}
                      name={"size_guideline_image"}
                    />
                    {this.state?.size_guideline_image && (
                      <div className="existing-images">
                        <div className="existing-img">
                          <img
                            src={this.state?.size_guideline_image}
                            alt="Size Guideline"
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Form.Group>
              </div>
            </div>

            {/* <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">KEY FEATURES</h4>
              </div>
              <div className="block-body">
                <Button
                  onClick={this.addOneKeyFeature}
                  variant={"success"}
                  disabled={product?.product_features_attributes?.length >= 5}
                >
                  Add One
                </Button>
                {product?.product_features_attributes?.length > 0 && (
                  <table className="table mt-5 table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Title in Bangla</th>
                        <th scope="col">Description</th>
                        <th scope="col">Description in Bangla</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product?.product_features_attributes?.map((item, i) => {
                        return (
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                value={item.title}
                                onChange={(e) =>
                                  this.handleKeyFeature(e, i, "title")
                                }
                                required
                                maxLength={25}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Title in Bangla"
                                value={item.bn_title}
                                onChange={(e) =>
                                  this.handleKeyFeature(e, i, "bn_title")
                                }
                                required
                                maxLength={25}
                              />
                            </td>
                            <td>
                              <textarea
                                className="form-control"
                                placeholder="Description"
                                onChange={(e) =>
                                  this.handleKeyFeature(e, i, "description")
                                }
                                // required
                                maxLength={50}
                              >
                                {item.description}
                              </textarea>
                            </td>
                            <td>
                              <textarea
                                className="form-control"
                                placeholder="Description in Bangla"
                                onChange={(e) =>
                                  this.handleKeyFeature(e, i, "bn_description")
                                }
                                // required
                                maxLength={50}
                              >
                                {item.bn_description}
                              </textarea>
                            </td>
                            <td>
                              <span
                                className="pointer-event"
                                onClick={() => this.removeKeyFeature(i)}
                              >
                                <IoMdClose />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div> */}

            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">SKU Type</h4>
              </div>
              <div className="block-body">
                <Form.Check
                  custom
                  inline
                  required
                  disabled
                  type="radio"
                  checked={product?.sku_type === "simple_product"}
                  name="sku_type"
                  id={`sku-type-1`}
                  value={"simple_product"}
                  label={`Simple Product`}
                />

                <Form.Check
                  custom
                  inline
                  type="radio"
                  required
                  disabled
                  checked={product?.sku_type === "variable_product"}
                  name="sku_type"
                  id={`sku-type-2`}
                  value={"variable_product"}
                  label={`Variable Product`}
                />

                <Form.Check
                  custom
                  inline
                  type="radio"
                  required
                  disabled
                  checked={product?.sku_type === "bundle_product"}
                  name="sku_type"
                  id={`sku-type-3`}
                  value={"bundle_product"}
                  label={`Bundle Product`}
                />

                <Form.Control.Feedback type="invalid">
                  Please please select a type.
                </Form.Control.Feedback>
              </div>
            </div>

            <div className="card-block">
              <div className="block-header">
                <h4 className="block-title">
                  {product.sku_type === "simple_product"
                    ? "SKU Information"
                    : [
                        product.sku_type === "bundle_product"
                          ? "Bundle Info"
                          : "Variants",
                      ]}
                </h4>
              </div>
              {product.sku_type === "variable_product" && (
                <div className="block-body">
                  <Form.Group as={Row} controlId="formInTheBox">
                    <Form.Label column sm="2">
                      Attribute Set
                    </Form.Label>
                    <Col sm="10" md={9} lg={7}>
                      <Select
                        value={this.state.selectedAttributeSet} //|| this.state.attributeSet?.find(i=>i.id === product.attribute_set_id)
                        options={this.state.attributeSet}
                        onChange={(e) => this.handleAttributeSetChange(e)}
                        styles={styleReactSelect}
                        placeholder="Select Attribute set..."
                        required={true}
                        isDisabled
                      ></Select>
                    </Col>
                  </Form.Group>
                  {this.state.selectedAttributeSet?.product_attributes?.length >
                    0 && (
                    <Form.Group as={Row} controlId="formInTheBox">
                      <Form.Label column sm="2">
                        Image Attribute
                      </Form.Label>
                      <Col sm="10" md={9} lg={7}>
                        <Form.Control
                          name="image_attribute_id"
                          value={product.image_attribute_id}
                          onChange={this.handleAttributeChange}
                          as="select"
                          type="text"
                        >
                          <option value="">--Select--</option>
                          {this.state.selectedAttributeSet?.product_attributes.map(
                            (item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  )}

                  {this.state.attributeValues?.length > 0 &&
                    this.state.attributeValues?.map((option, i) => (
                      <Form.Group as={Row} controlId="formInTheBox" key={i}>
                        <Form.Label column sm="2"></Form.Label>
                        <Col sm="10" md={9} lg={7}>
                          <table className="info-table">
                            <tbody>
                              <tr>
                                <td
                                  style={{ width: "50px" }}
                                  className="nowrap"
                                >
                                  {option.value}{" "}
                                </td>
                                <td
                                  style={{ width: "30px" }}
                                  className="text-center"
                                >
                                  :
                                </td>
                                <td>
                                  <BrowseButton
                                    name={"combo_img"}
                                    id={`combo-img-${option.id}`}
                                    size={"sm"}
                                    multiple
                                    //onChange={(e)=> this.handleImages('images_file',e.target.files, true)}
                                    onChange={(e) => {
                                      this.handleAttributeImageChange(
                                        option.id,
                                        e.target.files
                                      );
                                    }}
                                  />
                                  <div className="existing-images">
                                    {this.state.attribute_value_images?.length >
                                    0
                                      ? this.state.attribute_value_images
                                          ?.find(
                                            (item) =>
                                              item.product_attribute_value_id ===
                                              option.id
                                          )
                                          ?.images?.map((img) => {
                                            if (
                                              !this.state.deletedImages[
                                                "AttrValueImage"
                                              ]?.includes(img.id)
                                            ) {
                                              return (
                                                <div className="existing-img">
                                                  <ConfirmationModal
                                                    title="Delete"
                                                    body="Are you sure you want to delete?"
                                                    showBtn={`false`}
                                                    handleAction={() =>
                                                      this.deleteImage(
                                                        "AttrValueImage",
                                                        img.id
                                                      )
                                                    }
                                                  />
                                                  <img src={img.image} alt="" />
                                                </div>
                                              );
                                            }
                                          })
                                      : ""}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Form.Group>
                    ))}
                  {this.state.selectedAttributeSet?.product_attributes?.length >
                    0 && (
                    <>
                      <Button
                        onClick={this.handleShowVariationModal}
                        variant={"secondary"}
                        size={"sm"}
                      >
                        + Add Variation
                      </Button>

                      <Modal
                        size={"sm"}
                        show={this.state.isShowVariationModal}
                        onHide={this.handleCloseVariationModal}
                      >
                        <Modal.Header closeButton>
                          {this.state.updateVariantFor !== null ? (
                            <Modal.Title>Update Combination</Modal.Title>
                          ) : (
                            <Modal.Title>Create Combination</Modal.Title>
                          )}
                        </Modal.Header>
                        <Modal.Body>
                          {this.state.selectedAttributeSet?.product_attributes?.map(
                            (attr, index) => (
                              <Form.Group key={index}>
                                <Form.Label>
                                  {attr.name}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  onChange={(e) =>
                                    this.handleComboOnChange(e, index, attr)
                                  }
                                  as="select"
                                >
                                  {attr?.product_attribute_values?.length >
                                  0 ? (
                                    <>
                                      <option key={index} value="">
                                        --Select--
                                      </option>
                                      {attr.product_attribute_values.map(
                                        (option, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={option.id}
                                            >
                                              {option.value}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <option value="">No Option</option>
                                  )}
                                </Form.Control>
                              </Form.Group>
                            )
                          )}
                        </Modal.Body>

                        <Modal.Footer>
                          <Button
                            variant="default"
                            size={"sm"}
                            onClick={this.handleCloseVariationModal}
                          >
                            Close
                          </Button>
                          {this.state.updateVariantFor !== null ? (
                            <Button
                              variant="primary"
                              size={"sm"}
                              onClick={() => this.handleUpdateVariation()}
                            >
                              Update Variation
                            </Button>
                          ) : (
                            <Button
                              variant="primary"
                              size={"sm"}
                              onClick={this.handleAddVariation}
                            >
                              Add Variation
                            </Button>
                          )}
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}
                </div>
              )}

              <div
                style={
                  this.state.product.sku_type === "bundle_product" &&
                  this.state.product.bundle_status
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                {this.state.product.sku_type === "bundle_product" && (
                  <BundleForm
                    bundleVariants={this.state.product.bundle_variants}
                    handleBundleVariations={this.handleBundleVariations}
                    isEdit={true}
                  />
                )}
              </div>

              <div className="block-body">
                {this.state.product?.sku_type !== "bundle_product" &&
                  this.state.product &&
                  this.state.product?.variants_attributes?.map((variant, i) => {
                    return (
                      <VariantForm
                        businessType={this.state.product.business_type}
                        handleSKUInputOnChange={this.handleSKUInputOnChange}
                        variant={variant}
                        position={i}
                        header={
                          this.state.product?.sku_type === "variable_product"
                        }
                        attributeValues={
                          this.state.copy_variant_attribute_values[i]
                        }
                        handleEditSKUInfo={this.handleEditVariantModal}
                        handleDeleteSku={this.handleDeleteSku}
                        isEdit={variant?.id}
                      />
                    );
                  })}

                {this.state.product?.sku_type === "bundle_product" &&
                  this.state.product?.bundle_variants.length > 0 &&
                  this.state.product &&
                  this.state.product?.variants_attributes?.map((variant, i) => {
                    return (
                      <VariantForm
                        businessType={this.state.product.business_type}
                        handleSKUInputOnChange={this.handleSKUInputOnChange}
                        variant={variant}
                        position={i}
                        header={false}
                        attributeValues={
                          this.state.copy_variant_attribute_values[i]
                        }
                        handleEditSKUInfo={this.handleEditVariantModal}
                        handleDeleteSku={this.handleDeleteSku}
                        isEdit={variant?.id}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="card-block">
              <div className="block-body text-right">
                <Link
                  to={`/product/list`}
                  className={`btn btn-lg btn-default mr-2`}
                >
                  Cancel
                </Link>
                <Button
                  size="lg"
                  type={`submit`}
                  disabled={
                    this.state.isSubmitting || this.state.uniqueSlug === false
                  }
                  variant="primary"
                >
                  {this.state.isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </App>
    );
  }
}

export default withRouter(EditProduct);
