import {Button, Col, Form, Row} from "react-bootstrap";
import {ShippingMethodSchema} from "../../ValidationSchema/ConfigurationSchema"
import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {getConfigByKey, createOrUpdate} from "../../../redux/configuration/actions";
import {isEmpty, isObject, size} from 'lodash'

const FormShippingMethod = () => {
    const dispatch = useDispatch();
    const initialFormValues = {
        express_delivery: {
            title: '',
            charge: 70,
            enabled: 1
        },
        home_delivery: {
            title: "",
            charge: 100,
            enabled: 1
        },
        pick_up_point: {
            title: "",
            charge: 0,
            enabled: 1
        }
    }
    const [formData, setFormData] = useState(initialFormValues);
    useEffect(() => {
        dispatch(getConfigByKey('shipping_methods', (isSuccess, res) => {
            if (isSuccess && !isEmpty(res?.value_json)) {
                const value = JSON.parse(res.value_json);
                if (isObject(value) && size(value) > 0) {
                    setFormData({
                        ...formData,
                        ...value
                    })
                }
            } else {
                toast.error(res.message);
            }
        }))
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={ShippingMethodSchema}
            onSubmit={(values) => {
                dispatch(createOrUpdate({
                    key: 'shipping_methods',
                    user_type: 'warehouse',
                    value_json: values
                }, (isTrue, message) => {
                    if (isTrue) {
                        toast.success(message);
                    } else {
                        toast.error(message);
                    }
                }))
            }}
        >
            {(formik) => {
                const {
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isValid,
                    dirty,
                    touched
                } = formik;
                return (
                    <div className="card-block">
                        <div className="block-header">
                            <h4 className="block-title">Shipping Methods</h4>
                        </div>
                        <div className="block-body">
                            <Form onSubmit={handleSubmit}>
                                <div>
                                    <h6>Express Delivery</h6>
                                    <hr/>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Title <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                type="text"
                                                className={errors?.express_delivery?.title ? 'is-invalid' : ''}
                                                name="express_delivery.title"
                                                value={values.express_delivery.title}
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.express_delivery?.title}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Charge(BDT) <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                type="number"
                                                className={errors?.express_delivery?.charge && touched?.express_delivery?.charge ? 'is-invalid' : ''}
                                                name="express_delivery.charge"
                                                value={values.express_delivery.charge}
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.express_delivery?.charge}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Enabled <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control as="select"
                                                          className={errors?.express_delivery?.enabled && touched?.express_delivery?.enabled ? 'is-invalid' : ''}
                                                          name="express_delivery.enabled"
                                                          onBlur={handleBlur}
                                                          onChange={handleChange}
                                                          value={values.express_delivery?.enabled}
                                            >
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.express_delivery?.enabled}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="mt-5">
                                    <h6>Home Delivery</h6>
                                    <hr/>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Title <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                type="text"
                                                className={errors?.home_delivery?.title ? 'is-invalid' : ''}
                                                name="home_delivery.title"
                                                value={values.home_delivery.title}
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.home_delivery?.title}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Charge(BDT) <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                type="number"
                                                className={errors?.home_delivery?.charge && touched?.home_delivery?.charge ? 'is-invalid' : ''}
                                                name="home_delivery.charge"
                                                value={values.home_delivery.charge}
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.home_delivery?.charge}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Enabled <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control as="select"
                                                          className={errors?.home_delivery?.enabled && touched?.home_delivery?.enabled ? 'is-invalid' : ''}
                                                          name="home_delivery.enabled"
                                                          onBlur={handleBlur}
                                                          onChange={handleChange}
                                                          value={values.home_delivery?.enabled}
                                            >
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.home_delivery?.enabled}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="mt-5">
                                    <h6>Pick Up Point</h6>
                                    <hr/>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Title <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                type="text"
                                                className={errors?.pick_up_point?.title ? 'is-invalid' : ''}
                                                name="pick_up_point.title"
                                                value={values.pick_up_point.title}
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.pick_up_point?.title}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Charge(BDT) <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control
                                                type="number"
                                                className={errors?.pick_up_point?.charge && touched?.pick_up_point?.charge ? 'is-invalid' : ''}
                                                name="pick_up_point.charge"
                                                value={values.pick_up_point.charge}
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.pick_up_point?.charge}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Enabled <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Col sm="9" md={8} lg={7}>
                                            <Form.Control as="select"
                                                          className={errors?.pick_up_point?.enabled && touched?.pick_up_point?.enabled ? 'is-invalid' : ''}
                                                          name="pick_up_point.enabled"
                                                          onBlur={handleBlur}
                                                          onChange={handleChange}
                                                          value={values.pick_up_point?.enabled}
                                            >
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.pick_up_point?.enabled}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <Form.Group as={Row}>
                                    <Col md={10}>
                                        <div className="mt-5 d-flex justify-content-end">
                                            <Button
                                                size="btn-sm"
                                                type={"submit"}
                                                variant="primary"
                                                disabled={!(dirty && isValid)}
                                            >
                                                Save Change
                                            </Button>
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default FormShippingMethod;
