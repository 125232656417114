import React, {Component} from 'react';
import App from "../../App";
import {Button, Form, Modal} from "react-bootstrap";
import {FiCheck, FiPlus, FiUpload, IoMdClose} from "react-icons/all";
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';

class List extends Component {
  state = {};

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">SR List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/sr/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add SR</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">

              <form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="searchInput" className="sr-only">Search Key</label>
                  <input type="search" name="search_string" id="orderNumber"
                         className='form-control form-control-sm' placeholder="Search Key"/>
                </div>
                <Button size="sm" variant="secondary">Search</Button>
              </form>
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>SR Name</th>
                  <th>Name in Bangla</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr key={1}>
                  <td>1</td>
                  <td>
                    <div className="order-item">
                          SR 1
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      এস আর ১
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                        sr@gmail.com
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                        +880144775566
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                        Dhaka, Bangladesh
                    </div>
                  </td>
                  <td>
                    <div className="order-item">
                      Active
                    </div>
                  </td>
                  <td style={{width: '150px'}}>
                    <Link to={``} className="btn btn-block btn-sm btn-outline-secondary">View</Link>
                    <Link to={`${process.env.PUBLIC_URL}/sr/edit/1`} className="btn btn-block btn-sm btn-outline-info">Edit</Link>
                    <Button block size={`sm`} variant={`outline-danger`}>Delete</Button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
