import React, { Component, PureComponent } from "react";
import App from "../App";
import "../assets/scss/dashboard.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import { Tab, Tabs } from "react-bootstrap";
import TopSkuList from "../views/dashboard/topSkuList";
import { getWarehouseType } from "../helper/utils";
import { getData } from "../services/baseServices";
import Loader from "react-loader";

const warehouseType = getWarehouseType();

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-25)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

class Dashboard extends Component {
  state = {
    date: new Date(),
    dashboard_data: {},
    isLoading: true,
    selectedTab: "sku",
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    getData("dashboard/stats/7/days")
      .then((res) => {
        this.setState(
          {
            dashboard_data: res,
            isLoading: false,
          },
          () => {}
        );
      })
      .catch((errMsg) => {
        this.setState({ isLoading: false });
        console.log("Error Message: ", errMsg);
      });
  }

  render() {
    const dashboard_data = this.state?.dashboard_data;
    return (
      <App layout="">
        <div className="main-content">
          <Loader loaded={!this.state.isLoading}>
            <div className="col-12 row">
              <div className="col-6">
                <div className="card-block">
                  <div className="block-header">
                    <h4 className="block-title">Order Stats</h4>
                  </div>

                  <div className="block-body">
                    <div style={{ height: "250px", fontSize: "12px" }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={500}
                          data={dashboard_data?.order_type?.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            interval={0}
                            height={50}
                            dataKey={dashboard_data?.order_type?.x_axis?.x1}
                            tick={<CustomizedAxisTick />}
                          />
                          {!isNaN(dashboard_data?.order_type?.max_value) && (
                            <YAxis
                              type="number"
                              domain={[
                                0,
                                (dataMax) =>
                                  dataMax +
                                  parseInt(
                                    dashboard_data?.order_type?.max_value
                                  ),
                              ]}
                            />
                          )}
                          {/* <YAxis type="number" domain={[0, dashboard_data?.order_type?.max_value + 100]} /> */}
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            layout="vertical"
                            align="right"
                            wrapperStyle={{
                              paddingLeft: "10px",
                            }}
                          />
                          <Bar
                            dataKey={dashboard_data?.order_type?.y_axis?.y1}
                            fill="#8884d8"
                          />
                          <Bar
                            dataKey={dashboard_data?.order_type?.y_axis?.y2}
                            fill="#82ca9d"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="card-block">
                  <div className="block-header">
                    <h4 className="block-title">Delivered Order Stats</h4>
                  </div>

                  <div className="block-body">
                    <div style={{ height: "250px", fontSize: "12px" }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={700}
                          height={300}
                          data={dashboard_data?.shipping_type?.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            interval={0}
                            height={50}
                            dataKey={dashboard_data?.shipping_type?.x_axis?.x1}
                            tick={<CustomizedAxisTick />}
                          />
                          {!isNaN(dashboard_data?.shipping_type?.max_value) && (
                            <YAxis
                              type="number"
                              domain={[
                                0,
                                (dataMax) =>
                                  dataMax +
                                  parseInt(
                                    dashboard_data?.shipping_type?.max_value
                                  ),
                              ]}
                            />
                          )}
                          {/* <YAxis type="number" domain={[0, dashboard_data?.shipping_type?.max_value + 100]} /> */}
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            layout="vertical"
                            align="right"
                            wrapperStyle={{
                              paddingLeft: "10px",
                            }}
                          />
                          <Bar
                            dataKey={dashboard_data?.shipping_type?.y_axis?.y1}
                            fill="#8884d8"
                          />
                          <Bar
                            dataKey={dashboard_data?.shipping_type?.y_axis?.y3}
                            fill="#a4ebf3"
                          />
                          <Bar
                            dataKey={dashboard_data?.shipping_type?.y_axis?.y2}
                            fill="#82ca9d"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <div className="card-block">
                  <div className="block-header">
                    <h4 className="block-title">Value Discount Stats</h4>
                  </div>

                  <div className="block-body">
                    <div style={{ height: "250px", fontSize: "12px" }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          width={700}
                          height={300}
                          data={dashboard_data?.value_discount?.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid
                            stroke="#f5f5f5"
                            strokeDasharray="3 3"
                          />
                          <XAxis
                            interval={0}
                            height={50}
                            dataKey={dashboard_data?.value_discount?.x_axis?.x1}
                            tick={<CustomizedAxisTick />}
                          />
                          {!isNaN(
                            dashboard_data?.value_discount?.max_value
                          ) && (
                            <YAxis
                              type="number"
                              domain={[
                                0,
                                (dataMax) =>
                                  dataMax +
                                  parseInt(
                                    dashboard_data?.value_discount?.max_value
                                  ),
                              ]}
                            />
                          )}
                          {/* <YAxis type="number" domain={[0, dashboard_data?.value_discount?.max_value + 100]} /> */}
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            layout="vertical"
                            align="right"
                            wrapperStyle={{
                              paddingLeft: "10px",
                            }}
                          />
                          <Bar
                            barSize={30}
                            dataKey={dashboard_data?.value_discount?.y_axis?.y1}
                            fill="#82ca9d"
                          />
                          {/* <Line type="monotone" dataKey={dashboard_data?.value_discount?.y_axis?.y1} stroke="#ff7300" /> */}
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="card-block">
                  <div className="block-header">
                    <h4 className="block-title">Cart Value Stats</h4>
                  </div>

                  <div className="block-body">
                    <div style={{ height: "250px", fontSize: "12px" }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          width={700}
                          height={300}
                          data={dashboard_data?.cart_mrp?.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid
                            stroke="#f5f5f5"
                            strokeDasharray="3 3"
                          />
                          <XAxis
                            interval={0}
                            height={50}
                            dataKey={dashboard_data?.cart_mrp?.x_axis?.x1}
                            tick={<CustomizedAxisTick />}
                          />
                          {!isNaN(dashboard_data?.cart_mrp?.max_value) && (
                            <YAxis
                              type="number"
                              domain={[
                                0,
                                (dataMax) =>
                                  dataMax +
                                  parseInt(dashboard_data?.cart_mrp?.max_value),
                              ]}
                            />
                          )}
                          {/* <YAxis type="number" domain={[ dataMax => (dataMax + dataMax*.10), "auto"]} /> */}
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            layout="vertical"
                            align="right"
                            wrapperStyle={{
                              paddingLeft: "10px",
                            }}
                          />
                          <Bar
                            barSize={30}
                            dataKey={dashboard_data?.cart_mrp?.y_axis?.y1}
                            fill="#82ca9d"
                          />
                          {/* <Line type="monotone" dataKey={dashboard_data?.cart_mrp?.y_axis?.y1} stroke="#ff7300" /> */}
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <div className="card-block">
                  <div className="block-header">
                    <h4 className="block-title">Average Basket Value</h4>
                  </div>

                  <div className="block-body">
                    <div style={{ height: "250px", fontSize: "12px" }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          width={700}
                          height={300}
                          data={dashboard_data?.avg_basket?.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid
                            stroke="#f5f5f5"
                            strokeDasharray="3 3"
                          />
                          <XAxis
                            interval={0}
                            height={50}
                            dataKey={dashboard_data?.avg_basket?.x_axis?.x1}
                            tick={<CustomizedAxisTick />}
                          />
                          {!isNaN(dashboard_data?.avg_basket?.max_value) && (
                            <YAxis
                              type="number"
                              domain={[
                                0,
                                (dataMax) =>
                                  dataMax +
                                  parseInt(
                                    dashboard_data?.avg_basket?.max_value
                                  ),
                              ]}
                            />
                          )}
                          {/* <YAxis type="number" domain={[0, 'dataMax+10000']} /> */}
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            layout="vertical"
                            align="right"
                            wrapperStyle={{
                              paddingLeft: "10px",
                            }}
                          />
                          <Bar
                            barSize={30}
                            dataKey={dashboard_data?.avg_basket?.y_axis?.y1}
                            fill="#82ca9d"
                          />
                          {/* <Line type="monotone" dataKey={dashboard_data?.avg_basket?.y_axis?.y1} stroke="#ff7300" /> */}
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card-block">
                <div className="block-header">
                  <h4 className="block-title">Top 10</h4>
                </div>
                <div className="block-body">
                  <Tabs
                    defaultActiveKey={this.state.selectedTab}
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="sku" title="SKU">
                      <br />
                      <TopSkuList skuList={dashboard_data?.top_10_skus?.data} />
                    </Tab>
                    {/* <Tab eventKey="sub_categories" title="Sub Categories">
                      <br/>
                      <TopSubCategoryList
                        categoryList={this.state.topCategoryList}
                      />
                    </Tab> */}
                  </Tabs>
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </App>
    );
  }
}

export default Dashboard;
