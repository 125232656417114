import React, { Component } from "react";
import "../assets/scss/sidebar-nav.scss";
import {
  FiChevronDown,
  FiChevronUp,
  FiFileText,
  GoDashboard,
} from "react-icons/all";
import { Link, withRouter } from "react-router-dom";
import { getBusinessType } from "../helper/utils";

const businessType = getBusinessType();

/**
 * Hiding components for this sprint
 * 1. Inter Godown Transfer
 */
class SidebarNav extends Component {
  state = {
    type: businessType,
    navBar: [
      {
        id: 1,
        text: "Dashboard",
        path: "/",
        icon: <GoDashboard />,
      },
      {
        id: 2,
        text: "Stock Transfer Orders",
        path: "/dhPurchaseOrder/list",
        icon: <FiFileText />,
      },
      // {
      //   id: 3,
      //   text:
      //     businessType === "multicat"
      //       ? "Stock Return"
      //       : "Inter Godown Transfer",
      //   path: "/dhReturnOrder/list",
      //   icon: <FiFileText />,
      // },
      // {
      //   id: 4,
      //   text: "Riders",
      //   path: "/rider/list",
      //   icon: <FiFileText />,
      // },
      {
        id: 5,
        text: "Customer Orders",
        path: "/customerOrder/list",
        icon: <FiFileText />,
      },
      {
        id: 6,
        text: "Return Requests",
        path: "/returnOrder/list",
        icon: <FiFileText />,
      },
      {
        id: 7,
        text: "Delivery Man",
        path: "/route/list",
        icon: <FiFileText />,
      },
      {
        id: 8,
        text: "Reconciliation",
        path: "/reconciliation",
        icon: <FiFileText />,
        hasSubMenu: false,
      },
      {
        id: 9,
        text: "Partners",
        path: "/partner/list",
        icon: <FiFileText />,
      },
      {
        id: 10,
        text:
          businessType === "multicat"
            ? "Sales Representatives"
            : "Sales Representatives",
        path: "/ra/list",
        icon: <FiFileText />,
      },
      {
        id: 11,
        text: "Products",
        path: "/product/list",
        icon: <FiFileText />,
        isCollapsed: true,
        hasSubMenu: true,
        subMenu: [
          {
            id: 1,
            text: "Product List",
            path: "/product/list",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "View Custom Price",
            path: "/product/custom-product-price-list",
            icon: <FiFileText />,
          },
        ],
      },
      // {
      //   id: 11,
      //   text: 'Products',
      //   path: '#',
      //   icon: <FiFileText/>,
      //   hasSubMenu: true,
      //   isCollapsed: true,
      //   subMenu: [
      //     {
      //       id: 1,
      //       text: 'Product List',
      //       path: '/product/list',
      //       icon: <FiFileText/>,
      //     },
      //     {
      //       id: 2,
      //       text: 'Add Product',
      //       path: '/product/add-multicat-product',
      //       icon: <FiFileText/>,
      //     },
      //   ]
      // },
      {
        id: 12,
        text: "Bundle Products",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Pack Bundle",
            path: "/bundle/create-pack",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Pack List",
            path: "/bundle/list",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 13,
        text: "Location",
        path: "/location/list",
        icon: <FiFileText />,
      },
      {
        id: 14,
        text: "Inventory",
        path: "/inventory/list",
        icon: <FiFileText />,
      },
      {
        id: 15,
        text: "Qc Failed Items",
        path: "/qcFailedItems/list",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "STO Failed Items",
            path: "/sto-failed-items/list",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "RCO Failed Items",
            path: "/rco-failed-items/list",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 16,
        text: "Blocked Items",
        path: "/blockItems/list",
        icon: <FiFileText />,
      },
      {
        id: 17,
        text: "Requested Product List",
        path: "/product-request/list",
        icon: <FiFileText />,
      },
      // {
      //   id: 18,
      //   text: 'Payments',
      //   path: '#',
      //   icon: <FiFileText/>,
      //   hasSubMenu: true,
      //   isCollapsed: true,
      //   subMenu: [
      //     {
      //         id: 1,
      //         text: 'FC commission Transactions',
      //         path: '/commission-transactions/list',
      //         icon: <FiFileText/>,
      //     },
      //     {
      //       id: 2,
      //       text: 'Cash Deposit',
      //       path: '/cash-deposit/list',
      //       icon: <FiFileText/>,
      //     },
      //     {
      //       id: 3,
      //       text: 'SR List',
      //       path: '/srMargin/list',
      //       icon: <FiFileText/>,
      //     },
      //     {
      //       id: 4,
      //       text: "Partner Margin",
      //       path: "/partner-margin",
      //       icon: <FiFileText />,
      //     },
      //   ]
      // },
      {
        id: 19,
        text: "Challan",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Challan",
            path: "/challan/list",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Return Challan",
            path: "/return-challan/list",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 20,
        text: "KPI",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Sales",
            path: "/kpi/sales",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Delivery",
            path: "/kpi/delivery",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 69,
        text: "Buffer Stock SR",
        path: "/buffer-stock/sr",
        icon: <FiFileText />,
      },
      {
        id: 21,
        text: "Buffer Stock DM",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Add Buffer Stock",
            path: "/buffer-stock/add",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Edit Buffer Stock",
            path: "/buffer-stock/edit",
            icon: <FiFileText />,
          },
          {
            id: 3,
            text: "Reconcile",
            path: "/buffer-stock/reconcile",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 22,
        text: "DM Route Planning",
        path: "/outlet-sorting/csv-export-import",
        icon: <FiFileText />,
      },
      {
        id: 24,
        text: "MC Tracker Report",
        // path: "/outlet-sorting/csv-export-import",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Volume",
            path: "/report/volume",
            icon: <FiFileText />,
          },
          {
            id: 1,
            text: "Memo",
            path: "/report/memo",
            icon: <FiFileText />,
          },
        ],
      },
      {
        id: 23,
        text: "Export & Import File",
        path: "#",
        icon: <FiFileText />,
        hasSubMenu: true,
        isCollapsed: true,
        subMenu: [
          {
            id: 1,
            text: "Multicat Partner Upload",
            path: "/multicat-partner/import-file",
            icon: <FiFileText />,
          },
          {
            id: 2,
            text: "Multicat SR Creation",
            path: "/multicat/sr-creation",
            icon: <FiFileText />,
          },
          {
            id: 1,
            text: "Multicat DM Creation",
            path: "/multicat/dm-creation",
            icon: <FiFileText />,
          },
        ],
      },
    ],
  };

  componentDidMount() {
    const parent_id = this.props.history?.location?.state?.parent_id;
    console.log(parent_id);
    if (parent_id) {
      const navBar = { ...this.state.navBar };
      const itemObj = this.state.navBar.filter((obj) => {
        return obj.id === parent_id;
      });
      const index = this.state.navBar.indexOf(itemObj[0]);
      navBar[index].isCollapsed = false;
      this.setState(navBar);
    }
  }

  toggleSubNavItems = async (id) => {
    const navBar = { ...this.state.navBar };
    const itemObj = await this.state.navBar.filter((obj) => {
      return obj.id === id;
    });
    const index = this.state.navBar.indexOf(itemObj[0]);
    navBar[index].isCollapsed = !navBar[index].isCollapsed;
    this.setState(navBar);
  };

  render() {
    const sortedNavBar = [...this.state.navBar];

    // Sort the navBar alphabetically by the "text" property
    sortedNavBar.sort((a, b) => {
      if (a.text === "Dashboard") {
        return -1; // Keep "Dashboard" at the top
      } else if (b.text === "Dashboard") {
        return 1;
      } else {
        return a.text.localeCompare(b.text);
      }
    });
    const filteredNavBar = sortedNavBar.filter((item) => {
      if (
        (item.id === 20 || item.id === 21 || item.id === 22) &&
        this.state.type !== "multicat"
      ) {
        return false;
      }
      return true;
    });

    return (
      <div className="sidebar-nav collapsed-sidebar">
        <ul className="navigation">
          {filteredNavBar.map((item, i) => {
            if (item.hasSubMenu) {
              return (
                <li
                  key={i}
                  className={
                    item.isCollapsed
                      ? "has-sub-menu"
                      : "show-sub-menu has-sub-menu"
                  }
                >
                  <span
                    onClick={() => {
                      this.toggleSubNavItems(item.id);
                    }}
                    className="toggleCollapse nav-link"
                  >
                    <span className="nav-icon">{item.icon}</span>
                    <span className="nav-text">
                      <span className="text">{item.text}</span>
                      <span className="catet-icon">
                        {item.isCollapsed ? <FiChevronDown /> : <FiChevronUp />}
                      </span>
                    </span>
                  </span>

                  <ul className="sub-menu">
                    {item.subMenu.map((subItem, i) => {
                      return (
                        <li key={i}>
                          <Link
                            className={`nav-link ${
                              this.props?.history?.location?.pathname ===
                                subItem.path && "active-link"
                            } `}
                            to={{
                              pathname: subItem.path,
                              state: { parent_id: item.id },
                            }}
                          >
                            <span className="nav-text">{subItem.text}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            } else {
              return (
                <li key={i}>
                  <Link
                    className={`nav-link ${
                      this.props?.history?.location?.pathname === item.path &&
                      !this.props?.history?.location?.state &&
                      "active-link"
                    } `}
                    to={item.path}
                  >
                    <span className="nav-icon">{item.icon}</span>
                    <span className="nav-text">{item.text}</span>
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(SidebarNav);
