import * as Yup from "yup";

const ShippingMethodValidation = {
    title: Yup.string()
        .min(3, 'Title minimum length is 3 characters.')
        .max(50, 'Title maximum length is 50 characters.')
        .required('Title is required.'),
    charge: Yup.number()
        .min(0, 'Charge must be at least 0.')
        .required('Charge is required'),
    enabled: Yup.boolean()
        .required('Value is required')
}
export const ShippingMethodSchema = Yup.object().shape({
    express_delivery: Yup.object().shape(ShippingMethodValidation),
    home_delivery: Yup.object().shape(ShippingMethodValidation),
    pick_up_point: Yup.object().shape(ShippingMethodValidation)
});

const PartnerCommissionValidation = {
    organic_holding_fee: Yup.object().shape({
        value: Yup.number()
            .min(0, 'Charge must be at least 0.')
            .required('Charge is required'),
        percent: Yup.boolean()
            .required('Value is required')
    }),
    induced_holding_fee: Yup.object().shape({
        value: Yup.number()
            .min(0, 'Charge must be at least 0.')
            .required('Charge is required'),
        percent: Yup.boolean()
            .required('Value is required')
    })
}
export const CommissionSchema = Yup.object().shape({
    partner: Yup.object().shape(PartnerCommissionValidation)
});
