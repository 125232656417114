import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, getObjectToFormData} from "../helper/utils";

export const addProductType = (data) => {
  const authData = getLocalAuthData();
  delete data.attributes;
  delete data.sku_information;
  delete data.product_categories_attributes;

  const formData = getObjectToFormData({product: data})
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + '/product_types', formData, {
      headers: {
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject('Something went wrong')
      })
  })
}

export const updateProductType = (id, data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(`${API_BASE_URL}/product_types/${id}`, data, {
      headers: {
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data);
        } else {
          reject(res.data.message);
        }
      })
      .catch(err => {
        console.log(err);
        reject('Something went wrong')
      })
  });
}

export const getProductType = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/product_types`, {
      headers: {
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const deleteProductType = (id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.delete(`${API_BASE_URL}/product_types/${id}`, {
      headers: {
      }
    })
      .then(res => {
        if (res.data.status === 1) {
          resolve(res.data.message)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => {
        reject(err.message)
      })
  })
}