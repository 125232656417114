import React, { Component } from "react";
import App from "../../App";
import { Button, Col, Form, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Select } from "antd";
import {
  getList,
  getWarehouseDetails,
  updateDwh,
} from "../../services/baseServices";

class Edit extends Component {
  constructor() {
    super();
    this.state = {
      formData: {},
      warehouseInfo: {},
      companyList: [],
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      selectedCompanies: [],
    };
  }

  componentDidMount() {
    getWarehouseDetails("warehouses", this.props.match.params.id)
      .then((res) => {
        this.setState({ warehouseInfo: res?.data });
        this.setState({ selectedCompanies: res?.data?.companies });
      })
      .catch((errMsg) => {
        console.log("Err Msg: ", errMsg);
      });
    getList("options/companies")
      .then((res) => {
        if (res?.success) {
          this.setState({ companyList: res?.data });
        }
      })
      .catch((errMsg) => {});
  }

  handleCompanyChange = (target) => {
    if (target === null || target.length === 0) {
      this.setState({ selectedCompanies: [] });
    } else {
      this.setState({
        selectedCompanies: Array.isArray(target) ? target : [target],
      });
    }
  };
  handleUpdateDwh = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ isFormValidated: true });
    } else {
      this.setState({ isSubmitting: true }, () => {
        console.log("Selected: ", this.state.selectedCompanies);
        const selectedCompanies = [...this.state.selectedCompanies];
        updateDwh(
          "warehouses",
          this.props.match.params.id,
          "companies",
          selectedCompanies
        )
          .then((res) => {
            console.log("Response: ", res.data);
            toast.success(res?.message);
            this.props.history.push(`/dwh/list`);
          })
          .catch((errMsg) => {
            toast.error(errMsg);
            this.setState({ isSubmitting: false });
          });
      });
    }
  };

  render() {
    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Distribution House</h2>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form
                noValidate
                validated={this.state.isFormValidated}
                onSubmit={this.handleUpdateDwh}
              >
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Warehouse Name
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      defaultValue={this.state.warehouseInfo?.name}
                      onChange={this.handleInputOnChange}
                      type="text"
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter warehouse name name.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Companies
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      allowClear
                      placeholder="Please select"
                      onChange={(e) => {
                        this.handleCompanyChange(e);
                      }}
                      value={this.state.selectedCompanies}
                      options={
                        this.state.companyList &&
                        this.state.companyList.length > 0
                          ? this.state.companyList
                          : []
                      }
                    />
                  </Col>
                </Form.Group>

                <div className="mt-3">
                  <Button
                    size="lg"
                    type={"submit"}
                    disabled={this.state.isSubmitting}
                    variant="primary"
                  >
                    {this.state.isSubmitting ? "Updating..." : "Update"}
                  </Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Edit);
