import React, {Component} from 'react';
import App from "../../App";
import {Link, withRouter} from "react-router-dom";
import {FaLongArrowAltLeft, MdArrowBack} from "react-icons/all";
import {Alert} from "react-bootstrap";

class Details extends Component {
  state = {
    metaInfo:{}
  }

  render() {
    const {entrepreneurDetails} = this.state;
    const metaInfo = this.state.metaInfo

    return (
      <App layout={`boxed`}>
        <div className="main-content">
          <div className="page-header">
            <div className="page-title"/>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/category/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Category List</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Warehouse Details</h4>
            </div>
            <div className="block-body">
              <>
                <table className="info-table">
                  <tbody>
                  <tr>
                    <td style={{width: '250px'}}>Warehouse Name</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td className={`text-bold`}>Distribution Warehouse 1</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Warehouse Name in Bangla</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>ডিস্ট্রিবিউশন ওয়্যারহাউস ১</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Email</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>dist@gmail.com</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Phone</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>+880177445555</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Address</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>Dhaka, Bangladesh</td>
                  </tr>
                  <tr>
                    <td style={{width: '250px'}}>Creation Date</td>
                    <td style={{width: '20px', textAlign: 'center'}}>:</td>
                    <td>25th November, 2020</td>
                  </tr>
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
        <div className="card-block">
          <div className="block-header">
            <h4 className="block-title">Meta Info</h4>
          </div>
          <div className="block-body">
            <table className="info-table">
              <tbody>
                <tr>
                  <td style={{ width: "200px" }}>Meta Title</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.meta_title}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Title in Bangla</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.bn_meta_title}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Description</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.meta_description}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Description in Bangla</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{metaInfo?.bn_meta_description}</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Keywords</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{
                      metaInfo?.meta_keyword?.map((item,index)=>(
                        <span key={index}>{item}, </span>
                      ))
                  }</td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Meta Keywords in Bangla</td>
                  <td style={{ width: "20px", textAlign: "center" }}>
                    :
                  </td>
                  <td>{
                     metaInfo?.bn_meta_keyword?.map((item,index)=>(
                      <span key={index}>{item}, </span>
                    ))
                    }</td>
                </tr>
              </tbody>
            </table>
            </div>
            </div>
      </App>
    );
  }
}

export default withRouter(Details);