import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Button, Modal } from "react-bootstrap";
import FormControl from "../../components/controls/FormControl";
import { putData } from "../../services/baseServices";
import { toast } from "react-toastify";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"

const UnBlockModal = ({ unblocked_details, show, handleClose }) => {
  const [submitting, setSubmitting] = useState(false)
  useEffect(()=> {
    if (show) setSubmitting(false)
  }, [show])  
  const validationSchema = yup.object().shape({
    unblock_qunatity: yup
      .number()
      .required("Unblock quantity is required field")
      .min(1, "Unblock quantity must be greater than or equal to 1")
      .max(
        unblocked_details.remaining_blocked_quantity,
        `Unblock qunatity must be less than or equal to ${unblocked_details.remaining_blocked_quantity}`
      ).test(
        "is-decimal",
        "decimal value is not allowed",
        (value) => !(value + "").match(/^\d*\.{1}\d*$/)
      ),
    location: yup.string().required("Location is required field"),
  });

  const initialValues = {
    unblock_qunatity: "",
    location: '',
  };

  const debouncedOnUnBlock = _.debounce((values)=>{
    handleUnBlockSubmit(values)
  }, DEBOUNCE_TIME);

  const handleWithDebounceUnBlockSubmit = (values) => {
    setSubmitting(true)
    debouncedOnUnBlock(values);
  }

  const handleUnBlockSubmit = (values) => {
    setSubmitting(true)
    const params = {
      quantity: values.unblock_qunatity,
      location_id: +values.location,
    };
    putData(`blocked_items/unblock/${unblocked_details.id}`, params)
      .then((res) => {
        if (res.status_code === 200) {
          toast.success(res.message);
          handleClose(true);
        } else {
          toast.error(res.message);
          setSubmitting(false)
        }
      })
      .catch((err) => {
        toast.error(err);
        setSubmitting(false)
      });
  };
  return (
    <div>
      <Modal show={show} onHide={()=>handleClose(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="ml-3">Unblock Product Details</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleWithDebounceUnBlockSubmit}
        >
          {(formik) => (
            <Form>
              <Modal.Body className="p-5">
                <FormControl
                  control="input"
                  type="number"
                  name="unblock_qunatity"
                  label="Unblock quantity"
                  required="*"
                />
                <FormControl
                  control="select"
                  name="location"
                  label="Location"
                  required="*"
                  options={unblocked_details?.locations}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=> handleClose(false)}>
                  Close
                </Button>
                <Button variant="primary" type="submit" disabled={submitting}>
                  Unblock
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default UnBlockModal;
