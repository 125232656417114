import React, {Component} from 'react';
import App from "../../App";
import {Button, Form, Modal} from "react-bootstrap";
import {FiCheck, FiPlus, FiUpload, IoMdClose} from "react-icons/all";
import {withRouter, Link} from "react-router-dom";
import "../../assets/scss/order-list.scss";
import {ToastsContainer, ToastsStore} from 'react-toasts';

class List extends Component {
  state = {};

  render() {
    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <h2 className="page-title">Role List</h2>
            <div className="ml-auto">
              <Link to={`${process.env.PUBLIC_URL}/role/add`} className="btn btn-sm btn-secondary"><FiPlus/> Add
                Role</Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-body">

              <form className="form-inline mb-3">
                <div className="form-group mr-3">
                  <label htmlFor="searchInput" className="sr-only">Search Key</label>
                  <input type="search" name="search_string" id="orderNumber"
                         className='form-control form-control-sm' placeholder="Search Key"/>
                </div>
                <Button size="sm" variant="secondary">Search</Button>
              </form>
              <table className="order-table table table-striped">
                <thead>
                <tr>
                  <th style={{width: '20px'}}>#</th>
                  <th>Role</th>
                  <th>Bangla</th>
                  <th className={`text-center`}>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr key={1}>
                  <td>1</td>
                  <td>
                    <div className="order-item">
                      Product Manager
                    </div>
                  </td>

                  <td>
                    <div className="order-item">
                      প্রোডাক্ট ম্যানেজার
                    </div>
                  </td>

                  <td style={{width: '150px'}}>
                    {/*<Link to={`/role/details/1`} className="btn btn-block btn-sm btn-outline-secondary">View</Link>*/}
                    <Link to={`${process.env.PUBLIC_URL}/role/edit/1`}
                          className="btn btn-block btn-sm btn-outline-info">Edit</Link>
                    <Button block size={`sm`} variant={`outline-danger`}>Delete</Button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </App>
    );
  }
}

export default withRouter(List);
