import axios from 'axios';
import {API_BASE_URL} from "../helper/env";
import {getDeviceType, getLocalAuthData, getObjectToFormData} from "../helper/utils";

export const qcPurchaseOrderItem = (data) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + '/qcs/quality_control', data, {
      headers: {}
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject('Something went wrong')
      })
  })
}

export const getPurchaseOrderItems = (url, id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + url + id, {
      headers: {
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getPurchaseOrderItem = (order_id, line_item_id, order_type) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/qcs/line_item?order_id=${order_id}&line_item_id=${line_item_id}&order_type=${order_type}`, {
      headers: {
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getQuestionnaires = (category_id) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/questionnaires/list?category_id=${category_id}&questionnaire_type=Inbound`, {
      headers: {
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getProductType = () => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/product_types`, {
      headers: {
      }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const getPurchaseOrderList = params => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + `/wh_purchase_orders`, {
      params,
      headers: {}
    }).then(res => {
      console.log(res)
      if (res.data.error) {
        reject(res.data.error)
      } else {
        resolve(res)
      }
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const updatePOStatus = (id, url) => {
  const authData = getLocalAuthData();
  return new Promise((resolve, reject) => {
    axios.put(API_BASE_URL + `/dh_purchase_orders/${id}/` + url, {
      headers: {}
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}

export const assignLocationToPO = params => {
  return new Promise((resolve, reject) => {
    axios.post(API_BASE_URL + `/warehouse_variants_locations`, {
      warehouse_variants_locations: params,
      headers: {}
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.message)
    })
  })
}
