import { FC } from "react";
import { Controller } from "react-hook-form";
import { Checkbox } from "antd";

 const CheckboxControl = ({
  name,
  control,
  label = "",
  errors,
  isDisabled = false,
  className = "",
}) => {
  let errMsg = errors?.[name]?.message;

  return (
    <div className="my-select-container">
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Checkbox
            {...field}
            disabled={isDisabled}
            className={`rounded my-1 ${className}`}
          >
            {label}
          </Checkbox>
        )}
      />
      <p className="text-red-600 text-xs">{errMsg}</p>
    </div>
  );
};

export default CheckboxControl