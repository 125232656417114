import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getBusinessType } from "../../helper/utils";

const VariantForm = ({
  businessType,
  variant,
  position,
  attributeValues,
  handleDeleteSku,
  handleEditSKUInfo,
  handleSKUInputOnChange,
  header,
  isEdit,
}) => {
  const [variantProduct, setVariantProduct] = useState({});
  const [pos, setPostion] = useState(0);
  const [attrValues, setAtttrValues] = useState([]);
  useEffect(() => {
    setVariantProduct(variant);
    setPostion(position);
    setAtttrValues(attributeValues);
  }, [variant, attributeValues]);


  return (
    <div className="variation-card">
      {header && (
        <div className="variation-header">
          <div className="">
            <h4 className="variation-title">
              {attrValues
                ?.map((item) => {
                  return item.value;
                })
                ?.join("-")}
            </h4>
          </div>

          <div className="variation-actions">
            <span
              className="action mr-3 text-info"
              onClick={() => handleEditSKUInfo(pos)}
              style={{ cursor: "pointer" }}
            >
              Edit
            </span>
            <span
              className="action text-danger"
              onClick={() => handleDeleteSku(pos, variantProduct.id)}
              style={{ cursor: "pointer" }}
            >
              Delete
            </span>
          </div>
        </div>
      )}
      <div className="variation-body">
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            SKU <span className="text-danger">*</span>
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              name="sku"
              size={"sm"}
              required
              value={variantProduct.sku}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"text"}
              disabled={isEdit}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Code by Supplier
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              name="code_by_supplier"
              size={"sm"}
              value={variantProduct.code_by_supplier}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"text"}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Weight
            <span className={`text-danger`}>*</span>
          </Form.Label>

          <Col sm="9" md={9} lg={4}>
            <Form.Control
              name="weight"
              size={"sm"}
              step="0.01"
              value={variantProduct.weight}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
              required
            />
          </Col>

          <Col sm="3" md={3} lg={2}>
            <Form.Control
              name="weight_unit"
              size={"sm"}
              value={variantProduct.weight_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
              required
            >
              <option value="">--Select Unit--</option>
              <option value="Kg">Kg</option>
              <option value="g">g</option>
              <option value="mg">mg</option>
              <option value="L">L</option>
              <option value="mL">mL</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Height
          </Form.Label>

          <Col sm="9" md={9} lg={4}>
            <Form.Control
              name="height"
              size={"sm"}
              step="0.01"
              value={variantProduct.height}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
            />
          </Col>
          <Col sm="3" md={3} lg={2}>
            <Form.Control
              name="height_unit"
              size={"sm"}
              value={variantProduct.height_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
            >
              <option value="">--Select Unit--</option>
              <option value="mm">mm</option>
              <option value="cm">cm</option>
              <option value="m">m</option>
              <option value="inches">inches</option>
              <option value="feet">feet</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Width
          </Form.Label>

          <Col sm="9" md={9} lg={4}>
            <Form.Control
              name="width"
              size={"sm"}
              step="0.01"
              value={variantProduct.width}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
            />
          </Col>
          <Col sm="3" md={3} lg={2}>
            <Form.Control
              name="width_unit"
              size={"sm"}
              value={variantProduct.width_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
            >
              <option value="">--Select Unit--</option>
              <option value="mm">mm</option>
              <option value="cm">cm</option>
              <option value="m">m</option>
              <option value="inches">inches</option>
              <option value="feet">feet</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Depth
          </Form.Label>
          <Col sm="9" md={9} lg={4}>
            <Form.Control
              name="depth"
              size={"sm"}
              step="0.01"
              value={variantProduct.depth}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
            />
          </Col>
          <Col sm="3" md={3} lg={2}>
            <Form.Control
              name="depth_unit"
              size={"sm"}
              value={variantProduct.depth_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
            >
              <option value="">--Select Unit--</option>
              <option value="mm">mm</option>
              <option value="cm">cm</option>
              <option value="m">m</option>
              <option value="inches">inches</option>
              <option value="feet">feet</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPackageDimensions">
          <Form.Label column sm="3">
            Case Dimensions
          </Form.Label>
          <Col sm="3" md={2} lg={2}>
            <Form.Control
              name="sku_case_height"
              step="0.01"
              value={variantProduct.sku_case_height}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              placeholder="Case height"
              type="number"
            />
            <Form.Control
              name="sku_case_height_unit"
              size={"sm"}
              value={variantProduct.sku_case_height_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
            >
              <option value="">--Select Unit--</option>
              <option value="mm">mm</option>
              <option value="cm">cm</option>
              <option value="m">m</option>
              <option value="inches">inches</option>
              <option value="feet">feet</option>
            </Form.Control>
          </Col>
          <Col sm="3" md={2} lg={2}>
            <Form.Control
              name="sku_case_length"
              step="0.01"
              value={variantProduct.sku_case_length}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              placeholder="Case length"
              type="number"
            />
            <Form.Control
              name="sku_case_length_unit"
              size={"sm"}
              value={variantProduct.sku_case_length_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
            >
              <option value="">--Select Unit--</option>
              <option value="mm">mm</option>
              <option value="cm">cm</option>
              <option value="m">m</option>
              <option value="inches">inches</option>
              <option value="feet">feet</option>
            </Form.Control>
          </Col>
          <Col sm="3" md={2} lg={2}>
            <Form.Control
              name="sku_case_width"
              step="0.01"
              value={variantProduct.sku_case_width}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              placeholder="Case width"
              type="number"
            />
            <Form.Control
              name="sku_case_width_unit"
              size={"sm"}
              value={variantProduct.sku_case_width_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
            >
              <option value="">--Select Unit--</option>
              <option value="mm">mm</option>
              <option value="cm">cm</option>
              <option value="m">m</option>
              <option value="inches">inches</option>
              <option value="feet">feet</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Case Weight
          </Form.Label>

          <Col sm="9" md={9} lg={4}>
            <Form.Control
              name="case_weight"
              size={"sm"}
              step="0.01"
              value={variantProduct.case_weight}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
            />
          </Col>
          <Col sm="3" md={3} lg={2}>
            <Form.Control
              name="case_weight_unit"
              size={"sm"}
              value={variantProduct.case_weight_unit}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              as="select"
            >
              <option value="">--Select Unit--</option>
              <option value="Kg">Kg</option>
              <option value="g">g</option>
              <option value="mg">mg</option>
              <option value="L">L</option>
              <option value="mL">mL</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            MOQ
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              name="moq"
              size={"sm"}
              step="0.01"
              value={variantProduct.moq}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
            />
          </Col>
        </Form.Group>
        {/* {businessType === "multicat" ? ( */}
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Distribution Price (BDT)<span className={`text-danger`}>*</span>
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              name="price_distribution"
              required
              min="0"
              step="0.01"
              pattern="[0-9]+(\.[0-9]+)?"
              value={variantProduct.price_distribution}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              size={"sm"}
              type={"number"}
            />
          </Col>
        </Form.Group>
        {/* ) : null} */}
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Consumer Price (BDT)<span className={`text-danger`}>*</span>
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              name="price_consumer"
              required
              min="0"
              // disabled
              step="0.01"
              pattern="\d+\.\d{2}"
              value={variantProduct.price_consumer}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              size={"sm"}
              type={"number"}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Consumer Discount
          </Form.Label>

          <Col sm="9" md={9} lg={4}>
            <Form.Control
              name="consumer_discount"
              step="0.01"
              min="0"
              // disabled
              value={variantProduct.consumer_discount}
              max={
                variantProduct.discount_type === "percentage"
                  ? 100
                  : variantProduct.price_consumer
              }
              size={"sm"}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
            />
          </Col>
          <Col sm="3" md={3} lg={2}>
            <Form.Control
              name="discount_type"
              size={"sm"}
              value={variantProduct.discount_type}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              // disabled
              as="select"
            >
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Effective MRP
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              value={variantProduct?.effective_mrp}
              name="effective_mrp"
              size={"sm"}
              readOnly
              disabled
              type={"text"}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Consumer Price (BDT) B2B
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              name="b2b_price"
              required
              step="0.01"
              min="0"
              pattern="\d+\.\d{2}"
              value={variantProduct.b2b_price}
              // disabled
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              size={"sm"}
              type={"number"}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Consumer Discount (B2B)
          </Form.Label>

          <Col sm="9" md={9} lg={4}>
            <Form.Control
              name="b2b_discount"
              step="0.01"
              min="0"
              value={variantProduct.b2b_discount}
              max={
                variantProduct.b2b_discount === "percentage"
                  ? 100
                  : variantProduct.b2b_price
              }
              size={"sm"}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              type={"number"}
              // disabled
            />
          </Col>
          <Col sm="3" md={3} lg={2}>
            <Form.Control
              name="b2b_discount_type"
              size={"sm"}
              value={variantProduct.b2b_discount_type}
              onChange={(e) => handleSKUInputOnChange(e, pos)}
              // disabled
              as="select"
            >
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Effective MRP B2B
          </Form.Label>

          <Col sm="9" md={9} lg={6}>
            <Form.Control
              value={variantProduct?.b2b_effective_mrp}
              name="b2b_effective_mrp"
              size={"sm"}
              readOnly
              // disabled
              step="0.01"
              min="0"
              pattern="\d+\.\d{2}"
              type={"number"}
            />
          </Col>
        </Form.Group>
      </div>
    </div>
  );
};

export default VariantForm;
