import FormShippingMethod from './partial/FormShippingMethod'
import {Link} from "react-router-dom";
import {FaLongArrowAltLeft} from "react-icons/all";

const ShippingMethods = () => {
    return (
        <>
            <div className="page-header">
                <h2 className="page-title">Configurations</h2>
                <div className="ml-auto">
                    <Link to="/"
                          className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Dashboard</Link>
                </div>
            </div>
            <FormShippingMethod/>
        </>
    );
}
export default ShippingMethods;