import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { add, getList } from "../../services/baseServices";
import "../../assets/scss/new-po.scss";
import SearchComponent from "../../components/SearchComponent";
import Variants from "../../components/Variants";
import { FaLongArrowAltLeft } from "react-icons/all";
import { refreshPage } from "../../helper/utils";
import _ from "lodash";
import { DEBOUNCE_TIME } from "../../helper/env";

class Add extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    searchText: "",
    searchResult: { item_count: 0, variants: [] },
    loadingState: "initial",
    selectedProducts: [],
    isSubmitting: false,
    master_po_id: "",
    // payTermsInfo: {}
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // calcuTotalAmountForPaymentTerms() {
  //     let totalAmount = 0
  //     this.state.selectedProducts.forEach((product) => {
  //       product.suppliers_variants.forEach((variant) => totalAmount = variant.totalPrice !== undefined ? totalAmount + variant.totalPrice : totalAmount)
  //     })
  //     this.setState({total_amount: totalAmount})
  //   }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ loadingState: "initial" });
    }
  }

  sendQuery = () => {
    const targetedURL = `skus_search?search_string=${encodeURIComponent(
      this.state.searchText
    )}`;
    this.setState({ loadingState: "loading" });
    getList(targetedURL)
      .then((res) => {
        this.setState({ loadingState: "loaded", searchResult: res });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: "initial",
          searchResult: { item_count: 0, variants: [] },
        });
      });
  };

  handleOnChange = (e) => {
    this.setState({ searchText: e.target.value });
    if (e.target.value.length >= 3) {
      this.sendQuery();
    } else {
      this.setState({
        loadingState: "initial",
        searchResult: { item_count: 0, variants: [] },
      });
    }
  };

  handleMasterPOIID = (e) => {
    this.setState({ master_po_id: e.target.value });
  };

  handleOnSelect = (variant) => {
    let selectedProducts = [...this.state.selectedProducts];
    const found = selectedProducts.find((element) => element.id === variant.id);

    if (found === undefined) {
      if (variant.suppliers_variants.length === 0) {
        toast.info("Couldn't be added because suppliers' variants empty.");
      } else {
        selectedProducts.unshift(variant);
        this.setState(
          {
            loadingState: "initial",
            selectedProducts,
          },
          () => {
            // if(this.state.selectedProducts.length > 0) this.calcuTotalAmountForPaymentTerms()
          }
        );
      }
    } else {
      toast.info("Already selected.");
    }
  };

  handleVariantQuantityChange = (e, supplierVariant, variant_id) => {
    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex((x) => x.id === variant_id);

    if (productIndex !== -1) {
      const theProduct = selectedProducts[productIndex];
      let svOfTheProduct = theProduct.suppliers_variants;
      const svIndex = svOfTheProduct.findIndex(
        (x) =>
          x.variant_id === variant_id &&
          x.supplier_id === supplierVariant.supplier_id
      );

      if (svIndex !== -1) {
        svOfTheProduct[svIndex].quantity = parseInt(e.target.value);
        let parseqty = parseInt(e.target.value) || 0;
        svOfTheProduct[svIndex].totalPrice =
          parseqty * supplierVariant.supplier_price;
        svOfTheProduct[svIndex].quantity = parseInt(
          svOfTheProduct[svIndex].quantity
        );
        selectedProducts[productIndex].suppliers_variants = svOfTheProduct;

        this.setState({ selectedProducts }, () => {
          // if(this.state.selectedProducts.length > 0) this.calcuTotalAmountForPaymentTerms()
        });
      }
    }
  };

  handleVariantRemove = (e, id, variant_id) => {
    e.preventDefault();

    let { selectedProducts } = this.state;
    const productIndex = selectedProducts.findIndex((x) => x.id === variant_id);

    if (productIndex !== -1) {
      const theProduct = selectedProducts[productIndex];
      let svOfTheProduct = theProduct.suppliers_variants;
      const svIndex = svOfTheProduct.findIndex(
        (x) => x.variant_id === variant_id && x.id === id
      );

      if (svIndex !== -1) {
        svOfTheProduct.splice(svIndex, 1);
        selectedProducts[productIndex].suppliers_variants = svOfTheProduct;

        if (selectedProducts[productIndex].suppliers_variants.length === 0)
          selectedProducts.splice(productIndex, 1);

        this.setState({ selectedProducts }, () => {
          toast.success("Removed successfully.");
          // if(this.state.selectedProducts.length > 0) this.calcuTotalAmountForPaymentTerms()
        });
      }
    }
  };

  debouncedOnPOSubmit = _.debounce(() => {
    this.setState({ isSubmitting: true }, () => {
      this.proceedPurchaseOrder();
    });
  }, DEBOUNCE_TIME);

  handleWithDebouncePO = async (e) => {
    e.preventDefault();
    this.debouncedOnPOSubmit();
  };

  proceedPurchaseOrder = () => {
    let variantsToSend = [];
    this.state.selectedProducts.forEach((product) => {
      product.suppliers_variants.forEach((sv) => {
        if (sv.quantity && sv.quantity > 0) {
          variantsToSend.push(sv);
        }
      });
    });
    if (variantsToSend.length > 0) {
      const postData = {
        variants: variantsToSend,
        master_po_id: this.state.master_po_id,
      };

      add("purchase_orders", "wh_order_params", postData)
        .then((res) => {
          if (res?.status_code === 201) {
            toast.success("Purchase order has been created successfully.");
            refreshPage(`/purchaseOrder/list`, () => {
              this.setState({ isSubmitting: false });
            });
          } else if (res?.message) {
            toast.error(res?.message);
            this.setState({ isSubmitting: false });
          } else {
            toast.error("Please fill all required fields/quantities");
            this.setState({ isSubmitting: false });
          }
        })
        .catch((errMsg) => {
          toast.error(errMsg);
          this.setState({ isSubmitting: false });
        });
    } else {
      toast.error("Please create PO for atleast one item");
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    return (
      <App>
        <div className="page-header">
          <h2 className="page-title">Create Purchase Order</h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/purchaseOrder/list`}
              className="btn btn-sm btn-link"
            >
              <FaLongArrowAltLeft /> Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <SearchComponent
              wrapperRef={this.wrapperRef}
              loadingState={this.state.loadingState}
              handleOnChange={this.handleOnChange}
              handleOnSelect={this.handleOnSelect}
              searchResult={this.state.searchResult}
            />
          </div>
        </div>
        <></>
        <div>
          <Variants
            selectedProducts={this.state.selectedProducts}
            isSubmitting={this.state.isSubmitting}
            type="purchaseOrder"
            handleVariantQuantityChange={this.handleVariantQuantityChange}
            proceedPurchaseOrder={this.proceedPurchaseOrder}
            handleVariantRemove={this.handleVariantRemove}
            handlePurchaseOrder={this.handleWithDebouncePO}
            handleMasterPOIID={this.handleMasterPOIID}
            //   selectedProductsTotalAmount={this.state.total_amount}
          />
        </div>
      </App>
    );
  }
}

export default withRouter(Add);
