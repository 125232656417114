import React, { Component } from 'react';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getFormattedDate } from "../../../helper/utils";
import { Button } from "react-bootstrap";

class DateRangeFilter extends Component {
    render() {
        const {start_date_time, end_date_time, handleOnApply, filterList} = this.props

        return (
            <div className="pb-4">
                <div className="d-inline-block">
                    <DateRangePicker
                        onApply={(e, picker) => handleOnApply(e, picker)}
                        initialSettings={{
                            startDate: start_date_time,
                            endDate: end_date_time,
                            maxSpan: {"months": 3},
                            showDropdowns: true,
                            locale: {format: "DD/MM/YYYY"},
                            autoApply: true,
                        }}
                    >
                        <input type="text"
                               value={start_date_time && end_date_time ? `${getFormattedDate(start_date_time,"DD/MM/YYYY")} - ${getFormattedDate(end_date_time,"DD/MM/YYYY")}` : ''}
                               style={{textAlign: 'left', minWidth: '150px'}}
                               placeholder={"Select date range"}
                               className="form-control form-control-sm"/>
                    </DateRangePicker>
                </div>
                <div className="d-inline-block ml-2">
                    <Button size="sm"
                            type={`submit`}
                            onClick={filterList}
                            variant="primary">
                        Filter
                    </Button>
                </div>
            </div>
        );
    }
}

export default DateRangeFilter;
