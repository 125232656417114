import React, { Component } from "react";
import ModalForm from "../common/ModalFormClassComponent";
import { Form } from "react-bootstrap";
import { addV2 } from "../../services";
import { toast } from "react-toastify";
import { refreshPage } from "../../helper/utils";
import { DatePicker } from "antd";


class RescheduleDeliveryDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_id: props.order_id,
      date: "",
      reason: null,
      isSubmitting: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  dateOnSelect = (date) => {
    this.setState({ date: date })
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("date", this.state.date);
    formData.append("reason", this.state.reason);
    formData.append("order_id", this.state.order_id);

    addV2("customer_orders/update_delivery_date", formData)
      .then((res) => {
        if (res?.status_code == 200) {
          this.props.toggleModalState();
          toast.success(`${res.message}`);
          this.props.loadData();
        } else {
          toast.error(res?.message || res?.error);
        }
      })
      .catch((errMsg) => {
        toast.error(errMsg);
      });
  };

  render() {
    return (
      <ModalForm
        isModalOpen={this.props.isModalOpen}
        title="Reschedule Delivery Date"
        handleClose={this.props.toggleModalState}
        handleSubmit={this.handleSubmit}
      >
        <>
          <Form.Group controlId="rescheduledDate">
            <Form.Label>
              Reschedule Date
            </Form.Label>
            <DatePicker
              style={{ width: "100%" }}
              name={"date"}
              selected={new Date()}
              dateFormat="dd-MM-yyyy"
              onChange={this.dateOnSelect}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="reason">
              Reschedule reason <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="reason"
              name="reason"
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
        </>
      </ModalForm>
    );
  }
}

export default RescheduleDeliveryDate;
