
import React, { useState } from 'react';
import App from "../../../App";
import { Link, useHistory, withRouter } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/all";
import {postData} from '../../../services/baseServices'
import { toast } from 'react-toastify';
import DistrictForm from './Form';

const DistrictCreate = () => {
    const [district, setDistrict] = useState({
        name: '',
        bn_name: '',
    })
    const history = useHistory()

    const onFormSubmit = (data) => {
        const params = {
            district: {
                name: data.name,
                bn_name: data.bn_name
            }
        }
          postData("districts",params)
          .then(res => {
            if(res.success) {
              history.push(`/config/districts/list`);
              toast.success(res.message);
            }
            else {
              toast.error(res.message);
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
          }); 
    }
    return (
        <App layout="boxed">
            <div className="page-header">
                <h2 className="page-title">Create District</h2>
                <div className="ml-auto">
                    <Link to={`${process.env.PUBLIC_URL}/config/districts/list`}
                    className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to List</Link>
                </div>
            </div>

            <div className="card-block">
                <div className="block-body">
                    <>
                    <DistrictForm
                        district = {district}
                        onFormSubmit = {onFormSubmit}
                    />
                    </>
                </div>
            </div>
        </App>
    );
}
export default withRouter(DistrictCreate);