import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";

class Add extends Component {
  state = {
  };

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Add Checklist</h2>
        </div>

        <div className="card-block">
          <div className="block-body">
              <>
                <Form>
                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3"> Question <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"question"}
                        required
                        type="text"/>
                      <Form.Control.Feedback type="invalid">Please enter question.</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3">
                      Category <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"category"}
                        as="select">
                        <option value="">--Select Category--</option>
                        <option value="1">Mobile</option>
                        <option value="2">Laptop</option>
                        <option value="2">Headphone</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="displayOrShopName">
                    <Form.Label column sm="3">
                      Checklist Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="9" md={8} lg={7}>
                      <Form.Control
                        name={"category"}
                        as="select">
                        <option value="">--Select Type--</option>
                        <option value="1">Inbound</option>
                        <option value="2">Return</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <div className="mt-3">
                    <Link to={`/checklist/list`}>
                      <Button size="lg" type={"submit"} disabled={this.state.isAdding} variant="primary">{this.state.isAdding ? 'Adding...' : 'Add'}</Button>
                    </Link>
                  </div>
                </Form>
              </>
          </div>
        </div>
      </App>
    );
  }
}
export default withRouter(Add);