import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { humanize } from '../../helper/utils';
import "../../assets/scss/product.scss";
const LogModal = (props) => {
    const data = props?.data

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.logType}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <table className="order-table table table-striped">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>STAFF NAME</th>
                                <th>STAFF for</th>
                                <th> auditable Type</th>
                                <th>auditable id</th>
                                <th>Action Type</th>
                                <th>Changes</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data.length > 0 ? data?.map((item, i) => {
                                return <tr>
                                    <td>
                                        <div className="order-item">
                                            <h4 className="order-id mb-0">
                                                {
                                                    new Date(item?.created_at).toLocaleString()
                                                }
                                            </h4>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="order-item">
                                            <h4 className="order-id mb-0">
                                                {humanize(item?.created_by?.first_name)}   {humanize(item?.created_by?.last_name)}

                                            </h4>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="order-item">
                                            <h4 className="order-id mb-0">

                                                {humanize(item?.created_by?.unit)}
                                            </h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="order-item">
                                            <h4 className="order-id mb-0">

                                                {humanize(item?.auditable_type)}
                                            </h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="order-item">
                                            <h4 className="order-id mb-0">

                                                {item?.auditable_id}
                                            </h4>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="order-item">
                                            <h4 className="order-id mb-0">
                                                {humanize(item?.action)}
                                            </h4>
                                        </div>
                                    </td>

                                    {
                                        item?.action === "create" || item?.action === "destroy" ?
                                            <td>
                                                {/* <div className="order-item">
                                                    <h4 className="order-id mb-0">
                                                        *(All)
                                                    </h4>
                                                </div> */}
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            {
                                                                Object.keys(item?.audited_changes).map((item2) => <th>{item2}</th>)
                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Object.keys(item?.audited_changes).map((item2) => <td>{item?.audited_changes[item2]?.toString()}</td>)
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                            :
                                            <td>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            {
                                                                Object.keys(item?.audited_changes).map((item2) => <th>{item2}</th>)
                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {
                                                                Object.keys(item?.audited_changes).map((item2) => {
                                                                    return <td className='td-border'>
                                                                        <table>
                                                                            <thead>
                                                                                <th>Previous</th>
                                                                                <th>Current</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{item?.audited_changes[item2][0]?.toString()}</td>
                                                                                    <td>{item?.audited_changes[item2][1]?.toString()}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                })
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                    }
                                </tr>
                            })
                                :
                                <tr>
                                    <td colSpan={7} className="text-center">
                                        <h5>Log Details Not Found</h5>
                                    </td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default LogModal


