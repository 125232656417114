import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";

class QRComponent extends Component {
    returnQRCode = (component) => {
        let tbody = [];
        let tr = [];
        for (let i = 1; i <= 100; i++) {
          tr.push(
            <td style={{ textAlign: "center", padding: "0px" }}>
              {component}
            </td>
          );
          if (i % 10 === 0 || i === 90) {
            tbody.push(<tr>{tr}</tr>);
            tr = [];
          }
        }
        return tbody;
      };
    render() {
      return (
        <>
        <ReactToPrint
          trigger={() => {
            return (<a href="#">
              <Button variant={"default"}
                      size={"sm"}> Print QR
              </Button>
            </a>)
          }}
          content={(e) => this.referenceContent}
        />
        <div className="only-print" style={{paddingTop: '30px'}} ref={el => (this.referenceContent = el)}>
          <p className="text-center pb-3">{this.props.ProductTitle}</p>
          <table className=" p-4">
            <tbody>
              {this.returnQRCode(
                <div style={{border: "1px dashed #999", padding: "15px 10px 0 10px"}}>
                  <QRCode
                  size="60"
                    value={this.props.Sku}
                  />
                  <p className="pt-2 pb-2" style={{fontSize : "10px"}}>{this.props.Sku}</p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </>
      );
    }
  }
  export default withRouter(QRComponent);