import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import { getDetails } from "../../services/baseServices";
import { getFormattedDate, humanize } from "../../helper/utils";

class Details extends Component {
  state = {
    promotion: {},
    promotion_variants: {},
  };

  componentDidMount() {
    getDetails("coupons", this.props.match.params.id)
      .then((res) => {
        this.setState({
          promotion: res.data,
        });
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
  }

  render() {
    const { promotion } = this.state;

    return (
      <App layout="">
        <div className="main-content">
          <div className="page-header">
            <div className="page-title" />
            <div className="ml-auto">
              <Link
                to={`/promo-coupon/list`}
                className={`btn btn-sm btn-link`}>
                <MdArrowBack /> Back to List
              </Link>
            </div>
          </div>

          <div className="card-block">
            <div className="block-header">
              <h4 className="block-title">Promo Coupon Details</h4>

            </div>
            <div className="block-body">
              <>
                <table className="info-table">
                  <tbody>
                    <tr>
                      <td style={{ width: "250px" }}>Coupon Type</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{humanize(promotion.coupon_type)}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Coupon</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{promotion.coupon_code}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Created By</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {promotion.created_by?.name}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Applicable From</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {getFormattedDate(promotion.start_at, 'DD/MM/YYYY HH:mm')}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Applicable To</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {getFormattedDate(promotion.end_at, 'DD/MM/YYYY HH:mm')}
                      </td>
                    </tr>

                    {
                      promotion.coupon_type !== 'first_registration' &&
                      <>
                        <tr>
                          <td style={{ width: "250px" }}>Max Use Limit(Total Count)</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>{promotion.max_user_limit}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Max Use Limit (Per User)</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>{promotion.used_count}</td>
                        </tr>

                        <tr>
                          <td style={{ width: "250px" }}>Category Inclusion Type</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>{promotion?.coupon_categories?.category_inclusion_type}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Categories</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>  {promotion?.coupon_categories?.categories?.map(i => i?.title).join(', ')} </td>
                        </tr>

                        <tr>
                          <td style={{ width: "250px" }}>Public Visibility</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>
                            {promotion.is_visible ? "Visible" : "Invisible"}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>SKUs</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>
                            {promotion.skus}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>SKUs Inclusion Type</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>
                            {promotion?.sku_inclusion_type}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "250px" }}>Phone Numbers</td>
                          <td style={{ width: "20px", textAlign: "center" }}>:</td>
                          <td className={`text-bold`}>
                            {promotion.phone_numbers}
                          </td>
                        </tr>
                      </>
                    }
                    <tr>
                      <td style={{ width: "250px" }}>Discount Type</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{humanize(promotion.discount_type)}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "250px" }}>Discount Amount</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>{promotion.discount_amount}</td>
                    </tr>
                    {
                      promotion.discount_type === 'percentage' &&
                      <tr>
                        <td style={{ width: "250px" }}>Max Discount Amount</td>
                        <td style={{ width: "20px", textAlign: "center" }}>:</td>
                        <td className={`text-bold`}>{promotion.max_limit}</td>
                      </tr>
                    }
                    <tr>
                      <td style={{ width: "250px" }}>Status</td>
                      <td style={{ width: "20px", textAlign: "center" }}>:</td>
                      <td className={`text-bold`}>
                        {promotion.is_active ? "Active" : "Inactive"}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Details);
