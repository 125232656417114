import React, { Component } from 'react';
import App from "../../App";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { assignProduct } from "../../services/productServices";
import { getAssignedProducts } from "../../services/supplierServices";
import { toast } from "react-toastify";
import { deleteData, getList } from '../../services/baseServices'
import ConfirmationModal from "../common/Modal";
import SearchComponent from "../../components/SearchComponent";

class ProductAssign extends Component {

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    state = {
        supplier: [],
        value: [],
        selectedProducts: [],
        assignedProductLists: [],
        isLoading: true,
        isSubmitting: false,
        searchText: "",
        searchResult: { item_count: 0, variants: [] },
        loadingState: "initial",
    }

    getAssignedItems = () => {
        getAssignedProducts(this.props.match.params.id)
            .then(res => {
                this.setState({
                    assignedProductLists: res,
                    isLoading: false,
                });
            })
            .catch(errMsg => {
                console.log('Error Message: ', errMsg)
            })
    }

    componentDidMount() {
        this.getAssignedItems()
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ loadingState: "initial" })
        }
    }

    sendQuery = () => {
        const targetedURL = `products/skus/search?search_string=${this.state.searchText}`
        this.setState({ loadingState: "loading" })
        getList(targetedURL)
            .then((res) => this.setState({ loadingState: "loaded", searchResult: res }))
            .catch(err => {
                console.log(err)
                this.setState({ loadingState: "initial", searchResult: { item_count: 0, variants: [] } })
            }
            )
    }

    handleOnChange = (e) => {
        this.setState({ searchText: e.target.value })
        if (e.target.value.length >= 3) {
            this.sendQuery()
        } else {
            this.setState({ loadingState: "initial", searchResult: { item_count: 0, variants: [] } })
        }
    }

    handleOnSelect = (variant) => {
        let selectedProducts = [...this.state.selectedProducts]
        const found = selectedProducts.find(element => element.id === variant.id)
        const foundInAlreadyAssigned =
            this.state.assignedProductLists.find(product => product.variants.some(item => item.id === variant.id));
        if (found === undefined && foundInAlreadyAssigned === undefined) {
            variant.supplierPrice = ''
            selectedProducts.unshift(variant)
            this.setState({
                loadingState: "initial",
                selectedProducts: selectedProducts
            })
        } else {
            toast.info("Already selected or assigned")
        }
    }

    handleSupplierRemove = (e, id) => {
        e.preventDefault()

        let { selectedProducts } = this.state
        const productIndex = selectedProducts.findIndex(x => x.id === id);

        if (productIndex !== -1) {
            selectedProducts.splice(productIndex, 1);
            this.setState({ selectedProducts }, () => toast.success('Removed successfully.'))
        }
    }

    handlePriceChange = (e, variant_id) => {
        let { selectedProducts } = this.state
        const productIndex = selectedProducts.findIndex(x => x.id === variant_id);

        if (productIndex !== -1) {
            selectedProducts[productIndex].supplierPrice = e.target.value
            this.setState({ selectedProducts })
        }
    }

    handleAssignProduct = async (e) => {
        e.preventDefault();
        this.setState({ isSubmitting: true }, () => {
            let { selectedProducts } = this.state
            let suppliersVariantsToSend = []
            selectedProducts.forEach(product => {
                if (product.supplierPrice && product.supplierPrice > 0) {
                    suppliersVariantsToSend.push({ supplier_price: product.supplierPrice, variant_id: product.id })
                }
            })

            if (suppliersVariantsToSend.length > 0) {
                const postData = {
                    "suppliers_variants": suppliersVariantsToSend
                }

                assignProduct(postData, this.props.match.params.id)
                    .then(res => {
                        console.log(res);
                        toast.success("Product has been assigned successfully.");
                        window.location.replace(`/supplier/list`);
                    })
                    .catch(errMsg => {
                        toast.error(errMsg);
                        this.setState({ isSubmitting: false })
                    });
            } else {
                toast.error("Please assign atleast one item");
                this.setState({ isSubmitting: false })
            }
        })
    }

    removeAssignment = (variantId) => {
        let url = `suppliers/${this.props.match.params.id}/suppliers_variants`
        deleteData(url, { variant_id: variantId })
            .then(res => {
                if (res.status_code === 200) {
                    this.getAssignedItems()
                    toast.success(res.message);
                } else {
                    toast.error(res.message)
                }

            })
            .catch(errMsg => {
                toast.error(errMsg)
            })
    }

    render() {
        return (
            <App layout="boxed">
                <div className="page-header">
                    <h2 className="page-title">Supplier Product Assign</h2>
                </div>

                <div className="card-block">
                    <div className="block-body">
                        <SearchComponent
                            wrapperRef={this.wrapperRef}
                            loadingState={this.state.loadingState}
                            handleOnChange={this.handleOnChange}
                            handleOnSelect={this.handleOnSelect}
                            searchResult={this.state.searchResult} />
                    </div>
                </div>


                {
                    this.state.selectedProducts.length > 0 &&
                    <Form
                        onSubmit={this.handleAssignProduct}>
                        <div className="card-block">
                            {this.state.selectedProducts.map((product, index) => {
                                return (
                                    <>
                                        <div className="block-header">
                                            <h4 className="block-title">{product.product_title ? product.product_title : 'N/A'}</h4>
                                        </div>
                                        <div className="block-body">
                                            <div key={index} className="variation-card"
                                                style={{ borderBottom: 'none' }}>
                                                <div className="variation-header v-middle">
                                                    <div className="d-flex v-middle">
                                                        <h4 className="variation-title mb-0 nowrap">
                                                            {product.product_attribute_values.length > 0 ?
                                                                product.product_attribute_values.map((val, index2) => {
                                                                    let dash = index2 < product.product_attribute_values.length - 1 ? '-' : '';
                                                                    return (
                                                                        val.value + dash
                                                                    );
                                                                })
                                                                : "N/A"}
                                                        </h4>
                                                        <div className="form-inline ml-4">
                                                            <label htmlFor="" className="mr-2">Supplier
                                                                Price (BDT)</label>
                                                            <input className="form-control form-control-sm"
                                                                name="supplier_price"
                                                                required
                                                                value={product.supplierPrice !== undefined ? product.supplierPrice : ''}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                    }
                                                                }
                                                                }
                                                                onChange={(e) =>
                                                                    this.handlePriceChange(e, product.id)
                                                                }
                                                                min={1}
                                                                step={0.01}
                                                                type={"number"} />
                                                            <Button variant="danger" size="sm"
                                                                style={{ marginLeft: "10px" }}
                                                                onClick={(e) => this.handleSupplierRemove(e, product.id)}>X
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            )}
                        </div>

                        <div className="card-block">
                            <div className="block-body d-flex">
                                <div className="ml-auto">
                                    <Link className="btn btn-lg btn-default mr-2" to={`/supplier/list`}>
                                        Cancel
                                    </Link>
                                    <Button type={'submit'} size={'lg'} veriant={'primary'}>Assign</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                }

                <div className="card-block">
                    {this.state.assignedProductLists.length > 0 ?
                        this.state.assignedProductLists.map((assigned, index) => {
                            return (
                                <>
                                    <div className="block-header">
                                        <h4 className="block-title">{assigned.product}</h4>
                                    </div>
                                    <div className="block-body">
                                        {assigned?.variants.length > 0 ?
                                            assigned.variants.map((variant) => {
                                                return (
                                                    <div key={index} className="variation-card"
                                                        style={{ borderBottom: 'none' }}>
                                                        <div className="variation-header v-middle">
                                                            <div className="d-flex v-middle">
                                                                <h4 className="variation-title mb-0 nowrap">
                                                                    {variant.name}
                                                                </h4>
                                                                <div className="form-inline ml-4">
                                                                    <label htmlFor="" className="mr-2">Supplier Price
                                                                        (BDT)</label>
                                                                    <label htmlFor=""
                                                                        className="mr-2">{variant.sp_price}</label>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex v-middle">

                                                                <div className="form-inline ml-4">
                                                                    <label htmlFor="" className="mr-2">Created By</label>
                                                                    <label htmlFor=""
                                                                        className="mr-2">{variant?.created_by?.name}</label>
                                                                </div>
                                                            </div>

                                                            <div className="ml-auto">
                                                                <OverlayTrigger
                                                                    key={index + 2}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-view`}>
                                                                            Delete
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <ConfirmationModal
                                                                        title={`Close assignment for - ${variant.name}`}
                                                                        body="Are you sure to withdraw the assignment?"
                                                                        customBtn={true}
                                                                        variant={"danger"}
                                                                        btnText="Remove"
                                                                        handleAction={() => this.removeAssignment(variant.id)} />
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : ''}
                                    </div>
                                </>
                            )
                        }
                        )
                        : ''}
                </div>
            </App>
        );
    }
}

export default withRouter(ProductAssign);
