import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getData, postData } from "../../services/baseServices";
import _ from "lodash";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import ConfirmationModal from "../common/Modal"
import { TABLES } from './Reconciliation'
import { DEBOUNCE_TIME } from "../../helper/env"

class OrdersSummary extends Component {
	constructor() {
		super();
		this.state = {
			ordersSummary: null,
			isLoading: true
		};
	}    

	getOrdersSummary = (params) => {
        this.setState({ isLoading: true })
		getData(this.props.url, params)
			.then((res) => {
				this.setState(
					{
						ordersSummary: res,
						isLoading: false,
					},
					() => {}
				);
			})
			.catch((errMsg) => {
                this.setState({ isLoading: false })
				console.log("Error Message: ", errMsg);
			});
	};

	componentDidMount() {
        this.getOrdersSummary(this.props.queryParams);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
		    console.log(this.props.queryParams)
			this.getOrdersSummary(this.props.queryParams);
        }

        if(this.props.tablesToRefresh?.find(i => i === TABLES.ORDER_SUMMARY) && !this.props.tableRefreshed?.find(i => i === TABLES.ORDER_SUMMARY)) {
            this.props.stopReloading(TABLES.ORDER_SUMMARY)
			this.getOrdersSummary(this.props.queryParams);
        }
	}

    debouncedOnCollectMoney = _.debounce(()=>{
        this.collectMoney()
    }, DEBOUNCE_TIME);

    handleWithDebounceCollectMoney = async () => {
        this.debouncedOnCollectMoney();
    }

    collectMoney = () => {
        const data = {
            start_date_time: this.props.queryParams.start_date_time,
            end_date_time: this.props.queryParams.end_date_time,
        }
        
        postData( `${this.props.whom}s/${this.props.match.params.id}/cash_receive`, data)
            .then(res => {
                if (res.status_code === 200) {
                    toast.success(res.message);
                    this.props.onAfterAnyAction([TABLES.ORDER_LIST])
                    this.getOrdersSummary(this.props.queryParams);
                }
                else {
                    toast.error(res.message);
                }
            })
            .catch((err) => toast.error(err));
    }

	render() {
		return (
             <>
                { !_.isEmpty(this.state.ordersSummary)  &&
                    <div className="main-content">
                        <div className="page-header">
                            <h2 className="page-title">Order Details</h2>
                        </div>
                 
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan={2} className="bg-primary text-center">Customer Order</th>
                                    <th colSpan={2} className="bg-success text-center">Unpack Return</th>
                                    { this.props.whom === "route" && <th colSpan={2} className="bg-danger text-center">Pack Return</th> }
                                </tr>
                                <tr className="table-info">
                                    <th className="text-center">Collected by {this.props.whom === "route" ? "SR" : "Rider"}</th>
                                    <th className="text-center">Collected by FC</th>
                                    <th className="text-center">Collected by {this.props.whom === "route" ? "SR" : "Rider"}</th>
                                    <th className="text-center">Collected by FC</th>
                                    { this.props.whom === "route" &&
                                        <>
                                            <th className="text-center">Collected by {this.props.whom === "route" ? "SR" : "Rider"}</th>
                                            <th className="text-center">Collected by FC</th>
                                        </>
                                     }
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                <tr>
                                    <td>
                                        { this.state.ordersSummary.cash_collected?.[`${this.props.whom}`] }
                                        {
                                            parseFloat(this.state.ordersSummary.cash_collected?.dh) 
                                            < parseFloat(this.state.ordersSummary.cash_collected?.[`${this.props.whom}`]) ?
                                                <div>
                                                    <OverlayTrigger
                                                        key="view"
                                                        placement="top"
                                                        overlay={
                                                        <Tooltip id={`tooltip-view`}>
                                                            Collect
                                                        </Tooltip>
                                                        }
                                                    >
                                                        <ConfirmationModal 
                                                            customBtn = {true}
                                                            btnText = "Collect"
                                                            variant = "success"
                                                            title="Collection" 
                                                            body="Are you sure to collect Cash?" 
                                                            handleAction={() =>this.handleWithDebounceCollectMoney()}/>
                                                    </OverlayTrigger>
                                                </div>
                                                :
                                                <div><Button variant="success" disabled size={`sm`} >Collect</Button></div>
                                        }
                                        
                                    </td>
                                    <td>{ this.state.ordersSummary.cash_collected?.dh }</td>
                                    <td>{ this.state.ordersSummary.unpacked_return?.[`${this.props.whom}`] }</td>
                                    <td>{ this.state.ordersSummary.unpacked_return?.dh }</td>
                                    { this.props.whom === "route" &&
                                        <>
                                            <td>{ this.state.ordersSummary.packed_return?.[`${this.props.whom}`] }</td>
                                            <td>{ this.state.ordersSummary.packed_return?.dh }</td>
                                        </>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </>
            
		);
	}
}

export default withRouter(OrdersSummary);
