import React, { Component } from "react";
import App from "../../App";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FiEye, FaLongArrowAltLeft, FaPrint } from "react-icons/all";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { putData2, getData, getList, putData } from "../../services/baseServices";
import { getFormattedDate, humanize } from "../../helper/utils";
import Loader from 'react-loader';
import { toast } from "react-toastify";
import _ from 'lodash'
import { DEBOUNCE_TIME } from "../../helper/env"
import ConfirmationModal from "../common/Modal";
import ReactToPrint from "react-to-print";
import ChallanCopy from './ChallanCopy';
import QCModal from "../../components/QCModal";

class BackwardChallanForm extends Component {
	constructor(props) {
		super(props);
        this.componentRef = React.createRef(null)
		this.state = {
            questionnaires:[],
            question_details:{},
            locations:[],
            returnChallanDetails: {},
            includedOrderIds: [],
            includedReturnIds: [],
            allOrderSeleted: {
                '1': false 
            },
            allReturnOrderSeleted: {
                '1': false 
            } ,
            showQCModal: false,
            riderId: '',
            routeId: '',
            returnType: '',
            whom: ''
		};
	}

    setAllOrderMark = (all) => {
        if(all) {
          this.setState({ allOrderSeleted : {...this.state.allOrderSeleted, [this.state.page]: true}})
          const xxx = this.state.returnChallanDetails?.customer_orders?.filter(i=>i.return_challan_line_item_status !== 'received_by_fc')?.map(item=>+item.id)
          let ids = [...this.state.includedOrderIds]
          ids = ids?.filter(i => !xxx.includes(i))
          ids = ids.concat(xxx)
          this.setState({ includedOrderIds : ids})
        } else {
            this.setState({ allOrderSeleted : {...this.state.allOrderSeleted, [this.state.page]: false}})
            let selectedIds = [...this.state.includedOrderIds]
            const orderIds = this.state.returnChallanDetails?.customer_orders?.filter(i=>i.return_challan_line_item_status !== 'received_by_fc')?.map(i => i.id)
            selectedIds = selectedIds?.filter(i => !orderIds.includes(i))
            this.setState({ includedOrderIds : selectedIds})
        }
      }

    selectOrder = (orderId) => {
        let ids = [...this.state.includedOrderIds];
        if (ids?.includes(orderId)) {
          ids = ids.filter(function (e) {
            return e != orderId
          });
        } else {
          ids.push(+orderId);
            
        }
        this.setState({ includedOrderIds : ids})
      }

      setAllReturnMark = (all) => {
        if(all) {
          this.setState({ allReturnOrderSeleted : {...this.state.allReturnOrderSeleted, [this.state.page]: true}})
          const xxx = this.state.returnChallanDetails?.return_customer_orders?.filter(i=>i.return_challan_line_item_status !== 'received_by_fc')?.map(item=>+item.id)
          let ids = [...this.state.includedReturnIds]
          ids = ids?.filter(i => !xxx.includes(i))
          ids = ids.concat(xxx)
          this.setState({ includedReturnIds : ids})
        } else {
            this.setState({ allReturnOrderSeleted : {...this.state.allReturnOrderSeleted, [this.state.page]: false}})
            let selectedIds = [...this.state.includedReturnIds]
            const orderIds = this.state.returnChallanDetails?.return_customer_orders?.filter(i=>i.return_challan_line_item_status !== 'received_by_fc')?.map(i => i.id)
            selectedIds = selectedIds?.filter(i => !orderIds.includes(i))
            this.setState({ includedReturnIds : selectedIds})
        }
      }

    selectReturnOrder = (orderId) => {
        let ids = [...this.state.includedReturnIds];
        if (ids?.includes(orderId)) {
          ids = ids.filter(function (e) {
            return e != orderId
          });
        } else {
          ids.push(+orderId);
            
        }
        this.setState({ includedReturnIds : ids})
      }
    
    
    getChallan = () => {
      getData(`return_challans/${this.props.match.params.id}`)
      .then(res => {
        this.setState({returnChallanDetails: res?.data})
      })
      .catch(errMsg => {
        console.log('Error Message: ', errMsg)
      });
    }
  
    componentDidMount() {
        this.getChallan()
        getList("locations")
        .then((res) => {
            this.setState({ locations: res });
        })
        .catch((errMsg) => {});
    }

    submitChallanForm = () => {
        const params = {
            cancelled_order_ids: this.state.includedOrderIds,
            returned_order_ids: this.state.includedReturnIds
        }
        putData2(`return_challans/${this.state.returnChallanDetails?.id}/received`,params)
        .then(res => {
          if(res.success) {
            this.getChallan()
            toast.success(res.message);
          }
          else {
            toast.error(res.message);
          }
        })
        .catch(errMsg => {
          toast.error(errMsg);
        }); 
    }
  
    debouncedOnSubmitChallan = _.debounce(()=>{
      this.submitChallanForm()
    }, DEBOUNCE_TIME);
  
    handleWithDebounceSubmitForm = () => {
      this.debouncedOnSubmitChallan();
    } 

    handleAfterAction = () => {
        this.setState({ 
            showQCModal: !this.state.showQCModal,
            riderId: '',
            routeId: '',
            returnType : '',
            whom: ''
        });
        this.getChallan()
    }

	handleQCModal = (riderId, routeId, returnType, whom) => {
		this.setState({ 
            showQCModal: !this.state.showQCModal,
            riderId: riderId,
            routeId: routeId,
            returnType : returnType,
            whom: whom
        });
	};

      getQuestionnare = (category_id, return_order_id,return_type) =>{

        const question_info={return_order_id,return_type }
         
         getList("questionnaires/returns_questions",{category_id:category_id,return_order_id:return_order_id})
         .then((res) => {
             let questionnaires=res.map(item =>{
                 return ({id:item.id, question:item.question, defaultStatus:true})
             })
             this.setState({ questionnaires, question_details:question_info});
         })
         .catch((errMsg) => {});
     }
 
     setLineItemLocation = (e, line_item_id) => {
         const returnLocations = { ...this.state.returnLocations };
         returnLocations["location_id"] = e.target.value;
         returnLocations["line_item_id"] = line_item_id;
         this.setState({ returnLocations: returnLocations }, () => {});
     };
 
     debouncedOnAssignLocation = _.debounce((return_id, rider_id, route_id, whom)=>{
         this.assignLocation(return_id, rider_id, route_id, whom)
     }, DEBOUNCE_TIME);
     
     handleWithDebounceAssignLocation = async (return_id, rider_id, route_id, whom) => {
         this.debouncedOnAssignLocation(return_id, rider_id, route_id, whom);
     }
 
     assignLocation = (return_id, rider_id, route_id, whom) => {
         const params = {
             return_order_id:return_id,
             locations: [{...this.state.returnLocations}] 
         } 
 
         let addRouteOrRiderId = (obj, propName, propValue) =>{
             obj[propName] = propValue
         }
         if(whom === 'rider'){
             addRouteOrRiderId(params,'rider_id', rider_id )
         }else{
             addRouteOrRiderId(params,'route_id', route_id )
         }
 
        putData('warehouse_variants_locations/return_assign',params)
            .then((res) => {
                if(res.status_code == "200") {
                    toast.success(res.message);
                    this.getChallan()
                }    
                else {
                    toast.error(res.message);
                } 
                })
            
            
            .catch((errMsg) => {
                toast.error(errMsg);
            });
     };

	render() {
		return (
			<App layout="">
				<div className="main-content">
					<div className="page-header">
						<h2 className="page-title">Return Challan Details</h2>
                        <div className="ml-auto" style={{display: "flex"}}>
                          <Link to={'/return-challan/list'} className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Return Challan list</Link>
                        </div>
					</div>
                    {
                    
					<Loader loaded={!this.state.isLoading}>
                        <div className="card-block">
                            <div className="block-body">
                                <table className="info-table">
                                <tbody>
                                    <tr>
                                        <td style={{width: '200px'}}>Return Challan No</td>
                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                        <td className={`text-bold`}>{this.state.returnChallanDetails?.id}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '200px'}}>Distributor</td>
                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                        <td className={`text-bold`}>{this.state.returnChallanDetails?.distributor?.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '200px'}}>Status</td>
                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                        <td>{humanize(this.state.returnChallanDetails?.status)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '200px'}}>Created Date</td>
                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                        <td>{getFormattedDate(this.state.returnChallanDetails?.created_at, 'DD-MM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '200px'}}>Total Orders</td>
                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                        <td>{this.state.returnChallanDetails?.customer_orders?.length}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '200px'}}>Total Return Requests</td>
                                        <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                        <td>{this.state.returnChallanDetails?.return_customer_orders?.length}</td>
                                    </tr>
                                    {
                                        this.state.returnChallanDetails?.status !== 'initiated' &&
                                        <>
                                            <tr>
                                                <td style={{width: '200px'}}>Received Orders</td>
                                                <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                                <td>{this.state.returnChallanDetails?.customer_orders?.filter(i=>i.return_challan_line_item_status === 'received_by_fc')?.length}</td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '200px'}}>Received Return Requests</td>
                                                <td style={{width: '20px', textAlign: 'center'}}>:</td>
                                                <td>{this.state.returnChallanDetails?.return_customer_orders?.filter(i=>i.return_challan_line_item_status === 'received_by_fc')?.length}</td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <ReactToPrint
                                trigger={() => {
                                    return (<a href="#">
                                    <Button variant={"default"}  size={"sm"}><FaPrint/> Print Challan
                                    </Button>
                                    </a>)
                                }}
                                content={() => this.componentRef.current}
                                />
                            </div>
                            
                            <div ref={this.componentRef}>
                                <ChallanCopy 
                                    cancelledOrders={this.state.returnChallanDetails?.customer_orders} 
                                    returnRequests={this.state.returnChallanDetails?.return_customer_orders} 
                                    from={this.state.returnChallanDetails?.distributor} 
                                    to={this.state.returnChallanDetails?.fulfillment_center}/>
                            </div>
                            
                                {
                                    (['in_transit_to_fc', 'partially_received'].includes(this.state.returnChallanDetails?.status) && (this.state.includedOrderIds?.length > 0 || this.state.includedReturnIds?.length > 0)) &&
                                    <div className="block-body d-flex justify-content-between">
                                         <div>
                                            Selected Orders: <strong>{this.state.includedOrderIds?.length}</strong>
                                            <br/>
                                            Selected Return Requests: <strong>{this.state.includedReturnIds?.length}</strong>
                                        </div>
                                        <div>
                                            <OverlayTrigger
                                                key="view"
                                                placement="top"
                                                overlay={
                                                <Tooltip id={`tooltip-view`}>Receive Return Challan</Tooltip>
                                                }
                                            >
                                                <ConfirmationModal 
                                                    customBtn = {true}
                                                    btnText = "Receive Return Challan"
                                                    variant = "primary"
                                                    title="Receive Return Challan" 
                                                    body="Are you sure to receive the return challan?" 
                                                    handleAction={() =>this.debouncedOnSubmitChallan()}/>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                }
                                <h4>Cancelled Orders</h4>
                                <table className="order-table table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                            {
                                            !this.state.allOrderSeleted?.[this.state.page] ?
                                            <span><input name="select-all" id="select-all" type="checkbox" disabled={['completed', 'initiated'].includes(this.state.returnChallanDetails?.status) || this.state.returnChallanDetails?.customer_orders?.length == 0} onChange={()=>this.setAllOrderMark(true)}/>  Select All</span>
                                            : <span><input name="unselect-all" type="checkbox" id="unselect-all" checked={true} onChange={()=> this.setAllOrderMark(false)}/>  Unselect All</span>
                                            }
                                            </th>
                                            <th>Order No</th>
                                            <th>Shipping Type</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.returnChallanDetails?.customer_orders?.length > 0 ? (
                                            this.state.returnChallanDetails?.customer_orders?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <input 
                                                                custom
                                                                inline
                                                                type="checkbox" 
                                                                id={`custom_checkbox_${index}`} 
                                                                className="input"
                                                                value={item.id} 
                                                                name="select_order" 
                                                                disabled={['completed', 'initiated'].includes(this.state.returnChallanDetails?.status) || item?.return_challan_line_item_status === 'received_by_fc'}
                                                                checked={this.state.includedOrderIds?.includes((item.id)) || item?.return_challan_line_item_status === 'received_by_fc'}
                                                                onChange={()=>this.selectOrder(item.id)}/>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <div className="order-item">
                                                                {item.shipping_type}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="order-item">{item.total_price}</div>
                                                        </td>
                                                        <td>
                                                            <div className="order-item">{humanize(item.return_challan_line_item_status)}</div>
                                                        </td>
                                                        <td className={"nowrap"}>
                                                            <div className="btn-group">
                                                                <OverlayTrigger
                                                                    key="view"
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-view`}>View</Tooltip>
                                                                    }
                                                                >
                                                                    <Link
                                                                        to={`/customerOrder/details/${item.id}`}
                                                                        className="btn btn-sm btn-default"
                                                                    >
                                                                        <FiEye />
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <h5>No Order Found</h5>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {/* <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.activePage1}
                                        itemsCountPerPage={this.state.per_page}
                                        totalItemsCount={this.state.total_data1}
                                        pageRangeDisplayed={10}
                                        onChange={this.handlePageChange1.bind(this)}
                                    />
                                </div> */}
                            </div>
                            <div className="block-body">
                                <h4>Return Requests</h4>
                                <table className="order-table table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                            {
                                            !this.state.allReturnOrderSeleted?.[this.state.page] ?
                                            <span><input name="select-all" id="select-all" type="checkbox" disabled={['completed', 'initiated'].includes(this.state.returnChallanDetails?.status) || this.state.returnChallanDetails?.return_customer_orders?.length == 0} onChange={()=>this.setAllReturnMark(true)}/>  Select All</span>
                                            : <span><input name="unselect-all" type="checkbox" id="unselect-all" checked={true} onChange={()=> this.setAllReturnMark(false)}/>  Unselect All</span>
                                            }
                                            </th>
                                            <th>Return Request No</th>
                                            <th>Return Type</th>
                                            <th>Price</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state?.returnChallanDetails?.return_customer_orders?.length > 0 ? (
                                            this.state?.returnChallanDetails?.return_customer_orders?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <input 
                                                                custom
                                                                inline
                                                                type="checkbox" 
                                                                id={`custom_checkbox_${index}`} 
                                                                className="input"
                                                                value={item.id} 
                                                                name="select_order" 
                                                                disabled={['completed', 'initiated'].includes(this.state.returnChallanDetails?.status) || item?.return_challan_line_item_status === 'received_by_fc'}
                                                                checked={this.state.includedReturnIds?.includes((item.id)) || item?.return_challan_line_item_status === 'received_by_fc'}
                                                                onChange={()=>this.selectReturnOrder(item.id)}/>
                                                        </td>
                                                        <td>{item.id}</td>
                                                        
                                                        <td>
                                                            <div className="order-item">
                                                                {item.return_type}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="order-item">
                                                                {item.sub_total}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="order-item">{item.return_challan_line_item_status}</div>
                                                        </td>
                                                        <td className={"nowrap"}>
                                                            <div className="btn-group">
                                                                {
                                                                    (item.return_type === 'packed' && item.return_status === 'relocation_pending') ?
                                                                        <OverlayTrigger
                                                                            key="view"
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id={`tooltip-view`}>Unpack</Tooltip>
                                                                            }
                                                                        >
                                                                            <Link
                                                                                to={`/returnPackedItem/details/${item.id}`}
                                                                                className="btn btn-sm btn-default"
                                                                            >
                                                                                Unpack
                                                                            </Link>
                                                                        </OverlayTrigger>
                                                                    :
                                                                    item.return_status === "qc_pending" ? (
                                                                        <>
                                                                            <button
                                                                                className="btn btn-sm btn-primary text-center"
                                                                                onClick={() =>{
                                                                                    this.handleQCModal(item?.rider_id, item?.route_id, item?.return_type, item?.form_of_return === 'to_partner' ? 'route' : 'rider' );
                                                                                    this.getQuestionnare( item?.category_id, item.id, item.return_type)
                                                                                }}
                                                                            >
                                                                                Start QC
                                                                            </button>
                                                                        </>
                                                                    ) : item.return_status === "relocation_pending" ? (
                                                                <>
                                                                    <div className="d-flex">
                                                                        <select
                                                                            id="location"
                                                                            name="location_id"
                                                                            className="form-control form-control-sm"
                                                                            onChange={(e) => {this.setLineItemLocation(e, item?.line_item_id)}}
                                                                        >
                                                                            <option value="">--Locations--</option>
                                                                            {this.state.locations.length > 0
                                                                                ? this.state.locations.map((item, i) => {
                                                                                        return (
                                                                                            <option value={item.id} key={i}>
                                                                                                {item.code}
                                                                                            </option>
                                                                                        );
                                                                                    })
                                                                                : ""}
                                                                        </select>
                                                                        <button className="btn btn-sm btn-info text-white ml-2" 
                                                                        onClick={() => this.handleWithDebounceAssignLocation(item.id, item.rider_id, item.route_id, item?.form_of_return === 'to_partner' ? 'route' : 'rider')}>
                                                                            Assign
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            ) :item.return_status ==='completed'?(""):('')
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <h5>No Return Requests Found</h5>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {/* <div className="mt-3">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.activePage2}
                                        itemsCountPerPage={this.state.per_page}
                                        totalItemsCount={this.state.total_data2}
                                        pageRangeDisplayed={10}
                                        onChange={this.handlePageChange2.bind(this)}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </Loader>
                    }
				</div>
                <QCModal
                    show={this.state.showQCModal}
                    handleClose={this.handleQCModal}
                    questionnaires={this.state.questionnaires}
                    question_details={this.state.question_details}
                    whom = {this.state.whom}
                    rider_id = {this.state.riderId}
                    route_id = {this.state.routeId}
                    handleAfterAction = {this.handleAfterAction}

                />
			</App>
		);
	}
}

export default withRouter(BackwardChallanForm);
