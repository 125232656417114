import React, { Component } from "react";
import App from "../../App";
import { Link, withRouter } from "react-router-dom";
import { MdArrowBack } from "react-icons/all";
import { getDetails } from "../../services/baseServices";
import { getFormattedDate, humanize } from "../../helper/utils";
import ExportCSVAsync from "../common/ExportCSVAsync";

const headers = [
	{ label: "Coupon Code", key: "code" },
	{ label: "Type", key: "usable_type" },
	{ label: "Applicable For", key: "applicable_for" },
	{ label: "Applicable On", key: "applicable_on" },
	{ label: "Is Used", key: "is_used" }
];

const loadData = (item) => {
	return {
		code: item.code,
		usable_type: item.usable_type,
		applicable_for: item?.applicable_for,
		applicable_on: item?.applicable_on?.join(","),
		is_used: item?.is_used ? "True" : "False"
	};
};

class Details extends Component {
	state = {
		promotion: {},
	};

	componentDidMount() {
		getDetails("promo_coupons", this.props.match.params.id)
			.then((res) => {
				this.setState({
					promotion: res?.data,
				});
			})
			.catch((errMsg) => {
				console.log("Error Message: ", errMsg);
			});
	}

	render() {
		const { promotion } = this.state;

		return (
			<App layout="">
				<div className="main-content">
					<div className="page-header">
						<div className="page-title" />
						<div className="ml-auto">
							<Link
								to={`${process.env.PUBLIC_URL}/coupon/list`}
								className={`btn btn-sm btn-link`}
							>
								<MdArrowBack /> Back to List
							</Link>
						</div>
					</div>

					<div className="card-block">
						<div className="block-header">
							<h4 className="block-title">Promotion Details</h4>
							<div className="ml-auto">
								<ExportCSVAsync
									fileName={
										"Coupons" +
										getFormattedDate(new Date(), "DD-MM-YYYY") +
										".csv"
									}
									Url={`promo_coupons/${this.props.match.params.id}/export`}
									headers={headers}
									loadData={loadData}
									title="Promotion Coupon Details"
								/>
							</div>
						</div>
						<div className="block-body">
							<>
								<table className="info-table">
									<tbody>
										<tr>
											<td style={{ width: "250px" }}>Promotion Title</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{humanize(promotion.title)}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Created By</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{humanize(promotion?.created_by?.name)}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Applicable From</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{getFormattedDate(promotion.start_date, "DD/MM/YYYY")}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Applicable To</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>{getFormattedDate(promotion.end_date, "DD/MM/YYYY")}</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Status</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{
													humanize(promotion.status)
												}
											</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Mini Cart Value</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{promotion.minimum_cart_value}
											</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Order Type</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{humanize(promotion.order_type)}
											</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>Discount Type</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{humanize(promotion.discount_type)}
											</td>
										</tr>
										<tr>
											<td style={{ width: "250px" }}>
												Discount{promotion.discount_type === "percentage" && "(%)"}
											</td>
											<td style={{ width: "20px", textAlign: "center" }}>:</td>
											<td className={`text-bold`}>
												{promotion.discount}
											</td>
										</tr>
										{
											promotion?.discount_type !== "fixed" &&
											<tr>
												<td style={{ width: "250px" }}>Max Discount Amount</td>
												<td style={{ width: "20px", textAlign: "center" }}>:</td>
												<td className={`text-bold`}>
													{promotion.max_discount_amount}
												</td>
											</tr>
										}
										{
											promotion?.promo_coupon_rules?.map((item, i) => {
												return (
													<tr key={i}>
														<td style={{ width: "250px" }}>{item?.ruleable_type}</td>
														<td style={{ width: "20px", textAlign: "center" }}>:</td>
														<td className={`text-bold`}>
															{item?.ruleable_values?.join(", ")}
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</>
						</div>
					</div>
				</div>
			</App>
		);
	}
}

export default withRouter(Details);
