import {useState, useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import App from "../../App";
import {AiOutlineArrowLeft} from 'react-icons/all'
import { getDetails } from "../../services/baseServices";

const Details = () => {
  const { id } = useParams()
  const [metaInfoDetails, setMetaInfoDetails] = useState({})

  useEffect(() =>{
    getDetails('static_pages', id)
    .then((res) => {
      if (res?.success) {
        setMetaInfoDetails(res.data);
      }
    });    
  }, [id])

  return (
    <App layout={`boxed`}>
      <div className="main-content">
        <div className="page-header">
          <h2 className="page-title">Meta Info Details </h2>
          <div className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/meta-info/list`}
              className="btn btn-sm btn-link mr-2"
            >
              <AiOutlineArrowLeft /> Back to List
            </Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className='text-bold' style={{ width: "250px" }}>Page</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>{metaInfoDetails?.page_type}</td>
                  </tr> 
                   <tr>
                    <td className='text-bold' style={{ width: "250px" }}>Meta Title</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>{metaInfoDetails?.meta_info?.meta_title}</td>
                  </tr>
                  <tr>
                    <td className='text-bold' style={{ width: "250px" }}> Meta Title in Bangla</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>{metaInfoDetails?.meta_info?.bn_meta_title}</td>                  
                  </tr>
                  <tr>
                    <td className='text-bold' style={{ width: "250px" }}> Meta Description</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>{metaInfoDetails?.meta_info?.meta_description}</td>                  
                  </tr>
                  <tr>
                    <td className='text-bold' style={{ width: "250px" }}>
                        Meta Description in Bangla
                    </td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>{metaInfoDetails?.meta_info?.bn_meta_description}</td>                  
                  </tr>
                  <tr>
                    <td className='text-bold' style={{ width: "250px" }}> Meta Keywords</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>{
                          metaInfoDetails?.meta_info?.meta_keyword?.map((item,index)=>(
                            <span key={index}>{item}, </span>
                          ))
                    }</td>                  
                  </tr>
                  <tr>
                    <td className='text-bold' style={{ width: "250px" }}> Meta Keywords in Bangla</td>
                    <td style={{ width: "20px", textAlign: "center" }}>:</td>
                    <td>{
                          metaInfoDetails?.meta_info?.bn_meta_keyword?.map((item,index)=>(
                            <span key={index}>{item}, </span>
                          ))
                    }</td>                   
                  </tr>
                </tbody>
              </table>
            </>
          </div>
        </div>
      </div>
    </App>
  );
};

export default Details;
