import React, { Component } from 'react';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Pagination from 'react-js-pagination';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { Button } from 'react-bootstrap';
import App from './../../App';
import { getPaginatedList } from '../../services/baseServices';
import { FiEye } from 'react-icons/all';
import { getFormattedDate } from '../../helper/utils';

class ContactUsList extends Component {
    constructor() {
        super();
        this.state = {
            contacts: [],
            activePage: 1,
            per_page :20,
            page:1,
            isLoading: false,
            temp_params: {},
            query_params: {},
        };
    }

    componentDidMount() {
        this.getPaginatedReviews(this.getQueryParams());
    }

    getQueryParams = () => {
        let params = Object.assign(this.state.query_params, {per_page: this.state.per_page, page: this.state.page});
        return params;
    }

    handlePageChange = (pageNumber) => {
      this.setState({page: pageNumber, activePage: pageNumber}, () => {
          this.getPaginatedReviews(this.getQueryParams());
      });
  }

    getPaginatedReviews = (params) => {
        this.setState({...this.state, isLoading: true});
        getPaginatedList("contact_us",params)
          .then(res => {
              if(res.data?.success === true){
                this.setState({
                  contacts: res.data.data, 
                    isLoading: false,
                    per_page: parseInt(res.headers['x-per-page']),
                    total_data: parseInt(res.headers['x-total']),
                  }, () => {
                  });
              }else{
                this.setState({...this.state, isLoading: false});
              }
          })
          .catch(errMsg => {
            this.setState({...this.state, isLoading: false});
            console.log('Error Message: ', errMsg)
          })
    }
    
    handleOnFieldChange = (e) => {
      const queryData = {...this.state.temp_params};
      queryData[e.target.name] = e.target.value;
      this.setState({temp_params: queryData}, () => {
      });
    }

    handleOnSearch = (e) => {
      e.preventDefault();
      const tempParam = this.state.temp_params;
        this.setState({page: 1, query_params: tempParam, isLoading:true}, () => {
          this.getPaginatedReviews(this.state.temp_params);
        });
    }

    render() {
        return (
            <App layout="">
          <div className="main-content">
            <div className="page-header">
              <h2 className="page-title">Contact List</h2>
            </div>

              <div className="card-block">
                <div className="block-body">

                  <form className="form-inline mb-3">
                  <div className="form-group mr-3">
                    <label htmlFor="title" className="sr-only">Search by Phone</label>
                    <input type="search" name="phone" id="phone" onChange={this.handleOnFieldChange}
                          className='form-control form-control-sm' placeholder="Search by phone"/>
                  </div>

                  <div className="form-group mr-3">
                    <label htmlFor="brand" className="sr-only">Search by Email</label>
                    <input type="text" name="email" id="email" onChange={this.handleOnFieldChange}
                          className='form-control form-control-sm' placeholder="Search by Email"/>
                  </div>
                    <Button type={`submit`} onClick={this.handleOnSearch} size="sm" variant="primary">Search</Button>
                  </form>
                  <Loader loaded={!this.state.isLoading}>
                    <table className="order-table table table-striped">
                      <thead>
                      <tr>
                        <th style={{width: '75px'}}>SL</th>
                        <th style={{width: '100px'}}>Name</th>
                        <th style={{width: '120px'}}>CONTACT NUMBER</th>
                        <th style={{width: '200px'}}>Email</th>
                        <th>Message</th>
                        <th style={{width: '150px'}}>Submit Time</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.contacts.length > 0 ? this.state.contacts.map((item, index) => {
                        return (
                            <tr key={item.id}>
                              <td>
                                <div className="order-item">
                                    {item.id}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                    {item.name}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.phone}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.email}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                  {item?.message}
                                </div>
                              </td>
                              <td>
                                <div className="order-item">
                                    {getFormattedDate(item.created_at, 'DD MMMM, YYYY') || '_'}
                                </div>
                              </td>
                              <td>
                                <div className="btn-group">
                                  <OverlayTrigger
                                      key="view"
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-view`}>
                                          View Details
                                        </Tooltip>
                                      }
                                  >
                                  <Link to={`/contact-us/details/${item.id}`} className="btn btn-sm btn-default"><FiEye/></Link>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                        );
                      }) : ""}
                      </tbody>
                    </table>
                  </Loader>
                  </div>
                <div className="mt-3">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.per_page}
                    totalItemsCount={this.state.total_data}
                    pageRangeDisplayed={10}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
          
            </div>
          <ToastsContainer store={ToastsStore}/>
        </App>
        );
    }
}

export default ContactUsList;