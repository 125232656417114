import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiMoney } from "react-icons/all";
import Pagination from "react-js-pagination";
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { getPaginatedList, getList } from "../../services/baseServices";
import ExportCSVAsync from "../common/ExportCSVAsync";
import { getFormattedDate } from "../../helper/utils";

const headers = [
	{ label: "Route Name", key: "name" },
	{ label: "Distributor", key: "distributor_name" },
	{ label: "Phone", key: "phone" },
	{ label: "Total Order", key: "total_order" },
	{ label: "Prepaid", key: "prepaid_order_count" },
	{ label: "Collected by FC", key: "collected_by_fc" },
	{ label: "Collected by SR", key: "collected_by_sr"}
];

const loadData = (item) => {
	return {
		name: item.title,
		distributor_name: item.distributor_name,
		phone: item.phone,
		total_order: item.total_order,
		prepaid_order_count: item.prepaid_order_count,
		collected: item.collected,
		collected_by_fc: item.collected_by_fc,
		collected_by_sr: item.collected_by_sr
	};
};

class Routes extends Component {
	constructor() {
		super();
		this.state = {
			routes: [],
			activePage: 1,
			per_page: 15,
			page: 1,
			temp_params: {},
			query_params: {},
			isLoading: true,
			distributors: []
		};
	}

	getRouteList = (params) => {
		getPaginatedList("warehouses/routes", params)
			.then((res) => {
				this.setState(
					{
						routes: res.data,
						isLoading: false,
						activePage: parseInt(res.headers["x-page"]),
						per_page: res.headers["x-per-page"],
						total_data: parseInt(res.headers["x-total"]),
					},
					() => {}
				);
			})
			.catch((errMsg) => {
				console.log("Error Message: ", errMsg);
			});
	};

	getQueryParams = () => {
		let params = Object.assign(this.state.query_params, {
			per_page: this.state.per_page,
			page: this.state.page,
		});
		return params;
	};

	componentDidMount() {
		getList("distributors")
        .then((res) => {
            this.setState({distributors: res?.data});
        })
        .catch((errMsg) => {});
		this.setState({ query_params: this.props.queryParams, temp_params: this.props.queryParams }, () => {
			this.getRouteList(this.props.queryParams);
		})
	}

	handleOnSearch = (e) => {
		e.preventDefault();
		const tempParam = this.state.temp_params;
		this.setState({...this.state, page: 1, query_params: tempParam}, () => {
			this.getRouteList(this.state.temp_params);
		});
	}

	handleOnFieldChange = (e) => {
		const queryData = {...this.state.temp_params};
		queryData[e.target.name] = e.target.value;
		this.setState({temp_params: queryData}, () => {
		});
	}

	handlePageChange = (pageNumber) => {
		this.setState({ page: pageNumber, activePage: pageNumber }, () => {
			this.getRouteList(this.getQueryParams());
		});
	};

	handleOnClear = ()=>{
		this.setState({
			temp_params: {...this.state.query_params,
				title: '', 
				distributor_id:''
			},
			query_params: {...this.state.query_params, 
				title: '', 
				distributor_id:''
			}
		}, () =>{
			this.getRouteList(this.getQueryParams());
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.queryParams.start_date_time.toString() != this.props.queryParams.start_date_time.toString() 
            || prevProps.queryParams.end_date_time.toString() != this.props.queryParams.end_date_time.toString() ) {
			this.setState({ 
				query_params: { 
					title : this.state.query_params?.title, 
					distributor_id: this.state.query_params?.distributor_id, 
					...this.props.queryParams }, 
				temp_params: {
					title : this.state.query_params?.title, 
					distributor_id: this.state.query_params?.distributor_id, 
					...this.props.queryParams } }, () => {
            	this.getRouteList(this.getQueryParams());
			})
        }
	}

	render() {
		return (
		<div className="main-content">
			<div className="page-header">
				<h2 className="page-title">Route List</h2>
				<div className="ml-auto" style={{display: "flex"}}>
					<ExportCSVAsync
						fileName={"Routes-"+getFormattedDate(new Date(),"DD-MM-YYYY")+".csv"}
						Url={`warehouses/routes_export`}
						headers={headers}
						loadData={loadData}
						params={this.getQueryParams()}
						title="Route List"/>
				</div>
			</div>
			<div align="center">
				<form className="form-inline mb-3">
					<div className="form-group mr-3">
						<label htmlFor="searchInput" className="sr-only">
							Router Name
						</label>
						<input
							type="search"
							name="title"
							id="title"
							className="form-control form-control-sm"
							placeholder="Search by SR Name"
							value={this.state.temp_params?.title}
							onChange={this.handleOnFieldChange}
						/>
					</div>
					<div className="form-group mr-3">
						<label htmlFor="distributor_id" className="sr-only">
							Distributor
						</label>
						<select
							id="distributor_id"
							name="distributor_id"
							className="form-control form-control-sm"
							onChange={this.handleOnFieldChange}
							value={this.state.temp_params?.distributor_id}
						>
							<option value="">--Select Distributor--</option>
							{this.state.distributors.map((item, i) => {
								return (
									<option
										key={i}
										value={item.id}
									>
										{item.name}
									</option>
								);
							})}
						</select>
					</div>
					<Button
						size="sm"
						type={`submit`}
						onClick={this.handleOnSearch}
						variant="primary"
					>
						Filter
					</Button>
					<Button
						size="sm"
						type={`button`}
						className="ml-2"
						onClick={this.handleOnClear}
						variant="danger"
					>
						Clear
					</Button>
				</form>
			</div>
			<table className="order-table table table-striped">
				<thead>
					<tr>
						<th style={{ width: '20px' }}>#</th>
						<th>Delivery Man Name</th>
						<th>Distributor</th>
						<th>Phone</th>
						<th>Total Order</th>
						<th>Prepaid</th>
						<th>Collected By FC</th>
						<th>Collected by SR</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{this.state.routes.length > 0 ? this.state.routes.map((item, index) => {
					return (
						<tr key={index}>
							<td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
							<td>
								<div className="order-item">
								{item?.title}
								</div>
							</td>
							<td>
								<div className="order-item">
								{item?.distributor_name}
								</div>
							</td>
							<td>
								<div className="order-item">
								{item?.phone}
								</div>
							</td>
							<td>
								<div className="order-item">
									{item?.total_order}
								</div>
							</td>
							<td>
								<div className="order-item">
									{item?.prepaid_order_count}
								</div>
							</td>
							<td>
								<div className="order-item">
									{item?.collected_by_fc}
								</div>
							</td>
							<td>
								<div className="order-item">
									{item?.collected_by_sr}
								</div>
							</td>
							<td style={{ width: '150px' }}>
								<div className="btn-group">
								<OverlayTrigger
									key="view"
									placement="top"
									overlay={
									<Tooltip id={`tooltip-view`}>
										Collect
									</Tooltip>
									}
								>
									<Link to={`/route/collect/${item.id}`} className="btn btn-sm btn-default"><BiMoney /></Link>
								</OverlayTrigger>
								</div>
							</td>
						</tr>
					);
					}) : ""}
				</tbody>
			</table>
			<div className="mt-3">
				<Pagination
					itemClass="page-item"
					linkClass="page-link"
					activePage={this.state.activePage}
					itemsCountPerPage={parseInt(this.state.per_page)}
					totalItemsCount={parseInt(this.state.total_data)}
					pageRangeDisplayed={10}
					onChange={this.handlePageChange.bind(this)}
				/>
			</div>
		</div>
		);
	}

}

export default withRouter(Routes);
