import React, {Component} from 'react';
import App from "../../App";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import styleReactSelect from "../../assets/styleReactSelect";
import {FaLongArrowAltLeft} from "react-icons/all";
import {getAttributeDetails, updateAttribute} from "../../services/attributeServices";

class Add extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        product_attribute_values_attributes: [],
      },
      isFormValidated: false,
      isLoadingData: true,
      isSubmitting: false,
      attributeInfo: null,
      attributeValue: [],
      attributeBnValue: [],
      originalAttrValue: [],
    };
  }

  componentDidMount() {
    getAttributeDetails(this.props.match.params.id)
      .then(async res => {
        let attributeValue = this.state.attributeValue;
        let originalAttrValue = this.state.originalAttrValue;
        if (res.product_attribute_values && res.product_attribute_values.length > 0) {
          await res.product_attribute_values.map((item) => {
            attributeValue.push({label: item.value, value: item.value, id: item.id, bn_value: item.bn_value});
            originalAttrValue.push({id: item.id, value: item.value, bn_value: item.bn_value});
          });
        }
        await this.setState({
          attributeInfo: res,
          attributeValue: attributeValue,
          originalAttrValue: originalAttrValue,
          formData: {
            ...this.state.formData,
            name: res.name,
            product_attribute_values_attributes: res.product_attribute_values,
            bn_name: res.bn_name,
          }
        }, () => {
          console.log(this.state.originalAttrValue);
        })
      })
      .catch(errMsg => {
        console.log('Err Msg: ', errMsg)
      });
  }

  handleInputOnChange = (e) => {
    const formData = {...this.state.formData};
    formData[e.target.name] = e.target.value;
    this.setState({formData}, () => {
      console.log(this.state.formData);
    });
  }

  handleBnInputOnChange = (e, index) => {
    let attributes = [...this.state.formData.product_attribute_values_attributes];
    let originalAttrValue = [...this.state.formData.product_attribute_values_attributes];
    attributes[index].bn_value = e.target.value;
    originalAttrValue[index].bn_value = e.target.value;
    this.setState({
      formData: {...this.state.formData, product_attribute_values_attributes: attributes, originalAttrValue: originalAttrValue}
    }, () => {
      console.log(this.state.formData)
    });
  }

  handleAttrOnChange = (newValue, type) => {
    let attributes = [];
    if (newValue && type === 1) {
      newValue.forEach((val) => {
        attributes.push({value: val.value, bn_value: val.bn_value});
      });
    }
    this.setState({
      formData: {...this.state.formData, product_attribute_values_attributes: attributes}
    }, () => {
      console.log(this.state.formData)
    });
  }


  handleAddItem = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isFormValidated: true})
    } else {
      this.setState({/*isSubmitting: true*/}, () => {
        const formData = {...this.state.formData}
        const attributes = [...this.state.formData.product_attribute_values_attributes]
        const attIndex = [];
        const originalAttrValue = [...this.state.originalAttrValue]
          originalAttrValue.map((item, i) => {
              let index = attributes.findIndex(attr => attr.id === item.id || attr.value === item.value);
              console.log(attributes);
              if (index === -1) {
                item.is_deleted = true;
              }else{
                delete item.is_deleted;
                item.value = attributes[index].value;
                item.bn_value = attributes[index].bn_value;
                  attributes.splice(index, 1);
              }
            }
          );

        let product_attribute_values = [...originalAttrValue, ...attributes];
        formData.product_attribute_values_attributes = product_attribute_values;
        delete formData.originalAttrValue;
        updateAttribute(this.props.match.params.id, formData)
          .then(res => {
            if(res?.id) {
            this.props.history.push(`/attribute/list`);
            toast.success("Edit success");
            } else {
              toast.error(res?.message)
              this.setState({isSubmitting: false})
            }
          })
          .catch(errMsg => {
            toast.error(errMsg);
            this.setState({isSubmitting: false})
          });
      })
      this.setState({isFormValidated: true})
    }
  }

  render() {
    const {formData} = this.state;

    return (
      <App layout="boxed">
        <div className="page-header">
          <h2 className="page-title">Edit Attribute</h2>
          <div className="ml-auto">
            <Link to={`${process.env.PUBLIC_URL}/attribute/list`}
                  className="btn btn-sm btn-link"><FaLongArrowAltLeft/> Back to Attribute List</Link>
          </div>
        </div>

        <div className="card-block">
          <div className="block-body">
            <>
              <Form
                noValidate
                validated={this.state.isFormValidated}
                onSubmit={this.handleAddItem}>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"name"}
                      defaultValue={this.state.attributeInfo?.name}
                      onChange={this.handleInputOnChange}
                      required
                      pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter attribute name.</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Name in Bangla <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm="9" md={8} lg={7}>
                    <Form.Control
                      name={"bn_name"}
                      defaultValue={this.state.attributeInfo?.bn_name}
                      onChange={this.handleInputOnChange}
                      required
                      // pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
                      type="text"/>

                    <Form.Control.Feedback type="invalid">Please enter attribute in Bangla.</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="displayOrShopName">
                  <Form.Label column sm="3">
                    Values <span className="text-danger">*</span>
                  </Form.Label>

                  <Col sm="9" md={8} lg={7}>
                    <CreatableSelect
                      defaultValue={this.state.attributeValue}
                      required
                      isMulti
                      styles={styleReactSelect}
                      placeholder="Please type your values..."
                      onChange={(newValue) => {
                        this.handleAttrOnChange(newValue, 1);
                      }}
                      noOptionsMessage={() => 'Please type your values...'}
                    />
                  </Col>
                </Form.Group>

                <div id="dynamicInput">
                  {this.state.formData.product_attribute_values_attributes.map((item, index) => {
                    return (
                      <Form.Group as={Row} controlId="displayOrShopName">
                        <Form.Label column sm="3">
                          {item.value} in Bangla<span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="9" md={8} lg={7}>
                          <Form.Control
                            name={"bn_name"}
                            onChange={(e) => {
                              this.handleBnInputOnChange(e, index)
                            }}
                            defaultValue={item?.bn_value}
                            required
                            type="text"/>
                          <Form.Control.Feedback type="invalid">Please enter attribute in
                            Bangla.</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    );
                  })}
                </div>

                <div className="mt-3">
                  <Button size="lg"
                          type={`submit`}
                          disabled={this.state.isSubmitting}
                          variant="primary">{this.state.isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                </div>
              </Form>
            </>
          </div>
        </div>
      </App>
    );
  }
}

export default withRouter(Add);