import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import DatePicker from "react-datepicker";
import TimeInput from "material-ui-time-picker";
import { Link } from "react-router-dom";
import { getList, getData } from "../../services/baseServices";
import moment from "moment";
import "../../assets/scss/promo.scss";

class FlashSaleForm extends Component {
  state = {
    startTime: new Date(),
    endTime: new Date(),
    // warehouses: [],
    running: false,
  };

  componentDidMount() {
    // getList("warehouses")
    //   .then((res) => {
    //     this.setState({ warehouses: res });
    //     this.setState(
    //       {
    //         fromDate: new Date(this.props?.promotion?.from_date),
    //         toDate: new Date(this.props?.promotion?.to_date),
    //         running: this.props?.promotion?.running,
    //       },
    //       () => this.assignTimeBoundFields()
    //     );
    //   })
    //   .catch((errMsg) => {});
      this.setState(
        {
          fromDate: new Date(this.props?.promotion?.from_date),
          toDate: new Date(this.props?.promotion?.to_date),
          running: this.props?.promotion?.running,
        },
        () => this.assignTimeBoundFields());
  }

  assignTimeBoundFields = () => {
    let today = new Date();
    const startTimeParts = this.props?.promotion?.start_time.split(":");
    const endTimeParts = this.props?.promotion?.end_time.split(":");
    const localStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      startTimeParts[0],
      startTimeParts[1]
    );
    const localEndDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      endTimeParts[0],
      endTimeParts[1]
    );

    this.setState({
      startTime: localStartDate,
      endTime: localEndDate,
    });
  };

  handleOnChange = (id, key, value) => {
    console.log(key, value);
    let xx = value.map((item) => {
      return item.value;
    });
    console.log(xx, xx);
    this.props.onPromoFieldChange(id, key, xx);
  };

  render() {
    const errors = this.props.errors;
    return (
      <Form
        noValidate
        //validated={this.props.isFormValidated}
        onSubmit={this.props?.handleClick}>
        <Form.Group as={Row} controlId={"promotion-title"}>
          <Form.Label column sm="3">
            Title <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              // pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
              defaultValue={this.props.promotion?.title}
              name="title"
              onChange={this.props.handleInputOnChange}
              required
              type="text"
              maxLength={25}
            />
            <Form.Control.Feedback type="invalid">
              Title is required
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId={"promotion-title"}>
          <Form.Label column sm="3">
            Bangla Title <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              // pattern="^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()-]+$"
              defaultValue={this.props.promotion?.title_bn}
              name="title_bn"
              onChange={this.props.handleInputOnChange}
              required
              type="text"
              maxLength={25}
            />
            <Form.Control.Feedback type="invalid">
              Bangla Title is required
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} controlId="warehouse">
          <Form.Label column sm="3">
            Warehouse <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <Form.Control
              disabled={this.state.running}
              value={this.props.promotion?.warehouse_id}
              name="warehouse_id"
              onChange={(e) => {
                this.props.handleInputOnChange(e);
              }}
              as="select"
              type="text">
              <option value="">--Select--</option>
              {this.state.warehouses.length > 0
                ? this.state.warehouses.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                : ""}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a warehouse.
            </Form.Control.Feedback>
          </Col>
        </Form.Group> */}

        <Form.Group as={Row} controlId="from-date">
          <Form.Label column sm="3">
            From Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <DatePicker
              disabled={this.state.running}
              selected={this.state.fromDate}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              maxDate={this.state.toDate}
              onChange={(date) => {
                //this.props?.formData["from_date", date]
                this.setState({ fromDate: date });
                this.props.handleDateOnChange("from_date", date);
              }}
            />
            <Form.Control.Feedback className="d-block" type="invalid">
              {errors.from_date}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="to-date">
          <Form.Label column sm="3">
            To Date<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <DatePicker
              disabled={this.state.running}
              required
              selected={this.state.toDate}
              minDate={this.state.fromDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                //this.props?.formData["to_date", date]
                this.setState({ toDate: date });
                this.props.handleDateOnChange("to_date", date);
              }}
            />
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.to_date}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="start_time">
          <Form.Label column sm="3">
            Start Time<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <TimeInput
              disabled={this.state.running}
              required
              value={this.state.startTime}
              mode="24h"
              onChange={(time) => {
                this.setState({ startTime: time }, () => {
                  this.props.handleTimeOnChange(
                    "start_time",
                    moment(time).format("HH:mm")
                  );
                });
                //this.props?.formData["start_time", time]
              }}
            />
            {/* <Form.Control.Feedback type="invalid">{errors.start_time}</Form.Control.Feedback> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="end_time">
          <Form.Label column sm="3">
            End Time<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <TimeInput
              disabled={this.state.running}
              required
              value={this.state.endTime}
              mode="24h"
              onChange={(time) => {
                this.setState({ endTime: time }, () => {
                  this.props.handleTimeOnChange(
                    "end_time",
                    moment(time).format("HH:mm")
                  );
                  //this.props?.formData["end_time", time]
                });
              }}
            />
            {/* <Form.Control.Feedback type="invalid">{errors.end_time}</Form.Control.Feedback> */}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="active">
          <Form.Label column sm="3">
            Status
          </Form.Label>
          <Col sm="9" md={8} lg={7}>
            <RadioGroup
              row
              name="is_active"
              value={this.props.formData?.is_active}
              onChange={this.props.handleInputOnChange}>
              <FormControlLabel className="promo-status"
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel className="promo-status"
                value={false}
                control={<Radio />}
                label="In Active"
              />
            </RadioGroup>
          </Col>
        </Form.Group>

        <div className="mt-3 text-center">
          <Link
            to={`${process.env.PUBLIC_URL}/flashSale/list`}
            className="mr-2 btn btn-md btn-danger">
            Cancel
          </Link>
          <Button
            size="md"
            type={`submit`}
            disabled={this.props.isSubmitting}
            variant="primary">
            {this.props.isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </Form>
    );
  }
}
export default FlashSaleForm;
