import React, { Component } from 'react';
import App from "../../App";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiLayerPlus, FiEdit, FiPlus } from "react-icons/all";
import Pagination from 'react-js-pagination';
import { withRouter, Link } from "react-router-dom";
import "../../assets/scss/order-list.scss";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { toast } from 'react-toastify';
import ConfirmationModal from "../common/Modal";
import { destroy, getList, getPaginatedList } from "../../services/baseServices";
import { getWarehouseType } from "../../helper/utils";

const wareHouseType = getWarehouseType()

class List extends Component {
  constructor() {
    super();
    this.state = {
      routes: [],
      activePage: 1,
      per_page: 15,
      page: 1,
      query_params: {},
      distributors: []
    };
  }

  getRouteList = (params) => {
    getPaginatedList("routes/paginate", params)
      .then((res) => {
        this.setState(
          {
            routes: res.data,
            isLoading: false,
            per_page: res.headers["x-per-page"],
            total_data: parseInt(res.headers["x-total"]),
          },
          () => { }
        );
      })
      .catch((errMsg) => {
        console.log("Error Message: ", errMsg);
      });
  };

  getQueryParams = () => {
    let params = Object.assign(this.state.query_params, {
      per_page: this.state.per_page,
      page: this.state.page,
    });
    return params;
  };

  componentDidMount() {
    getList("distributors")
      .then((res) => {
        this.setState({ distributors: res?.data });
      })
      .catch((errMsg) => { });
    this.getRouteList(this.getQueryParams());
  }


  handleOnSearch = (e) => {
    e.preventDefault();
    const tempParam = this.state.temp_params;
    this.setState({ ...this.state, page: 1, query_params: tempParam }, () => {
      this.getRouteList(this.state.temp_params);
    });
  }

  handleOnFieldChange = (e) => {
    const queryData = { ...this.state.temp_params };
    queryData[e.target.name] = e.target.value;
    this.setState({ temp_params: queryData }, () => {
    });
  }

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber, activePage: pageNumber }, () => {
      this.getRouteList(this.getQueryParams());
    });
  };

  handleOnClear = () => {
    this.setState({
      temp_params: {
        ...this.state.temp_params,
        title: '',
        distributor_id: ''
      },
      query_params: {
        ...this.state.temp_params,
        title: '',
        distributor_id: ''
      }
    }, () => {
      this.getRouteList(this.getQueryParams());
    })
  }

  handleDeleteRoute = id => {
    destroy("routes", id)
      .then(res => {
        if (res.status_code === 200) {
          toast.success('Successfully deleted');
          this.setState({ page: 1, activePage: 1 }, () => {
            this.getRouteList(this.getQueryParams());
          });
        } else {
          toast.error(res?.message)
        }

      })
      .catch(errMsg => {
        toast.error(errMsg)
      })
  }

  render() {
    return (
      <App layout="">
        <div className="main-content">

          <div className="page-header">
            <h2 className="page-title">Delivery Man List</h2>
            {
              wareHouseType !== 'central' &&
              <div className="ml-auto">
                <Link to={`${process.env.PUBLIC_URL}/route/add`} className="btn btn-sm btn-secondary"><FiPlus /> Add Delivery Man</Link>
              </div>
            }

          </div>
          <div align="center">
            <form className="form-inline mb-3">
              <div className="form-group mr-3">
                <label htmlFor="searchInput" className="sr-only">
                  Router Name
                </label>
                <input
                  type="search"
                  name="title"
                  id="title"
                  value={this.state.temp_params?.title}
                  className="form-control form-control-sm"
                  placeholder="Search by SR Name"
                  onChange={this.handleOnFieldChange}
                />
              </div>
              <div className="form-group mr-3">
                <label htmlFor="distributor_id" className="sr-only">
                  Distributor
                </label>
                <select
                  id="distributor_id"
                  name="distributor_id"
                  className="form-control form-control-sm"
                  onChange={this.handleOnFieldChange}
                  value={this.state.temp_params?.distributor_id}
                >
                  <option value="">--Select Distributor--</option>
                  {this.state.distributors.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <Button
                size="sm"
                type={`submit`}
                onClick={this.handleOnSearch}
                variant="primary"
              >
                Filter
              </Button>
              <Button
                size="sm"
                type={`button`}
                className="ml-2"
                onClick={this.handleOnClear}
                variant="danger"
              >
                Clear
              </Button>
            </form>
          </div>
          <div className="card-block">
            <div className="block-body">

              {/*<form className="form-inline mb-3">*/}
              {/*  <div className="form-group mr-3">*/}
              {/*    <label htmlFor="searchInput" className="sr-only">Search Key</label>*/}
              {/*    <input type="search" name="search_string" id="orderNumber"*/}
              {/*           className='form-control form-control-sm' placeholder="Search Key"/>*/}
              {/*  </div>*/}
              {/*  <Button size="sm" variant="secondary">Search</Button>*/}
              {/*</form>*/}
              <table className="order-table table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: '20px' }}>#</th>
                    <th>Delivery Man Name</th>
                    <th>Distributor</th>
                    <th>Phone</th>
                    {
                      wareHouseType === 'central' && <th>Created By</th>
                    }

                    {
                      wareHouseType !== 'central' &&
                      <th>Action</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {this.state.routes.length > 0 ? this.state.routes.map((item, index) => {
                    return (
                      <tr key={1}>
                        <td>{((this.state.page - 1) * this.state.per_page) + index + 1}</td>
                        <td>
                          <div className="order-item">
                            {item?.title}
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            {item?.distributor_name}
                          </div>
                        </td>
                        <td>
                          <div className="order-item">
                            {item?.phone}
                          </div>
                        </td>
                        {
                          wareHouseType === 'central' && <td>
                            <div className="order-item">
                              {item?.created_by?.phone}
                            </div>
                          </td>
                        }

                        {
                          wareHouseType !== 'central' &&
                          <td style={{ width: '150px' }}>
                            <div className="btn-group">
                              <>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Connect Device
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/route/details/${item.id}`} className="btn btn-sm btn-default"><BiLayerPlus /></Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view`}>
                                      Edit Route
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/route/edit/${item.id}`} className="btn btn-sm btn-default"><FiEdit /></Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  key="view"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-view-2`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <ConfirmationModal title="Delete" body="Are you sure you want to delete?" handleAction={() => this.handleDeleteRoute(item.id)} />
                                </OverlayTrigger>
                              </>
                            </div>
                          </td>
                        }
                      </tr>
                    );
                  }) : ""}
                </tbody>
              </table>
            </div>
            <div className="mt-3">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.per_page}
                totalItemsCount={this.state.total_data}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </App>
    );
  }
}

export default withRouter(List);
